import { DsDiSuspiciousOrMaliciousWebsiteInputViewModel } from './../../../Contracts/TypesAndInterfaces/DsDiSuspiciousOrMaliciousWebsiteInputViewModel';
import { SovereignDigitalEventTypes } from './../../../Contracts/Enums/DigitalIncidentEnums';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DsDiMalwareInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiMalwareInputViewModel";
import { DsDiPhishingCallorTextInputViewModel, DsDiPhishingCallorTextLocalizedInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiPhishingCallorTextInputViewModel";
import { DsDiPhishingEmailInputViewModel, DsDiPhishingEmailLocalizedInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiPhishingEmailInputViewModel";
import { DsDiFileAttachmentInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiFileAttachmentInputViewModel";
import { DigitalIncidentModel } from "../../../Contracts/TypesAndInterfaces/DigitalIncidentModel";
import { DigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { DigitalIncidentType } from "../../../Contracts/TypesAndInterfaces/DigitalIncidentType";
import { DsDiExposureOfSensitiveInformationInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiExposureOfSensitiveInformationInputViewModel";
import { DsDiExposureOfPersonalDataInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiExposureOfPersonalDataInputViewModel";
import { DsDiUnauthorizedAccessOrHackInputLocalizedViewModel, DsDiUnauthorizedAccessOrHackInputViewModel } from '../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel';
import { NavigateFunction } from 'react-router-dom';
import { ExecutiveAliasErrorModel } from '../../../Contracts/ExceptionModels/ExceptionModel';
import { getLocalizedValue } from '../../../Services/localizationService';
import { MaxFileCountAllowed, PhishingEmailSimulationTypeName } from '../../../Constants/CommonConstants';
import { DsDiTechnicalSupportScamLocalizedViewModel, DsDiTechnicalSupportScamViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiTechnicalSupportScamViewModel";
const date = new Date();

const initialState: DigitalIncidentModel = {
    digitalIncidentTypes: {
        incidentTypesL1: [],
        incidentTypesL2: [],
        isOutlookButtonAvailable: true,
        selectedIncidentTypeL1: undefined,
        selectedIncidentTypeL2: undefined,
        isL1ApiCallSuccess: true,
        isL2ApiCallSuccess: true
    },
    digitalIncidentInformation: {
        reportingOn: new Date(),
        incidentDate: new Date(new Date().setSeconds(0, 0)),
        incidentHour: date.getHours(),
        incidentMinute: date.getMinutes(),
        incidentDescription: "",
        incidentTimeZone: "",
        incidentTimeZoneOffset: new Date().getTimezoneOffset(),
        incidentTypeId: 0,
        incidentTypeName: "",
        simulationCampaignId: null,
        isExecSupport: false,
        executiveAlias: null,
        azureSubscriptionId: null,
        attachedFiles: [],
        digitalSecurityIncidentPhishingEmail: undefined,
        digitalSecurityIncidentPhishingCallorText: undefined,
        digitalSecurityIncidentMalware: undefined,
        digitalSecurityIncidentExposureOfSensitiveInformation: undefined,
        digitalSecurityIncidentExposureOfPersonalData: undefined,
        digitalSecuritySuspiciousMaliciousWebsite: undefined,
        digitalSecurityIncidentTechnicalSupportScam: undefined
    },
    digitalIncidentLocalizedInformation:{
        incidentTypeL1Name:"",
        incidentTypeL2Name:"",
        digitalSecurityIncidentPhishingEmail: undefined,
        digitalSecurityIncidentPhishingCallorText: undefined,
        digitalSecurityIncidentTechnicalSupportScam:undefined,
        digitalSecurityUnauthorizedAccessOrHack:undefined
    },
    errorStates: {
        incidentDescription: "",
        malwareAffectedMachineName: "",
        malwareFileName: "",
        malwareThreatName: "",
        phishingCallOrTextFrequency: "",
        phishingCallOrTextOffenderDetails: "",
        phishingCallOrTextActions: "",
        phishingActions: "",
        locationAndTime: "",
        azureSubscription: "",
        exposureOfPersonalDataCustomerOrPartnerDetails: "",
        exposureOfSensitiveInformationCustomerOrPartnerDetails: "",
        suspiciousOrMaliciousWebsiteAffectedMachineName: "",
        suspiciousOrMaliciousWebsiteURL: "",
        unauthorizedSuspiciousLoginHostName: "",
        unauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount: "",
        technicalSupportScamAffectedMachineName: "",
        technicalSupportScamUrl: "",
        technicalSupportScamWasRemoteAccessAllowed: "",
        unauthorizedUninitiatedMFAHostName: "",
        unauthorizedUninitiatedMFAPromt: "",
        unauthorizedUninitiatedMFARelatedToWorkOrSchoolAccount: "",
        unauthorizedUnknownDeviceEnrolledDeviceName: "",
        unauthorizedUnknownDeviceHostName: "",
        unauthorizedUnknownDeviceIsNewDeviceEnrolled: "",
        executiveAlias: ""
    },
    validationErrorCount: 0,
    validationError: "",
    isFormValid: false,
    totalAttachmentSizeInKB: 0,
    isIncidentTypesPanelOpen: false,
    isIncidentTimeChanged: false
}

const DigitalIncidentSlice = createSlice({
    name: "DigitalIncident",
    initialState,
    reducers: {
        setIsDigitalIncidentTimeChanged: (state, { payload }: PayloadAction<boolean>) => {
            state.isIncidentTimeChanged = payload;
        },
        setDigitalIncidentTypesPanelVisibility: (state, { payload }: PayloadAction<boolean>) => {
            state.isIncidentTypesPanelOpen = payload;
        },
        getDigitalIncidentTypes: (state, { payload }: PayloadAction<{ level: number, parentIncidentTypeId?: number }>) => {
        },
        setDigitalIncidentAzureSubscriptionId: (state, { payload }: PayloadAction<string>) => {
            state.digitalIncidentInformation.azureSubscriptionId = payload;
        },
        setIsDigitalIncidentTypesL1ApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.digitalIncidentTypes.isL1ApiCallSuccess = payload;
        },
        setIsDigitalIncidentTypesL2ApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.digitalIncidentTypes.isL2ApiCallSuccess = payload;
        },
        setDigitalIncidentTypesL1: (state, { payload }: PayloadAction<DigitalIncidentType[]>) => {
            state.digitalIncidentTypes.incidentTypesL1 = payload;
        },
        setDigitalIncidentTypesL2: (state, { payload }: PayloadAction<DigitalIncidentType[]>) => {
            state.digitalIncidentTypes.incidentTypesL2 = payload;
        },
        setSelectedDigitalIncidentTypeL1: (state, { payload }: PayloadAction<DigitalIncidentType | undefined>) => {
            state.digitalIncidentTypes.selectedIncidentTypeL1 = payload;
        },
        setSelectedDigitalIncidentTypeL2: (state, { payload }: PayloadAction<DigitalIncidentType | undefined>) => {
            state.digitalIncidentTypes.selectedIncidentTypeL2 = payload;
        },
        setIsOutlookAvailable: (state, { payload }: PayloadAction<boolean>) => {
            state.digitalIncidentTypes.isOutlookButtonAvailable = payload;
        },
        setDigitalIncidentReportingOn: (state, { payload }: PayloadAction<Date>) => {
            state.digitalIncidentInformation.reportingOn = payload;
        },
        setDigitalIncidentIncidentDate: (state, { payload }: PayloadAction<Date | null>) => {
            if (payload) {
                const newDate = state.digitalIncidentInformation.incidentDate ?? new Date();
                newDate.setFullYear(payload.getFullYear(), payload.getMonth(), payload.getDate());
                state.digitalIncidentInformation.incidentDate = newDate;
            }
            else
                state.digitalIncidentInformation.incidentDate = payload;
        },
        setDigitalIncidentDateTime: (state, { payload }: PayloadAction<Date>) => {
            state.digitalIncidentInformation.incidentDate = payload;
        },
        setDigitalIncidentIncidentHour: (state, { payload }: PayloadAction<number>) => {
            state.digitalIncidentInformation.incidentHour = payload;

            if (payload >= 0 && payload < 24) {
                const newDate = new Date(state.digitalIncidentInformation.incidentDate?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setHours(payload);
                state.digitalIncidentInformation.incidentDate = newDate;
            }
        },
        setDigitalIncidentIncidentMinute: (state, { payload }: PayloadAction<number>) => {
            state.digitalIncidentInformation.incidentMinute = payload;

            if (payload >= 0 && payload < 60) {
                const newDate = new Date(state.digitalIncidentInformation.incidentDate?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setMinutes(payload);
                state.digitalIncidentInformation.incidentDate = newDate;
            }
            //state.digitalIncidentInformation.incidentHour = state.digitalIncidentInformation.incidentDate.getHours();
        },
        setDigitalIncidentIncidentDescription: (state, { payload }: PayloadAction<string>) => {
            state.digitalIncidentInformation.incidentDescription = payload;
        },
        setDigitalIncidentIncidentTimeZone: (state, { payload }: PayloadAction<string>) => {
            state.digitalIncidentInformation.incidentTimeZone = payload;
        },
        setDigitalIncidentIncidentTypeId: (state, { payload }: PayloadAction<number>) => {
            state.digitalIncidentInformation.incidentTypeId = payload;
            state.digitalIncidentInformation.incidentTypeName = state.digitalIncidentTypes.incidentTypesL1.find((s) => s.typeId == payload)?.name ?? "";
        },
        setDigitalIncidentIsExecSupport: (state, { payload }: PayloadAction<boolean>) => {
            state.digitalIncidentInformation.isExecSupport = payload;
        },
        setDigitalIncidentExecutiveAlias: (state, { payload }: PayloadAction<string | null>) => {
            if (state.digitalIncidentInformation.isExecSupport) { state.digitalIncidentInformation.executiveAlias = payload }
            else {
                state.digitalIncidentInformation.executiveAlias = null
            }
        },
        setDigitalIncidentAttachedFiles: (state, { payload }: PayloadAction<DsDiFileAttachmentInputViewModel[]>) => {
            state.digitalIncidentInformation.attachedFiles = payload;
        },
        setDigitalIncidentPhishingEmailObject: (state, { payload }: PayloadAction<DsDiPhishingEmailInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail = payload;
        },
        setMalwareAffectedMachineName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentMalware) {
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.affectedMachineName = payload;
            }
        },
        setMalwareFileName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentMalware) {
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.fileName = payload;
            }
        },
        setMalwareThreatName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentMalware) {
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.threatName = payload;
            }
        },
        setPhishingCallOrTextOffenderContactDetails: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.offenderContactDetails = payload;
            }
        },
        setPhishingCallOrTextCallFrequency: (state, { payload }: PayloadAction<number>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.callFrequencyPerDay = payload;
            }
        },
        setPhishingCallOrTextIsSensitiveInfoSharedByUser: (state, { payload }: PayloadAction<boolean>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.isSensitiveInformationShared = payload;
            }
        },
        setPhishingCallOrTextInfoSharedByUser: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.infoSharedByUser = payload;
            }
        },
        setPhishingCallOrTextReceivedOn: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.callReceivedOn = payload;
            }
        },
        setPhishingEmailDeliveredLocation: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.emailDeliveredLocation = payload;
            }
        },
        setPhishingEmailInfoSharedByUser: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser = payload;
            }
        },
        setPhishingEmailAttachmentUrlOpenedByUser: (state, { payload }: PayloadAction<boolean>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.isAttachmentorURLOpenedByUser = payload;
            }
        },
        validateDigitalIncidentFormInformation: (state, { payload }: PayloadAction<SovereignDigitalEventTypes | undefined>) => {
            let validationErrorCount = 0;
            let validationError = "";
            var valid = true;

            // Date time validations
            const currentDateTime = new Date();
            const isDateInFuture = state.digitalIncidentInformation.incidentDate && state.digitalIncidentInformation.incidentDate > currentDateTime;
            const isDateEmpty = state.digitalIncidentInformation.incidentDate == null;
            const isHoursInvalid = state.digitalIncidentInformation.incidentHour > 23 || state.digitalIncidentInformation.incidentHour < 0;
            const isMinutesInvalid = state.digitalIncidentInformation.incidentMinute > 59 || state.digitalIncidentInformation.incidentMinute < 0;


            if (isDateEmpty) {
                state.errorStates.locationAndTime = getLocalizedValue("RIN_ExpectedFormatIsDayMonthDateYear");
                if (valid) {
                    document.getElementById("digitalIncidentDateId-label")?.focus();
                }
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_ExpectedFormatIsDayMonthDateYear");
            }
            else if (isHoursInvalid || isMinutesInvalid) {
                if (state.digitalIncidentInformation.incidentHour > 23) {
                    if (valid) {
                        document.getElementById("digitalIncidentDateId-label")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_HourCannotBeGreaterThan");
                }
                else if (state.digitalIncidentInformation.incidentHour < 0) {
                    if (valid) {
                        document.getElementById("digitalIncidentDateId-label")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_HourCannotBeLessThan");
                }
                else if (state.digitalIncidentInformation.incidentMinute > 59) {
                    if (valid) {
                        document.getElementById("digitalIncidentDateId-label")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_MinuteCannotBeGreaterThan");
                }
                else if (state.digitalIncidentInformation.incidentMinute < 0) {
                    if (valid) {
                        document.getElementById("digitalIncidentDateId-label")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_MinuteCannotBeLessThan");
                }
            }
            else if (isDateInFuture) {
                state.errorStates.locationAndTime = getLocalizedValue("RIN_DateAndTimeCannotBeInTheFuture");
                if (valid) {
                    document.getElementById("digitalIncidentDateId-label")?.focus();
                }
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_DateAndTimeCannotBeInTheFuture");
            } else {
                state.errorStates.locationAndTime = "";
            }

            if (state.digitalIncidentInformation.isExecSupport && state.digitalIncidentTypes.selectedIncidentTypeL1 && state.digitalIncidentTypes.selectedIncidentTypeL1.typeId !== DigitalEventTypes.GovRelated && !state.digitalIncidentInformation.executiveAlias?.trim()) {
                if (valid) {
                    document.getElementById("executiveAlias")?.focus();
                }
                valid = false;
                validationErrorCount++;
                state.errorStates.executiveAlias = getLocalizedValue("RIN_PleaseInputAliasOfExecutiveAssociatedWithIncident");
                validationError = getLocalizedValue("RIN_PleaseInputAliasOfExecutiveAssociatedWithIncident");
            }

            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.UnauthorizedAccess || payload === SovereignDigitalEventTypes.UnauthorizedAccess) {
                if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin !== undefined) {
                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin?.hostName?.trim().length === 0) {
                        if (valid) {
                            document.getElementById("suspiciousLoginHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedSuspiciousLoginHostName = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin?.hostName && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin?.hostName?.trim().length > 500) {
                        if (valid) {
                            document.getElementById("suspiciousLoginHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedSuspiciousLoginHostName = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                    }
                }
                else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA !== undefined) {
                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.hostName?.trim().length === 0) {
                        if (valid) {
                            document.getElementById("uninitiatedMFAHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUninitiatedMFAHostName = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.hostName && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.hostName?.trim().length > 500) {
                        if (valid) {
                            document.getElementById("uninitiatedMFAHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUninitiatedMFAHostName = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                    }
                }
                else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled !== undefined) {
                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.hostName?.trim().length === 0) {
                        if (valid) {
                            document.getElementById("unknownDeviceHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUnknownDeviceHostName = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameIsAMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.hostName && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.hostName?.trim().length > 500) {
                        if (valid) {
                            document.getElementById("unknownDeviceHostNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUnknownDeviceHostName = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_HostNameCannotBeLongerThanCharacters");
                    }

                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.enrolledDeviceName?.trim().length === 0) {
                        if (valid) {
                            document.getElementById("unknownDeviceEnrolledDeviceNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUnknownDeviceEnrolledDeviceName = getLocalizedValue("RIN_EnrolledDeviceNameIsAMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_EnrolledDeviceNameIsAMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.enrolledDeviceName && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.enrolledDeviceName?.trim().length > 200) {
                        if (valid) {
                            document.getElementById("unknownDeviceEnrolledDeviceNameId")?.focus();
                        }
                        state.errorStates.unauthorizedUnknownDeviceEnrolledDeviceName = getLocalizedValue("RIN_EnrolledDeviceNameCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_EnrolledDeviceNameCannotBeLongerThanCharacters");
                    }
                }
                //This check confirms for selected Gov digital Tech scam is at L2
                else if(payload !== SovereignDigitalEventTypes.TechnicalSupportScam){
                    if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName.trim().length === 0) {
                        if (valid) {
                            document.getElementById("technicalScamAffectedMachineNameId")?.focus();
                        }
                        state.errorStates.technicalSupportScamAffectedMachineName = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName && state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName?.trim().length > 500) {
                        if (valid) {
                            document.getElementById("technicalScamAffectedMachineNameId")?.focus();
                        }
                        state.errorStates.technicalSupportScamAffectedMachineName = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                    }
    
                    if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl.trim().length === 0) {
                        if (valid) {
                            document.getElementById("technicalSupportScamUrlId")?.focus();
                        }
                        state.errorStates.technicalSupportScamUrl = getLocalizedValue("RIN_ScamURLIsAMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_ScamURLIsAMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl && state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl?.trim().length > 500) {
                        if (valid) {
                            document.getElementById("technicalSupportScamUrlId")?.focus();
                        }
                        state.errorStates.technicalSupportScamUrl = getLocalizedValue("RIN_ScamURLCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_ScamURLCannotBeLongerThanCharacters");
                    }
                    if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.wasRemoteAccessAllowed===undefined) {
                        if (valid) {
                            document.getElementById("technicalSupportScamWasRemoteAccessAllowed")?.focus();
                        }
                        state.errorStates.technicalSupportScamWasRemoteAccessAllowed = getLocalizedValue("RIN_WasRemoteAccessAllowedMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_WasRemoteAccessAllowedMandatoryField");
                    }
                    
                }
            }

            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.Malware || payload == SovereignDigitalEventTypes.Malware) {
                if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.affectedMachineName?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("malwareAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.malwareAffectedMachineName = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.affectedMachineName && state.digitalIncidentInformation.digitalSecurityIncidentMalware?.affectedMachineName?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("malwareAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.malwareAffectedMachineName = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                }


                if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.fileName?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("malwareFileNameId")?.focus();
                    }
                    state.errorStates.malwareFileName = getLocalizedValue("RIN_FileNameIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_FileNameIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.fileName && state.digitalIncidentInformation.digitalSecurityIncidentMalware?.fileName?.trim().length > 1000) {
                    if (valid) {
                        document.getElementById("malwareFileNameId")?.focus();
                    }
                    state.errorStates.malwareFileName = getLocalizedValue("RIN_FileNameCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_FileNameCannotBeLongerThanCharacters");
                }


                if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.threatName?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("malwareThreatNameId")?.focus();
                    }
                    state.errorStates.malwareThreatName = getLocalizedValue("RIN_ThreatNameIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_ThreatNameIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentMalware?.threatName && state.digitalIncidentInformation.digitalSecurityIncidentMalware?.threatName?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("malwareThreatNameId")?.focus();
                    }
                    state.errorStates.malwareThreatName = getLocalizedValue("RIN_ThreatNameCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_ThreatNameCannotBeLongerThanCharacters");
                }
            }
            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.ExposureOfPersonalData || payload == SovereignDigitalEventTypes.ExposureOfPersonalData) {
                if (state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData && state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData.customerOrPartnerDetails != null && state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData.customerOrPartnerDetails.trim().length > 500) {
                    if (valid) {
                        document.getElementById("digitalIncidentExposureOfPersonalDataCustOrPartnerId")?.focus();
                    }
                    state.errorStates.exposureOfPersonalDataCustomerOrPartnerDetails = getLocalizedValue("RIN_CustomerOrPartnerDataCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CustomerOrPartnerDataCannotBeLongerThanCharacters");
                }
            }
            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.ExposureOfSensitiveInformation || payload == SovereignDigitalEventTypes.ExposureOfSensitiveInformation) {
                if (state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation && state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation.customerOrPartnerDetails != null && state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation.customerOrPartnerDetails.trim().length > 500) {
                    if (valid) {
                        document.getElementById("digitalIncidentExposureOfSensitiveInfoCustOrPartnerId")?.focus();
                    }
                    state.errorStates.exposureOfSensitiveInformationCustomerOrPartnerDetails = getLocalizedValue("RIN_CustomerOrPartnerDataCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CustomerOrPartnerDataCannotBeLongerThanCharacters");
                }
            }
            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.SuspiciousOrMaliciousWebsite || payload == SovereignDigitalEventTypes.SuspiciousOrMaliciousWebsite) {
                if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.affectedMachineName?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("SuspiciousWebsiteAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.suspiciousOrMaliciousWebsiteAffectedMachineName = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.affectedMachineName && state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.affectedMachineName?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("SuspiciousWebsiteAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.suspiciousOrMaliciousWebsiteURL = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                }

                if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.uRL?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("suspiciousWebsiteURLId")?.focus();
                    }
                    state.errorStates.suspiciousOrMaliciousWebsiteURL = getLocalizedValue("RIN_URLIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_URLIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.uRL && state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite?.uRL?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("suspiciousWebsiteURLId")?.focus();
                    }
                    state.errorStates.suspiciousOrMaliciousWebsiteAffectedMachineName = getLocalizedValue("RIN_URLCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_URLCannotBeLongerThanCharacters");
                }
            }
            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.PhishingCallOrText || payload == SovereignDigitalEventTypes.PhishingCallOrText) {
                if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.offenderContactDetails?.trim().length === 0) {
                    if (valid) {
                        document.getElementById("phishingCallerDetailsId")?.focus();
                    }
                    state.errorStates.phishingCallOrTextOffenderDetails = getLocalizedValue("RIN_CallerDetailsIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CallerDetailsIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.offenderContactDetails && state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.offenderContactDetails?.trim().length > 200) {
                    if (valid) {
                        document.getElementById("phishingCallerDetailsId")?.focus();
                    }
                    state.errorStates.phishingCallOrTextOffenderDetails = getLocalizedValue("RIN_CallerDetailsCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CallerDetailsCannotBeLongerThanCharacters");
                }

                if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.callFrequencyPerDay === undefined) {
                    if (valid) {
                        document.getElementById("phishingCallerFrequencyId")?.focus();
                    }
                    state.errorStates.phishingCallOrTextFrequency = getLocalizedValue("RIN_CallFrequencyIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CallFrequencyIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.callFrequencyPerDay > 100 || state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.callFrequencyPerDay < 1) {
                    if (valid) {
                        document.getElementById("phishingCallerFrequencyId")?.focus();
                    }
                    state.errorStates.phishingCallOrTextFrequency = getLocalizedValue("RIN_CallFrequencyCannotBeLessThanAndGreaterThan");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_CallFrequencyCannotBeLessThanAndGreaterThan");
                }

                // if isSensitiveInformationShared is true then "your actions" is mandatory
                if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.isSensitiveInformationShared === true && state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.infoSharedByUser?.trim()?.length === 0) {
                    if (valid) {
                        document.getElementById("phishingCallerAdditionalDetailsId")?.focus();
                    }
                    state.errorStates.phishingCallOrTextActions = getLocalizedValue("RIN_YourActionsIsMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_YourActionsIsMandatoryField");
                }
                else
                    if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.infoSharedByUser && state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText?.infoSharedByUser?.trim().length > 1000) {
                        if (valid) {
                            document.getElementById("phishingCallerAdditionalDetailsId")?.focus();
                        }
                        state.errorStates.phishingCallOrTextActions = getLocalizedValue("RIN_YourActionsCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_YourActionsCannotBeLongerThanCharacters");
                    }

            }
            if (state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.PhishingEMail || payload == SovereignDigitalEventTypes.PhishingEMail) {
                if (valid) {
                    document.getElementById("phishingEmailDetailsID")?.focus();
                }
                if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.isAttachmentorURLOpenedByUser == true) {
                    if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.infoSharedByUser === undefined || state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.infoSharedByUser?.trim()?.length === 0) {
                        state.errorStates.phishingActions = getLocalizedValue("RIN_YourActionsIsMandatoryField");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_YourActionsIsMandatoryField");
                    }
                    else if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.infoSharedByUser && state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.infoSharedByUser.trim().length > 1000) {
                        if (valid) {
                            document.getElementById("phishingEmailDetailsID")?.focus();
                        }
                        state.errorStates.phishingActions = getLocalizedValue("RIN_YourActionsCannotBeLongerThanCharacters");
                        valid = false;
                        validationErrorCount++;
                        validationError = getLocalizedValue("RIN_YourActionsCannotBeLongerThanCharacters");
                    }
                }
            }
            if(state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.TechnicalSupportScam || payload === SovereignDigitalEventTypes.TechnicalSupportScam){
                if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName.trim().length === 0) {
                    if (valid) {
                        document.getElementById("technicalScamAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.technicalSupportScamAffectedMachineName = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineNameIsMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName && state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("technicalScamAffectedMachineNameId")?.focus();
                    }
                    state.errorStates.technicalSupportScamAffectedMachineName = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AffectedMachineCannotBeLongerThanCharacters");
                }

                if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl.trim().length === 0) {
                    if (valid) {
                        document.getElementById("technicalSupportScamUrlId")?.focus();
                    }
                    state.errorStates.technicalSupportScamUrl = getLocalizedValue("RIN_ScamURLIsAMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_ScamURLIsAMandatoryField");
                }
                else if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl && state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl?.trim().length > 500) {
                    if (valid) {
                        document.getElementById("technicalSupportScamUrlId")?.focus();
                    }
                    state.errorStates.technicalSupportScamUrl = getLocalizedValue("RIN_ScamURLCannotBeLongerThanCharacters");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_ScamURLCannotBeLongerThanCharacters");
                }
                if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.wasRemoteAccessAllowed === undefined) {
                    if (valid) {
                        document.getElementById("technicalSupportScamWasRemoteAccessAllowed")?.focus();
                    }
                    state.errorStates.technicalSupportScamWasRemoteAccessAllowed = getLocalizedValue("RIN_WasRemoteAccessAllowedMandatoryField");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_WasRemoteAccessAllowedMandatoryField");
                }
                
            }

            if (state.digitalIncidentInformation.azureSubscriptionId && state.digitalIncidentInformation.azureSubscriptionId.trim().length > 0) {
                if (!(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(state.digitalIncidentInformation.azureSubscriptionId))) {
                    if (valid) {
                        document.getElementById("digitalIncidentAzureSubscriptionId")?.focus();
                    }
                    state.errorStates.azureSubscription = getLocalizedValue("RIN_AzureSubscriptionIDShouldBeAValidGUID");
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_AzureSubscriptionIDShouldBeAValidGUID");
                }
            }

            if (state.digitalIncidentInformation.incidentDescription.trim().length === 0) {
                if (valid) {
                    document.getElementById("digitalIncidentSummaryId")?.focus();
                }
                state.errorStates.incidentDescription = getLocalizedValue("RIN_IncidentSummaryIsAMandatoryField");
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_IncidentSummaryIsAMandatoryField");
            }
            else if (state.digitalIncidentInformation.incidentDescription && state.digitalIncidentInformation.incidentDescription.trim().length > 4000) {
                if (valid) {
                    document.getElementById("digitalIncidentSummaryId")?.focus();
                }
                state.errorStates.incidentDescription = getLocalizedValue("RIN_IncidentSummaryCannotBeLongerThanCharacters");
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_IncidentSummaryCannotBeLongerThanCharacters");
            }

            if (state.totalAttachmentSizeInKB / 1024 > 2.8) {
                if (valid) {
                    document.getElementById("fileUploadInputId")?.focus();
                }
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_AttachedFileSizeExceeded") + " 2.8MB";
            }

            if ((state.digitalIncidentInformation.incidentTypeId === DigitalEventTypes.PhishingEMail || payload == SovereignDigitalEventTypes.PhishingEMail) && (!state.digitalIncidentInformation.attachedFiles || state.digitalIncidentInformation.attachedFiles?.length == 0)) {
                if (valid) {
                    document.getElementById("fileUploadInputId")?.focus();
                }
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_UploadThePhishingEmail");
            }

            if (state.digitalIncidentInformation.attachedFiles) {
                state.digitalIncidentInformation.attachedFiles.forEach(file => {
                    if (file.fileSizeInKB > 2.8 * 1024) {
                        if (valid) {
                            document.getElementById("fileUploadInputId")?.focus();
                        }
                        valid = false;
                        validationErrorCount++;
                        validationError = file.name + getLocalizedValue("RIN_ExceededAllowedAttachmentSizeOf") + " 2.8MB";
                    }
                    if (file.name.length > 200) {
                        if (valid) {
                            document.getElementById("fileUploadInputId")?.focus();
                        }
                        valid = false;
                        validationErrorCount++;
                        validationError = file.name + " " + getLocalizedValue("RIN_ExceededTheAllowedAttachmentNameLengthOfCharacters");
                    }
                });

                if (state.digitalIncidentInformation.attachedFiles.length > MaxFileCountAllowed) {
                    if (valid) {
                        document.getElementById("fileUploadInputId")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_FileUploadFileCountError");
                }
            }

            state.isFormValid = valid;

            if (valid) {
                if (state.digitalIncidentInformation.incidentDate != null) {
                    state.digitalIncidentInformation.incidentDate = new Date(state.digitalIncidentInformation.incidentDate.getTime());
                    state.digitalIncidentInformation.reportingOn = new Date();
                }
                if (state.digitalIncidentInformation.azureSubscriptionId != null && state.digitalIncidentInformation.azureSubscriptionId.trim().length === 0) {
                    state.digitalIncidentInformation.azureSubscriptionId = null;
                }
            }
            state.validationErrorCount = validationErrorCount;
            state.validationError = validationError;
        },

        setValidationErrorForExecutiveAlias: (state, { payload }: PayloadAction<ExecutiveAliasErrorModel>) => {
            document.getElementById("executiveAlias")?.focus();
            state.errorStates.executiveAlias = payload?.ErrorMessage ?? "";
            if (payload?.IsErrorOnSubmission) {
                state.validationErrorCount = state.validationErrorCount + 1;
                state.validationError = payload.ErrorMessage ?? "";
                state.isFormValid = false;
            }
        },

        setDigitalIncidentLocationAndTimeErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.locationAndTime = payload;
        },
        setDigitalIncidentMalwareObject: (state, { payload }: PayloadAction<DsDiMalwareInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentMalware = payload;
        },
        setDigitalIncidentExposureOfSensitiveInfoObject: (state, { payload }: PayloadAction<DsDiExposureOfSensitiveInformationInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation = payload;
        },
        setDigitalIncidentExposureOfPersonalDataObject: (state, { payload }: PayloadAction<DsDiExposureOfPersonalDataInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData = payload;
        },
        setDigitalIncidentExposureOfSensitiveInfoCustomerOrPartnerDetails: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation) {
                state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation.customerOrPartnerDetails = payload;
            }
        },
        setDigitalIncidentExposureOfPersonalDataCustomerOrPartnerDetails: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData) {
                state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData.customerOrPartnerDetails = payload;
            }
        },
        setDigitalIncidentPhishingCallOrTextObject: (state, { payload }: PayloadAction<DsDiPhishingCallorTextInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText = payload;
        },
        setDigitalIncidentSuspiciousOrMaliciousWebsitesObject: (state, { payload }: PayloadAction<DsDiSuspiciousOrMaliciousWebsiteInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite = payload;
        },
        setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite) {
                state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.affectedMachineName = payload;
            }
        },
        setDigitalIncidentSuspiciousOrMaliciousWebsiteURL: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite) {
                state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.uRL = payload;
            }
        },
        setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.suspiciousOrMaliciousWebsiteAffectedMachineName = payload;
        },
        setDigitalIncidentSuspiciousOrMaliciousWebsiteURLErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.suspiciousOrMaliciousWebsiteURL = payload;
        },
        setTotalAttachmentSizeInKB: (state, action: PayloadAction<number>) => {
            state.totalAttachmentSizeInKB = action.payload;
        },
        setIncidentDescriptionErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.incidentDescription = payload;
        },
        setExposureOfSensitiveInfoCustomerOrPartnerErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.exposureOfSensitiveInformationCustomerOrPartnerDetails = payload;
        },
        setExposureOfPersonalDataCustomerOrPartnerErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.exposureOfPersonalDataCustomerOrPartnerDetails = payload;
        },
        setAzureSubscriptionErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.azureSubscription = payload;
        },
        setAffectedMachineNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.malwareAffectedMachineName = payload;
        },
        setFileNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.malwareFileName = payload;
        },
        setThreatNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.malwareThreatName = payload;
        },
        setPhishingCallOrTextFrequencyErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.phishingCallOrTextFrequency = payload;
        },
        setPhishingCallOrTextOffenderDetailsErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.phishingCallOrTextOffenderDetails = payload;
        },
        setPhishingCallOrTextActionsErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.phishingCallOrTextActions = payload;
        },
        setPhishingActionsErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.phishingActions = payload;
        },
        getTimeZones: () => {

        },
        setUnauthorizedSuspiciousLoginHostName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.hostName = payload;
            }
        },
        setUnauthorizedSuspiciousLoginHostNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedSuspiciousLoginHostName = payload;
        },
        setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.relatedToWorkOrSchoolAccount = payload;
            }
        },
        setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccountErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount = payload;
        },
        setUnauthorizedUnknownDeviceHostName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.hostName = payload;
            }
        },
        setUnauthorizedUnknownDeviceHostNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUnknownDeviceHostName = payload;
        },
        setUnauthorizedUnknownDeviceEnrolledDeviceName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.enrolledDeviceName = payload;
            }
        },
        setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUnknownDeviceEnrolledDeviceName = payload;
        },
        setUnauthorizedUnknownDeviceIsNewDeviceEnrolled: (state, { payload }: PayloadAction<boolean>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.isNewDeviceEnrolled = payload;
            }
        },
        setUnauthorizedUnknownDeviceIsNewDeviceEnrolledErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUnknownDeviceIsNewDeviceEnrolled = payload;
        },
        setUnauthorizedUninitiatedMFAHostName: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.hostName = payload;
            }
        },
        setUnauthorizedUninitiatedMFAHostNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUninitiatedMFAHostName = payload;
        },
        setUnauthorizedUninitiatedMFAPrompt: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.mfaprompt = payload;
            }
        },
        setUnauthorizedUninitiatedMFAPromptErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUninitiatedMFAPromt = payload;
        },
        setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccount: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack && state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA) {
                state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.relatedToWorkOrSchoolAccount = payload;
            }
        },
        setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccountErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.unauthorizedUninitiatedMFARelatedToWorkOrSchoolAccount = payload;
        },
        setDigitalIncidentTechnicalSupportScamAffectedMachineName: (state, { payload }: PayloadAction<string>) => {
            if ( state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam) {
                state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.affectedMachineName = payload;
            }
        },
        setTechnicalSupportScamAffectedMachineNameErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.technicalSupportScamAffectedMachineName = payload;
        },
        setDigitalIncidentTechnicalSupportScamScamUrl: (state, { payload }: PayloadAction<string>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam) {
                state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.scamUrl = payload;
            }
        },
        setTechnicalSupportScamScamUrlErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.technicalSupportScamUrl = payload;
        },
        setDigitalIncidentTechnicalSupportScamRemoteAccessAllowed: (state, { payload }: PayloadAction<boolean>) => {
            if (state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam) {
                state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.wasRemoteAccessAllowed = payload;
            }
        },
        setTechnicalSupportScamRemoteAccessAllowedErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.technicalSupportScamWasRemoteAccessAllowed = payload;
        },
        setUnauthorizedAccessOrHackObject: (state, { payload }: PayloadAction<DsDiUnauthorizedAccessOrHackInputViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack = payload;
        },
        postDigitalIncidentFormInformation: (state, { payload }: PayloadAction<NavigateFunction | undefined>) => {
            state.digitalIncidentInformation.incidentDescription = state.digitalIncidentInformation.incidentDescription?.trim();

            if (state.digitalIncidentInformation.digitalSecurityIncidentMalware) {
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.affectedMachineName = state.digitalIncidentInformation.digitalSecurityIncidentMalware.affectedMachineName?.trim();
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.fileName = state.digitalIncidentInformation.digitalSecurityIncidentMalware.fileName?.trim();
                state.digitalIncidentInformation.digitalSecurityIncidentMalware.threatName = state.digitalIncidentInformation.digitalSecurityIncidentMalware.threatName?.trim();
            }

            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.infoSharedByUser = state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.infoSharedByUser?.trim();
                state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.offenderContactDetails = state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.offenderContactDetails?.trim();
            }

            if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail) {
                if (state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.isAttachmentorURLOpenedByUser)
                    state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser = state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser?.trim();
                else
                    state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser = undefined;
            }

            if (state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite) {
                state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.affectedMachineName = state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.affectedMachineName?.trim();
                state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.uRL = state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.uRL?.trim();
            }

            if(state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam){
                state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.affectedMachineName = state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.affectedMachineName.trim();
                    state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.scamUrl = state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.scamUrl.trim();
            }

            if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack) {
                if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin) {
                    state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.hostName = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.hostName.trim();
                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.relatedToWorkOrSchoolAccount) {
                        state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.relatedToWorkOrSchoolAccount = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.relatedToWorkOrSchoolAccount;
                    }
                }
                else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA) {
                    state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.hostName = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.hostName.trim();
                    if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.relatedToWorkOrSchoolAccount) {
                        state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.relatedToWorkOrSchoolAccount = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.relatedToWorkOrSchoolAccount;
                    }
                }
                else if (state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled) {
                    state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.enrolledDeviceName = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.enrolledDeviceName.trim();
                    state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.hostName = state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.hostName.trim();
                }
            }

        },
        postDigitalSimulationIncident: (state, action: PayloadAction<{ incidentTypeId: number, simulationCampaignId?: number, incidentDescription: string, timeZone: string }>) => {
            state.digitalIncidentInformation.incidentTypeId = action.payload.incidentTypeId;
            state.digitalIncidentInformation.incidentTypeName = PhishingEmailSimulationTypeName;
            state.digitalIncidentInformation.simulationCampaignId = action.payload.simulationCampaignId ?? null;
            state.digitalIncidentInformation.incidentDescription = action.payload.incidentDescription;
            state.digitalIncidentInformation.incidentTimeZone = action.payload.timeZone;
        },
        resetDigitalIncidentInformation: (state, { payload }: PayloadAction<boolean>) => {
            state.digitalIncidentInformation.reportingOn = new Date();
            state.digitalIncidentInformation.incidentDate = new Date();
            state.digitalIncidentInformation.incidentHour = new Date().getHours();
            state.digitalIncidentInformation.incidentMinute = new Date().getMinutes();
            state.isIncidentTimeChanged = false;
            state.digitalIncidentInformation.incidentDescription = "";
            state.digitalIncidentInformation.isExecSupport = false;
            state.digitalIncidentInformation.executiveAlias = null;
            state.digitalIncidentInformation.attachedFiles = [];
            state.digitalIncidentInformation.azureSubscriptionId = null;
            state.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail = undefined;
            state.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText = undefined;
            state.digitalIncidentInformation.digitalSecurityIncidentMalware = undefined;
            state.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation = undefined;
            state.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData = undefined;
            state.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite = undefined;
            state.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack = undefined;
            state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam = undefined;
            state.digitalIncidentLocalizedInformation.incidentTypeL1Name = "";
            state.digitalIncidentLocalizedInformation.incidentTypeL2Name = "";
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingEmail = undefined;
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingCallorText = undefined;
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentTechnicalSupportScam = undefined;
            state.digitalIncidentLocalizedInformation.digitalSecurityUnauthorizedAccessOrHack = undefined;
            if (payload) {
                state.digitalIncidentInformation.incidentTypeId = 0;
            }
            state.digitalIncidentInformation.simulationCampaignId = null;

            // TODO: when adding test incident functionality to existing UI please reset it.
            // state.digitalIncidentInformation.isTestIncident = undefined;

            // clear error states

            state.errorStates.incidentDescription = "";
            state.errorStates.malwareAffectedMachineName = "";
            state.errorStates.malwareFileName = "";
            state.errorStates.malwareThreatName = "";
            state.errorStates.phishingCallOrTextActions = "";
            state.errorStates.phishingCallOrTextFrequency = "";
            state.errorStates.phishingCallOrTextOffenderDetails = "";
            state.errorStates.phishingActions = "";
            state.errorStates.exposureOfPersonalDataCustomerOrPartnerDetails = "";
            state.errorStates.exposureOfSensitiveInformationCustomerOrPartnerDetails = "";
            state.errorStates.locationAndTime = "";
            state.errorStates.suspiciousOrMaliciousWebsiteAffectedMachineName = "";
            state.errorStates.suspiciousOrMaliciousWebsiteURL = "";
            state.validationError = "";
            state.validationErrorCount = 0;
            state.errorStates.unauthorizedSuspiciousLoginHostName = "";
            state.errorStates.unauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount = "";
            state.errorStates.technicalSupportScamAffectedMachineName = "";
            state.errorStates.technicalSupportScamUrl = "";
            state.errorStates.technicalSupportScamWasRemoteAccessAllowed = ""
            state.errorStates.unauthorizedUninitiatedMFAHostName = "";
            state.errorStates.unauthorizedUninitiatedMFAPromt = "";
            state.errorStates.unauthorizedUninitiatedMFARelatedToWorkOrSchoolAccount = "";
            state.errorStates.unauthorizedUnknownDeviceEnrolledDeviceName = "";
            state.errorStates.unauthorizedUnknownDeviceHostName = "";
            state.errorStates.unauthorizedUnknownDeviceIsNewDeviceEnrolled = "";
            state.errorStates.executiveAlias = "";

            //clear additional information section 

            state.errorStates.azureSubscription = "";
            state.digitalIncidentInformation.incidentDescription = "";
            state.digitalIncidentInformation.attachedFiles = [];
            state.digitalIncidentInformation.incidentDate = new Date();
            state.digitalIncidentInformation.incidentHour = new Date().getHours();
            state.digitalIncidentInformation.incidentMinute = new Date().getMinutes();

            //

            state.digitalIncidentTypes.incidentTypesL2 = [];
            state.digitalIncidentTypes.selectedIncidentTypeL2 = undefined;

            //set form state as invalid

            state.isFormValid = false;
        },
        clearDigitalIncidentErrorStates: (state) => {
            state.errorStates.incidentDescription = "";
            state.errorStates.malwareAffectedMachineName = "";
            state.errorStates.malwareFileName = "";
            state.errorStates.malwareThreatName = "";
            state.errorStates.phishingCallOrTextActions = "";
            state.errorStates.phishingCallOrTextFrequency = "";
            state.errorStates.phishingCallOrTextOffenderDetails = "";
            state.errorStates.phishingActions = "";
            state.errorStates.locationAndTime = "";
            state.errorStates.azureSubscription = "";
            state.errorStates.exposureOfPersonalDataCustomerOrPartnerDetails = "";
            state.errorStates.exposureOfSensitiveInformationCustomerOrPartnerDetails = "";
            state.errorStates.suspiciousOrMaliciousWebsiteAffectedMachineName = "";
            state.errorStates.suspiciousOrMaliciousWebsiteURL = "";
            state.validationError = "";
            state.validationErrorCount = 0;
            state.errorStates.unauthorizedSuspiciousLoginHostName = "";
            state.errorStates.unauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount = "";
            state.errorStates.technicalSupportScamAffectedMachineName = "";
            state.errorStates.technicalSupportScamUrl = "";
            state.errorStates.technicalSupportScamWasRemoteAccessAllowed = ""
            state.errorStates.unauthorizedUninitiatedMFAHostName = "";
            state.errorStates.unauthorizedUninitiatedMFAPromt = "";
            state.errorStates.unauthorizedUninitiatedMFARelatedToWorkOrSchoolAccount = "";
            state.errorStates.unauthorizedUnknownDeviceEnrolledDeviceName = "";
            state.errorStates.unauthorizedUnknownDeviceHostName = "";
            state.errorStates.unauthorizedUnknownDeviceIsNewDeviceEnrolled = "";
            state.errorStates.executiveAlias = "";
        },
        deleteDigitalIncidentAttachedFile: (state, action: PayloadAction<string>) => {
            state.digitalIncidentInformation.attachedFiles = state.digitalIncidentInformation.attachedFiles?.filter(x => x.name !== action.payload);
        },
        validateDigitalDataV2: (state, { payload }: PayloadAction<NavigateFunction>) => {
        },
        validateExecutiveAlias: (state) => {
        },
        setIsTestIncident: (state, { payload }: PayloadAction<boolean | undefined>) => {
            state.digitalIncidentInformation.isTestIncident = payload;
        },
        setDigitalIncidentLocalizedPhishingEmailObject:(state,{payload}:PayloadAction<DsDiPhishingEmailLocalizedInputViewModel>)=>{
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingEmail = payload;
        },

        setDigitalIncidentLocalizedPhishingCallOrTextObject:(state,{payload}:PayloadAction<DsDiPhishingCallorTextLocalizedInputViewModel>)=>{
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingCallorText = payload;
        },
        setLocalizedUnauthorizedAccessOrHackObject:(state,{payload}:PayloadAction<DsDiUnauthorizedAccessOrHackInputLocalizedViewModel>)=>{
            state.digitalIncidentLocalizedInformation.digitalSecurityUnauthorizedAccessOrHack = payload;
        },
        setLocalizedSelectedDigitalIncidentTypeNameL1: (state, { payload }: PayloadAction<string|undefined>) => {
            state.digitalIncidentLocalizedInformation.incidentTypeL1Name = payload??"";
        },
        setLocalizedSelectedDigitalIncidentTypeNameL2: (state, { payload }: PayloadAction<string|undefined>) => {
            state.digitalIncidentLocalizedInformation.incidentTypeL2Name = payload??"";
        },
        setDigitalIncidentLocalizedTechnicalSupportScamObject:(state,{payload}:PayloadAction<DsDiTechnicalSupportScamLocalizedViewModel>)=>{
            state.digitalIncidentLocalizedInformation.digitalSecurityIncidentTechnicalSupportScam = payload;
        },    
        setTechnicalSupportScamObject: (state, { payload }: PayloadAction<DsDiTechnicalSupportScamViewModel | undefined>) => {
            state.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam = payload
        }
    },
})
export const {
    setIsDigitalIncidentTimeChanged,
    setDigitalIncidentTypesPanelVisibility,
    setUnauthorizedSuspiciousLoginHostNameErrorMessage,
    setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccountErrorMessage,
    setTechnicalSupportScamAffectedMachineNameErrorMessage,
    setTechnicalSupportScamRemoteAccessAllowedErrorMessage,
    setTechnicalSupportScamScamUrlErrorMessage,
    setUnauthorizedUninitiatedMFAHostNameErrorMessage,
    setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccountErrorMessage,
    setUnauthorizedUninitiatedMFAPromptErrorMessage,
    setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage,
    setUnauthorizedUnknownDeviceHostNameErrorMessage,
    setUnauthorizedUnknownDeviceIsNewDeviceEnrolledErrorMessage,
    setUnauthorizedAccessOrHackObject,
    setUnauthorizedSuspiciousLoginHostName,
    setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount,
    setDigitalIncidentTechnicalSupportScamAffectedMachineName,
    setDigitalIncidentTechnicalSupportScamRemoteAccessAllowed,
    setDigitalIncidentTechnicalSupportScamScamUrl,
    setUnauthorizedUninitiatedMFAHostName,
    setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccount,
    setUnauthorizedUninitiatedMFAPrompt,
    setUnauthorizedUnknownDeviceEnrolledDeviceName,
    setUnauthorizedUnknownDeviceHostName,
    setUnauthorizedUnknownDeviceIsNewDeviceEnrolled,
    setDigitalIncidentDateTime,
    setExposureOfPersonalDataCustomerOrPartnerErrorMessage,
    setExposureOfSensitiveInfoCustomerOrPartnerErrorMessage,
    setDigitalIncidentExposureOfPersonalDataCustomerOrPartnerDetails,
    setDigitalIncidentExposureOfPersonalDataObject,
    setAzureSubscriptionErrorMessage,
    setDigitalIncidentExposureOfSensitiveInfoCustomerOrPartnerDetails,
    setDigitalIncidentExposureOfSensitiveInfoObject,
    setDigitalIncidentAzureSubscriptionId,
    setIsDigitalIncidentTypesL1ApiCallSuccess,
    setIsDigitalIncidentTypesL2ApiCallSuccess,
    setMalwareAffectedMachineName,
    setMalwareFileName,
    setMalwareThreatName,
    setPhishingCallOrTextCallFrequency,
    setPhishingCallOrTextIsSensitiveInfoSharedByUser,
    setPhishingCallOrTextInfoSharedByUser,
    setPhishingCallOrTextOffenderContactDetails,
    setPhishingCallOrTextReceivedOn,
    setPhishingEmailAttachmentUrlOpenedByUser,
    setPhishingEmailDeliveredLocation,
    setPhishingEmailInfoSharedByUser,
    setDigitalIncidentTypesL1,
    setDigitalIncidentTypesL2,
    setIsOutlookAvailable: setIsOutlookButtonAvailable,
    setSelectedDigitalIncidentTypeL1,
    setSelectedDigitalIncidentTypeL2,
    getDigitalIncidentTypes,
    setDigitalIncidentPhishingCallOrTextObject,
    setDigitalIncidentMalwareObject,
    setDigitalIncidentReportingOn,
    setDigitalIncidentAttachedFiles,
    setDigitalIncidentPhishingEmailObject,
    setDigitalIncidentIncidentDate,
    setDigitalIncidentIncidentDescription,
    setDigitalIncidentIncidentHour,
    setDigitalIncidentIncidentMinute,
    clearDigitalIncidentErrorStates,
    setDigitalIncidentIncidentTimeZone,
    setDigitalIncidentIncidentTypeId,
    setDigitalIncidentIsExecSupport,
    setDigitalIncidentExecutiveAlias,
    validateDigitalIncidentFormInformation,
    setValidationErrorForExecutiveAlias,
    setIncidentDescriptionErrorMessage,
    setAffectedMachineNameErrorMessage,
    setFileNameErrorMessage,
    setThreatNameErrorMessage,
    setPhishingCallOrTextActionsErrorMessage,
    setPhishingCallOrTextFrequencyErrorMessage,
    setPhishingCallOrTextOffenderDetailsErrorMessage,
    postDigitalIncidentFormInformation,
    setPhishingActionsErrorMessage,
    getTimeZones,
    setDigitalIncidentLocationAndTimeErrorMessage,
    resetDigitalIncidentInformation,
    postDigitalSimulationIncident,
    setTotalAttachmentSizeInKB,
    deleteDigitalIncidentAttachedFile,
    setDigitalIncidentSuspiciousOrMaliciousWebsiteURL,
    setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineName,
    setDigitalIncidentSuspiciousOrMaliciousWebsitesObject,
    setDigitalIncidentSuspiciousOrMaliciousWebsiteURLErrorMessage,
    setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineNameErrorMessage,
    validateDigitalDataV2,
    validateExecutiveAlias,
    setIsTestIncident,
    setDigitalIncidentLocalizedPhishingEmailObject,
    setDigitalIncidentLocalizedPhishingCallOrTextObject,
    setLocalizedUnauthorizedAccessOrHackObject,
    setLocalizedSelectedDigitalIncidentTypeNameL1,
    setLocalizedSelectedDigitalIncidentTypeNameL2,
    setDigitalIncidentLocalizedTechnicalSupportScamObject,
    setTechnicalSupportScamObject
} = DigitalIncidentSlice.actions;

export default DigitalIncidentSlice.reducer;
//export const DigitalIncidentSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident
export const DigitalIncidentAttachmentSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.attachedFiles;
export const DigitalIncidentInformationSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation;
export const IsFormValidSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.isFormValid;
export const SelectedDigitalIncidentSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.incidentTypeId;
export const SelectedDigitalIncidentTypeL1Selector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentTypes.selectedIncidentTypeL1;
export const SelectedDigitalIncidentTypeL2Selector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentTypes.selectedIncidentTypeL2;
export const SelectedDigitalIncidentTimeZoneSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.incidentTimeZone;
export const ValidationErrorCountSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.validationErrorCount;
export const ValidationErrorSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.validationError;
export const DigitalIncidentTypesSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentTypes;
export const IsOutlookButtonAvailableSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentTypes.isOutlookButtonAvailable;
export const MalwareSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentMalware;
export const PhishingCallOrTextSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText;
export const PhishingEmailSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail;
export const ExposureOfSensitiveInfoSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation;
export const ExposureOfPersonalDataSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData;
export const ErrorStatesSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.errorStates;
export const SuspiciousOrMaliciousWebsiteSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite;
export const UnauthorizedAccessOrHackSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack;
export const DigitalIncidentSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident;
export const DigitalIncidentTypesVisibilitySelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.isIncidentTypesPanelOpen;
export const DigitalIncidentTimeChangedSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.isIncidentTimeChanged;
export const IsTestDigitalIncidentSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.isTestIncident;
export const LocalizedPhishingEmailInputSelector = (state:{DigitalIncident:DigitalIncidentModel})=>state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingEmail;
export const LocalizedPhishingCallOrTextSelector = (state:{DigitalIncident:DigitalIncidentModel})=>state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityIncidentPhishingCallorText;
export const LocalizedUnauthorizedAccessSuspiciousLoginSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin;
export const LocalizedUnauthorizedAccessUnknownDeviceSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled;
export const LocalizedUnauthorizedAccessUnInitiatedMFA = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA;
export const LocalizedUTechnicalSupportScamSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.digitalSecurityIncidentTechnicalSupportScam;
export const LocalizedSelectedIncidentTypeL1Selector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.incidentTypeL1Name;
export const LocalizedSelectedIncidentTypeL2Selector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentLocalizedInformation.incidentTypeL2Name;
export const TechnicalSupportScamSelector = (state: { DigitalIncident: DigitalIncidentModel }) => state.DigitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam;