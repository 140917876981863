import { takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";
import { setFlowConfigurationsLoading, setIsLoading } from "../Components/Loader/LoaderSlice";
import {
  ApplicationSettingsSelector,
  clearSimulationDetails,
  getApplicationSettings,
  getFlowConfigurations,
  getIncidentStatistics,
  getSimulationStatistics,
  setApplicationSettings,
  setApplicationSettingsApiStatus,
  setClearSimulationDetailsApiStatus,
  setFlowConfigurations,
  setIncidentStatistics,
  setIncidentStatisticsApiStatus,
  setSimulationStatistics,
  setSimulationStatisticsApiStatus,
  submitApplicationSettings,
  updateApplicationSettings,
  validateApplicationSettings,
  validateIncidentStatistics,
} from "../Components/ConfigurationsPanel/ApplicationSettingsSlice";
import { serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE } from "../Constants/CommonConstants";
import {
  ClearSimulationArchiveDetails,
  FetchApplicationSettings,
  FetchFlowConfigurations,
  FetchIncidentStatistics,
  FetchSimulationStatistics,
  SubmitApplicationSettings,
} from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import {
  ApplicationSettingsModel,
  ApplicationSettingsResponseViewModel,
  FlowConfigurations,
} from "../Contracts/TypesAndInterfaces/ApplicationSettingsResponseViewModel";
import { IncidentStatisticsResponseViewModel } from "../Contracts/TypesAndInterfaces/ApplicationStatisticsResponseViewModel";
import { SimulationStatisticsResponseViewModel } from "../Contracts/TypesAndInterfaces/SimulationStatisticsResponseViewModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { handleError } from "../Services/errorHandlerService";
import { GET, POST } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";
import { fetchAPIAccessToken } from "../Services/AuthService";

export function* FetchApplicationSettingsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    let accessToken: string = yield fetchAPIAccessToken();
    yield put(setIsLoading(true));
    var response: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/getapplicationsettings",
      accessToken
    );
    yield put(
      setApplicationSettings(
        response?.responseObject == null ||
          response?.responseObject === undefined
          ? []
          : (response?.responseObject as ApplicationSettingsResponseViewModel[])
      )
    );
    if (!response?.responseSuccess) {
      yield put(
        setApplicationSettingsApiStatus({
          statusCode: response?.responseCode,
          statusReceived: true,
        })
      );
    }
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchApplicationSettings,
      SOURCE,
      "GetApplicationSettings",
      "Fetch application settings",
      "FetchApplicationSettingsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* FetchIncidentStatisticsAsync({
  payload,
}: PayloadAction<{ startDate: Date; endDate: Date }>) {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(
      validateIncidentStatistics({
        startDate: payload.startDate,
        endDate: payload.endDate,
      })
    );
    var applicationSettingsModel: ApplicationSettingsModel = yield select(
      ApplicationSettingsSelector
    );
    let accessToken: string = yield fetchAPIAccessToken();
    if (applicationSettingsModel.isIncidentStatisticsDataValid) {
      yield put(setIsLoading(true));
      var response: WebResponseModel | undefined = yield GET<WebResponseModel>(
        serviceCommunicationSettings.apiParameters.apiBaseAddress,
        "/api/global/getincidentstatistics?startDate=" +
        payload.startDate.toDateString() +
        "&endDate=" +
        payload.endDate.toDateString(),
        accessToken
      );
      if (response?.responseSuccess) {
        yield put(
          setIncidentStatistics(
            response.responseObject as IncidentStatisticsResponseViewModel
          )
        );
        yield put(
          setIncidentStatisticsApiStatus({
            statusCode: response?.responseCode,
            statusReceived: true,
          })
        );
      } else if (!response?.responseSuccess) {
        yield put(
          setIncidentStatisticsApiStatus({
            statusCode: response?.responseCode,
            statusReceived: true,
          })
        );
      }
      yield put(setIsLoading(false));
    }
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchIncidentStatistics,
      SOURCE,
      "GetIncidentStatistics",
      "Fetch incident statistics",
      "FetchIncidentStatisticsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* SubmitApplicationSettingsAsync({
  payload,
}: PayloadAction<ApplicationSettingsResponseViewModel[]>) {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(validateApplicationSettings(payload));
    const applicationSettingsModel: ApplicationSettingsModel = yield select(
      ApplicationSettingsSelector
    );
    let accessToken: string = yield fetchAPIAccessToken();
    if (applicationSettingsModel.isApplicationSettingsValid) {
      yield put(setIsLoading(true));
      yield put(updateApplicationSettings(payload));
      var response: WebResponseModel | undefined = yield POST<
        ApplicationSettingsResponseViewModel[]
      >(
        serviceCommunicationSettings.apiParameters.apiBaseAddress,
        "/api/global/submitapplicationsettings",
        payload,
        accessToken
      );
      if (response !== undefined && response != null) {
        yield put(
          setApplicationSettingsApiStatus({
            statusCode: response?.responseCode,
            statusReceived: true,
          })
        );
      } else {
        yield put(
          setApplicationSettingsApiStatus({
            statusCode: undefined,
            statusReceived: true,
          })
        );
      }
      yield put(setIsLoading(false));
    }
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      SubmitApplicationSettings,
      SOURCE,
      "SubmitApplicationSettings",
      "Submit application settings",
      "SubmitApplicationSettingsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* FetchSimulationStatisticsAsync({
  payload,
}: PayloadAction<{ startDate: Date; endDate: Date }>) {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(
      validateIncidentStatistics({
        startDate: payload.startDate,
        endDate: payload.endDate,
      })
    );
    var applicationSettingsModel: ApplicationSettingsModel = yield select(
      ApplicationSettingsSelector
    );
    let accessToken: string = yield fetchAPIAccessToken();
    if (applicationSettingsModel.isIncidentStatisticsDataValid) {
      yield put(setIsLoading(true));
      var response: WebResponseModel | undefined = yield GET<WebResponseModel>(
        serviceCommunicationSettings.apiParameters.apiBaseAddress,
        "/api/global/getsimulationstatistics?startDate=" +
        payload.startDate.toDateString() +
        "&endDate=" +
        payload.endDate.toDateString(),
        accessToken
      );
      if (response?.responseSuccess) {
        yield put(
          setSimulationStatistics(
            response.responseObject as SimulationStatisticsResponseViewModel[]
          )
        );
        yield put(
          setSimulationStatisticsApiStatus({
            statusCode: response?.responseCode,
            statusReceived: true,
          })
        );
      } else if (!response?.responseSuccess) {
        yield put(
          setSimulationStatisticsApiStatus({
            statusCode: response?.responseCode,
            statusReceived: true,
          })
        );
      }

      yield put(setIsLoading(false));
    }
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchSimulationStatistics,
      SOURCE,
      "GetSimulationStatistics",
      "Fetch simulation statistics",
      "FetchSimulationStatisticsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* ClearSimulationDetailsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setIsLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var response: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/clearsimulationdetails",
      accessToken
    );
    if (response?.responseSuccess) {
      yield put(
        setClearSimulationDetailsApiStatus({
          statusCode: response?.responseCode,
          statusReceived: true,
        })
      );
    } else if (!response?.responseSuccess) {
      yield put(
        setClearSimulationDetailsApiStatus({
          statusCode: response?.responseCode,
          statusReceived: true,
        })
      );
    }
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      ClearSimulationArchiveDetails,
      SOURCE,
      "ClearSimulationUserDetailsFromArchive",
      "Clear simulation user details from archive",
      "ClearSimulationDetailsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* FetchFlowConfigurationsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setFlowConfigurationsLoading(true));

    let accessToken:string = yield fetchAPIAccessToken();

    var response: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/getFlowConfigurations",
       accessToken
    );
    if (response?.responseSuccess) {
      yield put(
        setFlowConfigurations(
          response.responseObject as FlowConfigurations
        )
      );
     
    } 

    yield put(setFlowConfigurationsLoading(false));
  }

  catch (e) {
    yield put(setFlowConfigurationsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchFlowConfigurations,
      SOURCE,
      "GetFlowConfigurations",
      "Fetch Flow configurations",
      "FetchFlowConfigurationsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}



export function* watchSubmitApplicationSettings() {
  yield takeEvery(submitApplicationSettings, SubmitApplicationSettingsAsync);
}

export function* watchGetApplicationSettings() {
  yield takeEvery(getApplicationSettings, FetchApplicationSettingsAsync);
}

export function* watchGetApplicationStatistics() {
  yield takeEvery(getIncidentStatistics, FetchIncidentStatisticsAsync);
}

export function* watchGetSimulationStatistics() {
  yield takeEvery(getSimulationStatistics, FetchSimulationStatisticsAsync);
}

export function* watchClearSimulationDetails() {
  yield takeEvery(clearSimulationDetails, ClearSimulationDetailsAsync);
}
export function* watchGetFlowConfigurations() {
  yield takeEvery(getFlowConfigurations, FetchFlowConfigurationsAsync);
}
