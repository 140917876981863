import * as React from "react";
import { CoherenceHeader, CoherencePanelSize, initializeOBFeedback } from "@coherence-design-system/controls";
import { helpFooter, HelpPanel } from "../HelpPanel/HelpPanel";
import { useDispatch, useSelector } from "react-redux";
import { ProfilePanel } from "../ProfilePanel/ProfilePanel";
import { ClearObjectInSessionStorage } from "../../Services/sessionService";
import { SERVICE_COMMUNICATION_SETTINGS } from "../../Constants/SessionConstants";
import { UserProfileSelector } from "../UserProfile/UserProfileSlice";
import { ContextualMenuItemType, DefaultButton, PrimaryButton } from "@fluentui/react";
import { L2Panel } from "../L2Panel/L2Panel";
import { PhishingCampaignPanel } from "../DigitalSecurity/PhishingCampaignPanel/PhishingCampaignPanel";
import { PanelEnums } from "../../Contracts/Enums/PanelEnums";
import { PanelBase } from "@fluentui/react";
import { OcvAppIdSelector, OcvEnvNameSelector } from "../UserProfile/ServiceCommunicationSettingsSlice";
import { ConfigurationsPanel } from "../ConfigurationsPanel/ConfigurationsPanel";
import { useBoolean } from "../../Hooks/useBoolean";
import { HelpPanelVisibilitySelector, setHelpPanelVisibility } from "../HelpPanel/HelpSectionSlice";
import { getLocalizedValue } from "../../Services/localizationService";

const buttonStyles = { root: { marginRight: 8 } };

export default function Header() {
    const dispatch = useDispatch();
    const userProfileDetails = useSelector(UserProfileSelector);
    const ocvAppId = useSelector(OcvAppIdSelector);
    const ocvEnvName = useSelector(OcvEnvNameSelector);
    const [isConfigurationsPanelOpen, { setTrue: openConfigurationsPanel, setFalse: dismissConfigurationsPanel }] = useBoolean(false);
    const [
        isProfileOpen,
        { setTrue: openProfilePanel, setFalse: dismissProfilePanel },
    ] = useBoolean(false);
    const openHelpPanel = () => {
        dispatch(setHelpPanelVisibility(true));
    }
    const dismissHelpPanel = () => {
        dispatch(setHelpPanelVisibility(false));
    }
    const isHelpOpen = useSelector(HelpPanelVisibilitySelector);
    const [
        isL2PanelOpen,
        { setTrue: openL2Panel, setFalse: dismissL2Panel },
    ] = useBoolean(false);
    const [
        isPhisingCampaignPanelOpen,
        {
            setTrue: openPhisingCampaignPanel,
            setFalse: dismissPhisingCampaignPanel,
        },
    ] = useBoolean(false);

    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [moreInfoUrl, setMoreInfoUrl] = React.useState("");
    const [linkId, setLinkId] = React.useState<string | undefined>();
    const [L2OpenedSource, setL2OpenedSource] = React.useState(0);
    const [helpClickCaptured, setHelpClickCaptured] = React.useState(false);
    const [profileClickCaptured, setProfileClickCaptured] = React.useState(false);

    const [isOCVButtonFocused, setIsOCVButtonFocused] = React.useState(false);



    React.useEffect(() => {
        // to enable tooltip and to focus on dismiss of panels
        document.querySelector("button[value='Help']")?.setAttribute("id", "helpButtonId");
        document.querySelector("button[value='Help']")?.setAttribute("title", getLocalizedValue("RIN_Help"));
        document.querySelector("button[aria-label='Profile']")?.setAttribute("id", "profileButtonId");
        document.querySelector("button[aria-label='Feedback']")?.setAttribute("title", getLocalizedValue("RIN_Feedback"));

        return () => { };

    }, []);

    

    React.useEffect(() => {
        if (ocvAppId > 0 && ocvEnvName.length > 0) {
            initializeOBFeedback(
                ocvAppId,
                ocvEnvName,
                "/officebrowserfeedback.min.js",
                "/officebrowserfeedback.min.css",
                "/intl/",
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (error: string) => {
                    setIsOCVButtonFocused(false);
                },
                undefined,
                undefined,
                undefined,
                undefined,
                (submitted: boolean) => {
                    setIsOCVButtonFocused(false);
                },
                undefined,
                undefined
            );
        }
    }, [ocvAppId, ocvEnvName])

    const handleHelpClickCapture = (event: React.MouseEvent<PanelBase, MouseEvent>) => {
        let element = (event.target as Element);
        let buttonElement = element.closest("#helpButtonId");
        if (buttonElement) {
            setHelpClickCaptured(true);
            dismissHelpPanel();
        }
    }

    const handleOuterClick = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) {
            let element = (event.target as Element);
            let buttonElement = element.closest("#helpButtonId");
            if (buttonElement) {
                dismissHelpPanel();
            }
        }
    }


    const handleHelpKeyDown = (event: React.KeyboardEvent<PanelBase>) => {
        // 13 is the keyCode for enter key
        if (event.keyCode === 13) {
            let element = (event.target as Element);
            let tagName = element.tagName;
            if (tagName.toLowerCase() == "button" && element.getAttribute("id") !== "helpCloseButtonId") {
                dismissHelpPanel();
            }
        }
        // 27 is the keyCode for esc key
        else if (event.keyCode === 27) {
            dismissHelpPanel();
        }
    }

    const handleProfileKeyDown = (event: React.KeyboardEvent<PanelBase>) => {
        // 13 is the keyCode for enter key
        if (event.keyCode === 13) {
            let element = (event.target as Element);
            let tagName = element.tagName;
            if (tagName.toLowerCase() == "button" && element.getAttribute("id") !== "profileCloseButtonId") {
                dismissProfilePanel();
            }
        }
        // 27 is the keyCode for esc key
        else if (event.keyCode === 27) {
            dismissProfilePanel();
        }
    }

    const handleProfileClickCapture = (event: React.MouseEvent<PanelBase, MouseEvent>) => {
        setProfileClickCaptured(true);
        let element = (event.target as Element);
        let offsetParenthtmlElement = (element as HTMLElement).offsetParent;
        if (offsetParenthtmlElement != null) {
            let tagName = offsetParenthtmlElement.tagName;
            if (tagName.toLowerCase() == "button") {
                dismissProfilePanel();
            }
        }
    }

    return (
        <div id="headerId" tabIndex={1} style={{ width: "100%" }}>
            <CoherenceHeader
                headerLabel={"header"}
                appNameSettings={{
                    label: getLocalizedValue("RIN_Title"),
                }}
                styles={{
                    skipToMainLinkStyles: {
                        zIndex: 1000,
                        color: "#FFFFFF !important",
                        backgroundColor: "#0078D4 !important"
                    }
                }}
                farRightSettings={{
                    feedbackSettings: {
                        panelSettings: {
                            ocvButtonIsFocused: isOCVButtonFocused,
                            onClick: () => {
                                setIsOCVButtonFocused(true);
                                return true;
                            },
                            launchOptions: {
                                categories: {
                                    show: true,
                                    customCategories: ["Content", "Experience", "Vision", "Hearing", "Mobility"],
                                },
                            },
                        },
                    },
                    helpSettings: {
                        panelSettings: {
                            titleText:getLocalizedValue("RIN_Help"),
                            body: (
                                <HelpPanel
                                    openL2Panel={openL2Panel}
                                    dismissPanel={dismissHelpPanel}
                                    setTitle={setTitle}
                                    setDescription={setDescription}
                                    setMoreInfoUrl={setMoreInfoUrl}
                                    setLinkId={setLinkId}
                                    linkIdToFocus={linkId}
                                    setL2OpenedSource={setL2OpenedSource}
                                />
                            ),
                            onRenderFooter: () => helpFooter(dismissHelpPanel, setLinkId),
                            isFooterAtBottom: true,
                            onKeyDown: handleHelpKeyDown,
                            isOpen: isHelpOpen,
                            onClick: () => {
                                if (!isHelpOpen && !helpClickCaptured) {
                                    openHelpPanel();
                                }
                                setHelpClickCaptured(false);
                            },
                            onClickCapture: handleHelpClickCapture,
                            isLightDismiss: true,
                            onLightDismissClick: () => {
                                dismissHelpPanel();
                                document.getElementById("helpButtonId")?.click();
                            },
                            styles: {
                                header: {
                                    paddingTop: "32px"
                                },
                            },
                            onOuterClick: handleOuterClick,
                            //elementToFocusOnDismiss: document.getElementById("helpButtonId") ?? document.getElementById("coher-header-overflow-button-id") ?? undefined
                        }
                    },
                    profileSettings: {
                        panelSettings: {
                            titleText:getLocalizedValue("RIN_Profile"),
                            fullName:
                                userProfileDetails.displayName,
                            imageUrl:
                                userProfileDetails.pictureUrl,
                            emailAddress:
                                userProfileDetails.onPremisesUserPrincipalName,
                            logOutLink: "home/signout",
                            onLogOut: () =>
                                ClearObjectInSessionStorage(
                                    SERVICE_COMMUNICATION_SETTINGS
                                ),
                            isLightDismiss: true,
                            onLightDismissClick: () => {
                                dismissProfilePanel();
                                document.getElementById("profileButtonId")?.click();
                            },
                            body: <ProfilePanel
                                linkIdToFocus={linkId}
                                openL2Panel={openL2Panel}
                                dismissProfilePanel={dismissProfilePanel}
                                setLinkId={setLinkId}
                                openPhisingCampaignPanel={openPhisingCampaignPanel}
                                setTitle={setTitle} setMoreInfoUrl={setMoreInfoUrl}
                                setDescription={setDescription}
                                setL2OpenedSource={setL2OpenedSource}
                                openConfigurationsPanel={openConfigurationsPanel}
                            />,
                            onRenderFooter: () => (
                                <div>
                                    <PrimaryButton
                                        id="profileCloseButtonId"
                                        onClick={() => {
                                            dismissProfilePanel();
                                            setLinkId(undefined);
                                            document.getElementById("profileButtonId")?.click();
                                        }}
                                        styles={buttonStyles}>
                                        {getLocalizedValue("RIN_Close")}
                                    </PrimaryButton>
                                </div>),
                            isFooterAtBottom: true,
                            isOpen: isProfileOpen,
                            onKeyDown: handleProfileKeyDown,
                            onClick: () => {
                                if (!isProfileOpen && !profileClickCaptured) {
                                    openProfilePanel();
                                }
                                setProfileClickCaptured(false);
                            },
                            onClickCapture: handleProfileClickCapture,
                            styles: {
                                header: {
                                    paddingTop: "32px",
                                    marginBottom: "33px",
                                },
                                body: {
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%"
                                },
                            },
                            hideSignOut: true,
                            onOuterClick: dismissProfilePanel,
                            elementToFocusOnDismiss: document.getElementById("profileButtonId") ?? document.getElementById("coher-header-overflow-button-id") ?? undefined
                        }
                    },
                }}
            />
            {isL2PanelOpen &&
                <L2Panel
                    title={title}
                    description={description}
                    panelSize={CoherencePanelSize.small}
                    isOpen={isL2PanelOpen}
                    dismissPanel={() => {
                        dismissL2Panel();
                        if (L2OpenedSource == PanelEnums.Help) {
                            openHelpPanel();
                        }
                        else if (L2OpenedSource == PanelEnums.Profile) {
                            openProfilePanel();
                        }
                    }}
                    moreInfoUrl={moreInfoUrl}
                />
            }
            {isPhisingCampaignPanelOpen && <PhishingCampaignPanel isOpen={isPhisingCampaignPanelOpen} dismissPanel={() => { dismissPhisingCampaignPanel(); openProfilePanel(); }} allowedFileSize={1} />}
            {isConfigurationsPanelOpen &&
                <ConfigurationsPanel isOpen={isConfigurationsPanelOpen} dismissPanel={() => { dismissConfigurationsPanel(); openProfilePanel(); }} />
            }
        </div>
    );
}
