import { MessageBarType } from "@fluentui/react"
import { ValidationErrorCountSelector, ValidationErrorSelector } from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice"
import { showExceptionPage } from "../Components/ExceptionPage/exceptionPageSlice"
import { addOrUpdateMessage } from "../Components/Notifications/NotificationsSlice"
import { SOMETHING_WENT_WRONG_EXCEPTION } from "../Constants/ExceptionConstants"
import { ExceptionModel } from "../Contracts/ExceptionModels/ExceptionModel"
import { getLocalizedValue } from "./localizationService"
import { store } from "../Store/store"
import { trackException } from "./telemetryService"


export interface ErrorHandlerOptions {
    showErrorPage?: boolean;
    showErrorNotification?: boolean;
    errorNotofocationId?: string;
    errorMessageToDisplay?: string;
    errorPageHeader?: string;
    error?: any;
    occupyFullScreen?: boolean;
}

export function handleError(showErrorPage: boolean, showNotification: boolean, exceptionModel?: ExceptionModel) {
    if (showErrorPage) {
        store.dispatch(showExceptionPage({ errorMessageToDisplay: SOMETHING_WENT_WRONG_EXCEPTION.errorMessageToDisplay, errorPageHeader: SOMETHING_WENT_WRONG_EXCEPTION.errorPageHeader, occupyFullScreen: false }));
    }
    if (showNotification) {
        store.dispatch(addOrUpdateMessage({
            messageContent: getLocalizedValue("RIN_PhishingCampaignErrorMessage"),
            id: "form-submission-exception",
            messageType: MessageBarType.error
        }));
    }
    if (exceptionModel) {
        trackException(exceptionModel);
    }
}

export function setErrorRibbon()
{
  const errorMessage = ValidationErrorSelector(store.getState());
    const errorCount = ValidationErrorCountSelector(store.getState());
    store.dispatch(
      addOrUpdateMessage({
        messageContent:
          errorCount > 1
            ? "You have " + errorCount + " validation errors."
            : errorMessage,
        id: "validation-message",
        messageType: MessageBarType.error,
      })
    );
}