import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../Components/UserProfile/UserProfileSlice";
import { loadInitialData } from "../Services/initializationService";
import { graphRequest } from "./AuthConfig";
import "../index.css";
import { handleError } from "../Services/errorHandlerService";
import { LoaderSelector, setIsLoading } from "../Components/Loader/LoaderSlice";
import ExceptionPage from "../Components/ExceptionPage/ExceptionPage";
import Routing from "../Components/Routing/Routing";
import Loader from "../Components/Loader/Loader";
import { getLocalizedValue } from "../Services/localizationService";

export default function Auth() {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const graphTokenrequest = {
        ...graphRequest,
        account: accounts[0]
    };

    const fetchGraphToken = () => {
        dispatch(setIsLoading(true));
        instance.acquireTokenSilent(graphTokenrequest).then((response) => dispatch(getUserProfile(response.accessToken))).catch(function (error) {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required") {
                instance.acquireTokenRedirect(graphTokenrequest).then(
                    function () {
                        instance.acquireTokenSilent(graphTokenrequest).then((response) => dispatch(getUserProfile(response.accessToken))).catch(function () {
                            handleError(true, false, undefined);
                        })
                    }).catch(function () {
                        handleError(true, false, undefined);
                    });
            }
        });
        dispatch(setIsLoading(false));
    }

    React.useEffect(() => {
        if (isAuthenticated) {
            fetchGraphToken();
            loadInitialData();
        }
    }, [isAuthenticated])

    const errorMessageToDisplay:string = `${getLocalizedValue('RIN_ErrorMessage_Part1')} <b><a href='/' style='color:white' aria-label='Refresh page'>${getLocalizedValue("RIN_ErrorMessage_Part2")}</a></b> ${getLocalizedValue("RIN_ErrorMessage_Part3")} <b><a href='https://portal.microsofticm.com/imp/v3/incidents/create' aria-label='IcM'>" + ${getLocalizedValue("RIN_ErrorMessage_Part4")} </a></b> ${getLocalizedValue("RIN_ErrorMessage_Part5")} <b><a style='color:white' href='mailto: steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now'>steengind@microsoft.com</a></b>`
    const errComponent = (<ExceptionPage errorMessageToDisplay={errorMessageToDisplay} errorPageHeader={getLocalizedValue('RIN_ErrorPageHeader')} occupyFullScreen={true} />);
    
    const loaderDetails = useSelector(LoaderSelector);
    
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={()=> errComponent}>
            {loaderDetails.isLoading > 0 && <Loader />}
            <Routing />
        </MsalAuthenticationTemplate>
    );
}