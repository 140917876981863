import { IDropdownProps, IDropdownOption, Label, IRenderFunction } from "@fluentui/react";
import { Select, useId } from "@fluentui/react-components";
import React, { FormEvent } from "react";

export interface DropdownProps extends IDropdownProps {
    label: string;
    placeholder: string;
    selectedKey: string | number | undefined;
    onChange: (e: FormEvent<HTMLDivElement>, item: IDropdownOption | undefined) => void;
    options: IDropdownOption[];
    onRenderLabel: IRenderFunction<IDropdownProps>;
    // prop used to force rerender of the component. update the value of the prop to force rerender when there is no change in component state but needs to rerender the component
    updateToForceReRender?: number;
}

export function DropdownAdapter(props: DropdownProps) {
    const alternativeId = useId("dropdown-adapter");
    const id = props.id ?? alternativeId;
    const handleOptionSelection = (e, data) => {
        const selectedOption = props.options.find((option) => option.key == data.value);
        props.onChange(e, selectedOption);
    }

    return (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
            {props.onRenderLabel ? props.onRenderLabel(props) : <Label className={`${props.required ? "required-field-label" : ""}`} htmlFor={id}>{props.label}</Label>}
            <Select id={id} onChange={handleOptionSelection} aria-required={props.required} aria-describedby={props["aria-describedby"]}>
                <option value="" disabled selected>{props.placeholder}</option>
                {props.options?.map((option, index) => <>
                    <option key={`${index}-${props.updateToForceReRender}`} value={option.key} selected={props.selectedKey == option.key}>{option.text}</option>
                </>)}
            </Select>
        </div>
    );

}