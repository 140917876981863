import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RINStepperSteps } from "../../../Constants/CommonConstants";
import { StepperProps } from "./Stepperv2";

const initialState: StepperProps = {
  steps: RINStepperSteps,
  currentStepIndex: 0,
};

const StepperDetailsSlice = createSlice({
  name: "StepperDetails",
  initialState,
  reducers: {
    setStepperStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStepIndex = payload;
    },
  },
});
export const { setStepperStep } = StepperDetailsSlice.actions;
export default StepperDetailsSlice.reducer;
export const StepperSelector = (state: { StepperDetails: StepperProps }) =>
  state.StepperDetails;
