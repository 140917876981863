import {
    DefaultButton,
    IStyle,
    MessageBarType,
    PrimaryButton
} from "@fluentui/react";
import { useBoolean } from "../../../Hooks/useBoolean";
import path from "path";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DigitalEventTypes, SovereignDigitalEventTypes, SovereignDigitalUnauthorizedAccessL2EventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { DsDiFileAttachmentInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiFileAttachmentInputViewModel";
import { getLocalizedValue } from "../../../Services/localizationService";
import { primaryButtonStyles } from "../../../styles";
import { clearErrorStates, fileSelector, setFileValidationErrorState } from "../../FileUpload/FileUploadSlice";
import { setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { addOrUpdateMessage } from "../../Notifications/NotificationsSlice";
import { postSovereignDigitalFormInformation, postSoverignDigitalTechSupportScamFormInformation, sovereignSelectedIncidentTypeL1Selector, sovereignSelectedIncidentTypeL2Selector } from "../../Sovereign/SovereignSlice";
import IncidentInformationSection from "../IncidentInformation/IncidentInformation";
import IncidentTypeSection from "../IncidentType/IncidentType";
import { SimulationCampaignSelector } from "../IncidentType/SimulationCampaignSlice";
import {
    DigitalIncidentSelector,
    DigitalIncidentTypesSelector,
    IsOutlookButtonAvailableSelector,
    postDigitalIncidentFormInformation,
    resetDigitalIncidentInformation,
    SelectedDigitalIncidentTypeL1Selector,
    SelectedDigitalIncidentTypeL2Selector,
    setDigitalIncidentAttachedFiles,
    setIsOutlookButtonAvailable,
    setLocalizedSelectedDigitalIncidentTypeNameL1,
    setSelectedDigitalIncidentTypeL1,
    setTotalAttachmentSizeInKB,
    ValidationErrorCountSelector,
    ValidationErrorSelector
} from "./DigitalIncidentSlice";

export const buttonStyle: IStyle = {
    color: "var(--background-color)",
    borderRadius: "2px",
    border: "1px solid white"
}

export default function DigitalIncidentSection(props: any) {
    const fileDetails = useSelector(fileSelector);
    const selectedEventTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector)
    const selectedEventTypeId = selectedEventTypeL1?.typeId;
    const isOutlookButtonAvailable = useSelector(IsOutlookButtonAvailableSelector);
    const validationErrorCount = useSelector(ValidationErrorCountSelector);
    const validationError = useSelector(ValidationErrorSelector);
    const sovereignSelectedIncidenttype = useSelector(sovereignSelectedIncidentTypeL1Selector);
    const sovereignSelectedIncidenttypeL2 = useSelector(sovereignSelectedIncidentTypeL2Selector);

    const handleCancleButtonClicked = () => {
        dispatch(resetDigitalIncidentInformation(true));
        dispatch(setSelectedIncidentCategory(undefined));
        dispatch(setSelectedDigitalIncidentTypeL1(undefined));
        dispatch(setLocalizedSelectedDigitalIncidentTypeNameL1(undefined));
        dispatch(setIsOutlookButtonAvailable(true));
        document.getElementById("digitalSecurityId")?.focus();
        document.getElementById("mainRegion")?.scrollTo(0, 0)
    };
    const digitalIncidentData = useSelector(DigitalIncidentSelector);
    const setDigitalIncidentFileState = () => new Promise((resolve: any) => {
        var fileObjects: DsDiFileAttachmentInputViewModel[] = [];
        var totalSize = 0;

        if ((selectedEventTypeId == DigitalEventTypes.PhishingEMail || sovereignSelectedIncidenttype?.key == SovereignDigitalEventTypes.PhishingEMail) && (!fileDetails?.files || fileDetails?.files?.length == 0)) {
            dispatch(setFileValidationErrorState(getLocalizedValue("RIN_PhishingEmailError")));
        }

        const size = (fileDetails?.files?.reduce((p, c, i) => p + c.fileSize, 0) ?? 0);
        if (size > (2.8 * 1024 * 1024)) {
            dispatch(setFileValidationErrorState(getLocalizedValue("RIN_TotalAttachmentSizeExceeded")));
        }

        fileDetails.files.forEach(file => {
            fileObjects.push({
                fileContent: file.fileContent,
                fileSizeInKB: file.fileSize / 1024,
                extension: path.extname(file.fileName),
                name: file.fileName
            });
            totalSize += file.fileSize;
        });
        dispatch(setTotalAttachmentSizeInKB(totalSize / 1024));
        dispatch(setDigitalIncidentAttachedFiles(fileObjects));
        resolve();
    })

    const handleSubmitButtonClicked = () => {
        dispatch(clearErrorStates());
        setDigitalIncidentFileState().then(() => {
            if (selectedEventTypeL1?.isGovType && (selectedEventTypeL1?.govNationId == undefined || selectedEventTypeL1?.govNationId == null || selectedEventTypeL1?.govNationId == 0)) {
                dispatch(postSovereignDigitalFormInformation({digitalIncident: digitalIncidentData}));
            }
            else
                dispatch(postDigitalIncidentFormInformation());
        })
    };

    const dispatch = useDispatch();
    const date = new Date(new Date().toDateString());
    const simulationCampaign = useSelector(SimulationCampaignSelector).simulationCampaign;
    const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find(x => x.typeId === DigitalEventTypes.PhishingEMail);
    const phishingSimulation = simulationCampaign;
    const [isPhishingSimulation, setIsPhishingSimulation] = React.useState((phishingIncidentType !== undefined && phishingSimulation !== undefined) ? (phishingIncidentType.isSimulationEnabled && phishingSimulation.isActive && date <= new Date(phishingSimulation.endDate.toDateString()) && date >= new Date(phishingSimulation.startDate.toDateString())) : false)
    const selectedIncidentTypeL2 = useSelector(SelectedDigitalIncidentTypeL2Selector);

    React.useEffect(() => {
        setIsPhishingSimulation((phishingIncidentType !== undefined && phishingSimulation !== undefined) ? (phishingIncidentType.isSimulationEnabled && phishingSimulation.isActive && date <= new Date(phishingSimulation.endDate.toDateString()) && date >= new Date(phishingSimulation.startDate.toDateString())) : false)
    }, [phishingSimulation])

    const [
        isPhishingSimulationExperienceShown,
        {
            toggle: togglePhishingSimulationExperience,
            setTrue: showPhishingSimulationExperience,
            setFalse: hidePhishingSimulationExperience,
        },
    ] = useBoolean(
        isPhishingSimulation &&
        selectedEventTypeId === DigitalEventTypes.PhishingEMail
    );

    React.useEffect(() => {
        if (validationErrorCount == 0) return;
        if (validationErrorCount == 1) {
            dispatch(addOrUpdateMessage({
                id: "validation-message",
                messageContent: validationError,
                messageType: MessageBarType.error
            }));
        }
        else {
            dispatch(addOrUpdateMessage({
                id: "validation-message",
                messageContent: getLocalizedValue("RIN_YouHave")+" " + validationErrorCount + " "+getLocalizedValue("RIN_ValidationErrors"),
                messageType: MessageBarType.error
            }))
        }
    }, [validationError, validationErrorCount])

    let submissionButtons = (
        <div className="ms-Grid-row submission-buttons">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                <DefaultButton
                    onClick={handleSubmitButtonClicked}
                    styles={{
                        root: buttonStyle,
                        rootHovered: buttonStyle
                    }}
                >
                    {getLocalizedValue("RIN_Submit")}
                </DefaultButton>
                &nbsp;&nbsp;&nbsp;
                <PrimaryButton
                    styles={primaryButtonStyles}
                    onClick={handleCancleButtonClicked}
                >
                    {getLocalizedValue("RIN_Cancel")}
                </PrimaryButton>
            </div>
        </div>
    );
    if (!selectedEventTypeId || (selectedEventTypeId == DigitalEventTypes.GovRelated && !sovereignSelectedIncidenttype?.key) || (selectedEventTypeId === DigitalEventTypes.UnauthorizedAccess && selectedIncidentTypeL2 === undefined) || (sovereignSelectedIncidenttype?.key === SovereignDigitalEventTypes.UnauthorizedAccess && sovereignSelectedIncidenttypeL2 === undefined)) submissionButtons = <></>;

    if ((isOutlookButtonAvailable && ((selectedEventTypeId === DigitalEventTypes.PhishingEMail) || sovereignSelectedIncidenttype?.key === SovereignDigitalEventTypes.PhishingEMail))) {
        if (!isPhishingSimulationExperienceShown) {
            submissionButtons = (
                <>
                    <div className="ms-Grid-row submission-buttons">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                            <DefaultButton
                                styles={{
                                    root: buttonStyle,
                                    rootHovered: buttonStyle
                                }}
                                onClick={handleCancleButtonClicked}
                            >
                                {getLocalizedValue("RIN_Cancel")}
                            </DefaultButton>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </>
            );
        }
        else
        {
            submissionButtons=(<></>);
        }
    }



    return (
        <>
            <IncidentTypeSection {...{ isPhishingSimulation, showPhishingSimulationExperience, hidePhishingSimulationExperience }} />

            <IncidentInformationSection {...{
                isPhishingSimulationExperienceShown,
                hidePhishingSimulationExperience,
                image: phishingSimulation?.attachmentUrl,
                incidentTypeId: phishingSimulation?.incidentTypeId,
                simulationCampaignId: phishingSimulation?.campaignId,
            }} />


            {submissionButtons}
        </>
    );
}

