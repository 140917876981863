import * as React from "react";
import { Image } from "@fluentui/react";
import ErrorImage from "../../Assets/Error.svg";
import { useSelector } from "react-redux";
import { ExceptionPageModel, exceptionPageSelector } from "./exceptionPageSlice";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import Welcome from "../Welcome/Welcome";
import "./ExceptionPage.css"

export default function ExceptionPage({ errorMessageToDisplay, errorPageHeader, occupyFullScreen }: ExceptionPageModel) {
    const exceptionPageDetails = useSelector(exceptionPageSelector);
    const shouldErrorTakeUpFullPage = occupyFullScreen ?? exceptionPageDetails.occupyFullScreen ?? false;

    React.useEffect(() => {
        const css = ':root {--background-color: #0064BF;}';
        const style = document.createElement("style");
        const root = document.getElementById("root");
        root?.appendChild(style);
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
    }, [])

    return (
        <>
            {shouldErrorTakeUpFullPage && (
                <div className="exception-page">
                    <div className="ms-Grid grid-container error-container-welcome" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Welcome />
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid grid-container" dir="ltr" style={{ textAlign: "center" }} >
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg6 ms-lgPush3 ms-xl4 ms-xlPush4 ms-xxl4 ms-xxlPush4 ms-xxxl4 ms-xxxlPush4 block incident-container">
                                <Image
                                    src={ErrorImage}
                                    alt="an exception occurred."
                                    styles={{
                                        root: {
                                            marginTop: 24,
                                            marginBottom: 36,
                                        },
                                        image: {
                                            margin: "0 auto",
                                            width: "36%",
                                        },
                                    }}
                                />
                                <h2>
                                    <RichTextRenderer
                                        html={errorPageHeader ??
                                            (exceptionPageDetails.errorPageHeader?.length ?
                                                exceptionPageDetails.errorPageHeader :
                                                "Oops, looks like something went wrong :(")} />
                                </h2>
                                <p style={{ fontSize: 14, color: "#FFFFFF" }}>
                                    <RichTextRenderer html={errorMessageToDisplay ??
                                        (exceptionPageDetails.errorMessageToDisplay?.length ?
                                            exceptionPageDetails.errorMessageToDisplay :
                                            "Please try again later. If you continue experiencing problems please contact friendly name goes here")} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
            {!shouldErrorTakeUpFullPage && (
                <div className="ms-Grid grid-container" dir="ltr" style={{ textAlign: "center" }} >
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg6 ms-lgPush3 ms-xl4 ms-xlPush4 ms-xxl4 ms-xxlPush4 ms-xxxl4 ms-xxxlPush4 block incident-container">
                            <Image
                                src={ErrorImage}
                                alt="an exception occurred."
                                styles={{
                                    root: {
                                        marginTop: 24,
                                        marginBottom: 36,
                                    },
                                    image: {
                                        margin: "0 auto",
                                        width: "36%",
                                        marginTop: 52
                                    },
                                }}
                            />
                            <h2>
                                <RichTextRenderer
                                    html={errorPageHeader ??
                                        (exceptionPageDetails.errorPageHeader?.length ?
                                            exceptionPageDetails.errorPageHeader :
                                            "Oops, looks like something went wrong :(")} />
                            </h2>
                            <p style={{ fontSize: 14, color: "#FFFFFF" }}>
                                <RichTextRenderer html={errorMessageToDisplay ??
                                    (exceptionPageDetails.errorMessageToDisplay?.length ?
                                        exceptionPageDetails.errorMessageToDisplay :
                                        "Please try again later. If you continue experiencing problems please contact friendly name goes here")} />
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
