import { ChoiceGroup, IChoiceGroupOption, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsUnauthorizedUnknowDeviceLocalizedViewModel, DsUnauthorizedUnknowDeviceViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { ErrorStatesSelector, UnauthorizedAccessOrHackSelector, setUnauthorizedAccessOrHackObject, setUnauthorizedUnknownDeviceHostNameErrorMessage, setUnauthorizedUnknownDeviceHostName, setUnauthorizedUnknownDeviceIsNewDeviceEnrolled, setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage, setUnauthorizedUnknownDeviceEnrolledDeviceName, LocalizedUnauthorizedAccessUnknownDeviceSelector, setLocalizedUnauthorizedAccessOrHackObject } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { CustomLabel } from "./IncidentSpecificFieldsv2";


const EnrolledNewDeviceOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Yes"), value: "Yes" },
    { key: "2", text: getLocalizedValueV2("RIN_No"), value: "No" },
];


export default function UnauthorizedAccessUnknownDevice() {
    const dispatch = useDispatch();
    const unknownDevice_CalloputProps = {
        id: "unknowndevice-host-name-info",
        calloutDescription: "<div><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo1") + "</b></div><br><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo2") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo3") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo4") + " <b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo5") + "</b> " + getLocalizedValueV2("RIN_AffectedMachineNameInfo6") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo7") + "</li></ol><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo8") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo9") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo10") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo11") + "</li></ol><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo12") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo13") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo14") + " <b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo5") + "</b> " + getLocalizedValueV2("RIN_AffectedMachineNameInfo15") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo7") + "</li></ol></div>",
        moreInfoLink: undefined,
    };
    const [
        enrolledOption,
        setEnrolledOption,
    ] = React.useState<IChoiceGroupOption>();


    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const unauthorizedAccessDetails = useSelector(UnauthorizedAccessOrHackSelector);
    const [unknownDeviceObject, setUnknownDeviceObject] = React.useState<DsUnauthorizedUnknowDeviceViewModel>({
        hostName: "",
        enrolledDeviceName: "",
        isNewDeviceEnrolled: false
    });

    const defaultLocalizedUnauthorizedAccessDetails: DsUnauthorizedUnknowDeviceLocalizedViewModel = {
        hostName: "",
        enrolledDeviceName: "",
        isNewDeviceEnrolled: getLocalizedValueV2("RIN_No")
    }
    const localizedUnauthorizedAccessDetails: DsUnauthorizedUnknowDeviceLocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessUnknownDeviceSelector);


    useEffect(() => {
        if(unauthorizedAccessDetails?.unknownDeviceEnrolled) return;
        dispatch(setUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: unknownDeviceObject }));
        dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, ...defaultLocalizedUnauthorizedAccessDetails } }));
    }, [])

    const handleHostNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedUnknownDeviceHostName !== "") {
                dispatch(setUnauthorizedUnknownDeviceHostNameErrorMessage(""));
            }
            dispatch(setUnauthorizedUnknownDeviceHostName(newValue));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, hostName:newValue} as DsUnauthorizedUnknowDeviceLocalizedViewModel  }));
        }
    };

    const handleEnrolledOptionChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setEnrolledOption(option);
            dispatch(setUnauthorizedUnknownDeviceIsNewDeviceEnrolled(option.value === "Yes" ? true : false));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, isNewDeviceEnrolled:option.text} as DsUnauthorizedUnknowDeviceLocalizedViewModel  }));
        }
    };

    const hostNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.unauthorizedUnknownDeviceHostName} descriptionColor={'red'} iconName={"ErrorBadge"} />

    const handleEnrolledDeviceChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedUnknownDeviceEnrolledDeviceName !== "") {
                dispatch(setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage(""));
            }
            dispatch(setUnauthorizedUnknownDeviceEnrolledDeviceName(newValue));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, enrolledDeviceName:newValue} as DsUnauthorizedUnknowDeviceLocalizedViewModel  }));
        }
    };

    const enrolledDeviceDescription = (
        <FieldValidationDescription
            errorMessage={
                errorStatesDetails
                    .unauthorizedUnknownDeviceEnrolledDeviceName
            }
            descriptionColor={'red'}
            iconName={"ErrorBadge"}
        />
    );

    return (
        <>
            <div className="ms-Grid-row" style={{ marginBottom: '20px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="unknownDeviceHostNameId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                        aria-labelledby={unknownDevice_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...unknownDevice_CalloputProps} {...props} />}
                        value={unauthorizedAccessDetails?.unknownDeviceEnrolled?.hostName}
                        onChange={handleHostNameChange}
                        onRenderDescription={() => hostNameDescription}
                        styles={
                            errorStatesDetails.unauthorizedUnknownDeviceHostName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField label={getLocalizedValueV2("RIN_UnknownEnrolledRegisteredDeviceName")}
                        id="unknownDeviceEnrolledDeviceNameId"
                        value={unauthorizedAccessDetails?.unknownDeviceEnrolled?.enrolledDeviceName}
                        onChange={handleEnrolledDeviceChange}
                        required
                        aria-required={true}
                        onRenderDescription={() => enrolledDeviceDescription}
                        styles={
                            errorStatesDetails
                                .unauthorizedUnknownDeviceEnrolledDeviceName.length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        } />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={EnrolledNewDeviceOptions}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion3")}
                        selectedKey={EnrolledNewDeviceOptions.find(x=>x.value === (unauthorizedAccessDetails?.unknownDeviceEnrolled?.isNewDeviceEnrolled ? "Yes": "No"))?.key}
                        onChange={handleEnrolledOptionChange}
                        defaultSelectedKey="2"
                        styles={getRadioButtonStyle(128)}
                    />
                </div>
            </div>
        </>
    );
}