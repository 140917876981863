import * as React from "react";
import {
    Dropdown,
    IconButton,
    IDropdownOption,
    Label,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { PhysicalIncidentTypesPanel } from "../PhysicalIncidentTypesPanel/PhysicalIncidentTypesPanel";
import { getPsIncidentTypesL2, PhysicalIncidentTypesPanelVisibilitySelector, PsIncidentTypesSelector, PsResetLevel, PsShowAdditionalInfoSelector, resetPhysicalIncidentInformation, setPhysicalIncidentPanelVisibility, setPsLocalizedSelectedIncidentTypeNameL1, setPsLocalizedSelectedIncidentTypeNameL2, setPsLocalizedSelectedIncidentTypeNameL3, setPsLocalizedSelectedIncidentTypeNameL4, setPsSelectedIncidentTypeIdL1, setPsSelectedIncidentTypeIdL2, setPsSelectedIncidentTypeIdL3, setPsSelectedIncidentTypeIdL4 } from "../PhysicalIncident/PhysicalIncidentSlice";
import { IncidentTypeChange } from "../../LeaveIncident/IncidentTypeChange";
import styles from './IncidentType.module.css'
import { HelpSectionSelector, setHelpPanelVisibility } from "../../HelpPanel/HelpSectionSlice";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { SovereignSelectionComponent } from "../../Sovereign/Sovereign";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { selectedSovereignSelector } from "../../Sovereign/SovereignSlice";
import { useBoolean } from "../../../Hooks/useBoolean";
import { getLocalizedValue } from "../../../Services/localizationService";
import { VSOCNumberWhite } from "../../../Constants/CommonConstants";

const iconProps = { iconName: "Unknown" };

export default function PhysicalIncidentType(props: any) {
    const dispatch = useDispatch();
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const immediateAssistanceSection = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.find(s => s.panelId === PanelEnums.PhysicalIncidentForm) : undefined;
    const immediateAssitanceLink = immediateAssistanceSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === immediateAssistanceSection.sectionId);
    const selectedSovereignId = useSelector(selectedSovereignSelector)?.nationId;
    const isPanelOpen = useSelector(PhysicalIncidentTypesPanelVisibilitySelector);
    const PsIncidentTypes = useSelector(PsIncidentTypesSelector);
    const isAdditionalInfoShown = useSelector(PsShowAdditionalInfoSelector);

    const PsIncidentTypesL1 = PsIncidentTypes.incidentTypesL1;
    const PsSelectedIncidentTypIdL1 = PsIncidentTypes.selectedincidentTypeIdL1;
    const [incidentTypesL1, setIncidentTypesL1] = React.useState<IDropdownOption[]>([]);

    const PsIncidentTypesL2 = PsIncidentTypes.incidentTypesL2;
    const PsSelectedIncidentTypIdL2 = PsIncidentTypes.selectedincidentTypeIdL2;
    const [incidentTypesL2, setIncidentTypesL2] = React.useState<IDropdownOption[]>([]);

    const PsIncidentTypesL3 = PsIncidentTypes.incidentTypesL3;
    const PsSelectedIncidentTypIdL3 = PsIncidentTypes.selectedincidentTypeIdL3;
    const [incidentTypesL3, setIncidentTypesL3] = React.useState<IDropdownOption[]>([]);

    const PsIncidentTypesL4 = PsIncidentTypes.incidentTypesL4;
    const PsSelectedIncidentTypIdL4 = PsIncidentTypes.selectedincidentTypeIdL4;
    const [incidentTypesL4, setIncidentTypesL4] = React.useState<IDropdownOption[]>([]);

    const [selectedL2IncidentName, setSelectedL2IncidentName] = React.useState<string>();

    const [isIncidentTypeChangeDialogShown, setIsIncidentTypeChangeDialogShown] = React.useState(false);

    const hideIncidentTypeChangeDialog = () => {
        setIsIncidentTypeChangeDialogShown(false);
        document.getElementById("physicalIncidenceDropdownId")?.focus();
    };
    const [toBeSelectedIncidentType, setToBeSelectedIncidentType] = React.useState<IDropdownOption>();
    const [toBeSelectedIncidentLevel, setToBeSelectedIncidentLevel] = React.useState<PsResetLevel>();

    React.useEffect(() => {
        setIncidentTypesL1(PsIncidentTypesL1?.map(x => {
            return { key: x.typeId, text: getLocalizedValue(x.nameKey), data: getLocalizedValue(x.displayLabelKey), id: x.perspectiveDbId }
        }))
    }, [PsIncidentTypesL1])

    React.useEffect(() => {
        setIncidentTypesL2(PsIncidentTypesL2?.map(x => {
            return { key: x.typeId, text: getLocalizedValue(x.nameKey), data: getLocalizedValue(x.displayLabelKey), id: x.perspectiveDbId }
        }))
    }, [PsIncidentTypesL2])

    React.useEffect(() => {
        setIncidentTypesL3(PsIncidentTypesL3?.map(x => {
            return { key: x.typeId, text: getLocalizedValue(x.nameKey), data: getLocalizedValue(x.displayLabelKey), id: x.perspectiveDbId }
        }))
    }, [PsIncidentTypesL3])

    React.useEffect(() => {
        setIncidentTypesL4(PsIncidentTypesL4?.map(x => {
            return { key: x.typeId, text: getLocalizedValue(x.nameKey), data: getLocalizedValue(x.displayLabelKey), id: x.perspectiveDbId }
        }))
    }, [PsIncidentTypesL4])

    const handleIncidentTypeChange = (props: any) => {
        const { option, level }: { option: IDropdownOption, level: PsResetLevel } = props;
        if (option) {
            if (level == PsResetLevel.IncidentTypeL1Changed) {
                dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
                dispatch(setPsSelectedIncidentTypeIdL1({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "" }));
                dispatch(setPsLocalizedSelectedIncidentTypeNameL1(option.text));
            }
            else if (level == PsResetLevel.IncidentTypeL2Changed) {
                dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL2Changed));
                dispatch(setPsSelectedIncidentTypeIdL2({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "", selectedSovereignId: selectedSovereignId }));
                dispatch(setPsLocalizedSelectedIncidentTypeNameL2(option.text));
                setSelectedL2IncidentName(option?.text);
            }
            else if (level == PsResetLevel.IncidentTypeL3Changed) {
                if (PsSelectedIncidentTypIdL3 !== undefined) {
                    dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL3Changed));
                }
                dispatch(setPsSelectedIncidentTypeIdL3({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "", selectedSovereignId: selectedSovereignId }));
                dispatch(setPsLocalizedSelectedIncidentTypeNameL3(option.text));
            }
            else if (level == PsResetLevel.IncidentTypeL4Changed) {
                dispatch(setPsSelectedIncidentTypeIdL4({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "", selectedSovereignId: selectedSovereignId }));
                dispatch(setPsLocalizedSelectedIncidentTypeNameL4(option.text));
            }
            else if (level == PsResetLevel.SovereignIncidentTypeL1Changed) {
                dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
                dispatch(getPsIncidentTypesL2({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "", selectedSovereignId: selectedSovereignId }));
            }
        }
    }

    const handleIncidentTypeSelected = (incidentTypeId: number | undefined) => {
        const option = incidentTypesL1.find(x => x?.key == incidentTypeId);
        if ((!PsSelectedIncidentTypIdL1 || !isAdditionalInfoShown) && option) {
            if (helpSectionDetails.isPanelOpen) {
                dispatch(setHelpPanelVisibility(false));
                document.getElementById("helpButtonId")?.click();
                dispatch(setPhysicalIncidentPanelVisibility(false));
            }
            handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL1Changed });
        }
        else if (option && incidentTypeId != PsSelectedIncidentTypIdL1?.id) {
            setToBeSelectedIncidentType(option);
            setToBeSelectedIncidentLevel(PsResetLevel.IncidentTypeL1Changed)
            setIsIncidentTypeChangeDialogShown(true);
        }
    }

    const rerenderDropdownOnNoIncidentChange = () => {
    }

    const handleIncidentTypeL1Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if ((!PsSelectedIncidentTypIdL1 || !isAdditionalInfoShown) && option) {
            handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL1Changed });
            document.getElementById("physicalIncidenceDropdownId")?.focus();
        }
        else if (option && option.key != PsSelectedIncidentTypIdL1?.id) {
            setToBeSelectedIncidentType(option);
            setToBeSelectedIncidentLevel(PsResetLevel.IncidentTypeL1Changed)
            setIsIncidentTypeChangeDialogShown(true);
        }
    }

    const handleSovereignIncidentTypeL1Change = (option?: IDropdownOption) => {
        handleIncidentTypeChange({ option: option, level: PsResetLevel.SovereignIncidentTypeL1Changed });
    }

    const handleIncidentTypeL2Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if ((!PsSelectedIncidentTypIdL2 || !isAdditionalInfoShown) && option) {
            handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL2Changed });
        }
        else if (option && option.key != PsSelectedIncidentTypIdL2?.id && isAdditionalInfoShown) {
            setToBeSelectedIncidentType(option);
            setToBeSelectedIncidentLevel(PsResetLevel.IncidentTypeL2Changed)
            setIsIncidentTypeChangeDialogShown(true);
        }
    }

    const handleIncidentTypeL3Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL3Changed });
    }

    const handleIncidentTypeL4Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL4Changed });
    }

    return (
        <div className="physical-incident-type-container">
            <div className="incident-container margin-bottom-32">
                <div className="ms-Grid-row section-header">
                    <div className={styles.heading}>
                        <h2 className={styles.headingh2}> {getLocalizedValue("RIN_SelectthePhysicalIncidentType")} </h2>
                        <IconButton
                            // id={iconButtonId}
                            iconProps={iconProps}
                            title={getLocalizedValue("RIN_Info")}
                            ariaLabel={getLocalizedValue("RIN_PhysicalIncidentCategoryInfo")}
                            onClick={() => dispatch(setPhysicalIncidentPanelVisibility(true))}
                            className="iconButton"
                        />
                    </div>
                </div>
                {immediateAssitanceLink !== undefined && (
                    <p style={{ marginTop: 0, fontSize: 14, color: "white" }}>
                        <RichTextRenderer html={getLocalizedValue(immediateAssitanceLink.descriptionKey)+VSOCNumberWhite} />
                    </p>
                )}
                {immediateAssitanceLink === undefined && (
                    <p style={{ marginTop: 0, fontSize: 14, color: "white" }}>
                        For immediate assistance call Microsoft Global Security,
                        Virtual Security Operations Center (VSOC): {" "}
                        <Label styles={{ root: { display: 'inline' } }}>
                            +1 425 706-0000
                        </Label>
                    </p>
                )}
                <div>
                    <Dropdown
                        id="physicalIncidenceDropdownId"
                        ariaLabel={getLocalizedValue("RIN_SelectPhysicalIncidentType")}
                        selectedKey={PsSelectedIncidentTypIdL1?.id}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={handleIncidentTypeL1Change}
                        placeholder={getLocalizedValue("RIN_SelectPhysicalSecurityEventType")}
                        options={incidentTypesL1}
                        styles={{
                            root: {
                                marginTop: "12px",
                            },
                            //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                        }}
                    />
                </div>
                {PsSelectedIncidentTypIdL1?.id !== undefined && PsSelectedIncidentTypIdL1.perspectiveDbId === null && (
                    <div>
                        <SovereignSelectionComponent IncidentCategory={IncidentCategories.Physical} triggerOnIncidentTypeSelection={handleSovereignIncidentTypeL1Change} />
                    </div>
                )}
                <div>
                    {
                        incidentTypesL2?.length > 0 &&
                        <Dropdown
                            ariaLabel={PsSelectedIncidentTypIdL1?.label}
                            label={PsSelectedIncidentTypIdL1?.label}
                            selectedKey={PsSelectedIncidentTypIdL2?.id}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={handleIncidentTypeL2Change}
                            placeholder={getLocalizedValue("Select incident type")}
                            options={incidentTypesL2}
                            styles={{
                                root: {
                                    marginTop: "16px",
                                    marginBottom: 32,
                                },
                                //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                            }}
                        />
                    }
                </div>

                {
                    PsIncidentTypesL3?.length > 0 &&

                    (
                        <div className="incident-container" style={{ marginBottom: 24 }}>
                            <div className="ms-Grid-row section-header">
                                <h2 className="sectionHeading">{selectedL2IncidentName} {getLocalizedValue("RIN_Definition")}</h2>
                            </div>
                            <div className="ms-Grid-row" style={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "baseline"
                            }}>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block" style={{ padding: "0 !important" }}>
                                    <Dropdown
                                        label={PsSelectedIncidentTypIdL2?.label}
                                        selectedKey={PsSelectedIncidentTypIdL3?.id}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={handleIncidentTypeL3Change}
                                        placeholder={getLocalizedValue("RIN_Select")}
                                        options={incidentTypesL3}
                                        styles={{
                                            root: {
                                                marginBottom: '16px'
                                            },
                                            //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                                        }}
                                    />
                                </div>
                                {
                                    incidentTypesL4?.length > 0 &&
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block" style={{ padding: "0 !important" }}>
                                        <Dropdown
                                            label={PsSelectedIncidentTypIdL3?.label}
                                            selectedKey={PsSelectedIncidentTypIdL4?.id}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onChange={handleIncidentTypeL4Change}
                                            placeholder={getLocalizedValue("RIN_Select")}
                                            options={incidentTypesL4}
                                            styles={{
                                                root: {
                                                    marginBottom: '16px'
                                                },
                                                //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                                            }}
                                        />
                                    </div>}
                            </div>
                        </div>
                    )
                }
            </div>
            {isPanelOpen &&
                <PhysicalIncidentTypesPanel
                    isOpen={isPanelOpen}
                    dismissPanel={() => dispatch(setPhysicalIncidentPanelVisibility(false))}
                    handleIncidentTypeSelected={handleIncidentTypeSelected}
                />
            }
            {
                isIncidentTypeChangeDialogShown &&
                <IncidentTypeChange isHidden={!isIncidentTypeChangeDialogShown} dismissDialog={hideIncidentTypeChangeDialog} executeOnContinueProps={{ option: toBeSelectedIncidentType, level: toBeSelectedIncidentLevel }} executeOnContinue={handleIncidentTypeChange} executeOnCancle={rerenderDropdownOnNoIncidentChange} />
            }
        </div>
    );
}
