import * as React from "react";
import styles from "./ProfilePanel.module.css";
import LinkOutVector from "../../Assets/Vector.svg";
import { useDispatch, useSelector } from "react-redux";
import { HelpSectionSelector } from "../HelpPanel/HelpSectionSlice";
import { ProfileSections } from "../../Contracts/Enums/ProfileSectionEnums";
import { PanelEnums } from "../../Contracts/Enums/PanelEnums";
import { profileSections } from "../../Constants/ApiFailureConstants";
import { ApplicationSettingsSelector, getApplicationSettings } from "../ConfigurationsPanel/ApplicationSettingsSlice";
import { NewExperienceSelection } from "../Welcome/Welcome";
import { getLocalizedValue } from "../../Services/localizationService";

export interface ProfilePanelProps {
    openL2Panel: () => void;
    openPhisingCampaignPanel: () => void;
    setTitle: Function;
    setDescription: Function;
    setMoreInfoUrl: Function;
    setLinkId: Function;
    linkIdToFocus?: string;
    dismissProfilePanel: () => void;
    setL2OpenedSource: Function;
    openConfigurationsPanel: () => void;
}

export const ProfilePanel: React.FC<ProfilePanelProps> = ({
    openL2Panel,
    dismissProfilePanel,
    openPhisingCampaignPanel,
    setDescription,
    setMoreInfoUrl,
    setTitle,
    linkIdToFocus,
    setLinkId,
    setL2OpenedSource,
    openConfigurationsPanel
}) => {
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const applicationSettingsDetails = useSelector(ApplicationSettingsSelector);
    const dispatch = useDispatch();
    const aboutHelpSections = helpSectionDetails.helpSections.filter(s => s.panelId === PanelEnums.Profile);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const linkRef = React.useRef<HTMLAnchorElement>(null);
    React.useEffect(() => {
        if (linkIdToFocus !== undefined && linkRef.current != null) {
            linkRef.current.focus();
        }
    }, [])

    const handleL2PanelClick = (
        title: string,
        description: string,
        moreInfoUrl: string,
        linkId: number,
        sectionId: number
    ) => {
        setTitle(title);
        setDescription(description);
        setMoreInfoUrl(moreInfoUrl);
        setLinkId(sectionId.toString() + "-" + linkId.toString());
        setL2OpenedSource(PanelEnums.Profile);
    };

    const handleOpenConfigurationPanel = () => {
        if (applicationSettingsDetails.applicationSettings.length === 0) {
            dispatch(getApplicationSettings());
        }
        openConfigurationsPanel();
        dismissProfilePanel();
    }

    return (
        <div className={styles.moveToBottom}>
            <NewExperienceSelection />
            <div>
                {isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess && aboutHelpSections.length > 0 && aboutHelpSections.map((helpSection) => (
                    <>
                        <h2 className={styles.heading2}>{getLocalizedValue(helpSection.titleKey)}</h2>
                        {helpSectionDetails.helpSectionLinks
                            .filter((s) => s.sectionId === helpSection.sectionId)
                            .map((link) => link.openAsForm ?
                                <>
                                    {link.linkId == ProfileSections.SetPhishingCampaign &&
                                        <div className={styles.heading3}>
                                            <a role="button" className={styles.link} href='#' style={{ cursor: "pointer" }} onClick={() => { dismissProfilePanel(); setLinkId(helpSection.sectionId.toString() + "-" + link.linkId.toString()); openPhisingCampaignPanel(); }} aria-label={getLocalizedValue(link.titleKey)}>{getLocalizedValue(link.titleKey)}</a>
                                        </div>
                                    }
                                    {link.linkId == ProfileSections.SetConfiguration &&
                                        <div className={styles.heading3}>
                                            <a
                                                ref={(linkIdToFocus !== undefined && Number(linkIdToFocus.split("-")[0]) == helpSection.sectionId && Number(linkIdToFocus.split("-")[1]) === link.linkId) ? linkRef : undefined}
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                href='#'
                                                onClick={handleOpenConfigurationPanel}
                                                aria-label={getLocalizedValue(link.titleKey)}
                                            >
                                                {getLocalizedValue(link.titleKey)}
                                            </a>
                                        </div>
                                    }

                                </> : (
                                    <div className={styles.heading3}>
                                        {!link.openInNewTab ? (
                                            <a
                                                ref={(linkIdToFocus !== undefined && Number(linkIdToFocus.split("-")[0]) == helpSection.sectionId && Number(linkIdToFocus.split("-")[1]) === link.linkId) ? linkRef : undefined}
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                href='#'
                                                role="button"
                                                onClick={() => {
                                                    handleL2PanelClick(
                                                        getLocalizedValue(link.titleKey) ,
                                                        getLocalizedValue(link.descriptionKey) + " <a href=\"mailto:steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now\" style=\"color:#0064BF;\">steengind@microsoft.com</a>",
                                                        link.moreInfoUrl,
                                                        link.linkId,
                                                        helpSection.sectionId
                                                    );
                                                    dismissProfilePanel();
                                                    openL2Panel();
                                                }
                                                }
                                                aria-label={getLocalizedValue(link.titleKey)}
                                            >
                                                {getLocalizedValue(link.titleKey)}
                                            </a>
                                        ) : (
                                            <div>
                                                <a
                                                    className={styles.link}
                                                    href={link.moreInfoUrl}
                                                    aria-label={getLocalizedValue(link.titleKey) + ' '+ getLocalizedValue("RIN_OpenInNewWindow")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {getLocalizedValue(link.titleKey)}
                                                </a>
                                                <span aria-hidden={true} className={styles.linkOutVector}>
                                                    <img
                                                        src={LinkOutVector}
                                                        alt={getLocalizedValue("RIN_LinkOutIcon")}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                        <div className={styles.divider}></div>
                    </>
                ))}
                {(!isHelpSectionApiCallSuccess || !isHelpSectionLinksApiCallSuccess || aboutHelpSections.length === 0) && (
                    <>
                        {profileSections.map((section) => (
                            <>
                                <h2 className={styles.heading2}>{section.title}</h2>
                                {section.links.map((link) => (
                                    <div className={styles.heading3}>
                                        {link.openInNewTab && (
                                            <div>
                                                <a
                                                    className={styles.link}
                                                    href={link.url}
                                                    aria-label={link.title + ' '+ getLocalizedValue("RIN_OpenInNewWindow")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {link.title}
                                                </a>
                                                <span aria-hidden={true} className={styles.linkOutVector}>
                                                    <img
                                                        src={LinkOutVector}
                                                        alt={getLocalizedValue("RIN_LinkOutIcon")}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                        {link.openAsForm && (
                                            <a
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                href='#'
                                                role="button"
                                                onClick={() => {
                                                    handleL2PanelClick(
                                                        link.title,
                                                        link.description!,
                                                        link.url!,
                                                        link.id,
                                                        section.id
                                                    );
                                                    dismissProfilePanel();
                                                    openL2Panel();
                                                }
                                                }
                                                aria-label={link.title}
                                            >
                                                {link.title}
                                            </a>
                                        )}
                                    </div>
                                ))}
                                <div className={styles.divider}></div>
                            </>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}
