import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import { PhishingCampaignv2 } from "../RINv2/DigitalSecurity/PhishingCampaignv2/PhishingCampaignv2";
import ExceptionPagev2 from "../RINv2/ExceptionPagev2/ExceptionPagev2";
import { HelpV2 } from "../RINv2/Helpv2/HelpV2";
import Homev2 from "../RINv2/Homev2/Homev2";
import IncidentCategoryv2 from "../RINv2/IncidentCategoryv2/IncidentCategoryv2";
import { IncidentDetails } from "../RINv2/IncidentDetails/IncidentDetails";
import { IncidentFlow } from "../RINv2/IncidentFlow/IncidentFlow";
import { IncidentReview } from "../RINv2/IncidentReview/IncidentReview";
import { IncidentType1 } from "../RINv2/IncidentType/IncidentType1";
import { IncidentType2 } from "../RINv2/IncidentType/IncidentType2";
import Shellv2 from "../RINv2/Shellv2/Shellv2";
import Successv2 from "../RINv2/Successv2/Successv2";
import Shell from "../Shell/Shell";
import './Routing.css'
import { useSelector } from "react-redux";
import { DigitalIncidentTypesLoadingSelector, SimulationCampaignsLoadingSelector } from "../Loader/LoaderSlice";
import Loader from "../Loader/Loader";

export default function Routing() {
    const isSimulationCampaignDetailsLoading = useSelector(SimulationCampaignsLoadingSelector);
    const isDigitalIncidentTypesLoading = useSelector(DigitalIncidentTypesLoadingSelector);
    return (
        <div className="routing">
            <Routes>
                <Route path="/" element={<Shell />} >
                    <Route index element={<Home />} />
                    <Route path="*" element={<Home />} />
                </Route>
                <Route path="/v2" element={<Shellv2 />}>
                    <Route index element={<Homev2 />} />
                    <Route path="create" element={<IncidentFlow />}>
                        <Route index element={<IncidentCategoryv2 />} />
                        <Route path=":incidentCategory" element={<IncidentType1 />} />
                        <Route path=":incidentCategory/:incidentType" element={<IncidentType2 />} />
                        <Route path=":incidentCategory/:incidentType/details" element={<IncidentDetails />} />
                        <Route path=":incidentCategory/:incidentType/review" element={<IncidentReview />} />
                    </Route>
                    <Route path="success" element={<Successv2 />} />
                    <Route path="exception" element={<ExceptionPagev2 />} />
                    <Route path="help" element={<HelpV2 />} />
                    <Route path="phishingCampaign" element={isSimulationCampaignDetailsLoading || isDigitalIncidentTypesLoading ? <Loader /> : <PhishingCampaignv2 allowedFileSize={1} /> } />
                    <Route path="*" element={<Homev2 />} />
                </Route>
            </Routes>
        </div>
    );
}