import { TextField } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DsDiSuspiciousOrMaliciousWebsiteInputViewModel } from '../../../../Contracts/TypesAndInterfaces/DsDiSuspiciousOrMaliciousWebsiteInputViewModel';
import { getLocalizedValueV2 } from '../../../../Services/localizationServiceV2';
import { SuspiciousOrMaliciousWebsiteSelector, ErrorStatesSelector, setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineNameErrorMessage, setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineName, setDigitalIncidentSuspiciousOrMaliciousWebsiteURLErrorMessage, setDigitalIncidentSuspiciousOrMaliciousWebsiteURL, setDigitalIncidentSuspiciousOrMaliciousWebsitesObject } from '../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice';
import FieldValidationDescription from '../../../FieldValidationDescription/FieldValidationDescription';
import { CustomLabel } from './IncidentSpecificFieldsv2';

export function SuspiciousOrMaliciousWebsiteFields() {
    const affectedMachineName_CalloputProps = {
        id: "affected-machine-name-info",
        calloutDescription: "<div><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo1")+"</b></div><br><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo2")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo3")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo4")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo6")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo8")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo9")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo10")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo11")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo12")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo13")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo14")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo15")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol></div>",
        moreInfoLink: undefined,
    };
    const uRL_CalloputProps = {
        id: "affected-machine-name-info",
        calloutDescription: "<div>"+getLocalizedValueV2("RIN_URLInfo")+"</div>",
        moreInfoLink: undefined,
    };

    const dispatch = useDispatch();
    const suspiciousorMaliciousWebsitesSelector = useSelector(SuspiciousOrMaliciousWebsiteSelector);
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const affectedMachineNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.suspiciousOrMaliciousWebsiteAffectedMachineName} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const uRLDescription = <FieldValidationDescription errorMessage={errorStatesDetails.suspiciousOrMaliciousWebsiteURL} descriptionColor={'red'} iconName={"ErrorBadge"} />

    const handleAffectedMachineNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineNameErrorMessage(""));
            dispatch(setDigitalIncidentSuspiciousOrMaliciousWebsiteAffectedMachineName(newValue));
        }
    };

    const handleURLChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setDigitalIncidentSuspiciousOrMaliciousWebsiteURLErrorMessage(""));
            dispatch(setDigitalIncidentSuspiciousOrMaliciousWebsiteURL(newValue));
        }
    };

    React.useEffect(() => {
        if(suspiciousorMaliciousWebsitesSelector) return;
        dispatch(setDigitalIncidentSuspiciousOrMaliciousWebsitesObject({ affectedMachineName: "", uRL: ""} as DsDiSuspiciousOrMaliciousWebsiteInputViewModel));
    }, []);

    return (
        <div className='suspicious-or-malicious-website-fields'>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="SuspiciousWebsiteAffectedMachineNameId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_AffectedMachineName")}
                        aria-labelledby={affectedMachineName_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_AffectedMachineName")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...affectedMachineName_CalloputProps} {...props} />}
                        value={suspiciousorMaliciousWebsitesSelector?.affectedMachineName}
                        onChange={handleAffectedMachineNameChange}
                        onRenderDescription={() => affectedMachineNameDescription}
                        styles={
                            errorStatesDetails.suspiciousOrMaliciousWebsiteAffectedMachineName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="suspiciousWebsiteURLId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_URL")}
                        aria-labelledby={uRL_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_URL")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...uRL_CalloputProps} {...props} />}
                        value={suspiciousorMaliciousWebsitesSelector?.uRL}
                        onChange={handleURLChange}
                        onRenderDescription={() => uRLDescription}
                        styles={
                            errorStatesDetails.suspiciousOrMaliciousWebsiteURL.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
        </div>
    );
}
