import { AuthManager } from "../Auth/AuthManager";
import { handleError } from "./errorHandlerService";

const authManager: AuthManager = new AuthManager();

export const fetchAPIAccessToken = async (): Promise<string> => {
  let accessToken = "";
  try {
    accessToken = await authManager.getAPIAccessToken();
  } catch (error) {
    handleError(true, false, undefined);
  }
  return accessToken;
};
