import { ChoiceGroup, IChoiceGroupOption, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsUnauthorizedUnknowDeviceLocalizedViewModel, DsUnauthorizedUnknowDeviceViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { getLocalizedValue } from "../../../Services/localizationService";
import { getRadioButtonStyle } from "../../../styles";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { ErrorStatesSelector, LocalizedUnauthorizedAccessUnknownDeviceSelector, setLocalizedUnauthorizedAccessOrHackObject, setUnauthorizedAccessOrHackObject, setUnauthorizedUnknownDeviceEnrolledDeviceName, setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage, setUnauthorizedUnknownDeviceHostName, setUnauthorizedUnknownDeviceHostNameErrorMessage, setUnauthorizedUnknownDeviceIsNewDeviceEnrolled, UnauthorizedAccessOrHackSelector } from "../DigitalIncident/DigitalIncidentSlice";
import { CustomLabel } from "./IncidentSpecificFields";




export default function UnauthorizedAccessUnknownDevice() {
    const dispatch = useDispatch();
    const unknownDevice_CalloputProps = {
        id: "unknowndevice-host-name-info",
        calloutDescription: "<div><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo1")+"</b></div><br><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo2")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo3")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo4")+" <b>"+getLocalizedValue("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValue("RIN_AffectedMachineNameInfo6")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo7")+"</li></ol><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo8")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo9")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo10")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo11")+"</li></ol><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo12")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo13")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo14")+" <b>"+getLocalizedValue("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValue("RIN_AffectedMachineNameInfo15")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo7")+"</li></ol></div>",
        moreInfoLink: undefined,
    };
    const [
        enrolledOption,
        setEnrolledOption,
    ] = React.useState<IChoiceGroupOption>();


    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const unauthorizedAccessDetails = useSelector(UnauthorizedAccessOrHackSelector);
    const [unknownDeviceObject, setUnknownDeviceObject] = React.useState<DsUnauthorizedUnknowDeviceViewModel>({
        hostName: "",
        enrolledDeviceName: "",
        isNewDeviceEnrolled: false
    });

    const defaultLocalizedUnauthorizedAccessDetails: DsUnauthorizedUnknowDeviceLocalizedViewModel = {
        hostName: "",
        enrolledDeviceName: "",
        isNewDeviceEnrolled: getLocalizedValue("RIN_No")
    }
    const localizedUnauthorizedAccessDetails: DsUnauthorizedUnknowDeviceLocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessUnknownDeviceSelector);


    const EnrolledNewDeviceOptions: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Yes"), value:"Yes" },
            { key: "2", text: getLocalizedValue("RIN_No"), value:"No" },
    ];
    useEffect(() => {
        dispatch(setUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: unknownDeviceObject }));
        dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, ...defaultLocalizedUnauthorizedAccessDetails } }));
        return () => {
            dispatch(setUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: undefined }));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: undefined}));

        }
    }, [])

    const handleHostNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedUnknownDeviceHostName !== "") {
                dispatch(setUnauthorizedUnknownDeviceHostNameErrorMessage(""));
            }
            dispatch(setUnauthorizedUnknownDeviceHostName(newValue));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, enrolledDeviceName:newValue } as DsUnauthorizedUnknowDeviceLocalizedViewModel }));

        }
    };

    const handleEnrolledOptionChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setEnrolledOption(option);
            dispatch(setUnauthorizedUnknownDeviceIsNewDeviceEnrolled(option.value === "Yes" ? true : false));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ unknownDeviceEnrolled: { ...localizedUnauthorizedAccessDetails, isNewDeviceEnrolled:option.text } as DsUnauthorizedUnknowDeviceLocalizedViewModel }));

        }
    };

    const hostNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.unauthorizedUnknownDeviceHostName} descriptionColor={'white'} iconName={"ErrorBadge"} />

    const handleEnrolledDeviceChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedUnknownDeviceEnrolledDeviceName !== "") {
                dispatch(setUnauthorizedUnknownDeviceEnrolledDeviceNameErrorMessage(""));
            }
            dispatch(setUnauthorizedUnknownDeviceEnrolledDeviceName(newValue));
        }
    };

    const enrolledDeviceDescription = (
        <FieldValidationDescription
            errorMessage={
                errorStatesDetails
                    .unauthorizedUnknownDeviceEnrolledDeviceName
            }
            descriptionColor={'white'}
            iconName={"ErrorBadge"}
        />
    );

    return (
        <>
            <div className="ms-Grid-row" style={{ marginBottom: '20px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="unknownDeviceHostNameId"
                        aria-required={true}
                        ariaLabel={getLocalizedValue("RIN_UnauthorizedAcessHackQuestion1")}
                        aria-labelledby={unknownDevice_CalloputProps.id}
                        label={getLocalizedValue("RIN_UnauthorizedAcessHackQuestion1")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...unknownDevice_CalloputProps} {...props} />}
                        value={unauthorizedAccessDetails?.unknownDeviceEnrolled?.hostName}
                        onChange={handleHostNameChange}
                        onRenderDescription={() => hostNameDescription}
                        styles={
                            errorStatesDetails.unauthorizedUnknownDeviceHostName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField label={getLocalizedValue("RIN_UnknownEnrolledRegisteredDeviceName")}
                        id="unknownDeviceEnrolledDeviceNameId"
                        value={unauthorizedAccessDetails?.unknownDeviceEnrolled?.enrolledDeviceName}
                        onChange={handleEnrolledDeviceChange}
                        aria-required={true}
                        onRenderDescription={() => enrolledDeviceDescription}
                        styles={
                            errorStatesDetails
                                .unauthorizedUnknownDeviceEnrolledDeviceName.length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        } />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={EnrolledNewDeviceOptions}
                        label={getLocalizedValue("RIN_UnauthorizedAcessHackQuestion3")}
                        selectedKey={enrolledOption?.key}
                        onChange={handleEnrolledOptionChange}
                        defaultSelectedKey="2"
                        styles={getRadioButtonStyle(128)}
                    />
                </div>
            </div>
        </>
    );
}