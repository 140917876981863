import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from "@fluentui/react";
import { CoherenceCustomizations } from "@coherence-design-system/styles";
import { store } from "./Store/store"
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Auth/AuthConfig";
import Auth from "./Auth/Auth";
import { getLocaleFiles } from "./Services/localizationService";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import TelemetryProvider from "./Components/TelemetryProvider/TelemetryProvider";
import { createBrowserHistory } from "history";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

const msalInstance = new PublicClientApplication(msalConfig);
const history = createBrowserHistory();
getLocaleFiles();


ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <ThemeProvider {...CoherenceCustomizations}>
            <FluentProvider theme={webLightTheme} style={{lineHeight: "unset"}}>
                <Provider store={store}>
                    <BrowserRouter>
                        <TelemetryProvider instrumentationKey={process.env.REACT_APP_APP_INSIGHTS_KEY!} history={history}>
                            <Auth />
                        </TelemetryProvider>
                    </BrowserRouter>
                </Provider>
            </FluentProvider>
        </ThemeProvider>
    </MsalProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
