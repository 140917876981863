import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StepperSteps,
} from "../../../Constants/CommonConstants";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { IncidentCategorySelector } from "../../IncidentCategory/IncidentCategorySlice";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import { PhysicalIncidentTypesv2 } from "../PhysicalSecurity/PhysicalIncidentType/PhysicalIncidentTypev2";
import { DigitalInicdentTypesL2v2 } from "../DigitalSecurity/IncidentTypesL2v2/IncidentTypesL2v2";

export function IncidentType2() {
  const dispatch = useDispatch();
  const incidentCategoryDetails = useSelector(IncidentCategorySelector);
  const selectedIncidentCategoryId =
    incidentCategoryDetails.selectedIncidentCategory?.categoryId;

  React.useEffect(() => {
    dispatch(setStepperStep(StepperSteps.Type));
  }, []);
  return (
    <div className="incidentType2 site-margins">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
          {selectedIncidentCategoryId === IncidentCategories.Digital && (
            <DigitalInicdentTypesL2v2 />
          )}
          {selectedIncidentCategoryId === IncidentCategories.Physical && (
            <PhysicalIncidentTypesv2 />
          )}
        </div>
      </div>
    </div>
  );
}