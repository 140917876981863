export enum PanelEnums {
    IncidentCategories = 1,
    PhysicalIncidentTypes,
    DigitalIncidentTypes,
    Help,
    Feedback,
    Profile,
    PhysicalIncidentForm,
    DigitalIncidentForm,
    SiteMessages,
}