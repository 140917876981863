import * as React from "react";
import {
    Checkbox,
    Label,
    TextField,
} from "@fluentui/react";
import path from "path";
import { useDispatch, useSelector } from "react-redux";
import { PsAttachmentInputViewModel } from "../../../../Contracts/ServiceModels/PhysicalSecurity/PsAttachmentInputViewModel";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { fileSelector, clearFiles, setFileValidationErrorState, clearErrorStates } from "../../../FileUpload/FileUploadSlice";
import { renderDescriptionLabel } from "../../../Home/Home";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../../../IncidentCategory/IncidentCategorySlice";
import { removeMessage } from "../../../Notifications/NotificationsSlice";
import { PsShowAdditionalInfoSelector, PsErrorStatesSelector, physicalIncidentInformationSelector, resetPhysicalIncidentInformation, PsResetLevel, setAttachments, setReporterPhoneNumber, resetValidationMessagePhoneNumber, setSummaryNt, resetValidationMessageIncidentSummary, setPoliceFileNumberNv, resetValidationMessagePoliceFileNumber, setReportedToPoliceBt, validatePhysicalIncidentDataV2, PsSelectedincidentTypeIdL1Selector, PsIncidentTypesL1Selector } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { isSovereignIncidentSelector } from "../../../Sovereign/SovereignSlice";
import { useNavigate } from "react-router-dom";
import PhysicalIncidentLocationAndTimev2 from "../PhysicalIncidentLocationAndTime/PhysicalIncidentLocationAndTimev2";
import { DefaultButtonAdapter, PrimaryButtonAdapter } from "../../Controls";
import PhysicalIncidentAddItemv2 from "../PhysicalIncidentAddItem/PhysicalIncidentAddItemv2";
import PhysicalIncidentAddPersonv2 from "../PhysicalIncidentAddPerson/PhysicalIncidentAddPersonv2";
import PhysicalIncidentAddVehiclev2 from "../PhysicalIncidentAddVehicle/PhysicalIncidentAddVehiclev2";
import FileUploadv2 from "../../FileUploadv2/FileUploadv2";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { INCIDENT_DETAILS_STEP } from "../../../../Constants/InstrumentationConstants";
import { trackEvent } from "../../../../Services/telemetryService";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../../UserProfile/ServiceCommunicationSettingsSlice";

export function PhysicalIncidentDetailsv2() {
	const dispatch = useDispatch();
	const fileDetails = useSelector(fileSelector);
	const showAdditionalInfo = useSelector(PsShowAdditionalInfoSelector);

	const errorStates = useSelector(PsErrorStatesSelector);

	const PsIncidentInfo = useSelector(physicalIncidentInformationSelector);
	const isReportedToPolice = PsIncidentInfo.reportedToPoliceBt;

	const policeFileNumber = PsIncidentInfo.policeFileNumberNv;
	const reporterPhoneNumber = PsIncidentInfo.reporterPhoneNumber;
	const incidentSummary = PsIncidentInfo.summaryNt;
	const isGovIncident = useSelector(isSovereignIncidentSelector);

	const phoneErrorMessage = <FieldValidationDescription errorMessage={errorStates.PhoneNumber} descriptionColor="#e50000" iconName={"ErrorBadge"} />;

	const policeFileNumberErrorMessage = <FieldValidationDescription errorMessage={errorStates.PoliceFileNumber} descriptionColor="#e50000" iconName={"ErrorBadge"} />;

	const incidentSummaryErrorMessage = <FieldValidationDescription errorMessage={errorStates.IncidentSummary} descriptionColor="#e50000" iconName={"ErrorBadge"} />;

	const handleCancleButtonClicked = () => {
		dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
		dispatch(setSelectedIncidentCategory(undefined));
		dispatch(clearFiles());
		dispatch(removeMessage("validation-message"));
		document.getElementById("physicalSecurityId")?.focus();
		document.getElementById("mainRegion")?.scrollTo(0, 0);
	};

	const removeFileExtension = (filename: string): string => {
		var lastDotPosition = filename.lastIndexOf(".");
		if (lastDotPosition === -1) return filename;
		else return filename.substr(0, lastDotPosition);
	};

	const saveFileToState = () =>
		new Promise((resolve: any) => {
			var fileObjects: PsAttachmentInputViewModel[] = [];

			const size = fileDetails?.files?.reduce((p, c, i) => p + c.fileSize, 0) ?? 0;
			if (size > 20 * 1024 * 1024) {
				dispatch(setFileValidationErrorState(getLocalizedValueV2("RIN_TotalAttachmentSizeExceeded")));
			}

			fileDetails.files.forEach((file) => {
				fileObjects.push({
					attachmentIm: file.fileContent,
					fileExtensionNv: path.extname(file.fileName),
					fileNameNv: file.fileName,
					fileSizeIn: file.fileSize,
					attachmentTitleNv: removeFileExtension(file.fileName),
				});
			});
			dispatch(setAttachments(fileObjects));
			resolve();
		});

	const handlePhoneNumberChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		dispatch(setReporterPhoneNumber(newValue ?? ""));
		dispatch(resetValidationMessagePhoneNumber());
	};

	const handleSummaryChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		dispatch(setSummaryNt(newValue ?? ""));
		dispatch(resetValidationMessageIncidentSummary());
	};

	React.useEffect(() => {
		if (!isReportedToPolice) {
			dispatch(setPoliceFileNumberNv(undefined));
			dispatch(resetValidationMessagePoliceFileNumber());
		}
	}, [isReportedToPolice]);
	const navigate = useNavigate();

	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const incidentTypesL1 = useSelector(PsIncidentTypesL1Selector);
	const selectedIncidentTypeIdL1 = useSelector(PsSelectedincidentTypeIdL1Selector);

	React.useEffect(()=>{		
		trackEvent({name: INCIDENT_DETAILS_STEP}, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_DETAILS_STEP,
			incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
			incidentType: incidentTypesL1.find(x=>x.perspectiveDbId === selectedIncidentTypeIdL1?.perspectiveDbId)?.name,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
		});
	}, []);

	return (
		<>
			<PhysicalIncidentLocationAndTimev2 />
			<div className="ms-Grid-row section-header">
				<h2 className="sectionHeading">{getLocalizedValueV2("RIN_IncidentInformation")}</h2>
			</div>
			<div id="police-report" style={{ marginBottom: "28px" }}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<Checkbox
							checked={isReportedToPolice}
							onChange={() => dispatch(setReportedToPoliceBt(!isReportedToPolice))}
							aria-labelledby="poilce-report-label"
							label={getLocalizedValueV2("RIN_ReportedToThePolice")}
						/>
					</div>
				</div>
				{isReportedToPolice && (
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
							<TextField
								id="physicalIncidentPoliceFileNumberId"
								required
								aria-required={true}
								label={getLocalizedValueV2("RIN_PoliceFileNumber")}
								placeholder={getLocalizedValueV2("RIN_AddPoliceFileNumber")}
								value={policeFileNumber}
								onChange={(e, newValue) => {
									dispatch(setPoliceFileNumberNv(newValue));
									dispatch(resetValidationMessagePoliceFileNumber());
								}}
								onRenderDescription={() => policeFileNumberErrorMessage}
								styles={{
									root: {
										marginTop: 12,
									},
									field: errorStates.PoliceFileNumber.length > 0 ? { backgroundColor: "#FED9CC" } : { backgroundColor: "white" },
								}}
							/>
						</div>
					</div>
				)}
			</div>
			<div id="phone-number" style={{ marginBottom: "28px" }}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
						<Label htmlFor="physicalIncidentPhoneNumberId"><span className="required-field-label">{getLocalizedValueV2("RIN_YourPhoneNumber")}</span></Label>
					</div>
				</div>

				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<span
							id="incident-phone-label"
							style={{
								fontWeight: 400,
								fontSize: 14,
								display: "block",
								marginBottom: 8,
							}}
						>
							{getLocalizedValueV2("RIN_YourPhoneNumberInfo")}
						</span>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
						<TextField
							id="physicalIncidentPhoneNumberId"
							aria-required={true}
							aria-describedby="incident-phone-label"
							placeholder={getLocalizedValueV2("RIN_AddAPhoneNumber")}
							value={reporterPhoneNumber}
							onChange={handlePhoneNumberChange}
							styles={
								errorStates.PhoneNumber.length > 0
									? {
											field: {
												backgroundColor: "#FED9CC",
											},
									  }
									: {
											field: {
												backgroundColor: "white",
											},
									  }
							}
							onRenderDescription={() => phoneErrorMessage}
						/>
					</div>
				</div>
			</div>
			<div id="incident-summary" style={{ marginBottom: "28px" }}>
			{
					isGovIncident &&
					(
						<div className="ms-Grid-row" style={{marginBottom: 16}}>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
								<strong>{getLocalizedValueV2("RIN_Disclaimer_Caps")}</strong>{getLocalizedValueV2("RIN_CUIDisclaimer")}
							</div>
						</div>
					)
				}
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<Label id="incident-summary-label" htmlFor="physicalIncidentSummaryId"><span className="required-field-label">{getLocalizedValueV2("RIN_IncidentSummary")}</span></Label>
						<label id="incident-summary-description" style={{ display: "block", marginBottom: 8 }}>{getLocalizedValueV2("RIN_IncidentSummaryDesc")}</label>
					</div>
				</div>

				<TextField
					id="physicalIncidentSummaryId"
					aria-labelledby="incident-summary-label"
					aria-describedby="incident-summary-description"
					aria-required={true}
					rows={6}
					placeholder={getLocalizedValueV2("RIN_IncidentSummaryPlaceholder")}
					multiline
					value={incidentSummary}
					onChange={handleSummaryChange}
					styles={
						errorStates.IncidentSummary.length > 0
							? {
									field: {
										backgroundColor: "#FED9CC",
									},
							  }
							: {
									field: {
										backgroundColor: "white",
									},
							  }
					}
					onRenderDescription={(props) => {
						return incidentSummaryErrorMessage;
					}}
				/>
			</div>

			<FileUploadv2 allowdSizePerFileInMB={5} totalAllowedSizeInMB={20} isDigitalIncType={false} allowedFileTypes={[".msg", ".eml", ".mp3",".mp4",".mov", ".png", ".jpg", ".jpeg",".gif",".heic",".xlsx",".csv", ".docx",".pdf"]} allowedFileNameLength={100} />

			<div className="add-related-items-section" style={{ marginBottom: "28px" }}>
				<PhysicalIncidentAddPersonv2 />
				<PhysicalIncidentAddVehiclev2 />
				<PhysicalIncidentAddItemv2 />
			</div>
			<div className="ms-Grid-row" style={{ marginBottom: 16 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{display: 'flex', gap: 12}}>
					<DefaultButtonAdapter onClick={() => navigate("/v2/create/physical")}>{getLocalizedValueV2("RIN_Back")}</DefaultButtonAdapter>{" "}
					<PrimaryButtonAdapter
						onClick={() => {
							dispatch(clearErrorStates());
							saveFileToState().then(() => {
								dispatch(validatePhysicalIncidentDataV2(navigate));
							});
						}}
					>
						{getLocalizedValueV2("RIN_Next")}
					</PrimaryButtonAdapter>
				</div>
			</div>
		</>
	);
}
