import * as React from "react";
import Routing from "../Routing/Routing";
import "../../index.css";
import "./Shell.css";
import Header from "../Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsTestIncident } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { setIsTestPhysicalIncidentIncident } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { featureFlagsSelector } from "../UserProfile/ServiceCommunicationSettingsSlice";

export default function Shell() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const featureFlags = useSelector(featureFlagsSelector);
    const [isMobileExperienceInPilot, setPilotStatus] = React.useState<boolean | undefined>(featureFlags?.includes("MobileExperiencePilot"));
    React.useEffect(() => {
        const css = ":root {--background-color: #0064BF;}";
        const style = document.createElement("style");
        const root = document.getElementById("root");
        root?.appendChild(style);
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
        document.body.className = "classic";
    }, []);

    React.useEffect(()=>{
        // reset IsTestIncident on switching to existing experience.
        dispatch(setIsTestIncident(undefined));
        dispatch(setIsTestPhysicalIncidentIncident(undefined));
    },[]);

    React.useEffect(()=>{
		if(featureFlags && isMobileExperienceInPilot != featureFlags?.includes("MobileExperiencePilot")){
			setPilotStatus(featureFlags?.includes("MobileExperiencePilot"));
		}
	},[featureFlags]);

    React.useEffect(()=>{
        if(!isMobileExperienceInPilot) navigate("/v2");
        const isNewExperienceSelected =localStorage.getItem("hasUserSelectedNewExperience") === 'true';
        if(isNewExperienceSelected)
            navigate("/v2");
    }, [isMobileExperienceInPilot]);

    return (
        <div className="shell-container">
            <Header />
            <Outlet />
        </div>
    );
}
