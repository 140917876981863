import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { DatePicker, Label, MessageBar, MessageBarType, Pivot, PivotItem, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DayPickerStrings, firstDayOfWeek } from "../../Constants/DateControlConfigConstants";
import styles from "./ConfigurationsPanel.module.css";
import { ApplicationSettingsSelector, resetState, clearSimulationDetails, getIncidentStatistics, getSimulationStatistics, setApplicationSettingsApiStatus, setClearSimulationDetailsApiStatus, setErrorState, setIncidentStatistics, setIncidentStatisticsApiStatus, setIncidentStatisticsErrorState, setSimulationStatisticsApiStatus, submitApplicationSettings } from "./ApplicationSettingsSlice";
import { ApplicationSettingsResponseViewModel } from "../../Contracts/TypesAndInterfaces/ApplicationSettingsResponseViewModel";
import FieldValidationDescription from "../FieldValidationDescription/FieldValidationDescription";
import { CSVLink } from "react-csv";
import { csvHeaders } from "../../Constants/CommonConstants";

export interface ConfigurationPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
}

export const ConfigurationsPanel: React.FC<ConfigurationPanelProps> = ({ isOpen, dismissPanel }) => {
    const applicationSettingsDetails = useSelector(ApplicationSettingsSelector);
    const dispatch = useDispatch();
    const csvRef = React.useRef<any | null>(null);
    const [startDate, setStartDate] = React.useState(new Date(new Date().toDateString()));
    const [showIncidentResults, setShowIncidentResults] = React.useState(false);
    const [endDate, setEndDate] = React.useState(new Date(new Date().toDateString()));
    const [isIncidentStatisticsOpen, setIsIncidentStatisticsOpen] = React.useState(false);
    const [appSettings, setAppSettings] = React.useState<ApplicationSettingsResponseViewModel[]>(applicationSettingsDetails.applicationSettings);
    const originalSettingValues = applicationSettingsDetails.applicationSettings.map(s => s.value);
    const updatedSettingValues = appSettings.map(s => s.value);
    const applicationStatus = applicationSettingsDetails.applicationStatus;
    const incidentStatus = applicationSettingsDetails.incidentStatus;
    const simulationStatus = applicationSettingsDetails.simulationStatus;
    const clearSimulationStatus = applicationSettingsDetails.clearSimulationStatus;
    const isSimulationStatisticsEmpty = (isIncidentStatisticsOpen && simulationStatus.statusReceived && applicationSettingsDetails.simulationStatistics.length === 0);
    const isApplicationSettingsSubmitted = (!isIncidentStatisticsOpen && applicationStatus.statusReceived && applicationStatus.statusCode === 200);
    const isClearedSimulationDetails = (isIncidentStatisticsOpen && (clearSimulationStatus.statusReceived && clearSimulationStatus.statusCode === 200));

    const isError = (statusCode: number | undefined): boolean => {
        return (statusCode === 500 || statusCode === undefined || statusCode === 401 || statusCode === 400 || statusCode === 404);
    }

    const isErrorNotification = ((!isIncidentStatisticsOpen && (isError(applicationStatus.statusCode) && applicationStatus.statusReceived)) ||
        (isIncidentStatisticsOpen && ((isError(incidentStatus.statusCode) && incidentStatus.statusReceived) || (isError(simulationStatus.statusCode) && simulationStatus.statusReceived) || (isError(clearSimulationStatus.statusCode) && clearSimulationStatus.statusReceived))));

    const isIncidentStatisticsError = (isIncidentStatisticsOpen && isError(incidentStatus.statusCode) && incidentStatus.statusReceived);

    const checkStringArrIdentical = (array1: string[], array2: string[]): boolean => {
        var i = array1.length;
        while (i--) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    }

    React.useEffect(() => {
        if (applicationSettingsDetails.applicationSettings.length > 0 && appSettings.length === 0) {
            setAppSettings(applicationSettingsDetails.applicationSettings);
        }
    }, [applicationSettingsDetails.applicationSettings])

    React.useEffect(() => {
        if (incidentStatus.statusReceived && !showIncidentResults && !isError(incidentStatus.statusCode)) {
            setShowIncidentResults(true);
        }
    }, [incidentStatus.statusReceived])

    React.useEffect(() => {
        return () => {
            dispatch(resetState());
            setShowIncidentResults(false);
        }
    }, [])

    const handleSubmit = () => {
        dispatch(submitApplicationSettings(appSettings));
    }

    const handleSettingChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, settingId: number, isNumber: boolean, newValue?: string) => {
        if (newValue !== undefined) {
            if (isNumber) {
                newValue = event.currentTarget.value.replace(/[^0-9]*/g, '');
            }
            let settingIndex = applicationSettingsDetails.applicationSettings.findIndex(s => s.settingId === settingId);
            if (settingIndex !== -1) {
                dispatch(setErrorState({ settingIndex: settingIndex, errorMessage: "" }));
                setAppSettings([...appSettings.slice(0, settingIndex), { ...appSettings[settingIndex], value: isNumber ? Number(newValue).toString() : newValue }, ...appSettings.slice(settingIndex + 1)])
            }
        }
    }

    const onSelectStartDate = (date: Date | null | undefined): void => {
        if (date != null && date !== undefined) {
            dispatch(setIncidentStatisticsErrorState(""));
            setStartDate(date);
        }
    };

    const onSelectEndDate = (date: Date | null | undefined): void => {
        if (date != null && date !== undefined) {
            dispatch(setIncidentStatisticsErrorState(""));
            setEndDate(date);
        }
    };

    const handleFetch = () => {
        dispatch(getIncidentStatistics({ startDate: startDate, endDate: endDate }));
    }

    const handleToggleChange = (settingId: number, checked?: boolean) => {
        if (checked !== undefined) {
            let settingIndex = applicationSettingsDetails.applicationSettings.findIndex(s => s.settingId === settingId);
            if (settingIndex !== -1) {
                setAppSettings([...appSettings.slice(0, settingIndex), { ...appSettings[settingIndex], value: checked ? "true" : "false" }, ...appSettings.slice(settingIndex + 1)])
            }
        }
    }

    const onRenderFooterContent =
        (
            <>
                {!isIncidentStatisticsOpen &&
                    <PrimaryButton text="Save" disabled={checkStringArrIdentical(originalSettingValues, updatedSettingValues)} onClick={handleSubmit}></PrimaryButton>
                }
            </>
        );

    const handlePivotChange = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item) {
            if (item.props.id === "IncidentStatistics") {
                setIsIncidentStatisticsOpen(true);
            }
            else {
                setIsIncidentStatisticsOpen(false);
            }
        }
    }

    const handlePanelDismiss = () => {
        if (!isIncidentStatisticsOpen) {
            dispatch(setApplicationSettingsApiStatus({ statusCode: applicationStatus.statusCode, statusReceived: false }));
        }
        else {
            if (incidentStatus.statusReceived) {
                dispatch(setIncidentStatisticsApiStatus({ statusCode: incidentStatus.statusCode, statusReceived: false }));
            }
            else if (simulationStatus.statusReceived) {
                dispatch(setSimulationStatisticsApiStatus({ statusCode: simulationStatus.statusCode, statusReceived: false }));
            }
            else {
                dispatch(setClearSimulationDetailsApiStatus({ statusCode: clearSimulationStatus.statusCode, statusReceived: false }));
            }
        }
    }

    const applicationAdminPanelNotification = (
        <MessageBar
            messageBarType=
            {
                isErrorNotification ? MessageBarType.error :
                    isSimulationStatisticsEmpty ? MessageBarType.info :
                        (isApplicationSettingsSubmitted || isClearedSimulationDetails) ? MessageBarType.success : undefined
            }
            onDismiss={handlePanelDismiss}
            dismissButtonAriaLabel="Close"
            styles={
                isErrorNotification ?
                    {
                        root: {
                            backgroundColor: "#FED9CC",
                            marginLeft: '0px',
                            marginBottom: '16px'
                        }
                    } : isSimulationStatisticsEmpty ?
                        {
                            root: {
                                backgroundColor: "#F2F2F2",
                                marginLeft: '0px'
                            },
                            icon: {
                                color: "black"
                            }
                        }
                        : (isApplicationSettingsSubmitted || isClearedSimulationDetails) ?
                            {
                                root: {
                                    backgroundColor: "#DFF6DD",
                                    marginLeft: '0px',
                                    marginBottom: '16px'
                                },
                                icon: {
                                    color: "rgb(16, 124, 16)"
                                }
                            } :
                            {
                            }
            }
        >
            {
                isErrorNotification ? <> Oops ... something went wrong. Please try again.</> :
                    isSimulationStatisticsEmpty ? <>No simulation incidents were reported in the selected time frame.</> :
                        isApplicationSettingsSubmitted ? <>Application settings successfully updated.</> :
                            isClearedSimulationDetails ? <>Successfully cleared simulation details.</> : <></>
            }
        </MessageBar>
    );

    const getSimulationStats = () => {
        dispatch(getSimulationStatistics({ startDate: startDate, endDate: endDate }));
    }

    const jsonParser = (value: string): string => {
        try {
            let type = typeof JSON.parse(value);
            return type;
        }
        catch (e) {
            return "string";
        }
    }

    const handleClearSimulationDetails = () => {
        dispatch(clearSimulationDetails());
    }

    React.useEffect(() => {
        if (applicationSettingsDetails.simulationStatistics.length > 0 && csvRef.current != null) {
            setTimeout(() => {
                csvRef.current.link.click();
            });
        }
    }, [applicationSettingsDetails.simulationStatistics])

    return (
        <CoherencePanel
            titleText={'Application Admins'}
            panelSize={CoherencePanelSize.medium}
            isOpen={isOpen}
            onLightDismissClick={dismissPanel}
            isLightDismiss={true}
            hasCloseButton={true}
            isFooterAtBottom={true}
            onDismiss={dismissPanel}
            onRenderFooter={() => onRenderFooterContent}
            closeButtonAriaLabel={'Close panel'}
            backButton={{
                ariaLabel: "Back",
                onClick: dismissPanel
            }}
            styles={{
                header: {
                    marginBottom: '24px'
                }
            }}
        >
            <Pivot aria-label="Application admin pivot" onLinkClick={handlePivotChange}>
                <PivotItem headerText="Application Settings" id="ApplicationSettings">
                    <div style={{ marginTop: "16px" }}>
                        {applicationStatus.statusReceived &&
                            < div className="stickey-Header-Container">
                                {applicationAdminPanelNotification}
                            </div>
                        }
                        {appSettings.map((appSetting, index) =>
                            <div style={{ marginBottom: "12px" }}>
                                {
                                    jsonParser(applicationSettingsDetails.applicationSettings[index].value) === "boolean" ?
                                        <Toggle label={appSetting.name} onText="True" offText="False" onChange={(e, c) => handleToggleChange(appSetting.settingId, c)} checked={appSetting.value === "true"} />
                                        :
                                        jsonParser(applicationSettingsDetails.applicationSettings[index].value) === "number" ?
                                            <TextField
                                                type="number"
                                                label={appSetting.name}
                                                value={appSetting.value}
                                                disabled={appSetting.isReadOnly}
                                                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { if (event.key === '.') { event.preventDefault(); } }}
                                                onChange={(e, value) => { handleSettingChange(e, appSetting.settingId, true, value); }}
                                                onRenderDescription={() => <FieldValidationDescription errorMessage={applicationSettingsDetails.applicationSettings[index].errorState ?? ""} descriptionColor={'red'} iconName={"ErrorBadge"} />}
                                            />
                                            :
                                            <TextField
                                                label={appSetting.name}
                                                value={appSetting.value}
                                                disabled={appSetting.isReadOnly}
                                                onChange={(e, value) => { handleSettingChange(e, appSetting.settingId, false, value); }}
                                                onRenderDescription={() => <FieldValidationDescription errorMessage={applicationSettingsDetails.applicationSettings[index].errorState ?? ""} descriptionColor={'red'} iconName={"ErrorBadge"} />}
                                            />
                                }
                            </div>
                        )}
                    </div>
                </PivotItem>
                { /*Uncomment below pivot when statistics section is required*/}
                {/*<PivotItem headerText="Incident Statistics" id="IncidentStatistics">*/}
                {/*    <>*/}
                {/*        {(isIncidentStatisticsError || isSimulationStatisticsEmpty || isClearedSimulationDetails) &&*/}
                {/*            <div className="stickey-Header-Container" style={{ marginTop: "16px" }}>*/}
                {/*                {applicationAdminPanelNotification}*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%", marginTop: "16px", marginBottom: "12px" }}>*/}
                {/*            <DatePicker*/}
                {/*                label="Start date"*/}
                {/*                ariaLabel="Start date"*/}
                {/*                isRequired={false}*/}
                {/*                firstDayOfWeek={firstDayOfWeek}*/}
                {/*                isMonthPickerVisible={false}*/}
                {/*                strings={DayPickerStrings}*/}
                {/*                value={startDate}*/}
                {/*                maxDate={new Date()}*/}
                {/*                onSelectDate={onSelectStartDate}*/}
                {/*                styles={{*/}
                {/*                    root: {*/}
                {/*                        width: '240px',*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*            />*/}

                {/*            <DatePicker*/}
                {/*                label="End date"*/}
                {/*                ariaLabel="End date"*/}
                {/*                isRequired={false}*/}
                {/*                firstDayOfWeek={firstDayOfWeek}*/}
                {/*                isMonthPickerVisible={false}*/}
                {/*                strings={DayPickerStrings}*/}
                {/*                value={endDate}*/}
                {/*                maxDate={new Date()}*/}
                {/*                onSelectDate={onSelectEndDate}*/}
                {/*                styles={{*/}
                {/*                    root: {*/}
                {/*                        width: '240px',*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}

                {/*        {applicationSettingsDetails.incidentStatisticsErrorState.length > 0 &&*/}
                {/*            <div style={{ marginTop: "-12px", marginBottom: "8px" }}>*/}
                {/*                <FieldValidationDescription errorMessage={applicationSettingsDetails.incidentStatisticsErrorState} descriptionColor={'red'} iconName={"ErrorBadge"} />*/}
                {/*            </div>*/}
                {/*        }*/}

                {/*        <PrimaryButton text="Fetch incident statistics" onClick={handleFetch} style={{ marginTop: "8px" }}></PrimaryButton>*/}

                {/*        {showIncidentResults && (*/}
                {/*            <div style={{ marginTop: "16px" }}>*/}
                {/*                <Label>Results</Label>*/}
                {/*                <table className={styles.statTable}>*/}
                {/*                    <tr className={styles.statRow}>*/}
                {/*                        <th className={styles.statHeading}>Statistic</th>*/}
                {/*                        <th className={styles.statHeading}>Value</th>*/}
                {/*                    </tr>*/}
                {/*                    <tr className={styles.statRow}>*/}
                {/*                        <td className={styles.statData}>Number of digital incidents</td>*/}
                {/*                        <td className={styles.statData}>{applicationSettingsDetails.incidentStatistics.digitalSubmissionsCount}</td>*/}
                {/*                    </tr>*/}
                {/*                    <tr className={styles.statRow}>*/}
                {/*                        <td className={styles.statData}>Number of physical incidents</td>*/}
                {/*                        <td className={styles.statData}>{applicationSettingsDetails.incidentStatistics.physicalSubmissionsCount}</td>*/}
                {/*                    </tr>*/}
                {/*                    <tr className={styles.statRow}>*/}
                {/*                        <td className={styles.statData}>Total number of incidents</td>*/}
                {/*                        <td className={styles.statData}>{applicationSettingsDetails.incidentStatistics.digitalSubmissionsCount + applicationSettingsDetails.incidentStatistics.physicalSubmissionsCount}</td>*/}
                {/*                    </tr>*/}
                {/*                </table>*/}
                {/*            </div>*/}
                {/*        )}*/}

                {/*        <div className={styles.divider}></div>*/}
                {/*        <div style={{ marginTop: "28px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>*/}
                {/*            <PrimaryButton text="Download simulation statistics" onClick={getSimulationStats} />*/}
                {/*            <CSVLink*/}
                {/*                data={applicationSettingsDetails.simulationStatistics}*/}
                {/*                filename={"Phishing Email Simulation Details.csv"}*/}
                {/*                ref={csvRef}*/}
                {/*                headers={csvHeaders}*/}
                {/*            />*/}
                {/*            <PrimaryButton text="Clear simulation details" onClick={handleClearSimulationDetails} />*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*</PivotItem>*/}
            </Pivot>
        </CoherencePanel>
    );
}