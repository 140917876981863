import * as React from "react";
import { getLocalizedValue } from "../../../Services/localizationService";
import styles from "./DragAndDrop.module.css";

export default class DragAndDrop extends React.Component<any, any> {
	private dropRef: HTMLDivElement | undefined = undefined;

	constructor(props: {}) {
		super(props);
		this.state = {
			drag: false,
			dragCounter: 0,
		};
	}

	setDropRef = (element: HTMLDivElement) => (this.dropRef = element);

	handleDrag = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	handleDragIn = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ dragCounter: this.state.dragCounter + 1 });
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ drag: true });
		}
	};
	handleDragOut = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ dragCounter: this.state.dragCounter - 1 });
		if (this.state.dragCounter === 0) {
			this.setState({ drag: false });
		}
	};
	handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ drag: false });
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
			this.setState({ dragCounter: 0 });
		}
	};
	componentDidMount() {
		let div = this.dropRef;
		div?.addEventListener("dragenter", this.handleDragIn);
		div?.addEventListener("dragleave", this.handleDragOut);
		div?.addEventListener("dragover", this.handleDrag);
		div?.addEventListener("drop", this.handleDrop);
	}
	componentWillUnmount() {
		let div = this.dropRef;
		div?.removeEventListener("dragenter", this.handleDragIn);
		div?.removeEventListener("dragleave", this.handleDragOut);
		div?.removeEventListener("dragover", this.handleDrag);
	}

	onFileChange = (e: any) => {
		this.props.handleDrop(e.target.files);
	};

	render() {
		return (
			<>
				<div className="visually-hidden" aria-live="assertive"></div>
				<div
					role="button"
					tabIndex={0}
					aria-labelledby="file-upload"
					aria-describedby="file-input-label"
					className={this.props.isInvalidState ? styles.errorContainer : styles.dragContainer}
					ref={this.setDropRef}
					onClick={(e) => this.props.fileInputRef.current?.click()}
					onKeyDown={(e) => { 
						if (e.key === 'Enter' || e.key === ' ') { 
							this.props.fileInputRef.current?.click(); 
							e.preventDefault(); 
						} 
					}}
					style={{ marginBottom: 4 }}
				>
					{!this.state.drag && <div className={styles.dragContainerText}>{getLocalizedValue("RIN_ClickHereOrDragAndDropToUpload")}</div>}
					<input
						id="fileUploadInputId"
						tabIndex={-1}
						role="button"
						aria-hidden={true}
						aria-label={getLocalizedValue("RIN_ClickHereOrDragAndDropToUpload")}
						className={styles.fileInput}
						type="file"
						multiple
						onChange={this.onFileChange}
						ref={this.props.fileInputRef}
						onClick={(e: any) => {
							if (document.querySelector("div[class='visually-hidden']") != null) {
								document.querySelector("div[class='visually-hidden']")!.textContent = "";
							}
						}}
						accept=".msg, .eml, .mp3, .png, .jpg, .jpeg, .pdf, .zip"
					/>
				</div>
			</>
		);
	}
}
