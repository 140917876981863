import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HelpSectionLinkResponseViewModel } from "../../Contracts/TypesAndInterfaces/HelpSectionLinkResponseViewModel";
import { HelpSectionModel } from "../../Contracts/TypesAndInterfaces/HelpSectionModel";
import { HelpSectionResponseViewModel } from "../../Contracts/TypesAndInterfaces/HelpSectionResponseViewModel";

const initialState: HelpSectionModel = {
    helpSections: [],
    helpSectionLinks: [],
    isHelpSectionApiCallSuccess: true,
    isHelpSectionLinksApiCallSuccess: true,
    isPanelOpen: false
}

const HelpSectionSlice = createSlice({
    name: "HelpSection",
    initialState,
    reducers: {
        setHelpPanelVisibility: (state, { payload }: PayloadAction<boolean>) => {
            state.isPanelOpen = payload;
        },
        setHelpSections: (state, { payload }: PayloadAction<HelpSectionResponseViewModel[]>) => {
            state.helpSections = payload;
        },
        getHelpSections: () => {

        },
        setHelpSectionLinks: (state, { payload }: PayloadAction<HelpSectionLinkResponseViewModel[]>) => {
            state.helpSectionLinks = payload;
        },
        getHelpSectionLinks: () => {

        },
        setIsHelpSectionApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.isHelpSectionApiCallSuccess = payload;
        },
        setIsHelpSectionLinksApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.isHelpSectionLinksApiCallSuccess = payload;
        },
    },
})
export const { setHelpPanelVisibility, getHelpSections, getHelpSectionLinks, setHelpSections, setHelpSectionLinks, setIsHelpSectionApiCallSuccess, setIsHelpSectionLinksApiCallSuccess } = HelpSectionSlice.actions;
export default HelpSectionSlice.reducer;
export const HelpSectionSelector = (state: { HelpSection: HelpSectionModel }) => state.HelpSection;
export const HelpSectionDetailsSelector = (state: { HelpSection: HelpSectionModel }) => state.HelpSection.helpSections;
export const HelpPanelVisibilitySelector = (state: { HelpSection: HelpSectionModel }) => state.HelpSection.isPanelOpen;