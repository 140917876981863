import { LoadLocalizationFile } from "../Constants/ErrorCodes";
import { GetExceptionModel, trackException } from "./telemetryService";

let localizations: any = null;
export let localizationsModule: any;
export const getLocaleFilesV2 = () => {


    let culture = navigator.language;//'en-US';//navigator.language;
    let language = culture.split("-")?.[0];
    let cultureLocalizations: any = {};
    let cultureLevelDefaultLocalizations: any = {};
    let defaultLocalizations: any = {};
    let LOCALIZATION_LOADING_SCENARIO = "Loading new experience localization files";
    let SOURCE = "UI";
    let OUTER_CATCH_LOCATION = "OUTER";
     



    try {
        cultureLocalizations = require("../LocalizationsV2/" + language + "/" + culture + ".json");
    }
    catch {
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of culture related localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFilesV2",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }

    try {
        cultureLevelDefaultLocalizations = require("../LocalizationsV2/" + language + "/" + language + ".json");
    }
    catch {
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of language related localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFilesV2",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }

    try{
    defaultLocalizations = require("../LocalizationsV2/en/en.json");
    }
    catch{
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of default english localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFilesV2",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }


    localizations = overrideLocalizations(cultureLevelDefaultLocalizations, cultureLocalizations);
    localizations = overrideLocalizations(defaultLocalizations, localizations);

}

const overrideLocalizations = (targetLocalizations: any, sourceLocalizations: any) => {
    return Object.assign(targetLocalizations, sourceLocalizations);
}

export const getLocalizedValueV2: any = (key: string) => {
    if (!localizations) {
        getLocaleFilesV2();
    }
    return localizations[key];
}


