import {
    DefaultButton,
    DialogFooter,
    DialogType,
    Dropdown,
    IButtonStyles,
    IconButton,
    IDropdown,
    IDropdownOption,
    IIconProps,
    ITextField,
    Label,
    Modal,
    PrimaryButton,
    TextField,
    Toggle,
} from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddedPersonModel } from "../../../../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import { useBoolean } from "../../../../Hooks/useBoolean";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { physicalIncidentCardStyle } from "../../../../styles";
import { PsAddedPeopleSelector, PsPersonInvolvementTypesSelector, getPersonInvolvementTypes, validateAndAddPerson, validateAndUpdatePerson, deletePersonFromAddedPeople } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { DropdownAdapter } from "../../Controls";

const addIcon: IIconProps = { iconName: "Add" };


const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

export default function PhysicalIncidentAddPersonv2() {
    const [hideDialog, { toggle: toggleHideDialog, setFalse: openHiddenDialog }] = useBoolean(true);
    const dispatch = useDispatch();

    const [involvementType, setInvolvementType] = React.useState<IDropdownOption>();
    const [isEmployee, { toggle: toggleIsEmployee }] = useBoolean(false);
    const [firstName, setFirstName] = React.useState<string>();
    const [lastName, setLastName] = React.useState<string>();
    const [phoneNumber, setPhoneNumber] = React.useState<string>();
    const [personId, setPersonId] = React.useState<number>(-1);
    const [email, setEmail] = React.useState<string>();
    const [notes, setNotes] = React.useState<string>();

    const [involvementTypeErrorMessage, setInvolvementTypeErrorMessage] = React.useState<string>();
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState<string>();
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState<string>();
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = React.useState<string>();
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>();
    const [notesErrorMessage, setNotesErrorMessage] = React.useState<string>();

    const addIconRef = React.createRef<HTMLElement>();

    const addedPeople = useSelector(PsAddedPeopleSelector);
    const PsPersonInvolvementTypes = useSelector(PsPersonInvolvementTypesSelector);
    const [personInvolvementTypes, setPersonInvolvementtypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setPersonInvolvementtypes(PsPersonInvolvementTypes.map(x => {
            return { key: x.idPk, text: x.valueNameNv }
        }) ?? [])
    }, [PsPersonInvolvementTypes])


    const handleAddPersonButtonClicked = () => {
        if (personInvolvementTypes.length == 0)
            dispatch(getPersonInvolvementTypes());
        toggleHideDialog();
    }

    const resetCurrentPerson = () => {
        setInvolvementType(undefined);
        if (isEmployee) toggleIsEmployee();
        setFirstName(undefined);
        setLastName(undefined);
        setPhoneNumber(undefined);
        setPersonId(-1);
        setEmail(undefined);
        setNotes(undefined);

        setInvolvementTypeErrorMessage(undefined);
        setFirstNameErrorMessage(undefined);
        setLastNameErrorMessage(undefined);
        setPhoneNumberErrorMessage(undefined);
        setEmailErrorMessage(undefined);
        setNotesErrorMessage(undefined);
    };

    const validatePerson = (): boolean => {
        let isValid = true;

        if (!involvementType) {
            if (isValid) {
                involvementTypeDropdownRef.current?.focus();
            }
            isValid = false;
            setInvolvementTypeErrorMessage(getLocalizedValueV2("RIN_InvolvementTypeIsRequired"));
        }

        if (!firstName || firstName?.trim()?.length == 0) {
            if (isValid) {
                firstNameRef.current?.focus();
            }
            isValid = false;
            setFirstNameErrorMessage(getLocalizedValueV2("RIN_FirstNameIsRequired"));
        }
        else if (firstName?.trim().length > 100) {
            if (isValid) {
                firstNameRef.current?.focus();
            }
            isValid = false;
            setFirstNameErrorMessage(getLocalizedValueV2("RIN_FirstNameCannotBeLongerThanCharacters"));
        }

        if (!lastName || lastName?.trim()?.length == 0) {
            if (isValid) {
                lastNameRef.current?.focus();
            }
            isValid = false;
            setLastNameErrorMessage(getLocalizedValueV2("RIN_LastNameIsRequired"));
        }
        else if (lastName?.trim().length > 100) {
            if (isValid) {
                lastNameRef.current?.focus();
            }
            isValid = false;
            setLastNameErrorMessage(getLocalizedValueV2("RIN_LastNameCannotBeLongerThan"));
        }

        if (phoneNumber && phoneNumber?.trim()?.length > 25) {
            if (isValid) {
                phoneRef.current?.focus();
            }
            isValid = false;
            setPhoneNumberErrorMessage(getLocalizedValueV2("RIN_PhoneNumberCannotBeLongerThan"));
        }

        if (email && email?.trim()?.length > 250) {
            if (isValid) {
                emailRef.current?.focus();
            }
            isValid = false;
            setEmailErrorMessage(getLocalizedValueV2("RIN_EmailCannotBeLongerThan"));
        }

        if (notes && notes?.trim().length > 4000) {
            if (isValid) {
                notesRef.current?.focus();
            }
            isValid = false;
            setNotesErrorMessage(getLocalizedValueV2("RIN_TotalCharactersMustBeLessThanOrEqual"));
        }

        return isValid;
    }

    const handelAddButtonClicked = () => {
        const isValid = validatePerson();
        if (isValid) {
            const addedPerson: AddedPersonModel = {
                id: personId,
                efirstNameNv: firstName?.trim() ?? "",
                elastNameNv: lastName?.trim() ?? "",
                involvementType: involvementType?.text,
                eemployeeBt: isEmployee,
                primaryPhoneNumberNv: phoneNumber?.trim() ?? "",
                primaryEmailNv: email?.trim() ?? "",
                supplementalInformationNt: notes?.trim(),
                PersInvolvementTypeLookupIdFk: involvementType?.key?.toString()
            }
            if (personId == -1) {
                dispatch(validateAndAddPerson(addedPerson));
            } else {
                dispatch(validateAndUpdatePerson(addedPerson))
            }

            resetCurrentPerson();

            toggleHideDialog();

            if (document.querySelector("div[class='visually-hidden']") != null) {
                if (personId === -1) {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyAddedPerson") + firstName + lastName;
                }
                else {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyUpdatedPerson") + firstName + lastName;
                }
            }
        }
    };

    const handleCloseButtonClicked = () => {
        resetCurrentPerson();

        toggleHideDialog();
    };

    const setCurrentPerson = (person: AddedPersonModel | undefined) => {
        setInvolvementType(personInvolvementTypes.find(x => x.key == person?.PersInvolvementTypeLookupIdFk));
        if (person?.eemployeeBt) toggleIsEmployee();
        setFirstName(person?.efirstNameNv);
        setLastName(person?.elastNameNv);
        setPhoneNumber(person?.primaryPhoneNumberNv);
        setPersonId(person?.id ?? -1);
        setEmail(person?.primaryEmailNv);
        setNotes(person?.supplementalInformationNt);
    };

    const deletePerson = (personId: number) => {
        let deletePerson = addedPeople.find(s => s.id === personId);
        if (document.querySelector("div[class='visually-hidden']") != null) {
            document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyDeletedPerson") + deletePerson?.efirstNameNv + deletePerson?.elastNameNv;
        }
        dispatch(deletePersonFromAddedPeople(personId));
        resetCurrentPerson();
        addIconRef?.current?.focus();
    };

    const updatePerson = (personId: number) => {
        const currentPerson = addedPeople.find((x) => x.id === personId);
        setCurrentPerson(currentPerson);
        openHiddenDialog();
    };

    const involvementTypeDropdownRef = React.createRef<IDropdown>();
    const firstNameRef = React.createRef<ITextField>();
    const lastNameRef = React.createRef<ITextField>();
    const emailRef = React.createRef<ITextField>();
    const phoneRef = React.createRef<ITextField>();
    const notesRef = React.createRef<ITextField>();

    return (
        <div style={{ marginBottom: "28px" }}>
            <div className="visually-hidden" aria-live="assertive"></div>
            <div className="ms-Grid-row" style={{ marginBottom: "8px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <IconButton
                        elementRef={addIconRef}
                        iconProps={addIcon}
                        title={getLocalizedValueV2("RIN_AddPersonOptional")}
                        onClick={handleAddPersonButtonClicked}
                        styles={{
                            root: {
                                backgroundColor: "white",
                            },
                        }}
                    />
                    <Label
                        styles={{
                            root: {
                                display: "inline",
                                paddingLeft: 12,
                                verticalAlign: "text-top",
                            },
                        }}
                    >
                       {getLocalizedValueV2("RIN_AddPersonOptional")}
                    </Label>
                </div>
            </div>

            {addedPeople.map((person) => (
                <>
                    <div
                        className="ms-Grid-row"
                        style={physicalIncidentCardStyle}
                    >
                        <div
                            className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block"
                            style={{ padding: 0 }}
                        >
                            <div>
                                <div>
                                    <h3 style={{ margin: "0 0 5px 0", wordBreak: "break-all" }}>
                                        {person.efirstNameNv} {person.elastNameNv}{" "}
                                        {person.eemployeeBt ? "(Employee)" : ""}
                                    </h3>
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_Involvement")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span>{person.involvementType}</span>
                                    <br />
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_Phone")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ marginLeft: "2px" }}>{person.primaryPhoneNumberNv.length ? person.primaryPhoneNumberNv : "N/A"}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-x2 ms-xx2 ms-xxxl2 block"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: 0,
                            }}
                        >
                            <IconButton
                                onClick={() => updatePerson(person.id)}
                                iconProps={{ iconName: "Edit" }}
                                title={ getLocalizedValueV2("RIN_EditPersonDetailsOf")+" " + person.efirstNameNv + " " + person.elastNameNv}
                            />
                            <IconButton
                                onClick={() => deletePerson(person.id)}
                                iconProps={{ iconName: "Delete" }}
                                title={getLocalizedValueV2("RIN_DeletePersonDetailsOf")+" " + person.efirstNameNv + " " + person.elastNameNv}
                            />
                        </div>
                    </div>
                </>
            ))
            }
            <hr style={{ marginTop: 36, marginBottom: 36 }} />
            <Modal
                titleAriaId='add-person-modal-header'
                isOpen={!hideDialog}
                onDismiss={handleCloseButtonClicked}
                isModeless={true}
                onLayerDidMount={() => involvementTypeDropdownRef.current?.focus()}
                styles={{
                    main: {
                        padding: 30,
                        paddingTop: 0
                    },
                    scrollableContent: {
                        overflowX: 'hidden'
                    }
                }}
            >
                <div style={{
                    flex: '1 1',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                }}>
                    <h2 id='add-person-modal-header'>{getLocalizedValueV2("RIN_AddPerson")}</h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={getLocalizedValueV2("RIN_ClosePopUpModal")}
                        onClick={handleCloseButtonClicked}
                    />
                </div>
                <div className="ms-Grid-row" style={{ marginBottom: "4px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9 block popup-form-control-container"
                    >
                        <DropdownAdapter
                            componentRef={involvementTypeDropdownRef}
                            label={getLocalizedValueV2("RIN_InvolvementType")}
                            required
                            aria-required={true}
                            selectedKey={involvementType?.key}
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            options={personInvolvementTypes}
                            onChange={(e, item) => { setInvolvementType(item); setInvolvementTypeErrorMessage(undefined); }}
                            onLoad={() => involvementTypeDropdownRef.current?.focus()}
                            errorMessage={involvementTypeErrorMessage}
                            styles={{
                                root: {
                                    display: "inline-block",
                                    width: "100%",
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md3 ms-lg3ms-xl3 ms-xxl3 ms-xxxl3 block popup-form-control-container"
                    >
                        <Toggle
                            checked={isEmployee}
                            label={getLocalizedValueV2("RIN_Employee")}
                            onChange={toggleIsEmployee}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={firstNameRef}
                            defaultValue={firstName}
                            label={getLocalizedValueV2("RIN_Firstname")}
                            required
                            aria-required={true}
                            placeholder={getLocalizedValueV2("RIN_ProvideFirstName")}
                            onChange={(e, value) => { setFirstName(value); setFirstNameErrorMessage(undefined); }}
                            errorMessage={firstNameErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={lastNameRef}
                            defaultValue={lastName}
                            required
                            aria-required={true}
                            label={getLocalizedValueV2("RIN_LastName")}
                            placeholder={getLocalizedValueV2("RIN_ProvideLastName")}
                            onChange={(e, value) => { setLastName(value); setLastNameErrorMessage(undefined); }}
                            errorMessage={lastNameErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={phoneRef}
                            defaultValue={phoneNumber}
                            label={getLocalizedValueV2("RIN_PhoneNumberOptional")}
                            placeholder={getLocalizedValueV2("RIN_ContactNumber")}
                            onChange={(e, value) => { setPhoneNumber(value); setPhoneNumberErrorMessage(undefined); }}
                            errorMessage={phoneNumberErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={emailRef}
                            defaultValue={email}
                            label={getLocalizedValueV2("RIN_EmailAddressOptional")}
                            placeholder={getLocalizedValueV2("RIN_ContactEmail")}
                            onChange={(e, value) => { setEmail(value); setEmailErrorMessage(undefined) }}
                            errorMessage={emailErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <TextField
                    componentRef={notesRef}
                    defaultValue={notes}
                    label={getLocalizedValueV2("RIN_NotesOptional")}
                    placeholder={getLocalizedValueV2("RIN_AddAdditionalDetail")}
                    multiline
                    rows={4}
                    onChange={(e, value) => { setNotes(value); setNotesErrorMessage(undefined); }}
                    errorMessage={notesErrorMessage}
                    styles={{ root: { marginBottom: 28 } }}
                />

                <DialogFooter>
                    <PrimaryButton
                        onClick={handelAddButtonClicked}
                        text={personId == -1 ? getLocalizedValueV2("RIN_Add") : getLocalizedValueV2("RIN_Update")}
                    />
                    <DefaultButton
                        onClick={handleCloseButtonClicked}
                        text={getLocalizedValueV2("RIN_Cancel")}
                    />
                </DialogFooter>
            </Modal>
        </div >
    );
}
