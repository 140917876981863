import {
    DefaultButton,
    IButtonStyles,
    IStyle,
    Label,
} from "@fluentui/react";
import * as React from "react";
import { useBoolean } from "../../Hooks/useBoolean";
import { useDispatch, useSelector } from "react-redux";
import { LeaveIncident } from "../LeaveIncident/LeaveIncident";
import {
    IncidentCategorySelector,
    setSelectedIncidentCategory,
} from "./IncidentCategorySlice";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { IncidentCategory } from "../../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";
import { physicalIncidentSelector, PsResetLevel, resetPhysicalIncidentInformation } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { DigitalIncidentTypesSelector } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import styles from './IncidentCategory.module.css'
import { IsSiteNotificationsAPICallSuccessSelector, SiteNotificationDetailsSelector } from "../Notifications/NotificationsSlice";
import { HelpSectionSelector } from "../HelpPanel/HelpSectionSlice";
import { PanelEnums } from "../../Contracts/Enums/PanelEnums";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import { getLocalizedValue } from "../../Services/localizationService";
import { VSOCNumberWhite } from "../../Constants/CommonConstants";

const toggleButtonFocusedOrHoveredStyle: IStyle = {
    backgroundColor: "rgba(255, 255, 255, .1)",
    color: "white",
};

const toggleButtonStyles: IButtonStyles = {
    root: {
        width: "100%",
        background: "transparent",
        color: "white",
        paddingLeft: 4,
        paddingRight: 4,
        selectors: {
            '@media (max-width: 479px)': {
                padding: "28px 30px",
                ".ms-Button-label": {
                    lineHeight: "122%"
                }
            }
        },
        border: "1px solid white"
    },
    rootHovered: toggleButtonFocusedOrHoveredStyle,
    rootFocused: toggleButtonFocusedOrHoveredStyle,
    rootChecked: {
        background: "white",
        color: "var(--background-color)"
    },
    rootCheckedHovered: {
        color: "var(--background-color)"
    }
};

const iconProps = { iconName: "Unknown" };
export function IncidentCategorySection() {
    const incidentCategoryDetails = useSelector(IncidentCategorySelector);
    const [isLeaveIncidentDialogOpen, { setTrue: openLeaveIncidentDialog, setFalse: dismissLeaveIncidentDialog }] = useBoolean(false);
    const dispatch = useDispatch();
    const siteNotificationDetails = useSelector(SiteNotificationDetailsSelector);
    const isSiteNotificationsSuccess = useSelector(IsSiteNotificationsAPICallSuccessSelector);
    const physicalIncidentDetails = useSelector(physicalIncidentSelector);
    const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
    const physicalIncidentCategory = incidentCategoryDetails.incidentCategories.find((s) => s.categoryId === IncidentCategories.Physical);
    const digitalIncidentCategory = incidentCategoryDetails.incidentCategories.find((s) => s.categoryId === IncidentCategories.Digital);
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const immediateAssistanceSection = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.find(s => s.panelId === PanelEnums.PhysicalIncidentForm) : undefined;
    const immediateAssitanceLink = immediateAssistanceSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === immediateAssistanceSection.sectionId);
    const immediateAssitanceDescription = getLocalizedValue(immediateAssitanceLink?.descriptionKey)+VSOCNumberWhite;
    const handleCategoryChange = (currentIncidentCategory: IncidentCategory | undefined) => {
        if (incidentCategoryDetails.selectedIncidentCategory === undefined || incidentCategoryDetails.selectedIncidentCategory === null) {
            dispatch(setSelectedIncidentCategory(currentIncidentCategory));
            return;
        }
        else if (incidentCategoryDetails.selectedIncidentCategory.categoryId === currentIncidentCategory?.categoryId) {
            return;
        }
        else {
            if (incidentCategoryDetails.selectedIncidentCategory.categoryId === IncidentCategories.Physical) {
                if (physicalIncidentDetails.incidentTypes.selectedincidentTypeIdL1) {
                    openLeaveIncidentDialog();
                }
                else {
                    dispatch(setSelectedIncidentCategory(currentIncidentCategory));
                    dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
                }
            } else if (incidentCategoryDetails.selectedIncidentCategory.categoryId === IncidentCategories.Digital) {
                if (digitalIncidentTypesDetails.selectedIncidentTypeL1) {
                    openLeaveIncidentDialog();
                } else {
                    dispatch(setSelectedIncidentCategory(currentIncidentCategory));
                }
            }
        }
    };

    return (
        <div>
            <div className="incident-category-container margin-bottom-32">
                <div className="ms-Grid-row section-header">
                    <div className={styles.heading2}>
                        <h2 className={styles.headingh2} >{getLocalizedValue("RIN_SelectIncidentCategory")}</h2>
                    </div>
                    <div>
                        {isSiteNotificationsSuccess &&
                            <p>
                                {siteNotificationDetails.find(s => s.notificationId === 2)?.description}
                            </p>}
                        {!isSiteNotificationsSuccess &&
                            <p>
                                For life threatening emergencies call your local emergency services (e.g. police, fire, medical)
                            </p>}
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div
                        className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                        style={{ padding: "0 !important" }}
                    >
                        <DefaultButton
                            id="physicalSecurityId"
                            toggle
                            checked={
                                incidentCategoryDetails.selectedIncidentCategory
                                    ?.categoryId === IncidentCategories.Physical
                            }
                            styles={toggleButtonStyles}
                            onClick={() =>
                                handleCategoryChange(physicalIncidentCategory)
                            }
                        >
                            {getLocalizedValue(physicalIncidentCategory?.nameKey)}
                        </DefaultButton>
                        <p>
                            {getLocalizedValue(physicalIncidentCategory?.descriptionKey)}
                        </p>
                        <div className={styles.physicalCategory}>
                            {immediateAssitanceLink !== undefined && (
                                <p style={{ marginTop: 0, fontSize: 14 }}>
                                  <RichTextRenderer html={immediateAssitanceDescription} /> 
                                </p>
                            )}
                            {immediateAssitanceLink === undefined && (
                                <p style={{ marginTop: 0, fontSize: 14 }}>
                                    {getLocalizedValue("RIN_PI_VSOC")} {" "}
                                    <Label styles={{ root: { display: 'inline' } }}>
                                        +1 425 706-0000
                                    </Label>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
                        <DefaultButton
                            id="digitalSecurityId"
                            toggle
                            checked={
                                incidentCategoryDetails.selectedIncidentCategory
                                    ?.categoryId === IncidentCategories.Digital
                            }
                            styles={toggleButtonStyles}
                            onClick={() =>
                                handleCategoryChange(digitalIncidentCategory)
                            }
                        >
                            {getLocalizedValue(digitalIncidentCategory?.nameKey)}
                        </DefaultButton>
                        <p>
                            {getLocalizedValue(digitalIncidentCategory?.descriptionKey)}
                        </p>
                    </div>
                </div>
            </div>
            {isLeaveIncidentDialogOpen && (
                <LeaveIncident
                    isHidden={!isLeaveIncidentDialogOpen}
                    dismissDialog={dismissLeaveIncidentDialog}
                />
            )}
        </div>
    );
}
