import { getId, IconButton } from "@fluentui/react";
import React, { useState, useEffect, useRef } from "react";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import styles from './CustomChoiceGroup.module.css'
import LinkOutVector from "../../../../Assets/Vector.svg";
import RichTextRenderer from "../../../RichTextRenderer/RichTextRenderer";

export interface CustomChoiceGroupProps {
	options: ICustomChoiceGroupOption[];
	name?: string;
	selectedKey: number | undefined;
	onChange: (option: ICustomChoiceGroupOption | undefined) => void;
	legendId: string;
}

export function CustomChoiceGroup(props: CustomChoiceGroupProps) {
	const [selectedOption, setSelectedOption] = useState<ICustomChoiceGroupOption | undefined>(props.options.find((x) => x.key == props.selectedKey));
	const choiceGroupName = getId("custom-choice-group")
	const onOptionChange = (newOption: ICustomChoiceGroupOption) => {
		setSelectedOption(newOption);
		props.onChange(newOption);
	}

	useEffect(() => {
		if (props.selectedKey)
			setSelectedOption(props.options.find(x => x.key === props.selectedKey));
	}, [])

	return (
		<div className="custom-choice-group" role="radiogroup" aria-labelledby={props.legendId}>
			{props.options.map((x, i) => (
				<CustomChoiceGroupOption
					{...x}
					checked={x.key === selectedOption?.key}
					onChange={onOptionChange}
					name={props.name ?? choiceGroupName}
					itemKey={x.key}
				/>
			))}
		</div>
	);
}

export interface ICustomChoiceGroupOption {
	key: number;
	optionLabelLocalizationKey: string;
	optionDescriptionLocalizationKey: string;
	moreInfoLink?: string;
	toBeHighlighted?:boolean;
	
}

export interface ICustomChoiceGroupOptionProps extends Omit<ICustomChoiceGroupOption, 'key'> {
	itemKey: number;
	key: number;
	checked?: boolean;
	name?: string;
	onChange: (option: ICustomChoiceGroupOption) => void;
}

export function CustomChoiceGroupOption(props: ICustomChoiceGroupOptionProps) {
	const radioButtonElement = useRef<HTMLInputElement>(null);
	const optionLabelId = getId("option-label");
	const optionDescriptionId = getId("option-description");
	return (
		<div className={(props.toBeHighlighted != true ? styles.categoryBlock : styles.categoryBlockHighlighted )+ " " + styles["custom-choice-group-option"]} onClick={() => radioButtonElement.current?.click()}>
			<div className={styles.category + " site-margins"}>
				<div className="radio-button-icon">
					<input
						className="visually-hidden"
						type="radio"
						aria-labelledby={optionLabelId}
						aria-describedby={optionDescriptionId}
						name={props.name}
						checked={props.checked}
						aria-checked={props.checked}
						onChange={() => props.onChange({ ...props, key: props.itemKey })}
						ref={radioButtonElement}
						value={props.name}
						onFocus={(e) => { e.target.parentElement?.parentElement?.parentElement?.classList.add(styles["custom-choice-group-option-focused"]) }}
						onBlur={(e) => { e.target.parentElement?.parentElement?.parentElement?.classList.remove(styles["custom-choice-group-option-focused"]) }}
					/>
					<IconButton
						tabIndex={-1}
						checked={props.checked}
						aria-hidden={true}
						iconProps={{ iconName: "CheckMark" }}
						styles={{
							root: {
								borderRadius: "50%",
								backgroundColor: "#F8F8F8",
								border: "1px solid rgba(0, 0, 0, 0.1)",
							},
							rootChecked: {
								color: "white",
								backgroundColor: "#0078d4",
								'@media (forced-colors: active)': {
									backgroundColor: "Highlight",
								}
							},
							icon: {
								color: "#F8F8F8",
							},
							rootHovered: {
								backgroundColor: "lightgrey",
							},
							rootCheckedHovered: {
								color: "white",
								backgroundColor: "#0078d4",
								'@media (forced-colors: active)': {
									backgroundColor: "Highlight",
								}
							}
						}}
					/>
				</div>
				<div className={props.toBeHighlighted != true ?  styles.categoryDetails : styles.categoryDetailsHighlighted}>
					<h2 className={styles.categoryHeader} id={optionLabelId}>
						{ props.toBeHighlighted != true ? getLocalizedValueV2(props.optionLabelLocalizationKey) :  `${getLocalizedValueV2(props.optionLabelLocalizationKey)} (🔥${getLocalizedValueV2("RIN_FrequentlyReported")})` }
					</h2>
					
					<p className={styles.categoryDescription} id={optionDescriptionId}>
						<RichTextRenderer
							html={getLocalizedValueV2(props.optionDescriptionLocalizationKey)}
						/>
					</p>
					{props.moreInfoLink &&
						<div style={{ display: "flex", gap: "8px" }}>
							<a
								href={props.moreInfoLink}
								aria-label={getLocalizedValueV2(props.optionLabelLocalizationKey) + " " + getLocalizedValueV2("RIN_MoreInfo")}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#0078D4", textDecoration: "none" }}
								onClick={(e) => { e.stopPropagation(); }}
								tabIndex={props.checked ? 0 : -1}
							>
								{getLocalizedValueV2("RIN_MoreInfo")}
							</a>
							<span>
								<img src={LinkOutVector} alt="" />
							</span>
						</div>}
				</div>
			</div>
			<div className="c-stepper__divider"></div>
		</div>
	);
}
