import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "@fluentui/react/lib/Label";
import { ColorClassNames, MessageBar, MessageBarType } from "@fluentui/react";
import DragAndDrop from "./DragAndDrop/DragAndDrop";
import path from "path";
import { MaxFileCountAllowed } from "../../../Constants/CommonConstants";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { renderDescriptionLabel } from "../../Home/Home";
import {
	CustomLabelProps,
	CustomLabel,
} from "../DigitalSecurity/IncidentSpecificFieldsv2/IncidentSpecificFieldsv2";
import {
	fileSelector,
	setFileValidationErrorState,
	clearErrorStates,
	removeFile,
	setSizeInBytes,
	FileObject,
	addFileError,
	setFiles,
} from "../../FileUpload/FileUploadSlice";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";

interface FileUploadProps {
	totalAllowedSizeInMB: number;
	allowdSizePerFileInMB: number;
	allowedFileTypes: string[];
	isDigitalIncType: boolean;
	allowedFileNameLength: number;
	isPhishingEmailIncident?: boolean;
	showCallOut?: boolean;
	callOutComponent?: JSX.Element;
	disclaimerToBeShown?: string | null;
}

export default function FileUploadv2({
	totalAllowedSizeInMB,
	allowdSizePerFileInMB,
	allowedFileTypes,
	isDigitalIncType,
	allowedFileNameLength,
	isPhishingEmailIncident,
	showCallOut,
	callOutComponent,
	disclaimerToBeShown,
}: FileUploadProps) {
	const fileDetails = useSelector(fileSelector);
	const fileInputRef = React.createRef<HTMLInputElement>();
	const fileValidationErrorMessage = (
		<FieldValidationDescription
			errorMessage={fileDetails.errorStates.validationError}
			descriptionColor={"#e50000"}
			iconName={"ErrorBadge"}
		/>
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (
			fileDetails.sizeInBytes <= totalAllowedSizeInMB * 1024 * 1024 &&
			fileDetails.files.length <= MaxFileCountAllowed
		) {
			dispatch(setFileValidationErrorState(""));
		}
	}, [fileDetails.sizeInBytes, fileDetails.files.length]);

	const handleFileRemoval = (id: number, size: number, name: string) => {
		if (document.querySelector("div[class='visually-hidden']") != null) {
			document.querySelector("div[class='visually-hidden']")!.textContent =
				getLocalizedValueV2("RIN_SuccessfullyDeletedAttachment") + name;
		}
		dispatch(clearErrorStates());
		dispatch(removeFile(id));
		dispatch(setSizeInBytes(fileDetails.sizeInBytes - size));
		fileInputRef?.current?.focus();
	};
	const Files = (files: FileObject[]): JSX.Element => {
		files = Array.from(files);
		return (
			<div style={{ marginTop: "20px" }}>
				{files.map((file) => (
					<FileAttachment file={file} handleFileRemoval={handleFileRemoval} />
				))}
			</div>
		);
	};

	var finalFiles: FileObject[] = [];
	const uploadFileAsFormData = async (files: File[]) => {
		if (document.querySelector("div[class='visually-hidden']") != null) {
			if (files.length > 1) {
				document.querySelector("div[class='visually-hidden']")!.textContent =
					getLocalizedValueV2("RIN_SuccessfullyUploaded") +
					" " +
					files.length +
					getLocalizedValueV2("RIN_Attachments");
			} else {
				document.querySelector("div[class='visually-hidden']")!.textContent =
					getLocalizedValueV2("RIN_SuccessfullyUploadedAttachment") +
					files[0].name;
			}
		}
		let newFileId =
			fileDetails.files.reduce((p, c, i) => Math.max(p, c.id), 0) + 1;
		for (var i = 0; i < files.length; i++) {
			if (fileDetails.files.find((x) => x.fileName == files[i].name)) {
				continue;
			}

			const toBase64 = (file: File) =>
				new Promise((resolve) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
				});
			await toBase64(files[i]).then((result: any) => {
				finalFiles.push({
					id: newFileId++,
					fileContent: (result as string)?.split(",")[1],
					fileName: files[i].name,
					fileSize: files[i].size,
				});
			});
		}
	};
	const handleDrop = (files: File[]) => {
		try {
			dispatch(clearErrorStates());
			var size = fileDetails.sizeInBytes;
			var isAttachmentValid = true;

			for (var i = 0; i < files.length; i++) {
				if (fileDetails.files.find((x) => x.fileName == files[i].name)) {
					continue;
				}
				if (
					allowedFileTypes.find(
						(s) => s === path.extname(files[i].name)?.toLowerCase()
					) === undefined
				) {
					dispatch(
						addFileError(
							files[i].name +
							" " +
							getLocalizedValueV2("RIN_IsNotASupportedAttachmentType")
						)
					);
					isAttachmentValid = false;
					break;
				}
				if (files[i].size > allowdSizePerFileInMB * 1024 * 1024) {
					dispatch(
						addFileError(
							files[i].name +
							" " +
							getLocalizedValueV2("RIN_ExceedsTheAllowedSizeOf") +
							" " +
							allowdSizePerFileInMB +
							"MB"
						)
					);
					isAttachmentValid = false;
					break;
				}
				if (files[i].name.length > allowedFileNameLength) {
					dispatch(
						addFileError(
							files[i].name +
							" " +
							getLocalizedValueV2(
								"RIN_ExceedsTheAllowedAttachmentNameLength"
							) +
							" " +
							allowedFileNameLength +
							" " +
							getLocalizedValueV2("RIN_Characters")
						)
					);
					isAttachmentValid = false;
					break;
				}
				if (files[i].size === 0) {
					dispatch(
						addFileError(
							files[i].name +
							" " +
							getLocalizedValueV2("RIN_IsInvalidAsItDoesNotHaveAnyContent")
						)
					);
					isAttachmentValid = false;
					break;
				}
				size += files[i].size;
			}
			if (size > totalAllowedSizeInMB * 1024 * 1024) {
				dispatch(
					setFileValidationErrorState(
						getLocalizedValueV2("RIN_TotalAttachmentSizeExceeded")
					)
				);
			} else if (
				fileDetails.files.length + files.length >
				MaxFileCountAllowed
			) {
				dispatch(
					setFileValidationErrorState(
						getLocalizedValueV2("RIN_FileUploadFileCountError")
					)
				);
			} else {
				dispatch(setFileValidationErrorState(""));
			}
			var fileArray = Array.from(files);

			if (
				isAttachmentValid &&
				fileArray.length > 0 &&
				fileArray.length + fileDetails.files.length <= MaxFileCountAllowed
			) {
				uploadFileAsFormData(fileArray).then((response) => {
					dispatch(setFiles([...fileDetails.files, ...finalFiles]));
				});
				dispatch(setSizeInBytes(size));
			}
		} catch (e) { }
	};

	const getRoundedOffTotalFileSize = () => {
		if (fileDetails.sizeInBytes == 0) return <>0KB</>;

		if (fileDetails.sizeInBytes < 1024 * 1024)
			return <>{(fileDetails.sizeInBytes / 1024).toFixed(2)}KB</>;

		return <>{(fileDetails.sizeInBytes / (1024 * 1024)).toFixed(2)}MB</>;
	};
	const attachment_CalloutProps: CustomLabelProps = {
		id: "file-input-",
		calloutDescription:
			"<div style='text-align: left'><div class='headding'><b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo1") +
			"</b></div><ol class='orderedList' style='list-style: decimal; text-align: left; padding-left: 12px;line-height:1.6'><li>" +
			getLocalizedValueV2("RIN_EmailUploadInfo2") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo3") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailuploadInfo4") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo5") +
			"</b>.</li><li>" +
			getLocalizedValueV2("RIN_EmailUploadInfo6") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo7") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo8") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo9") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo10") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo9") +
			"</b>, " +
			getLocalizedValueV2("RIN_EmailUploadInfo11") +
			"</li><li>" +
			getLocalizedValueV2("RIN_EmailUploadInfo12") +
			" <b>" +
			getLocalizedValueV2("RIN_FileName") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo13") +
			"</li><li>" +
			getLocalizedValueV2("RIN_EmailUploadInfo6") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo14") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo15") +
			" <b>" +
			getLocalizedValueV2("RIN_Save") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo16") +
			"</li><li>" +
			getLocalizedValueV2("RIN_EmailUploadInfo17") +
			" <b>" +
			getLocalizedValueV2("RIN_EmailUploadInfo18") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo19") +
			" <b>" +
			getLocalizedValueV2("RIN_Submit") +
			"</b> " +
			getLocalizedValueV2("RIN_EmailUploadInfo20") +
			"</li></ol></div>",
		moreInfoLink: undefined,
		label: getLocalizedValueV2("RIN_SupportingAttachmentsDesc"),
		labelStyle: {
			fontWeight: 400,
			fontSize: 14,
			marginBottom: 8,
		},
	};

	return (
		<div
			style={{
				marginBottom: "28px",
			}}
		>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Label id="file-upload-v2">
					{`${getLocalizedValueV2("RIN_SupportingAttachments")} ${isPhishingEmailIncident ? "" : " - " + getLocalizedValueV2("RIN_Optional")}`}
				</Label>
				{showCallOut === true && callOutComponent}
			</div>
			{!isPhishingEmailIncident ? (
				renderDescriptionLabel(
					{
						label: `${getLocalizedValueV2(
							"RIN_SupportingAttachmentsDesc"
						)} (${allowedFileTypes.map((x) => " " + x)}):`,
					},
					"file-input-label"
				)
			) : (
				<CustomLabel {...attachment_CalloutProps} />
			)}
			{disclaimerToBeShown && (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Label>{disclaimerToBeShown}</Label>
				</div>
			)}
			<DragAndDrop
				handleDrop={handleDrop}
				isInvalidState={fileDetails.errorStates.validationError.length > 0}
				fileInputRef={fileInputRef}
				allowedFileTypes={allowedFileTypes}
			/>
			<label
				style={{
					color: "inherit",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{getLocalizedValueV2("RIN_TotalAllowedSize")}: {totalAllowedSizeInMB}MB{" "}
			</label>
			&nbsp;&nbsp;
			<span
				style={{
					color: "inherit",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{" "}
				{getLocalizedValueV2("RIN_ConsumedSize")}:{" "}
			</span>
			<span
				style={{
					color: "inherit",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{getRoundedOffTotalFileSize()}
			</span>
			{fileDetails.files.length > 0 && Files(fileDetails.files)}
			{fileDetails.errorStates.fileErrors.length > 0 && (
				<div style={{ marginTop: "12px", marginBottom: "4px" }}>
					{fileDetails.errorStates.fileErrors.map((error) => (
						<div style={{ marginTop: "4px" }}>
							<FieldValidationDescription
								errorMessage={error}
								descriptionColor="#e50000"
								iconName={"Info"}
							/>
						</div>
					))}
				</div>
			)}
			{fileDetails.errorStates.validationError.length > 0 &&
				fileValidationErrorMessage}
		</div>
	);
}

function FileAttachment({
	file,
	handleFileRemoval,
}: {
	file: FileObject;
	handleFileRemoval: Function;
}) {
	return (
		<MessageBar
			messageBarType={MessageBarType.info}
			isMultiline={false}
			onDismiss={() => handleFileRemoval(file.id, file.fileSize, file.fileName)}
			dismissButtonAriaLabel={
				getLocalizedValueV2("RIN_RemoveAttachment") + " " + file.fileName
			}
			truncated={false}
			styles={{
				root: {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					marginBottom: "4px",
				},
				iconContainer: {
					display: "none",
				},
				content: {
					color: "inherit",
					fontSize: "14px",
				},
				expand: {
					color: "inherit",
					selectors: {
						".ms-Button-icon": {
							color: "inherit",
						},
					},
				},
				dismissal: {
					color: "inherit",
					selectors: {
						".ms-Button-icon": {
							color: "Highlight",
						},
						"@media (forced-colors: active)": {
							".ms-Button-icon": {
								color: "Text !important",
							},
						},
					},
				},
			}}
			expandButtonProps={{
				ariaLabel: getLocalizedValueV2("RIN_ExpandAttachment") + " " + file.fileName,
			}}
			dismissIconProps={{
				iconName: "Clear",
			}}
		>
			{file.fileSize < 1024 * 1024 ? (
				<>{(file.fileSize / 1024).toFixed(2)}KB</>
			) : (
				<>{(file.fileSize / (1024 * 1024)).toFixed(2)}MB</>
			)}{" "}
			- {file.fileName}
		</MessageBar>
	);
}