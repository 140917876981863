import { put, select, takeEvery } from "redux-saga/effects";
import { setFeatureFlagsLoading, setIsLoading, setUserFlightStatusLoading, setUserTestInProdStatusLoading } from "../Components/Loader/LoaderSlice";
import {
  getUserFlightStatus,
  getUserTestInProdStatus,
  getUserProfile,
  setUserFlightStatus,
  setUserProfile,
  setUserProfilePicture,
  UserFlightStatusSelector,
  setUserTestInProdStatus,
} from "../Components/UserProfile/UserProfileSlice";
import { SOURCE } from "../Constants/CommonConstants";
import { FetchUserProfile } from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings, UserProfile } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { handleError } from "../Services/errorHandlerService";
import { GET } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";
import { graphConfig } from "../Auth/AuthConfig";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getFeatureFlags, serviceCommunicationSettingsSelector, setFeatureFlags } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { fetchAPIAccessToken } from "../Services/AuthService";

export const getImageUrl = (dataUrl: string): string => {
  const bstr = atob(dataUrl);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  var blob = new Blob([u8arr], { type: "image/jpeg" });
  return URL.createObjectURL(blob);
};

export function* GetUserPicture(token: string) {
  try {
    var response = yield axios.get(graphConfig.graphMePhotoEndpoint, {
      headers: { Authorization: "Bearer " + token },
      responseType: "blob",
    });
    const url = window.URL || window.webkitURL;
    const blobUrl = url.createObjectURL(response.data);
    yield put(setUserProfilePicture(blobUrl));
  } catch (e) {
  }
}

export function* GetUserProfile({ payload }: PayloadAction<string>) {
  try {
    var apiResponse = yield GET(graphConfig.graphMeEndpoint, "/", payload);
    if (apiResponse != null || apiResponse !== undefined) {
      yield GetUserPicture(payload);
      let isUserMemberOfFlight: boolean = yield select(UserFlightStatusSelector);
      let userProfile: UserProfile = {
        displayName: apiResponse.displayName,
        jobTitle: apiResponse.jobTitle,
        onPremisesUserPrincipalName: apiResponse.userPrincipalName,
        picture: "",
        pictureUrl: "",
        isUserMemberOfFlight: isUserMemberOfFlight
      };
      yield put(setUserProfile(userProfile));
    } else {
      yield handleError(false, false, undefined);
    }
  } catch (e) {
    let exceptionModel = GetExceptionModel(
      FetchUserProfile,
      SOURCE,
      "FetchUserProfile",
      "Fetch user profile",
      "FetchUserProfileAsync",
      true,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetUserProfile() {
  yield takeEvery(getUserProfile, GetUserProfile);
}

export function* GetUserFlightStatus() {
  let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
  try {
    yield put(setUserFlightStatusLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel = yield GET(serviceCommunicationSettings.apiParameters.apiBaseAddress, "/api/Global/userFlightStatus", accessToken);
    if (apiResponse != null || apiResponse !== undefined) {
      let isUserPartOfFlight = (apiResponse.responseObject ?? false) as unknown as boolean;
      yield put(setUserFlightStatus([isUserPartOfFlight, true]))
      // Disable new experience for the users that are no longer a part of flight.
      if(!isUserPartOfFlight)
        localStorage.removeItem("hasUserSelectedNewExperience")
    } else {
      yield handleError(false, false, undefined);
    }
    yield put(setUserFlightStatusLoading(false));
  } catch (e) {
    yield put(setUserFlightStatusLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchUserProfile,
      SOURCE,
      "FetchUserFlightStatus",
      "Fetch user flight status",
      "GetUserFlightStatus",
      true,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetUserFlightStatus() {
  yield takeEvery(getUserFlightStatus, GetUserFlightStatus);
}

export function* GetUserTestInProdStatus() {
  let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
  try {
    yield put(setUserTestInProdStatusLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel = yield GET(serviceCommunicationSettings.apiParameters.apiBaseAddress, "/api/Global/userTIPStatus", accessToken);
    if (apiResponse != null || apiResponse !== undefined) {
      let isUserPartOfTestInProdGroup = (apiResponse.responseObject ?? false) as unknown as boolean;
      yield put(setUserTestInProdStatus(isUserPartOfTestInProdGroup))
    } else {
      yield handleError(false, false, undefined);
    }
    yield put(setUserTestInProdStatusLoading(false));
  } catch (e) {
    yield put(setUserTestInProdStatusLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchUserProfile,
      SOURCE,
      "FetchUserTIPStatus",
      "Fetch user TIP status",
      "GetUserTIPStatus",
      true,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetUserTestInPRodStatus() {
  yield takeEvery(getUserTestInProdStatus, GetUserTestInProdStatus);
}


export function* GetFeatureFlags() {
  let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
  try {
    yield put(setFeatureFlagsLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel = yield GET(serviceCommunicationSettings.apiParameters.apiBaseAddress, "/api/Global/featureFlags", accessToken);
    if (apiResponse != null || apiResponse !== undefined) {
      let featureFlags = (apiResponse.responseObject ?? "") as unknown as string;
      yield put(setFeatureFlags(featureFlags));
    } else {
      yield handleError(false, false, undefined);
    }
    yield put(setFeatureFlagsLoading(false));
  } catch (e) {
    yield put(setFeatureFlagsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchUserProfile,
      SOURCE,
      "FetchFeatureFlags",
      "Fetch feature flags",
      "GetFeatureFlags",
      true,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetFeatureFlags() {
  yield takeEvery(getFeatureFlags, GetFeatureFlags);
}