import { put, select, takeEvery } from "redux-saga/effects";
import {
  getIncidentCategories,
  setIncidentCategories,
} from "../Components/IncidentCategory/IncidentCategorySlice";
import { setIncidentCategoriesLoading, setIsLoading } from "../Components/Loader/LoaderSlice";
import { serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE, STEP } from "../Constants/CommonConstants";
import { FetchIncidentCategories } from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { IncidentCategory } from "../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError } from "../Services/errorHandlerService";
import { GET } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";

export function* FetchIncidentCategoriesAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setIncidentCategoriesLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/getincidentcategories",
      accessToken
    );
    yield put(
      setIncidentCategories(
        apiResponse?.responseObject == null ||
          apiResponse?.responseObject === undefined
          ? []
          : (apiResponse?.responseObject as IncidentCategory[])
      )
    );
    if (!apiResponse?.responseSuccess) {
      yield handleError(true, false, undefined);
    }
    yield put(setIncidentCategoriesLoading(false));
  } catch (e) {
    yield put(setIncidentCategoriesLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchIncidentCategories,
      SOURCE,
      "Global" + STEP,
      "Fetch incident categories",
      "FetchIncidentCategoriesAsync",
      true,
      "Outer",
      null
    );
    yield handleError(true, false, exceptionModel);
  }
}

export function* watchGetIncidentCategories() {
  yield takeEvery(getIncidentCategories, FetchIncidentCategoriesAsync);
}
