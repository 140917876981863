import React, {Component, Fragment} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from '../../Services/telemetryService';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

class TelemetryProvider extends Component<any, any> {
    state = {
        initialized: false
    };

    componentDidMount() {
        const history = this.props.history;
        const { initialized } = this.state;
        const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export interface RoutedProps<Params = any, State = any> {
    location: State;
    navigate: NavigateFunction;
    params: Params;
}

export function withRouter<P extends RoutedProps>(Child: React.ComponentClass<P>) {
    return (props: Omit<P, keyof RoutedProps>) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return <Child {...props as P} navigate={navigate} location={location} params={params} />;
    }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));