import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimeZoneListItem } from "../../Contracts/TypesAndInterfaces/TimeZoneListItem";
export interface TimeZonesModel {
    timeZones: TimeZoneListItem[];
}

const initialState: TimeZonesModel = {
    timeZones: []
}
const TimeZonesDetailsSlice = createSlice({
    name: "TimeZonesDetails",
    initialState,
    reducers: {
        getTimeZones: () => {
        },
        setTimeZones: (state, { payload }: PayloadAction<TimeZoneListItem[]>) => {
            state.timeZones = payload;
        },
    }
})
export const { getTimeZones, setTimeZones } = TimeZonesDetailsSlice.actions;
export default TimeZonesDetailsSlice.reducer;
export const TimeZonesSelector = (state: { TimeZonesDetails: TimeZonesModel }) => state.TimeZonesDetails.timeZones;
