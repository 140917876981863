import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setIsTestIncident } from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { HelpSectionDetailsSelector } from "../../HelpPanel/HelpSectionSlice";
import { LoaderSelector, setInitializationCompletion } from "../../Loader/LoaderSlice";
import { setIsTestPhysicalIncidentIncident } from "../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { featureFlagsSelector, startIncidentSubmissionFlow } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { ScrollableContentRootId } from '../../../Constants/CommonConstants';
import Headerv2 from "../Headerv2/Headerv2";
import "./Shellv2.css";
export default function Shellv2() {
    let navigate = useNavigate();
    const isLoading = useSelector(LoaderSelector).isLoading;
    const helpSections = useSelector(HelpSectionDetailsSelector);
    const dispatch = useDispatch();
    const featureFlags = useSelector(featureFlagsSelector);
    const [isMobileExperienceInPilot, setPilotStatus] = React.useState<boolean | undefined>(featureFlags?.includes("MobileExperiencePilot"));
    
    React.useEffect(()=>{
		if(featureFlags && isMobileExperienceInPilot != featureFlags?.includes("MobileExperiencePilot")){
			setPilotStatus(featureFlags?.includes("MobileExperiencePilot"));
		}
	},[featureFlags]);

    React.useEffect(() => {
        document.body.className = "v2";
    }, []);

    React.useEffect(()=>{
        if(!isMobileExperienceInPilot) return;
        // set IsTestIncident on switching to new experience.
        dispatch(setIsTestIncident(true));
        dispatch(setIsTestPhysicalIncidentIncident(true));
    },[isMobileExperienceInPilot]);

    React.useEffect(()=>{
        dispatch(startIncidentSubmissionFlow());
    }, []);

    React.useEffect(()=>{
        if(!isMobileExperienceInPilot) return;
        const isNewExperienceSelected = localStorage.getItem("hasUserSelectedNewExperience") === 'true';
        if(!isNewExperienceSelected)
            navigate("/");
    }, [isMobileExperienceInPilot]);



    return (
        <div className="shellv2">
            <div className="shellv2__header">
                <Headerv2 />
            </div>
            <main tabIndex={-1} id={ScrollableContentRootId} className="shellv2__scrollable ms-Grid" dir="ltr">
                <div style={{marginBottom: 64}}>
                <Outlet />
                </div>
            </main>
        </div>
    );
}


