export function isSessionExpired() {}

export function ReturnSessionObjectIfExists<T>(
  objectName: string
): T | undefined {
  if (
    sessionStorage.getItem(objectName) === null ||
    sessionStorage.getItem(objectName) === undefined
  ) {
    return undefined;
  } else {
    let jsonObject = JSON.parse(sessionStorage.getItem(objectName)!);
    let typedObject: T = <T>jsonObject;
    return typedObject;
  }
}

export function ClearObjectInSessionStorage(objectName: string) {
  sessionStorage.removeItem(objectName);
}

export function ClearSessionStorage() {
  sessionStorage.clear();
}

export function SetItemInSessionStorage<T>(objectName: string, object: T) {
  sessionStorage.setItem(objectName, JSON.stringify(object));
}

export function getExpirationTimeFromToken(token: string): number {
    // get the expiration time from the token and if it fails then set the expiration time to one hour from the current time
    return parseJwt(token)?.exp ?? new Date().getTime() / 1000 + 3600;
}

const parseJwt = (token: string): { exp: number } | null => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};