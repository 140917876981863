import { Coachmark, DirectionalHint, TeachingBubbleContent, Toggle } from "@fluentui/react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SiteNotificationType } from "../../Contracts/Enums/NotificationEnums";
import { SiteNotificationResponseViewModel } from "../../Contracts/TypesAndInterfaces/SiteNotificationResponseViewModel";
import { useBoolean } from "../../Hooks/useBoolean";
import { LoaderSelector } from "../Loader/LoaderSlice";
import { getLocalizedValue } from "../../Services/localizationService";
import { IsSiteNotificationsAPICallSuccessSelector, SiteNotificationDetailsSelector } from "../Notifications/NotificationsSlice";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import { UserProfileSelector } from "../UserProfile/UserProfileSlice";
import "./Welcome.css";
import { getLocalizedValueV2 } from "../../Services/localizationServiceV2";
import { featureFlagsSelector } from "../UserProfile/ServiceCommunicationSettingsSlice";

export default function Welcome() {
	const siteNotificationDetails = useSelector(SiteNotificationDetailsSelector);
	const LoaderDetails = useSelector(LoaderSelector);
	const isSiteNotofocationAPICallSuccess = useSelector(IsSiteNotificationsAPICallSuccessSelector);
	const [RINMessage, setRINMessage] = React.useState("");
	const RIN_BACKUP_MESSAGE = getLocalizedValueV2("RIN_NS_TitleCaption");
	const [caption, setCaption] = React.useState<SiteNotificationResponseViewModel | undefined>(undefined);
	const [isCoachmarkVisible, { setFalse: hideCoachmark, setTrue: showCoachmark, toggle: toggleCoachmark }] = useBoolean(false);
	const [isCoachmarkShownOnLoad, setCoachmarkShownOnLoad] = React.useState(false);
	const targetButton = React.useRef<HTMLDivElement>(null);
	const userProfileDetails = useSelector(UserProfileSelector);
	const positioningContainerProps = React.useMemo(
		() => ({
			directionalHint: DirectionalHint.leftTopEdge,
			doNotLayer: true,
		}),
		[]
	);
	React.useEffect(() => {
		if (LoaderDetails.isLoading === 0 && userProfileDetails.isUserMemberOfFlight && !isCoachmarkShownOnLoad) {
			showCoachmark();
			setCoachmarkShownOnLoad(true);
		}
	}, [LoaderDetails.isLoading]);
	React.useEffect(() => {
		if (isSiteNotofocationAPICallSuccess) {
			setCaption(siteNotificationDetails.find((x) => x.notificationTypeId == SiteNotificationType.caption));
			if (caption) setRINMessage(caption.description);
			else setRINMessage(RIN_BACKUP_MESSAGE);
		} else if (isSiteNotofocationAPICallSuccess === false) {
			setRINMessage(RIN_BACKUP_MESSAGE);
		}
	}, [isSiteNotofocationAPICallSuccess]);

	return (
		<div className="welcome-container">
			<div className="welcome">
				<h1 className="heading" id="mainRegionHeader" tabIndex={-1}>
					Report It Now
				</h1>
				<span className="desc" style={{ color: "white" }}>
					<RichTextRenderer html={getLocalizedValue("RIN_NS_TitleCaption")} />
				</span>
			</div>
			{userProfileDetails.isUserMemberOfFlight && (
				<div className="new-expereience-container" style={{ zIndex: 1 }} ref={targetButton}>
					<NewExperienceSelection />
					{isCoachmarkVisible && (
						<Coachmark
							target={targetButton.current}
							positioningContainerProps={positioningContainerProps}
							ariaAlertText={getLocalizedValueV2("RIN_ACoachmarkHasAppeared")}
							ariaDescribedBy="coachmark-desc1"
							ariaLabelledBy="coachmark-label1"
							ariaDescribedByText={getLocalizedValueV2("RIN_OpenNotification")}
							ariaLabelledByText={getLocalizedValueV2("RIN_CoachmarkNotification")}
							color="white"
							// styles={{childrenContainer:{
							//     width: 300,
							// }}}
						>
							<TeachingBubbleContent
								headline={getLocalizedValueV2("RIN_TryOurNewExperience")}
								hasCloseButton
								closeButtonAriaLabel={getLocalizedValueV2("RIN_Close")}
								onDismiss={hideCoachmark}
								ariaDescribedBy="example-description1"
								ariaLabelledBy="example-label1"
								styles={{
									content: {
										backgroundColor: "#42855B !important",
									},
								}}
							>
								{getLocalizedValueV2("RIN_CoachmarkNotificationContent")}
							</TeachingBubbleContent>
						</Coachmark>
					)}
				</div>
			)}
		</div>
	);
}

export function NewExperienceSelection() {
	const navigate = useNavigate();
	const [goToNewExperience, setGoToNewExperience] = React.useState(false);
	const userProfileDetails = useSelector(UserProfileSelector);
	const featureFlags = useSelector(featureFlagsSelector);
	const [isMobileExperienceInPilot, setPilotStatus] = React.useState<boolean>(false);

	React.useEffect(()=>{
		if(featureFlags){
			setPilotStatus(featureFlags?.includes("MobileExperiencePilot"));
		}
	},[featureFlags]);

	React.useEffect(() => {
		const isNewExperienceSelected = localStorage.getItem("hasUserSelectedNewExperience") === "true";
		setGoToNewExperience(isNewExperienceSelected);
	}, []);

	const handleNewExperienceToggleChange = (e: any, checked: boolean | undefined) => {
		localStorage.setItem("hasUserSelectedNewExperience", "" + checked);
		setGoToNewExperience(checked ?? false);
		if (checked) navigate("/v2");
		else navigate("/");
	};

	return (
		<>
			{isMobileExperienceInPilot && userProfileDetails.isUserMemberOfFlight && (
				<Toggle
					label={getLocalizedValueV2("RIN_NewExperience")}
					inlineLabel
					role="checkbox"
					// onText="on"
					// offText="off"
					checked={goToNewExperience}
					onChange={handleNewExperienceToggleChange}
				/>
			)}
		</>
	);
}
