import {
    ChoiceGroup,
    IChoiceGroupOption,
    Label,
    TextField,
} from "@fluentui/react";
import * as React from "react";
import { renderDescriptionLabel } from "../../Home/Home";
import { useDispatch, useSelector } from "react-redux";
import { DsDiPhishingEmailInputViewModel, DsDiPhishingEmailLocalizedInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiPhishingEmailInputViewModel";
import {
    ErrorStatesSelector,
    LocalizedPhishingEmailInputSelector,
    PhishingEmailSelector,
    setDigitalIncidentLocalizedPhishingEmailObject,
    setDigitalIncidentPhishingEmailObject,
    setPhishingActionsErrorMessage,
    setPhishingEmailAttachmentUrlOpenedByUser,
    setPhishingEmailDeliveredLocation,
    setPhishingEmailInfoSharedByUser,
} from "../DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { getRadioButtonStyle } from "../../../styles";
import { getLocalizedValue } from "../../../Services/localizationService";




export default function PhishingEmailFields() {
    const labelId: string = "action-taken-details";
    const dispatch = useDispatch();
    const [
        phishingDetails,
        setPhishingDetails,
    ] = React.useState<DsDiPhishingEmailInputViewModel>({
        emailDeliveredLocation: "Inbox",
        infoSharedByUser: "",
        isAttachmentorURLOpenedByUser: false,
    });
    const defaultLocalizedPhishingDetails:DsDiPhishingEmailLocalizedInputViewModel = {
        emailDeliveredLocation:getLocalizedValue("RIN_Inbox"),
        infoSharedByUser:"",
        isAttachmentorURLOpenedByUser:getLocalizedValue("RIN_No")
    }
    const localizedPhishingDetails: DsDiPhishingEmailLocalizedInputViewModel|undefined = useSelector(LocalizedPhishingEmailInputSelector);


    const [
        emailLocation,
        setEmailLocation,
    ] = React.useState<IChoiceGroupOption>();
    const [
        attachmentOpened,
        setAttachmentOpened,
    ] = React.useState<IChoiceGroupOption>();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const phishingEmailSelector = useSelector(PhishingEmailSelector);
    const phishingActionsMessage = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingActions}
            descriptionColor={'white'}
            iconName={"ErrorBadge"}
        />
    );

    const emailDeliveryLocationOptions: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Inbox"), value: "Inbox" },
        { key: "2", text: getLocalizedValue("RIN_JunkMailFolder"), value: "Junk mail folder" },
    ];

    const userActionOptions: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Yes"), value: "Yes" },
        { key: "2", text: getLocalizedValue("RIN_No"), value: "No" },
    ];

    React.useEffect(() => {
        dispatch(setDigitalIncidentPhishingEmailObject(phishingDetails));
        dispatch(setDigitalIncidentLocalizedPhishingEmailObject({...localizedPhishingDetails,...defaultLocalizedPhishingDetails}));
        return () => {
            dispatch(setDigitalIncidentPhishingEmailObject(undefined));
        };
    }, []);

    const handleDeliveryLocationChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setEmailLocation(option);
            dispatch(setPhishingEmailDeliveredLocation((typeof (option.value) === "string" ? option.value ?? "" : "")));
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({...localizedPhishingDetails, emailDeliveredLocation: option.text} as DsDiPhishingEmailLocalizedInputViewModel));
        }
    };

    const handleAttachmentOpenedChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setAttachmentOpened(option);
            if (option.value == "Yes") {
                dispatch(setPhishingEmailAttachmentUrlOpenedByUser(true));
            }
            else {
                dispatch(setPhishingEmailAttachmentUrlOpenedByUser(false));
                dispatch(setPhishingActionsErrorMessage(""));
                dispatch(setPhishingEmailInfoSharedByUser(""));
            }
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({...localizedPhishingDetails, isAttachmentorURLOpenedByUser: option.text} as DsDiPhishingEmailLocalizedInputViewModel));
        }
    };

    const handleInfoChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingActionsErrorMessage(""));
            dispatch(setPhishingEmailInfoSharedByUser(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({...localizedPhishingDetails, infoSharedByUser: newValue} as DsDiPhishingEmailLocalizedInputViewModel));
        }
    };



    return (
        <div className="phishing-email-fields">
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={emailDeliveryLocationOptions}
                        label={getLocalizedValue("RIN_EmailDeliveryLocation")}
                        selectedKey={emailLocation?.key}
                        onChange={handleDeliveryLocationChange}
                        defaultSelectedKey="1"
                        styles={getRadioButtonStyle(222)}
                    />
                </div>
            </div>

            <div id="your-actions-section" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-row" style={{ marginBottom: "2px" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <Label>{getLocalizedValue("RIN_YourActions")}</Label>
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "20px" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <Label
                            id="url-clicked"
                            style={{ fontWeight: "normal" }}
                        >
                            {getLocalizedValue("RIN_PhishingEmailQuestion1")}
                        </Label>
                        <ChoiceGroup
                            options={userActionOptions}
                            ariaLabelledBy="url-clicked"
                            selectedKey={attachmentOpened?.key}
                            onChange={handleAttachmentOpenedChange}
                            defaultSelectedKey="2"
                            styles={getRadioButtonStyle(128)}
                        />
                    </div>
                </div>

                {
                    attachmentOpened?.value == "Yes" &&
                    <TextField
                        id="phishingEmailDetailsID"
                        aria-required={true}
                        aria-labelledby={labelId}
                        label={getLocalizedValue("RIN_PhishingEmailQuestion2")}
                        placeholder={getLocalizedValue("RIN_PhishingEmailQuestion2PlaceHolder")}
                        multiline
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(props, labelId)
                        }
                        value={phishingEmailSelector?.infoSharedByUser}
                        onChange={handleInfoChange}
                        onRenderDescription={() => phishingActionsMessage}
                        styles={
                            errorStatesDetails.phishingActions
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />}
            </div>
        </div>
    );
}
