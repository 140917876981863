import {
    DefaultButton,
    DialogType,
    IDialogContentProps,
    IModalProps,
} from "@fluentui/react";
import * as React from "react";
import { useSelector } from "react-redux";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import { getLocalizedValue } from "../../../Services/localizationService";
import { buttonStyle, whiteLinkStyle } from "../../../styles";
import { HelpSectionSelector } from "../../HelpPanel/HelpSectionSlice";


export default function NextStepsDialog(props: any) {
    const {
        hideNextStepsDialog,
        hidePhishingSimulationExperience,
    } = props;

    const handleContinueButtonClicked = () => {
        hideNextStepsDialog();
        hidePhishingSimulationExperience();
        document.getElementById("incident-information-container")?.focus();
    };

    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const phishingEmailInstructionsSection = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.find(s => s.panelId === PanelEnums.DigitalIncidentForm) : undefined;
    const phishingAwarenessLink = phishingEmailInstructionsSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === phishingEmailInstructionsSection.sectionId && s.linkId == 22);

    // using the hardcoded value if data not received from API.
    let learnMoreLinkText = <a href="https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Don%27t-get-fooled-by-phishing.aspx" target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
    if (phishingAwarenessLink !== undefined) {
        if (phishingAwarenessLink.openInNewTab === true)
            learnMoreLinkText = <a href={phishingAwarenessLink.moreInfoUrl} target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
        else
            learnMoreLinkText = <a href={phishingAwarenessLink.moreInfoUrl} rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
    }

    React.useEffect(() => {
        document.getElementById("nextStepsDialog")?.focus();
    })
    return (
        <>
            <p tabIndex={0} id="nextStepsDialog" style={{ marginBottom: 28 }}>
                {getLocalizedValue("RIN_PhishingEmailInfo1")}
            </p>
            <p style={{ marginBottom: 28 }}>
                {getLocalizedValue("RIN_PhishingEmailInfo2")}
            </p>
            <p>
                {getLocalizedValue("RIN_PhishingEmailInfo3")}
                <br />
                {learnMoreLinkText}
            </p>

            <div className="ms-Grid-row submission-buttons">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <DefaultButton
                        onClick={handleContinueButtonClicked}
                        styles={{
                            root: buttonStyle,
                            rootHovered: buttonStyle
                        }}
                    >
                        {getLocalizedValue("RIN_Continue")}
                    </DefaultButton>
                </div>

            </div>

        </>
    )
}