import { newGuid } from "@microsoft/applicationinsights-core-js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceCommunicationSettings } from "../../Contracts/ServiceModels/ServiceCommunicationSettings";

const initialState: ServiceCommunicationSettings = {
  apiParameters: {
    apiBaseAddress: process.env.REACT_APP_API_BASE_ADDRESS!,
  },
  ocvParameters: {
    appId: Number(process.env.REACT_APP_OCV_APP_ID!),
    envName: process.env.REACT_APP_OCV_ENV!,
  },
  appInsightsKey: process.env.REACT_APP_APP_INSIGHTS_KEY!,
  appInsightsIncidentSubmissionFlowId: newGuid(),
  appInsightsIncidentStartingTimeStamp: new Date().getTime(),
};

const ServiceCommunicationSettingsSlice = createSlice({
  name: "serviceCommunicationSettings",
  initialState,
  reducers: {
    setServiceCommunicationSettings: (
      state,
      { payload }: PayloadAction<ServiceCommunicationSettings>
    ) => {
      state.apiParameters = payload.apiParameters;
      state.ocvParameters = payload.ocvParameters;
      state.appInsightsKey = payload.appInsightsKey;
    },
    setApiBaseAddress: (state, { payload }: PayloadAction<string>) => {
      state.apiParameters.apiBaseAddress = payload;
    },
    startIncidentSubmissionFlow: (state)=>{
      state.appInsightsIncidentSubmissionFlowId = newGuid();
      state.appInsightsIncidentStartingTimeStamp = new Date().getTime();
    },
    getFeatureFlags: ()=>{},
    setFeatureFlags: (state, {payload}: PayloadAction<string>)=>{
      state.featureFlags = payload.split(";");
    },
  },
});
export const { setServiceCommunicationSettings, setApiBaseAddress, startIncidentSubmissionFlow, getFeatureFlags, setFeatureFlags } =
  ServiceCommunicationSettingsSlice.actions;
export default ServiceCommunicationSettingsSlice.reducer;
export const serviceCommunicationSettingsSelector = (state: {
  serviceCommunicationSettings: ServiceCommunicationSettings;
}) => state.serviceCommunicationSettings;
export const OcvAppIdSelector = (state: {
  serviceCommunicationSettings: ServiceCommunicationSettings;
}) => state.serviceCommunicationSettings.ocvParameters.appId;
export const OcvEnvNameSelector = (state: {
  serviceCommunicationSettings: ServiceCommunicationSettings;
}) => state.serviceCommunicationSettings.ocvParameters.envName;
export const appInsightsIncidentSubmissionFlowIdSelector = (state:{serviceCommunicationSettings: ServiceCommunicationSettings;})=>state.serviceCommunicationSettings.appInsightsIncidentSubmissionFlowId;
export const appInsightsIncidentSubmissionFlowStartTimeSelector = (state:{serviceCommunicationSettings: ServiceCommunicationSettings;})=>state.serviceCommunicationSettings.appInsightsIncidentStartingTimeStamp;
export const featureFlagsSelector = (state:{serviceCommunicationSettings: ServiceCommunicationSettings;})=>state.serviceCommunicationSettings.featureFlags;