import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SubmissionSuccessState {
    isSuccessPageShown: boolean;
    message: string;
    incidentId?: string;
    advisoryMessage?: string;
}

const initialState: SubmissionSuccessState = {
    isSuccessPageShown: false,
    message: "",
    incidentId: undefined,
    advisoryMessage: undefined,
};

const submissionSuccessSlice = createSlice({
    name: "submittionSuccessSlice",
    initialState,
    reducers: {
        showSuccessPage(state, action: PayloadAction<SubmissionSuccessState>) {
            state.isSuccessPageShown = action.payload.isSuccessPageShown;
            state.message = action.payload.message;
            state.advisoryMessage = action.payload.advisoryMessage;
        },
        hideSuccessPage(state) {
            state.isSuccessPageShown = false;
            state.message = "";
            state.advisoryMessage = undefined;
        },
        setSubmittedIncidentId: (state, { payload }: PayloadAction<string | undefined>) => {
            state.incidentId = payload;
        }
    }
});

export const {
    showSuccessPage,
    hideSuccessPage,
    setSubmittedIncidentId,
} = submissionSuccessSlice.actions;

export const submissionSuccessPageSelector = (state: { SubmissionSuccess: SubmissionSuccessState }) => state.SubmissionSuccess;

export default submissionSuccessSlice.reducer;