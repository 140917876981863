import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdditionalInformation } from "../PhysicalIncidentAdditionalInformation/PhysicalIncidentAdditionalInformation";
import PhysicalIncidentLocationAndTime from "../PhysicalIncidentLocationAndTime/PhysicalIncidentLocationAndTime";
import PhysicalIncidentType from "../PhysicalIncidentType/PhysicalIncidentType";
import { getItemCategoryTypes, getItemMakeTypes, getPersonInvolvementTypes, getVehicleColorTypes, getVehicleInvolvementTypes, getVehicleMakeTypes, getVehicleStyleTypes, PsAdditionalInfoSelector, PsIncidentTypesSelector, PsShowAdditionalInfoSelector } from "./PhysicalIncidentSlice";

export default function PhysicalIncident(props: any) {
    const dispatch = useDispatch();
    const PsAdditionalInfo = useSelector(PsAdditionalInfoSelector);

    const PsIncidentTypes = useSelector(PsIncidentTypesSelector);
    const PsSelectedIncidentTypIdL1 = PsIncidentTypes.selectedincidentTypeIdL1;
    // Silently preload L1 lookups for dropdowns
    React.useEffect(() => {
        if (PsAdditionalInfo.itemMakeTypes.length == 0)
            dispatch(getItemMakeTypes(true));
        if (PsAdditionalInfo.itemCategoryTypes.length == 0)
            dispatch(getItemCategoryTypes(true));
        if (PsAdditionalInfo.personInvolvementTypes.length == 0)
            dispatch(getPersonInvolvementTypes(true));
        if (PsAdditionalInfo.vehicleinvolvementTypes.length == 0)
            dispatch(getVehicleInvolvementTypes(true));
        if (PsAdditionalInfo.vehicleMakeTypes.length == 0)
            dispatch(getVehicleMakeTypes(true));
        if (PsAdditionalInfo.vehicleColorTypes.length == 0)
            dispatch(getVehicleColorTypes(true));
        if (PsAdditionalInfo.vehicleStyleTypes.length == 0)
            dispatch(getVehicleStyleTypes(true));
    }, [])
    const isAdditionalInfoShown = useSelector(PsShowAdditionalInfoSelector);
    return (
        <>
            <PhysicalIncidentType />
            {
                (isAdditionalInfoShown) &&
                <>
                    <PhysicalIncidentLocationAndTime />

                    <AdditionalInformation {...props} />
                </>
            }
        </>
    );
}