import { LoadLocalizationFile } from "../Constants/ErrorCodes";
import { trackException, GetExceptionModel } from "../Services/telemetryService";
let localizations: any = null;
export let localizationsModule: any;
export const getLocaleFiles = () => {


    let culture = 'en-US';//navigator.language;
    let language = culture.split("-")?.[0];
    let cultureLocalizations: any = {};
    let cultureLevelDefaultLocalizations: any = {};
    let defaultLocalizations: any = {};
    let LOCALIZATION_LOADING_SCENARIO = "Loading localization files";
    let SOURCE = "UI";
    let OUTER_CATCH_LOCATION = "OUTER";


    try {
        cultureLocalizations = require("../Localizations/" + language + "/" + culture + ".json");
    }
    catch {
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of culture related localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFiles",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }

    try {
        cultureLevelDefaultLocalizations = require("../Localizations/" + language + "/" + language + ".json");
    }
    catch {
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of language related localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFiles",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }

    try {
        defaultLocalizations = require("../Localizations/en/en.json");
    }
    catch {
        let exceptionModel = GetExceptionModel(LoadLocalizationFile,
            SOURCE,
            "Loading of default english localizations",
            LOCALIZATION_LOADING_SCENARIO,
            "getLocaleFiles",
            false,
            OUTER_CATCH_LOCATION,
            null)
        trackException(exceptionModel);
    }


    localizations = overrideLocalizations(cultureLevelDefaultLocalizations, cultureLocalizations);
    localizations = overrideLocalizations(defaultLocalizations, localizations);

}

const overrideLocalizations = (targetLocalizations: any, sourceLocalizations: any) => {
    return Object.assign(targetLocalizations, sourceLocalizations);
}

export const getLocalizedValue: any = (key: string) => {
    if (!localizations) {
        getLocaleFiles();
    }
    return localizations[key];
}


