import * as React from "react";
import "./UserProfile.css";
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import { useSelector } from "react-redux";
import { UserProfileSelector } from "./UserProfileSlice";

export default function Profile() {
    const userProfileDetails = useSelector(UserProfileSelector);
    const examplePersona: IPersonaSharedProps = {
        imageUrl: userProfileDetails.pictureUrl,
        text: userProfileDetails.displayName,
        secondaryText: userProfileDetails.jobTitle,
        optionalText: userProfileDetails.onPremisesUserPrincipalName,
    };
    return (
        <div className="hide-onmobile">
            {userProfileDetails.onPremisesUserPrincipalName.length > 0 &&
                <Persona
                    {...examplePersona}
                    size={PersonaSize.size100}
                    presence={PersonaPresence.none}
                    imageAlt={userProfileDetails.displayName}
                    styles={{
                        optionalText: { marginTop: '6px', color: 'white' },
                        primaryText: {
                            fontSize: '20px', color: 'white',
                            ':hover': {
                                color: 'white'
                            }
                        },
                        secondaryText: { color: 'white' }
                    }}
                />
            }
        </div>
    );
}