import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepperSteps } from "../../../Constants/CommonConstants";
import {
	DigitalIncidentTypesSelector,
	resetDigitalIncidentInformation,
	SelectedDigitalIncidentTypeL1Selector,
	setDigitalIncidentIncidentTypeId,
	setLocalizedSelectedDigitalIncidentTypeNameL1,
	setSelectedDigitalIncidentTypeL1,
} from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { DefaultButtonAdapter, PrimaryButtonAdapter } from "../Controls";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import styles1 from "../IncidentCategoryv2/IncidentCategoryv2.module.css";
import { useNavigate } from "react-router-dom";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import {
	PsIncidentTypesL1Selector,
	PsResetLevel,
	PsSelectedincidentTypeIdL1Selector,
	resetPhysicalIncidentInformation,
	setPsLocalizedSelectedIncidentTypeNameL1,
	setPsSelectedIncidentTypeIdL1,
} from "../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { clearSovereignDetails, isSovereignIncidentSelector, setIsSovereignIncidentSelected, setSovereignIncidentTypes, setSovereignSelectedIncidentTypeL1 } from "../../Sovereign/SovereignSlice";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { CustomChoiceGroup, ICustomChoiceGroupOption } from "../Controls/CustomChoiceGroup/CustomChoiceGroup";
import { INCIDENT_TYPE_SELECTION_STEP } from "../../../Constants/InstrumentationConstants";
import { trackEvent } from "../../../Services/telemetryService";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { DigitalIncidentTypesLoadingSelector, PsIncidentTypesL1LoadingSelector, SimulationCampaignsLoadingSelector } from "../../Loader/LoaderSlice";
import Loader from "../../Loader/Loader";
import { SimulationCampaignSelector } from "../../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { DigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { ScrollableContentRootId } from '../../../Constants/CommonConstants';
import { getRadioButtonStyle } from "../../../styles";

export function IncidentType1() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const selectedIncidentCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;
	const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
	const physicalIncidentL1Details = useSelector(PsIncidentTypesL1Selector);
	const phishingCampaignDetails = useSelector(SimulationCampaignSelector);
	const date = new Date(new Date().toDateString());
	const [incidentTypes, setIncidentTypes] = useState<ICustomChoiceGroupOption[]>(getIncidentTypes() ?? []);
	const selectedDigitalIncidentTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector);
	const selectedPhysicalIncidentTypeL1 = useSelector(PsSelectedincidentTypeIdL1Selector);
	const [selectedIncidentTypeId, setSelectedIncidentTypeId] = useState<number | undefined>(getSelectedIncidentTypeId());
	const isGovIncident = useSelector(isSovereignIncidentSelector);
	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	useEffect(() => {
		if (selectedDigitalIncidentTypeL1)
			setSelectedIncidentTypeId(selectedDigitalIncidentTypeL1?.typeId);
	}, [selectedDigitalIncidentTypeL1]);
	useEffect(() => {
		if (selectedPhysicalIncidentTypeL1)
			setSelectedIncidentTypeId(selectedPhysicalIncidentTypeL1?.id);
	}, [selectedPhysicalIncidentTypeL1]);

	const isDigitalIncidentTypesLoading = useSelector(DigitalIncidentTypesLoadingSelector);
	const isPhysicalIncidentTypesLoading = useSelector(PsIncidentTypesL1LoadingSelector);
	const isSimulationDetailsLoading = useSelector(SimulationCampaignsLoadingSelector);

	const govOption = { key: "1", text: getLocalizedValueV2("RIN_Yes")};
	const corpOption = { key: "2", text: getLocalizedValueV2("RIN_No")};
	const corpOrGovOptions: IChoiceGroupOption[] = [govOption, corpOption];
	const [
        chosenCorpOrGovOption,
        setChosenCorpOrGovOption,
    ] = React.useState<IChoiceGroupOption>(isGovIncident ? govOption : corpOption);
	const handleCorpOrGovOptionChoiceChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setChosenCorpOrGovOption(option);
			dispatch(setIsSovereignIncidentSelected(option === govOption));
        }
    };

	useEffect(() => {
		let incidentTypeOptions: ICustomChoiceGroupOption[] = getIncidentTypes()
		if (incidentTypeOptions.length > 0) {
			setIncidentTypes(incidentTypeOptions);
			document.getElementById(ScrollableContentRootId)?.scrollTo(0, 0);
		}
		else {
			navigate("/");
		}
	}, [digitalIncidentTypesDetails.incidentTypesL1, physicalIncidentL1Details, phishingCampaignDetails]);

	function getSelectedIncidentTypeId() {
		if (selectedDigitalIncidentTypeL1)
			return selectedDigitalIncidentTypeL1?.typeId;
		if (selectedPhysicalIncidentTypeL1)
			return selectedPhysicalIncidentTypeL1?.id;
		return;
	}

	function getIncidentTypes() {
		let incidentTypeOptions: ICustomChoiceGroupOption[];
		if (selectedIncidentCategoryId === IncidentCategories.Digital) {
			incidentTypeOptions = digitalIncidentTypesDetails.incidentTypesL1.filter(x => x.isVisibleInHelpPanel && !x.isGovType).map(x => {
				let incidentType = {
					key: x.typeId,
					optionDescriptionLocalizationKey: x.descriptionKey,
					optionLabelLocalizationKey: x.nameKey,
					toBeHighlighted: false
				}
				if (x.typeId == DigitalEventTypes.PhishingEMail && phishingCampaignDetails.simulationCampaign !== undefined && phishingCampaignDetails.simulationCampaign !== null && phishingCampaignDetails.simulationCampaign?.isActive &&
					date <= new Date(phishingCampaignDetails.simulationCampaign?.endDate.toDateString()) && date >= new Date(phishingCampaignDetails.simulationCampaign?.startDate.toDateString())
				) {
					incidentType.toBeHighlighted = true;
				}

				return incidentType;
			})
		}
		else if (selectedIncidentCategoryId === IncidentCategories.Physical) {
			incidentTypeOptions = physicalIncidentL1Details.filter(x => x.isVisibleInHelpPanel && !x.isGovType).map(x => {
				return {
					key: x.typeId,
					optionDescriptionLocalizationKey: x.descriptionKey,
					optionLabelLocalizationKey: x.nameKey,
				}
			})
		}
		else
		{
			incidentTypeOptions = [];
		}

		return incidentTypeOptions;
	}

	const handleCreateIncident = (incidentId: number) => {
		if (selectedIncidentCategoryId === IncidentCategories.Digital) {
			if (incidentId === selectedDigitalIncidentTypeL1?.typeId) return;
			dispatch(resetDigitalIncidentInformation(true));
			dispatch(setSelectedIncidentCategory(incidentCategoryDetails.incidentCategories.find((s) => s.categoryId === IncidentCategories.Digital)));
			dispatch(setSelectedDigitalIncidentTypeL1(digitalIncidentTypesDetails.incidentTypesL1.find((s) => s.typeId == incidentId)));
			dispatch(setLocalizedSelectedDigitalIncidentTypeNameL1(getLocalizedValueV2(digitalIncidentTypesDetails.incidentTypesL1.find((s) => s.typeId == incidentId)?.nameKey)));
			dispatch(setDigitalIncidentIncidentTypeId(incidentId));
			navigate(`${incidentId}`);
		} else if (selectedIncidentCategoryId === IncidentCategories.Physical) {
			const option = physicalIncidentL1Details.find((x) => x?.typeId == incidentId);
			dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
			dispatch(setPsSelectedIncidentTypeIdL1({ id: incidentId, perspectiveDbId: option?.perspectiveDbId, label: option?.displayLabel ?? "" }));
			dispatch(setPsLocalizedSelectedIncidentTypeNameL1(getLocalizedValueV2(option?.nameKey)));
			navigate(`${incidentId}`);
		}
	};

	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Type));
	}, []);

	React.useEffect(() => {
		trackEvent({ name: INCIDENT_TYPE_SELECTION_STEP }, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_TYPE_SELECTION_STEP,
			incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime) / 1000,
		});
	}, []);
	return (
		<div className="incidentType1">
			{(isDigitalIncidentTypesLoading || isSimulationDetailsLoading || isPhysicalIncidentTypesLoading) && <Loader />}
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					<div className={styles1.categoryBlock} style={{ backgroundColor: "rgba(250, 250, 250, 1)", paddingTop: 12 }}>
						<div className={styles1.category + " site-margins"}>
							<div className={styles1.categoryDetails}>
								<h2 aria-level={1} className={styles1.categoryHeader} id="incident-type-step-heading">{getLocalizedValueV2("RIN_SelectTheIncidentType")}</h2>
								<ChoiceGroup
									options={corpOrGovOptions}
									label={getLocalizedValueV2("RIN_IsGovIncidentInfo")}
									selectedKey={chosenCorpOrGovOption?.key}
									onChange={handleCorpOrGovOptionChoiceChange}
									styles={getRadioButtonStyle(222)}
								/>
							</div>
						</div>
						<div className="c-stepper__divider"></div>
					</div>
					<CustomChoiceGroup
						options={incidentTypes}
						selectedKey={selectedIncidentTypeId}
						name="incident types"
						onChange={(option) => { setSelectedIncidentTypeId(option!.key) }}
						legendId="incident-type-step-heading"
					/>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginBottom: 16, marginTop: 28 }}>
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3" style={{ display: 'flex', gap: 12 }}>
					<DefaultButtonAdapter onClick={() => navigate("/v2/create")}>{getLocalizedValueV2("RIN_Back")}</DefaultButtonAdapter>{" "}
					<PrimaryButtonAdapter
						disabled={selectedIncidentTypeId === null || selectedIncidentTypeId === undefined}
						onClick={() => {
							if (isGovIncident) {
								dispatch(setSovereignSelectedIncidentTypeL1({ key: selectedIncidentTypeId ?? 0, text: "" }));
								dispatch(setSovereignIncidentTypes(selectedIncidentCategoryId === IncidentCategories.Digital ? digitalIncidentTypesDetails : physicalIncidentL1Details))
							}
							else dispatch(clearSovereignDetails());

							if (selectedIncidentCategoryId === IncidentCategories.Digital) {
								if (selectedIncidentTypeId === selectedDigitalIncidentTypeL1?.typeId)
									navigate("/v2/create/digital/" + selectedDigitalIncidentTypeL1?.typeId);
								else if (selectedIncidentTypeId) handleCreateIncident(selectedIncidentTypeId);
							} else if (selectedIncidentCategoryId === IncidentCategories.Physical) {
								if (selectedIncidentTypeId === selectedPhysicalIncidentTypeL1?.id)
									navigate("/v2/create/physical/" + selectedPhysicalIncidentTypeL1?.id);
								else if (selectedIncidentTypeId) handleCreateIncident(selectedIncidentTypeId);
							}
						}}
					>
						{getLocalizedValueV2("RIN_Next")}
					</PrimaryButtonAdapter>
				</div>
			</div>
		</div>
	);
}
