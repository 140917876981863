import axios from "axios";
export async function GET<T>(
    baseUrl: string = "/",
    path: string,
    accessToken: string = ""
) {
    var options;
    if (accessToken.length === 0) {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        };
    } else {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: "Bearer " + accessToken,
            },
        };
    }

    try {
        const response = await axios.get(baseUrl + path, options);
        let data = response.data as T;
        return data;
    } catch (error) {
        throw error;
    }
}

export async function POST<T>(
    baseUrl: string = "/",
    path: string,
    formData: T,
    accessToken: string = ""
) {
    var options;
    if (accessToken.length === 0) {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
        };
    } else {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
    }
    try {
        const response = await axios.post(baseUrl + path, formData, options);
        let data = response.data;
        return data;
    } catch (error) {
        throw error;
    }

    
}

export async function PUT<T>(
    baseUrl: string = "/",
    path: string,
    formData: T,
    accessToken: string = ""
) {
    var options;
    if (accessToken.length === 0) {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
        };
    } else {
        options = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
    }
    try {
        const response = await axios.put(baseUrl + path, formData, options);
        let data = response.data;
        return data;
    } catch (error) {
        throw error;
    }
}
