import * as React from 'react';
import { DefaultButton, IDropdownOption, Image, PrimaryButton } from '@fluentui/react';
import { clearDigitalIncidentErrorStates, DigitalIncidentInformationSelector, DigitalIncidentTypesSelector, postDigitalSimulationIncident, setValidationErrorForExecutiveAlias } from '../DigitalIncident/DigitalIncidentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SimulationCampaignSelector } from '../IncidentType/SimulationCampaignSlice';
import { useBoolean } from "../../../Hooks/useBoolean";
import NextStepsDialog from '../PhishingSimulation/NextStepsDialoge';
import { primaryButtonStyles, buttonStyle, whiteLinkStyle } from '../../../styles';
import { showSuccessPage } from '../../SubmissionSuccess/SubmissionSuccessSlice';
import { HelpSectionSelector } from '../../HelpPanel/HelpSectionSlice';
import { PanelEnums } from '../../../Contracts/Enums/PanelEnums';
import { DigitalEventTypes } from '../../../Contracts/Enums/DigitalIncidentEnums';
import { ExecutiveAliasErrorModel } from '../../../Contracts/ExceptionModels/ExceptionModel';
import { getLocalizedValue } from '../../../Services/localizationService';
import { ApplicationSettingsSelector } from '../../ConfigurationsPanel/ApplicationSettingsSlice';
import { PhishingEmailSimulationTypeName } from '../../../Constants/CommonConstants';
import { TimeZonesSelector } from '../../TimeZone/TimeZonesSlice';
export default function PhishingSimulation(props: any) {

    const {
        hidePhishingSimulationExperience,
        resetPhishingForm,
        image,
        incidentTypeId,
        simulationCampaignId
    } = props;

    const [isDefaultOpen, setIsDefaultOpen] = React.useState(true);
    const dispatch = useDispatch();


    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const phishingEmailInstructionsSection = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.find(s => s.panelId === PanelEnums.DigitalIncidentForm) : undefined;
    const phishingAwarenessLink = phishingEmailInstructionsSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === phishingEmailInstructionsSection.sectionId && s.linkId == 22);
    const simulationCampaignDetails = useSelector(SimulationCampaignSelector);
    const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find(x => x.typeId === DigitalEventTypes.PhishingEMail);
    const phishingSimulation = phishingIncidentType?.isSimulationEnabled ? simulationCampaignDetails.simulationCampaign : undefined;
    const digitalIncidentInformation = useSelector(DigitalIncidentInformationSelector);
    const applicationSettingsSelector = useSelector(ApplicationSettingsSelector);
    const flowConfigurations =  applicationSettingsSelector?.flowConfigurations;
    const timeZoneDetails = useSelector(TimeZonesSelector);
    // using the hardcoded value if data not received from API.
    let learnMoreLinkText = <a href="https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Don%27t-get-fooled-by-phishing.aspx" target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
    if (phishingAwarenessLink !== undefined) {
        if (phishingAwarenessLink.openInNewTab === true)
            learnMoreLinkText = <a href={phishingAwarenessLink.moreInfoUrl} target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
        else
            learnMoreLinkText = <a href={phishingAwarenessLink.moreInfoUrl} rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_LearnMoreAboutPhishingLink1")}</a>
    }


    const congratsMessage: any = (
        <>
            <p style={{ marginBottom: 28 }}>
                {getLocalizedValue("RIN_PhisingEmailCongratsMessage1")}
            </p>
            <p style={{ marginBottom: 28 }}>
                {getLocalizedValue("RIN_PhisingEmailCongratsMessage2")}
            </p>
            <p>
                {getLocalizedValue("RIN_PhishingEmailCongratsMessage3")}
                <br />
                {learnMoreLinkText}
            </p>
        </>
    );



    const isCongratsToBeDisplayed = useSelector(SimulationCampaignSelector).submissionStatus;

    const getCurrentTimeZone = ():string=>{
        if (timeZoneDetails.length > 0) {
            var timeZoneList: IDropdownOption[] = [];
            const timeZoneOffset = -(new Date().getTimezoneOffset());
            let isCurrentTimeZoneSet = false;

            for (
                var i = 0;
                i < timeZoneDetails.length;
                i++
            ) {
                if (timeZoneOffset == timeZoneDetails[i].offset)
                {
                    return timeZoneDetails[i].text;
                }
            }
    }
    return "";
}

    const handelYesButtonClicked = () => {
        if (digitalIncidentInformation.isExecSupport && !digitalIncidentInformation.executiveAlias?.trim()) {
            let executiveAliasError : ExecutiveAliasErrorModel  = {ErrorMessage:getLocalizedValue("RIN_PleaseInputAliasOfExecutiveAssociatedWithIncident"), IsErrorOnSubmission:true};
            dispatch(clearDigitalIncidentErrorStates());
            dispatch(setValidationErrorForExecutiveAlias(executiveAliasError));
        }
        else {
            let incidentDescription:string = "";
            let timeZone:string = "";
           
                if(flowConfigurations.isDigitalIncidentSubmissionViaNewFlow)
                {
                    incidentDescription = PhishingEmailSimulationTypeName;
                    timeZone = getCurrentTimeZone();
                }
            
            dispatch(postDigitalSimulationIncident({ incidentTypeId, simulationCampaignId, incidentDescription, timeZone  }));
        }
    };

    const [
        isNextStepsDialogHidden,
        {
            toggle: toggleNextStepsDialog,
            setTrue: hideNextStepsDialog,
            setFalse: showNextStepsDialog,
        },
    ] = useBoolean(true);

    const handleNoButtonClicked = () => {
        setIsDefaultOpen(false);
        showNextStepsDialog();
    }

    React.useEffect(() => {
        if (isCongratsToBeDisplayed) {
            setIsDefaultOpen(false);
            dispatch(showSuccessPage({ isSuccessPageShown: true, message: congratsMessage }));
            resetPhishingForm();
            hidePhishingSimulationExperience();
        }

    }, [isCongratsToBeDisplayed])

    return (
        <div id="phishing-simulation-container">

            {(isDefaultOpen && (isNextStepsDialogHidden)) && (<>

                <p>
                    {getLocalizedValue("RIN_PhishingEmailReport")}
                </p>
                <figure style={{
                    marginLeft: "0px",
                    marginRight: "0px"
                }}>
                    <Image
                        src={image}
                        alt="Phishing simulation email screenshot."
                        width="100%"
                        shouldStartVisible
                        styles={{
                            image: {
                                minHeight: "30vh",
                            },
                        }}
                    />
                    <figcaption
                        style={{
                            position: "absolute",
                            left: -10000,
                            top: "auto",
                            width: 1,
                            height: 1,
                            overflow: "hidden",
                        }}
                    >
                        <p>
                            <caption>from: {phishingSimulation?.senderName} {phishingSimulation?.senderEmail}</caption>
                            <caption>subject: {phishingSimulation?.emailSubject}</caption>
                            <caption>{phishingSimulation?.outlookWarningMessage}</caption>
                            <caption>{phishingSimulation?.emailBody}</caption>
                        </p>
                    </figcaption>
                </figure>

                <div className="ms-Grid-row submission-buttons">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <DefaultButton
                            onClick={handelYesButtonClicked}
                            styles={{
                                root: buttonStyle,
                                rootHovered: buttonStyle
                            }}
                        >
                            {getLocalizedValue("RIN_Yes")}
                        </DefaultButton>
                        &nbsp;&nbsp;&nbsp;
                        <PrimaryButton
                            styles={primaryButtonStyles}
                            onClick={handleNoButtonClicked}
                        >
                            {getLocalizedValue("RIN_No")}
                        </PrimaryButton>
                    </div>
                </div>
            </>)
            }

            {!isNextStepsDialogHidden &&
                <NextStepsDialog
                    {...props}
                    {...{
                        isNextStepsDialogHidden,
                        toggleNextStepsDialog,
                        hideNextStepsDialog,
                        showNextStepsDialog,
                    }}
                />
            }

        </div >


    );
}