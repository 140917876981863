import { ChoiceGroup, IChoiceGroupOption, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsDiTechnicalSupportScamViewModel, DsDiTechnicalSupportScamLocalizedViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiTechnicalSupportScamViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { TechnicalSupportScamSelector, ErrorStatesSelector, setDigitalIncidentLocalizedTechnicalSupportScamObject, setTechnicalSupportScamAffectedMachineNameErrorMessage, setTechnicalSupportScamObject,setDigitalIncidentTechnicalSupportScamAffectedMachineName, setTechnicalSupportScamScamUrlErrorMessage, setDigitalIncidentTechnicalSupportScamScamUrl, setDigitalIncidentTechnicalSupportScamRemoteAccessAllowed, setTechnicalSupportScamRemoteAccessAllowedErrorMessage, LocalizedUTechnicalSupportScamSelector } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { CustomLabel } from "./IncidentSpecificFieldsv2";

const RemoteAccessAllowedOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Yes"), value:"Yes" },
    { key: "2", text: getLocalizedValueV2("RIN_No"), value:"No" },
];


export default function TechnicalSupportScam() {
    const dispatch = useDispatch();
    const technicalSupportScam_CalloputProps = {
        id: "technicalsupportscam-affectedmachine-name-info",
        calloutDescription:"<div><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo1")+"</b></div><br><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo2")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo3")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo4")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo6")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo8")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo9")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo10")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo11")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo12")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo13")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo14")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo15")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol></div>",
        moreInfoLink: undefined,
    };
    const technicalSupportScamDetails = useSelector(TechnicalSupportScamSelector);
    const errorStatesDetails = useSelector(ErrorStatesSelector);

    const [
        remoteAccessAllowedOption,
        setRemoteAccessAllowedOption,
    ] = React.useState<IChoiceGroupOption>();

    const affectedMachineNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.technicalSupportScamAffectedMachineName !== "") {
                dispatch(setTechnicalSupportScamAffectedMachineNameErrorMessage(""));
            }
            dispatch(setDigitalIncidentTechnicalSupportScamAffectedMachineName(newValue));
            dispatch(setDigitalIncidentLocalizedTechnicalSupportScamObject({ ...localizedTechnicalScamObject, affectedMachineName: newValue } as DsDiTechnicalSupportScamLocalizedViewModel));
        }
    };

    const [technicalScamObject, setTechnicalScamObject] = React.useState<DsDiTechnicalSupportScamViewModel>({
        affectedMachineName: "",
        scamUrl: "",
        wasRemoteAccessAllowed: undefined
    });

    const defaultLocalizedtechnicalScamObject:DsDiTechnicalSupportScamLocalizedViewModel ={
        affectedMachineName: "",
        scamUrl: "",
        wasRemoteAccessAllowed: ""
    }
    const localizedTechnicalScamObject:DsDiTechnicalSupportScamLocalizedViewModel|undefined = useSelector(LocalizedUTechnicalSupportScamSelector);


    useEffect(() => {
        if(technicalSupportScamDetails) return;
        dispatch(setTechnicalSupportScamObject(technicalScamObject));
        dispatch(setDigitalIncidentLocalizedTechnicalSupportScamObject({ ...localizedTechnicalScamObject, ...defaultLocalizedtechnicalScamObject } as DsDiTechnicalSupportScamLocalizedViewModel));
    }, [])

    const affectedMachineNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.technicalSupportScamAffectedMachineName} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const scamUrlDescription = <FieldValidationDescription errorMessage={errorStatesDetails.technicalSupportScamUrl} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const wasRemoteAccessAllowedDescription = <FieldValidationDescription errorMessage={errorStatesDetails.technicalSupportScamWasRemoteAccessAllowed} descriptionColor={'red'} iconName={"ErrorBadge"} />

    const handleScamUrlChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.technicalSupportScamUrl !== "") {
                dispatch(setTechnicalSupportScamScamUrlErrorMessage(""));
            }
            dispatch(setDigitalIncidentTechnicalSupportScamScamUrl(newValue));
            dispatch(setDigitalIncidentLocalizedTechnicalSupportScamObject({ ...localizedTechnicalScamObject, scamUrl: newValue } as DsDiTechnicalSupportScamLocalizedViewModel));
        }
    };

    const handleRemoteAccessOptionChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            dispatch(setTechnicalSupportScamRemoteAccessAllowedErrorMessage(""));
            setRemoteAccessAllowedOption(option);
            dispatch(setDigitalIncidentTechnicalSupportScamRemoteAccessAllowed(option.value === "Yes" ? true : false));
            dispatch(setDigitalIncidentLocalizedTechnicalSupportScamObject({ ...localizedTechnicalScamObject, wasRemoteAccessAllowed: option.text } as DsDiTechnicalSupportScamLocalizedViewModel));
        }
    };

    return (
        <>
            <div className="ms-Grid-row" style={{ marginBottom: '20px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="technicalScamAffectedMachineNameId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion5")}
                        aria-labelledby={technicalSupportScam_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion5")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...technicalSupportScam_CalloputProps} {...props} />}
                        value={technicalSupportScamDetails?.affectedMachineName}
                        onChange={affectedMachineNameChange}
                        onRenderDescription={() => affectedMachineNameDescription}
                        styles={
                            errorStatesDetails.technicalSupportScamAffectedMachineName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField label={getLocalizedValueV2("RIN_ScamURL")}
                        id="technicalSupportScamUrlId"
                        value={technicalSupportScamDetails?.scamUrl}
                        onChange={handleScamUrlChange}
                        required
                        aria-required={true}
                        onRenderDescription={() => scamUrlDescription}
                        
                        styles={
                            errorStatesDetails
                                .technicalSupportScamUrl.length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        } />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={RemoteAccessAllowedOptions}
                        id="technicalSupportScamWasRemoteAccessAllowed"
                        required
                        aria-required = {true}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion6")}
                        selectedKey={technicalSupportScamDetails?.wasRemoteAccessAllowed == undefined 
                            ? undefined 
                            :(RemoteAccessAllowedOptions.find(x=>x.value === (technicalSupportScamDetails?.wasRemoteAccessAllowed ? "Yes" : "No" ))?.key)}
                        onChange={handleRemoteAccessOptionChange}
                        styles={getRadioButtonStyle(128)}
                    />
                    {errorStatesDetails.technicalSupportScamWasRemoteAccessAllowed?.length>0  ?  wasRemoteAccessAllowedDescription : ""}
                </div>
            </div>
        </>
    );
}