import { DsDiExposureOfPersonalDataInputViewModel } from "./../../Contracts/TypesAndInterfaces/DsDiExposureOfPersonalDataInputViewModel";
import { DsDiExposureOfSensitiveInformationInputViewModel } from "./../../Contracts/TypesAndInterfaces/DsDiExposureOfSensitiveInformationInputViewModel.d";
import { DsDiPhishingCallorTextInputViewModel } from "./../../Contracts/TypesAndInterfaces/DsDiPhishingCallorTextInputViewModel.d";
import { DsDiPhishingEmailInputViewModel } from "./../../Contracts/TypesAndInterfaces/DsDiPhishingEmailInputViewModel.d";
import {
	SovereignPhysicalIncidentModel,
	Sovereign_PS_IncidentInformationModel,
	Sovereign_PS_IncidentLocationModel,
	Sovereign_PS_IncidentTimeModel,
	Sovereign_PS_IncidentTypeDetailsModel,
	Sovereign_PS_ItemDetailsModel,
	Sovereign_PS_PersonDetailsModel,
	Sovereign_PS_VehicleDetailsModel,
} from "./../../Contracts/TypesAndInterfaces/Sovereign/SovereignIncidentModels.d";
import { PhysicalIncidentModel } from "./../../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel.d";
import { DigitalEventTypes, SovereignDigitalEventTypes } from "./../../Contracts/Enums/DigitalIncidentEnums";
import { GovNationsResponseViewModel } from "./../../Contracts/TypesAndInterfaces/Sovereign/GovNationsResponseViewModel.d";
import { IDropdownOption } from "@fluentui/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { PsPhysicalIncidentTypeResponseViewModel } from "../../Contracts/TypesAndInterfaces/PsPhysicalIncidentTypeResponseViewModel";
import { DigitalIncidentModel } from "../../Contracts/TypesAndInterfaces/DigitalIncidentModel";
import {
	SovereignDigitalIncidentModel,
	Sovereign_DS_IncidentInformationModel,
	Sovereign_DS_LocationAndTimingsModel,
} from "../../Contracts/TypesAndInterfaces/Sovereign/SovereignIncidentModels";
import {
	DsDiUnauthorizedAccessOrHackInputViewModel,
	DsUnauthorizedSuspiciousLoginViewModel,
	DsUnauthorizedUninitiatedMFAViewModel,
	DsUnauthorizedUnknowDeviceViewModel,
} from "../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { DigitalIncidentTypes, DigitalIncidentType } from "../../Contracts/TypesAndInterfaces/DigitalIncidentType";
import { DsDiMalwareInputViewModel } from "../../Contracts/TypesAndInterfaces/DsDiMalwareInputViewModel";
import { DsDiSuspiciousOrMaliciousWebsiteInputViewModel } from "../../Contracts/TypesAndInterfaces/DsDiSuspiciousOrMaliciousWebsiteInputViewModel";
import { NavigateFunction } from "react-router-dom";
import { DsDiTechnicalSupportScamViewModel } from "../../Contracts/TypesAndInterfaces/DsDiTechnicalSupportScamViewModel";

export type SovereignIncidenttypes = DigitalIncidentTypes | PsPhysicalIncidentTypeResponseViewModel[];

export interface SovereignModel {
	SovereignDetails: {
		isSovereignIncidentSelected: boolean;
		Sovereigns: GovNationsResponseViewModel[];
		selectedSovereign?: GovNationsResponseViewModel;
	};
	IncidentDetails: {
		IncidentCategory?: IncidentCategories;
		IncidentTypes: SovereignIncidenttypes;
		selectedIncidentTypeL1?: IDropdownOption;
		selectedIncidentTypeL2?: IDropdownOption;
	};
	formData: SovereignDigitalIncidentModel | SovereignPhysicalIncidentModel | undefined;
}
const initialState: SovereignModel = {
	SovereignDetails: {
		isSovereignIncidentSelected: false,
		Sovereigns: [],
		selectedSovereign: undefined,
	},
	IncidentDetails: {
		IncidentCategory: undefined,
		IncidentTypes: [],
		selectedIncidentTypeL1: undefined,
		selectedIncidentTypeL2: undefined,
	},
	formData: undefined,
};

const sovereignSlice = createSlice({
	name: "sovereign",
	initialState,
	reducers: {
		getSovereigns: () => { },
		getSovereignDigitalL2IncidentTypes: (state, { payload }: PayloadAction<GovNationsResponseViewModel | undefined>) => { },
		setIsSovereignIncidentSelected: (state, { payload }: PayloadAction<boolean>) => {
			state.SovereignDetails.isSovereignIncidentSelected = payload;
		},
		setSovereigns: (state, action: PayloadAction<GovNationsResponseViewModel[]>) => {
			state.SovereignDetails.Sovereigns = action.payload;
		},
		setSelectedSovereign: (state, action: PayloadAction<GovNationsResponseViewModel | undefined>) => {
			state.SovereignDetails.selectedSovereign = action.payload;
		},
		setSovereignIncidentCategory: (state, action: PayloadAction<IncidentCategories | undefined>) => {
			state.IncidentDetails.IncidentCategory = action.payload;
		},
		setSovereignIncidentTypes: (state, action: PayloadAction<SovereignIncidenttypes>) => {
			state.IncidentDetails.IncidentTypes = action.payload;
		},
		setSovereignSelectedIncidentTypeL1: (state, action: PayloadAction<IDropdownOption | undefined>) => {
			state.IncidentDetails.selectedIncidentTypeL1 = action.payload;
		},
		setSovereignSelectedIncidentTypeL2: (state, action: PayloadAction<IDropdownOption | undefined>) => {
			state.IncidentDetails.selectedIncidentTypeL2 = action.payload;
		},
		postSovereignDigitalFormInformation(state, { payload }: PayloadAction<{ digitalIncident: DigitalIncidentModel, navigate?: NavigateFunction }>) {
			state.formData = {} as SovereignDigitalIncidentModel;
			let level1IncidentType: DigitalIncidentType | undefined;
			let level2IncidentType: DigitalIncidentType | undefined;
			let digitalIncidentTypes = state.IncidentDetails.IncidentTypes as DigitalIncidentTypes;
			if (state.IncidentDetails.selectedIncidentTypeL1 && state.IncidentDetails.selectedIncidentTypeL1.key > 0) {
				level1IncidentType = digitalIncidentTypes.incidentTypesL1.find(
					(l1incidentType) => l1incidentType?.typeId === state.IncidentDetails.selectedIncidentTypeL1?.key
				);
			}
			if (state.IncidentDetails.selectedIncidentTypeL1 && state.IncidentDetails.selectedIncidentTypeL1.key > 0) {
				level2IncidentType = digitalIncidentTypes.incidentTypesL2.find(
					(l2incidentType) => l2incidentType?.typeId === state.IncidentDetails.selectedIncidentTypeL2?.key
				);
			}
			state.formData.isTestIncident = payload.digitalIncident.digitalIncidentInformation.isTestIncident;
			state.formData.incidentTypeDetails = { level1: level1IncidentType?.name ?? "", level2: level2IncidentType?.name };

			state.formData.incidentLocationandTimings = {} as Sovereign_DS_LocationAndTimingsModel;
			state.formData.incidentLocationandTimings.reportedDateTimeInUTC = new Date();
			state.formData.incidentLocationandTimings.occurredDateTimeInUTC = payload.digitalIncident.digitalIncidentInformation.incidentDate || new Date();
			state.formData.incidentLocationandTimings.occurredDateTimeInUTC.setHours(
				payload.digitalIncident.digitalIncidentInformation.incidentHour,
				payload.digitalIncident.digitalIncidentInformation.incidentMinute
			);

			state.formData.incidentLocationandTimings.occuredDateTimeZoneOffset = new Date().getTimezoneOffset();

			state.formData.incidentLocationandTimings.incidentTimeZone = payload.digitalIncident.digitalIncidentInformation.incidentTimeZone;

			state.formData.incidentInformation = {} as Sovereign_DS_IncidentInformationModel;
			state.formData.incidentInformation.requiresExecutiveSupport = payload.digitalIncident.digitalIncidentInformation.isExecSupport;
			if (payload.digitalIncident.digitalIncidentInformation.azureSubscriptionId && payload.digitalIncident.digitalIncidentInformation.azureSubscriptionId.length > 0)
				state.formData.incidentInformation.azureSubscriptionId = payload.digitalIncident.digitalIncidentInformation.azureSubscriptionId;
			state.formData.incidentInformation.incidentSummary = payload.digitalIncident.digitalIncidentInformation.incidentDescription.trim();

			switch (state.IncidentDetails.selectedIncidentTypeL1?.key) {
				case DigitalEventTypes.PhishingEMail:
				case SovereignDigitalEventTypes.PhishingEMail:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail) {
						state.formData.incidentInformation.additionalPhishingEmailDetails = {} as DsDiPhishingEmailInputViewModel;
						state.formData.incidentInformation.additionalPhishingEmailDetails.emailDeliveredLocation =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail?.emailDeliveredLocation;
						if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser == "")
							state.formData.incidentInformation.additionalPhishingEmailDetails.infoSharedByUser = undefined;
						else
							state.formData.incidentInformation.additionalPhishingEmailDetails.infoSharedByUser =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.infoSharedByUser?.trim();
						state.formData.incidentInformation.additionalPhishingEmailDetails.isAttachmentorURLOpenedByUser =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingEmail.isAttachmentorURLOpenedByUser;
					}
					break;
				case DigitalEventTypes.PhishingCallOrText:
				case SovereignDigitalEventTypes.PhishingCallOrText:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText) {
						state.formData.incidentInformation.additionalPhishingCallOrTextDetails = {} as DsDiPhishingCallorTextInputViewModel;
						state.formData.incidentInformation.additionalPhishingCallOrTextDetails.callFrequencyPerDay =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.callFrequencyPerDay;
						state.formData.incidentInformation.additionalPhishingCallOrTextDetails.callReceivedOn =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.callReceivedOn.trim();
						state.formData.incidentInformation.additionalPhishingCallOrTextDetails.offenderContactDetails =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.offenderContactDetails.trim();
						if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.infoSharedByUser == "")
							state.formData.incidentInformation.additionalPhishingCallOrTextDetails.infoSharedByUser = undefined;
						else
							state.formData.incidentInformation.additionalPhishingCallOrTextDetails.infoSharedByUser =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentPhishingCallorText.infoSharedByUser?.trim();
					}
					break;
				case DigitalEventTypes.Malware:
				case SovereignDigitalEventTypes.Malware:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentMalware) {
						state.formData.incidentInformation.additionalMalwareDetails = {} as DsDiMalwareInputViewModel;
						state.formData.incidentInformation.additionalMalwareDetails.affectedMachineName =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentMalware.affectedMachineName.trim();
						state.formData.incidentInformation.additionalMalwareDetails.fileName =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentMalware.fileName.trim();
						state.formData.incidentInformation.additionalMalwareDetails.threatName =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentMalware.threatName.trim();
					}
					break;
				case DigitalEventTypes.PolicyViolation:
				case SovereignDigitalEventTypes.PolicyViolation:
					break;
				case DigitalEventTypes.ExposureOfSensitiveInformation:
				case SovereignDigitalEventTypes.ExposureOfSensitiveInformation:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation) {
						state.formData.incidentInformation.additionalExposureOfSensitiveInformationDetails =
							{} as DsDiExposureOfSensitiveInformationInputViewModel;
						if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation.customerOrPartnerDetails == "")
							// hide additionalExposureOfSensitiveInformationDetails if empty
							state.formData.incidentInformation.additionalExposureOfSensitiveInformationDetails = undefined;
						else
							state.formData.incidentInformation.additionalExposureOfSensitiveInformationDetails.customerOrPartnerDetails =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfSensitiveInformation.customerOrPartnerDetails?.trim();
					}
					break;
				case DigitalEventTypes.UnauthorizedAccess:
				case SovereignDigitalEventTypes.UnauthorizedAccess:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack) {
						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails = {} as DsDiUnauthorizedAccessOrHackInputViewModel;
						if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin) {
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.suspiciousLogin =
								{} as DsUnauthorizedSuspiciousLoginViewModel;
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.suspiciousLogin.hostName =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.hostName.trim();
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.suspiciousLogin.relatedToWorkOrSchoolAccount =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.suspiciousLogin.relatedToWorkOrSchoolAccount;
						} else if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled) {
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.unknownDeviceEnrolled =
								{} as DsUnauthorizedUnknowDeviceViewModel;
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.unknownDeviceEnrolled.hostName =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.hostName.trim();
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.unknownDeviceEnrolled.enrolledDeviceName =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.enrolledDeviceName.trim();
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.unknownDeviceEnrolled.isNewDeviceEnrolled =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.unknownDeviceEnrolled.isNewDeviceEnrolled;
						} else if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA) {
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.uninitiatedMFA =
								{} as DsUnauthorizedUninitiatedMFAViewModel;
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.uninitiatedMFA.hostName =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.hostName.trim();
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.uninitiatedMFA.mfaprompt =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.mfaprompt;
							state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.uninitiatedMFA.relatedToWorkOrSchoolAccount =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityUnauthorizedAccessOrHack.uninitiatedMFA.relatedToWorkOrSchoolAccount;
						}
						
					}
					else if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam) {
						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails
							= {} as DsDiUnauthorizedAccessOrHackInputViewModel;

						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.technicalSupportScam =
							{} as DsDiTechnicalSupportScamViewModel;
						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.technicalSupportScam.affectedMachineName =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.affectedMachineName.trim();
						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.technicalSupportScam.scamUrl =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.scamUrl.trim();
						state.formData.incidentInformation.additionalUnauthorizedAccessOrHackDetails.technicalSupportScam.wasRemoteAccessAllowed =
							payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam.wasRemoteAccessAllowed;
					}
					break;
				case DigitalEventTypes.SuspiciousOrMaliciousWebsite:
				case SovereignDigitalEventTypes.SuspiciousOrMaliciousWebsite:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite) {
						state.formData.incidentInformation.additionalSuspiciousOrMaliciousWebsiteDetails = {} as DsDiSuspiciousOrMaliciousWebsiteInputViewModel;
						state.formData.incidentInformation.additionalSuspiciousOrMaliciousWebsiteDetails.affectedMachineName =
							payload.digitalIncident.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.affectedMachineName.trim();
						state.formData.incidentInformation.additionalSuspiciousOrMaliciousWebsiteDetails.uRL =
							payload.digitalIncident.digitalIncidentInformation.digitalSecuritySuspiciousMaliciousWebsite.uRL.trim();
					}
					break;
				case DigitalEventTypes.ExposureOfPersonalData:
				case SovereignDigitalEventTypes.ExposureOfPersonalData:
					if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData) {
						state.formData.incidentInformation.additionalExposureOfPersonalDataDetails = {} as DsDiExposureOfPersonalDataInputViewModel;
						if (payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData.customerOrPartnerDetails == "")
							// hide additionalExposureOfPersonalDataDetails if empty
							state.formData.incidentInformation.additionalExposureOfPersonalDataDetails = undefined;
						else
							state.formData.incidentInformation.additionalExposureOfPersonalDataDetails.customerOrPartnerDetails =
								payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentExposureOfPersonalData.customerOrPartnerDetails?.trim();
					}
					break;
				case DigitalEventTypes.Other:
				case SovereignDigitalEventTypes.Other:
					break;
				case DigitalEventTypes.TechnicalSupportScam:	
				case SovereignDigitalEventTypes.TechnicalSupportScam:
					state.formData.incidentInformation.additionalTechnicalSupportScamDetails = {} as DsDiTechnicalSupportScamViewModel;
					state.formData.incidentInformation.additionalTechnicalSupportScamDetails.affectedMachineName =
						payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.affectedMachineName?.trim() ?? "";
					state.formData.incidentInformation.additionalTechnicalSupportScamDetails.scamUrl =
						payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.scamUrl?.trim() ?? "";
					state.formData.incidentInformation.additionalTechnicalSupportScamDetails.wasRemoteAccessAllowed =
						payload.digitalIncident.digitalIncidentInformation.digitalSecurityIncidentTechnicalSupportScam?.wasRemoteAccessAllowed;
					break;
				default:
					break;
			}
		},
		
		postSovereignPhysicalFormInformation(state, { payload }: PayloadAction<{ physicalIncident: PhysicalIncidentModel, navigate?: NavigateFunction }>) {
			state.formData = {} as SovereignPhysicalIncidentModel;
			state.formData.isTestIncident = payload.physicalIncident.physicalIncidentInformation.isTestIncident;
			state.formData.incidentTypeDetails = {} as Sovereign_PS_IncidentTypeDetailsModel;

			state.formData.incidentTypeDetails.level1 =
				payload.physicalIncident.incidentTypes.incidentTypesL1.find((x) => x.typeId == payload.physicalIncident.incidentTypes.selectedincidentTypeIdL1?.id)?.name ?? "";

			state.formData.incidentTypeDetails.level2 =
				payload.physicalIncident.incidentTypes.incidentTypesL2.find((x) => x.typeId == payload.physicalIncident.incidentTypes.selectedincidentTypeIdL2?.id)?.name ?? "";

			let selectedIncidentTypeL3 = payload.physicalIncident.incidentTypes.incidentTypesL3.find((x) => x.typeId == payload.physicalIncident.incidentTypes.selectedincidentTypeIdL3?.id);
			if (selectedIncidentTypeL3) {
				let labelL3 = selectedIncidentTypeL3.displayLabel,
					nameL3 = selectedIncidentTypeL3.name;
				state.formData.incidentTypeDetails.level3 = {} as { [key: string]: string };
				state.formData.incidentTypeDetails.level3[labelL3] = nameL3;
			}

			let selectedIncidentTypeL4 = payload.physicalIncident.incidentTypes.incidentTypesL4.find((x) => x.typeId == payload.physicalIncident.incidentTypes.selectedincidentTypeIdL4?.id);
			if (selectedIncidentTypeL4) {
				let labelL4 = selectedIncidentTypeL4.displayLabel,
					nameL4 = selectedIncidentTypeL4.name;
				state.formData.incidentTypeDetails.level4 = {} as { [key: string]: string };
				state.formData.incidentTypeDetails.level4[labelL4] = nameL4;
			}

			state.formData.incidentTimings = {} as Sovereign_PS_IncidentTimeModel;

			state.formData.incidentTimings.incidentTimeZone = payload.physicalIncident.physicalIncidentInformation.incidentTimezone;
			state.formData.incidentTimings.reportedDateTimeInUTC = new Date();
			state.formData.incidentTimings.occurredFromDateTimeInUTC = payload.physicalIncident.physicalIncidentInformation.occurredDateTimeDt || new Date();
			state.formData.incidentTimings.occurredToDateTimeInUTC = payload.physicalIncident.physicalIncidentInformation.occurredToDateTimeDt || new Date();
			state.formData.incidentTimings.timeZoneOffset = new Date().getTimezoneOffset();

			state.formData.locationDetails = {} as Sovereign_PS_IncidentLocationModel;
			state.formData.locationDetails.country =
				payload.physicalIncident.locationAndTime.locationsL1.find((x) => x.idPk == payload.physicalIncident.locationAndTime.selectedLocationTypeIdL1)?.siteNameNv ?? "";
			state.formData.locationDetails.city =
				payload.physicalIncident.locationAndTime.locationsL2.find((x) => x.idPk == payload.physicalIncident.locationAndTime.selectedLocationTypeIdL2)?.siteNameNv ?? undefined;
			state.formData.locationDetails.building =
				payload.physicalIncident.locationAndTime.locationsL3.find((x) => x.idPk == payload.physicalIncident.locationAndTime.selectedLocationTypeIdL3)?.siteNameNv ?? undefined;

			state.formData.incidentInformation = {} as Sovereign_PS_IncidentInformationModel;
			state.formData.incidentInformation.reportedToPolice = payload.physicalIncident.physicalIncidentInformation.reportedToPoliceBt ?? false;
			state.formData.incidentInformation.policeFileNumber = payload.physicalIncident.physicalIncidentInformation.policeFileNumberNv?.trim() ?? undefined;
			if (payload.physicalIncident.physicalIncidentInformation.policeFileNumberNv?.length == 0) state.formData.incidentInformation.policeFileNumber = undefined;
			state.formData.incidentInformation.reporterPhoneNumber = payload.physicalIncident.physicalIncidentInformation.reporterPhoneNumber.trim();
			state.formData.incidentInformation.incidentSummary = payload.physicalIncident.physicalIncidentInformation.summaryNt.trim();
			state.formData.incidentInformation.persons =
				payload.physicalIncident.additionalInfoLookups.addedPeople?.map((x) => {
					return {
						involvementType: x.involvementType ?? "",
						IsEmployee: x.eemployeeBt ?? false,
						firstName: x.efirstNameNv,
						lastName: x.elastNameNv,
						phoneNumber: x.primaryPhoneNumberNv == "" || x.primaryPhoneNumberNv == undefined ? undefined : x.primaryPhoneNumberNv,
						email: x.primaryEmailNv == "" || x.primaryEmailNv == undefined ? undefined : x.primaryEmailNv,
						notes: x.supplementalInformationNt == "" || x.supplementalInformationNt == undefined ? undefined : x.supplementalInformationNt,
					} as Sovereign_PS_PersonDetailsModel;
				}) ?? undefined;

			state.formData.incidentInformation.vehicles =
				payload.physicalIncident.additionalInfoLookups.addedVehicles?.map((x) => {
					let make = payload.physicalIncident.additionalInfoLookups.vehicleMakeTypes.find((y) => y.idPk == x.selectedMakeId)?.makeModelNv ?? undefined;
					let model = payload.physicalIncident.additionalInfoLookups.vehicleModelTypes.find((y) => y.idPk == x.selectedModelId)?.makeModelNv ?? undefined;
					let style = payload.physicalIncident.additionalInfoLookups.vehicleStyleTypes.find((y) => y.idPk == x.evehicleStyleLookupIdFk)?.valueNameNv ?? undefined;
					let color = payload.physicalIncident.additionalInfoLookups.vehicleColorTypes.find((y) => y.idPk == x.evehicleColorLookupIdFk)?.valueNameNv ?? undefined;
					return {
						licensePlateNumber: x.elicensePlateNv,
						isCompanyVehicle: x.ecompanyVehicleBt ?? false,
						driverOwnerName: x.evehicleDriverFullNameNv == "" || x.evehicleDriverFullNameNv == undefined ? undefined : x.evehicleDriverFullNameNv,
						make: make == "" || make == undefined ? undefined : make,
						model: model == "" || model == undefined ? undefined : model,
						style: style == "" || style == undefined ? undefined : style,
						color: color == "" || color == undefined ? undefined : color,
						year: x.eyearNm ?? new Date().getFullYear(),
						involvementType: x.selectedInvolvement ?? "",
						notes: x.commentsNt == "" || x.commentsNt == undefined ? undefined : x.commentsNt,
					} as Sovereign_PS_VehicleDetailsModel;
				}) ?? undefined;
			if (state.formData.incidentInformation.vehicles?.length == 0) state.formData.incidentInformation.vehicles = undefined;
			state.formData.incidentInformation.items =
				payload.physicalIncident.additionalInfoLookups.addedItems?.map((x) => {
					let make = payload.physicalIncident.additionalInfoLookups.itemMakeTypes.find((y) => y.idPk == x.selectedMakeId)?.makeModelNv ?? undefined;
					let model = payload.physicalIncident.additionalInfoLookups.itemModelTypes.find((y) => y.idPk == x.selectedModelId)?.makeModelNv ?? undefined;
					let category = payload.physicalIncident.additionalInfoLookups.itemCategoryTypes.find((y) => y.idPk == x.selectedCategoryId)?.itemClassNv ?? undefined;
					let subCategory =
						payload.physicalIncident.additionalInfoLookups.itemSubCategoryTypes.find((y) => y.idPk == x.selectedSubCategoryId)?.itemClassNv ?? undefined;
					return {
						name: x.eitemNameNv,
						value: "$" + (x.eitemValueNm ?? 0),
						serialNumber: x.eserialNumberNv == "" || x.eserialNumberNv == undefined ? undefined : x.eserialNumberNv,
						make: make == "" || make == undefined ? undefined : make,
						model: model == "" || model == undefined ? undefined : model,
						category: category == "" || category == undefined ? undefined : category,
						subcategory: subCategory == "" || subCategory == undefined ? undefined : subCategory,
						ownerName: x.eownerNamePersonNv == "" || x.eownerNamePersonNv == undefined ? undefined : x.eownerNamePersonNv,
						notes: x.itemNotesNt == "" || x.itemNotesNt == undefined ? undefined : x.itemNotesNt,
					} as Sovereign_PS_ItemDetailsModel;
				}) ?? undefined;
			if (state.formData.incidentInformation.items?.length == 0) state.formData.incidentInformation.items = undefined;
		},
		clearSovereignDetails: (state) => {
			state.IncidentDetails.IncidentCategory = undefined;
			state.IncidentDetails.IncidentTypes = [];
			state.IncidentDetails.selectedIncidentTypeL1 = undefined;
			state.IncidentDetails.selectedIncidentTypeL2 = undefined;
			state.formData = undefined;
			state.SovereignDetails.Sovereigns = [];
			state.SovereignDetails.selectedSovereign = undefined;
		},
	},
});

export const {
	setSovereignSelectedIncidentTypeL2,
	getSovereignDigitalL2IncidentTypes,
	setSelectedSovereign,
	getSovereigns,
	setSovereigns,
	setSovereignIncidentCategory,
	setSovereignIncidentTypes,
	setSovereignSelectedIncidentTypeL1,
	postSovereignDigitalFormInformation,
	postSovereignPhysicalFormInformation,
	clearSovereignDetails,
	setIsSovereignIncidentSelected,
} = sovereignSlice.actions;
export default sovereignSlice.reducer;
export const sovereignSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign;
export const isSovereignIncidentSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.SovereignDetails.isSovereignIncidentSelected;
export const sovereignIncidentDetailsSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.IncidentDetails;
export const sovereignIncidentTypesSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.IncidentDetails.IncidentTypes;
export const sovereignDetailsSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.SovereignDetails;
export const sovereignIncidentCategorySelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.IncidentDetails.IncidentCategory;
export const selectedSovereignSelector = (state: { Sovereign: SovereignModel }) => state.Sovereign.SovereignDetails.selectedSovereign;
export const sovereignSelectedIncidentTypeL1Selector = (state: { Sovereign: SovereignModel }) => state.Sovereign.IncidentDetails.selectedIncidentTypeL1;
export const sovereignSelectedIncidentTypeL2Selector = (state: { Sovereign: SovereignModel }) => state.Sovereign.IncidentDetails.selectedIncidentTypeL2;
