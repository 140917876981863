import React from "react";
import HomeIcon from "../../../Assets/HomeIcon.svg";
import "./Homev2.css";
import { MessageBar } from "@fluentui/react";
import { PrimaryButtonAdapter } from "../Controls";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../../../Services/telemetryService";
import { useSelector } from "react-redux";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { FILE_A_REPORT_BUTTON_CLICKED, HOME } from "../../../Constants/InstrumentationConstants";
import { FeatureFlagsLoadingSelector, FlowConfigurationsLoadingSelector, UserFlightStatusLoadingSelector, UserTestInProdStatusLoadingSelector } from "../../Loader/LoaderSlice";
import Loader from "../../Loader/Loader";

export default function Homev2() {
    let navigate = useNavigate();
    const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
    const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);

    const isUserFlightStatusLoading = useSelector(UserFlightStatusLoadingSelector);
    const isFeatureFlagsLoading = useSelector(FeatureFlagsLoadingSelector);
    const isUserTestInProdStatusLoading = useSelector(UserTestInProdStatusLoadingSelector);
    const isFlowConfigurationsLoading = useSelector(FlowConfigurationsLoadingSelector);

    React.useEffect(()=>{
        trackEvent({name: HOME}, {
            incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
            step: HOME,
            timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
        });
    },[])

    return (
        <div className="homev2 ms-Grid-row site-margins">
            {(isUserFlightStatusLoading || isFeatureFlagsLoading || isUserTestInProdStatusLoading || isFlowConfigurationsLoading) && <Loader />}
            <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
                    <img className="homev2__image" src={HomeIcon} alt={getLocalizedValueV2("RIN_WelcomeToReportItNow")} />
                    <h1 className="homev2__header">{getLocalizedValueV2("RIN_DoYouHaveASecurityConcern")}</h1>
                    <MessageBar
                        messageBarIconProps={{
                            iconName: "ShieldAlert",
                        }}
                        styles={{
                            root: {
                                selectors: {
                                    "@media (max-width: 414px)": {
                                        margin: "0 auto",
                                    },
                                },
                                backgroundColor: "#FDE7E9",
                                color: "#A80000",
                                borderRadius: "4px",
                                width: "auto",
                                margin: "0 25px",
                                textAlign: "left"
                            },
                            innerText: {
                                selectors: {
                                    "@media (max-width: 414px)": {
                                        fontSize: "12px",
                                        lineHeight: 16,
                                    },
                                },
                                fontFamily: "Segoe UI",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: 24,
                            },
                            icon: {
                                selectors: {
                                    "@media (max-width: 414px)": {
                                        fontSize: "16px",
                                    },
                                },
                                color: "#A80000",
                                fontSize: "18px",
                                alignSelf: "center",
                            },
                        }}
                    >
                        {getLocalizedValueV2("RIN_EmergencyServices")}
                    </MessageBar>
                    <p className={"homev2__text"}>
									{getLocalizedValueV2("RIN_Home_GSOC")}{" "}
                    <a href="tel:+1 425 706-0000" style={{ color: "#0078D4", textDecoration: "none" }} aria-label="Microsoft Global Security number +1 425 706-0000">
										+1 425 706-0000
									</a>
								</p>
                    <PrimaryButtonAdapter
                        text={getLocalizedValueV2("RIN_FileAReport")}
                        onClick={() => {
                            trackEvent({name: FILE_A_REPORT_BUTTON_CLICKED}, {
                                incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
                                step: FILE_A_REPORT_BUTTON_CLICKED,
                                timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
                            });
                            navigate("create");
                        }}
                        styles={{
                            root:{
                                marginTop: 16,
                                selectors: {
                                    "@media (max-width: 479px)": {
                                        marginTop: 12,
                                    },
                                },
                            },
                        }}
                    />
                </div>
        </div>
    );
}
