import { IStackStyles, IStackTokens } from "@fluentui/react";
import { Step } from "../Components/RINv2/Stepperv2/Stepperv2";
import { getLocalizedValueV2 } from "../Services/localizationServiceV2";

export const SOURCE = "UI";
export const STEP = "IncidentCreated";

export const stackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 500,
};

export const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: { padding: 20 },
};

export const csvHeaders = [
  { label: "User Email Id", key: "userEmailId" },
  { label: "Incident Count", key: "incidentCount" },
];

export const RINStepperSteps: Step[] = [
  { label: getLocalizedValueV2("RIN_Category") },
  { label: getLocalizedValueV2("RIN_Type") },
  { label: getLocalizedValueV2("RIN_Details") },
  { label: getLocalizedValueV2("RIN_Review") },
];

export enum StepperSteps{
  Category,
  Type,
  Details,
  Review
}


export const CONSTANTS = {
    SENSITIVEINFODISCLAIMER : "RIN_DISCLAIMERPleaseDoNotUploadSensitiveInformation",
}

export const VSOCNumberWhite = " <label style=\"color:white;font-weight:600;\"><a href=\"tel:+14257060000\" style=\"color:white;font-weight:bold;\">+1 425 706-0000</a></label>"

export const VSOCNumberBlue = " <a href=\"tel:+14257060000\" style=\"color:#0064BF;\">+1 425 706-0000</a>"

export const PhishingEmailSimulationTypeName = "Phishing email simulation";

export const MaxFileCountAllowed:number = 10;

export const IncidentCategoryRoutes = {
  Digital: 'digital',
  Physical: 'physical'
}

export const HELP_SECTIONS_CACHE_KEY = "HelpSectionsCache";
export const HELP_SECTION_LINKS_CACHE_KEY = "HelpSectionLinksCache";

export const ScrollableContentRootId = "main";