import * as React from "react";
import { fileSelector, setFiles, FileObject, removeFile, clearErrorStates, setFileValidationErrorState, addFileError, setSizeInBytes } from "./FileUploadSlice";
import { useDispatch, useSelector } from "react-redux";
import DragAndDrop from "./DragAndDrop/DragAndDrop";
import path from "path";
import { renderDescriptionLabel } from "../Home/Home";
import FieldValidationDescription from "../FieldValidationDescription/FieldValidationDescription";
import { CustomLabel, CustomLabelProps } from "../DigitalSecurity/IncidentSpecificFields/IncidentSpecificFields";
import { getLocalizedValue } from "../../Services/localizationService";
import { MaxFileCountAllowed } from "../../Constants/CommonConstants";
import { MessageBar, MessageBarType, Label } from "@fluentui/react";

interface FileUploadProps {
	totalAllowedSizeInMB: number;
	allowdSizePerFileInMB: number;
	allowedFileTypes: string[];
	checkFileTypes: boolean;
	allowedFileNameLength: number;
	isPhishingEmailIncident?: boolean;
	showCallOut?: boolean;
	callOutComponent?: JSX.Element;
	disclaimerToBeShown?: string | null;
}

export default function FileUpload({
	totalAllowedSizeInMB,
	allowdSizePerFileInMB,
	allowedFileTypes,
	checkFileTypes,
	allowedFileNameLength,
	isPhishingEmailIncident,
	showCallOut,
	callOutComponent,
	disclaimerToBeShown,
}: FileUploadProps) {
	const fileDetails = useSelector(fileSelector);
	const fileInputRef = React.createRef<HTMLInputElement>();
	const fileValidationErrorMessage = (
		<FieldValidationDescription errorMessage={fileDetails.errorStates.validationError} descriptionColor={"white"} iconName={"ErrorBadge"} />
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (fileDetails.sizeInBytes <= totalAllowedSizeInMB * 1024 * 1024 && fileDetails.files.length <= 10) {
			dispatch(setFileValidationErrorState(""));
		}
	}, [fileDetails.sizeInBytes, fileDetails.files.length]);

	const handleFileRemoval = (id: number, size: number, name: string) => {
		if (document.querySelector("div[class='visually-hidden']") != null) {
			document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValue("RIN_SuccessfullyDeletedAttachment") + name;
		}
		dispatch(clearErrorStates());
		dispatch(removeFile(id));
		dispatch(setSizeInBytes(fileDetails.sizeInBytes - size));
		fileInputRef?.current?.focus();
	};
	const Files = (files: FileObject[]): JSX.Element => {
		files = Array.from(files);
		return (
			<div style={{ marginTop: "20px" }}>
				{files.map((file) => (
					<MessageBar
						messageBarType={MessageBarType.info}
						isMultiline={false}
						onDismiss={() => handleFileRemoval(file.id, file.fileSize, file.fileName)}
						dismissButtonAriaLabel={getLocalizedValue("RIN_RemoveAttachment") + " " + file.fileName}
						truncated={false}
						styles={{
							root: {
								backgroundColor: "rgb(0, 80, 169)",
								marginBottom: "4px",
							},
							iconContainer: {
								display: "none",
							},
							content: {
								color: "white",
								fontSize: "14px",
							},
							expand: {
								color: "white !important",
								selectors: {
									".ms-Button-icon": {
										color: "white",
									},
								},
							},
						}}
						overflowButtonAriaLabel={getLocalizedValue("RIN_ExpandAttachment") + " " + file.fileName}
						dismissIconProps={{
							iconName: "Clear",
							style: {
								color: "white",
							},
						}}
					>
						{file.fileSize < 1024 * 1024 ? <>{(file.fileSize / 1024).toFixed(2)}KB</> : <>{(file.fileSize / (1024 * 1024)).toFixed(2)}MB</>} -{" "}
						{file.fileName}
					</MessageBar>
				))}
			</div>
		);
	};

	var finalFiles: FileObject[] = [];
	const uploadFileAsFormData = async (files: File[]) => {
		if (document.querySelector("div[class='visually-hidden']") != null) {
			if (files.length > 1) {
				document.querySelector("div[class='visually-hidden']")!.textContent =
					getLocalizedValue("RIN_SuccessfullyUploaded") + " " + files.length + "attachments";
			} else {
				document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValue("RIN_SuccessfullyUploadedAttachment") + files[0].name;
			}
		}
		let newFileId = fileDetails.files.reduce((p, c, i) => Math.max(p, c.id), 0) + 1;
		for (var i = 0; i < files.length; i++) {
			if (fileDetails.files.find((x) => x.fileName == files[i].name)) {
				continue;
			}

			const toBase64 = (file: File) =>
				new Promise((resolve) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
				});
			await toBase64(files[i]).then((result: any) => {
				finalFiles.push({
					id: newFileId++,
					fileContent: (result as string)?.split(",")[1],
					fileName: files[i].name,
					fileSize: files[i].size,
				});
			});
		}
	};
	const handleDrop = (files: File[]) => {
		try {
			dispatch(clearErrorStates());
			var size = fileDetails.sizeInBytes;
			var isAttachmentValid = true;

			for (var i = 0; i < files.length; i++) {
				if (fileDetails.files.find((x) => x.fileName == files[i].name)) {
					continue;
				}
				if (checkFileTypes && allowedFileTypes.find((s) => s === path.extname(files[i].name)?.toLowerCase()) === undefined) {
					dispatch(addFileError(files[i].name + " " + getLocalizedValue("RIN_IsNotASupportedAttachmentType")));
					isAttachmentValid = false;
					break;
				}
				if (files[i].size > allowdSizePerFileInMB * 1024 * 1024) {
					dispatch(addFileError(files[i].name + " " + getLocalizedValue("RIN_ExceedsTheAllowedSizeOf") + " " + allowdSizePerFileInMB + "MB"));
					isAttachmentValid = false;
					break;
				}
				if (files[i].name.length > allowedFileNameLength) {
					dispatch(
						addFileError(
							files[i].name + " " + getLocalizedValue("RIN_ExceedsTheAllowedAttachmentNameLength") + " " + allowedFileNameLength + " characters"
						)
					);
					isAttachmentValid = false;
					break;
				}
				if (files[i].size === 0) {
					dispatch(addFileError(files[i].name + " " + getLocalizedValue("RIN_IsInvalidAsItDoesNotHaveAnyContent")));
					isAttachmentValid = false;
					break;
				}
				size += files[i].size;
			}
			if (size > totalAllowedSizeInMB * 1024 * 1024) {
				dispatch(setFileValidationErrorState(getLocalizedValue("RIN_TotalAttachmentSizeExceeded")));
			} else if (fileDetails.files.length + files.length > MaxFileCountAllowed) {
				dispatch(setFileValidationErrorState(getLocalizedValue("RIN_FileUploadFileCountError")));
			} else {
				dispatch(setFileValidationErrorState(""));
			}
			var fileArray = Array.from(files);

			if (isAttachmentValid && fileArray.length > 0) {
				uploadFileAsFormData(fileArray).then((response) => {
					dispatch(setFiles([...fileDetails.files, ...finalFiles]));
				});
				dispatch(setSizeInBytes(size));
			}
		} catch (e) {}
	};

	const getRoundedOffTotalFileSize = () => {
		if (fileDetails.sizeInBytes == 0) return <>0KB</>;

		if (fileDetails.sizeInBytes < 1024 * 1024) return <>{(fileDetails.sizeInBytes / 1024).toFixed(2)}KB</>;

		return <>{(fileDetails.sizeInBytes / (1024 * 1024)).toFixed(2)}MB</>;
	};
	const attachment_CalloutProps: CustomLabelProps = {
		id: "file-input-",
		calloutDescription:
			"<div style='text-align: left'><div class='headding'><b>" +
			getLocalizedValue("RIN_EmailUploadInfo1") +
			"</b></div><ol class='orderedList' style='list-style: decimal; text-align: left; padding-left: 12px;line-height:1.6'><li>" +
			getLocalizedValue("RIN_EmailUploadInfo2") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo3") +
			"</b> " +
			getLocalizedValue("RIN_EmailuploadInfo4") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo5") +
			"</b>.</li><li>" +
			getLocalizedValue("RIN_EmailUploadInfo6") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo7") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo8") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo9") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo10") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo9") +
			"</b>, " +
			getLocalizedValue("RIN_EmailUploadInfo11") +
			"</li><li>" +
			getLocalizedValue("RIN_EmailUploadInfo12") +
			" <b>" +
			getLocalizedValue("RIN_FileName") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo13") +
			"</li><li>" +
			getLocalizedValue("RIN_EmailUploadInfo6") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo14") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo15") +
			" <b>" +
			getLocalizedValue("RIN_Save") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo16") +
			"</li><li>" +
			getLocalizedValue("RIN_EmailUploadInfo17") +
			" <b>" +
			getLocalizedValue("RIN_EmailUploadInfo18") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo19") +
			" <b>" +
			getLocalizedValue("RIN_Submit") +
			"</b> " +
			getLocalizedValue("RIN_EmailUploadInfo20") +
			"</li></ol></div>",
		moreInfoLink: undefined,
		label: getLocalizedValue("RIN_SupportingAttachmentsDesc"),
		labelStyle: {
			fontWeight: 400,
			fontSize: 14,
			marginBottom: 8,
		},
	};

	return (
		<div
			style={{
				marginBottom: "28px",
			}}
		>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Label id="file-upload">
					{getLocalizedValue("RIN_SupportingAttachments")} {isPhishingEmailIncident ? "" : " - " + getLocalizedValue("RIN_Optional")}
				</Label>
				{showCallOut === true && callOutComponent}
			</div>
			{!isPhishingEmailIncident ? (
				renderDescriptionLabel(
					{
						label: checkFileTypes
							? getLocalizedValue("RIN_SupportingAttachmentsDesc")
							: getLocalizedValue("RIN_PleaseUploadSupportingAttachmentsIfAny"),
					},
					"file-input-label"
				)
			) : (
				<CustomLabel {...attachment_CalloutProps} />
			)}
			{disclaimerToBeShown && (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Label>{disclaimerToBeShown}</Label>
				</div>
			)}
			<DragAndDrop handleDrop={handleDrop} isInvalidState={fileDetails.errorStates.validationError.length > 0} fileInputRef={fileInputRef} />
			<label
				style={{
					color: "white",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{getLocalizedValue("RIN_TotalAllowedSize")}: {totalAllowedSizeInMB}MB{" "}
			</label>
			&nbsp;&nbsp;
			<span
				style={{
					color: "white",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{" "}
				{getLocalizedValue("RIN_ConsumedSize")}:{" "}
			</span>
			<span
				style={{
					color: "white",
					fontSize: "14px",
					fontWeight: 400,
				}}
			>
				{getRoundedOffTotalFileSize()}
			</span>
			{fileDetails.files.length > 0 && Files(fileDetails.files)}
			{fileDetails.errorStates.fileErrors.length > 0 && (
				<div style={{ marginTop: "12px", marginBottom: "4px" }}>
					{fileDetails.errorStates.fileErrors.map((error) => (
						<div style={{ marginTop: "4px" }}>
							<FieldValidationDescription errorMessage={error} descriptionColor={"white"} iconName={"Info"} />
						</div>
					))}
				</div>
			)}
			{fileDetails.errorStates.validationError.length > 0 && fileValidationErrorMessage}
		</div>
	);
}
