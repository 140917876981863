import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeEvery } from "redux-saga/effects";
import { setDigitalIncidentTypesPanelVisibility } from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { getHelpSectionLinks, getHelpSections, setHelpSectionLinks, setHelpSections, setIsHelpSectionApiCallSuccess, setIsHelpSectionLinksApiCallSuccess } from "../Components/HelpPanel/HelpSectionSlice";
import { setHelpSectionLinksLoading, setHelpSectionsLoading, setIsLoading } from "../Components/Loader/LoaderSlice";
import { setPhysicalIncidentPanelVisibility } from "../Components/PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE } from "../Constants/CommonConstants";
import { FetchHelpSectionLinks, FetchHelpSections } from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { HelpSectionLinkResponseViewModel } from "../Contracts/TypesAndInterfaces/HelpSectionLinkResponseViewModel";
import { HelpSectionResponseViewModel } from "../Contracts/TypesAndInterfaces/HelpSectionResponseViewModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError } from "../Services/errorHandlerService";
import { GET } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";

export function* FetchHelpSectionsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setHelpSectionsLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/gethelpsections",
      accessToken
    );
    yield put(
      setHelpSections(
        apiResponse?.responseObject == null ||
          apiResponse?.responseObject === undefined
          ? []
          : (apiResponse?.responseObject as HelpSectionResponseViewModel[])
      )
    );
    if (!apiResponse?.responseSuccess) {
      yield put(setIsHelpSectionApiCallSuccess(false));
    }
    yield put(setHelpSectionsLoading(false));
  } catch (e) {
    yield put(setIsHelpSectionApiCallSuccess(false));
    yield put(setHelpSectionsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchHelpSections,
      SOURCE,
      "GetHelpSections",
      "Fetch help sections",
      "FetchHelpSectionsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* FetchHelpSectionLinksAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setHelpSectionLinksLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/gethelpsectionlinks",
      accessToken
    );
    yield put(
      setHelpSectionLinks(
        apiResponse?.responseObject == null ||
          apiResponse?.responseObject === undefined
          ? []
          : (apiResponse?.responseObject as HelpSectionLinkResponseViewModel[])
      )
    );
    if (!apiResponse?.responseSuccess) {
      yield put(setIsHelpSectionLinksApiCallSuccess(false));
    }
    yield put(setHelpSectionLinksLoading(false));
  } catch (e) {
    yield put(setIsHelpSectionLinksApiCallSuccess(false));
    yield put(setHelpSectionLinksLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchHelpSectionLinks,
      SOURCE,
      "GetHelpSectionLinks",
      "Fetch help section links",
      "FetchHelpSectionLinksAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetHelpSections() {
  yield takeEvery(getHelpSections, FetchHelpSectionsAsync);
}

export function* watchGetHelpSectionLinks() {
    yield takeEvery(getHelpSectionLinks, FetchHelpSectionLinksAsync)
}

export function* SetFocusOnDigitalIncidentTypeDropDown({ payload }: PayloadAction<boolean>) {
    if (!payload) {
        document.getElementById("digitalSecurityDropdownId")?.focus();
    }
}

export function* watchCloseDigitalIncidentTypesPanel() {
    yield takeEvery(setDigitalIncidentTypesPanelVisibility, SetFocusOnDigitalIncidentTypeDropDown);
}

export function* SetFocusOnPhysicalIncidentTypeDropDown({ payload }: PayloadAction<boolean>) {
    if (!payload) {
        document.getElementById("physicalIncidenceDropdownId")?.focus();
    }
}

export function* watchClosePhysicalIncidentTypesPanel() {
    yield takeEvery(setPhysicalIncidentPanelVisibility, SetFocusOnPhysicalIncidentTypeDropDown);
}
