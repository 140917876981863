import * as React from 'react';
import { IDropdownOption, Image } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DigitalEventTypes } from '../../../../Contracts/Enums/DigitalIncidentEnums';
import { PanelEnums } from '../../../../Contracts/Enums/PanelEnums';
import { SimulationCampaignSelector } from '../../../DigitalSecurity/IncidentType/SimulationCampaignSlice';
import { HelpSectionSelector } from '../../../HelpPanel/HelpSectionSlice';
import { showSuccessPage } from '../../../SubmissionSuccess/SubmissionSuccessSlice';
import { DigitalIncidentTypesSelector, DigitalIncidentInformationSelector, postDigitalSimulationIncident } from '../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice';
import { useNavigate } from 'react-router-dom';
import { PhishingEmailSimulationTypeName } from '../../../../Constants/CommonConstants';
import { ApplicationSettingsSelector } from '../../../ConfigurationsPanel/ApplicationSettingsSlice';
import { TimeZonesSelector } from '../../../TimeZone/TimeZonesSlice';
import { DefaultButtonAdapter, PrimaryButtonAdapter } from '../../Controls';
import { getLocalizedValueV2 } from '../../../../Services/localizationServiceV2';

export function PhishingSimulationV2(props: any) {
    const { image, incidentTypeId, simulationCampaignId } = props;
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess =
      helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess =
      helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const phishingEmailInstructionsSection =
      isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess
        ? helpSectionDetails.helpSections.find(
            (s) => s.panelId === PanelEnums.DigitalIncidentForm
          )
        : undefined;
    const phishingAwarenessLink =
      phishingEmailInstructionsSection === undefined
        ? undefined
        : helpSectionDetails.helpSectionLinks.find(
            (s) =>
              s.sectionId === phishingEmailInstructionsSection.sectionId &&
              s.linkId == 22
          );
    const simulationCampaignDetails = useSelector(SimulationCampaignSelector);
    const phishingIncidentType = useSelector(
      DigitalIncidentTypesSelector
    ).incidentTypesL1.find((x) => x.typeId === DigitalEventTypes.PhishingEMail);
    const phishingSimulation = phishingIncidentType?.isSimulationEnabled && simulationCampaignDetails.simulationCampaign !==null
      ? simulationCampaignDetails.simulationCampaign
      : undefined;
    const digitalIncidentInformation = useSelector(
      DigitalIncidentInformationSelector
    );
    const timeZoneDetails = useSelector(TimeZonesSelector);
    const applicationSettingsSelector = useSelector(ApplicationSettingsSelector);
    const flowConfigurations = applicationSettingsSelector?.flowConfigurations;
    // using the hardcoded value if data not received from API.
    let learnMoreLinkText = (
      <a
        href="https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Don%27t-get-fooled-by-phishing.aspx"
        target="_blank"
        rel="noopener noreferrer"
      >
        {getLocalizedValueV2("RIN_LearnMoreAboutPhishingLink1")}
      </a>
    );
    if (phishingAwarenessLink !== undefined) {
      if (phishingAwarenessLink.openInNewTab === true)
        learnMoreLinkText = (
          <a
            href={phishingAwarenessLink.moreInfoUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getLocalizedValueV2("RIN_LearnMoreAboutPhishingLink1")}
          </a>
        );
      else
        learnMoreLinkText = (
          <a href={phishingAwarenessLink.moreInfoUrl} rel="noopener noreferrer">
           {getLocalizedValueV2("RIN_LearnMoreAboutPhishingLink1")}
          </a>
        );
    }
  
    const congratsMessage: any = (
      <>
        <p style={{ marginBottom: 28 }}>
          {getLocalizedValueV2("RIN_PhishingEmailCongratsMessage1")}
        </p>
        <p style={{ marginBottom: 28 }}>
          {getLocalizedValueV2("RIN_PhishingEmailCongratsMessage2")}
        </p>
        <p>
          {getLocalizedValueV2("RIN_PhishingEmailCongratsMessage3")}
          <br />
          {learnMoreLinkText}
        </p>
      </>
    );
  
    const isCongratsToBeDisplayed = useSelector(
      SimulationCampaignSelector
    ).submissionStatus;
  
    const getCurrentTimeZone = (): string => {
      if (timeZoneDetails.length > 0) {
        var timeZoneList: IDropdownOption[] = [];
        const timeZoneOffset = -new Date().getTimezoneOffset();
        let isCurrentTimeZoneSet = false;
  
        for (var i = 0; i < timeZoneDetails.length; i++) {
          if (timeZoneOffset == timeZoneDetails[i].offset) {
            return timeZoneDetails[i].text;
          }
        }
      }
      return "";
    };
  
    const handelYesButtonClicked = () => {
      let incidentDescription: string = "";
      let timeZone: string = "";
  
      if (flowConfigurations.isDigitalIncidentSubmissionViaNewFlow) {
        incidentDescription = PhishingEmailSimulationTypeName;
        timeZone = getCurrentTimeZone();
      }
      dispatch(
        postDigitalSimulationIncident({
          incidentTypeId,
          simulationCampaignId,
          incidentDescription,
          timeZone,
        })
      );
    };
  
    const handleNoButtonClicked = () => {
      // setIsDefaultOpen(false);
      // showNextStepsDialog();
      navigate("details");
    };
  
    React.useEffect(() => {
      if (isCongratsToBeDisplayed) {
        dispatch(
          showSuccessPage({ isSuccessPageShown: true, message: congratsMessage })
        );
        navigate("/v2/success");
      }
    }, [isCongratsToBeDisplayed]);
  
    return (
      <div id="phishing-simulation-container">
        <h2 aria-level={1} role="heading">
          {getLocalizedValueV2("RIN_PhishingEmailReport")}
        </h2>
        <figure
          style={{
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <Image
            src={image}
            alt={getLocalizedValueV2("RIN_PhishingSimulationScreenshot")}
            width="100%"
            shouldStartVisible
          />
          <figcaption
            style={{
              position: "absolute",
              left: -10000,
              top: "auto",
              width: 1,
              height: 1,
              overflow: "hidden",
            }}
          >
            <p>
              <caption>
                from: {phishingSimulation?.senderName}{" "}
                {phishingSimulation?.senderEmail}
              </caption>
              <caption>subject: {phishingSimulation?.emailSubject}</caption>
              <caption>{phishingSimulation?.outlookWarningMessage}</caption>
              <caption>{phishingSimulation?.emailBody}</caption>
            </p>
          </figcaption>
        </figure>
  
        <div className="ms-Grid-row submission-buttons">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
            style={{ display: "flex", gap: 12 }}
          >
            <DefaultButtonAdapter onClick={handleNoButtonClicked}>
              {getLocalizedValueV2("RIN_No")}
            </DefaultButtonAdapter>
            <PrimaryButtonAdapter onClick={handelYesButtonClicked}>
              {getLocalizedValueV2("RIN_Yes")}
            </PrimaryButtonAdapter>
          </div>
        </div>
      </div>
    );
  }