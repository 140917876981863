import * as React from "react";
import {
	IDropdownOption,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import styles from './IncidentType.module.css'
import { IncidentTypeChange } from "../../../LeaveIncident/IncidentTypeChange";
import { PsIncidentTypesSelector, PsShowAdditionalInfoSelector, PsResetLevel, resetPhysicalIncidentInformation, setPsSelectedIncidentTypeIdL1, setPsSelectedIncidentTypeIdL2, setPsSelectedIncidentTypeIdL3, setPsSelectedIncidentTypeIdL4, getPsIncidentTypesL2, setPsLocalizedSelectedIncidentTypeNameL1, setPsLocalizedSelectedIncidentTypeNameL2, setPsLocalizedSelectedIncidentTypeNameL4 } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { selectedSovereignSelector } from "../../../Sovereign/SovereignSlice";
import { HelpSectionSelector } from "../../../HelpPanel/HelpSectionSlice";
import { useNavigate } from "react-router-dom";
import { DropdownAdapter, DefaultButtonAdapter, PrimaryButtonAdapter } from "../../Controls";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";


export function PhysicalIncidentTypesv2() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const selectedSovereignId = useSelector(selectedSovereignSelector)?.nationId;
	const PsIncidentTypes = useSelector(PsIncidentTypesSelector);
	const isAdditionalInfoShown = useSelector(PsShowAdditionalInfoSelector);

	const PsIncidentTypesL1 = PsIncidentTypes.incidentTypesL1;
	const PsSelectedIncidentTypIdL1 = PsIncidentTypes.selectedincidentTypeIdL1;
	const [incidentTypesL1, setIncidentTypesL1] = React.useState<IDropdownOption[]>([]);

	const PsIncidentTypesL2 = PsIncidentTypes.incidentTypesL2;
	const PsSelectedIncidentTypIdL2 = PsIncidentTypes.selectedincidentTypeIdL2;
	const [incidentTypesL2, setIncidentTypesL2] = React.useState<IDropdownOption[]>([]);

	const PsIncidentTypesL3 = PsIncidentTypes.incidentTypesL3;
	const PsSelectedIncidentTypIdL3 = PsIncidentTypes.selectedincidentTypeIdL3;
	const [incidentTypesL3, setIncidentTypesL3] = React.useState<IDropdownOption[]>([]);

	const PsIncidentTypesL4 = PsIncidentTypes.incidentTypesL4;
	const PsSelectedIncidentTypIdL4 = PsIncidentTypes.selectedincidentTypeIdL4;
	const [incidentTypesL4, setIncidentTypesL4] = React.useState<IDropdownOption[]>([]);

	const [selectedL2IncidentName, setSelectedL2IncidentName] = React.useState<string>();

	const [isIncidentTypeChangeDialogShown, setIsIncidentTypeChangeDialogShown] = React.useState(false);

	const hideIncidentTypeChangeDialog = () => {
		setIsIncidentTypeChangeDialogShown(false);
		document.getElementById("physicalIncidentDropdownL2")?.focus();
	};
	const [toBeSelectedIncidentType, setToBeSelectedIncidentType] = React.useState<IDropdownOption>();
	const [toBeSelectedIncidentLevel, setToBeSelectedIncidentLevel] = React.useState<PsResetLevel>();

	React.useEffect(() => {
		setIncidentTypesL1(
			PsIncidentTypesL1?.map((x) => {
				return { key: x.typeId, text: getLocalizedValueV2(x.nameKey), data: getLocalizedValueV2(x.displayLabelKey), id: x.perspectiveDbId };
			})
		);
	}, [PsIncidentTypesL1]);

	React.useEffect(() => {
		setIncidentTypesL2(
			PsIncidentTypesL2?.map((x) => {
				return { key: x.typeId, text: getLocalizedValueV2(x.nameKey), data: getLocalizedValueV2(x.displayLabelKey), id: x.perspectiveDbId };
			})
		);
	}, [PsIncidentTypesL2]);

	React.useEffect(() => {
		setIncidentTypesL3(
			PsIncidentTypesL3?.map((x) => {
				return { key: x.typeId, text: getLocalizedValueV2(x.nameKey), data: getLocalizedValueV2(x.displayLabelKey), id: x.perspectiveDbId };
			})
		);
	}, [PsIncidentTypesL3]);

	React.useEffect(() => {
		setIncidentTypesL4(
			PsIncidentTypesL4?.map((x) => {
				return { key: x.typeId, text: getLocalizedValueV2(x.nameKey), data: getLocalizedValueV2(x.displayLabelKey), id: x.perspectiveDbId };
			})
		);
	}, [PsIncidentTypesL4]);

	const handleIncidentTypeChange = (props: any) => {
		const { option, level }: { option: IDropdownOption; level: PsResetLevel } = props;
		if (option) {
			if (level == PsResetLevel.IncidentTypeL1Changed) {
				dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
				dispatch(setPsSelectedIncidentTypeIdL1({ id: Number(option.key), perspectiveDbId: option.id, label: option.data ?? "" }));
				dispatch(setPsLocalizedSelectedIncidentTypeNameL1(getLocalizedValueV2(option.text)));
			} else if (level == PsResetLevel.IncidentTypeL2Changed) {
				dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL2Changed));
				dispatch(
					setPsSelectedIncidentTypeIdL2({
						id: Number(option.key),
						perspectiveDbId: option.id,
						label: option.data ?? "",
						selectedSovereignId: selectedSovereignId,
					})
				);
				dispatch(setPsLocalizedSelectedIncidentTypeNameL2(option.text));
				setSelectedL2IncidentName(option?.text);
			} else if (level == PsResetLevel.IncidentTypeL3Changed) {
				if (PsSelectedIncidentTypIdL3 !== undefined) {
					dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL3Changed));
				}
				dispatch(
					setPsSelectedIncidentTypeIdL3({
						id: Number(option.key),
						perspectiveDbId: option.id,
						label: option.data ?? "",
						selectedSovereignId: selectedSovereignId,
					})
				);
				dispatch(setPsLocalizedSelectedIncidentTypeNameL2(option.text));
			} else if (level == PsResetLevel.IncidentTypeL4Changed) {
				dispatch(
					setPsSelectedIncidentTypeIdL4({
						id: Number(option.key),
						perspectiveDbId: option.id,
						label: option.data ?? "",
						selectedSovereignId: selectedSovereignId,
					})
				);
				dispatch(setPsLocalizedSelectedIncidentTypeNameL4(option.text));
			} else if (level == PsResetLevel.SovereignIncidentTypeL1Changed) {
				dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
				dispatch(
					getPsIncidentTypesL2({
						id: Number(option.key),
						perspectiveDbId: option.id,
						label: option.data ?? "",
						selectedSovereignId: selectedSovereignId,
					})
				);
			}
		}
	};


	// used to forcefully rerender component
	const [updateToForceReRender, forceUpdate] = React.useReducer(x => x + 1, 0);
	const rerenderDropdownOnNoIncidentChange = () => {
		// Doing this rerenders all dropdowns on the page.
		// TODO: Find a simple way to rerender only the dropdowns that need to be rerendered.
		forceUpdate();
	};



	const handleIncidentTypeL2Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
		if ((!PsSelectedIncidentTypIdL2 || !isAdditionalInfoShown) && option) {
			handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL2Changed });
		} else if (option && option.key != PsSelectedIncidentTypIdL2?.id && isAdditionalInfoShown) {
			setToBeSelectedIncidentType(option);
			setToBeSelectedIncidentLevel(PsResetLevel.IncidentTypeL2Changed);
			setIsIncidentTypeChangeDialogShown(true);
		}
	};

	const handleIncidentTypeL3Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
		handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL3Changed });
	};

	const handleIncidentTypeL4Change = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
		handleIncidentTypeChange({ option: option, level: PsResetLevel.IncidentTypeL4Changed });
	};
	return (
		<div className="physical-incident-type-container">
			<div className="incident-container margin-bottom-32">
				<div className="ms-Grid-row section-header">
					<div className={styles.heading}>
						<h2 className={styles.headingh2}> {getLocalizedValueV2("RIN_IncidentType1")}</h2>
					</div>
				</div>
				<div>
					{incidentTypesL2?.length > 0 && (
						<DropdownAdapter
							id="physicalIncidentDropdownL2"
							required
							aria-required={true}
							label={PsSelectedIncidentTypIdL1?.label}
							selectedKey={PsSelectedIncidentTypIdL2?.id}
							// eslint-disable-next-line react/jsx-no-bind
							onChange={handleIncidentTypeL2Change}
							placeholder={getLocalizedValueV2("RIN_SelectIncidentType")}
							options={incidentTypesL2}
							styles={{
								root: {
									marginTop: "16px",
									marginBottom: 32,
								},
							}}
							updateToForceReRender={updateToForceReRender}
						/>
					)}
				</div>

				{PsIncidentTypesL3?.length > 0 && (
					<div className="incident-container" style={{ marginBottom: 24 }}>
						<div className="ms-Grid-row section-header">
							<h2 className="sectionHeading">{selectedL2IncidentName} definition</h2>
						</div>
						<div
							className="ms-Grid-row"
							style={{
								display: "flex",
								flexWrap: "wrap",
								alignItems: "baseline",
							}}
						>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block" style={{ padding: "0 !important" }}>
								<DropdownAdapter
									label={PsSelectedIncidentTypIdL2?.label}
									selectedKey={PsSelectedIncidentTypIdL3?.id}
									// eslint-disable-next-line react/jsx-no-bind
									onChange={handleIncidentTypeL3Change}
									placeholder={getLocalizedValueV2("RIN_Select")}
									options={incidentTypesL3}
									styles={{
										root: {
											marginBottom: "16px",
										},
									}}
									updateToForceReRender={updateToForceReRender}
								/>
							</div>
							{incidentTypesL4?.length > 0 && (
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block" style={{ padding: "0 !important" }}>
									<DropdownAdapter
										label={PsSelectedIncidentTypIdL3?.label}
										selectedKey={PsSelectedIncidentTypIdL4?.id}
										// eslint-disable-next-line react/jsx-no-bind
										onChange={handleIncidentTypeL4Change}
										placeholder={getLocalizedValueV2("RIN_Select")}
										options={incidentTypesL4}
										styles={{
											root: {
												marginBottom: "16px",
											},
										}}
										updateToForceReRender={updateToForceReRender}
									/>
								</div>
							)}
						</div>
					</div>
				)}
			</div>

			{isIncidentTypeChangeDialogShown && (
				<IncidentTypeChange
					isHidden={!isIncidentTypeChangeDialogShown}
					dismissDialog={hideIncidentTypeChangeDialog}
					executeOnContinueProps={{ option: toBeSelectedIncidentType, level: toBeSelectedIncidentLevel }}
					executeOnContinue={handleIncidentTypeChange}
					executeOnCancle={rerenderDropdownOnNoIncidentChange}
				/>
			)}
			<div className="ms-Grid-row" style={{ marginBottom: 16 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12" style={{ display: 'flex', gap: 12 }}>
					<DefaultButtonAdapter onClick={() => navigate("/v2/create/physical")}>{getLocalizedValueV2("RIN_Back")}</DefaultButtonAdapter>{" "}
					<PrimaryButtonAdapter
						disabled={!PsSelectedIncidentTypIdL2}
						onClick={() => {
							navigate("details");
						}}
					>
						{getLocalizedValueV2("RIN_Next")}
					</PrimaryButtonAdapter>
				</div>
			</div>
		</div>
	);
}
