import * as React from "react";
import {
    Checkbox,
    DefaultButton,
    Label,
    PrimaryButton,
    TextField,
} from "@fluentui/react";
import path from "path";
import FileUpload from "../../FileUpload/FileUpload";
import { onRenderLabel, renderDescriptionLabel } from "../../Home/Home";
import { useDispatch, useSelector } from "react-redux";
import PhysicalIncidentAddPerson from "../PhysicalIncidentAddPerson/PhysicalIncidentAddPerson";
import PhysicalIncidentAddVehicle from "../PhysicalIncidentAddVehicle/PhysicalIncidentAddVehicle";
import PhysicalIncidentAddItem from "../PhysicalIncidentAddItem/PhysicalIncidentAddItem";
import { setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { removeMessage } from "../../Notifications/NotificationsSlice";
import { physicalIncidentInformationSelector, physicalIncidentSelector, PsErrorStatesSelector, PsIncidentTypesSelector, PsResetLevel, PsSelectedincidentTypeIdL1Selector, PsShowAdditionalInfoSelector, resetPhysicalIncidentInformation, resetValidationMessageIncidentSummary, resetValidationMessagePhoneNumber, resetValidationMessagePoliceFileNumber, setAttachments, setPoliceFileNumberNv, setReportedToPoliceBt, setReporterPhoneNumber, setSummaryNt, submitPhysicalSecurityIncident } from "../PhysicalIncident/PhysicalIncidentSlice";
import { PsAttachmentInputViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsAttachmentInputViewModel";
import { clearErrorStates, clearFiles, fileSelector, setFileValidationErrorState } from "../../FileUpload/FileUploadSlice";
import { primaryButtonStyles } from "../../../styles";
import { buttonStyle } from "../../DigitalSecurity/DigitalIncident/DigitalIncident";
import { postSovereignPhysicalFormInformation } from "../../Sovereign/SovereignSlice";
import { getLocalizedValue } from "../../../Services/localizationService";

export function AdditionalInformation() {
    const dispatch = useDispatch();
    const fileDetails = useSelector(fileSelector);
    const showAdditionalInfo = useSelector(PsShowAdditionalInfoSelector);

    const errorStates = useSelector(PsErrorStatesSelector);

    const PsIncidentInfo = useSelector(physicalIncidentInformationSelector);
    const isReportedToPolice = PsIncidentInfo.reportedToPoliceBt;

    const policeFileNumber = PsIncidentInfo.policeFileNumberNv;
    const reporterPhoneNumber = PsIncidentInfo.reporterPhoneNumber;
    const incidentSummary = PsIncidentInfo.summaryNt;

    const PsIncidentTypes = useSelector(PsIncidentTypesSelector);
    const PsSelectedIncidentTypIdL1 = PsIncidentTypes.selectedincidentTypeIdL1;

    const phoneErrorMessage = (
        <FieldValidationDescription
            errorMessage={
                errorStates.PhoneNumber
            }
            iconName={"ErrorBadge"}
        />
    );

    const policeFileNumberErrorMessage = (
        <FieldValidationDescription
            errorMessage={
                errorStates.PoliceFileNumber
            }
            iconName={"ErrorBadge"}
        />
    );

    const incidentSummaryErrorMessage = (
        <FieldValidationDescription
            errorMessage={
                errorStates.IncidentSummary
            }
            iconName={"ErrorBadge"}
        />
    );

    const handleCancleButtonClicked = () => {
        dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
        dispatch(setSelectedIncidentCategory(undefined));
        dispatch(clearFiles());
        dispatch(removeMessage("validation-message"));
        document.getElementById("physicalSecurityId")?.focus();
        document.getElementById("mainRegion")?.scrollTo(0, 0)
    };

    const removeFileExtension = (filename: string): string => {
        var lastDotPosition = filename.lastIndexOf(".");
        if (lastDotPosition === -1) return filename;
        else return filename.substr(0, lastDotPosition);
    }

    const saveFileToState = () => new Promise((resolve: any) => {
        var fileObjects: PsAttachmentInputViewModel[] = [];

        const size = (fileDetails?.files?.reduce((p, c, i) => p + c.fileSize, 0) ?? 0);
        if (size > (20 * 1024 * 1024)) {
            dispatch(setFileValidationErrorState("Total attachment size exceeded"));
        }

        fileDetails.files.forEach(file => {
            fileObjects.push({
                attachmentIm: file.fileContent,
                fileExtensionNv: path.extname(file.fileName),
                fileNameNv: file.fileName,
                fileSizeIn: file.fileSize,
                attachmentTitleNv: removeFileExtension(file.fileName)
            });
        });
        dispatch(setAttachments(fileObjects));
        resolve();
    })
    const physicalIncidentData = useSelector(physicalIncidentSelector);
    const selectedincidentTypeIdL1 = useSelector(PsSelectedincidentTypeIdL1Selector)
    const handleSubmitButtonClicked = () => {
        dispatch(clearErrorStates());
        saveFileToState().then(() => {
            // if it's a gov incident, trigger gov submission, else trigger physical security submission.
            let selectedPhysicalIncidentL1 = physicalIncidentData.incidentTypes.incidentTypesL1.find(x => x.typeId == selectedincidentTypeIdL1?.id);
            if (selectedPhysicalIncidentL1?.isGovType && (selectedPhysicalIncidentL1?.govNationId == null || selectedPhysicalIncidentL1?.govNationId == undefined || selectedPhysicalIncidentL1?.govNationId == 0)) {
                dispatch(postSovereignPhysicalFormInformation({ physicalIncident: physicalIncidentData }));
            }
            else {
                dispatch(submitPhysicalSecurityIncident());
            }
        })
    };

    const handlePhoneNumberChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch(setReporterPhoneNumber(newValue ?? ""));
        dispatch(resetValidationMessagePhoneNumber());
    }

    const handleSummaryChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch(setSummaryNt(newValue ?? ""));
        dispatch(resetValidationMessageIncidentSummary());
    }

    React.useEffect(() => {
        if (!isReportedToPolice) {
            dispatch(setPoliceFileNumberNv(undefined));
            dispatch(resetValidationMessagePoliceFileNumber());
        }
    }, [isReportedToPolice])

    if (showAdditionalInfo)
        return (
            <div>
                <div className="ms-Grid-row section-header">
                    <h2 className="sectionHeading">Incident information</h2>
                </div>
                <div id="police-report" style={{ marginBottom: "28px" }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                            <Checkbox
                                checked={isReportedToPolice}
                                onChange={() => dispatch(setReportedToPoliceBt(!isReportedToPolice))}
                                aria-labelledby="poilce-report-label"
                                label={getLocalizedValue("RIN_ReportedToThePolice")}
                                onRenderLabel={(props) => onRenderLabel(props, "poilce-report-label", 400)}
                                styles={{
                                    checkbox: {
                                        backgroundColor: "white",
                                    },
                                    checkmark: {
                                        color: "black",
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {
                        isReportedToPolice && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
                                    <TextField
                                        id="physicalIncidentPoliceFileNumberId"
                                        aria-required={true}
                                        aria-label={getLocalizedValue("RIN_AddPoliceFileNumber")}
                                        placeholder={getLocalizedValue("RIN_AddPoliceFileNumber")}
                                        value={policeFileNumber}
                                        onChange={(e, newValue) => { dispatch(setPoliceFileNumberNv(newValue)); dispatch(resetValidationMessagePoliceFileNumber()); }}
                                        onRenderDescription={() => policeFileNumberErrorMessage}
                                        styles={{
                                            root: {
                                                marginTop: 12
                                            },
                                            field: errorStates.PoliceFileNumber.length > 0 ? { backgroundColor: "#FED9CC" } : { backgroundColor: "white" }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div id="phone-number" style={{ marginBottom: "28px" }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
                            <Label>{getLocalizedValue("RIN_YourPhoneNumber")}</Label>
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                            <span
                                id="incident-phone-label"
                                style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    display: "block",
                                    marginBottom: 8,
                                    color: "white"
                                }}
                            >{getLocalizedValue("RIN_YourPhoneNumberInfo")}</span>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block">
                            <TextField
                                id="physicalIncidentPhoneNumberId"
                                aria-required={true}
                                aria-labelledby="incident-phone-label"
                                placeholder={getLocalizedValue("RIN_AddAPhoneNumber")}
                                value={reporterPhoneNumber}
                                onChange={handlePhoneNumberChange}
                                styles={
                                    errorStates.PhoneNumber.length > 0 ? {
                                        field: {
                                            backgroundColor: "#FED9CC"
                                        }
                                    } :
                                        {
                                            field: {
                                                backgroundColor: "white"
                                            }
                                        }
                                }
                                onRenderDescription={() => phoneErrorMessage}
                            />
                        </div>
                    </div>
                </div>
                <div id="incident-summary" style={{ marginBottom: "28px" }}>
                    {
                        PsSelectedIncidentTypIdL1?.id !== undefined && PsSelectedIncidentTypIdL1.perspectiveDbId === null &&
                        (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                    <Label>{getLocalizedValue("RIN_CUIDisclaimer")}</Label>
                                </div>
                            </div>
                        )
                    }
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                            <Label>{getLocalizedValue("RIN_IncidentSummary")}</Label>
                        </div>
                    </div>

                    <TextField
                        //componentRef={physicalIncidentSummaryRef}
                        id="physicalIncidentSummaryId"
                        aria-labelledby="incident-summary-label"
                        aria-required={true}
                        rows={6}
                        label={getLocalizedValue("RIN_IncidentSummaryDesc")}
                        placeholder={getLocalizedValue("RIN_IncidentSummaryPlaceholder")}
                        multiline
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(
                                props,
                                "incident-summary-label"
                            )
                        }
                        value={incidentSummary}
                        onChange={handleSummaryChange}
                        styles={
                            errorStates.IncidentSummary.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                        onRenderDescription={() => {

                            return incidentSummaryErrorMessage
                        }}
                    />
                </div>

                <FileUpload
                    allowdSizePerFileInMB={5}
                    totalAllowedSizeInMB={20}
                    checkFileTypes={false}
                    allowedFileTypes={[]}
                    allowedFileNameLength={100}
                />

                <div
                    className="add-related-items-section"
                    style={{ marginBottom: "28px" }}
                >
                    <PhysicalIncidentAddPerson />
                    <PhysicalIncidentAddVehicle />
                    <PhysicalIncidentAddItem />
                </div>

                <div className="ms-Grid-row submission-buttons">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <DefaultButton
                            onClick={handleSubmitButtonClicked}
                            styles={{
                                root: buttonStyle,
                                rootHovered: buttonStyle
                            }}
                        >
                            {getLocalizedValue("RIN_Submit")}
                        </DefaultButton>
                        &nbsp;&nbsp;&nbsp;
                        <PrimaryButton
                            styles={primaryButtonStyles}
                            onClick={handleCancleButtonClicked}
                        >
                            {getLocalizedValue("RIN_Cancel")}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        );
    return <></>;
}
