import { ChoiceGroup, IChoiceGroupOption, IconButton, Label } from "@fluentui/react";
import * as React from "react";
import { useSelector } from "react-redux";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import { getLocalizedValue } from "../../../Services/localizationService";
import { getRadioButtonStyle, whiteLinkStyle } from "../../../styles";
import { HelpSectionSelector } from "../../HelpPanel/HelpSectionSlice";
import { setIsOutlookButtonAvailable } from "../DigitalIncident/DigitalIncidentSlice";
import OutlookIcon from "./outlookIcon.jpg";


export default function OutlookReportingInstructions(props: any) {
    const {
        isOutlookButtonAvailable,
        dispatch,
    } = props;

    let outlookReportingOptions: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Yes"), ariaLabel:getLocalizedValue("RIN_OutlookMessageButton") + getLocalizedValue("RIN_Yes") },
        { key: "2", text: getLocalizedValue("RIN_No"), ariaLabel: getLocalizedValue("RIN_OutlookMessageButton") + getLocalizedValue("RIN_No") },
    ];
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const phishingEmailInstructionsSection = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.find(s => s.panelId === PanelEnums.DigitalIncidentForm) : undefined;
    const phishingAwarenessLink = phishingEmailInstructionsSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === phishingEmailInstructionsSection.sectionId && s.linkId == 22);
    const outlookReportingInstructionsLink = phishingEmailInstructionsSection === undefined ? undefined : helpSectionDetails.helpSectionLinks.find(s => s.sectionId === phishingEmailInstructionsSection.sectionId && s.linkId == 21);

    // using the hardcoded value if data not received from API.
    let outlookButtonLinkText = <a href="https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Report-suspicious-emails-with-Outlook%27s-Report-Message-button.aspx" target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>{getLocalizedValue("RIN_PhishingEmailInstructions2")}</a>;
    if (outlookReportingInstructionsLink !== undefined) {
        if (outlookReportingInstructionsLink.openInNewTab === true)
            outlookButtonLinkText = <a href={outlookReportingInstructionsLink.moreInfoUrl} rel="noopener noreferrer" target="_blank" style={whiteLinkStyle}>{getLocalizedValue("RIN_PhishingEmailInstructions2")}</a>
        else
            outlookButtonLinkText = <a href={outlookReportingInstructionsLink.moreInfoUrl} style={whiteLinkStyle}>{getLocalizedValue("RIN_PhishingEmailInstructions2")}</a>
    }

    // using the hardcoded value if data not received from API.
    let msPhishLinkText = <a href="https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Don%27t-get-fooled-by-phishing.aspx" target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>aka.ms/MSPhish</a>
    if (phishingAwarenessLink !== undefined) {
        if (phishingAwarenessLink.openInNewTab === true)
            msPhishLinkText = <a href={phishingAwarenessLink.moreInfoUrl} target="_blank" rel="noopener noreferrer" style={whiteLinkStyle}>aka.ms/MSPhish</a>
        else
            msPhishLinkText = <a href={phishingAwarenessLink.moreInfoUrl} style={whiteLinkStyle}>aka.ms/MSPhish</a>
    }

    React.useEffect(() => {
        if (isOutlookButtonAvailable) { document.getElementById("phishing-outlook-reporting-instructions")?.focus(); }

    })

    return (
        <div className="outlook-reporting-instructions margin-bottom-32" id="phishing-outlook-reporting-instructions" tabIndex={0} >
            <div className="ms-Grid-row section-header" >
                <h2 className="heading">{getLocalizedValue("RIN_Instructions")}</h2>
            </div>

            <div
                className="ms-Grid-row"
                style={{ display: "flex", alignItems: "center" }}
            >
                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9 ms-xl9 ms-xxl9 ms-xxxl9 block" style={{ fontSize: 14 }}>
                    <p>
                        {getLocalizedValue("RIN_PhishingEmailInstructions1")} {outlookButtonLinkText}  {getLocalizedValue("RIN_PhishingEmailInstructions3")}
                    </p>
                    <p>
                        {getLocalizedValue("RIN_ToLearnMoreAboutPhishingPleaseVisit")} {msPhishLinkText}
                    </p>
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block">
                    <img
                        src={OutlookIcon}
                        alt={getLocalizedValue("RIN_ReportIncidentButtonInOutlook")}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <Label
                        id="outlook-report-question"
                        style={{ fontWeight: "normal" }}
                    >
                        {getLocalizedValue("RIN_OutlookMessageButton")}
                    </Label>
                    <ChoiceGroup
                        selectedKey={
                            isOutlookButtonAvailable == true ? "1" : "2"
                        }
                        options={outlookReportingOptions}
                        onChange={(event, selectedOption) => {
                            dispatch(
                                setIsOutlookButtonAvailable(
                                    selectedOption?.text === getLocalizedValue("RIN_Yes")
                                )
                            );
                        }}
                        styles={getRadioButtonStyle(128)}
                    />
                    {!isOutlookButtonAvailable && (
                        <p>{getLocalizedValue("RIN_CompletingTheFormBelow")}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
