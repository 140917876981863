import * as React from "react";
import { Image } from "@fluentui/react";
import ErrorImage from "../../../Assets/Error.svg";
import { useSelector } from "react-redux";
import { ExceptionPageModel, exceptionPageSelector } from "../../ExceptionPage/exceptionPageSlice";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import "../../ExceptionPage/ExceptionPage.css";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";

export default function ExceptionPagev2({ errorMessageToDisplay, errorPageHeader, occupyFullScreen }: ExceptionPageModel) {
	
	const exceptionPageDetails = useSelector(exceptionPageSelector);

	const exceptionSupport: string = getLocalizedValueV2("RIN_ErrorMessage_Part1") + " <b><a href='/' aria-label='Refresh page'>" + getLocalizedValueV2("RIN_ErrorMessage_Part2") + "</a></b> " + getLocalizedValueV2("RIN_ErrorMessage_Part3") + " <b><a href='https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=BY3h3t' aria-label='IcM'>" + getLocalizedValueV2("RIN_ErrorMessage_Part4") + "</a></b> "+ getLocalizedValueV2("RIN_ErrorMessage_Part5") +" <b><a href='mailto: steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now'>steengind@microsoft.com</a></b>";

	React.useEffect(() => {
        document.title = `Report It Now | Error page`;
    },[]);

	return (
		<div className="exception-page">
			<div className="ms-Grid grid-container" dir="ltr" style={{ textAlign: "center", marginTop: 36, }}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg6 ms-lgPush3 ms-xl4 ms-xlPush4 ms-xxl4 ms-xxlPush4 ms-xxxl4 ms-xxxlPush4 block incident-container">
						<Image
							src={ErrorImage}
							alt={getLocalizedValueV2("RIN_AnExceptionOccurred")}
							styles={{
								root: {
									marginTop: 24,
									marginBottom: 36,
								},
								image: {
									margin: "0 auto",
									width: "36%",
								},
							}}
						/>
						<h2>
							<RichTextRenderer
								html={
									errorPageHeader ??
									(exceptionPageDetails.errorPageHeader?.length ? exceptionPageDetails.errorPageHeader : getLocalizedValueV2("RIN_ErrorPageHeader"))
								}
							/>
						</h2>
						<p style={{ fontSize: 14}}>
							<RichTextRenderer
								html={
									errorMessageToDisplay ??
									(exceptionPageDetails.errorMessageToDisplay?.length
										? exceptionPageDetails.errorMessageToDisplay
										: exceptionSupport )
								}
							/>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
