import {
    DefaultButton,
    DialogFooter,
    DialogType,
    Dropdown,
    IButtonStyles,
    IconButton,
    IDropdownOption,
    IIconProps,
    ITextField,
    Label,
    Modal,
    PrimaryButton,
    TextField,
} from "@fluentui/react";
import * as React from "react";
import { IconButtonStyles, physicalIncidentCardStyle } from "../../../../styles";
import { useDispatch, useSelector } from "react-redux";
import { AddedItemModel } from "../../../../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import './PhysicalIncidentAddItem.css'
import { useBoolean } from "../../../../Hooks/useBoolean";
import { PsAddedItemsSelector, PsItemMakeTypesSelector, PsItemModelTypesSelector, PsItemCategoryTypesSelector, PsItemSubCategoryTypesSelector, getItemMakeTypes, getItemCategoryTypes, validateAndAddItem, validateAndUpdateItem, deleteItemFromAddedItems, setSelectedItemMakeTypeId, setSelectedItemCategoryTypeId } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { DropdownAdapter } from "../../Controls";

const addIcon: IIconProps = { iconName: "Add" };


const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

export default function PhysicalIncidentAddItemv2() {
    const [hideDialog, { toggle: toggleHideDialog, setFalse: openHiddenDialog, },] = useBoolean(true);

    const [itemId, setItemId] = React.useState<number>(-1);
    const [ItemName, setItemName] = React.useState<string>();
    const [ItemValue, setItemValue] = React.useState<number>();
    const [SerialNumber, setSerialNumber] = React.useState<string>();
    const [ItemMakeId, setItemMake] = React.useState<string>();
    const [ItemModelId, setItemModel] = React.useState<string>();
    const [ItemCategoryId, setItemCategory] = React.useState<string>();
    const [ItemSubCategoryId, setItemSubCategory] = React.useState<string>();
    const [ItemOwnerName, setItemOwnerName] = React.useState<string>();
    const [notes, setNotes] = React.useState<string>();

    const [ItemNameErrorMessage, setItemNameErrorMessage] = React.useState<string>();
    const [ItemValueErrorMessage, setItemValueErrorMessage] = React.useState<string>();
    const [SerialNumberErrorMessage, setSerialNumberErrorMessage] = React.useState<string>();
    const [ItemOwnerNameErrorMessage, setItemOwnerNameErrorMessage] = React.useState<string>();
    const [notesErrorMessage, setNotesErrorMessage] = React.useState<string>();

    const addIconRef = React.createRef<HTMLElement>();

    const addedItems = useSelector(PsAddedItemsSelector);

    const PsItemMakeTypes = useSelector(PsItemMakeTypesSelector);
    const [ItemMakeTypes, setItemMaketypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setItemMaketypes(PsItemMakeTypes.map(x => {
            return { key: x.idPk, text: x.makeModelNv }
        }) ?? [])
    }, [PsItemMakeTypes])

    const PsItemModelTypes = useSelector(PsItemModelTypesSelector);
    const [ItemModelTypes, setItemModelTypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setItemModelTypes(PsItemModelTypes.map(x => {
            return { key: x.idPk, text: x.makeModelNv }
        }) ?? [])
    }, [PsItemModelTypes])

    const PsItemCategoryTypes = useSelector(PsItemCategoryTypesSelector);
    const [ItemCategoryTypes, setItemCategorytypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setItemCategorytypes(PsItemCategoryTypes.map(x => {
            return { key: x.idPk, text: x.itemClassNv }
        }) ?? [])
    }, [PsItemCategoryTypes])

    const PsItemSubCategoryTypes = useSelector(PsItemSubCategoryTypesSelector);
    const [ItemSubCategoryTypes, setItemSubCategorytypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setItemSubCategorytypes(PsItemSubCategoryTypes.map(x => {
            return { key: x.idPk, text: x.itemClassNv }
        }) ?? [])
    }, [PsItemSubCategoryTypes])

    const resetCurrentItem = () => {
        setItemId(-1);
        setItemName(undefined);
        setItemValue(undefined);
        setSerialNumber(undefined);
        setItemMake(undefined);
        setItemModel(undefined);
        setItemCategory(undefined);
        setItemSubCategory(undefined);
        setItemOwnerName(undefined);
        setNotes(undefined);
    };

    const dispatch = useDispatch();

    const handleAddItemButtonClicked = () => {
        if (ItemMakeTypes.length == 0)
            dispatch(getItemMakeTypes());

        if (ItemCategoryTypes.length == 0)
            dispatch(getItemCategoryTypes())

        toggleHideDialog();
    }
    const itemNameRef = React.createRef<ITextField>();
    const itemValueRef = React.createRef<ITextField>();
    const serialNumberRef = React.createRef<ITextField>();
    const itemOwnerRef = React.createRef<ITextField>();
    const notesRef = React.createRef<ITextField>();

    const validateItem = () => {
        let isValid = true;

        if (!ItemName || ItemName?.trim()?.length == 0) {
            if (isValid) {
                itemNameRef.current?.focus();
            }
            isValid = false;
            setItemNameErrorMessage(getLocalizedValueV2("RIN_ItemNameIsRequired"));
        }
        else if (ItemName?.trim().length > 100) {
            if (isValid) {
                itemNameRef.current?.focus();
            }
            isValid = false;
            setItemNameErrorMessage(getLocalizedValueV2("RIN_ItemNameCannotBeLongerThan"));
        }

        if (!ItemValue) {
            if (isValid) {
                itemValueRef.current?.focus();
            }
            isValid = false;
            setItemValueErrorMessage(getLocalizedValueV2("RIN_ItemValueIsRequired"));
        }
        else if (ItemValue > 9999999999.99 || ItemValue < 0) {
            if (isValid) {
                itemValueRef.current?.focus();
            }
            isValid = false;
            setItemValueErrorMessage(getLocalizedValueV2("RIN_ItemValueMustBeBetween"));
        }

        if (SerialNumber && SerialNumber?.trim().length > 50) {
            if (isValid) {
                serialNumberRef.current?.focus();
            }
            isValid = false;
            setSerialNumberErrorMessage(getLocalizedValueV2("RIN_SerialNumberCannotBeLongerThan"));
        }

        if (ItemOwnerName && ItemOwnerName?.trim().length > 100) {
            if (isValid) {
                itemOwnerRef.current?.focus();
            }
            isValid = false;
            setItemOwnerNameErrorMessage(getLocalizedValueV2("RIN_ItemOwnerCannotBeLonger"));
        }

        if (notes && notes?.trim().length > 4000) {
            if (isValid) {
                notesRef.current?.focus();
            }
            isValid = false;
            setNotesErrorMessage(getLocalizedValueV2("RIN_TotalCharactersMustBeLessThanOrEqual"));
        }

        return isValid;
    }

    const handelAddButtonClicked = () => {
        const isValid = validateItem();
        if (isValid) {
            const addedItem: AddedItemModel = {
                id: itemId,
                selectedMakeId: ItemMakeId,
                selectedModelId: ItemModelId,
                selectedCategoryId: ItemCategoryId,
                selectedSubCategoryId: ItemSubCategoryId,
                eitemNameNv: ItemName?.trim() ?? "",
                eserialNumberNv: SerialNumber?.trim() ?? "",
                eitemClassRollupIdFk: (ItemSubCategoryId?.length ?? 0) > 0 ? ItemSubCategoryId : ItemCategoryId,
                eitemMakeModelRollupIdFk: (ItemModelId?.length ?? 0) > 0 ? ItemModelId : ItemMakeId,
                eitemValueNm: ItemValue,
                eownerNamePersonNv: ItemOwnerName?.trim(),
                itemNotesNt: notes?.trim() ?? "",
            }
            if (itemId == -1) {
                dispatch(validateAndAddItem(addedItem))
            } else {
                dispatch(validateAndUpdateItem(addedItem))
            }

            resetCurrentItem();

            toggleHideDialog();

            if (document.querySelector("div[class='visually-hidden']") != null) {
                if (itemId === -1) {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyAddedItem") + ItemName;
                }
                else {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyUpdatedItem") + ItemName;
                }
            }
        }
    };

    const handleCloseButtonClicked = () => {
        resetCurrentItem();

        toggleHideDialog();
    };

    const setCurrentItem = (item: AddedItemModel | undefined) => {
        setItemId(item?.id ?? -1);
        setItemName(item?.eitemNameNv);
        setItemValue(item?.eitemValueNm);
        setSerialNumber(item?.eserialNumberNv);
        setItemMake(item?.selectedMakeId);
        setItemModel(item?.selectedModelId);
        setItemCategory(item?.selectedCategoryId);
        setItemSubCategory(item?.selectedSubCategoryId);
        setItemOwnerName(item?.eownerNamePersonNv);
        setNotes(item?.itemNotesNt);
    };

    const deleteItem = (itemId: number) => {
        let deleteItem = addedItems.find(s => s.id === itemId);
        if (document.querySelector("div[class='visually-hidden']") != null) {
            document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyDeletedItem") + deleteItem?.eitemNameNv;
        }
        dispatch(deleteItemFromAddedItems(itemId))
        resetCurrentItem();
        addIconRef?.current?.focus();
    };

    const updateItem = (itemId: number) => {
        const currentItem = addedItems.find((x) => x.id === itemId);
        if (currentItem) {
            dispatch(setSelectedItemMakeTypeId(currentItem.selectedMakeId));
            dispatch(setSelectedItemCategoryTypeId(currentItem.selectedCategoryId));
        }
        setCurrentItem(currentItem);
        openHiddenDialog();
    };

    return (
        <div style={{ marginBottom: "28px" }}>
            <div className="visually-hidden" aria-live="assertive"></div>
            <div className="ms-Grid-row" style={{ marginBottom: "8px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <IconButton
                        elementRef={addIconRef}
                        iconProps={addIcon}
                        title={getLocalizedValueV2("RIN_AddItemOptional")}
                        onClick={handleAddItemButtonClicked}
                        styles={{
                            root: {
                                backgroundColor: "white",
                            },
                        }}
                    />
                    <Label
                        styles={{
                            root: {
                                display: "inline",
                                paddingLeft: 12,
                                verticalAlign: "text-top",
                            },
                        }}
                    >
                        {getLocalizedValueV2("RIN_AddItemOptional")}
                    </Label>
                </div>
            </div>

            {addedItems.map((item) => (
                <>
                    <div
                        className="ms-Grid-row"
                        style={physicalIncidentCardStyle}
                    >
                        <div
                            className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block"
                            style={{ padding: 0 }}
                        >
                            <div>
                                <div>
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_ItemName")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ wordBreak: "break-all" }}>{item.eitemNameNv}</span>
                                    <br />
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_ItemValue")}:
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ marginLeft: "-2px" }}>{item.eitemValueNm ?? "N/A"}</span>
                                    <br />
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_ItemOwner")}:
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ marginLeft: "-1px", wordBreak: "break-all" }}>{item.eownerNamePersonNv?.length ? item.eownerNamePersonNv : "N/A"}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-x2 ms-xx2 ms-xxxl2 block"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: 0,
                            }}
                        >
                            <IconButton
                                onClick={() => updateItem(item.id)}
                                iconProps={{ iconName: "Edit" }}
                                title={getLocalizedValueV2("RIN_EditItemDetailsOf")+" " + item.eitemNameNv}
                            />
                            <IconButton
                                onClick={() => deleteItem(item.id)}
                                iconProps={{ iconName: "Delete" }}
                                title={getLocalizedValueV2("RIN_DeleteItemDetailsOf")+" " + item.eitemNameNv}
                            />
                        </div>
                    </div>
                </>
            ))}

            <hr style={{ marginTop: 36, marginBottom: 36 }} />

            <Modal
                titleAriaId='add-item-modal-header'
                isOpen={!hideDialog}
                onDismiss={handleCloseButtonClicked}
                isModeless={true}
                styles={{
                    main: {
                        padding: 30,
                        paddingTop: 0,
                    },
                    scrollableContent: {
                        overflowX: 'hidden'
                    }
                }}
            >
                <div style={{
                    flex: '1 1',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                }}>
                    <h2 id='add-item-modal-header'>{getLocalizedValueV2("RIN_AddItem")}</h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={getLocalizedValueV2("RIN_ClosePopUpModal")}
                        onClick={handleCloseButtonClicked}
                    />
                </div>
                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div className="ms-Grid-col ms-s12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <TextField
                            componentRef={itemNameRef}
                            required
                            aria-required={true}
                            label={getLocalizedValueV2("RIN_ItemName")}
                            value={ItemName}
                            placeholder={getLocalizedValueV2("RIN_ItemNamePlaceholder")}
                            onChange={(e, value) => { setItemName(value); setItemNameErrorMessage(undefined); }}
                            errorMessage={ItemNameErrorMessage}
                            styles={{
                                root: {
                                    display: "inline-block",
                                    width: "100%",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl4 ms-xxl4 ms-xxxl4 block popup-form-control-container value-container">
                        <TextField
                            componentRef={itemValueRef}
                            required
                            aria-required={true}
                            label={getLocalizedValueV2("RIN_ItemValue")}
                            placeholder="$"
                            type="number"
                            step={0.01}
                            value={ItemValue?.toString()}
                            onChange={(e, value) => { setItemValue(Number(value)); setItemValueErrorMessage(undefined); }}
                            errorMessage={ItemValueErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-s12 ms-md12 ms-lg8 ms-xl8 ms-xxl8 ms-xxxl8 block popup-form-control-container serial-number-conteiner"
                    >
                        <TextField
                            componentRef={serialNumberRef}
                            label={getLocalizedValueV2("RIN_SerialNumberOptional")}
                            value={SerialNumber}
                            placeholder={getLocalizedValueV2("RIN_SerialNumberOptionalPlaceholder")}
                            onChange={(e, value) => { setSerialNumber(value); setSerialNumberErrorMessage(undefined) }}
                            errorMessage={SerialNumberErrorMessage}
                            styles={{
                                root: {
                                    display: "inline-block",
                                    width: "100%",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <DropdownAdapter
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_ItemMakeOptional")}
                            selectedKey={ItemMakeId}
                            onChange={(e, value) => {
                                setItemMake(value?.key?.toString())
                                dispatch(setSelectedItemMakeTypeId(value?.key?.toString()));
                            }}
                            options={ItemMakeTypes}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                    {
                        ItemModelTypes?.length > 0 &&
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                        >

                                <DropdownAdapter
                                placeholder={getLocalizedValueV2("RIN_Select")}
                                label={getLocalizedValueV2("RIN_ItemModelOptional")}
                                selectedKey={ItemModelId}
                                onChange={(e, value) =>
                                    setItemModel(value?.key?.toString())
                                }
                                options={ItemModelTypes}
                                styles={{
                                    root: {
                                        width: "100%",
                                        display: "inline-block",
                                    },
                                }}
                            />

                        </div>}
                </div>
                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <DropdownAdapter
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_ItemCategoryOptional")}
                            options={ItemCategoryTypes}
                            selectedKey={ItemCategoryId}
                            onChange={(e, value) => {
                                setItemCategory(value?.key?.toString())
                                dispatch(setSelectedItemCategoryTypeId(value?.key?.toString()))
                            }}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                                dropdownItemsWrapper: {
                                    selectors: {
                                        '@media (min-width: 720px)': {
                                            maxHeight: "30vh"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    {
                        ItemSubCategoryTypes.length > 0 &&
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                        >

                                <DropdownAdapter
                                placeholder={getLocalizedValueV2("RIN_Select")}
                                label={getLocalizedValueV2("RIN_ItemSubCategoryOptional")}
                                options={ItemSubCategoryTypes}
                                selectedKey={ItemSubCategoryId}
                                onChange={(e, value) =>
                                    setItemSubCategory(value?.key?.toString())
                                }
                                styles={{
                                    root: {
                                        width: "100%",
                                        display: "inline-block",
                                    },
                                    dropdownItemsWrapper: {
                                        selectors: {
                                            '@media (min-width: 720px)': {
                                                maxHeight: "30vh"
                                            }
                                        }
                                    }
                                }}
                            />

                        </div>}
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div className="ms-Grid-col ms-s12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <TextField
                            componentRef={itemOwnerRef}
                            label={getLocalizedValueV2("RIN_ItemOwnersNameOptional")}
                            value={ItemOwnerName}
                            placeholder="Who owned this item"
                            onChange={(e, value) => { setItemOwnerName(value); setItemOwnerNameErrorMessage(undefined); }}
                            errorMessage={ItemOwnerNameErrorMessage}
                            styles={{
                                root: {
                                    display: "inline-block",
                                    width: "100%",
                                },
                            }}
                        />
                    </div>
                </div>

                <TextField
                    componentRef={notesRef}
                    label={getLocalizedValueV2("RIN_NotesOptional")}
                    placeholder={getLocalizedValueV2("RIN_AddAdditionalDetail")}
                    multiline
                    rows={4}
                    styles={{ root: { marginBottom: 28 } }}
                    value={notes}
                    onChange={(e, value) => { setNotes(value); setNotesErrorMessage(undefined); }}
                    errorMessage={notesErrorMessage}
                />

                <DialogFooter>
                    <PrimaryButton
                        onClick={handelAddButtonClicked}
                        text={itemId == -1 ? getLocalizedValueV2("RIN_Add") : getLocalizedValueV2("RIN_Update")}
                    />
                    <DefaultButton
                        onClick={handleCloseButtonClicked}
                        text={getLocalizedValueV2("RIN_Cancel")}
                    />
                </DialogFooter>
            </Modal>
        </div>
    );
}
