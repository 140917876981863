import { Callout, DelayedRender, DirectionalHint, Dropdown, FocusTrapCallout, FocusTrapZone, IconButton, IDropdownOption, Stack } from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { labelCalloutStackStyles, stackTokens } from "../../Constants/CommonConstants";
import { SovereignDigitalEventTypes } from "../../Contracts/Enums/DigitalIncidentEnums";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { DigitalIncidentType, DigitalIncidentTypes } from "../../Contracts/TypesAndInterfaces/DigitalIncidentType";
import { PsPhysicalIncidentTypeResponseViewModel } from "../../Contracts/TypesAndInterfaces/PsPhysicalIncidentTypeResponseViewModel";
import { getLocalizedValue } from "../../Services/localizationService";
import { clearDigitalIncidentErrorStates, resetDigitalIncidentInformation, setIsOutlookButtonAvailable } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { CustomLabel } from "../DigitalSecurity/IncidentSpecificFields/IncidentSpecificFields";
import { clearFiles } from "../FileUpload/FileUploadSlice";
import { IncidentTypeChange } from "../LeaveIncident/IncidentTypeChange";
import { removeMessage } from "../Notifications/NotificationsSlice";
import { hideAdditionalInfo, PsResetLevel, resetPhysicalIncidentInformation } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import "./Sovereign.css";
import { SovereignChangeDialog } from "./SovereignChangeDialog";
import { clearSovereignDetails, getSovereignDigitalL2IncidentTypes, getSovereigns, setSelectedSovereign, setSovereignIncidentCategory, setSovereignIncidentTypes, setSovereignSelectedIncidentTypeL1, setSovereignSelectedIncidentTypeL2, sovereignSelector } from "./SovereignSlice";

export interface SovereignSelectionProps {
    IncidentCategory: IncidentCategories;
    triggerOnIncidentTypeSelection?: (option?: IDropdownOption) => void;
    childRenderer?: Function;
}
const iconProps = { iconName: "Unknown" };
export function SovereignSelectionComponent({IncidentCategory, childRenderer, triggerOnIncidentTypeSelection }: SovereignSelectionProps) {
    const dispatch = useDispatch();
    
    const sovereign = useSelector(sovereignSelector);
    const sovereigns = sovereign.SovereignDetails.Sovereigns;
    const sovereignIncidentTypes = sovereign.IncidentDetails.IncidentTypes;
    const sovereignSelectedIncidentTypeL1 = sovereign.IncidentDetails.selectedIncidentTypeL1;
    const sovereignSelectedIncidentTypeL2 = sovereign.IncidentDetails.selectedIncidentTypeL2;
    const [sovereignList, setSovereignList] = React.useState<IDropdownOption[]>([]);
    const [selectedSovereignNation, setSelectedSovereignNation] = React.useState<IDropdownOption>();
    const [incidentTypeOptions, setIncidentTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [incidentTypeL2Options, setIncidentTypeL2Options] = React.useState<IDropdownOption[]>([]);
    const [selectedIncidentType, setSelectedincidentType] = React.useState<IDropdownOption>();
    const [selectedIncidentTypeL2, setSelectedincidentTypeL2] = React.useState<IDropdownOption>();
    const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
    const toggleIsCalloutVisible = () => setIsCalloutVisible(!isCalloutVisible);

    const [isIncidentTypeChangeDialogShown, setIsIncidentTypeChangeDialogShown] = React.useState(false);
    const hideIncidentTypeChangeDialog = () => { setIsIncidentTypeChangeDialogShown(false); document.getElementById("sovereign-incident-dropdown")?.focus(); };
    const [toBeSelectedIncidentType, setToBeSelectedIncidentType] = React.useState<IDropdownOption | undefined>();

    const [isSovereignChangeDialogShown, setIsSovereignChangeDialogShown] = React.useState(false);
    const hideSovereignChangeDialog = () => { setIsSovereignChangeDialogShown(false); document.getElementById("sovereign-dropdown")?.focus(); };
    const [toBeSelectedSovereign, setToBeSelectedSovereign] = React.useState<IDropdownOption | undefined>();


    const sovereignCallOurProps = {
        id: "sovereign-info",
        calloutDescription: getLocalizedValue("RIN_SelectGovernmentOrSovereignStateHelp"),
        moreInfoLink: undefined,
        labelStyle: { fontWeight: 400 }
    };

    const iconButtonId: string = sovereignCallOurProps.id + "-iconButton";
    const descriptionId: string = sovereignCallOurProps.id + "-description";

    const handleSovereignSelection = (item: IDropdownOption | undefined) => {
        if(!selectedSovereignNation && item){
            setSelectedSovereignNation(item);
            dispatch(setSelectedSovereign(sovereigns.find(x => x.nationId === item?.key)));
            document.getElementById("sovereign-dropdown")?.focus();
        }
        else if(item && item.key !== selectedSovereignNation?.key){
            setToBeSelectedSovereign(item);
            setIsSovereignChangeDialogShown(true);
        }
    }

    const handleSovereignChange = ({sovereign, incidentCategory}:{ sovereign: IDropdownOption, incidentCategory: IncidentCategories}) =>{
        if (sovereign) {
            setSelectedincidentType(undefined);
            dispatch(setSovereignSelectedIncidentTypeL1(undefined));
            dispatch(setSovereignSelectedIncidentTypeL2(undefined));
            dispatch(setSovereignIncidentTypes([]));
            
            if(incidentCategory === IncidentCategories.Digital){
                dispatch(setIsOutlookButtonAvailable(true));
                dispatch(resetDigitalIncidentInformation(false));
            }
            else if(incidentCategory === IncidentCategories.Physical){
                dispatch(resetPhysicalIncidentInformation(PsResetLevel.IncidentTypeL1Changed));
                dispatch(hideAdditionalInfo());
            }

            setSelectedSovereignNation(sovereign);
            dispatch(setSelectedSovereign(sovereigns.find(x => x.nationId === sovereign?.key)));
            dispatch(clearFiles());
            document.getElementById("sovereign-dropdown")?.focus();
        }
    }

    const handleIncidentSelection = (item: IDropdownOption | undefined) => {

        if(!selectedIncidentType && item){
            setSelectedincidentType(item);
            dispatch(setSovereignSelectedIncidentTypeL1(item));
            document.getElementById("sovereign-incident-dropdown")?.focus();
            if(triggerOnIncidentTypeSelection) 
                triggerOnIncidentTypeSelection(item);
        }
        else if(item && item.key !== selectedIncidentType?.key){
            setToBeSelectedIncidentType(item);
            setIsIncidentTypeChangeDialogShown(true);
        }
    }

    const handleIncidentTypeChange = ({incidentType, resetForm, incidentCategory}:{ incidentType?: IDropdownOption, resetForm: boolean, incidentCategory: IncidentCategories }) =>{
        if (incidentType) {
            if(incidentCategory === IncidentCategories.Digital){
                dispatch(setIsOutlookButtonAvailable(true));
                if (resetForm) {
                    dispatch(resetDigitalIncidentInformation(false));
                }
            }
            else if(incidentCategory === IncidentCategories.Physical){
                
            }
            
            dispatch(clearFiles());
            setSelectedincidentType(incidentType);
            dispatch(setSovereignSelectedIncidentTypeL1(incidentType));
            dispatch(setSovereignSelectedIncidentTypeL2(undefined));
            document.getElementById("sovereign-incident-dropdown")?.focus();
            if(triggerOnIncidentTypeSelection) 
                triggerOnIncidentTypeSelection(incidentType);
        }
    }

    React.useEffect(() => {
        if ((sovereignIncidentTypes as DigitalIncidentTypes).incidentTypesL2 && (sovereignIncidentTypes as DigitalIncidentTypes).incidentTypesL2.length > 0) {
            let l2IncidentOptions: IDropdownOption[] = [];
            setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: "<div>" }));
            (sovereignIncidentTypes as DigitalIncidentTypes).incidentTypesL2.forEach(
                (incidentType, index) => {
                    setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + "<div><b>" }));
                    l2IncidentOptions.push({
                        key: incidentType.typeId,
                        text: getLocalizedValue(incidentType.nameKey),
                    });
                    if (index !== (sovereignIncidentTypes as DigitalIncidentTypes).incidentTypesL2.length - 1) {
                        setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + getLocalizedValue(incidentType.nameKey) + ":" + "</b></br>" + getLocalizedValue(incidentType.descriptionKey) + "</div><br>" }));
                    }
                    else {
                        setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + getLocalizedValue(incidentType.nameKey) + ":" + "</b></br>" + getLocalizedValue(incidentType.descriptionKey) + "</div>" }));
                    }
                }
            );
            setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + "</div>" }));
            setIncidentTypeL2Options(l2IncidentOptions);

        }

    }, [sovereignIncidentTypes])

    React.useEffect(() => {
        dispatch(getSovereigns());
        dispatch(setSovereignIncidentCategory(IncidentCategory));
        return () => {
            dispatch(clearSovereignDetails());
        }
    }, []);

    React.useEffect(() => {
        if (sovereigns) {
            const tempSovereignList: IDropdownOption[] = [];
            sovereigns.forEach(sovereign => {
                tempSovereignList.push({ key: sovereign.nationId, text: getLocalizedValue(sovereign.nameKey) });
            });
            setSovereignList(tempSovereignList);
        }
        return () => {
            setSelectedSovereignNation(undefined);
            setSovereignList([]);
        }
    },[sovereigns]);

    React.useEffect(() => {
        if (sovereignIncidentTypes) {
            const tempIncidentTypeOptions: IDropdownOption[] = [];
            if (sovereign.IncidentDetails.IncidentCategory === IncidentCategories.Physical) {
                (sovereignIncidentTypes as PsPhysicalIncidentTypeResponseViewModel[]).forEach((incidentType: PsPhysicalIncidentTypeResponseViewModel) => {
                    tempIncidentTypeOptions.push({ key: incidentType.typeId, text: getLocalizedValue(incidentType.nameKey) });
                });
            }
            else if (sovereign.IncidentDetails.IncidentCategory === IncidentCategories.Digital) {
                (sovereignIncidentTypes as DigitalIncidentTypes).incidentTypesL1.forEach((incidentType: DigitalIncidentType) => {
                    tempIncidentTypeOptions.push({ key: incidentType.typeId, text: getLocalizedValue(incidentType.nameKey) });
                });
            }
            setIncidentTypeOptions(tempIncidentTypeOptions);
        }
    }, [sovereignIncidentTypes]);

    React.useEffect(() => {
        if (sovereignSelectedIncidentTypeL1?.key === SovereignDigitalEventTypes.UnauthorizedAccess) {
            dispatch(getSovereignDigitalL2IncidentTypes(sovereign.SovereignDetails.selectedSovereign));
        }

    }, [sovereignSelectedIncidentTypeL1])

    React.useEffect(() =>{
        if(sovereignSelectedIncidentTypeL1 != selectedIncidentType){
            setSelectedincidentType(sovereignSelectedIncidentTypeL1);
        }
    }, [sovereignSelectedIncidentTypeL1]);

    React.useEffect(() => {
        if (sovereignSelectedIncidentTypeL2 != selectedIncidentTypeL2) {
            setSelectedincidentTypeL2(sovereignSelectedIncidentTypeL2);
        }
    }, [sovereignSelectedIncidentTypeL2]);

    const [incidentSubcategoryCalloutProps, setIncidentSubcategoryCallourProps] = React.useState({
        id: "incident-subcategory-info",
        calloutDescription: "",
        moreInfoLink: undefined,
    });

    const handleL2IncidentSelection = (item: IDropdownOption | undefined) => {
        if (item) {
            if (selectedIncidentTypeL2?.key !== item.key) {
                dispatch(clearDigitalIncidentErrorStates());
                dispatch(removeMessage("validation-message"));
            }
            dispatch(setSovereignSelectedIncidentTypeL2(item));
        }
    };

    if(sovereignList.length === 0) return <></>;

    return (
        <div className="sovereign-selection-container margin-bottom-32">
            <div className="ms-Grid-row section-header">
                <div className="ms-Grid-row section-header sovereign-heading">
                    <h2 className="sectionHeading display-inline">{getLocalizedValue("RIN_SelectGovernmentOrSovereignState")}</h2>
                    <IconButton
                        id={iconButtonId}
                        iconProps={iconProps}
                        title={getLocalizedValue("RIN_SelectGovernmentOrSovereignState")+" help"}
                        ariaLabel={getLocalizedValue("RIN_SovereignStateCategoryInfo")}
                        onClick={toggleIsCalloutVisible}
                        className="iconButton"
                    />
                </div>
            </div>
            <Dropdown
                id="sovereign-dropdown"
                ariaLabel={getLocalizedValue("RIN_SelectASovereign")}
                selectedKey={selectedSovereignNation?.key}
                role="combobox"
                aria-haspopup={false}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e, item) => handleSovereignSelection(item)}
                placeholder={getLocalizedValue("RIN_SelectASovereign")}
                options={sovereignList}
            />
            {
                incidentTypeOptions.length > 0 && <>
                    <Dropdown
                        id="sovereign-incident-dropdown"
                        label={getLocalizedValue("RIN_SelectGovernmentSovereignIncidentType")}
                        ariaLabel={getLocalizedValue("RIN_SelectGovernmentSovereignIncidentType")}
                        selectedKey={selectedIncidentType?.key}
                        role="combobox"
                        aria-haspopup={false}
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange={(e, item) => handleIncidentSelection(item)}
                        placeholder={getLocalizedValue("RIN_SelectGovernmentSovereignIncidentType")}
                        options={incidentTypeOptions}
                        styles={{
                            root: {
                                marginTop: "16px",
                            },
                        }}
                    />
                    {sovereignSelectedIncidentTypeL1?.key !== SovereignDigitalEventTypes.UnauthorizedAccess && childRenderer && selectedIncidentType && childRenderer(selectedIncidentType?.key)}
                </>
            }
            {
                sovereignSelectedIncidentTypeL1?.key === SovereignDigitalEventTypes.UnauthorizedAccess &&
                <Dropdown
                    id="sovereign-l2-dropdown"
                    label={getLocalizedValue("RIN_SelectIncidentSubcategory")}
                    ariaLabel={getLocalizedValue("RIN_SelectIncidentSubcategory")}
                    selectedKey={selectedIncidentTypeL2?.key}
                    role="combobox"
                    aria-haspopup={false}
                    // eslint-disable-next-line react/jsx-no-bind
                    onRenderLabel={(props) => <CustomLabel {...incidentSubcategoryCalloutProps} {...props} />}
                    onChange={(e, item) => handleL2IncidentSelection(item)}
                    placeholder={getLocalizedValue("RIN_SelectDigitalSecurityEventType")}
                    options={incidentTypeL2Options}
                    styles={{
                        root: {
                            marginTop: "-14px",
                        },
                        //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                    }}
                />
            }
            {sovereignSelectedIncidentTypeL1?.key === SovereignDigitalEventTypes.UnauthorizedAccess && (sovereignSelectedIncidentTypeL2 !== undefined) && childRenderer && selectedIncidentType && childRenderer(selectedIncidentType?.key)}
            {
                isIncidentTypeChangeDialogShown &&
                <IncidentTypeChange isHidden={!isIncidentTypeChangeDialogShown} dismissDialog={hideIncidentTypeChangeDialog} executeOnContinueProps={{ incidentType: toBeSelectedIncidentType, resetForm: true, incidentCategory: IncidentCategory }} executeOnContinue={handleIncidentTypeChange} executeOnCancle={() =>{}} />
            }
            {
                isSovereignChangeDialogShown &&
                <SovereignChangeDialog isHidden={!isSovereignChangeDialogShown} dismissDialog={hideSovereignChangeDialog} executeOnContinueProps={{ sovereign: toBeSelectedSovereign, incidentCategory: IncidentCategory }} executeOnContinue={handleSovereignChange} executeOnCancle={() =>{}} />
            }
            {isCalloutVisible && (
                <FocusTrapCallout
                    directionalHint={DirectionalHint.topAutoEdge}
                    target={"#" + iconButtonId}
                    setInitialFocus
                    onDismiss={toggleIsCalloutVisible}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                    aria-live="assertive"
                >
                    <DelayedRender>
                        <FocusTrapZone isClickableOutsideFocusTrap>
                            <Stack
                                tokens={stackTokens}
                                horizontalAlign="start"
                                styles={labelCalloutStackStyles}
                            >
                                <span>
                                    <RichTextRenderer html={sovereignCallOurProps.calloutDescription} />
                                </span>
                            </Stack>
                        </FocusTrapZone>
                    </DelayedRender>
                </FocusTrapCallout>
            )}
        </div>
    );
}

