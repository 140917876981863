export const FetchDigitalIncidentTypes = "GET_FAIL-DS-INC_TYP-UI";
export const FetchTimeZones = "GET_FAIL-GLB-TIME_ZON-UI";
export const FetchSimulationCampaigns = "GET_FAIL-DS-SIM_CAM-UI";
export const FetchIncidentCategories = "GET_FAIL-GLB-INC_CAT-UI";
export const FetchHelpSectionLinks = "GET_FAIL-GLB-HLP_SEC_LNK-UI";
export const FetchHelpSections = "GET_FAIL-GLB-HLP_SEC-UI";
export const FetchPhysicalIncidentTypes = "GET_FAIL-PS-INC_TYP-UI";
export const FetchPhysicalIncidentLocations = "GET_FAIL-PS-LOC_DET-UI";
export const FetchPhysicalIncidentPersonInvolvementTypes = "GET_FAIL-PS-PER_INV_TYP-UI";
export const FetchPhysicalIncidentVehicleInvolvementTypes = "GET_FAIL-PS-VEH_INV_TYP-UI";
export const FetchPhysicalIncidentVehicleMakeTypes = "GET_FAIL-PS-VEH_MAK-UI";
export const FetchPhysicalIncidentVehicleModelTypes = "GET_FAIL-PS-VEH_MOD-UI";
export const FetchPhysicalIncidentVehicleStyleTypes = "GET_FAIL-PS-VEH_STYLE-UI";
export const FetchPhysicalIncidentVehicleColorTypes = "GET_FAIL-PS-VEH_COLOR-UI";
export const FetchPhysicalIncidentItemMakeTypes = "GET_FAIL-PS-ITEM_MAK-UI";
export const FetchPhysicalIncidentItemModelTypes = "GET_FAIL-PS-ITEM_MOD-UI";
export const FetchPhysicalIncidentItemCategoryTypes = "GET_FAIL-PS-ITEM_CAT-UI";
export const FetchPhysicalIncidentItemSubCategoryTypes = "GET_FAIL-PS-ITEM_SUB_CAT-UI";
export const SubmitDigitalIncident = "DS-INC_SUB-UI";
export const SubmitPhysicalIncident = "PS-INC_SUB-UI";
export const SubmitSimulationCampaign = "DS-SIM_SUB-UI";
export const FetchUserProfile = "SI-UP_FETCH-UI";
export const FetchUserFlightStatus = "SI-UFS_FETCH-UI";
export const FetchServiceCommunicationSettings = "SI-SCS_FETCH-UI";
export const UnauthorizedFetch = "SI-GET_UNAUTH-UI";
export const FetchApplicationSettings = "GET_FAIL-GLB-APP_SET-UI"; // newly added
export const FetchIncidentStatistics = "GET_FAIL-GLB-INC_STAT-UI"; // newly added
export const SubmitApplicationSettings = "GLB-APP_SET_SUB-UI"; // newly added
export const FetchSimulationStatistics = "GET_FAIL-GLB-SIM_STAT-UI"; // newly added
export const ClearSimulationArchiveDetails = "GLB-CLR_SIM_DET-UI"; // newly added
export const FetchGovNations = "GET_FAIL-GLB-GOV_NAT-UI"; // newly added
export const FetchGovIncident = "GET_FAIL-GLB-GOV_INC-UI"; // newly added
export const SubmitGovIncident = "GLB-GOV_INC_SUB-UI"; // newly added
export const FetchSiteNotifications = "GET_FAIL-GLB-SITE_NOT-UI"; // newly added
export const FetchFlowConfigurations = "GET_FAIL-GLB-FLOW-UI";//newly added
export const LoadLocalizationFile = "LOAD_FAIL-GLB-L10N-UI";//newly added