import * as React from "react";
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useDispatch, useSelector } from "react-redux";
import { removeMessage } from "../Notifications/NotificationsSlice";
import { clearFiles } from "../FileUpload/FileUploadSlice";
import { Dialog, DialogFooter, DialogType, IModalProps } from "@fluentui/react";
import { HelpPanelVisibilitySelector, setHelpPanelVisibility } from "../HelpPanel/HelpSectionSlice";
import { setDigitalIncidentTypesPanelVisibility } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { setPhysicalIncidentPanelVisibility } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { getLocalizedValue } from "../../Services/localizationService";

export interface IncidentTypeChangeProps {
    isHidden: boolean;
    dismissDialog: () => void;
    executeOnContinue: (props: any) => void;
    executeOnContinueProps: any;
    executeOnCancle: Function;
}
const dialogContentProps = {
    type: DialogType.close,
    title: getLocalizedValue('RIN_ChangingTheIncidentType'),
    titleProps: {
        style: {
            fontWeight: 700
        }
    },
    closeButtonAriaLabel: getLocalizedValue('RIN_CloseDialog'),
    subText: getLocalizedValue('RIN_ChangingTheIncidentTypeInfo')
};

const dialogStyles = {
    main: {
        selectors: {
            '@media (min-width: 0px)': {
                height: 192,
                maxHeight: 192,
                maxWidth: 600,
                width: 600
            }
        }
    }
};

export const IncidentTypeChange: React.FC<IncidentTypeChangeProps> = ({ isHidden, dismissDialog, executeOnContinue, executeOnContinueProps, executeOnCancle }) => {
    const dispatch = useDispatch();
    const isHelpPanelOpen = useSelector(HelpPanelVisibilitySelector);
    const modalProps: IModalProps =
    {
        isBlocking: false,
        styles: dialogStyles
    };

    const handleContinue = () => {
        if (isHelpPanelOpen) {
            dispatch(setHelpPanelVisibility(false));
            document.getElementById("helpButtonId")?.click();
            dispatch(setDigitalIncidentTypesPanelVisibility(false));
            dispatch(setPhysicalIncidentPanelVisibility(false));
        }
        dismissDialog();
        executeOnContinue(executeOnContinueProps);
        dispatch(removeMessage("validation-message"));
        dispatch(removeMessage("form-submission-exception"));
        dispatch(clearFiles());
    }

    const handleCancle = () => {
        dismissDialog();
        executeOnCancle();
    }

    return (
        <Dialog
            hidden={isHidden}
            onDismiss={dismissDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter styles={{
                actions: {
                    marginTop: "30px"
                }
            }}>
                <PrimaryButton onClick={handleContinue} text= {getLocalizedValue("RIN_Continue")} />
                <DefaultButton onClick={handleCancle} text={getLocalizedValue("RIN_Cancel")} />
            </DialogFooter>
        </Dialog>
    );
}