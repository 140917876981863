import { DefaultPalette, IButtonStyles, IChoiceGroupStyles, IStackItemStyles, IStackStyles,  IStyle } from "@fluentui/react";
import * as React from "react";

export const stackStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themeTertiary,
    },
};
export const stackItemStyles: IStackItemStyles = {
    root: {
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        padding: 5,
    },
};

export const IconButtonStyles: Partial<IButtonStyles> = { root: { color: 'white', background: 'transparent' }, rootHovered: { color: 'white', backgroundColor: 'rgba(242, 242, 242, .2)' } };

export const primaryButtonHoveredStyle: IStyle = {
    backgroundColor: "rgba(255, 255, 255, .1)",
    color: "white",
    border: "1px solid white"
};

export const primaryButtonFocusedStyle: IStyle = {
    backgroundColor: "rgba(255, 255, 255, .1)",
    color: "white",
};

export const primaryButtonStyles: IButtonStyles = {
    root: {
        background: "transparent",
        color: "white",
        border: "1px solid white"
    },
    rootHovered: primaryButtonHoveredStyle,
    rootFocused: primaryButtonFocusedStyle,
};

export const physicalIncidentCardStyle: React.CSSProperties = {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    padding: '8px 0px 8px 0',
    fontSize: 14,
    display: "flex",
}

export const getRadioButtonStyle = (width?: number, maxWidth?: number): IChoiceGroupStyles => {
    var radioButtonStyle: IChoiceGroupStyles = {
        flexContainer: {
            display: "flex",
            width: width,
            maxWidth: maxWidth,
            justifyContent: "space-between",
            selectors: {
                "@media (max-width: 479px)": {
                    flexDirection: "column"
                }
            }
        }
    }
    return radioButtonStyle;
}

export const whiteLinkStyle: React.CSSProperties = {
    color: "white",
    fontWeight: "bold"
}

export const buttonStyle: IStyle = {
    color: "var(--background-color)",
    borderRadius: "2px",
    border: "1px solid white"
}
