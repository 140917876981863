/* eslint-disable react/jsx-no-undef */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepperSteps } from "../../../Constants/CommonConstants";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { IncidentCategorySelector } from "../../IncidentCategory/IncidentCategorySlice";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import { PhysicalIncidentDetailsv2 } from "../PhysicalSecurity/PhysicalIncidentAdditionalInformation/PhysicalIncidentDetailsv2";
import { DigitalIncidentDetailsv2 } from "../DigitalSecurity/IncidentInformationv2/DigitalIncidentDetailsv2";

export function IncidentDetails() {
	const dispatch = useDispatch();
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const selectedIncidentCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;

	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Details));
	}, []);


	return (
		<div className="incidentDetails site-margins" style={{paddingTop: 28}}>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					{selectedIncidentCategoryId == IncidentCategories.Digital && <DigitalIncidentDetailsv2 />}
					{selectedIncidentCategoryId == IncidentCategories.Physical && <PhysicalIncidentDetailsv2 />}
				</div>
			</div>
		</div>
	);
}
