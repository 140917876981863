import {
    ChoiceGroup,
    IChoiceGroupOption,
    Label,
    TextField,
} from "@fluentui/react";
import * as React from "react";
import { renderDescriptionLabel } from "../../Home/Home";
import { useDispatch, useSelector } from "react-redux";
import {
    ErrorStatesSelector,
    PhishingCallOrTextSelector,
    setDigitalIncidentPhishingCallOrTextObject,
    setPhishingCallOrTextActionsErrorMessage,
    setPhishingCallOrTextCallFrequency,
    setPhishingCallOrTextFrequencyErrorMessage,
    setPhishingCallOrTextInfoSharedByUser,
    setPhishingCallOrTextOffenderContactDetails,
    setPhishingCallOrTextOffenderDetailsErrorMessage,
    setPhishingCallOrTextReceivedOn,
    setPhishingCallOrTextIsSensitiveInfoSharedByUser,
    SelectedDigitalIncidentTypeL1Selector,
    LocalizedPhishingCallOrTextSelector,
    setDigitalIncidentLocalizedPhishingCallOrTextObject,
} from "../DigitalIncident/DigitalIncidentSlice";
import { DsDiPhishingCallorTextInputViewModel, DsDiPhishingCallorTextLocalizedInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiPhishingCallorTextInputViewModel";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { getRadioButtonStyle } from "../../../styles";
import { getLocalizedValue } from "../../../Services/localizationService";
import { DigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { ApplicationSettingsSelector } from "../../ConfigurationsPanel/ApplicationSettingsSlice";

export default function PhishingCallOrTextFields() {
    const labelId: string = "shared-info-details";
    const dispatch = useDispatch();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const phishingCallOrTextSelector = useSelector(PhishingCallOrTextSelector);
    const selectedEventTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector)
    const selectedEventTypeId = selectedEventTypeL1?.typeId;
    const applicationSettingsSelector = useSelector(ApplicationSettingsSelector);
    const flowConfigurations = applicationSettingsSelector?.flowConfigurations;
    const [phishingCallOrTextDetails, setPhishingCallOrTextDetails] =
        React.useState<DsDiPhishingCallorTextInputViewModel>({
            callFrequencyPerDay: undefined,
            callReceivedOn: "Teams Call",
            offenderContactDetails: "",
            isSensitiveInformationShared: false,
            infoSharedByUser: "",
        });

    const defaultLocalizedPhishingCallOrTextDetails: DsDiPhishingCallorTextLocalizedInputViewModel = {
        callFrequencyPerDay: undefined,
        callReceivedOn: getLocalizedValue("RIN_TeamsCall"),
        offenderContactDetails: "",
        isSensitiveInformationShared: getLocalizedValue("RIN_No"),
        infoSharedByUser: "",
    }
    const localizedPhishingCallOrTextDetails: DsDiPhishingCallorTextLocalizedInputViewModel | undefined = useSelector(LocalizedPhishingCallOrTextSelector);

    React.useEffect(() => {
        dispatch(
            setDigitalIncidentPhishingCallOrTextObject(
                phishingCallOrTextDetails
            )
        );
        dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({ ...localizedPhishingCallOrTextDetails, ...defaultLocalizedPhishingCallOrTextDetails }));


    }, []);

    const callOrSMSReceivedOnOptionsNew: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_TeamsCall"), value: "Teams Call" },
        { key: "2", text: getLocalizedValue("RIN_TeamsMessage"), value: "Teams Message" },
        { key: "3", text: getLocalizedValue("RIN_PersonalCall"), value: "Personal Call" },
        { key: "4", text: getLocalizedValue("RIN_PersonalSMSText"), value: "Personal SMS/Text" }
    ];

    const callOrSMSReceivedOnOptionsOld: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Teams"), value: "Teams" },
        { key: "2", text: getLocalizedValue("RIN_PersonalPhone"), value: "Personal phone" },
    ]

    let callOrSMSReceivedOnOptions: IChoiceGroupOption[] = callOrSMSReceivedOnOptionsOld;
    let callOrSMSOptionsWidth: number = 222;

    const setCallOrSMSOptions = () => {
        callOrSMSReceivedOnOptions = callOrSMSReceivedOnOptionsNew;
        callOrSMSOptionsWidth = 400;
    }

    if (selectedEventTypeId && selectedEventTypeId == DigitalEventTypes.GovRelated) {
        if (flowConfigurations.isShowPhishingCallOptionsToFed) {
            setCallOrSMSOptions();
        }
    }
    else {
        if (flowConfigurations.isShowPhishingCallOptionsToCorp) {
            setCallOrSMSOptions();
        }
    }

    const isSensitiveinfoSharedOptions: IChoiceGroupOption[] = [
        { key: "1", text: getLocalizedValue("RIN_Yes"), value: "Yes" },
        { key: "2", text: getLocalizedValue("RIN_No"), value: "No" },
    ];

    const [callOrSmsOption, setCallOrSmsOption] =
        React.useState<IChoiceGroupOption>();
    const [isSensitiveInfoSharedOption, setIsSensitiveInfoSharedOption] =
        React.useState<IChoiceGroupOption>(isSensitiveinfoSharedOptions[1]);

    const actionsDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextActions}
            descriptionColor={"white"}
            iconName={"ErrorBadge"}
        />
    );
    const offenderDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextOffenderDetails}
            descriptionColor={"white"}
            iconName={"ErrorBadge"}
        />
    );
    const frequencyDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextFrequency}
            descriptionColor={"white"}
            iconName={"ErrorBadge"}
        />
    );

    const handleFrequencyChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(setPhishingCallOrTextFrequencyErrorMessage(""));
            let frequency = Number(newValue);
            if (frequency < 0 || frequency > 100) {
                dispatch(setPhishingCallOrTextCallFrequency(0));
                dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callFrequencyPerDay:0} as DsDiPhishingCallorTextLocalizedInputViewModel));
            } else {
                dispatch(
                    setPhishingCallOrTextCallFrequency(Number(newValue.trim()))
                );
                dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callFrequencyPerDay:Number(newValue.trim())} as DsDiPhishingCallorTextLocalizedInputViewModel));
            }
        }
    };

    const handleOffenderDetailsChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingCallOrTextOffenderDetailsErrorMessage(""));
            dispatch(setPhishingCallOrTextOffenderContactDetails(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,offenderContactDetails:newValue} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleInfoChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingCallOrTextActionsErrorMessage(""));
            dispatch(setPhishingCallOrTextInfoSharedByUser(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,infoSharedByUser:newValue} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleCallOrSmsChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setCallOrSmsOption(option);
            dispatch(setPhishingCallOrTextReceivedOn((typeof (option.value) === "string" ? option.value ?? "" : "")));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callReceivedOn:option.text} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleIsSensitiveInfoSharedChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setIsSensitiveInfoSharedOption(option);
            dispatch(
                setPhishingCallOrTextIsSensitiveInfoSharedByUser(
                    option.value == "Yes" ? true : false
                )
            );
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,isSensitiveInformationShared:option.text} as DsDiPhishingCallorTextLocalizedInputViewModel));

            // When IsSensitiveInfoSharedByuser changes, we need to reset the Your actions field
            dispatch(setPhishingCallOrTextActionsErrorMessage(""));
            dispatch(setPhishingCallOrTextInfoSharedByUser(""));
        }
    };


    return (
        <div className="phishing-call-or-text-fields">
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        label={getLocalizedValue("RIN_CallerDetails")}
                        id="phishingCallerDetailsId"
                        value={
                            phishingCallOrTextSelector?.offenderContactDetails
                        }
                        onChange={handleOffenderDetailsChange}
                        aria-required={true}
                        onRenderDescription={() => offenderDescription}
                        min={0}
                        defaultValue="0"
                        styles={
                            errorStatesDetails.phishingCallOrTextOffenderDetails
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={callOrSMSReceivedOnOptions}
                        label={getLocalizedValue("RIN_CallSMSReceivedon")}
                        selectedKey={callOrSmsOption?.key}
                        onChange={handleCallOrSmsChange}
                        defaultSelectedKey="1"
                        styles={getRadioButtonStyle(callOrSMSOptionsWidth)}
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="phishingCallerFrequencyId"
                        aria-required={true}
                        label={getLocalizedValue("RIN_PhishingCallQuestion1")}
                        value={phishingCallOrTextSelector?.callFrequencyPerDay?.toString()}
                        onChange={handleFrequencyChange}
                        onRenderDescription={() => frequencyDescription}
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(props, "call-sms-details")
                        }
                        min={1}
                        max={100}
                        type="number"
                        onKeyDown={(
                            event: React.KeyboardEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) => {
                            if (event.key === ".") {
                                event.preventDefault();
                            }
                        }}
                        defaultValue="0"
                        aria-labelledby="call-sms-details"
                        styles={
                            errorStatesDetails.phishingCallOrTextFrequency
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            </div>

            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={isSensitiveinfoSharedOptions}
                        label={getLocalizedValue("RIN_PhishingCallQuestion2")}
                        selectedKey={isSensitiveInfoSharedOption?.key}
                        onChange={handleIsSensitiveInfoSharedChange}
                        defaultSelectedKey="2"
                        styles={getRadioButtonStyle(145)}
                    />
                </div>
            </div>

            {isSensitiveInfoSharedOption?.value == "Yes" && (
                <div id="your-actions" style={{ marginBottom: "28px" }}>
                    <div className="ms-Grid-row">
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                            style={{ marginBottom: "2px" }}
                        >
                            <Label>{getLocalizedValue("RIN_YourActions")}</Label>
                        </div>
                    </div>
                    <TextField
                        id="phishingCallerAdditionalDetailsId"
                        aria-required={false}
                        aria-labelledby={labelId}
                        label={getLocalizedValue("RIN_PhishingCallQuestion3")}
                        placeholder={getLocalizedValue("RIN_PhishingEmailQuestion2PlaceHolder")}
                        multiline
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(props, labelId)
                        }
                        value={phishingCallOrTextSelector?.infoSharedByUser}
                        onChange={handleInfoChange}
                        onRenderDescription={() => actionsDescription}
                        styles={
                            errorStatesDetails.phishingCallOrTextActions
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            )}
        </div>
    );
}
