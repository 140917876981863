import { AxiosError } from "axios";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  getSimulationCampaigns,
  setApiStatus,
  setIsUploadedSimulationCampaignSubmitted,
  setIsUploadedSimulationCampaignValid,
  setSimulationCampaigns,
  setSimulationEndDateTime,
  setSimulationStartDateTime,
  SimulationCampaignSelector,
  submitUploadedSimulationCampaign,
  validateSimulationCampaign,
} from "../Components/DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { setIsLoading, setSimulationCampaignsLoading } from "../Components/Loader/LoaderSlice";
import { serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE } from "../Constants/CommonConstants";
import {
  FetchSimulationCampaigns,
  SubmitSimulationCampaign,
} from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { DsDiSimulationCampaignResponseViewModel } from "../Contracts/TypesAndInterfaces/DsDiSimulationCampaignResponseViewModel";
import { DsSimulationCampaignInputViewModel } from "../Contracts/TypesAndInterfaces/DsSimulationCampaignInputViewModel";
import { SimulationCampaignModel } from "../Contracts/TypesAndInterfaces/SimulationCampaignModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError } from "../Services/errorHandlerService";
import { GET, POST, PUT } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";
import { getImageUrl } from "./userProfileSaga";

export function* FetchSimulationCampaignsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    yield put(setSimulationCampaignsLoading(true));
    let accessToken: string = yield fetchAPIAccessToken();
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/digitalincident/getsimulationcampaigns",
      accessToken
    );
    yield put(
      setApiStatus({
        statusCode: apiResponse?.responseCode,
        statusReceived: true,
      })
    );
    if (apiResponse && apiResponse.responseSuccess) {
      var simulationCampaign =
        apiResponse.responseObject as DsDiSimulationCampaignResponseViewModel;
      
      if(simulationCampaign)
      {
        simulationCampaign.attachmentUrl = getImageUrl(
              simulationCampaign.attachmentContent
            );
            simulationCampaign.startDate = new Date(simulationCampaign.startDate);
            simulationCampaign.endDate = new Date(simulationCampaign.endDate);
      }

      yield put(setSimulationCampaigns(simulationCampaign));
    }
    if (!apiResponse?.responseSuccess) {
      yield put(setApiStatus({ statusCode: undefined, statusReceived: true }));
    }
    yield put(setSimulationCampaignsLoading(false));
  } catch (e) {
    yield put(setSimulationCampaignsLoading(false));
    yield put(setApiStatus({ statusCode: undefined, statusReceived: true }));
    let exceptionModel = GetExceptionModel(
      FetchSimulationCampaigns,
      SOURCE,
      "GetSimulationCampaigns",
      "Fetch simulation campaigns",
      "FetchSimulationCampaignsAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* SubmitSimulationCampaignAsync() {
  yield put(setApiStatus({ statusCode: 200, statusReceived: true }));
  yield put(validateSimulationCampaign(1));
  var serviceCommunicationSettings: ServiceCommunicationSettings = yield select(
    serviceCommunicationSettingsSelector
  );
  var simulationCampaignDetails: SimulationCampaignModel = yield select(
    SimulationCampaignSelector
  );
  let accessToken: string = yield fetchAPIAccessToken();
  try {
    if (simulationCampaignDetails.isUploadedSimulationCampaignValid) {
      if (simulationCampaignDetails.uploadedSimulationCampaign != null) {
        yield put(
          setIsLoading({ loadingState: true, showSubmissionText: true })
        );
        var apiResponse: WebResponseModel | undefined =
          yield PUT<DsSimulationCampaignInputViewModel>(
            serviceCommunicationSettings.apiParameters.apiBaseAddress,
            //"/api/digitalincident/addsimulationcampaign",
            "/api/digitalincident/updatesimulationcampaign",
            simulationCampaignDetails.uploadedSimulationCampaign,
            accessToken
          );
        if (apiResponse && apiResponse.responseSuccess) {
          yield put(
            setIsLoading({ loadingState: false, showSubmissionText: false })
          );
          yield put(
            setApiStatus({
              statusCode: apiResponse.responseCode,
              statusReceived: true,
            })
          );
          yield put(setIsUploadedSimulationCampaignSubmitted(true));
          yield put(setIsUploadedSimulationCampaignValid(false));
        } else if (apiResponse && !apiResponse.responseSuccess) {
          yield put(
            setIsLoading({ loadingState: false, showSubmissionText: false })
          );
          yield put(
            setApiStatus({
              statusCode: apiResponse.responseCode,
              statusReceived: true,
            })
          );
        } else if (apiResponse === undefined) {
          yield put(
            setIsLoading({ loadingState: false, showSubmissionText: false })
          );
          yield put(
            setApiStatus({ statusCode: undefined, statusReceived: true })
          );
        }
      }
    }
  } catch (e) {
    yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
    yield put(setApiStatus({ statusCode: undefined, statusReceived: true }));
    let exceptionModel = GetExceptionModel(
      SubmitSimulationCampaign,
      SOURCE,
      "DigitalSimulationCreation",
      "Submit simulation campaign",
      "SubmitSimulationCampaignAsync",
      true,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}

export function* watchGetSimulationCampaigns() {
  yield takeEvery(getSimulationCampaigns, FetchSimulationCampaignsAsync);
}

export function* watchSubmitUploadedSimulationCampaign() {
  yield takeEvery(
    submitUploadedSimulationCampaign,
    SubmitSimulationCampaignAsync
  );
}
