import {
    Callout,
    Checkbox,
    DelayedRender,
    DirectionalHint,
    Dropdown,
    FocusTrapCallout,
    FocusTrapZone,
    IconButton,
    IDropdownOption,
    Stack,
    TextField,
} from "@fluentui/react";
import * as React from "react";
import { useBoolean } from "../../../Hooks/useBoolean";
import { DigitalIncidentTypesPanel } from "../DigitalIncidentTypesPanel/DigitalIncidentTypesPanel";
import { DigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { useDispatch, useSelector } from "react-redux";
import styles from './IncidentType.module.css'
import {
    clearDigitalIncidentErrorStates,
    DigitalIncidentInformationSelector,
    DigitalIncidentTimeChangedSelector,
    DigitalIncidentTypesSelector,
    DigitalIncidentTypesVisibilitySelector,
    ErrorStatesSelector,
    resetDigitalIncidentInformation,
    SelectedDigitalIncidentTypeL1Selector,
    SelectedDigitalIncidentTypeL2Selector,
    setDigitalIncidentExecutiveAlias,
    setDigitalIncidentIncidentTypeId,
    setDigitalIncidentIsExecSupport,
    setDigitalIncidentTypesPanelVisibility,
    setIsOutlookButtonAvailable,
    setLocalizedSelectedDigitalIncidentTypeNameL1,
    setLocalizedSelectedDigitalIncidentTypeNameL2,
    setSelectedDigitalIncidentTypeL1,
    setSelectedDigitalIncidentTypeL2,
    setValidationErrorForExecutiveAlias,
} from "../DigitalIncident/DigitalIncidentSlice";
import { clearFiles, fileSelector } from "../../FileUpload/FileUploadSlice";
import { IncidentTypeChange } from "../../LeaveIncident/IncidentTypeChange";
import { onRenderLabel } from "../../Home/Home";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { labelCalloutStackStyles, stackTokens } from "../../../Constants/CommonConstants";
import { removeMessage } from "../../Notifications/NotificationsSlice";
import { CustomLabel } from "../IncidentSpecificFields/IncidentSpecificFields";
import { HelpPanelVisibilitySelector, setHelpPanelVisibility } from "../../HelpPanel/HelpSectionSlice";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { ExecutiveAliasErrorModel } from "../../../Contracts/ExceptionModels/ExceptionModel";
import { getLocalizedValue } from "../../../Services/localizationService";

const iconProps = { iconName: "Unknown" };

export default function IncidentTypeSection(props: any) {
    const { isPhishingSimulation, showPhishingSimulationExperience, hidePhishingSimulationExperience } = props;
    const fileDetails = useSelector(fileSelector);
    const isHelpPanelOpen = useSelector(HelpPanelVisibilitySelector);
    const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
    const digitalIncidentDetails = useSelector(DigitalIncidentInformationSelector);
    const isPanelOpen = useSelector(DigitalIncidentTypesVisibilitySelector);
    const selectedIncidentTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector);
    const [incidentTypeOptions, setIncidentTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
    const isTimeChanged = useSelector(DigitalIncidentTimeChangedSelector);
    const toggleIsCalloutVisible = () => setIsCalloutVisible(!isCalloutVisible);
    const [isIncidentTypeChangeDialogShown, setIsIncidentTypeChangeDialogShown] = React.useState(false);
    const hideIncidentTypeChangeDialog = () => { setIsIncidentTypeChangeDialogShown(false); document.getElementById("digitalSecurityDropdownId")?.focus(); };
    const [toBeSelectedIncidentTypeId, setToBeSelectedIncidentTypeId] = React.useState<number | undefined>();
    const selectedIncidentTypeL2 = useSelector(SelectedDigitalIncidentTypeL2Selector);
    const [incidentTypesL2, setIncidentTypesL2] = React.useState<IDropdownOption[]>([]);
    const digitalIncidentTypeL2Details = useSelector(DigitalIncidentTypesSelector).incidentTypesL2;
    const [incidentSubcategoryCalloutProps, setIncidentSubcategoryCallourProps] = React.useState({
        id: "incident-subcategory-info",
        calloutDescription: "",
        moreInfoLink: undefined,
    });
    const dispatch = useDispatch();

    const errorStatesDetails = useSelector(ErrorStatesSelector);

    const selectedEventTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector)
    const selectedEventTypeId = selectedEventTypeL1?.typeId;
  
    React.useEffect(() => {
        if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
            var incidentTypes: IDropdownOption[] = [];
            if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
                digitalIncidentTypesDetails.incidentTypesL1.forEach(
                    (incidentType) => {
                        incidentTypes.push({
                            key: incidentType.typeId,
                            text: getLocalizedValue(incidentType.nameKey),
                        });
                    }
                );
                setIncidentTypeOptions(incidentTypes);
            }
        }
    }, [digitalIncidentTypesDetails]);

    const isFormFilledForCurrentIncidentType = (): boolean => {
        if (digitalIncidentDetails.digitalSecurityIncidentPhishingEmail) {
            if (digitalIncidentDetails.digitalSecurityIncidentPhishingEmail?.emailDeliveredLocation?.length > 0 ||
                (digitalIncidentDetails.digitalSecurityIncidentPhishingEmail?.infoSharedByUser && digitalIncidentDetails.digitalSecurityIncidentPhishingEmail?.infoSharedByUser?.length > 0) ||
                digitalIncidentDetails.digitalSecurityIncidentPhishingEmail?.isAttachmentorURLOpenedByUser === true) {
                return true;
            }
        }
        else if (digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText) {
            if ((digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.callFrequencyPerDay && digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.callFrequencyPerDay > 0) ||
                digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.callReceivedOn !== "Teams" ||
                (digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.infoSharedByUser && digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.infoSharedByUser?.length > 0) ||
                digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.isSensitiveInformationShared === true ||
                digitalIncidentDetails.digitalSecurityIncidentPhishingCallorText?.offenderContactDetails?.length > 0) {
                return true;
            }
        }
        else if (digitalIncidentDetails.digitalSecurityIncidentMalware) {
            if (digitalIncidentDetails.digitalSecurityIncidentMalware?.affectedMachineName?.length > 0 ||
                digitalIncidentDetails.digitalSecurityIncidentMalware?.fileName?.length > 0 ||
                digitalIncidentDetails.digitalSecurityIncidentMalware?.threatName?.length > 0) {
                return true;
            }
        }
        else if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack) {
            if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin) {
                if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin?.hostName?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.suspiciousLogin?.relatedToWorkOrSchoolAccount !== undefined) {
                    return true;
                }
            }
            else if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.technicalSupportScam) {
                if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.technicalSupportScam?.affectedMachineName?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.technicalSupportScam?.scamUrl?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.technicalSupportScam?.wasRemoteAccessAllowed === true) {
                    return true;
                }
            }
            else if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA) {
                if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.hostName?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.mfaprompt?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.uninitiatedMFA?.relatedToWorkOrSchoolAccount !== undefined) {
                    return true;
                }
            }
            else if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled) {
                if (digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.enrolledDeviceName?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.hostName?.length > 0 ||
                    digitalIncidentDetails.digitalSecurityUnauthorizedAccessOrHack?.unknownDeviceEnrolled?.isNewDeviceEnrolled === true) {
                    return true;
                }
            }
        }
        else if (digitalIncidentDetails.digitalSecuritySuspiciousMaliciousWebsite) {
            if (digitalIncidentDetails.digitalSecuritySuspiciousMaliciousWebsite?.affectedMachineName?.length > 0 ||
                digitalIncidentDetails.digitalSecuritySuspiciousMaliciousWebsite?.uRL?.length > 0) {
                return true;
            }
        }
        else if (digitalIncidentDetails.digitalSecurityIncidentExposureOfSensitiveInformation) {
            if (digitalIncidentDetails.digitalSecurityIncidentExposureOfSensitiveInformation?.customerOrPartnerDetails && digitalIncidentDetails.digitalSecurityIncidentExposureOfSensitiveInformation?.customerOrPartnerDetails?.length > 0) {
                return true;
            }
        }
        else if (digitalIncidentDetails.digitalSecurityIncidentExposureOfPersonalData) {
            if (digitalIncidentDetails.digitalSecurityIncidentExposureOfPersonalData?.customerOrPartnerDetails && digitalIncidentDetails.digitalSecurityIncidentExposureOfPersonalData?.customerOrPartnerDetails?.length > 0) {
                return true;
            }
        }
        if (fileDetails.files.length > 0 ||
            (digitalIncidentDetails.azureSubscriptionId && digitalIncidentDetails.azureSubscriptionId.length > 0) ||
            digitalIncidentDetails.incidentDescription?.length > 0 ||
            digitalIncidentDetails.isExecSupport === true ||
            isTimeChanged) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        if (digitalIncidentTypeL2Details.length > 0) {
            var incidentTypesL2: IDropdownOption[] = [];
            setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: "<div>" }));
            digitalIncidentTypeL2Details.forEach(
                (incidentType, index) => {
                    setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + "<div><b>" }));
                    incidentTypesL2.push({
                        key: incidentType.typeId,
                        text: getLocalizedValue(incidentType.nameKey),
                    });
                    if (index !== digitalIncidentTypeL2Details.length - 1) {
                        setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + getLocalizedValue(incidentType.nameKey) + ":" + "</b></br>" + getLocalizedValue(incidentType.descriptionKey) + "</div><br>" }));
                    }
                    else {
                        setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + getLocalizedValue(incidentType.nameKey) + ":" + "</b></br>" + getLocalizedValue(incidentType.descriptionKey) + "</div>" }));
                    }
                }
            );
            setIncidentSubcategoryCallourProps(prevState => ({ ...prevState, calloutDescription: prevState.calloutDescription + "</div>" }));
            setIncidentTypesL2(incidentTypesL2);

        }

    }, [digitalIncidentTypeL2Details])

    const handleIncidentSelection = (item: IDropdownOption | undefined) => {
        if (!selectedIncidentTypeL1 && item) {
            handleIncidentTypeChange({ incidentTypeId: Number(item.key), resetForm: false });
            document.getElementById("digitalSecurityDropdownId")?.focus();
        }
        else if (item && item.key != digitalIncidentDetails.incidentTypeId) {
            if (isFormFilledForCurrentIncidentType()) {
                setToBeSelectedIncidentTypeId(Number(item.key));
                setIsIncidentTypeChangeDialogShown(true);
            }
            else {
                handleIncidentTypeChange({ incidentTypeId: Number(item.key), resetForm: false });
                if (selectedIncidentTypeL2 !== undefined) {
                    dispatch(setSelectedDigitalIncidentTypeL2(undefined));
                    dispatch(setLocalizedSelectedDigitalIncidentTypeNameL2(undefined));
                }
                document.getElementById("digitalSecurityDropdownId")?.focus();
            }
        }
    };

    const handleL2IncidentSelection = (item: IDropdownOption | undefined) => {
        if (item) {
            if (selectedIncidentTypeL2 !== undefined && selectedIncidentTypeL2.typeId !== item.key) {
                dispatch(clearDigitalIncidentErrorStates());
                dispatch(removeMessage("validation-message"));
            }
            dispatch(setSelectedDigitalIncidentTypeL2(digitalIncidentTypeL2Details.find((s) => s.typeId === item.key)));
            dispatch(setLocalizedSelectedDigitalIncidentTypeNameL2(item.text));
        }
    };

    const handleIncidentSelectionFromPanel = (incidentTypeId: number | undefined) => {
        if (!selectedIncidentTypeL1 && incidentTypeId) {
            if (isHelpPanelOpen) {
                dispatch(setHelpPanelVisibility(false));
                document.getElementById("helpButtonId")?.click();
            }
            handleIncidentTypeChange({ incidentTypeId: Number(incidentTypeId), resetForm: false });
        }
        else if (incidentTypeId && incidentTypeId != digitalIncidentDetails.incidentTypeId) {
            if (isFormFilledForCurrentIncidentType()) {
                setToBeSelectedIncidentTypeId(incidentTypeId);
                setIsIncidentTypeChangeDialogShown(true);
            }
            else {
                dispatch(setDigitalIncidentTypesPanelVisibility(false));
                handleIncidentTypeChange({ incidentTypeId: incidentTypeId, resetForm: false });
                if (selectedIncidentTypeL2 !== undefined) {
                    dispatch(setSelectedDigitalIncidentTypeL2(undefined));
                    dispatch(setLocalizedSelectedDigitalIncidentTypeNameL2(undefined));
                }
                if (isHelpPanelOpen) {
                    dispatch(setHelpPanelVisibility(false));
                    document.getElementById("helpButtonId")?.click();
                }
            }
        }
        else if (incidentTypeId && incidentTypeId === digitalIncidentDetails.incidentTypeId) {
            if (isHelpPanelOpen) {
                dispatch(setHelpPanelVisibility(false));
                document.getElementById("helpButtonId")?.click();
            }
        }
    }

    const handleIncidentTypeChange = (props: any) => {
        const { incidentTypeId, resetForm }: { incidentTypeId: number | undefined, resetForm: boolean } = props;
        if (incidentTypeId) {
            dispatch(setSelectedDigitalIncidentTypeL1(digitalIncidentTypesDetails.incidentTypesL1.find((s) => s.typeId == incidentTypeId)));
            dispatch(setLocalizedSelectedDigitalIncidentTypeNameL1(getLocalizedValue(digitalIncidentTypesDetails.incidentTypesL1.find((s) => s.typeId == incidentTypeId)?.nameKey)));
            dispatch(setIsOutlookButtonAvailable(true));
            dispatch(setDigitalIncidentIncidentTypeId(incidentTypeId));
            if (resetForm) {
                dispatch(resetDigitalIncidentInformation(false));
            }
            if (isPhishingSimulation && incidentTypeId === DigitalEventTypes.PhishingEMail) {
                showPhishingSimulationExperience();
            }
            else {
                hidePhishingSimulationExperience();
            }
            dispatch(clearFiles());
        }
    }

    const rerenderDropdownOnNoIncidentChange = () => {

    }
    const requiresExecSupportCalloutProps = {
        id: "requires-exec-support-info",
        calloutDescription: getLocalizedValue("RIN_ExecutiveSupportHelpDesc"),
        moreInfoLink: undefined,
        labelStyle: { fontWeight: 400 }
    };

    const iconButtonId: string = requiresExecSupportCalloutProps.id + "-iconButton";
    const labelId: string = "require-exec-sup";
    const descriptionId: string = requiresExecSupportCalloutProps.id + "-description";

    const executiveAliasDescription = (
        <FieldValidationDescription
            errorMessage={
                errorStatesDetails.executiveAlias
            }
            descriptionColor={'white'}
            iconName={"ErrorBadge"}
        />
    );

    const handleExecutiveAliasChange = (newValue:string)=>{
        let executiveAliasError : ExecutiveAliasErrorModel  = {ErrorMessage:"", IsErrorOnSubmission:false};
        dispatch(setValidationErrorForExecutiveAlias(executiveAliasError))
        dispatch(setDigitalIncidentExecutiveAlias(newValue));
    }


    return (
        <div className="incident-container margin-bottom-32">
            <div className="ms-Grid-row section-header">
                <div className={styles.heading}>
                    <h2 className={styles.headingh2}>{getLocalizedValue("RIN_SelectTheDigitalincidentType")}</h2>
                    <IconButton
                        iconProps={iconProps}
                        title={getLocalizedValue("RIN_DigitalIncidentTypesInfo")}
                        ariaLabel={getLocalizedValue("RIN_DigitalIncidentTypesInfo")}
                        onClick={() => dispatch(setDigitalIncidentTypesPanelVisibility(true))}
                        className="iconButton"
                    />
                </div>
            </div>
            <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox
                        aria-labelledby={labelId}
                        label={getLocalizedValue("RIN_ExecutiveSupport")}
                        checked={
                            digitalIncidentDetails
                                .isExecSupport
                        }
                        onChange={() => {
                            dispatch(
                                setDigitalIncidentIsExecSupport(
                                    !digitalIncidentDetails
                                        .isExecSupport
                                )
                            );
                            if (!digitalIncidentDetails
                                .isExecSupport)
                                dispatch(setDigitalIncidentExecutiveAlias(null));
                        }
                        }
                        onRenderLabel={(props) => onRenderLabel(props, labelId, 400)}
                        styles={{
                            checkbox: {
                                backgroundColor: "white",
                            },
                            checkmark: {
                                color: "black",
                            },
                        }}
                    />
                    <IconButton
                        id={iconButtonId}
                        iconProps={iconProps}
                        title={getLocalizedValue("RIN_ExecutiveSupportHelp")}
                        ariaLabel={getLocalizedValue("RIN_ExecutiveSupportHelp")}
                        onClick={toggleIsCalloutVisible}
                        className="iconButton"
                        styles={{ root: { height: "fit-content" } }}
                    />
                </div>
                


                <Dropdown
                    id="digitalSecurityDropdownId"
                    ariaLabel={getLocalizedValue("RIN_SelectTheDigitalincidentType")}
                    selectedKey={selectedIncidentTypeL1?.typeId}
                    role="combobox"
                    aria-haspopup={false}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e, item) => handleIncidentSelection(item)}
                    placeholder={getLocalizedValue("RIN_SelectDigitalSecurityEventType")}
                    options={incidentTypeOptions}
                    styles={{
                        root: {
                            marginTop: "16px",
                        },
                        //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                    }}
                />
            </div>
            <>
            {(digitalIncidentDetails.isExecSupport) && (selectedEventTypeId && selectedEventTypeId !== DigitalEventTypes.GovRelated) &&
                        <div className="ms-Grid-row" style={{ marginTop: '16px' }}>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <TextField
                                    ariaLabel={getLocalizedValue("RIN_ExecutiveAlias")}
                                    id="executiveAlias"
                                    label={getLocalizedValue("RIN_ExecutiveAlias")}
                                    onChange={(event, newValue) => {handleExecutiveAliasChange(newValue ?? "");}}
                                    onRenderDescription={() => executiveAliasDescription}
                                    placeholder = {getLocalizedValue("RIN_AddAliasOfAssociatedExecutiveUser")}
                                    styles={errorStatesDetails.executiveAlias.length > 0
                                        ? {
                                            field: {
                                                backgroundColor: "#FED9CC",
                                            },
                                        }
                                        : {
                                            field: {
                                                backgroundColor: "white",
                                            },
                                        }}
                                />

                            </div>
                        </div>
                    }
                </>
            {selectedIncidentTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess &&
                <Dropdown
                    id="unauthorizedAccessDropdownId"
                    label={getLocalizedValue("RIN_SelectIncidentSubcategory")}
                    ariaLabel={getLocalizedValue("RIN_SelectIncidentSubcategory")}
                    selectedKey={selectedIncidentTypeL2?.typeId}
                    role="combobox"
                    aria-haspopup={false}
                    // eslint-disable-next-line react/jsx-no-bind
                    onRenderLabel={(props) => <CustomLabel {...incidentSubcategoryCalloutProps} {...props} />}
                    onChange={(e, item) => handleL2IncidentSelection(item)}
                    placeholder={getLocalizedValue("RIN_SelectDigitalSecurityEventType")}
                    options={incidentTypesL2}
                    styles={{
                        root: {
                            marginTop: "16px",
                        },
                        //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                    }}
                />
            }
            <div>
                {isPanelOpen && (
                    <DigitalIncidentTypesPanel
                        isOpen={isPanelOpen}
                        dismissPanel={() => dispatch(setDigitalIncidentTypesPanelVisibility(false))}
                        handleIncidentTypeSelected={handleIncidentSelectionFromPanel}
                    />
                )}
            </div>
            {
                isIncidentTypeChangeDialogShown &&
                <IncidentTypeChange isHidden={!isIncidentTypeChangeDialogShown}
                    dismissDialog={hideIncidentTypeChangeDialog}
                    executeOnContinueProps={{ incidentTypeId: toBeSelectedIncidentTypeId, resetForm: true }}
                    executeOnContinue={handleIncidentTypeChange}
                    executeOnCancle={rerenderDropdownOnNoIncidentChange}
                />
            }
            {isCalloutVisible && (
                <FocusTrapCallout
                    directionalHint={DirectionalHint.topAutoEdge}
                    target={"#" + iconButtonId}
                    setInitialFocus
                    onDismiss={toggleIsCalloutVisible}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                    aria-live="assertive"
                >
                    <DelayedRender>
                        <FocusTrapZone isClickableOutsideFocusTrap>
                            <Stack
                                tokens={stackTokens}
                                horizontalAlign="start"
                                styles={labelCalloutStackStyles}
                            >
                                <span>
                                    <RichTextRenderer html={requiresExecSupportCalloutProps.calloutDescription} />
                                </span>
                            </Stack>
                        </FocusTrapZone>
                    </DelayedRender>
                </FocusTrapCallout>
            )}
        </div>
    );
}


