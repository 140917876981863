import { getFlowConfigurations } from "../Components/ConfigurationsPanel/ApplicationSettingsSlice";
import {
  getDigitalIncidentTypes,
  getTimeZones,
} from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { getSimulationCampaigns } from "../Components/DigitalSecurity/IncidentType/SimulationCampaignSlice";
import {
  getHelpSectionLinks,
  getHelpSections,
} from "../Components/HelpPanel/HelpSectionSlice";
import { getIncidentCategories } from "../Components/IncidentCategory/IncidentCategorySlice";
import {
  getPsIncidentTypesL1,
  getPsLocationsL1,
} from "../Components/PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { getFeatureFlags } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { getUserFlightStatus, getUserTestInProdStatus } from "../Components/UserProfile/UserProfileSlice";
import { store } from "../Store/store";

export function loadInitialData() {
  store.dispatch(getUserFlightStatus());
  store.dispatch(getFeatureFlags());
  store.dispatch(getUserTestInProdStatus());
  store.dispatch(getFlowConfigurations());

  store.dispatch(getIncidentCategories());

  store.dispatch(getDigitalIncidentTypes({ level: 1 }));
  store.dispatch(getPsIncidentTypesL1());
  store.dispatch(getSimulationCampaigns());

  store.dispatch(getTimeZones());
  store.dispatch(getPsLocationsL1());
  
  store.dispatch(getHelpSections());
  store.dispatch(getHelpSectionLinks());
}
