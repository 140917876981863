import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepperSteps } from "../../../Constants/CommonConstants";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import styles from "../IncidentCategoryv2/IncidentCategoryv2.module.css";
import styles1 from "../../DigitalSecurity/DigitalIncidentTypesPanel/DigitalIncidentTypesPanel.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PrimaryButtonAdapter } from "../Controls";
import { Checkbox, Label, MessageBar, MessageBarType } from "@fluentui/react";
import {
	DigitalIncidentInformationSelector,
	DigitalIncidentSelector,
	DigitalIncidentTypesSelector,
	ExposureOfPersonalDataSelector,
	ExposureOfSensitiveInfoSelector,
	IsTestDigitalIncidentSelector,
	LocalizedPhishingCallOrTextSelector,
	LocalizedPhishingEmailInputSelector,
	LocalizedSelectedIncidentTypeL1Selector,
	LocalizedSelectedIncidentTypeL2Selector,
	LocalizedUnauthorizedAccessSuspiciousLoginSelector,
	LocalizedUTechnicalSupportScamSelector,
	LocalizedUnauthorizedAccessUnInitiatedMFA,
	LocalizedUnauthorizedAccessUnknownDeviceSelector,
	MalwareSelector,
	postDigitalIncidentFormInformation,
	SelectedDigitalIncidentTimeZoneSelector,
	SelectedDigitalIncidentTypeL1Selector,
	SelectedDigitalIncidentTypeL2Selector,
	setIsTestIncident,
	SuspiciousOrMaliciousWebsiteSelector,
	TechnicalSupportScamSelector,
	UnauthorizedAccessOrHackSelector,
} from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { IncidentCategorySelector } from "../../IncidentCategory/IncidentCategorySlice";
import {
	IsTestPhysicalIncidentIncidentSelector,
	occurredDateTimeDtSelector,
	occurredToDateTimeDtSelector,
	physicalIncidentSelector,
	PsIncidentTypesL1Selector,
	PsLocalizedIncidentTypesSelector,
	PsLocationsL1Selector,
	PsLocationsL2Selector,
	PsLocationsL3Selector,
	PsPoliceFileNumberSelector,
	PsPoliceReportStatusSelector,
	PsSelectedincidentTypeIdL1Selector,
	PsSelectedLocationIdL1Selector,
	PsSelectedLocationIdL2Selector,
	PsSelectedLocationIdL3Selector,
	SelectedPhysicalIncidentTimeZoneSelector,
	setIsTestPhysicalIncidentIncident,
	submitPhysicalSecurityIncident,
} from "../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { physicalIncidentCardStyle } from "../../../styles";
import { DigitalEventTypes, DigitalUnauthorizedAccessL2EventTypes, SovereignDigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { isSovereignIncidentSelector, postSovereignDigitalFormInformation, postSovereignPhysicalFormInformation } from "../../Sovereign/SovereignSlice";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { DsUnauthorizedSuspiciousLoginLocalizedViewModel, DsUnauthorizedUninitiatedMFALocalizedViewModel, DsUnauthorizedUnknowDeviceLocalizedViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { DsDiPhishingCallorTextLocalizedInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiPhishingCallorTextInputViewModel";
import { DsDiTechnicalSupportScamLocalizedViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiTechnicalSupportScamViewModel";
import { INCIDENT_REVIEW_STEP } from "../../../Constants/InstrumentationConstants";
import { trackEvent } from "../../../Services/telemetryService";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector, featureFlagsSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { FileObject } from "../../FileUpload/FileUploadSlice";
import { UserTestInProdStatusSelector } from "../../UserProfile/UserProfileSlice";

export function IncidentReview() {
	const dispatch = useDispatch();
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const selectedIncidentCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;
	const localizedDigitalIncidentTypeL1Name = useSelector(LocalizedSelectedIncidentTypeL1Selector);
	const localizedPhysicalIncidentTypeL1Name = useSelector(PsLocalizedIncidentTypesSelector)?.selectedincidentTypeNameL1;
	const isGovIncident = useSelector(isSovereignIncidentSelector);
	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Review));
	}, []);

	return (
		<div className="incidentReview">
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					<div className={styles.categoryBlock} style={{ backgroundColor: "rgba(250, 250, 250, 1)", paddingTop: 12 }}>
						<div className={styles.category + " site-margins"}>
							<div className={styles.categoryDetails}>
								<h1 className={styles1.heading2}>{getLocalizedValueV2("RIN_ReviewYourReport")}</h1>
							</div>
						</div>
						<div className={styles.divider}></div>
					</div>
					<div className="site-margins">
						<h2 className={styles1.heading2}>{incidentCategoryDetails.selectedIncidentCategory?.name}</h2>
						<div className={styles1.incident}>
							<div>{getLocalizedValueV2("RIN_SelectedCategory")}</div>
							<div>
								<Link
									to={"/v2/create"}
									title={getLocalizedValueV2("RIN_EditIncidentCategory")}
									aria-label={getLocalizedValueV2("RIN_EditIncidentCategory")}
								>
									{getLocalizedValueV2("RIN_Edit")}
								</Link>
							</div>
						</div>
					</div>
					<div className={styles.divider} style={{ marginTop: 12, marginBottom: 12 }}></div>
					<div className="site-margins">
						<div>
							<h2 className={styles1.heading2}>
								{selectedIncidentCategoryId === IncidentCategories.Physical
									? localizedPhysicalIncidentTypeL1Name
									: localizedDigitalIncidentTypeL1Name}
								{isGovIncident && " - " + getLocalizedValueV2("RIN_USGovernmentRelated")}
							</h2>
						</div>
						<div style={{ paddingBottom: 16, display: "flex", justifyContent: "space-between" }}>
							<div>{getLocalizedValueV2("RIN_SelectedType")}</div>
							<Link
								to={"/v2/create/" + (selectedIncidentCategoryId === IncidentCategories.Physical ? "physical" : "digital")}
								title={getLocalizedValueV2("RIN_EditIncidentType")}
								aria-label={getLocalizedValueV2("RIN_EditIncidentType")}
							>
								{getLocalizedValueV2("RIN_Edit")}
							</Link>
						</div>
					</div>
					<div className={styles.divider} style={{ marginTop: 12, marginBottom: 12 }}></div>
					{selectedIncidentCategoryId == IncidentCategories.Digital && <DigitalIncidentReview />}
					{selectedIncidentCategoryId == IncidentCategories.Physical && <PhysicalIncidentReview />}
				</div>
			</div>
		</div>
	);
}

function PhysicalIncidentReview() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const selectedPhysicalIncidentTypeL1 = useSelector(PsSelectedincidentTypeIdL1Selector);
	const psLocalizedPhysicalIncidentTypesSelector = useSelector(PsLocalizedIncidentTypesSelector);
	const localizedPhysicalIncidentTypeL2Name = psLocalizedPhysicalIncidentTypesSelector.selectedincidentTypeNameL2;
	const localizedPhysicalIncidentTypeL3Name = psLocalizedPhysicalIncidentTypesSelector.selectedincidentTypeNameL3;
	const localizedPhysicalIncidentTypeL4Name = psLocalizedPhysicalIncidentTypesSelector.selectedincidentTypeNameL4;
	const countriesList = useSelector(PsLocationsL1Selector);
	const stateList = useSelector(PsLocationsL2Selector);
	const buildingList = useSelector(PsLocationsL3Selector);
	const selectedCountryId = useSelector(PsSelectedLocationIdL1Selector);
	const selectedStateId = useSelector(PsSelectedLocationIdL2Selector);
	const selectedBuildingId = useSelector(PsSelectedLocationIdL3Selector);
	const selectedCountry = countriesList.find((x) => x.idPk === selectedCountryId)?.siteNameNv ?? "";
	const selectedState = (selectedStateId ? stateList.find((x) => x.idPk === selectedStateId)?.siteNameNv : undefined) ?? "";
	const selectedBuilding = (selectedBuildingId ? buildingList.find((x) => x.idPk === selectedBuildingId)?.siteNameNv : undefined) ?? "";
	const selectedFromTime = useSelector(occurredDateTimeDtSelector);
	const selectedToTime = useSelector(occurredToDateTimeDtSelector);
	const selectedTimeZone = useSelector(SelectedPhysicalIncidentTimeZoneSelector);
	const isReportedToPolice = useSelector(PsPoliceReportStatusSelector);
	const policeFileNumber = useSelector(PsPoliceFileNumberSelector);
	const phoneNumber = useSelector(physicalIncidentSelector).physicalIncidentInformation.reporterPhoneNumber;
	const issueDescription = useSelector(physicalIncidentSelector).physicalIncidentInformation.summaryNt;
	const people = useSelector(physicalIncidentSelector).additionalInfoLookups.addedPeople;
	const items = useSelector(physicalIncidentSelector).additionalInfoLookups.addedItems;
	const vehicles = useSelector(physicalIncidentSelector).additionalInfoLookups.addedVehicles;
	const attachments = useSelector(physicalIncidentSelector).physicalIncidentInformation.attachments?.map((x, i) => {
		return {
			id: i,
			fileContent: x.attachmentIm,
			fileName: x.fileNameNv,
			fileSize: x.fileSizeIn
		} as FileObject;
	})

	const isGovIncident = useSelector(isSovereignIncidentSelector);
	const physicalIncidentData = useSelector(physicalIncidentSelector);

	const isTestIncident = useSelector(IsTestPhysicalIncidentIncidentSelector);

	const detailsSection = (label: string, description: string) => (
		<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
				<h3 className="label-h3">{label ?? ""}</h3>
				<span>{description ?? ""}</span>
			</div>
		</div>
	);

	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const incidentTypesL1 = useSelector(PsIncidentTypesL1Selector);
	const selectedIncidentTypeIdL1 = useSelector(PsSelectedincidentTypeIdL1Selector);

	const featureFlags = useSelector(featureFlagsSelector);
	const [isMobileExperienceInPilot, setPilotStatus] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (featureFlags) {
			setPilotStatus(featureFlags?.includes("MobileExperiencePilot"));
		}
	}, [featureFlags]);
	const isUserTestInProdPrivileged = useSelector(UserTestInProdStatusSelector);

	React.useEffect(() => {
		trackEvent({ name: INCIDENT_REVIEW_STEP }, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_REVIEW_STEP,
			incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
			incidentType: incidentTypesL1.find(x => x.perspectiveDbId === selectedIncidentTypeIdL1?.perspectiveDbId)?.name,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime) / 1000,
		});
	}, []);

	return (
		<div className="site-margins">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<h2 className={styles1.heading2}>{getLocalizedValueV2("RIN_Details")}</h2>
				<Link
					to={selectedPhysicalIncidentTypeL1?.id ? "/v2/create/physical/" + selectedPhysicalIncidentTypeL1?.id + "/details" : "/v2/create/physical"}
					title={getLocalizedValueV2("RIN_EditIncidentDetails")}
					aria-label={getLocalizedValueV2("RIN_EditIncidentDetails")}
				>
					{getLocalizedValueV2("RIN_Edit")}
				</Link>
			</div>
			{localizedPhysicalIncidentTypeL2Name && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						{localizedPhysicalIncidentTypeL3Name?.length > 0 ? (
							<>
								<h3 className="label-h3">{localizedPhysicalIncidentTypeL2Name}</h3>
								<span>{`${localizedPhysicalIncidentTypeL3Name}` + (localizedPhysicalIncidentTypeL4Name?.length > 0 ? `, ${localizedPhysicalIncidentTypeL4Name ?? ""}` : ``)}</span>
							</>
						) : (
							<>
								<h3 className="label-h3">{getLocalizedValueV2("RIN_IncidentType_Capitalized")}</h3>
								<span>{localizedPhysicalIncidentTypeL2Name ?? ""}</span>
							</>
						)}
					</div>
				</div>
			)}
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_Location")}</h3>
					<span>{"".concat(selectedBuilding?.length > 0 ? `${selectedBuilding}, ` : "", selectedState?.length > 0 ? `${selectedState}, ` : "", selectedCountry) ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_Time")}</h3>
					<span>
						{getLocalizedValueV2("RIN_From")} {selectedFromTime?.toLocaleString() ?? ""},
						<br />
						{getLocalizedValueV2("RIN_To")} {selectedToTime?.toLocaleString() ?? ""},
						<br />
						{selectedTimeZone}
					</span>
				</div>
			</div>
			{detailsSection(getLocalizedValueV2("RIN_ReportedToPolice"), isReportedToPolice ? "Yes" : "No")}
			{isReportedToPolice && policeFileNumber && detailsSection(getLocalizedValueV2("RIN_PoliceFileNumber"), policeFileNumber)}
			{detailsSection(getLocalizedValueV2("RIN_PhoneNumber"), phoneNumber)}
			{detailsSection(getLocalizedValueV2("RIN_IncidentSummary"), issueDescription)}
			{people?.length > 0 && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{ marginTop: 16 }}>
						<h2 className={styles1.heading2} style={{ marginBottom: -16 }}>
							{getLocalizedValueV2("RIN_InvolvedPeople")}
						</h2>
						{people.map((person) => (
							<>
								<div className="ms-Grid-row" style={physicalIncidentCardStyle}>
									<div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block" style={{ padding: 0 }}>
										<div>
											<div>
												<h3 className="label-h3" style={{ margin: "0 0 5px 0", wordBreak: "break-all" }}>
													{person.efirstNameNv} {person.elastNameNv}{" "}
													{person.eemployeeBt ? "(" + getLocalizedValueV2("RIN_Employee1") + ")" : ""}
												</h3>
												<label>
													<b style={{ fontWeight: 600 }}>{getLocalizedValueV2("RIN_Involvement")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
												</label>{" "}
												<span>{person.involvementType}</span>
												<br />
												<label>
													<b style={{ fontWeight: 600 }}>
														{getLocalizedValueV2("RIN_Phone")}{" "}
														&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</b>
												</label>{" "}
												<span style={{ marginLeft: "2px" }}>
													{person.primaryPhoneNumberNv.length ? person.primaryPhoneNumberNv : "N/A"}
												</span>
											</div>
										</div>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			)}

			{vehicles?.length > 0 && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{ marginTop: 16 }}>
						<h2 className={styles1.heading2} style={{ marginBottom: -16 }}>
							{getLocalizedValueV2("RIN_InvolvedVehicles")}
						</h2>
						{vehicles.map((vehicle) => (
							<>
								<div className="ms-Grid-row" style={physicalIncidentCardStyle}>
									<div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block" style={{ padding: 0 }}>
										<div>
											<div>
												<h3 className="label-h3">
													<b style={{ fontWeight: 600 }}>{getLocalizedValueV2("RIN_LicensePlate")} #: &nbsp;</b>
												</h3>{" "}
												<span>{vehicle.elicensePlateNv}</span>
												<br />
												<label>
													<b style={{ fontWeight: 600 }}>{getLocalizedValueV2("RIN_Involvement")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
												</label>{" "}
												<span style={{ marginLeft: "0px" }}>{vehicle.selectedInvolvement ?? "N/A"}</span>
												<br />
												<label>
													<b style={{ fontWeight: 600 }}>{getLocalizedValueV2("RIN_DriverName")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
												</label>{" "}
												<span style={{ marginLeft: "1px", wordBreak: "break-all" }}>
													{vehicle.evehicleDriverFullNameNv?.length ? vehicle.evehicleDriverFullNameNv : "N/A"}
												</span>
											</div>
										</div>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			)}

			{items?.length > 0 && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{ marginTop: 16 }}>
						<h2 className={styles1.heading2} style={{ marginBottom: -16 }}>
							{getLocalizedValueV2("RIN_InvolvedItems")}
						</h2>
						{items.map((item) => (
							<>
								<div className="ms-Grid-row" style={physicalIncidentCardStyle}>
									<div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block" style={{ padding: 0 }}>
										<div>
											<div>
												<h3 className="label-h3">
													<b style={{ fontWeight: 600 }}>
														{getLocalizedValueV2("RIN_ItemName")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</b>
												</h3>{" "}
												<span style={{ wordBreak: "break-all" }}>{item.eitemNameNv}</span>
												<br />
												<label>
													<b style={{ fontWeight: 600 }}>
														{getLocalizedValueV2("RIN_ItemValue")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</b>
												</label>{" "}
												<span style={{ marginLeft: "-2px" }}>{item.eitemValueNm ?? "N/A"}</span>
												<br />
												<label>
													<b style={{ fontWeight: 600 }}>
														{getLocalizedValueV2("RIN_ItemOwner")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</b>
												</label>{" "}
												<span style={{ marginLeft: "-1px", wordBreak: "break-all" }}>
													{item.eownerNamePersonNv?.length ? item.eownerNamePersonNv : "N/A"}
												</span>
											</div>
										</div>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			)}

			{
				attachments && attachments.length > 0 &&
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_Attachments_Capitalized")}</h3>
						{Files(attachments)}
					</div>
				</div>
			}
			{
				(isMobileExperienceInPilot || isUserTestInProdPrivileged ) &&
				<Checkbox
					label={getLocalizedValueV2("RIN_TestIncidentDisclaimer")}
					styles={{ root: { display: "inline" } }}
					checked={isTestIncident}
					disabled={true}
					onChange={(ev, checked) => {
						dispatch(setIsTestPhysicalIncidentIncident(checked ? true : undefined));
					}}
				/>
			}
			<br/>
			<div><strong className="label-h3" style={{ display: "inline" }}>{getLocalizedValueV2("RIN_DISCLAIMER")}:</strong> {getLocalizedValueV2("RIN_CELA_UserDisclaimer")}</div>
			<PrimaryButtonAdapter
				onClick={() => {
					if (isGovIncident) dispatch(postSovereignPhysicalFormInformation({ physicalIncident: physicalIncidentData, navigate: navigate }));
					else dispatch(submitPhysicalSecurityIncident(navigate));
				}}
				styles={{
					root: {
						marginTop: 16,
					},
				}}
			>
				{getLocalizedValueV2("RIN_Submit")}
			</PrimaryButtonAdapter>
		</div>
	);
}

function DigitalIncidentReview() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isGovIncident = useSelector(isSovereignIncidentSelector);
	const digitalIncidentData = useSelector(DigitalIncidentSelector);

	const selectedIncidentType = useSelector(SelectedDigitalIncidentTypeL1Selector);
	const selectedIncidentTypeL2 = useSelector(SelectedDigitalIncidentTypeL2Selector);
	const localizedIncidentTypeL1Name = useSelector(LocalizedSelectedIncidentTypeL1Selector);
	const localizedIncidentTypeL2Name = useSelector(LocalizedSelectedIncidentTypeL2Selector);
	const selectedIncidentTimeZone = useSelector(SelectedDigitalIncidentTimeZoneSelector);

	const digitalIncidentDetails = useSelector(DigitalIncidentInformationSelector);
	const selectedIncidentTime = digitalIncidentDetails.incidentDate;
	const isTestIncident = useSelector(IsTestDigitalIncidentSelector);

	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
	const selectedEventTypeL1 = digitalIncidentTypesDetails.selectedIncidentTypeL1;
	const attachments = digitalIncidentData.digitalIncidentInformation.attachedFiles?.map((x, i) => {
		return {
			id: i,
			fileContent: x.fileContent,
			fileName: x.name,
			fileSize: x.fileSizeInKB
		} as FileObject
	});

	const featureFlags = useSelector(featureFlagsSelector);
	const [isMobileExperienceInPilot, setPilotStatus] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (featureFlags) {
			setPilotStatus(featureFlags?.includes("MobileExperiencePilot"));
		}
	}, [featureFlags]);
	const isUserTestInProdPrivileged = useSelector(UserTestInProdStatusSelector);

	React.useEffect(() => {
		trackEvent({ name: INCIDENT_REVIEW_STEP }, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_REVIEW_STEP,
			incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
			incidentType: selectedEventTypeL1?.name,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime) / 1000,
		});
	}, []);

	return (
		<div className="site-margins">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<h2 className={styles1.heading2}>{getLocalizedValueV2("RIN_Details")}</h2>
				<Link
					to={selectedIncidentType?.typeId ? "/v2/create/digital/" + selectedIncidentType?.typeId + "/details" : "/v2/create/digital"}
					title={getLocalizedValueV2("RIN_EditIncidentDetails")}
					aria-label={getLocalizedValueV2("RIN_EditIncidentDetails")}
				>
					{getLocalizedValueV2("RIN_Edit")}
				</Link>
			</div>
			<div>
				<div className="location-time-container">
					<div>
						{selectedIncidentType && selectedIncidentTypeL2 && (
							<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
									<h3 className="label-h3">{`${localizedIncidentTypeL1Name} type`}</h3>
									<span>{localizedIncidentTypeL2Name}</span>
								</div>
							</div>
						)}
						<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
								<h3 className="label-h3">{getLocalizedValueV2("RIN_LocationTime")}</h3>
								<span>
									{selectedIncidentTime?.toLocaleString() ?? ""},
									<br />
									{selectedIncidentTimeZone}
								</span>
							</div>
						</div>

						<IncidentSpecificFieldsReview />

						{digitalIncidentDetails.azureSubscriptionId && (
							<div className="ms-Grid-row" style={{ marginBottom: "16px" }}>
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
									<h3 className="label-h3" id="lblMin">{getLocalizedValueV2("RIN_AzureSubscriptionId")}</h3>
									<span>{digitalIncidentDetails.azureSubscriptionId}</span>
								</div>
							</div>
						)}

						<div className="ms-Grid-row" style={{ marginBottom: "16px" }}>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
								<h3 className="label-h3" id="lblMinI">{getLocalizedValueV2("RIN_IncidentSummary")}</h3>
								<span>{digitalIncidentDetails.incidentDescription}</span>
							</div>
						</div>

						{
							attachments && attachments.length > 0 &&
							<div className="ms-Grid-row" style={{ marginBottom: "16px" }}>
								<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
									<h3 className="label-h3">{getLocalizedValueV2("RIN_Attachments_Capitalized")}</h3>
									{Files(attachments)}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			<div>
				{
					(isMobileExperienceInPilot || isUserTestInProdPrivileged) &&
					<Checkbox
						label={getLocalizedValueV2("RIN_TestIncidentDisclaimer")}
						styles={{ root: { display: "inline" } }}
						checked={isTestIncident}
						disabled={!isUserTestInProdPrivileged}
						onChange={(ev, checked) => {
							dispatch(setIsTestIncident(checked ? true : undefined));
						}}
					/>
				}
				<br />
				<div><strong className="label-h3" style={{ display: "inline" }}>{getLocalizedValueV2("RIN_DISCLAIMER")}:</strong> {getLocalizedValueV2("RIN_CELA_UserDisclaimer")}</div>
				<PrimaryButtonAdapter
					onClick={() => {
						if (isGovIncident) {
							dispatch(postSovereignDigitalFormInformation({ digitalIncident: digitalIncidentData, navigate: navigate }));
						}
						else dispatch(postDigitalIncidentFormInformation(navigate));
					}}
					styles={{
						root: {
							marginTop: 16,
						},
					}}
				>
					{getLocalizedValueV2("RIN_Submit")}
				</PrimaryButtonAdapter>
			</div>
		</div>
	);
}

const Files = (files: FileObject[]): JSX.Element => {
	files = Array.from(files);
	return (
		<div>
			{files.map((file) => (
				<MessageBar
					messageBarType={MessageBarType.info}
					isMultiline={false}
					truncated={false}
					styles={{
						root: {
							backgroundColor: "rgba(0, 0, 0, 0.1)",
							marginBottom: "4px",
						},
						iconContainer: {
							display: "none",
						},
						content: {
							color: "inherit",
							fontSize: "14px",
						},
						expand: {
							color: "inherit",
							selectors: {
								".ms-Button-icon": {
									color: "inherit",
								},
							},
						},
					}}
					overflowButtonAriaLabel={getLocalizedValueV2("RIN_ExpandAttachment") + " " + file.fileName}
				>
					{file.fileSize < 1024 * 1024 ? <>{(file.fileSize / 1024).toFixed(2)}KB</> : <>{(file.fileSize / (1024 * 1024)).toFixed(2)}MB</>} -{" "}
					{file.fileName}
				</MessageBar>
			))}
		</div>
	);
};

function IncidentSpecificFieldsReview() {
	const selectedIncidentType = useSelector(SelectedDigitalIncidentTypeL1Selector);
	const selectedIncidentTypeL1Id = selectedIncidentType?.typeId;
	switch (selectedIncidentTypeL1Id) {
		case DigitalEventTypes.PhishingEMail:
		case SovereignDigitalEventTypes.PhishingEMail:
			return <PhishingEmailFieldsReview />;
		case DigitalEventTypes.PhishingCallOrText:
		case SovereignDigitalEventTypes.PhishingCallOrText:
			return <PhishingCallOrTextFieldsReview />;
		case DigitalEventTypes.Malware:
		case SovereignDigitalEventTypes.Malware:
			return <MalwareFieldsReview />;
		case DigitalEventTypes.ExposureOfSensitiveInformation:
		case SovereignDigitalEventTypes.ExposureOfSensitiveInformation:
			return <ExposureOfSensitiveInformationFieldsReview />;
		case DigitalEventTypes.ExposureOfPersonalData:
		case SovereignDigitalEventTypes.ExposureOfPersonalData:
			return <ExposureOfPersonalDataFieldsReview />;
		case DigitalEventTypes.SuspiciousOrMaliciousWebsite:
		case SovereignDigitalEventTypes.SuspiciousOrMaliciousWebsite:
			return <SuspiciousOrMaliciousWebsiteFieldsReview />;
		case DigitalEventTypes.UnauthorizedAccess:
		case SovereignDigitalEventTypes.UnauthorizedAccess:
			return <UnauthorizedAccessOrHackFieldsReview />;
		case DigitalEventTypes.TechnicalSupportScam:
		case SovereignDigitalEventTypes.TechnicalSupportScam:
			return <TechnicalSupportScamReview />;
		default:
			break;
	}

	return <></>;
}

function PhishingEmailFieldsReview() {
	const phishingEmailDetails = useSelector(LocalizedPhishingEmailInputSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_EmailDeliveryLocation")}</h3>
					<span>{phishingEmailDetails?.emailDeliveredLocation ?? ""}</span>
				</div>
			</div>

			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_PhishingEmailQuestion1")}</h3>
					<span>{phishingEmailDetails?.isAttachmentorURLOpenedByUser}</span>
				</div>
			</div>

			{phishingEmailDetails?.isAttachmentorURLOpenedByUser && phishingEmailDetails.infoSharedByUser && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_PhishingEmailQuestion2")}</h3>
						<span>{phishingEmailDetails.infoSharedByUser}</span>
					</div>
				</div>
			)}
		</>
	);
}

function PhishingCallOrTextFieldsReview() {
	const phishingCallOrTextDetails: DsDiPhishingCallorTextLocalizedInputViewModel | undefined = useSelector(LocalizedPhishingCallOrTextSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_CallerDetails")}</h3>
					<span>{phishingCallOrTextDetails?.offenderContactDetails ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_CallSMSReceivedon")}</h3>
					<span>{phishingCallOrTextDetails?.callReceivedOn ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_CallSMSFrequency")}</h3>
					<span>{phishingCallOrTextDetails?.callFrequencyPerDay ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_SharedSensitiveInformation")}</h3>
					<span>{phishingCallOrTextDetails?.isSensitiveInformationShared}</span>
				</div>
			</div>
			{phishingCallOrTextDetails?.isSensitiveInformationShared && phishingCallOrTextDetails.infoSharedByUser && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_InformationSharedByUser")}</h3>
						<span>{phishingCallOrTextDetails.infoSharedByUser}</span>
					</div>
				</div>
			)}
		</>
	);
}

function MalwareFieldsReview() {
	const malwareDetails = useSelector(MalwareSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_AffectedMachineName")}</h3>
					<span>{malwareDetails?.affectedMachineName ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_FileName")}</h3>
					<span>{malwareDetails?.fileName ?? ""}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_ThreatName")}</h3>
					<span>{malwareDetails?.threatName ?? ""}</span>
				</div>
			</div>
		</>
	);
}

function ExposureOfSensitiveInformationFieldsReview() {
	const exposureOfSensitiveInfoDetails = useSelector(ExposureOfSensitiveInfoSelector);
	return (
		<>
			{exposureOfSensitiveInfoDetails?.customerOrPartnerDetails && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_CustomerOrPartnerDetails")}</h3>
						<span>{exposureOfSensitiveInfoDetails.customerOrPartnerDetails}</span>
					</div>
				</div>
			)}
		</>
	);
}

function ExposureOfPersonalDataFieldsReview() {
	const exposureOfPersonalDataSelector = useSelector(ExposureOfPersonalDataSelector);
	return (
		<>
			{exposureOfPersonalDataSelector?.customerOrPartnerDetails && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_CustomerOrPartnerDetails")}</h3>
						<span>{exposureOfPersonalDataSelector.customerOrPartnerDetails}</span>
					</div>
				</div>
			)}
		</>
	);
}

function SuspiciousOrMaliciousWebsiteFieldsReview() {
	const suspiciousorMaliciousWebsitesSelector = useSelector(SuspiciousOrMaliciousWebsiteSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_AffectedMachineName")}</h3>
					<span>{suspiciousorMaliciousWebsitesSelector?.affectedMachineName}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_URL")}</h3>
					<span>{suspiciousorMaliciousWebsitesSelector?.uRL}</span>
				</div>
			</div>
		</>
	);
}

function UnauthorizedAccessOrHackFieldsReview() {
	const selectedIncidentTypeL2 = useSelector(DigitalIncidentTypesSelector).selectedIncidentTypeL2;
	switch (selectedIncidentTypeL2?.typeId) {
		case DigitalUnauthorizedAccessL2EventTypes.SuspiciousLogin:
			return <UnauthorizedAccessSuspiciousLoginReview />;
		case DigitalUnauthorizedAccessL2EventTypes.UnknownDevice:
			return <UnauthorizedAccessUnknownDeviceReview />;
		case DigitalUnauthorizedAccessL2EventTypes.UninitiatedMFA:
			return <UnauthorizedAccessUninitiatedMFAReview />;
		default:
			return <></>;
	}
}

function UnauthorizedAccessSuspiciousLoginReview() {
	const suspiciousLoginDetails: DsUnauthorizedSuspiciousLoginLocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessSuspiciousLoginSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_Hostname")}</h3>
					<span>{suspiciousLoginDetails?.hostName}</span>
				</div>
			</div>
			{suspiciousLoginDetails?.relatedToWorkOrSchoolAccount && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_RelatedAccountType")}</h3>
						<span>{suspiciousLoginDetails?.relatedToWorkOrSchoolAccount}</span>
					</div>
				</div>
			)}
		</>
	);
}
function UnauthorizedAccessUnknownDeviceReview() {
	const unknownDeviceDetails: DsUnauthorizedUnknowDeviceLocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessUnknownDeviceSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_Hostname")}</h3>
					<span>{unknownDeviceDetails?.hostName}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_DeviceName")}</h3>
					<span>{unknownDeviceDetails?.enrolledDeviceName}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_IsTheDeviceEnrolled")}</h3>
					<span>{unknownDeviceDetails?.isNewDeviceEnrolled}</span>
				</div>
			</div>
		</>
	);
}
function UnauthorizedAccessUninitiatedMFAReview() {
	const uninitiatedMFADetails: DsUnauthorizedUninitiatedMFALocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessUnInitiatedMFA);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_Hostname")}</h3>
					<span>{uninitiatedMFADetails?.hostName}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_MFAPromptType")}</h3>
					<span>{uninitiatedMFADetails?.mfaprompt}</span>
				</div>
			</div>
			{uninitiatedMFADetails?.relatedToWorkOrSchoolAccount && (
				<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<h3 className="label-h3">{getLocalizedValueV2("RIN_RelatedAccountType")}</h3>
						<span>{uninitiatedMFADetails?.relatedToWorkOrSchoolAccount}</span>
					</div>
				</div>
			)}
		</>
	);
}
function TechnicalSupportScamReview() {
	const techSupportScamDetails: DsDiTechnicalSupportScamLocalizedViewModel | undefined = useSelector(LocalizedUTechnicalSupportScamSelector);
	return (
		<>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_AffectedMachineName")}</h3>
					<span>{techSupportScamDetails?.affectedMachineName}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_ScamURL")}</h3>
					<span>{techSupportScamDetails?.scamUrl}</span>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginTop: 8, marginBottom: 8 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
					<h3 className="label-h3">{getLocalizedValueV2("RIN_WasRemoteAccessAllowed")}</h3>
					<span>{techSupportScamDetails?.wasRemoteAccessAllowed}</span>
				</div>
			</div>
		</>
	);
}
