import { colGroupProperties, MessageBarType } from "@fluentui/react";
import { PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { put, select, takeEvery } from "redux-saga/effects";
import { clearFiles } from "../Components/FileUpload/FileUploadSlice";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../Components/IncidentCategory/IncidentCategorySlice";
import { setIsLoading, setPsIncidentTypesL1Loading, setPsLocationsL1Loading } from "../Components/Loader/LoaderSlice";
import { addOrUpdateMessage, removeMessage } from "../Components/Notifications/NotificationsSlice";
import {
	getItemCategoryTypes,
	getItemMakeTypes,
	getPersonInvolvementTypes,
	getPsIncidentTypesL1,
	getPsIncidentTypesL2,
	getPsLocationsL1,
	getVehicleColorTypes,
	getVehicleInvolvementTypes,
	getVehicleMakeTypes,
	getVehicleStyleTypes,
	physicalIncidentSelector,
	PsResetLevel,
	PsSelectedincidentTypeIdL2Selector,
	PsSelectedincidentTypeIdL3Selector,
	PsSelectedItemCategoryTypeIdSelector,
	PsSelectedItemMakeTypeIdSelector,
	PsSelectedLocationIdL1Selector,
	PsSelectedLocationIdL2Selector,
	PsSelectedVehicleMakeTypeIdSelector,
	PsValidationErrorCountSelector,
	PsValidationErrorMessageSelector,
	resetPhysicalIncidentInformation,
	setIsL1PhysicalIncidentTypesApiCallSuccess,
	setIsL2PhysicalIncidentTypesApiCallSuccess,
	setIsL3PhysicalIncidentTypesApiCallSuccess,
	setIsL4PhysicalIncidentTypesApiCallSuccess,
	setItemCategoryTypes,
	setItemMakeTypes,
	setItemModelTypes,
	setItemSubCategoryTypes,
	setPersonInvolvementTypes,
	setPsincidentTypesL1,
	setPsincidentTypesL2,
	setPsincidentTypesL3,
	setPsincidentTypesL4,
	setPsLocationsL1,
	setPsLocationsL2,
	setPsLocationsL3,
	setPsSelectedIncidentTypeIdL1,
	setPsSelectedIncidentTypeIdL2,
	setPsSelectedIncidentTypeIdL3,
	setPsSelectedLocationIdL1,
	setPsSelectedLocationIdL2,
	setSelectedItemCategoryTypeId,
	setSelectedItemMakeTypeId,
	setSelectedVehicleMakeTypeId,
	setVehicleColorTypes,
	setVehicleInvolvementTypes,
	setVehicleMakeTypes,
	setVehicleModelTypes,
	setVehicleStyleTypes,
	submitPhysicalSecurityIncident,
	validatePhysicalSecurityincident,
	validatePhysicalIncidentDataV2,
	PsSelectedincidentTypeIdL1Selector,
	PsIncidentTypesL1Selector,
	setIsTestPhysicalIncidentIncident,
} from "../Components/PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { setSubmittedIncidentId, showSuccessPage } from "../Components/SubmissionSuccess/SubmissionSuccessSlice";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector, serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE, STEP } from "../Constants/CommonConstants";
import {
	FetchPhysicalIncidentItemCategoryTypes,
	FetchPhysicalIncidentItemMakeTypes,
	FetchPhysicalIncidentItemModelTypes,
	FetchPhysicalIncidentItemSubCategoryTypes,
	FetchPhysicalIncidentLocations,
	FetchPhysicalIncidentPersonInvolvementTypes,
	FetchPhysicalIncidentTypes,
	FetchPhysicalIncidentVehicleColorTypes,
	FetchPhysicalIncidentVehicleInvolvementTypes,
	FetchPhysicalIncidentVehicleMakeTypes,
	FetchPhysicalIncidentVehicleModelTypes,
	FetchPhysicalIncidentVehicleStyleTypes,
	SubmitPhysicalIncident,
} from "../Constants/ErrorCodes";
import { PHYSICAL_SUBMISSION_MESSAGE } from "../Constants/SubmissionSuccessConstants";
import { PhysicalIncidentModel } from "../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import { PsEincidentInputViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsEincidentInputViewModel";
import { PsItemClassRollupResponseViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsItemClassRollupResponseViewModel";
import { PsItemMakeModelRollupResponseViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsItemMakeModelRollupResponseViewModel";
import { PsLookupValueResponseViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsLookupValueResponseViewModel";
import { PsSiteRollupResponseViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsSiteRollupResponseViewModel";
import { PsVehicleMakeModelRollupResponseViewModel } from "../Contracts/ServiceModels/PhysicalSecurity/PsVehicleMakeModelRollupResponseViewModel";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { PsPhysicalIncidentTypeResponseViewModel } from "../Contracts/TypesAndInterfaces/PsPhysicalIncidentTypeResponseViewModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { getLocalizedValue } from "../Services/localizationService";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError } from "../Services/errorHandlerService";
import { GET, POST } from "../Services/httpService";
import { GetExceptionModel, trackEvent } from "../Services/telemetryService";
import { INCIDENT_SUBMISSION_STEP } from "../Constants/InstrumentationConstants";
import { IncidentCategoryModel } from "../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";

/** Incident Types */

function* fetchPsIncidentTypesL1() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	try {
		yield put(setPsIncidentTypesL1Loading(true));
		let accessToken: string = yield fetchAPIAccessToken();
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getphysicalincidenttypesbylevelandid?rollupLevel=1",
			accessToken
		);
		yield put(
			setPsincidentTypesL1(
				response?.responseObject == null || response?.responseObject === undefined
					? []
					: (response?.responseObject as PsPhysicalIncidentTypeResponseViewModel[])
			)
		);
		if (!response?.responseSuccess) {
			yield put(setIsL1PhysicalIncidentTypesApiCallSuccess(false));
			yield handleError(true, false, undefined);
		}
		yield put(setPsIncidentTypesL1Loading(false));
	} catch (e) {
		yield put(setPsIncidentTypesL1Loading(false));
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident types L1",
			"fetchPsIncidentTypesL1",
			true,
			"Outer",
			null
		);
		yield handleError(true, false, exceptionModel);
	}
}

function* fetchPsIncidentTypesL2({
	payload,
}: PayloadAction<
	| {
			id: number;
			perspectiveDbId: string | null | undefined;
			label: string;
			selectedSovereignId?: number;
	  }
	| undefined
>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	const selectedincidentTypeIdL1: { id: number; perspectiveDbId: string | null | undefined; label: string } | undefined = payload; //yield select(PsSelectedincidentTypeIdL1Selector);
	if (selectedincidentTypeIdL1 && (selectedincidentTypeIdL1.perspectiveDbId != null || payload?.selectedSovereignId)) {
		try {
			yield put(setIsLoading(true));
			let endPoint = "/api/PhysicalIncident/getphysicalincidenttypesbylevelandid?rollupLevel=2&parentClassRollupId=" + selectedincidentTypeIdL1.id;
			if (payload?.selectedSovereignId) endPoint += "&govNationId=" + payload?.selectedSovereignId;
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				endPoint,
				accessToken
			);
			yield put(
				setPsincidentTypesL2(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsPhysicalIncidentTypeResponseViewModel[])
				)
			);
			if (!response?.responseSuccess) {
				yield put(setIsL2PhysicalIncidentTypesApiCallSuccess(false));
				yield handleError(true, false, undefined);
			}
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident types L2",
				"fetchPsIncidentTypesL2",
				true,
				"Outer",
				null
			);
			yield handleError(true, false, exceptionModel);
		}
	}
}

function* fetchPsIncidentTypesL3({ payload }: PayloadAction<{ selectedSovereignId?: number } | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const selectedincidentTypeIdL2: { id: number; perspectiveDbId: string } = yield select(PsSelectedincidentTypeIdL2Selector);
	let accessToken: string = yield fetchAPIAccessToken();
	if (selectedincidentTypeIdL2) {
		try {
			yield put(setIsLoading(true));
			let endPoint = "/api/PhysicalIncident/getphysicalincidenttypesbylevelandid?rollupLevel=3&parentClassRollupId=" + selectedincidentTypeIdL2.id;
			if (payload?.selectedSovereignId) endPoint += "&govNationId=" + payload?.selectedSovereignId;
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				endPoint,
				accessToken
			);
			yield put(
				setPsincidentTypesL3(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsPhysicalIncidentTypeResponseViewModel[])
				)
			);
			if (!response?.responseSuccess) {
				yield put(setIsL3PhysicalIncidentTypesApiCallSuccess(false));
			}
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident types L3",
				"fetchPsIncidentTypesL3",
				false,
				"Outer",
				null
			);
			yield handleError(false, false, exceptionModel);
		}
	}
}

function* fetchPsIncidentTypesL4({ payload }: PayloadAction<{ selectedSovereignId?: number } | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const selectedincidentTypeIdL3: { id: number; perspectiveDbId: string } = yield select(PsSelectedincidentTypeIdL3Selector);
	let accessToken: string = yield fetchAPIAccessToken();
	if (selectedincidentTypeIdL3) {
		try {
			yield put(setIsLoading(true));
			let endPoint = "/api/PhysicalIncident/getphysicalincidenttypesbylevelandid?rollupLevel=4&parentClassRollupId=" + selectedincidentTypeIdL3.id;
			if (payload?.selectedSovereignId) endPoint += "&govNationId=" + payload?.selectedSovereignId;
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				endPoint,
				accessToken
			);
			yield put(
				setPsincidentTypesL4(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsPhysicalIncidentTypeResponseViewModel[])
				)
			);
			if (!response?.responseSuccess) {
				yield put(setIsL4PhysicalIncidentTypesApiCallSuccess(false));
			}
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident types L4",
				"fetchPsIncidentTypesL4",
				false,
				"Outer",
				null
			);
			yield handleError(false, false, exceptionModel);
		}
	}
}

export function* watchGetPsIncidentTypesL1() {
	yield takeEvery(getPsIncidentTypesL1, fetchPsIncidentTypesL1);
}

export function* watchGetPsIncidentTypesL2() {
	yield takeEvery([setPsSelectedIncidentTypeIdL1, getPsIncidentTypesL2], fetchPsIncidentTypesL2);
}

export function* watchGetPsIncidentTypesL3() {
	yield takeEvery(setPsSelectedIncidentTypeIdL2, fetchPsIncidentTypesL3);
}

export function* watchGetPsIncidentTypesL4() {
	yield takeEvery(setPsSelectedIncidentTypeIdL3, fetchPsIncidentTypesL4);
}

/** Locations */

/**
 * set payload to true if you don't want loader to show up.
 */
function* fetchPsLocationsL1({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setPsLocationsL1Loading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getsiterollupsbylevelandid?rollupLevel=1",
			accessToken
		);
		yield put(
			setPsLocationsL1(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsSiteRollupResponseViewModel[])
			)
		);
		if (!payload) yield put(setPsLocationsL1Loading(false));

		if (!response?.responseSuccess) {
			yield handleError(true, false, undefined);
		}
	} catch (e) {
		if (!payload) yield put(setPsLocationsL1Loading(false));
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentLocations,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident locations L1",
			"fetchPsLocationsL1",
			true,
			"Outer",
			null
		);
		yield handleError(true, false, exceptionModel);
	}
}

function* fetchPsLocationsL2() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const selectedLocationIdL1 = yield select(PsSelectedLocationIdL1Selector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getsiterollupsbylevelandid?rollupLevel=2&parentClassRollupId=" + selectedLocationIdL1,
			accessToken
		);
		yield put(
			setPsLocationsL2(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsSiteRollupResponseViewModel[])
			)
		);
		yield put(setIsLoading(false));
	} catch (e) {
		yield put(setIsLoading(false));
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentLocations,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident locations L2",
			"fetchPsLocationsL2",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

function* fetchPsLocationsL3() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const selectedLocationIdL2 = yield select(PsSelectedLocationIdL2Selector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getsiterollupsbylevelandid?rollupLevel=3&parentClassRollupId=" + selectedLocationIdL2,
			accessToken
		);
		yield put(
			setPsLocationsL3(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsSiteRollupResponseViewModel[])
			)
		);
		yield put(setIsLoading(false));
	} catch (e) {
		yield put(setIsLoading(false));
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentLocations,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident locations L3",
			"fetchPsLocationsL3",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

export function* watchGetPsLocationsL1() {
	yield takeEvery(getPsLocationsL1, fetchPsLocationsL1);
}

export function* watchGetPsLocationsL2() {
	yield takeEvery(setPsSelectedLocationIdL1, fetchPsLocationsL2);
}

export function* watchGetPsLocationsL3() {
	yield takeEvery(setPsSelectedLocationIdL2, fetchPsLocationsL3);
}

/** Person lookup*/

function* fetchPsPsPersonInvolvementTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getlookupvaluesbyparentid?parentId=PersInvolvementTypes",
			accessToken
		);
		yield put(
			setPersonInvolvementTypes(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsLookupValueResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
		if (!response?.responseSuccess && !payload) {
			yield handleError(true, false, undefined);
		}
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentPersonInvolvementTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident person involvement types",
			"fetchPsPsPersonInvolvementTypes",
			true,
			"Outer",
			null
		);
		if (!payload) {
			yield handleError(true, false, exceptionModel);
		} else {
			yield handleError(false, false, exceptionModel);
		}
	}
}

export function* watchGetPsPersonInvolvementTypes() {
	yield takeEvery(getPersonInvolvementTypes, fetchPsPsPersonInvolvementTypes);
}

/** Vehicle Lookup */

function* fetchPsVehicleInvolvementTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getlookupvaluesbyparentid?parentId=VehInvolvementTypes",
			accessToken
		);
		yield put(
			setVehicleInvolvementTypes(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsLookupValueResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
		if (!response?.responseSuccess && !payload) {
			yield handleError(true, false, undefined);
		}
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentVehicleInvolvementTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident vehicle involvement types",
			"fetchPsVehicleInvolvementTypes",
			true,
			"Outer",
			null
		);
		if (!payload) {
			yield handleError(true, false, exceptionModel);
		} else {
			yield handleError(false, false, exceptionModel);
		}
	}
}

function* fetchPsVehicleMakeTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getvehiclemakemodelrollupsbylevelandid?rollupLevel=1",
			accessToken
		);
		yield put(
			setVehicleMakeTypes(
				response?.responseObject == null || response?.responseObject === undefined
					? []
					: (response?.responseObject as PsVehicleMakeModelRollupResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentVehicleMakeTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident vehicle make types",
			"fetchPsVehicleMakeTypes",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

function* fetchPsVehicleModelTypes() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const parentClassRollupId = yield select(PsSelectedVehicleMakeTypeIdSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	if (parentClassRollupId) {
		try {
			yield put(setIsLoading(true));
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				"/api/PhysicalIncident/getvehiclemakemodelrollupsbylevelandid?rollupLevel=2&parentClassRollupId=" + parentClassRollupId,
				accessToken
			);
			yield put(
				setVehicleModelTypes(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsVehicleMakeModelRollupResponseViewModel[])
				)
			);
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentVehicleModelTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident vehicle model types",
				"fetchPsVehicleModelTypes",
				false,
				"Outer",
				null
			);
			yield handleError(false, false, exceptionModel);
		}
	}
}

function* fetchPsVehicleColorTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getlookupvaluesbyparentid?parentId=VehicleColors",
			accessToken
		);
		yield put(
			setVehicleColorTypes(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsLookupValueResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentVehicleColorTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident vehicle color types",
			"fetchPsVehicleColorTypes",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

function* fetchPsVehicleStyleTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getlookupvaluesbyparentid?parentId=VehicleStyles",
			accessToken
		);
		yield put(
			setVehicleStyleTypes(
				response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as PsLookupValueResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
	} catch (e) {
		yield put(setIsLoading(false));
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentVehicleStyleTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident vehicle style types",
			"fetchPsVehicleStyleTypes",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

export function* watchGetPsVehicleInvolvementTypes() {
	yield takeEvery(getVehicleInvolvementTypes, fetchPsVehicleInvolvementTypes);
}

export function* watchGetPsVehicleMakeTypes() {
	yield takeEvery(getVehicleMakeTypes, fetchPsVehicleMakeTypes);
}

export function* watchGetPsVehicleModelTypes() {
	yield takeEvery(setSelectedVehicleMakeTypeId, fetchPsVehicleModelTypes);
}

export function* watchGetPsVehicleColorTypes() {
	yield takeEvery(getVehicleColorTypes, fetchPsVehicleColorTypes);
}

export function* watchGetPsVehicleStyleTypes() {
	yield takeEvery(getVehicleStyleTypes, fetchPsVehicleStyleTypes);
}

/** Item Lookups */

function* fetchPsItemMakeTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getitemmakemodelrollupsbylevelandid?rollupLevel=1",
			accessToken
		);
		yield put(
			setItemMakeTypes(
				response?.responseObject == null || response?.responseObject === undefined
					? []
					: (response?.responseObject as PsItemMakeModelRollupResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentItemMakeTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident item make types",
			"fetchPsItemMakeTypes",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

function* fetchPsItemModelTypes() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const parentClassRollupId = yield select(PsSelectedItemMakeTypeIdSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	if (parentClassRollupId) {
		try {
			yield put(setIsLoading(true));
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				"/api/PhysicalIncident/getItemmakemodelrollupsbylevelandid?rollupLevel=2&parentClassRollupId=" + parentClassRollupId,
				accessToken
			);
			yield put(
				setItemModelTypes(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsItemMakeModelRollupResponseViewModel[])
				)
			);
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentItemModelTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident item model types",
				"fetchPsItemModelTypes",
				false,
				"Outer",
				null
			);
			yield handleError(false, false, exceptionModel);
		}
	}
}

function* fetchPsItemCategoryTypes({ payload }: PayloadAction<boolean | undefined>) {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	try {
		if (!payload) yield put(setIsLoading(true));
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/PhysicalIncident/getitemclassrollupsbylevelandid?rollupLevel=1",
			accessToken
		);
		yield put(
			setItemCategoryTypes(
				response?.responseObject == null || response?.responseObject === undefined
					? []
					: (response?.responseObject as PsItemClassRollupResponseViewModel[])
			)
		);
		if (!payload) yield put(setIsLoading(false));
	} catch (e) {
		if (!payload) {
			yield put(setIsLoading(false));
		}
		let exceptionModel = GetExceptionModel(
			FetchPhysicalIncidentItemCategoryTypes,
			SOURCE,
			"Physical" + STEP,
			"Fetch physical incident item category types",
			"fetchPsItemCategoryTypes",
			false,
			"Outer",
			null
		);
		yield handleError(false, false, exceptionModel);
	}
}

function* fetchPsItemSubCategoryTypes() {
	const serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const parentClassRollupId = yield select(PsSelectedItemCategoryTypeIdSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	if (parentClassRollupId) {
		try {
			yield put(setIsLoading(true));
			let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				"/api/PhysicalIncident/getitemclassrollupsbylevelandid?rollupLevel=2&parentClassRollupId=" + parentClassRollupId,
				accessToken
			);
			yield put(
				setItemSubCategoryTypes(
					response?.responseObject == null || response?.responseObject === undefined
						? []
						: (response?.responseObject as PsItemClassRollupResponseViewModel[])
				)
			);
			yield put(setIsLoading(false));
		} catch (e) {
			yield put(setIsLoading(false));
			let exceptionModel = GetExceptionModel(
				FetchPhysicalIncidentItemSubCategoryTypes,
				SOURCE,
				"Physical" + STEP,
				"Fetch physical incident item sub category types",
				"fetchPsItemSubCategoryTypes",
				false,
				"Outer",
				null
			);
			yield handleError(false, false, exceptionModel);
		}
	}
}

export function* watchGetPsItemMakeTypes() {
	yield takeEvery(getItemMakeTypes, fetchPsItemMakeTypes);
}

export function* watchGetPsItemModelTypes() {
	yield takeEvery(setSelectedItemMakeTypeId, fetchPsItemModelTypes);
}

export function* watchGetPsItemCategoryTypes() {
	yield takeEvery(getItemCategoryTypes, fetchPsItemCategoryTypes);
}

export function* watchGetPsItemSubCategoryTypes() {
	yield takeEvery(setSelectedItemCategoryTypeId, fetchPsItemSubCategoryTypes);
}

/** Form submission */

function* postPsIncident({ payload }: PayloadAction<NavigateFunction | undefined>) {
	var serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	yield put(removeMessage("form-submission-exception"));
	yield put(validatePhysicalSecurityincident());
	const formData: PhysicalIncidentModel = yield select(physicalIncidentSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	const appInsightsIncidentSubmissionFlowId: string | undefined = yield select(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime: number = yield select(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails: IncidentCategoryModel = yield select(IncidentCategorySelector);
	const incidentTypesL1: PsPhysicalIncidentTypeResponseViewModel[] = yield select(PsIncidentTypesL1Selector);
	const selectedIncidentTypeIdL1: {perspectiveDbId:string | null | undefined} = yield select(PsSelectedincidentTypeIdL1Selector);
	if (formData.isFormValid) {
		try {
			yield put(removeMessage("validation-message"));
			yield put(setIsLoading({ loadingState: true, showSubmissionText: true }));
			var response: WebResponseModel | undefined = yield POST<PsEincidentInputViewModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				"/api/PhysicalIncident/submitincident",
				formData.physicalIncidentInformation,
				accessToken
			);
			if (!response?.responseSuccess) {
				yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
				if (response?.responseCode === 500) {
					yield handleError(false, true, undefined);
				} else {
					yield handleError(true, false, undefined);
				}
        if (payload) {
					payload("/v2/exception");
				}
			} else if (response?.responseSuccess) {
				yield put(setSubmittedIncidentId(response.responseObject?.toString()));
				yield put(
					showSuccessPage({
						isSuccessPageShown: true,
						message: getLocalizedValue(PHYSICAL_SUBMISSION_MESSAGE),
						incidentId: response.responseObject.toString(),
					})
				);
				if (payload) {
					payload("/v2/success");
					trackEvent({name: INCIDENT_SUBMISSION_STEP}, {
						incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
						step: INCIDENT_SUBMISSION_STEP,
						incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
						incidentType: incidentTypesL1.find(x=>x.perspectiveDbId === selectedIncidentTypeIdL1?.perspectiveDbId)?.name,
						isSovereignIncident: false,
						timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
					});
				}
				yield put(setSelectedIncidentCategory(undefined));
				yield put(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
				yield put(clearFiles());
				yield put(removeMessage("form-submission-exception"));
				yield put(setIsTestPhysicalIncidentIncident(undefined));
				yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			}
		} catch (e) {
			yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			let exceptionModel = GetExceptionModel(
				SubmitPhysicalIncident,
				SOURCE,
				"Physical" + STEP,
				"Submit physical incident",
				"postPsIncident",
				true,
				"Outer",
				null
			);
			yield handleError(false, true, exceptionModel);
      if (payload) {
        payload("/v2/exception");
      }
		}
	} else {
		const errorMessage = yield select(PsValidationErrorMessageSelector);
		const errorCount = yield select(PsValidationErrorCountSelector);
		yield put(
			addOrUpdateMessage({
				messageContent:
					errorCount > 1 ? getLocalizedValue("RIN_YouHave") + " " + errorCount + " " + getLocalizedValue("RIN_ValidationErrors") : errorMessage,
				id: "validation-message",
				messageType: MessageBarType.error,
			})
		);
	}
}

export function* watchPostPsIncidnet() {
	yield takeEvery(submitPhysicalSecurityIncident, postPsIncident);
}

function* clearNotifications({ payload }: PayloadAction<PsResetLevel>) {
	if (payload <= PsResetLevel.IncidentTypeL2Changed) {
		yield put(removeMessage("validation-message"));
		yield put(removeMessage("form-submission-exception"));
	}
}

export function* watchPsIncidentFormReset() {
	yield takeEvery(resetPhysicalIncidentInformation, clearNotifications);
}

function* validatePhysicalIncidentV2({ payload }: PayloadAction<NavigateFunction>) {
	var serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	yield put(removeMessage("form-submission-exception"));
	yield put(validatePhysicalSecurityincident());
	const formData: PhysicalIncidentModel = yield select(physicalIncidentSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	const selectedPhysicalIncidentTypeL1 = yield select(PsSelectedincidentTypeIdL1Selector);
	if (formData.isFormValid) {
		if (payload) payload("/v2/create/physical/" + selectedPhysicalIncidentTypeL1.id + "/review");
	} else {
		const errorMessage = yield select(PsValidationErrorMessageSelector);
		const errorCount = yield select(PsValidationErrorCountSelector);
		yield put(
			addOrUpdateMessage({
				messageContent: errorCount > 1 ? "You have " + errorCount + " validation errors." : errorMessage,
				id: "validation-message",
				messageType: MessageBarType.error,
			})
		);
	}
}

export function* watchValidatePhysicalV2() {
	yield takeEvery(validatePhysicalIncidentDataV2, validatePhysicalIncidentV2);
}
