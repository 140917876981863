import * as React from "react";
import { Spinner } from '@fluentui/react/lib/Spinner';
import { useSelector } from "react-redux";
import { LoaderSelector } from "./LoaderSlice";
import styles from "./Loader.module.css";

export default function Loader() {
    const loaderDetails = useSelector(LoaderSelector);
    return (
        <div className={styles.loader} role="alert" aria-live="assertive">
                <Spinner label={loaderDetails.loaderText} styles={{
                    circle: {
                        color: "white",
                        height: "80px",
                        width: "80px",
                        borderWidth: "3px"
                    },
                    label: {
                        color: "white",
                        fontSize: "20px"
                    }
                }
                } />
        </div>
    );
}