import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ExceptionPageModel {
  showErrorPage?: boolean;
  errorMessageToDisplay?: string;
  errorPageHeader?: string;
  occupyFullScreen?: boolean;
}
const initialState: ExceptionPageModel = {
  showErrorPage: false,
  errorMessageToDisplay: "",
  errorPageHeader: "",
  occupyFullScreen: true,
};

const exceptionPageSlice = createSlice({
  name: "exception",
  initialState,
  reducers: {
    showExceptionPage: (
      state,
      { payload }: PayloadAction<ExceptionPageModel>
    ) => {
      state.showErrorPage = true;
      state.errorMessageToDisplay = payload.errorMessageToDisplay;
      state.errorPageHeader = payload.errorPageHeader;
      state.occupyFullScreen = payload.occupyFullScreen;
    },
    hideExceptionPage: (state) => {
      state.showErrorPage = false;
      state.errorMessageToDisplay = "";
    },
  },
});

export const { showExceptionPage, hideExceptionPage } =
  exceptionPageSlice.actions;
export default exceptionPageSlice.reducer;
export const exceptionPageSelector = (state: {
  exceptionPage: ExceptionPageModel;
}) => state.exceptionPage;

export const showExceptionPageSelector = (state: {
  exceptionPage: ExceptionPageModel;
}) => state.exceptionPage.showErrorPage;
