export const SERVICE_COMMUNICATION_SETTINGS: string = "serviceCommunicationSettings";
export const APPLICATION_INSIGHTS_KEY: string = "applicationInsightsKey";
export const ENVIRONMENT: string = "environment";
export const SERVICE_URI: string = "serviceUri";
export const ACCESS_TOKEN: string = "accessToken";
export const GRAPH_TOKEN: string = "graphToken";
export const ALIAS: string = "Alias";
export const NAME: string = "Name";
export const THUMBNAIL: string = "Thumbnail";
export const THUMBNAIL_URL: string = "ThumbnailUrl"
export const TITLE: string = "Title";
export const EXP: string = "Exp";
export const USER_PROFILE = "UserProfile";


