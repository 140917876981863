import { IButtonProps, DefaultButton, PrimaryButton as FluentPrimaryButton } from "@fluentui/react";
import { deepmerge } from "@fluentui/styles";
import React from "react";

export type DefaultButtonProps = IButtonProps;

export function DefaultButtonAdapter(props: DefaultButtonProps) {
	return (
		<DefaultButton
			{...props}
			styles={deepmerge(props.styles, {
				root: {
					selectors: {
						"@media (max-width: 479px)": {
							fontSize: "16px",
							width: "100%",
							borderRadius: "4px",
							height: "54px",
						},
					},
				},
			})}
		/>
	);
}

export type PrimaryButtonProps = IButtonProps;

export function PrimaryButtonAdapter(props: PrimaryButtonProps) {
	return (
		<FluentPrimaryButton
			{...props}
			styles={deepmerge(props.styles, {
				root: {
					selectors: {
						"@media (max-width: 479px)": {
							fontSize: "16px",
							width: "100%",
							borderRadius: "4px",
							height: "54px",
						},
					},
				},
			})}
		/>
	);
}
