export enum DigitalEventTypes{
    PhishingEMail=1,
    PhishingCallOrText,
    Malware,
    PolicyViolation,
    ExposureOfSensitiveInformation,
    UnauthorizedAccess,
    SuspiciousOrMaliciousWebsite,
    ExposureOfPersonalData ,
    GovRelated,
    TechnicalSupportScam = 24,
    Other,
}

export enum SovereignDigitalEventTypes{
    PhishingEMail=12,
    PhishingCallOrText,
    Malware,
    PolicyViolation,
    ExposureOfSensitiveInformation,
    UnauthorizedAccess,
    SuspiciousOrMaliciousWebsite,
    ExposureOfPersonalData ,
    TechnicalSupportScam=28,
    Other,
}

export enum DigitalUnauthorizedAccessL2EventTypes {
    SuspiciousLogin = 21,
    UnknownDevice,
    UninitiatedMFA,
}

export enum SovereignDigitalUnauthorizedAccessL2EventTypes {
    SuspiciousLogin = 25,
    UnknownDevice,
    UninitiatedMFA,
    TechnicalSupportScam
}