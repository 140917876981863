import { SiteNotificationResponseViewModel } from './../../Contracts/TypesAndInterfaces/SiteNotificationResponseViewModel.d';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationDetails, Notifications } from "../../Contracts/TypesAndInterfaces/NotificationsDetails";

const initialState: Notifications = {
    messages: [],
    siteNotificationDetails: [],
    isSiteNotificationsAPICallSuccess: undefined,
}

const NotificationDetailsSlice = createSlice({
    name: "NotificationDetails",
    initialState,
    reducers: {
        addMessage: (state, { payload }: PayloadAction<NotificationDetails>) => {
            state.messages.push(payload);
        },
        addOrUpdateMessage: (state, { payload }: PayloadAction<NotificationDetails>) => {
            const messageIndex = state.messages.findIndex(x => x.id === payload.id);
            if (messageIndex >= 0) {
                state.messages[messageIndex] = payload;
            }
            else
                state.messages.push(payload);
        },
        removeMessage: (state, { payload }: PayloadAction<string>) => {
            state.messages = state.messages.filter(s => s.id !== payload);
        },
        setSiteNotificationDetails: (state, { payload }: PayloadAction<SiteNotificationResponseViewModel[]>) => {
            state.siteNotificationDetails = payload;
        },
        setIsSiteNotificationsAPICallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.isSiteNotificationsAPICallSuccess = payload;
        },
        getSiteNotificationDetails:()=>{}
    },
})
export const { addMessage, removeMessage, addOrUpdateMessage, setSiteNotificationDetails, setIsSiteNotificationsAPICallSuccess, getSiteNotificationDetails } = NotificationDetailsSlice.actions;
export default NotificationDetailsSlice.reducer;
export const MessageDetailsSelector = (state: { NotificationDetails: Notifications }) => state.NotificationDetails;
export const SiteNotificationDetailsSelector = (state: { NotificationDetails: Notifications }) => state.NotificationDetails.siteNotificationDetails;
export const IsSiteNotificationsAPICallSuccessSelector = (state: { NotificationDetails: Notifications }) => state.NotificationDetails.isSiteNotificationsAPICallSuccess;