import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IncidentCategory, IncidentCategoryModel } from "../../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";

const initialState: IncidentCategoryModel = {
    incidentCategories: [],
    selectedIncidentCategory: undefined,
}

const IncidentCategorySlice = createSlice({
    name: "IncidentCategory",
    initialState,
    reducers: {
        getIncidentCategories: () => {

        },
        setIncidentCategories: (state, { payload }: PayloadAction<IncidentCategory[]>) => {
            state.incidentCategories = payload;
        },
        setSelectedIncidentCategory: (state, { payload }: PayloadAction<IncidentCategory | undefined>) => {
            state.selectedIncidentCategory = payload;
        },
    },
})
export const { getIncidentCategories, setIncidentCategories, setSelectedIncidentCategory } = IncidentCategorySlice.actions;
export default IncidentCategorySlice.reducer;
export const IncidentCategorySelector = (state: { IncidentCategory: IncidentCategoryModel }) => state.IncidentCategory