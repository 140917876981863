import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FileObject {
    id: number,
    fileContent: string,
    fileName: string,
    fileSize: number
}
export interface FileState {
    sizeInBytes: number,
    files: FileObject[],
    errorStates: {
        validationError: string,
        fileErrors: string[]
    }
}
const initialState: FileState = {
    sizeInBytes: 0,
    files: [],
    errorStates: {
        validationError: "",
        fileErrors: []
    }
}

const fileSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        setSizeInBytes: (state, { payload }: PayloadAction<number>) => {
            state.sizeInBytes = payload;
        },
        setFiles: (state, { payload }: PayloadAction<FileObject[]>) => {
            state.files = payload;
        },
        clearFiles: (state) => {
            state.files = [];
            state.errorStates.validationError = "";
            state.errorStates.fileErrors = [];
            state.sizeInBytes = 0;
        },
        removeFile: (state, { payload }: PayloadAction<number>) => {
            state.files = state.files.filter(s => s.id != payload);
        },
        setFileValidationErrorState: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.validationError = payload;
        },
        addFileError: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.fileErrors.push(payload);
        },
        clearErrorStates: (state) => {
            state.errorStates.validationError = "";
            state.errorStates.fileErrors = [];
        }
    },
})

export const { setSizeInBytes, setFiles, addFileError, removeFile, clearFiles, setFileValidationErrorState, clearErrorStates } = fileSlice.actions;
export default fileSlice.reducer;
export const fileSelector = (state: { FileUploadDetails: FileState }) =>
    state.FileUploadDetails
