import { ExceptionPageModel } from "../Components/ExceptionPage/exceptionPageSlice";
import { ErrorHandlerOptions } from "../Services/errorHandlerService";
import { getLocalizedValue } from "../Services/localizationService";

export const NOT_FOUND_EXCEPTION_PROPS: ExceptionPageModel = {
  errorPageHeader: getLocalizedValue("RIN_NotFound"),
  errorMessageToDisplay:
    getLocalizedValue("RIN_ThereSourceYouAreTryingToAccess"),
  occupyFullScreen: true,
};

export const UNAUTHORIZED_EXCEPTION_PROPS: ExceptionPageModel = {
  errorPageHeader: getLocalizedValue("RIN_Unauthorized"),
  errorMessageToDisplay:
    getLocalizedValue("RIN_SignedOutError") + " <a href='/signin'>signin</a>.",
  occupyFullScreen: true,
};

export const SOMETHING_WENT_WRONG_EXCEPTION_PROPS: ExceptionPageModel = {
  errorPageHeader: getLocalizedValue("RIN_ErrorPageHeader"),
  errorMessageToDisplay:
  getLocalizedValue("RIN_ErrorMessage_Part1") + " <b><a href='/' aria-label='Refresh page'>" + getLocalizedValue("RIN_ErrorMessage_Part2") + "</a></b> " + getLocalizedValue("RIN_ErrorMessage_Part3") + " <b><a href='https://portal.microsofticm.com/imp/v3/incidents/create' aria-label='IcM'>" + getLocalizedValue("RIN_ErrorMessage_Part4") + "</a></b> "+ getLocalizedValue("RIN_ErrorMessage_Part5") +" <b><a href='mailto: steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now'>steengind@microsoft.com</a></b>",
  occupyFullScreen: true,
};

export const NOT_FOUND_EXCEPTION: ErrorHandlerOptions = {
  showErrorPage: true,
  errorPageHeader: getLocalizedValue("RIN_NotFound"),
  errorMessageToDisplay:
    getLocalizedValue("RIN_ThereSourceYouAreTryingToAccess"),
  occupyFullScreen: false,
};

export const UNAUTHORIZED_EXCEPTION: ErrorHandlerOptions = {
  showErrorPage: true,
  errorPageHeader: getLocalizedValue("RIN_Unauthorized"),
  errorMessageToDisplay:
    getLocalizedValue("RIN_SignedOutError") + " <a style='color: white;' href='/signin'>signin</a>.",
  occupyFullScreen: false,
};

export const SOMETHING_WENT_WRONG_EXCEPTION: ErrorHandlerOptions = {
  showErrorPage: true,
  errorPageHeader: getLocalizedValue("RIN_ErrorPageHeader"),
  errorMessageToDisplay:
  getLocalizedValue("RIN_ErrorMessage_Part1") + " <b><a href='/' aria-label='Refresh page'>" + getLocalizedValue("RIN_ErrorMessage_Part2") + "</a></b> " + getLocalizedValue("RIN_ErrorMessage_Part3") + " <b><a href='https://portal.microsofticm.com/imp/v3/incidents/create' aria-label='IcM'>" + getLocalizedValue("RIN_ErrorMessage_Part4") + "</a></b> "+ getLocalizedValue("RIN_ErrorMessage_Part5") +" <b><a href='mailto: steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now'>steengind@microsoft.com</a></b>",

  occupyFullScreen: false,
};

export const FORBIDDEN_EXCEPTION: ErrorHandlerOptions = {
  showErrorPage: true,
  errorPageHeader: getLocalizedValue("RIN_ActionForbidden"),
  errorMessageToDisplay: getLocalizedValue("RIN_ActionYouAreTryingToPerformIsForbidden"),
  occupyFullScreen: false,
};
