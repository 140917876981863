import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import styles from "./LevelTwoPanel.module.css";
import LinkOutVector from "../../Assets/Vector.svg";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/controls";
import { getLocalizedValue } from "../../Services/localizationService";

export interface L2PanelProps {
    title: string,
    description: string,
    isOpen: boolean,
    dismissPanel: () => void;
    moreInfoUrl: string;
    panelSize: CoherencePanelSize;
}

const buttonStyles = { root: { marginRight: 8 } };

export const L2Panel: React.FC<L2PanelProps> = ({ isOpen, dismissPanel, title, description, panelSize, moreInfoUrl }) => {

    const onRenderFooterContent = React.useCallback(() => (
        <div>
            <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
                {getLocalizedValue("RIN_Close")}
            </PrimaryButton>
        </div>
    ), [dismissPanel]);

    return (
        <CoherencePanel
            titleText={title}
            panelSize={panelSize}
            isOpen={isOpen}
            onLightDismissClick={dismissPanel}
            isLightDismiss={true}
            hasCloseButton={true}
            isFooterAtBottom={true}
            onDismiss={dismissPanel}
            onRenderFooter={onRenderFooterContent}
            closeButtonAriaLabel={'Close panel'}
            backButton={{
                ariaLabel: "Back",
                onClick: dismissPanel
            }}
            focusTrapZoneProps={{
                firstFocusableTarget: () => document.querySelector("button[title = 'Back']")
            }}
        >
            <div>
                <p className={styles.text}>
                    <RichTextRenderer html={description} />
                </p>
                {
                    moreInfoUrl?.length > 0 && (
                        <h3 className={styles.heading3}>
                            <a className={styles.link} href={moreInfoUrl} target="_blank" rel="noopener noreferrer" aria-label="More info open in new window">{getLocalizedValue("RIN_MoreInfo")}</a>
                            <span className={styles.linkOutVector}><img src={LinkOutVector} alt="Link out icon" /></span>
                        </h3>
                    )
                }
            </div>
        </CoherencePanel>
    );
}