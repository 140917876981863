import { Callout, Checkbox, DelayedRender, DirectionalHint, FocusTrapCallout, FocusTrapZone, IconButton, IDropdownOption, Label, Stack, TextField } from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { stackTokens, labelCalloutStackStyles } from "../../../../Constants/CommonConstants";
import {
	DigitalEventTypes,
	SovereignDigitalEventTypes,
	DigitalUnauthorizedAccessL2EventTypes,
	SovereignDigitalUnauthorizedAccessL2EventTypes,
} from "../../../../Contracts/Enums/DigitalIncidentEnums";
import { ExecutiveAliasErrorModel } from "../../../../Contracts/ExceptionModels/ExceptionModel";
import { DsDiFileAttachmentInputViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiFileAttachmentInputViewModel";
import { useBoolean } from "../../../../Hooks/useBoolean";
import {
	DigitalIncidentTypesSelector,
	DigitalIncidentInformationSelector,
	SelectedDigitalIncidentTypeL2Selector,
	ErrorStatesSelector,
	getDigitalIncidentTypes,
	setIncidentDescriptionErrorMessage,
	setDigitalIncidentIncidentDescription,
	setAzureSubscriptionErrorMessage,
	setDigitalIncidentAzureSubscriptionId,
	validateDigitalDataV2,
	setDigitalIncidentExecutiveAlias,
	setDigitalIncidentIsExecSupport,
	setValidationErrorForExecutiveAlias,
	setDigitalIncidentAttachedFiles,
	setTotalAttachmentSizeInKB,
} from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { SimulationCampaignSelector } from "../../../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { clearErrorStates, fileSelector, setFileValidationErrorState } from "../../../FileUpload/FileUploadSlice";
import RichTextRenderer from "../../../RichTextRenderer/RichTextRenderer";
import {
	isSovereignIncidentSelector,
	sovereignSelectedIncidentTypeL1Selector,
	sovereignSelectedIncidentTypeL2Selector,
} from "../../../Sovereign/SovereignSlice";
import { DefaultButtonAdapter, PrimaryButtonAdapter } from "../../Controls";
import FileUploadv2 from "../../FileUploadv2/FileUploadv2";
import IncidentSpecificFieldsv2, { CustomLabel } from "../IncidentSpecificFieldsv2/IncidentSpecificFieldsv2";
import DigitalLocationAndTimev2 from "../LocationAndTimev2/LocationAndTimev2";
import OutlookReportingInstructions from "./OutlookReportingInstructions";
import path from "path";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { renderDescriptionLabel } from "../../../Home/Home";
import { getLocalizedValue } from "../../../../Services/localizationService";
import { INCIDENT_DETAILS_STEP } from "../../../../Constants/InstrumentationConstants";
import { trackEvent } from "../../../../Services/telemetryService";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../../UserProfile/ServiceCommunicationSettingsSlice";
import { IncidentCategorySelector } from "../../../IncidentCategory/IncidentCategorySlice";

export function DigitalIncidentDetailsv2() {
	const dispatch = useDispatch();
	const isGovIncident = useSelector(isSovereignIncidentSelector);
	const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
	const selectedEventTypeL1 = digitalIncidentTypesDetails.selectedIncidentTypeL1;
	let selectedEventTypeId = selectedEventTypeL1?.typeId;
	const digitalIncidentDetails = useSelector(DigitalIncidentInformationSelector);
	const selectedL2IncidentType = useSelector(SelectedDigitalIncidentTypeL2Selector);
	const errorStatesDetails = useSelector(ErrorStatesSelector);
	const sovereignIncidentTypeL1 = useSelector(sovereignSelectedIncidentTypeL1Selector);
	const sovereignIncidentTypeL2 = useSelector(sovereignSelectedIncidentTypeL2Selector);
	const isPhishingEmailIncident =
		selectedEventTypeL1?.typeId === DigitalEventTypes.PhishingEMail || selectedEventTypeL1?.typeId === SovereignDigitalEventTypes.PhishingEMail;
	const isCallOutPresent =
		(selectedEventTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess &&
			selectedL2IncidentType !== undefined &&
			selectedL2IncidentType.typeId === DigitalUnauthorizedAccessL2EventTypes.UnknownDevice) ||
		(selectedEventTypeL1?.typeId === DigitalEventTypes.GovRelated &&
			sovereignIncidentTypeL1?.key === SovereignDigitalEventTypes.UnauthorizedAccess &&
			sovereignIncidentTypeL2?.key === SovereignDigitalUnauthorizedAccessL2EventTypes.UnknownDevice);
	const description = <FieldValidationDescription errorMessage={errorStatesDetails.incidentDescription} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;

	const azureSubscriptionDescription = (
		<FieldValidationDescription errorMessage={errorStatesDetails.azureSubscription} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />
	);

	const date = new Date(new Date().toDateString());
	const simulationCampaign = useSelector(SimulationCampaignSelector).simulationCampaign;
	const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find((x) => x.typeId === DigitalEventTypes.PhishingEMail);
	const phishingSimulation = simulationCampaign;
	const [isPhishingSimulation, setIsPhishingSimulation] = React.useState(
		phishingIncidentType !== undefined && (phishingSimulation !== undefined && phishingSimulation !== null)
			? phishingIncidentType.isSimulationEnabled &&
			phishingSimulation.isActive &&
			date <= new Date(phishingSimulation.endDate.toDateString()) &&
			date >= new Date(phishingSimulation.startDate.toDateString())
			: false
	);
	React.useEffect(() => {
		setIsPhishingSimulation(
			phishingIncidentType !== undefined && phishingSimulation !== undefined && phishingSimulation !== null
				? phishingIncidentType.isSimulationEnabled &&
				phishingSimulation.isActive &&
				date <= new Date(phishingSimulation.endDate.toDateString()) &&
				date >= new Date(phishingSimulation.startDate.toDateString())
				: false
		);
	}, [phishingSimulation]);
	const [isPhishingSimulationExperienceShown] = useBoolean(isPhishingSimulation && selectedEventTypeId === DigitalEventTypes.PhishingEMail);

	React.useEffect(() => {
		if (selectedEventTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess) {
			dispatch(getDigitalIncidentTypes({ level: 2, parentIncidentTypeId: DigitalEventTypes.UnauthorizedAccess }));
		}
	}, [selectedEventTypeL1]);

	const labelId: string = "incident-summary";
	const azureSubscriptionLabelId: string = "azure-subscription";

	const handleDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		if (newValue !== undefined) {
			dispatch(setIncidentDescriptionErrorMessage(""));
			dispatch(setDigitalIncidentIncidentDescription(newValue));
		}
	};

	const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
	const toggleIsCalloutVisible = () => setIsCalloutVisible(!isCalloutVisible);

	const handleAzureSubscriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		if (newValue !== undefined) {
			dispatch(setAzureSubscriptionErrorMessage(""));
			dispatch(setDigitalIncidentAzureSubscriptionId(newValue));
		}
	};

	const azureSubscriptionId_CalloputProps = {
		id: azureSubscriptionLabelId,
		calloutDescription:
			`<div>
				<div>
					<b>${getLocalizedValueV2("RIN_AzureSubscriptionInfo1")}</b>
				</div>
				<ol class='orderedList' style='list-style:decimal;text-align:left;padding-left: 12px;'>
					<li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AzureSubscriptionInfo2")}</li>
					<li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AzureSubscriptionInfo3")}</li>
					<li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AzureSubscriptionInfo4")}</li>
					<li>${getLocalizedValueV2("RIN_AzureSubscriptionInfo5")}</li>
				</ol>
			</div>`,
		moreInfoLink: undefined,
	};

	const attachmentCalloutProps = {
		id: "attachment-info",
		calloutDescription: "<b>Note: </b>Attach the device enrollment/registration email you received (if any).",
		moreInfoLink: undefined,
		labelStyle: { fontWeight: 400 },
	};
	const iconButtonId: string = attachmentCalloutProps.id + "-iconButton";

	const [incidentTypeOptions, setIncidentTypeOptions] = React.useState<IDropdownOption[]>([]);

	const iconProps = { iconName: "Unknown" };
	const callOutComponent = (
		<IconButton
			id={iconButtonId}
			iconProps={iconProps}
			title={getLocalizedValueV2("RIN_SupportingAttachments") + (isPhishingEmailIncident ? "" : " - " + getLocalizedValueV2("RIN_Optional"))}
			ariaLabel={getLocalizedValueV2("RIN_SupportingAttachments") + (isPhishingEmailIncident ? "" : " - " + getLocalizedValueV2("RIN_Optional")) + getLocalizedValueV2("RIN_Help_LowerCase")}
			onClick={toggleIsCalloutVisible}
			className="iconButton"
			styles={{ root: { height: "fit-content", marginTop: "4px" } }}
		/>
	);

	React.useEffect(() => {
		if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
			var incidentTypes: IDropdownOption[] = [];
			if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
				digitalIncidentTypesDetails.incidentTypesL1.forEach((incidentType) => {
					incidentTypes.push({
						key: incidentType.typeId,
						text: incidentType.name,
					});
				});
				setIncidentTypeOptions(incidentTypes);
			}
		}
	}, [digitalIncidentTypesDetails]);
	const navigate = useNavigate();

	const executiveAliasDescription = (
		<FieldValidationDescription errorMessage={errorStatesDetails.executiveAlias} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />
	);

	const handleExecutiveAliasChange = (newValue: string) => {
		let executiveAliasError: ExecutiveAliasErrorModel = { ErrorMessage: "", IsErrorOnSubmission: false };
		dispatch(setValidationErrorForExecutiveAlias(executiveAliasError));
		dispatch(setDigitalIncidentExecutiveAlias(newValue));
	};

	const requiresExecSupportCalloutProps = {
		id: "requires-exec-support-info",
		calloutDescription: getLocalizedValueV2("RIN_ExecutiveSupportHelpDesc"),
		moreInfoLink: undefined,
		labelStyle: { fontWeight: 400 },
	};
	const descriptionId: string = requiresExecSupportCalloutProps.id + "-description";
	const fileDetails = useSelector(fileSelector);
	const setDigitalIncidentFileState = () =>
		new Promise((resolve: any) => {
			var fileObjects: DsDiFileAttachmentInputViewModel[] = [];
			var totalSize = 0;

			if (selectedEventTypeId == DigitalEventTypes.PhishingEMail && (!fileDetails?.files || fileDetails?.files?.length == 0)) {
				dispatch(setFileValidationErrorState(getLocalizedValueV2("RIN_PhishingEmailError")));
			}

			const size = fileDetails?.files?.reduce((p, c, i) => p + c.fileSize, 0) ?? 0;
			if (size > 2.8 * 1024 * 1024) {
				dispatch(setFileValidationErrorState(getLocalizedValueV2("RIN_TotalAttachmentSizeExceeded")));
			}

			fileDetails.files.forEach((file) => {
				fileObjects.push({
					fileContent: file.fileContent,
					fileSizeInKB: file.fileSize / 1024,
					extension: path.extname(file.fileName),
					name: file.fileName,
				});
				totalSize += file.fileSize;
			});
			dispatch(setTotalAttachmentSizeInKB(totalSize / 1024));
			dispatch(setDigitalIncidentAttachedFiles(fileObjects));
			resolve();
		});

		const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
		const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
		const incidentCategoryDetails = useSelector(IncidentCategorySelector);

		React.useEffect(()=>{
			trackEvent({name: INCIDENT_DETAILS_STEP}, {
				incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
				step: INCIDENT_DETAILS_STEP,
				incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
				incidentType: selectedEventTypeL1?.name,
				timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
			});
		}, []);

	return (
		<>
			<div>
				{!isGovIncident && (
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Checkbox
							aria-labelledby={labelId}
							label={getLocalizedValueV2("RIN_ExecutiveSupport")}
							checked={digitalIncidentDetails.isExecSupport}
							onChange={() => {
								dispatch(setDigitalIncidentIsExecSupport(!digitalIncidentDetails.isExecSupport));
								if (!digitalIncidentDetails.isExecSupport) dispatch(setDigitalIncidentExecutiveAlias(null));
							}}
						/>
						<IconButton
							id={iconButtonId}
							iconProps={iconProps}
							title={getLocalizedValueV2("RIN_ExecutiveSupportHelp")}
							ariaLabel={getLocalizedValueV2("RIN_ExecutiveSupportHelp")}
							onClick={toggleIsCalloutVisible}
							className="iconButton"
							styles={{ root: { height: "fit-content" } }}
						/>
						{isCalloutVisible && (
							<FocusTrapCallout
								directionalHint={DirectionalHint.topAutoEdge}
								target={"#" + iconButtonId}
								setInitialFocus
								onDismiss={toggleIsCalloutVisible}
								ariaDescribedBy={descriptionId}
								role="alertdialog"
								aria-live="assertive"
							>
								<DelayedRender>
									<FocusTrapZone>
										<Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
											<span>
												<RichTextRenderer html={requiresExecSupportCalloutProps.calloutDescription} />
											</span>
										</Stack>
									</FocusTrapZone>
								</DelayedRender>
							</FocusTrapCallout>
						)}
					</div>
				)}
				{digitalIncidentDetails.isExecSupport && !isGovIncident && (
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
							<TextField
								required
								type="email"
								ariaLabel={getLocalizedValueV2("RIN_ExecutiveAlias")}
								id="executiveAlias"
								label={getLocalizedValueV2("RIN_ExecutiveAlias")}
								onChange={(event, newValue) => {
									handleExecutiveAliasChange(newValue ?? "");
								}}
								value={digitalIncidentDetails.executiveAlias ?? undefined}
								onRenderDescription={() => executiveAliasDescription}
								placeholder={getLocalizedValueV2("RIN_AddAliasOfAssociatedExecutiveUser")}
								styles={
									errorStatesDetails.executiveAlias.length > 0
										? {
											field: {
												backgroundColor: "#FED9CC",
											},
										}
										: {
											field: {
												backgroundColor: "white",
											},
										}
								}
							/>
						</div>
					</div>
				)}
			</div>
			{isPhishingEmailIncident && <OutlookReportingInstructions />}
			<DigitalLocationAndTimev2 />
			<div className="ms-Grid-row">
				<h2 className="sectionHeading">{getLocalizedValueV2("RIN_IncidentInformation")}</h2>
			</div>
			<div className="incident-specific-fields">
				<IncidentSpecificFieldsv2 incidentTypeId={selectedEventTypeId} />
			</div>
			{selectedEventTypeId !== DigitalEventTypes.PhishingEMail &&
				selectedEventTypeId !== SovereignDigitalEventTypes.PhishingEMail &&
				selectedEventTypeId !== DigitalEventTypes.PhishingCallOrText &&
				selectedEventTypeId !== SovereignDigitalEventTypes.PhishingCallOrText && (
					<div className="azureSubscription" style={{ marginBottom: "28px", marginTop: "28px" }}>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
								<CustomLabel
									id={azureSubscriptionId_CalloputProps.id}
									label={getLocalizedValueV2("RIN_RelatedToAnAzureSubscriptionOptional")}
									calloutDescription={azureSubscriptionId_CalloputProps.calloutDescription}
								/>
							</div>
						</div>
						<TextField
							id="digitalIncidentAzureSubscriptionId"
							aria-required={false}
							aria-labelledby={azureSubscriptionId_CalloputProps.id + "label"}
							placeholder={getLocalizedValueV2("RIN_RelatedToAnAzureSubscriptionOptionalPlaceholder")}
							onRenderDescription={() => azureSubscriptionDescription}
							value={digitalIncidentDetails.azureSubscriptionId ?? ""}
							onChange={handleAzureSubscriptionChange}
							styles={
								errorStatesDetails.azureSubscription.length > 0
									? {
										field: {
											backgroundColor: "#FED9CC",
										},
									}
									: {
										field: {
											backgroundColor: "white",
										},
									}
							}
						/>
					</div>
				)}
			<div id="incident-summary" style={{ marginBottom: "28px" }}>
				{
					isGovIncident &&
					(
						<div className="ms-Grid-row" style={{marginBottom: 16}}>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
								<strong>{getLocalizedValueV2("RIN_Disclaimer_Caps")}</strong>{getLocalizedValueV2("RIN_CUIDisclaimer")}
							</div>
						</div>
					)
				}
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<Label><span className="required-field-label">{getLocalizedValueV2("RIN_IncidentSummary")}</span></Label>
					</div>
				</div>
				<TextField
					id="digitalIncidentSummaryId"
					required
					aria-required={true}
					label={getLocalizedValueV2("RIN_IncidentSummaryDesc")}
					placeholder={getLocalizedValueV2("RIN_IncidentSummaryPlaceholder")}
					multiline
					rows={6}
					onRenderLabel={(props) =>
						renderDescriptionLabel(props, "digitalIncidentSummaryLabel")
					}
					onRenderDescription={() => description}
					value={digitalIncidentDetails.incidentDescription}
					onChange={handleDescriptionChange}
					styles={
						errorStatesDetails.incidentDescription.length > 0
							? {
								field: {
									backgroundColor: "#FED9CC",
								},
							}
							: {
								field: {
									backgroundColor: "white",
								},
							}
					}
				/>
			</div>
			<FileUploadv2
				allowdSizePerFileInMB={2.8}
				totalAllowedSizeInMB={2.8}
				isDigitalIncType={true}
				allowedFileTypes={[".msg", ".eml", ".mp3", ".png", ".jpg", ".jpeg", ".pdf", ".zip"]}
				allowedFileNameLength={200}
				isPhishingEmailIncident={isPhishingEmailIncident}
				showCallOut={isCallOutPresent}
				callOutComponent={isCallOutPresent === true ? callOutComponent : undefined}
			/>
			<div className="ms-Grid-row" style={{ marginBottom: 16 }}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{ display: "flex", gap: 12 }}>
					<DefaultButtonAdapter onClick={() => navigate("/v2/create/digital")}>{getLocalizedValueV2("RIN_Back")}</DefaultButtonAdapter>{" "}
					<PrimaryButtonAdapter
						onClick={() => {
							dispatch(clearErrorStates());
							setDigitalIncidentFileState().then(() => {
								dispatch(validateDigitalDataV2(navigate));
							});
						}}
					>
						{getLocalizedValueV2("RIN_Next")}
					</PrimaryButtonAdapter>
				</div>
			</div>
		</>
	);
}
