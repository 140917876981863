import * as React from "react";
import styles from "./ProfilePanel.module.css";
import LinkOutVector from "../../../Assets/Vector.svg";
import { useDispatch, useSelector } from "react-redux";
import { profileSections } from "../../../Constants/ApiFailureConstants";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import { ProfileSections } from "../../../Contracts/Enums/ProfileSectionEnums";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { HelpSectionSelector } from "../../HelpPanel/HelpSectionSlice";
import { NewExperienceSelection } from "../../Welcome/Welcome";
import { useNavigate } from "react-router-dom";
import { CustomAccordion } from "../Controls/CustomAccordion/CustomAccordion";

export interface ProfilePanelProps {
    dismissProfilePanel: () => void;
}

export const ProfilePanelv2: React.FC<ProfilePanelProps> = ({
    dismissProfilePanel,
}) => {
    const navigate = useNavigate();
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const aboutHelpSections = helpSectionDetails.helpSections.filter(s => s.panelId === PanelEnums.Profile);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;

    React.useEffect(() => {
        const closeButton = document.querySelector("button.ms-Panel-closeButton.ms-PanelAction-close") as HTMLButtonElement;
        const clickHandler = (event: MouseEvent) => {
            dismissProfilePanel();
            event.preventDefault();
        }
        closeButton?.addEventListener("click", clickHandler);
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter" || event.key === "Space") {
                dismissProfilePanel();
                event.preventDefault();
            }
        }
        closeButton.addEventListener("keydown", handleKeyDown);
        return () => {
            closeButton?.removeEventListener("click", clickHandler);
            closeButton?.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <div>
            <NewExperienceSelection />
            <div>
                {isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess && aboutHelpSections.length > 0 && aboutHelpSections.map((helpSection) => (
                    <>
                        <h2 className={styles.heading2}>{getLocalizedValueV2(helpSection.titleKey)}</h2>
                        {helpSectionDetails.helpSectionLinks
                            .filter((s) => s.sectionId === helpSection.sectionId)
                            .map((link) => link.openAsForm ?
                                <>
                                    {link.linkId == ProfileSections.SetPhishingCampaign &&
                                        <div className={styles.heading3}>
                                            <a role="button" className={styles.link} href='' style={{ cursor: "pointer" }} onClick={() => { navigate("/v2/phishingCampaign"); dismissProfilePanel(); }} aria-label={getLocalizedValueV2(link.titleKey)}>{getLocalizedValueV2(link.titleKey)}</a>
                                        </div>
                                    }
                                </> : (
                                    <div className={styles.heading3}>
                                        {!link.openInNewTab ? (
                                            <CustomAccordion link={link} />
                                        ) : (
                                            <div>
                                                <a
                                                    className={styles.link}
                                                    href={link.moreInfoUrl}
                                                    aria-label={getLocalizedValueV2(link.titleKey) + ' '+ getLocalizedValueV2("RIN_OpenInNewWindow")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {getLocalizedValueV2(link.titleKey)}
                                                </a>
                                                <span aria-hidden={true} className={styles.linkOutVector}>
                                                    <img
                                                        src={LinkOutVector}
                                                        alt={getLocalizedValueV2("RIN_LinkOutIcon")}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                        <div className={styles.divider}></div>
                    </>
                ))}
                {(!isHelpSectionApiCallSuccess || !isHelpSectionLinksApiCallSuccess || aboutHelpSections.length === 0) && (
                    <>
                        {profileSections.map((section) => (
                            <>
                                <h2 className={styles.heading2}>{getLocalizedValueV2(section.title)}</h2>
                                {section.links.map((link) => (
                                    <div className={styles.heading3}>
                                        {link.openInNewTab && (
                                            <div>
                                                <a
                                                    className={styles.link}
                                                    href={link.url}
                                                    aria-label={getLocalizedValueV2(link.title) + ' '+ getLocalizedValueV2("RIN_OpenInNewWindow")}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {link.title}
                                                </a>
                                                <span aria-hidden={true} className={styles.linkOutVector}>
                                                    <img
                                                        src={LinkOutVector}
                                                        alt={getLocalizedValueV2("RIN_LinkOutIcon")}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className={styles.divider}></div>
                            </>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}
