import { ChoiceGroup, IChoiceGroupOption, Label, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsUnauthorizedSuspiciousLoginLocalizedViewModel, DsUnauthorizedSuspiciousLoginViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { ErrorStatesSelector, UnauthorizedAccessOrHackSelector, setUnauthorizedAccessOrHackObject, setUnauthorizedSuspiciousLoginHostNameErrorMessage, setUnauthorizedSuspiciousLoginHostName, setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount, LocalizedUnauthorizedAccessSuspiciousLoginSelector, setLocalizedUnauthorizedAccessOrHackObject } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { CustomLabel } from "./IncidentSpecificFieldsv2";

const RelatedToWorkOrSchoolAccountOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_WorkAccount"), value: "Work account" },
    { key: "2", text: getLocalizedValueV2("RIN_PersonalAccount"), value: "Personal account" },
];


export default function UnauthorizedAccessSuspiciousLogin() {
    const dispatch = useDispatch();
    const hostName_CalloputProps = {
        id: "suspiciouslogin-host-name-info",
        calloutDescription: "<div><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo1") + "</b></div><br><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo2") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo3") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo4") + " <b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo5") + "</b> " + getLocalizedValueV2("RIN_AffectedMachineNameInfo6") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo7") + "</li></ol><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo8") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo9") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo10") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo11") + "</li></ol><div><b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo12") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo13") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo14") + " <b>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo5") + "</b> " + getLocalizedValueV2("RIN_AffectedMachineNameInfo15") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValueV2("RIN_AffectedMachineNameInfo7") + "</li></ol></div>",
        moreInfoLink: undefined,
    };
    const [
        accountOption,
        setAccountOption,
    ] = React.useState<IChoiceGroupOption>();

    const choiceGroupChoicesWidth: number = 272;
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const unauthorizedAccessDetails = useSelector(UnauthorizedAccessOrHackSelector);
    const [suspiciousLoginObject, setSuspiciousLoginObject] = React.useState<DsUnauthorizedSuspiciousLoginViewModel>({
        hostName: "",
        relatedToWorkOrSchoolAccount: undefined
    });

    const defaultLocalizedSuspiciousLoginObject: DsUnauthorizedSuspiciousLoginLocalizedViewModel = {
        hostName: "",
        relatedToWorkOrSchoolAccount: undefined
    }
    const localizedSuspiciousLoginObject: DsUnauthorizedSuspiciousLoginLocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessSuspiciousLoginSelector);

    useEffect(() => {
        if(unauthorizedAccessDetails?.suspiciousLogin) return;
        dispatch(setUnauthorizedAccessOrHackObject({ suspiciousLogin: suspiciousLoginObject }));
        dispatch(setLocalizedUnauthorizedAccessOrHackObject({ suspiciousLogin: { ...localizedSuspiciousLoginObject, ...defaultLocalizedSuspiciousLoginObject } }));
    }, [])

    const handleHostNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedSuspiciousLoginHostName !== "") {
                dispatch(setUnauthorizedSuspiciousLoginHostNameErrorMessage(""));
            }
            dispatch(setUnauthorizedSuspiciousLoginHostName(newValue));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({suspiciousLogin:{...localizedSuspiciousLoginObject,hostName:newValue}}));

        }
    };

    const handleAccountChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setAccountOption(option);
            dispatch(setUnauthorizedSuspiciousLoginRelatedToWorkOrSchoolAccount((typeof(option.value)==="string" ? option.value??"" :"" )));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({suspiciousLogin:{...localizedSuspiciousLoginObject,relatedToWorkOrSchoolAccount:option.text} as DsUnauthorizedSuspiciousLoginLocalizedViewModel}));

        }
    };

    const hostNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.unauthorizedSuspiciousLoginHostName} descriptionColor={'red'} iconName={"ErrorBadge"} />

    return (
        <><div className="ms-Grid-row" style={{ marginBottom: '20px' }}>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                <TextField
                    id="suspiciousLoginHostNameId"
                    required
                    aria-required={true}
                    ariaLabel={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                    aria-labelledby={hostName_CalloputProps.id}
                    label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                    // eslint-disable-next-line react/jsx-no-bind
                    onRenderLabel={(props) => <CustomLabel {...hostName_CalloputProps} {...props} />}
                    value={unauthorizedAccessDetails?.suspiciousLogin?.hostName}
                    onChange={handleHostNameChange}
                    onRenderDescription={() => hostNameDescription}
                    styles={
                        errorStatesDetails.unauthorizedSuspiciousLoginHostName.length > 0 ? {
                            field: {
                                backgroundColor: "#FED9CC"
                            }
                        } :
                            {
                                field: {
                                    backgroundColor: "white"
                                }
                            }
                    }
                />
            </div>
        </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={RelatedToWorkOrSchoolAccountOptions}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion2")}
                        selectedKey={unauthorizedAccessDetails?.suspiciousLogin?.relatedToWorkOrSchoolAccount == undefined ? undefined : (RelatedToWorkOrSchoolAccountOptions.find(x=>x.value === unauthorizedAccessDetails?.suspiciousLogin?.relatedToWorkOrSchoolAccount)?.key)}
                        onChange={handleAccountChange}
                        styles={getRadioButtonStyle(choiceGroupChoicesWidth)}
                    />
                </div>
            </div>
        </>
    );
}