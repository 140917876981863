import { stringToBoolOrDefault } from "@microsoft/applicationinsights-common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DsDiSimulationCampaignResponseViewModel } from "../../../Contracts/TypesAndInterfaces/DsDiSimulationCampaignResponseViewModel";
import { DsSimulationCampaignInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsSimulationCampaignInputViewModel";
import { SimulationCampaignModel } from "../../../Contracts/TypesAndInterfaces/SimulationCampaignModel";
import { getLocalizedValue } from "../../../Services/localizationService";

const initialState: SimulationCampaignModel = {
    simulationCampaign: null,
    uploadedSimulationCampaign: null,
    previousSimulationCampaign: null,
    submissionStatus: false,
    errorStates: {
        startDate: "",
        endDate: "",
        fileSize: "",
        fileType: "",
        senderName:"",
        emailBody:"",
        emailSubject:"",
        outlookWarningMessage:"",
        senderEmail:""
    },
    isUploadedSimulationCampaignValid: false,
    isUploadedSimulationCampaignSubmitted: false,
    apiStatus: { statusCode: 200, statusReceived: true}
}

const SimulationCampaignSlice = createSlice({
    name: "SimulationCampaign",
    initialState,
    reducers: {
        setSimulationCampaigns: (state, { payload }: PayloadAction<DsDiSimulationCampaignResponseViewModel>) => {
            state.simulationCampaign = payload;
        },
        setIsUploadedSimulationCampaignSubmitted: (state, { payload }: PayloadAction<boolean>) => {
            state.isUploadedSimulationCampaignSubmitted = payload;
        },
        getSimulationCampaigns: () => {

        },
        setSubmissionStatus: (state, action: PayloadAction<boolean>) => {
            state.submissionStatus = action.payload;
        },
        setUploadedSimulationCampaign: (state, { payload }: PayloadAction<DsSimulationCampaignInputViewModel | null>) => {
            state.uploadedSimulationCampaign = payload;
        },
        submitUploadedSimulationCampaign: () => {
            
        },
        setStartDateErrorState: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.startDate = payload;
        },
        setEndDateErrorState: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.endDate = payload;
        },
        setFileSizeErrorState: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.fileSize = payload;
        },
        setFileTypeErrorState: (state, { payload }: PayloadAction<string>) => {
            state.errorStates.fileType = payload;
        },
        resetErrorStates: (state) => {
            state.errorStates.startDate = "";
            state.errorStates.endDate = "";
            state.errorStates.fileSize = "";
            state.errorStates.fileType = "";
            state.errorStates.emailBody="";
            state.errorStates.emailSubject="";
            state.errorStates.senderEmail="";
            state.errorStates.senderName="";
            state.errorStates.outlookWarningMessage="";

        },
        validateSimulationCampaign: (state, { payload }: PayloadAction<number>) => {
            if (state.uploadedSimulationCampaign != null) {
                let currentDate = new Date().toDateString();
                let valid = true;
                if (state.previousSimulationCampaign === null) {
                    if (new Date(state.uploadedSimulationCampaign.startDate.toDateString()) < new Date(currentDate)) {
                        valid = false;
                        state.errorStates.startDate = getLocalizedValue("RIN_StartDateCannotBeInThePast");
                    }

                    if (new Date(state.uploadedSimulationCampaign.endDate.toDateString()) < new Date(currentDate)) {
                        valid = false;
                        state.errorStates.endDate = getLocalizedValue("RIN_EndDateCannotBeInThePast");
                    }

                    if (state.uploadedSimulationCampaign.attachment.fileContent.trim().length === 0) {
                        valid = false;
                        state.errorStates.fileSize = getLocalizedValue("RIN_AttachmentCannotBeEmpty");
                    }
                }
                else {
                    if (new Date(currentDate) > new Date(state.previousSimulationCampaign.endDate.toDateString())) {
                        if (new Date(state.uploadedSimulationCampaign.startDate.toDateString()) < new Date(currentDate)) {
                            valid = false;
                            state.errorStates.startDate = getLocalizedValue("RIN_StartDateCannotBeInThePast");
                        }

                        if (new Date(state.uploadedSimulationCampaign.endDate.toDateString()) < new Date(currentDate)) {
                            valid = false;
                            state.errorStates.endDate = getLocalizedValue("RIN_EndDateCannotBeInThePast");
                        }
                    }
                    else {
                        if (new Date(state.previousSimulationCampaign.startDate.toDateString()).getTime() !== new Date(state.uploadedSimulationCampaign.startDate.toDateString()).getTime() && new Date(state.uploadedSimulationCampaign.startDate.toDateString()) < new Date(currentDate)) {
                            valid = false;
                            state.errorStates.startDate = getLocalizedValue("RIN_StartDateCannotBeInThePast");
                        }

                        if (new Date(state.previousSimulationCampaign.endDate.toDateString()).getTime() !== new Date(state.uploadedSimulationCampaign.endDate.toDateString()).getTime() && new Date(state.uploadedSimulationCampaign.endDate.toDateString()) < new Date(currentDate)) {
                            valid = false;
                            state.errorStates.endDate = getLocalizedValue("RIN_EndDateCannotBeInThePast");
                        }
                    }
                }

                if (new Date(state.uploadedSimulationCampaign.endDate.toDateString()) < new Date(state.uploadedSimulationCampaign.startDate.toDateString())) {
                    valid = false;
                    state.errorStates.endDate = getLocalizedValue("RIN_EndDateCannotBeBeforeTheStartDate");
                }

                if (state.uploadedSimulationCampaign.attachment.fileSizeInKB / 1024 > payload) {
                    valid = false;
                    state.errorStates.fileSize = getLocalizedValue("RIN_AttachmentSizeLimitExceeded");
                }

                if (state.uploadedSimulationCampaign.attachment.extension.toLowerCase() != ".png" && state.uploadedSimulationCampaign.attachment.extension.toLowerCase() != ".jpeg" && state.uploadedSimulationCampaign.attachment.extension.toLowerCase() != ".jpg") {
                    valid = false;
                    state.errorStates.fileType = getLocalizedValue("RIN_AttachmentShouldBeAnImage");
                }

                let senderNameLength:number = state.uploadedSimulationCampaign.phishingSimulationDetails?.senderName?.trim()?.length;
                let emailSubjectLength:number = state.uploadedSimulationCampaign.phishingSimulationDetails?.emailSubject?.trim()?.length;
                let emailBodyLength:number = state.uploadedSimulationCampaign.phishingSimulationDetails?.emailBody?.trim()?.length;
                let outlookWarningMsgLength:number = state.uploadedSimulationCampaign.phishingSimulationDetails?.outlookWarningMessage?.trim()?.length;
                let senderEmailLength:number = state.uploadedSimulationCampaign.phishingSimulationDetails?.senderEmail?.trim()?.length;

                if(senderNameLength === 0)
                {
                    valid = false;
                    state.errorStates.senderName = getLocalizedValue("RIN_SenderNameIsRequired");
                }
                else if(senderNameLength > 200)
                {
                    valid = false;
                    state.errorStates.senderName = getLocalizedValue("RIN_SenderNameCannotBeLongerThan");
                }

                if(emailSubjectLength === 0)
                {
                    valid = false;
                    state.errorStates.emailSubject = getLocalizedValue("RIN_EmailSubjectIsRequired");
                }
                else if(emailSubjectLength > 200)
                {
                    valid = false;
                    state.errorStates.emailSubject = getLocalizedValue("RIN_EmailSubjectCannotBeLonger");
                }
                if(emailBodyLength === 0)
                {
                    valid = false;
                    state.errorStates.emailBody = getLocalizedValue("RIN_EmailBodyIsRequired");
                }
                else if(emailBodyLength > 500)
                {
                    valid = false;
                    state.errorStates.emailBody = getLocalizedValue("RIN_EmailBodyCannotBeLongerThan");
                }
                if(outlookWarningMsgLength > 300)
                {
                    valid = false;
                    state.errorStates.outlookWarningMessage = getLocalizedValue("RIN_OutlookWarningMessageCannotBeLonger");
                }
                if(senderEmailLength > 200)
                {
                    valid = false;
                    state.errorStates.senderEmail = getLocalizedValue("RIN_SenderEmailCannotBeLonger");
                }


                state.isUploadedSimulationCampaignValid = valid;
                if (valid) {
                    const dateNow = new Date();
                    var startDate = new Date(state.uploadedSimulationCampaign!.startDate.setHours(dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds(), dateNow.getMilliseconds()));
                    state.uploadedSimulationCampaign!.startDate = new Date(startDate.getTime());
                    var endDate = new Date(state.uploadedSimulationCampaign!.endDate.setHours(dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds(), dateNow.getMilliseconds()));
                    state.uploadedSimulationCampaign!.endDate = new Date(endDate.getTime());
                    state.uploadedSimulationCampaign.incidentTimeZoneOffset = new Date().getTimezoneOffset();
                }
            }
        },
        setSimulationStartDateTime: (state, { payload }: PayloadAction<Date>) => {
            if (state.uploadedSimulationCampaign != null) {
                state.uploadedSimulationCampaign.startDate = payload;
            }
        },
        setSimulationEndDateTime: (state, { payload }: PayloadAction<Date>) => {
            if (state.uploadedSimulationCampaign != null) {
                state.uploadedSimulationCampaign.endDate = payload;
            }
        },
        setPreviousSimulationCampaign: (state, { payload }: PayloadAction<DsSimulationCampaignInputViewModel | null>) => {
            state.previousSimulationCampaign = payload;
        },
        setIsUploadedSimulationCampaignValid: (state, { payload }: PayloadAction<boolean>) => {
            state.isUploadedSimulationCampaignValid = payload;
        },
        setApiStatus: (state, { payload }: PayloadAction<{ statusCode: number | undefined, statusReceived: boolean }>) => {
            state.apiStatus = payload;
        },
    },
})
export const {
    setSimulationEndDateTime,
    setSimulationStartDateTime,
    setApiStatus,
    setSimulationCampaigns,
    getSimulationCampaigns,
    setSubmissionStatus,
    setEndDateErrorState,
    setStartDateErrorState,
    setUploadedSimulationCampaign,
    submitUploadedSimulationCampaign,
    validateSimulationCampaign,
    setPreviousSimulationCampaign,
    setFileSizeErrorState,
    setIsUploadedSimulationCampaignSubmitted,
    setFileTypeErrorState,
    setIsUploadedSimulationCampaignValid,
    resetErrorStates } = SimulationCampaignSlice.actions;
export default SimulationCampaignSlice.reducer;
export const SimulationCampaignSelector = (state: { SimulationCampaign: SimulationCampaignModel }) => state.SimulationCampaign;