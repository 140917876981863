import { put, select, takeEvery } from "redux-saga/effects";
import { setIsLoading } from "../Components/Loader/LoaderSlice";
import {
  getSiteNotificationDetails,
  setIsSiteNotificationsAPICallSuccess,
  setSiteNotificationDetails,
} from "../Components/Notifications/NotificationsSlice";
import { serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE } from "../Constants/CommonConstants";
import { FetchSiteNotifications } from "../Constants/ErrorCodes";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { SiteNotificationResponseViewModel } from "../Contracts/TypesAndInterfaces/SiteNotificationResponseViewModel";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError } from "../Services/errorHandlerService";
import { GET } from "../Services/httpService";
import { GetExceptionModel } from "../Services/telemetryService";

export function* FetchSiteNotificationsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    let accessToken: string = yield fetchAPIAccessToken();
    yield put(setIsLoading(true));
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/getsitenotifications",
      accessToken
    );
    yield put(
      setSiteNotificationDetails(
        apiResponse?.responseObject == null ||
          apiResponse?.responseObject === undefined
          ? []
          : (apiResponse?.responseObject as SiteNotificationResponseViewModel[])
      )
    );
    if (!apiResponse?.responseSuccess) {
      yield put(setIsSiteNotificationsAPICallSuccess(false));
    } else yield put(setIsSiteNotificationsAPICallSuccess(true));
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsSiteNotificationsAPICallSuccess(false));
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchSiteNotifications,
      SOURCE,
      "GetHelpSectionLinks",
      "Fetch help section links",
      "FetchHelpSectionLinksAsync",
      false,
      "Outer",
      null
    );
    yield handleError(false, false, exceptionModel);
  }
}

export function* watchGetSiteNotificationsSections() {
  yield takeEvery(getSiteNotificationDetails, FetchSiteNotificationsAsync);
}
