import * as React from "react";
import { Image, MessageBar, MessageBarType } from "@fluentui/react";
import successImage from "../../../Assets/success.svg";
import { useDispatch, useSelector } from "react-redux";
import { hideSuccessPage, setSubmittedIncidentId, submissionSuccessPageSelector } from "../../SubmissionSuccess/SubmissionSuccessSlice";
import { setSubmissionStatus } from "../../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { clearDigitalIncidentErrorStates, resetDigitalIncidentInformation } from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { PsResetLevel, resetPhysicalIncidentInformation } from "../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { PrimaryButtonAdapter } from "../Controls";
import { useNavigate, redirect } from "react-router-dom";
import { clearErrorStates, clearFiles } from "../../FileUpload/FileUploadSlice";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { startIncidentSubmissionFlow } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";

export default function Successv2() {
	const dispatch = useDispatch();
    const navigate = useNavigate();
	const message = useSelector(submissionSuccessPageSelector).message;
	const incidentId = useSelector(submissionSuccessPageSelector).incidentId;
	const advisoryMessage = useSelector(submissionSuccessPageSelector).advisoryMessage;
	const handleReportAnotherIncidentClicked = () => {
		dispatch(startIncidentSubmissionFlow());
		dispatch(hideSuccessPage());
		dispatch(setSubmittedIncidentId(undefined));
		dispatch(setSubmissionStatus(false));
		resetAllFormFields();
        navigate('/v2');
	};

	const resetAllFormFields = () => {
		dispatch(resetDigitalIncidentInformation(true));
		dispatch(clearDigitalIncidentErrorStates());
		dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
		dispatch(setSelectedIncidentCategory(undefined));
		dispatch(clearFiles())
		dispatch(clearErrorStates());
	};

	const headingRef = React.useRef<HTMLHeadingElement>(null);
	React.useEffect(() => {
		if (headingRef.current != null) {
			headingRef.current.focus();
		}
	}, []);

	React.useEffect(() => {
        document.title = `Report It Now | Success page`;
    },[]);

	return (
		<div className="success-page ms-Grid-row site-margins" style={{marginTop:32}}>
			<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3" style={{textAlign: 'center'}}>
				<h1
					style={{
						marginTop: 0,
						fontSize: "34px",
						fontWeight: 500,
					}}
					tabIndex={0}
					ref={headingRef}
				>
					{getLocalizedValueV2("RIN_ThankYou")}
				</h1>
				{incidentId?.trim().length && <p style={{ fontSize: 16 }}>{getLocalizedValueV2("RIN_YourEIncidentNumberIs")} {incidentId}</p>}
				<Image
					src={successImage}
					alt={getLocalizedValueV2("RIN_YouHaveSuccessfullySubmittedTheIncident")}
					styles={{
						root: {
							marginTop: 24,
							marginBottom: 48,
						},
						image: {
							margin: "0 auto",
						},
					}}
				/>
				{advisoryMessage &&
					<MessageBar
						messageBarType={MessageBarType.warning}
						styles={{
							iconContainer: {
								display: 'flex',
								alignItems: 'center',
							},
							text: {
								textAlign: 'left',
								font: 'inherit',
							},
							innerText: {
								fontSize: 16,
								lineHeight: 24,
							}
						} }
				>
					<RichTextRenderer html={advisoryMessage} />
				</MessageBar>}
				<p style={{ fontSize: 14 }} tabIndex={0}>
					{message}
				</p>
				<PrimaryButtonAdapter onClick={handleReportAnotherIncidentClicked}>{getLocalizedValueV2("RIN_FileAnotherReport")}</PrimaryButtonAdapter>
			</div>
		</div>
	);
}
