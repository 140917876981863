import * as React from "react";
import { Icon } from "@fluentui/react";
import { Text } from '@fluentui/react/lib/Text';

export default function FieldValidationDescription(props: any) {
    const getDescriptionStyles = () => ({
        root: { color: props.descriptionColor },
    });

    const onRenderDescription = (errorMessage: string): JSX.Element => {
        var description = (<></>);
        if (errorMessage.length > 0) {
            description = <div>
                <Icon iconName={props.iconName} styles={{
                    root: {
                        fontSize: "10px",
                        marginRight: "4px",
                        color: props.descriptionColor
                    }
                }}></Icon>
                <Text variant="small" styles={getDescriptionStyles()}>
                    {errorMessage}
                </Text>
            </div>;
        }
        return description;
    };
    return (
        onRenderDescription(props.errorMessage)
        );
}