import { MessageBarType } from "@fluentui/react";
import { PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { put, select, takeEvery } from "redux-saga/effects";
import { graphConfig } from "../Auth/AuthConfig";
import {
	clearDigitalIncidentErrorStates,
	DigitalIncidentInformationSelector,
	getDigitalIncidentTypes,
	getTimeZones,
	IsFormValidSelector,
	postDigitalIncidentFormInformation,
	postDigitalSimulationIncident,
	resetDigitalIncidentInformation,
	setDigitalIncidentDateTime,
	setDigitalIncidentReportingOn,
	setDigitalIncidentTypesL1,
	setDigitalIncidentTypesL2,
	setIsDigitalIncidentTypesL1ApiCallSuccess,
	setIsDigitalIncidentTypesL2ApiCallSuccess,
	setIsOutlookButtonAvailable,
	setSelectedDigitalIncidentTypeL1,
	setValidationErrorForExecutiveAlias,
	validateDigitalIncidentFormInformation,
	ValidationErrorCountSelector,
	ValidationErrorSelector,
	validateDigitalDataV2,
	validateExecutiveAlias,
	setLocalizedSelectedDigitalIncidentTypeNameL1,
	setIsTestIncident,
} from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { setSubmissionStatus } from "../Components/DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { clearFiles } from "../Components/FileUpload/FileUploadSlice";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../Components/IncidentCategory/IncidentCategorySlice";
import { setDigitalIncidentTypesLoading, setIsLoading, setTimeZonesLoading } from "../Components/Loader/LoaderSlice";
import { addOrUpdateMessage, removeMessage } from "../Components/Notifications/NotificationsSlice";
import { showSuccessPage } from "../Components/SubmissionSuccess/SubmissionSuccessSlice";
import { setTimeZones } from "../Components/TimeZone/TimeZonesSlice";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector, serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import { SOURCE, STEP } from "../Constants/CommonConstants";
import { FetchDigitalIncidentTypes, FetchTimeZones, SubmitDigitalIncident } from "../Constants/ErrorCodes";
import { DIGITAL_SUBMISSION_MESSAGE } from "../Constants/SubmissionSuccessConstants";
import { ExecutiveAliasErrorModel } from "../Contracts/ExceptionModels/ExceptionModel";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { DigitalIncidentModel } from "../Contracts/TypesAndInterfaces/DigitalIncidentModel";
import { DigitalIncidentType } from "../Contracts/TypesAndInterfaces/DigitalIncidentType";
import { DsDiInputViewModel } from "../Contracts/TypesAndInterfaces/DsDiInputViewModel";
import { TimeZoneListItem } from "../Contracts/TypesAndInterfaces/TimeZoneListItem";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { getLocalizedValue } from "../Services/localizationService";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { handleError, setErrorRibbon } from "../Services/errorHandlerService";
import { GET, POST } from "../Services/httpService";
import { GetExceptionModel, trackEvent } from "../Services/telemetryService";
import { INCIDENT_SUBMISSION_STEP } from "../Constants/InstrumentationConstants";
import { IncidentCategoryModel } from "../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";
import { HelpSectionSelector } from "../Components/HelpPanel/HelpSectionSlice";
import { HelpSectionModel } from "../Contracts/TypesAndInterfaces/HelpSectionModel";
import { PanelEnums } from "../Contracts/Enums/PanelEnums";

export function* PostFormInformationAsync({ payload }: PayloadAction<NavigateFunction | undefined>) {
	let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	yield put(removeMessage("form-submission-exception"));
	yield put(validateDigitalIncidentFormInformation());
	const isFormValid: DigitalIncidentModel = yield select(IsFormValidSelector);
	const digitalIncidentInformation: DsDiInputViewModel = yield select(DigitalIncidentInformationSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	const appInsightsIncidentSubmissionFlowId: string | undefined = yield select(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime: number = yield select(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails: IncidentCategoryModel = yield select(IncidentCategorySelector);
	const helpSections: HelpSectionModel = yield select(HelpSectionSelector);
	if (isFormValid) {
		try {
			yield put(removeMessage("validation-message"));
			yield put(clearDigitalIncidentErrorStates());
			yield put(setIsLoading({ loadingState: true, showSubmissionText: true }));
			let response: WebResponseModel | undefined = yield POST<DsDiInputViewModel>(
				serviceCommunicationSettings.apiParameters.apiBaseAddress,
				"/api/digitalincident/submitincident",
				digitalIncidentInformation,
				accessToken
			);
			if (!response?.responseSuccess) {
				yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
				switch (response?.responseCode) {
					case 403:
						let executiveAliasError: ExecutiveAliasErrorModel = { ErrorMessage: response.responseMessage ?? "", IsErrorOnSubmission: true };
						yield put(setValidationErrorForExecutiveAlias(executiveAliasError));
						setErrorRibbon();
						break;
					case 500:
						yield handleError(false, true, undefined);
						break;
					default:
						yield handleError(true, false, undefined);
						break;
				}
			} else if (response?.responseSuccess) {
				const advisoryHelpSectionId = helpSections.helpSections.find(x => x.panelId === PanelEnums.SiteMessages)?.sectionId;
				const advisoryMessage = helpSections.helpSectionLinks.find(x => x.sectionId === advisoryHelpSectionId)?.title;
				yield put(
					showSuccessPage({
						isSuccessPageShown: true,
						message: getLocalizedValue(DIGITAL_SUBMISSION_MESSAGE),
						advisoryMessage: advisoryMessage ?? undefined,
					})
				);
				if (payload) {
					payload("/v2/success");
					trackEvent({name: INCIDENT_SUBMISSION_STEP}, {
						incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
						step: INCIDENT_SUBMISSION_STEP,
						incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
						incidentType:digitalIncidentInformation.incidentTypeName,
						isSovereignIncident: false,
						timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
					});
				}
				yield put(setSelectedIncidentCategory(undefined));
				yield put(setIsOutlookButtonAvailable(true));
				yield put(resetDigitalIncidentInformation(true));
				yield put(setSelectedDigitalIncidentTypeL1(undefined));
				yield put(setLocalizedSelectedDigitalIncidentTypeNameL1(undefined));
				yield put(removeMessage("form-submission-exception"));
				yield put(clearFiles());
				yield put(setIsTestIncident(undefined));
				yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			}
		} catch (e) {
			yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			let exceptionModel = GetExceptionModel(
				SubmitDigitalIncident,
				SOURCE,
				"Digital" + STEP,
				"Submit digital incident",
				"PostFormInformationAsync",
				true,
				"Outer",
				null
			);
			yield handleError(false, true, exceptionModel);
		}
	} else {
		setErrorRibbon();
	}
}

export function* PostSimulationIncidentAsync() {
	yield put(setDigitalIncidentDateTime(new Date()));
	yield put(setDigitalIncidentReportingOn(new Date()));
	let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	const digitalIncidentInformation: DsDiInputViewModel = yield select(DigitalIncidentInformationSelector);
	let accessToken: string = yield fetchAPIAccessToken();
	const appInsightsIncidentSubmissionFlowId: string | undefined = yield select(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime: number = yield select(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const incidentCategoryDetails: IncidentCategoryModel = yield select(IncidentCategorySelector);
	try {
		yield put(removeMessage("form-submission-exception"));
		yield put(removeMessage("validation-message"));
		yield put(clearDigitalIncidentErrorStates());
		yield put(setIsLoading({ loadingState: true, showSubmissionText: true }));
		let response: WebResponseModel | undefined = yield POST<DsDiInputViewModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/digitalincident/submitincident",
			digitalIncidentInformation,
			accessToken
		);
		if (!response?.responseSuccess) {
			yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			switch (response?.responseCode) {
				case 403:
					let executiveAliasError: ExecutiveAliasErrorModel = { ErrorMessage: response.responseMessage ?? "", IsErrorOnSubmission: true };
					yield put(setValidationErrorForExecutiveAlias(executiveAliasError));
					setErrorRibbon();
					break;
				default:
					yield handleError(false, true, undefined);
					break;
			}
		} else if (response?.responseSuccess) {
			trackEvent({name: INCIDENT_SUBMISSION_STEP}, {
				incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
				step: INCIDENT_SUBMISSION_STEP,
				incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
				incidentType:digitalIncidentInformation.incidentTypeName + "(Simulation Campaign)",
				isSovereignIncident: false,
				timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
			});
			yield put(setSubmissionStatus(true));
			yield put(resetDigitalIncidentInformation(true));
			yield put(setSelectedDigitalIncidentTypeL1(undefined));
			yield put(removeMessage("form-submission-exception"));
			yield put(clearFiles());
			yield put(setIsTestIncident(undefined));
			yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
		}
	} catch (e) {
		yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
		let exceptionModel = GetExceptionModel(
			SubmitDigitalIncident,
			SOURCE,
			"Digital" + STEP,
			"Submit simulation incident",
			"PostSimulationIncidentAsync",
			true,
			"Outer",
			null
		);
		yield handleError(false, true, exceptionModel);
	}
}

export function* FetchTimeZonesAsync() {
	try {
		let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
		yield put(setTimeZonesLoading(true));
		let accessToken: string = yield fetchAPIAccessToken();
		let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/global/gettimezones",
			accessToken
		);
		yield put(
			setTimeZones(response?.responseObject == null || response?.responseObject === undefined ? [] : (response?.responseObject as TimeZoneListItem[]))
		);
		if (!response?.responseSuccess) {
			yield handleError(true, false, undefined);
		}
		yield put(setTimeZonesLoading(false));
	} catch (e) {
		yield put(setTimeZonesLoading(false));
		let exceptionModel = GetExceptionModel(FetchTimeZones, SOURCE, "Digital" + STEP, "Fetch time zones", "FetchTimeZonesAsync", true, "Outer", null);
		yield handleError(true, false, exceptionModel);
	}
}

export function* FetchDigitalIncidentTypesAsync({ payload }: PayloadAction<{ level: number; parentIncidentTypeId?: number }>) {
	try {
		let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
		if(payload.level === 1) yield put(setDigitalIncidentTypesLoading(true));
		else yield put(setIsLoading(true));
		let accessToken: string = yield fetchAPIAccessToken();
		let apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
			serviceCommunicationSettings.apiParameters.apiBaseAddress,
			"/api/digitalincident/getincidenttypes?level=" +
				payload.level.toString() +
				(payload.parentIncidentTypeId === undefined ? "" : "&parentIncidentTypeId=" + payload.parentIncidentTypeId?.toString()),
			accessToken
		);
		if (payload.level === 1) {
			yield put(
				setDigitalIncidentTypesL1(
					apiResponse?.responseObject == null || apiResponse?.responseObject === undefined
						? []
						: (apiResponse?.responseObject as DigitalIncidentType[])
				)
			);
			if (!apiResponse?.responseSuccess) {
				yield put(setIsDigitalIncidentTypesL1ApiCallSuccess(false));
				yield handleError(true, false, undefined);
			}
		} else if (payload.level === 2) {
			yield put(
				setDigitalIncidentTypesL2(
					apiResponse?.responseObject == null || apiResponse?.responseObject === undefined
						? []
						: (apiResponse?.responseObject as DigitalIncidentType[])
				)
			);
			if (!apiResponse?.responseSuccess) {
				yield put(setIsDigitalIncidentTypesL2ApiCallSuccess(false));
				yield handleError(true, false, undefined);
			}
		}
		if(payload.level === 1) yield put(setDigitalIncidentTypesLoading(false));
		else yield put(setIsLoading(false));
	} catch (e) {
		if(payload.level === 1) yield put(setDigitalIncidentTypesLoading(false));
		else yield put(setIsLoading(false));
		let exceptionModel = GetExceptionModel(
			FetchDigitalIncidentTypes,
			SOURCE,
			"Digital" + STEP,
			"Fetch digital incident types level " + payload.level.toString(),
			"FetchDigitalIncidentTypesAsync",
			true,
			"Outer",
			null
		);
		yield handleError(true, false, exceptionModel);
	}
}

export function* watchGetDigitalIncidentTypes() {
	yield takeEvery(getDigitalIncidentTypes, FetchDigitalIncidentTypesAsync);
}

export function* CleanFormState() {
	yield put(removeMessage("validation-message"));
}

export function* watchPostFormInformation() {
	yield takeEvery(postDigitalIncidentFormInformation, PostFormInformationAsync);
}

export function* watchPostDigitalSimulationIncident() {
	yield takeEvery(postDigitalSimulationIncident, PostSimulationIncidentAsync);
}

export function* watchGetTimeZones() {
	yield takeEvery(getTimeZones, FetchTimeZonesAsync);
}

export function* watchClearForm() {
	yield takeEvery(resetDigitalIncidentInformation, CleanFormState);
}

export function* validateDigitalIncidentDetailsV2({ payload }: PayloadAction<NavigateFunction>) {
	let serviceCommunicationSettings: ServiceCommunicationSettings = yield select(serviceCommunicationSettingsSelector);
	yield put(removeMessage("form-submission-exception"));
	yield put(validateDigitalIncidentFormInformation());
	const isFormValid: DigitalIncidentModel = yield select(IsFormValidSelector);
	const digitalIncidentInformation: DsDiInputViewModel = yield select(DigitalIncidentInformationSelector);
	if (isFormValid) {
		if (digitalIncidentInformation.isExecSupport) {
			try {
				let accessToken: string = yield fetchAPIAccessToken();
				yield put(setIsLoading({ loadingState: true, showSubmissionText: false }));
				let response: WebResponseModel | undefined = yield GET<WebResponseModel>(
					serviceCommunicationSettings.apiParameters.apiBaseAddress,
					"/api/digitalincident/validateexecutivealias?alias=" + digitalIncidentInformation.executiveAlias,
					accessToken
				);
				if (!response?.responseSuccess) {
					yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
					switch (response?.responseCode) {
						case 403:
							let executiveAliasError: ExecutiveAliasErrorModel = { ErrorMessage: response.responseMessage ?? "", IsErrorOnSubmission: true };
							yield put(setValidationErrorForExecutiveAlias(executiveAliasError));
							setErrorRibbon();
							break;
						default:
							yield handleError(false, true, undefined);
							break;
					}
				} else if (response?.responseSuccess) {
					yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
					yield put(setValidationErrorForExecutiveAlias({ ErrorMessage: "", IsErrorOnSubmission: false }));
					// Payload here is navigate method used to redirect user to
					if (payload) payload("/v2/create/digital/" + digitalIncidentInformation.incidentTypeId + "/review");
				}
			} catch (e) {
				yield put(setIsLoading({ loadingState: false, showSubmissionText: false }));
			}
		} else {
			// Payload here is navigate method used to redirect user to
			if (payload) payload("/v2/create/digital/" + digitalIncidentInformation.incidentTypeId + "/review");
		}
	} else {
		const errorMessage = yield select(ValidationErrorSelector);
		const errorCount = yield select(ValidationErrorCountSelector);
		yield put(
			addOrUpdateMessage({
				messageContent: errorCount > 1 ? "You have " + errorCount + " validation errors." : errorMessage,
				id: "validation-message",
				messageType: MessageBarType.error,
			})
		);
	}
}

export function* watchValidateDigitalV2() {
	yield takeEvery(validateDigitalDataV2, validateDigitalIncidentDetailsV2);
}
