import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IncidentCategoryRoutes } from "../../../Constants/CommonConstants";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import {
	DigitalIncidentTypesSelector,
	setDigitalIncidentIncidentTypeId,
	setLocalizedSelectedDigitalIncidentTypeNameL1,
	setSelectedDigitalIncidentTypeL1,
} from "../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { DigitalIncidentTypesLoadingSelector, IncidentCategoriesLoadingSelector, LoaderSelector, PsIncidentTypesL1LoadingSelector } from "../../Loader/LoaderSlice";
import { PsIncidentTypesL1Selector, setPsLocalizedSelectedIncidentTypeNameL1, setPsSelectedIncidentTypeIdL1 } from "../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { Step, Stepperv2 } from "../Stepperv2/Stepperv2";
import { StepperSelector } from "../Stepperv2/Stepperv2Slice";
import Loader from "../../Loader/Loader";

export function IncidentFlow() {
	let stepperDetails = useSelector(StepperSelector);
	let isStateUpdated = usePathForState();

	useEffect(() => {
        document.title = `Report It Now | Incident reporting page`;
    },[]);
	return (
		<div className="incidentFlow">
			<Stepperv2 currentStepIndex={stepperDetails.currentStepIndex} steps={stepperDetails.steps} />
			{!isStateUpdated && <Loader />}
			{isStateUpdated && <Outlet />}
		</div>
	);
}

function usePathForState() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { incidentCategory, incidentType } = useParams();
	const loadingDetails = useSelector(LoaderSelector);
	const incidentCategories = useSelector(IncidentCategorySelector);
	const digitalIncidentTypes = useSelector(DigitalIncidentTypesSelector);
	const physicalIncidentTypes = useSelector(PsIncidentTypesL1Selector);
	const [isStateUpdated, setIsStateUpdated] = React.useState(false);
	const isIncidentCategoriesLoading = useSelector(IncidentCategoriesLoadingSelector);
	const isDigitalIncidentTypesLoading = useSelector(DigitalIncidentTypesLoadingSelector);
	const isPhysicalIncidentsLoading = useSelector(PsIncidentTypesL1LoadingSelector);
	React.useEffect(() => {
		if (isStateUpdated) return;
		if(location.pathname.includes("review")) {
			navigate("/v2");
			return;
		}
		if (loadingDetails.isLoading !== 0 || 
			incidentCategories.incidentCategories.length <= 0 || isIncidentCategoriesLoading || isDigitalIncidentTypesLoading || isPhysicalIncidentsLoading) return;
		// TODO: Add this route name to the master data to avoid this nested if else string comparision.
		if (incidentCategory === IncidentCategoryRoutes.Digital) {
			const digitalIncidentCategory = incidentCategories.incidentCategories.find((x) => x.categoryId === IncidentCategories.Digital);
			dispatch(setSelectedIncidentCategory(digitalIncidentCategory));
			const incidentTypeId = Number(incidentType);
			if (!Number.isNaN(incidentTypeId)) {
				const selectedIncidentType = digitalIncidentTypes.incidentTypesL1.find((x) => x.typeId === incidentTypeId);
				if (selectedIncidentType) {
					dispatch(setSelectedDigitalIncidentTypeL1(selectedIncidentType));
					dispatch(setLocalizedSelectedDigitalIncidentTypeNameL1(getLocalizedValueV2(selectedIncidentType.nameKey)));
					dispatch(setDigitalIncidentIncidentTypeId(incidentTypeId));
				} else navigate("digital");
			} else navigate("digital");
		} else if (incidentCategory === IncidentCategoryRoutes.Physical) {
			const physicalIncidentCategory = incidentCategories.incidentCategories.find((x) => x.categoryId === IncidentCategories.Physical);
			dispatch(setSelectedIncidentCategory(physicalIncidentCategory));
			const incidentTypeId = Number(incidentType);
			if (!Number.isNaN(incidentTypeId)) {
				const selectedIncidentType = physicalIncidentTypes.find((x) => x.typeId === incidentTypeId);
				if (selectedIncidentType){
					dispatch(
						setPsSelectedIncidentTypeIdL1({
							id: selectedIncidentType.typeId,
							perspectiveDbId: selectedIncidentType.perspectiveDbId,
							label: selectedIncidentType.displayLabel ?? "",
						})
					);
					dispatch(setPsLocalizedSelectedIncidentTypeNameL1(getLocalizedValueV2(selectedIncidentType.nameKey)));
				}
				else navigate("physical");
			} else navigate("physical");
		} else navigate("");
		setIsStateUpdated(true);
	}, [loadingDetails.isLoading, incidentCategories.incidentCategories, location.pathname, isIncidentCategoriesLoading, isDigitalIncidentTypesLoading, isPhysicalIncidentsLoading]);
	return isStateUpdated;
}
