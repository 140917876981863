import { useCallback, useState } from "react";

type useBooleanReturnType = [boolean, { setTrue: () => void, setFalse: () => void, toggle: () => void}]

export function useBoolean(defaultValue: boolean): useBooleanReturnType {
    const [value, setValue] = useState(defaultValue)

    const makeTrue = useCallback(() => setValue(true), [])
    const makeFalse = useCallback(() => setValue(false), [])
    const toggleValue = useCallback(() => setValue(x => !x), [])

    return [value, { setTrue: makeTrue, setFalse: makeFalse, toggle: toggleValue }]
}