import { TextField } from '@fluentui/react';
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DsDiMalwareInputViewModel } from '../../../Contracts/TypesAndInterfaces/DsDiMalwareInputViewModel';
import { ErrorStatesSelector, MalwareSelector, setAffectedMachineNameErrorMessage, setDigitalIncidentMalwareObject, setFileNameErrorMessage, setMalwareAffectedMachineName, setMalwareFileName, setMalwareThreatName, setThreatNameErrorMessage } from '../DigitalIncident/DigitalIncidentSlice';
import FieldValidationDescription from '../../FieldValidationDescription/FieldValidationDescription';
import { CustomLabel } from './IncidentSpecificFields';
import { getLocalizedValue } from '../../../Services/localizationService';

export default function MalwareFields() {
    const dispatch = useDispatch();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const malwareSelector = useSelector(MalwareSelector);
    const [malwareDetails, setMalwareDetails] = React.useState<DsDiMalwareInputViewModel>({ affectedMachineName: "", threatName: "", fileName: "" });


    React.useEffect(() => {
        dispatch(setDigitalIncidentMalwareObject(malwareDetails));
        return () => {
            dispatch(setDigitalIncidentMalwareObject(undefined));
        }
    }, []);

    const affectedMachineName_CalloputProps = {
        id: "affected-machine-name-info",
    calloutDescription: "<div><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo1")+"</b></div><br><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo2")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo3")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo4")+" <b>"+getLocalizedValue("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValue("RIN_AffectedMachineNameInfo6")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo7")+"</li></ol><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo8")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo9")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo10")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo11")+"</li></ol><div><b>"+getLocalizedValue("RIN_AffectedMachineNameInfo12")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo13")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo14")+" <b>"+getLocalizedValue("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValue("RIN_AffectedMachineNameInfo15")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValue("RIN_AffectedMachineNameInfo7")+"</li></ol></div>",
        moreInfoLink: undefined,
    };
    const fileName_CalloutProps = {
        id: "file-name-info",
        calloutDescription: getLocalizedValue("RIN_FileNameInfo"),
        moreInfoLink: undefined
    };
    const threatName_CalloutProps = {
        id: "threat-name-info",
        calloutDescription: "<span style='word-break: break-all;'>"+getLocalizedValue("RIN_ThreatNameInfo")+"</span>",
        moreInfoLink: undefined
    };

    const machineNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareAffectedMachineName} descriptionColor={'white'} iconName={"ErrorBadge"}/>
    const fileNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareFileName} descriptionColor={'white'} iconName={"ErrorBadge"}/>
    const threatNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareThreatName} descriptionColor={'white'} iconName={"ErrorBadge"}/>

    const handleAffectedMachineNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setAffectedMachineNameErrorMessage(""));
            dispatch(setMalwareAffectedMachineName(newValue));
        }
    }
    const handleFileNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setFileNameErrorMessage(""));
            dispatch(setMalwareFileName(newValue));

        }
    }
    const handleThreatNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setThreatNameErrorMessage(""));
            dispatch(setMalwareThreatName(newValue));
        }
    }

    return (
        <div className="malware-fields">
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareAffectedMachineNameId"
                        aria-required={true}
                        ariaLabel={getLocalizedValue("RIN_AffectedMachineName")}
                        aria-labelledby={affectedMachineName_CalloputProps.id}
                        label={getLocalizedValue("RIN_AffectedMachineName")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...affectedMachineName_CalloputProps} {...props} />}
                        value={malwareSelector?.affectedMachineName}
                        onChange={handleAffectedMachineNameChange}
                        onRenderDescription={() => machineNamedescription}
                        styles={
                            errorStatesDetails.malwareAffectedMachineName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareFileNameId"
                        aria-required={true}
                        aria-labelledby={fileName_CalloutProps.id}
                        label={getLocalizedValue("RIN_FileName")}
                        ariaLabel={getLocalizedValue("RIN_FileName")}
                        onRenderLabel={(props) => <CustomLabel {...fileName_CalloutProps} {...props} />}
                        // eslint-disable-next-line react/jsx-no-bind
                        value={malwareSelector?.fileName}
                        onChange={handleFileNameChange}
                        onRenderDescription={() => fileNamedescription}
                        styles={
                            errorStatesDetails.malwareFileName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareThreatNameId"
                        aria-required={true}
                        aria-labelledby={threatName_CalloutProps.id}
                        label={getLocalizedValue("RIN_ThreatName")}
                        ariaLabel={getLocalizedValue("RIN_ThreatName")}
                        onRenderLabel={(props) => <CustomLabel {...threatName_CalloutProps} {...props} />}
                        // eslint-disable-next-line react/jsx-no-bind
                        value={malwareSelector?.threatName}
                        onChange={handleThreatNameChange}
                        onRenderDescription={() => threatNamedescription}
                        styles={
                            errorStatesDetails.malwareThreatName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
        </div>
    );
}