import {
    ChoiceGroup,
    IChoiceGroupOption,
    ITextField,
    Label,
    TextField,
} from "@fluentui/react";
import { wizardContentMaxWidth } from "@m365-admin/wizard";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DigitalEventTypes } from "../../../../Contracts/Enums/DigitalIncidentEnums";
import { DsDiPhishingCallorTextInputViewModel, DsDiPhishingCallorTextLocalizedInputViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiPhishingCallorTextInputViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { ApplicationSettingsSelector } from "../../../ConfigurationsPanel/ApplicationSettingsSlice";
import { ErrorStatesSelector, PhishingCallOrTextSelector, setDigitalIncidentPhishingCallOrTextObject, setPhishingCallOrTextFrequencyErrorMessage, setPhishingCallOrTextCallFrequency, setPhishingCallOrTextOffenderDetailsErrorMessage, setPhishingCallOrTextOffenderContactDetails, setPhishingCallOrTextActionsErrorMessage, setPhishingCallOrTextInfoSharedByUser, setPhishingCallOrTextReceivedOn, setPhishingCallOrTextIsSensitiveInfoSharedByUser, SelectedDigitalIncidentTypeL1Selector, LocalizedPhishingCallOrTextSelector, setDigitalIncidentLocalizedPhishingCallOrTextObject } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { renderDescriptionLabel } from "../../../Home/Home";

const callOrSMSReceivedOnOptionsNew: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_TeamsCall"), value: "Teams Call" },
    { key: "2", text: getLocalizedValueV2("RIN_TeamsMessage"), value: "Teams Message" },
    { key: "3", text: getLocalizedValueV2("RIN_PersonalCall"), value: "Personal Call" },
    { key: "4", text: getLocalizedValueV2("RIN_PersonalSMSText"), value: "Personal SMS/Text" }
];

const callOrSMSReceivedOnOptionsOld: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Teams"), value: "Teams" },
    { key: "2", text: getLocalizedValueV2("RIN_PersonalPhone"), value: "Personal phone" },
]


const isSensitiveinfoSharedOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Yes"), value: "Yes" },
    { key: "2", text: getLocalizedValueV2("RIN_No"), value: "No" },
];

export default function PhishingCallOrTextFields() {
    const labelId: string = "shared-info-details";
    const dispatch = useDispatch();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const phishingCallOrTextSelector = useSelector(PhishingCallOrTextSelector);
    const selectedEventTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector)
    const selectedEventTypeId = selectedEventTypeL1?.typeId;
    const applicationSettingsSelector = useSelector(ApplicationSettingsSelector);
    const flowConfigurations = applicationSettingsSelector?.flowConfigurations;
    const [phishingCallOrTextDetails, setPhishingCallOrTextDetails] =
        React.useState<DsDiPhishingCallorTextInputViewModel>({
            callFrequencyPerDay: undefined,
            callReceivedOn: "Teams Call",
            offenderContactDetails: "",
            isSensitiveInformationShared: false,
            infoSharedByUser: "",
        });

    const defaultLocalizedPhishingCallOrTextDetails: DsDiPhishingCallorTextLocalizedInputViewModel = {
        callFrequencyPerDay: undefined,
        callReceivedOn: getLocalizedValueV2("RIN_TeamsCall"),
        offenderContactDetails: "",
        isSensitiveInformationShared: getLocalizedValueV2("RIN_No"),
        infoSharedByUser: "",
    }
    const localizedPhishingCallOrTextDetails: DsDiPhishingCallorTextLocalizedInputViewModel | undefined = useSelector(LocalizedPhishingCallOrTextSelector);


    React.useEffect(() => {
        if(phishingCallOrTextSelector) return;
        dispatch(
            setDigitalIncidentPhishingCallOrTextObject(
                phishingCallOrTextDetails
            )
        );
        dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({ ...localizedPhishingCallOrTextDetails, ...defaultLocalizedPhishingCallOrTextDetails }));
    }, []);

    const [callOrSmsOption, setCallOrSmsOption] =
        React.useState<IChoiceGroupOption>();
    const [isSensitiveInfoSharedOption, setIsSensitiveInfoSharedOption] =
        React.useState<IChoiceGroupOption>(isSensitiveinfoSharedOptions[1]);

    const actionsDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextActions}
            descriptionColor={"#e50000"}
            iconName={"ErrorBadge"}
        />
    );
    const offenderDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextOffenderDetails}
            descriptionColor={"#e50000"}
            iconName={"ErrorBadge"}
        />
    );
    const frequencyDescription = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingCallOrTextFrequency}
            descriptionColor={"#e50000"}
            iconName={"ErrorBadge"}
        />
    );


    let callOrSMSReceivedOnOptions: IChoiceGroupOption[] = callOrSMSReceivedOnOptionsOld;
    let callOrSMSOptionsWidth: number = 222;

    const setCallOrSMSOptions = () => {
        callOrSMSReceivedOnOptions = callOrSMSReceivedOnOptionsNew;
        callOrSMSOptionsWidth = 600;
    }

    if (selectedEventTypeId && selectedEventTypeId == DigitalEventTypes.GovRelated) {
        if (flowConfigurations.isShowPhishingCallOptionsToFed) {
            setCallOrSMSOptions();
        }
    }
    else {
        if (flowConfigurations.isShowPhishingCallOptionsToCorp) {
            setCallOrSMSOptions();
        }
    }

    const handleFrequencyChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(setPhishingCallOrTextFrequencyErrorMessage(""));
            let frequency = Number(newValue);
            if (frequency < 0 || frequency > 100) {
                dispatch(setPhishingCallOrTextCallFrequency(0));
                dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callFrequencyPerDay:0} as DsDiPhishingCallorTextLocalizedInputViewModel));

            } else {
                dispatch(
                    setPhishingCallOrTextCallFrequency(Number(newValue.trim()))
                );
                dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callFrequencyPerDay:Number(newValue.trim())} as DsDiPhishingCallorTextLocalizedInputViewModel));
            }
        }
    };
    const handleOffenderDetailsChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingCallOrTextOffenderDetailsErrorMessage(""));
            dispatch(setPhishingCallOrTextOffenderContactDetails(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,offenderContactDetails:newValue} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleInfoChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingCallOrTextActionsErrorMessage(""));
            dispatch(setPhishingCallOrTextInfoSharedByUser(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,infoSharedByUser:newValue} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleCallOrSmsChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setCallOrSmsOption(option);
            dispatch(setPhishingCallOrTextReceivedOn((typeof (option.value) === "string" ? option.value ?? "" : "")));
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,callReceivedOn:option.text} as DsDiPhishingCallorTextLocalizedInputViewModel));

        }
    };

    const handleIsSensitiveInfoSharedChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setIsSensitiveInfoSharedOption(option);
            dispatch(
                setPhishingCallOrTextIsSensitiveInfoSharedByUser(
                    option.value == "Yes" ? true : false
                )
            );
            dispatch(setDigitalIncidentLocalizedPhishingCallOrTextObject({...localizedPhishingCallOrTextDetails,isSensitiveInformationShared:option.text} as DsDiPhishingCallorTextLocalizedInputViewModel));
            // When IsSensitiveInfoSharedByuser changes, we need to reset the Your actions field
            dispatch(setPhishingCallOrTextActionsErrorMessage(""));
            dispatch(setPhishingCallOrTextInfoSharedByUser(""));
        }
    };

    return (
        <div className="phishing-call-or-text-fields">
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        label={getLocalizedValueV2("RIN_CallerDetails")}
                        id="phishingCallerDetailsId"
                        value={
                            phishingCallOrTextSelector?.offenderContactDetails
                        }
                        onChange={handleOffenderDetailsChange}
                        required
                        aria-required={true}
                        onRenderDescription={() => offenderDescription}
                        min={0}
                        defaultValue="0"
                        styles={
                            errorStatesDetails.phishingCallOrTextOffenderDetails
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={callOrSMSReceivedOnOptions}
                        label={getLocalizedValueV2("RIN_CallSMSReceivedon")}
                        selectedKey={callOrSMSReceivedOnOptions.find(x=>x.value === phishingCallOrTextSelector?.callReceivedOn)?.key}
                        onChange={handleCallOrSmsChange}
                        defaultSelectedKey="1"
                        styles={getRadioButtonStyle(undefined, callOrSMSOptionsWidth)}
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="phishingCallerFrequencyId"
                        required
                        aria-required={true}
                        label={getLocalizedValueV2("RIN_PhishingCallQuestion1")}
                        value={phishingCallOrTextSelector?.callFrequencyPerDay?.toString()}
                        onChange={handleFrequencyChange}
                        onRenderDescription={() => frequencyDescription}
                        onRenderLabel={(props) =>
                            renderDescriptionLabel({...props, className: "required-field-label"}, "call-sms-details")
                        }
                        min={1}
                        max={100}
                        type="number"
                        onKeyDown={(
                            event: React.KeyboardEvent<
                                HTMLInputElement | HTMLTextAreaElement
                            >
                        ) => {
                            if (event.key === ".") {
                                event.preventDefault();
                            }
                        }}
                        defaultValue="0"
                        aria-labelledby="call-sms-details"
                        styles={
                            errorStatesDetails.phishingCallOrTextFrequency
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            </div>

            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={isSensitiveinfoSharedOptions}
                        label={getLocalizedValueV2("RIN_PhishingCallQuestion2")}
                        selectedKey={isSensitiveinfoSharedOptions.find(x=>x.value === (phishingCallOrTextSelector?.isSensitiveInformationShared ? "Yes" : "No"))?.key}
                        onChange={handleIsSensitiveInfoSharedChange}
                        defaultSelectedKey="2"
                        styles={getRadioButtonStyle(145)}
                    />
                </div>
            </div>

            {isSensitiveInfoSharedOption?.value == "Yes" && (
                <div id="your-actions" style={{ marginBottom: "28px" }}>
                    <div className="ms-Grid-row">
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                            style={{ marginBottom: "2px" }}
                        >
                            <Label><span className="required-field-label">{getLocalizedValueV2("RIN_YourActions")}</span></Label>
                        </div>
                    </div>
                    <TextField
                        id="phishingCallerAdditionalDetailsId"
                        required
                        aria-required={true}
                        aria-labelledby={labelId}
                        label={getLocalizedValueV2("RIN_PhishingCallQuestion3")}
                        placeholder={getLocalizedValueV2("RIN_PhishingEmailQuestion2PlaceHolder")}
                        multiline
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(props, labelId)
                        }
                        value={phishingCallOrTextSelector?.infoSharedByUser}
                        onChange={handleInfoChange}
                        onRenderDescription={() => actionsDescription}
                        styles={
                            errorStatesDetails.phishingCallOrTextActions
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />
                </div>
            )}
        </div>
    );
}
