import React, {  } from "react";
import styles from "./IncidentCategoryv2.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import { setStepperStep } from "../Stepperv2/Stepperv2Slice";
import { StepperSteps } from "../../../Constants/CommonConstants";
import { PrimaryButtonAdapter } from "../Controls";
import { useNavigate } from "react-router-dom";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { CustomChoiceGroup, ICustomChoiceGroupOption } from "../Controls/CustomChoiceGroup/CustomChoiceGroup";
import { trackEvent } from "../../../Services/telemetryService";
import { INCIDENT_CATEGORY_SELECTION_STEP } from "../../../Constants/InstrumentationConstants";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { IncidentCategoriesLoadingSelector } from "../../Loader/LoaderSlice";
import Loader from "../../Loader/Loader";

export default function IncidentCategoryv2() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const incidentCategoryDetails = useSelector(IncidentCategorySelector);
	let selectedCategoryId = incidentCategoryDetails.selectedIncidentCategory?.categoryId;
	const appInsightsIncidentSubmissionFlowId = useSelector(appInsightsIncidentSubmissionFlowIdSelector);
    const appInsightsIncidentSubmissionFlowStartTime = useSelector(appInsightsIncidentSubmissionFlowStartTimeSelector);
	const handleIncidentCategoryChange = (categoryId: IncidentCategories) => {
		let incidentCategory = incidentCategoryDetails.incidentCategories.find((s) => s.categoryId === categoryId);
		dispatch(setSelectedIncidentCategory(incidentCategory));
	};

	const incidentCategoryChoiceGroupOptions: ICustomChoiceGroupOption[] = incidentCategoryDetails.incidentCategories.map((x) => {
		return {
			key: x.categoryId,
			moreInfoLink: x.moreInfoUrl,
			optionDescriptionLocalizationKey: x.descriptionKey,
			optionLabelLocalizationKey: x.nameKey,
		};
	})

	const isIncidentCategoriesLoading = useSelector(IncidentCategoriesLoadingSelector);

	React.useEffect(() => {
		dispatch(setStepperStep(StepperSteps.Category));
	}, []);

	React.useEffect(()=>{
		trackEvent({name: INCIDENT_CATEGORY_SELECTION_STEP}, {
			incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
			step: INCIDENT_CATEGORY_SELECTION_STEP,
			timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
		});
	}, []);

	return (
		<div className="incidentCategory ms-Grid-row">
			{isIncidentCategoriesLoading && <Loader />}
			<div
				className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3"
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div>
					<div className={styles.categoryBlock} style={{ backgroundColor: "rgba(250, 250, 250, 1)", paddingTop: 12 }}>
						<div className={styles.category + " site-margins"}>
							<div className={styles.categoryDetails}>
								<h2 aria-level={1} className={styles.categoryHeader} id="incident-category-step-heading">{getLocalizedValueV2("RIN_SelectIncidentCategory")}</h2>
								<p className={styles.categoryDescription}>
									{getLocalizedValueV2("RIN_PI_GSOC")}{" "}
									<a href="tel:+1 425 706-0000" style={{ color: "#0078D4", textDecoration: "none" }} aria-label={getLocalizedValueV2("RIN_PI_GSOC") + " +1 425 706-0000" }>
										+1 425 706-0000
									</a>
								</p>
							</div>
						</div>
						<div className="c-stepper__divider"></div>
					</div>
					<CustomChoiceGroup
						options={incidentCategoryChoiceGroupOptions}
						selectedKey={selectedCategoryId}
						name="incident categories"
						onChange={(option) => { handleIncidentCategoryChange(option!.key) }}
						legendId="incident-category-step-heading"
					/>
				</div>
				<div className="ms-Grid-row" style={{ marginBottom: 16, marginTop: 28 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
						<PrimaryButtonAdapter
							text={getLocalizedValueV2("RIN_Next")}
							disabled={!selectedCategoryId}
							onClick={() => {
								if (selectedCategoryId == IncidentCategories.Digital) navigate("digital");
								else navigate("physical");
							}}
							styles={{
								root: {
									marginTop: 16,
									marginBottom: 16,
									selectors: {
										"@media (max-width: 479px)": {
											marginTop: 12,
										},
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}