import * as React from "react";
import "@fluentui/react/dist/css/fabric.css";
import "./home.css";
import { IncidentCategorySection } from "../IncidentCategory/IncidentCategory";
import DigitalIncidentSection from "../DigitalSecurity/DigitalIncident/DigitalIncident";
import Welcome from "../Welcome/Welcome";
import UserProfile from "../UserProfile/UserProfile";
import PhysicalIncident from "../PhysicalSecurity/PhysicalIncident/PhysicalIncident";
import SubmissionSuccess from "../SubmissionSuccess/SubmissionSuccess";
import ExceptionPage from "../ExceptionPage/ExceptionPage";
import { useSelector } from "react-redux";
import { IncidentCategorySelector } from "../IncidentCategory/IncidentCategorySlice";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { submissionSuccessPageSelector } from "../SubmissionSuccess/SubmissionSuccessSlice";
import Notifications from "../Notifications/Notifications";
import { exceptionPageSelector } from "../ExceptionPage/exceptionPageSlice";

function CustomLabel(props: any) {
    return (
        <span
            id={props.labelId}
            style={{
                color: "white",
                fontSize: "14px",
                fontWeight: props.fontWeight ?? 600,
            }}
        >
            {props.label}
        </span>
    );
}

function CustomDescriptionLabel(props: any) {
    return (
        <span
            id={props.labelId}
            className={props?.className}
            style={{
                fontSize: "14px",
                display: "block",
                marginBottom: "8px",
                fontWeight: props.fontWeight
            }}
        >
            {props.label}
        </span>
    );
}

function CustomDescription(props: any) {
    return (
        <span
            style={{ color: "white", fontSize: "14px" }}
        >
            {props.description}
        </span>
    );
}

export const onRenderLabel = (props: any, id: string, fontWeight?: number) => (
    <CustomLabel labelId={id} {...props} fontWeight={fontWeight} />
);

export const renderDescriptionLabel = (props: any, id: string) => (
    <CustomDescriptionLabel labelId={id} {...props} />
);

export const onRenderDescription = (props: any) => (
    <CustomDescription {...props} />
);

export default function Home() {
    const incidentCategoryDetails = useSelector(IncidentCategorySelector);
    
    const isSuccessPage = useSelector(submissionSuccessPageSelector).isSuccessPageShown;
    const isExceptionPageShown = useSelector(exceptionPageSelector).showErrorPage;

    return (
        <main tabIndex={-1} id="mainRegion" className="page-container">
            <div className="stickey-Header-Container">
                <Notifications />
            </div>
            {!isSuccessPage && (
                <>
                    <div className="ms-Grid grid-container" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Welcome />
                                <UserProfile />
                            </div>
                        </div>
                    </div>
                    <div className="background-images">
                        <div
                            className="ms-Grid grid-container"
                            dir="ltr"
                        >
                            {!isExceptionPageShown && (
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg6 ms-lgPush3 ms-xl4 ms-xlPush4 ms-xxl4 ms-xxlPush4 ms-xxxl4 ms-xxxlPush4 block incident-container">
                                        {incidentCategoryDetails
                                            .incidentCategories.length >
                                            0 && (
                                                <IncidentCategorySection />
                                            )}

                                        {incidentCategoryDetails.selectedIncidentCategory !== undefined &&
                                            incidentCategoryDetails.selectedIncidentCategory.categoryId ===
                                            IncidentCategories.Digital &&
                                            (
                                                <DigitalIncidentSection />
                                            )}
                                        {incidentCategoryDetails.selectedIncidentCategory !== undefined &&
                                            incidentCategoryDetails.selectedIncidentCategory.categoryId ===
                                            IncidentCategories.Physical && (
                                                <PhysicalIncident />
                                            )}
                                    </div>
                                </div>
                            )}
                            {isExceptionPageShown && <ExceptionPage />}
                        </div>
                    </div>
                </>
            )}
            {isSuccessPage && (
                <div className="ms-Grid grid-container background-images submission-success-background" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg6 ms-lgPush3 ms-xl4 ms-xlPush4 ms-xxl4 ms-xxlPush4 ms-xxxl4 ms-xxxlPush4 block incident-container">
                            <SubmissionSuccess />
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
