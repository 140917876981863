import { Label, TextField } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DsDiExposureOfSensitiveInformationInputViewModel } from '../../../../Contracts/TypesAndInterfaces/DsDiExposureOfSensitiveInformationInputViewModel';
import { getLocalizedValueV2 } from '../../../../Services/localizationServiceV2';
import { ExposureOfSensitiveInfoSelector, ErrorStatesSelector, setExposureOfSensitiveInfoCustomerOrPartnerErrorMessage, setDigitalIncidentExposureOfSensitiveInfoCustomerOrPartnerDetails, setDigitalIncidentExposureOfSensitiveInfoObject } from '../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice';
import FieldValidationDescription from '../../../FieldValidationDescription/FieldValidationDescription';
import { renderDescriptionLabel } from '../../../Home/Home';

export default function ExposureOfSensitiveInformationFields() {
    const labelId: string = "customerOrPartnerData";
    const dispatch = useDispatch();
    const exposureOfSensitiveInfoSelector = useSelector(ExposureOfSensitiveInfoSelector);
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const customerOrPartnerDescription = <FieldValidationDescription errorMessage={errorStatesDetails.exposureOfSensitiveInformationCustomerOrPartnerDetails} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const [exposureOfSensitiveInfoDetails, setExposureOfSensitiveInfoDetails] = React.useState<DsDiExposureOfSensitiveInformationInputViewModel>({
        customerOrPartnerDetails: null
    });

    const handleCustomerOrPartnerDataChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setExposureOfSensitiveInfoCustomerOrPartnerErrorMessage(""))
            dispatch(setDigitalIncidentExposureOfSensitiveInfoCustomerOrPartnerDetails(newValue));
        }
    };

    React.useEffect(() => {
        if(exposureOfSensitiveInfoSelector) return;
        dispatch(setDigitalIncidentExposureOfSensitiveInfoObject(exposureOfSensitiveInfoDetails));
    }, []);

    return (
        <div className="exposureOfSensitiveInfo-fields">
            <div className="ms-Grid-row" style={{ marginBottom: '32px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                            <Label id='lblSensitiveInfo' style={{ padding: 0, lineHeight: 1 }}>{getLocalizedValueV2("RIN_ExposureofSensitiveInfoQuestion1")}</Label>
                        </div>
                    </div>
                    <TextField
                        id="digitalIncidentExposureOfSensitiveInfoCustOrPartnerId"
                        aria-required={false}
                        aria-labelledby="lblSensitiveInfo"
                        placeholder={getLocalizedValueV2("RIN_ProvideCustomerPartnerName")}
                        onRenderLabel={(props) =>
                            renderDescriptionLabel(props, labelId)
                        }
                        value={exposureOfSensitiveInfoSelector?.customerOrPartnerDetails ?? ""}
                        onChange={handleCustomerOrPartnerDataChange}
                        onRenderDescription={() => customerOrPartnerDescription}
                        styles={
                            errorStatesDetails.exposureOfSensitiveInformationCustomerOrPartnerDetails.length > 0 ?
                                {
                                    field: {
                                        backgroundColor: "#FED9CC"
                                    }
                                } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
        </div>
    );
}