import { Callout, DelayedRender, DirectionalHint, FocusTrapCallout, FocusTrapZone, IconButton, IDropdownOption, Label, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { labelCalloutStackStyles, stackTokens } from "../../../Constants/CommonConstants";
import { DigitalEventTypes, DigitalUnauthorizedAccessL2EventTypes, SovereignDigitalEventTypes, SovereignDigitalUnauthorizedAccessL2EventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { IncidentCategories } from "../../../Contracts/Enums/IncidentCategory";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import FileUpload from "../../FileUpload/FileUpload";
import { renderDescriptionLabel } from "../../Home/Home";
import { setSelectedIncidentCategory } from "../../IncidentCategory/IncidentCategorySlice";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { SovereignSelectionComponent } from "../../Sovereign/Sovereign";
import { sovereignSelectedIncidentTypeL1Selector, sovereignSelectedIncidentTypeL2Selector } from "../../Sovereign/SovereignSlice";
import {
    DigitalIncidentInformationSelector,
    DigitalIncidentTypesSelector,
    ErrorStatesSelector,
    getDigitalIncidentTypes,
    SelectedDigitalIncidentTypeL2Selector,
    setAzureSubscriptionErrorMessage,
    setDigitalIncidentAzureSubscriptionId,
    setDigitalIncidentIncidentDescription,
    setIncidentDescriptionErrorMessage,
    setIsOutlookButtonAvailable,
    setSelectedDigitalIncidentTypeL1
} from "../DigitalIncident/DigitalIncidentSlice";
import IncidentSpecificFields, { CustomLabel } from "../IncidentSpecificFields/IncidentSpecificFields";
import LocationAndTimeSection from "../LocationAndTime/LocationAndTime";
import PhishingSimulation from "../PhishingSimulation/PhishingSimulation";
import OutlookReportingInstructions from "./OutlookReportingInstructions";
import { CONSTANTS } from "../../../Constants/CommonConstants"
import { getLocalizedValue } from "../../../Services/localizationService";

const iconProps = { iconName: "Unknown" };

export default function IncidentInformationSection(props: any) {
    const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
    const selectedEventTypeL1 = digitalIncidentTypesDetails.selectedIncidentTypeL1;
    const isOutlookButtonAvailable =
        digitalIncidentTypesDetails.isOutlookButtonAvailable;
    const selectedIncidentTypeL2 = useSelector(DigitalIncidentTypesSelector).selectedIncidentTypeL2;
    const digitalIncidentDetails = useSelector(DigitalIncidentInformationSelector);
    const selectedL2IncidentType = useSelector(SelectedDigitalIncidentTypeL2Selector);
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const sovereignIncidentTypeL1 = useSelector(sovereignSelectedIncidentTypeL1Selector);
    const sovereignIncidentTypeL2 = useSelector(sovereignSelectedIncidentTypeL2Selector);
    const isPhishingEmailIncident = (selectedEventTypeL1?.typeId === DigitalEventTypes.PhishingEMail || selectedEventTypeL1?.typeId === SovereignDigitalEventTypes.PhishingEMail);
    const isCallOutPresent = (selectedEventTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess && (selectedL2IncidentType !== undefined && selectedL2IncidentType.typeId === DigitalUnauthorizedAccessL2EventTypes.UnknownDevice)) || (selectedEventTypeL1?.typeId === DigitalEventTypes.GovRelated && (sovereignIncidentTypeL1?.key === SovereignDigitalEventTypes.UnauthorizedAccess && sovereignIncidentTypeL2?.key === SovereignDigitalUnauthorizedAccessL2EventTypes.UnknownDevice));
    const description = (
        <FieldValidationDescription
            errorMessage={
                errorStatesDetails.incidentDescription
            }
            descriptionColor={'white'}
            iconName={"ErrorBadge"}
        />
    );

    const azureSubscriptionDescription = (
        <FieldValidationDescription
            errorMessage={
                errorStatesDetails.azureSubscription
            }
            descriptionColor={'white'}
            iconName={"ErrorBadge"}
        />
    );
    const dispatch = useDispatch();
    const allowedSizePerFileInMB: number = 2.8;
    const totalAllowedSizeInMB: number = 2.8;
    React.useEffect(() => {
        if (selectedEventTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess) {
            dispatch(getDigitalIncidentTypes({ level: 2, parentIncidentTypeId: DigitalEventTypes.UnauthorizedAccess }));
        }
    }, [selectedEventTypeL1])

    const {
        isPhishingSimulationExperienceShown,
        hidePhishingSimulationExperience,
        image,
        incidentTypeId,
        simulationCampaignId,
    } = props;

    const labelId: string = "incident-summary";
    const azureSubscriptionLabelId: string = "azure-subscription";

    const resetPhishingForm = () => {
        dispatch(setIsOutlookButtonAvailable(true));
        dispatch(setSelectedDigitalIncidentTypeL1(undefined));
        dispatch(setSelectedIncidentCategory(undefined));
    };
    const handleDescriptionChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setIncidentDescriptionErrorMessage(""));
            dispatch(setDigitalIncidentIncidentDescription(newValue));
        }
    };

    const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
    const toggleIsCalloutVisible = () => setIsCalloutVisible(!isCalloutVisible);

    const handleAzureSubscriptionChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setAzureSubscriptionErrorMessage(""));
            dispatch(setDigitalIncidentAzureSubscriptionId(newValue));
        }
    };

    const azureSubscriptionId_CalloputProps = {
        id: azureSubscriptionLabelId,
        calloutDescription: "<div><div><b>" + getLocalizedValue("RIN_AzureSubscriptionInfo1") + "</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;padding-left: 12px;\'><li style='margin-bottom: 4px;'>" + getLocalizedValue("RIN_AzureSubscriptionInfo2") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValue("RIN_AzureSubscriptionInfo3") + "</li><li style='margin-bottom: 4px;'>" + getLocalizedValue("RIN_AzureSubscriptionInfo4") + "</li><li>" + getLocalizedValue("RIN_AzureSubscriptionInfo5") + "</li></ol></div>",
        moreInfoLink: undefined,
    };

    const attachmentCalloutProps = {
        id: "attachment-info",
        calloutDescription: "<b>Note: </b>Attach the device enrollment/registration email you received (if any).",
        moreInfoLink: undefined,
        labelStyle: { fontWeight: 400 }
    };
    const iconButtonId: string = attachmentCalloutProps.id + "-iconButton";
    const descriptionId: string = attachmentCalloutProps.id + "-description";

    const [incidentTypeOptions, setIncidentTypeOptions] = React.useState<IDropdownOption[]>([]);

    const callOutComponent = (<IconButton
        id={iconButtonId}
        iconProps={iconProps}
        title={getLocalizedValue("RIN_SupportingAttachments") + (isPhishingEmailIncident ? "" : " - " + getLocalizedValue("RIN_Optional"))}
        ariaLabel={getLocalizedValue("RIN_SupportingAttachments") + (isPhishingEmailIncident ? "" : " - " + getLocalizedValue("RIN_Optional")) + getLocalizedValue("RIN_Help")}
        onClick={toggleIsCalloutVisible}
        className="iconButton"
        styles={{ root: { height: "fit-content", marginTop: "4px" } }}
    />);

    React.useEffect(() => {
        if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
            var incidentTypes: IDropdownOption[] = [];
            if (digitalIncidentTypesDetails.incidentTypesL1.length > 0) {
                digitalIncidentTypesDetails.incidentTypesL1.forEach(
                    (incidentType) => {
                        incidentTypes.push({
                            key: incidentType.typeId,
                            text: getLocalizedValue(incidentType.nameKey),
                        });
                    }
                );
                setIncidentTypeOptions(incidentTypes);
            }
        }
    }, [digitalIncidentTypesDetails]);

    if (!selectedEventTypeL1) return <></>;

    if (isPhishingSimulationExperienceShown)
        return (
            <PhishingSimulation
                {...{
                    isPhishingSimulationExperienceShown,
                    hidePhishingSimulationExperience,
                    resetPhishingForm,
                    image,
                    incidentTypeId,
                    simulationCampaignId
                }}
            />
        );

    if (selectedEventTypeL1?.typeId === DigitalEventTypes.GovRelated) {
        return <div className="digital-incident-information-container"><SovereignSelectionComponent IncidentCategory={IncidentCategories.Digital} childRenderer={IncidentInformationSection} /></div>
    }

    return (
        IncidentInformationSection(selectedEventTypeL1?.typeId)
    );

    function IncidentInformationSection(selectedEventTypeId: number) {
        const showIncidentInformation =
            (selectedEventTypeId !== DigitalEventTypes.UnauthorizedAccess && ((selectedEventTypeId !== DigitalEventTypes.PhishingEMail && selectedEventTypeId !== SovereignDigitalEventTypes.PhishingEMail) ||
                ((selectedEventTypeId === DigitalEventTypes.PhishingEMail || selectedEventTypeId === SovereignDigitalEventTypes.PhishingEMail) &&
                    !isOutlookButtonAvailable))) || (selectedEventTypeId === DigitalEventTypes.UnauthorizedAccess && selectedIncidentTypeL2);
        return (
            <div className="incident-information-container">
                {(selectedEventTypeId === DigitalEventTypes.PhishingEMail || selectedEventTypeId === SovereignDigitalEventTypes.PhishingEMail) && (
                    <OutlookReportingInstructions
                        {...{ isOutlookButtonAvailable, dispatch }}
                    />
                )}
                {showIncidentInformation && (
                    <>
                        <LocationAndTimeSection />
                        <div className="ms-Grid-row section-header">
                            <h2 className="sectionHeading">{getLocalizedValue("RIN_IncidentInformation")}</h2>
                        </div>
                        <div className="incident-specific-fields">
                            <IncidentSpecificFields incidentTypeId={selectedEventTypeId} />
                        </div>
                        {(selectedEventTypeId !== DigitalEventTypes.PhishingEMail && selectedEventTypeId !== SovereignDigitalEventTypes.PhishingEMail && selectedEventTypeId !== DigitalEventTypes.PhishingCallOrText && selectedEventTypeId !== SovereignDigitalEventTypes.PhishingCallOrText) && (
                            <div className="azureSubscription" style={{ marginBottom: "28px", marginTop: "28px" }}>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                        <CustomLabel id={azureSubscriptionId_CalloputProps.id} label={getLocalizedValue("RIN_RelatedToAnAzureSubscriptionOptional")} calloutDescription={azureSubscriptionId_CalloputProps.calloutDescription} />
                                    </div>
                                </div>
                                <TextField
                                    id="digitalIncidentAzureSubscriptionId"
                                    aria-required={false}
                                    aria-labelledby={azureSubscriptionId_CalloputProps.id + 'label'}
                                    placeholder={getLocalizedValue("RIN_RelatedToAnAzureSubscriptionOptionalPlaceholder")}
                                    onRenderDescription={() => azureSubscriptionDescription}
                                    value={digitalIncidentDetails.azureSubscriptionId ?? ""}
                                    onChange={handleAzureSubscriptionChange}
                                    styles={
                                        errorStatesDetails
                                            .azureSubscription.length > 0
                                            ? {
                                                field: {
                                                    backgroundColor: "#FED9CC",
                                                },
                                            }
                                            : {
                                                field: {
                                                    backgroundColor: "white",
                                                },
                                            }
                                    }
                                />
                            </div>
                        )}
                        <div id="incident-summary" style={{ marginBottom: "28px" }}>
                            {
                                selectedEventTypeL1?.typeId == DigitalEventTypes.GovRelated &&
                                (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                            <Label>{getLocalizedValue("RIN_CUIDisclaimer")}</Label>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                    <Label>{getLocalizedValue("RIN_IncidentSummary")}</Label>
                                </div>
                            </div>
                            <TextField
                                id="digitalIncidentSummaryId"
                                aria-required={true}
                                aria-labelledby={labelId}
                                label={getLocalizedValue("RIN_IncidentSummaryDesc")}
                                placeholder={getLocalizedValue("RIN_IncidentSummaryPlaceholder")}
                                multiline
                                rows={6}
                                onRenderDescription={() => description}
                                onRenderLabel={(props) =>
                                    renderDescriptionLabel(props, labelId)
                                }
                                value={
                                    digitalIncidentDetails
                                        .incidentDescription
                                }
                                onChange={handleDescriptionChange}
                                styles={
                                    errorStatesDetails
                                        .incidentDescription.length > 0
                                        ? {
                                            field: {
                                                backgroundColor: "#FED9CC",
                                            },
                                        }
                                        : {
                                            field: {
                                                backgroundColor: "white",
                                            },
                                        }
                                }
                            />

                        </div>

                        <FileUpload
                            allowdSizePerFileInMB={allowedSizePerFileInMB}
                            totalAllowedSizeInMB={totalAllowedSizeInMB}
                            checkFileTypes={true}
                            allowedFileTypes={[".msg", ".eml", ".mp3", ".png", ".jpg", ".jpeg", ".pdf", ".zip"]}
                            allowedFileNameLength={200}
                            isPhishingEmailIncident={isPhishingEmailIncident}
                            showCallOut={isCallOutPresent}
                            callOutComponent={isCallOutPresent === true ? callOutComponent : undefined}
                            disclaimerToBeShown={(selectedEventTypeL1?.typeId === DigitalEventTypes.ExposureOfPersonalData ||
                                selectedEventTypeL1?.typeId === DigitalEventTypes.ExposureOfSensitiveInformation) ? getLocalizedValue(CONSTANTS.SENSITIVEINFODISCLAIMER) : null}
                        />
                        {isCalloutVisible && isCallOutPresent && (
                            <FocusTrapCallout
                                directionalHint={DirectionalHint.topAutoEdge}
                                target={"#" + iconButtonId}
                                setInitialFocus
                                onDismiss={toggleIsCalloutVisible}
                                ariaDescribedBy={descriptionId}
                                role="alertdialog"
                                aria-live="assertive"
                            >
                                <DelayedRender>
                                    <FocusTrapZone isClickableOutsideFocusTrap>
                                        <Stack
                                            tokens={stackTokens}
                                            horizontalAlign="start"
                                            styles={labelCalloutStackStyles}
                                        >
                                            <span>
                                                <RichTextRenderer html={attachmentCalloutProps.calloutDescription} />
                                            </span>
                                        </Stack>
                                    </FocusTrapZone>
                                </DelayedRender>
                            </FocusTrapCallout>
                        )}
                    </>
                )}
            </div>
        );
    }
}
