import { MessageBarType } from "@fluentui/react";

export enum SiteNotificationType {
    caption = 1,
    general,
    info,
    error,
    blocked,
    severeWarning,
    success,
    warningSingleline,
    warningMultiline,
}

export function getMessageBarTypeFromNotificationType(notificationType: SiteNotificationType) {
    switch (notificationType) {
        case SiteNotificationType.info:
            return MessageBarType.info;
        case SiteNotificationType.error:
            return MessageBarType.error;
        case SiteNotificationType.blocked:
            return MessageBarType.blocked;
        case SiteNotificationType.severeWarning:
            return MessageBarType.severeWarning;
        case SiteNotificationType.success:
            return MessageBarType.success;
        case SiteNotificationType.warningSingleline:
            return MessageBarType.warning;
        case SiteNotificationType.warningMultiline:
            return MessageBarType.warning;
        default:
            return MessageBarType.info;
    }
}