import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { MaxFileCountAllowed } from "../../../Constants/CommonConstants";
import { AddedItemModel, AddedPersonModel, AddedVehicleModel, PhysicalIncidentModel, PhysicalIncidentTypesModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import { PsAttachmentInputViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsAttachmentInputViewModel";
import { PsItemClassRollupResponseViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsItemClassRollupResponseViewModel";
import { PsItemMakeModelRollupResponseViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsItemMakeModelRollupResponseViewModel";
import { PsLookupValueResponseViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsLookupValueResponseViewModel";
import { PsSiteRollupResponseViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsSiteRollupResponseViewModel";
import { PsVehicleMakeModelRollupResponseViewModel } from "../../../Contracts/ServiceModels/PhysicalSecurity/PsVehicleMakeModelRollupResponseViewModel";
import { PsPhysicalIncidentTypeResponseViewModel } from "../../../Contracts/TypesAndInterfaces/PsPhysicalIncidentTypeResponseViewModel";
import { getLocalizedValue } from "../../../Services/localizationService";

export enum PsResetLevel {
    CategoryChanged,
    IncidentTypeL1Changed,
    IncidentTypeL2Changed,
    IncidentTypeL3Changed,
    IncidentTypeL4Changed,
    SovereignIncidentTypeL1Changed,
}

const initialState: PhysicalIncidentModel = {
    physicalIncidentInformation: {
        incidentTimezone: "",
        incidentTimezoneOffset: new Date().getTimezoneOffset(),
        reportedDateTimeDt: new Date(new Date().setSeconds(0, 0)),
        occurredDateTimeDt: new Date(new Date().setSeconds(0, 0)),
        occurredToDateTimeDt: new Date(new Date().setSeconds(0, 0)),
        classRollupIdFk: "",
        incidentType:"",
        siteRollupIdFk: "",
        summaryNt: "",
        reportedToPoliceBt: false,
        policeFileNumberNv: undefined,
        reporterPhoneNumber: "",
        attachments: [],
        items: [],
        persons: [],
        vehicles: [],
    },
    physicalIncidentLocalizedInformation:{
        selectedincidentTypeNameL1:"",
        selectedincidentTypeNameL2:"",
        selectedincidentTypeNameL3:"",
        selectedincidentTypeNameL4:""
    },
    incidentTypes: {
        incidentTypesL1: [],
        incidentTypesL2: [],
        incidentTypesL3: [],
        incidentTypesL4: [],
        selectedincidentTypeIdL1: undefined,
        selectedincidentTypeIdL2: undefined,
        selectedincidentTypeIdL3: undefined,
        selectedincidentTypeIdL4: undefined,
        isL1ApiCallSuccess: true,
        isL2ApiCallSuccess: true,
        isL3ApiCallSuccess: true,
        isL4ApiCallSuccess: true
    },
    locationAndTime: {
        locationsL1: [],
        locationsL2: [],
        locationsL3: [],
        selectedLocationTypeIdL1: undefined,
        selectedLocationTypeIdL2: undefined,
        selectedLocationTypeIdL3: undefined,
    },
    additionalInfoLookups: {
        personInvolvementTypes: [],
        addedPeople: [],

        vehicleMakeTypes: [],
        vehicleModelTypes: [],
        vehicleStyleTypes: [],
        vehicleColorTypes: [],
        vehicleinvolvementTypes: [],
        addedVehicles: [],

        itemMakeTypes: [],
        itemModelTypes: [],
        itemCategoryTypes: [],
        itemSubCategoryTypes: [],
        addedItems: [],
    },
    occuredFromToHourAndMinute: {
        occuredFromHours: new Date().getHours(),
        occuredFromMinutes: new Date().getMinutes(),
        occuredToHours: new Date().getHours(),
        occuredToMinutes: new Date().getMinutes()
    },
    errorStates: {
        OccurredFrom: "",
        OccurredTo: "",
        PoliceFileNumber: "",
        PhoneNumber: "",
        IncidentSummary: "",
        country: "",
    },
    validationErrorCount: 0,
    validationError: "",
    isFormValid: false,
    isAdditionalInfoShown: false,
    isPhysicalIncidentPanelOpen: false,
};

const physicalIncidentSlice = createSlice({
    name: "physicalIncidentSlice",
    initialState,
    reducers: {
        setPhysicalIncidentPanelVisibility: (state, { payload }: PayloadAction<boolean>) => {
            state.isPhysicalIncidentPanelOpen = payload;
        },
        setOccurredDateTimeDate: (state, { payload }: PayloadAction<Date | null>) => {
            if (payload) {
                const newDate = state.physicalIncidentInformation.occurredDateTimeDt ?? new Date();
                newDate.setFullYear(payload.getFullYear(), payload.getMonth(), payload.getDate());
                state.physicalIncidentInformation.occurredDateTimeDt = newDate;
            } else {
                state.physicalIncidentInformation.occurredDateTimeDt = payload;
            }
        },
        setPhysicalIncidentTimeZone: (state, { payload }: PayloadAction<string>) => {
            state.physicalIncidentInformation.incidentTimezone = payload;
        },
        setAttachments: (state, { payload }: PayloadAction<PsAttachmentInputViewModel[]>) => {
            state.physicalIncidentInformation.attachments = payload;
        },
        setIsL1PhysicalIncidentTypesApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.incidentTypes.isL1ApiCallSuccess = payload;
        },
        setIsL2PhysicalIncidentTypesApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.incidentTypes.isL2ApiCallSuccess = payload;
        },
        setIsL3PhysicalIncidentTypesApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.incidentTypes.isL3ApiCallSuccess = payload;
        },
        setIsL4PhysicalIncidentTypesApiCallSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.incidentTypes.isL4ApiCallSuccess = payload;
        },
        setOccurredDateTimeHours: (state, { payload }: PayloadAction<number>) => {
            state.occuredFromToHourAndMinute.occuredFromHours = payload;

            if (payload < 24 && payload >= 0) {
                const newDate = new Date(state.physicalIncidentInformation.occurredDateTimeDt?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setHours(payload);
                state.physicalIncidentInformation.occurredDateTimeDt = newDate;
            }
        },

        setIsTestPhysicalIncidentIncident: (state, {payload}: PayloadAction<boolean | undefined>)=>{
            state.physicalIncidentInformation.isTestIncident = payload;
        },

        setOccurredDateTimeMinutes: (state, { payload }: PayloadAction<number>) => {
            state.occuredFromToHourAndMinute.occuredFromMinutes = payload;

            if (payload < 60 && payload >= 0) {
                const newDate = new Date(state.physicalIncidentInformation.occurredDateTimeDt?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setMinutes(payload);
                state.physicalIncidentInformation.occurredDateTimeDt = newDate;
            }
        },

        setOccurredToDateTimeDate: (state, { payload }: PayloadAction<Date | null>) => {
            if (payload) {
                const newDate = state.physicalIncidentInformation.occurredToDateTimeDt ?? new Date();
                newDate.setFullYear(payload.getFullYear(), payload.getMonth(), payload.getDate());
                state.physicalIncidentInformation.occurredToDateTimeDt = newDate;
            } else {
                state.physicalIncidentInformation.occurredToDateTimeDt = payload;
            }
        },

        setOccurredToDateTimeHours: (state, { payload }: PayloadAction<number>) => {
            state.occuredFromToHourAndMinute.occuredToHours = payload;

            if (payload < 24 && payload >= 0) {
                const newDate = new Date(state.physicalIncidentInformation.occurredToDateTimeDt?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setHours(payload);
                state.physicalIncidentInformation.occurredToDateTimeDt = newDate;
            }
        },

        setOccurredToDateTimeMinutes: (state, { payload }: PayloadAction<number>) => {
            state.occuredFromToHourAndMinute.occuredToMinutes = payload;

            if (payload < 60 && payload >= 0) {
                const newDate = new Date(state.physicalIncidentInformation.occurredToDateTimeDt?.setSeconds(0, 0) ?? new Date().setSeconds(0, 0));
                newDate.setMinutes(payload);
                state.physicalIncidentInformation.occurredToDateTimeDt = newDate;
            }
        },

        setReportedToPoliceBt: (state, { payload }: PayloadAction<boolean>) => {
            state.physicalIncidentInformation.reportedToPoliceBt = payload;
            if (!payload)
                state.physicalIncidentInformation.policeFileNumberNv = undefined;
        },

        setPoliceFileNumberNv: (state, { payload }: PayloadAction<string | undefined>) => {
            state.physicalIncidentInformation.policeFileNumberNv = payload;
        },

        setReporterPhoneNumber: (state, { payload }: PayloadAction<string>) => {
            state.physicalIncidentInformation.reporterPhoneNumber = payload;
        },

        setSummaryNt: (state, { payload }: PayloadAction<string>) => {
            state.physicalIncidentInformation.summaryNt = payload;
        },

        getPsIncidentTypesL1: (state) => {

        },

        getPsLocationsL1: (state, { payload }: PayloadAction<boolean | undefined>) => {

        },

        setPsincidentTypesL1: (state, { payload }: PayloadAction<PsPhysicalIncidentTypeResponseViewModel[]>) => {
            state.incidentTypes.incidentTypesL1 = payload;
        },

        setPsincidentTypesL2: (state, { payload }: PayloadAction<PsPhysicalIncidentTypeResponseViewModel[]>) => {
            state.incidentTypes.incidentTypesL2 = payload;

            if (!payload || payload.length == 0)
                state.isAdditionalInfoShown = true;
        },

        setPsincidentTypesL3: (state, { payload }: PayloadAction<PsPhysicalIncidentTypeResponseViewModel[]>) => {
            state.incidentTypes.incidentTypesL3 = payload;

            //if (!payload || payload.length == 0)
            state.isAdditionalInfoShown = true;
        },

        setPsincidentTypesL4: (state, { payload }: PayloadAction<PsPhysicalIncidentTypeResponseViewModel[]>) => {
            state.incidentTypes.incidentTypesL4 = payload;

            state.isAdditionalInfoShown = true;
        },

        setPsSelectedIncidentTypeIdL1: (state, { payload }: PayloadAction<{ id: number, perspectiveDbId: string | null | undefined, label: string} | undefined>) => {
            state.incidentTypes.selectedincidentTypeIdL1 = payload;
        },

        getPsIncidentTypesL2: (state, { payload }: PayloadAction<{ id: number, perspectiveDbId: string | null | undefined, label: string, selectedSovereignId?: number } | undefined>) => {
        },

        setPsSelectedIncidentTypeIdL2: (state, { payload }: PayloadAction<{ id: number, perspectiveDbId: string | null | undefined, label: string, selectedSovereignId?: number } | undefined>) => {
            state.incidentTypes.selectedincidentTypeIdL2 = payload;
        },

        setPsSelectedIncidentTypeIdL3: (state, { payload }: PayloadAction<{ id: number, perspectiveDbId: string | null | undefined, label: string, selectedSovereignId?: number } | undefined>) => {
            state.incidentTypes.selectedincidentTypeIdL3 = payload;
        },

        setPsSelectedIncidentTypeIdL4: (state, { payload }: PayloadAction<{ id: number, perspectiveDbId: string | null | undefined, label: string, selectedSovereignId?: number } | undefined>) => {
            state.incidentTypes.selectedincidentTypeIdL4 = payload;
        },

        showAdditionalInfo: (state) => { state.isAdditionalInfoShown = true },
        hideAdditionalInfo: (state) => { state.isAdditionalInfoShown = false },

        setPsLocationsL1: (state, { payload }: PayloadAction<PsSiteRollupResponseViewModel[]>) => {
            state.locationAndTime.locationsL1 = payload;
        },

        setPsLocationsL2: (state, { payload }: PayloadAction<PsSiteRollupResponseViewModel[]>) => {
            state.locationAndTime.locationsL2 = payload;
        },

        setPsLocationsL3: (state, { payload }: PayloadAction<PsSiteRollupResponseViewModel[]>) => {
            state.locationAndTime.locationsL3 = payload;
        },

        setPsSelectedLocationIdL1: (state, { payload }: PayloadAction<string | undefined>) => {
            state.locationAndTime.selectedLocationTypeIdL1 = payload;

            state.locationAndTime.locationsL2 = [];
            state.locationAndTime.locationsL3 = [];
            state.locationAndTime.selectedLocationTypeIdL2 = undefined;
            state.locationAndTime.selectedLocationTypeIdL3 = undefined;
        },

        setPsSelectedLocationIdL2: (state, { payload }: PayloadAction<string | undefined>) => {
            state.locationAndTime.selectedLocationTypeIdL2 = payload;

            state.locationAndTime.locationsL3 = [];
            state.locationAndTime.selectedLocationTypeIdL3 = undefined;
        },

        setPsSelectedLocationIdL3: (state, { payload }: PayloadAction<string | undefined>) => {
            state.locationAndTime.selectedLocationTypeIdL3 = payload;
        },


        getPersonInvolvementTypes: (state, { payload }: PayloadAction<boolean | undefined>) => {

        },

        setPersonInvolvementTypes: (state, { payload }: PayloadAction<PsLookupValueResponseViewModel[]>) => {
            state.additionalInfoLookups.personInvolvementTypes = payload;
        },

        setAddedPeople: (state, { payload }: PayloadAction<AddedPersonModel[]>) => {
            state.additionalInfoLookups.addedPeople = payload;
        },

        validateAndAddPerson: (state, { payload }: PayloadAction<AddedPersonModel>) => {
            payload.id = Math.max(0, ...(state.additionalInfoLookups.addedPeople.map(x => x.id))) + 1
            state.additionalInfoLookups.addedPeople = [...(state.additionalInfoLookups.addedPeople), payload];
        },

        validateAndUpdatePerson: (state, { payload }: PayloadAction<AddedPersonModel>) => {

            let index = state.additionalInfoLookups.addedPeople.findIndex(x => x.id === payload.id);
            if (index >= 0) {
                state.additionalInfoLookups.addedPeople[index].eemployeeBt = payload.eemployeeBt;
                state.additionalInfoLookups.addedPeople[index].involvementType = payload.involvementType;
                state.additionalInfoLookups.addedPeople[index].etitleLookupIdFk = payload.etitleLookupIdFk;
                state.additionalInfoLookups.addedPeople[index].PersInvolvementTypeLookupIdFk = payload.PersInvolvementTypeLookupIdFk;
                state.additionalInfoLookups.addedPeople[index].elastNameNv = payload.elastNameNv;
                state.additionalInfoLookups.addedPeople[index].efirstNameNv = payload.efirstNameNv;
                state.additionalInfoLookups.addedPeople[index].primaryPhoneNumberNv = payload.primaryPhoneNumberNv;
                state.additionalInfoLookups.addedPeople[index].primaryEmailNv = payload.primaryEmailNv;
                state.additionalInfoLookups.addedPeople[index].supplementalInformationNt = payload.supplementalInformationNt;

            }
        },

        deletePersonFromAddedPeople: (state, { payload }: PayloadAction<number>) => {
            state.additionalInfoLookups.addedPeople = state.additionalInfoLookups.addedPeople.filter(x => x.id != payload);
        },

        getVehicleInvolvementTypes: (state, { payload }: PayloadAction<boolean | undefined>) => {

        },

        setVehicleInvolvementTypes: (state, { payload }: PayloadAction<PsLookupValueResponseViewModel[]>) => {
            state.additionalInfoLookups.vehicleinvolvementTypes = payload;
        },

        getVehicleMakeTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setVehicleMakeTypes: (state, { payload }: PayloadAction<PsVehicleMakeModelRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.vehicleMakeTypes = payload;
        },

        setSelectedVehicleMakeTypeId: (state, { payload }: PayloadAction<string | undefined>) => {
            if (payload?.length) {
                state.additionalInfoLookups.selectedVehicleMakeTypeId = payload;
                state.additionalInfoLookups.vehicleModelTypes = [];
            }
        },

        getVehicleModelTypes: () => { },

        setVehicleModelTypes: (state, { payload }: PayloadAction<PsVehicleMakeModelRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.vehicleModelTypes = payload;
        },

        getVehicleColorTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setVehicleColorTypes: (state, { payload }: PayloadAction<PsLookupValueResponseViewModel[]>) => {
            state.additionalInfoLookups.vehicleColorTypes = payload;
        },

        getVehicleStyleTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setVehicleStyleTypes: (state, { payload }: PayloadAction<PsLookupValueResponseViewModel[]>) => {
            state.additionalInfoLookups.vehicleStyleTypes = payload;
        },

        validateAndAddVehicle: (state, { payload }: PayloadAction<AddedVehicleModel>) => {
            payload.id = Math.max(0, ...(state.additionalInfoLookups.addedVehicles.map(x => x.id))) + 1
            state.additionalInfoLookups.addedVehicles = [...(state.additionalInfoLookups.addedVehicles), payload];
        },

        validateAndUpdateVehicle: (state, { payload }: PayloadAction<AddedVehicleModel>) => {

            let index = state.additionalInfoLookups.addedVehicles.findIndex(x => x.id === payload.id);
            if (index >= 0) {
                state.additionalInfoLookups.addedVehicles[index].selectedMakeId = payload.selectedMakeId;
                state.additionalInfoLookups.addedVehicles[index].selectedModelId = payload.selectedModelId;
                state.additionalInfoLookups.addedVehicles[index].selectedInvolvement = payload.selectedInvolvement;
                state.additionalInfoLookups.addedVehicles[index].vehInvolvementTypeLookupIdFk = payload.vehInvolvementTypeLookupIdFk;
                state.additionalInfoLookups.addedVehicles[index].elicensePlateNv = payload.elicensePlateNv;
                state.additionalInfoLookups.addedVehicles[index].eyearNm = payload.eyearNm;
                state.additionalInfoLookups.addedVehicles[index].evehMakeModelRollupIdFk = payload.evehMakeModelRollupIdFk;
                state.additionalInfoLookups.addedVehicles[index].evehicleStyleLookupIdFk = payload.evehicleStyleLookupIdFk;
                state.additionalInfoLookups.addedVehicles[index].evehicleColorLookupIdFk = payload.evehicleColorLookupIdFk;
                state.additionalInfoLookups.addedVehicles[index].ecompanyVehicleBt = payload.ecompanyVehicleBt;
                state.additionalInfoLookups.addedVehicles[index].evehicleDriverFullNameNv = payload.evehicleDriverFullNameNv;
                state.additionalInfoLookups.addedVehicles[index].commentsNt = payload.commentsNt;
            }
        },

        deleteVehicleFromAddedVehicles: (state, { payload }: PayloadAction<number>) => {
            state.additionalInfoLookups.addedVehicles = state.additionalInfoLookups.addedVehicles.filter(x => x.id != payload);
        },

        getItemMakeTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setItemMakeTypes: (state, { payload }: PayloadAction<PsItemMakeModelRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.itemMakeTypes = payload;
        },

        setSelectedItemMakeTypeId: (state, { payload }: PayloadAction<string | undefined>) => {
            if (payload?.length) {
                state.additionalInfoLookups.selectedItemMakeTypeId = payload;
                state.additionalInfoLookups.itemModelTypes = [];
            }
        },

        getItemModelTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setItemModelTypes: (state, { payload }: PayloadAction<PsItemMakeModelRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.itemModelTypes = payload;
        },

        getItemCategoryTypes: (state, { payload }: PayloadAction<boolean | undefined>) => { },

        setItemCategoryTypes: (state, { payload }: PayloadAction<PsItemClassRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.itemCategoryTypes = payload;
        },
        setSelectedItemCategoryTypeId: (state, { payload }: PayloadAction<string | undefined>) => {
            if (payload?.length) {
                state.additionalInfoLookups.selectedItemCategoryTypeId = payload;
                state.additionalInfoLookups.itemSubCategoryTypes = [];
            }
        },
        getItemSubCategoryTypes: () => { },
        setItemSubCategoryTypes: (state, { payload }: PayloadAction<PsItemClassRollupResponseViewModel[]>) => {
            state.additionalInfoLookups.itemSubCategoryTypes = payload;
        },

        validateAndAddItem: (state, { payload }: PayloadAction<AddedItemModel>) => {
            payload.id = Math.max(0, ...(state.additionalInfoLookups.addedItems.map(x => x.id))) + 1
            state.additionalInfoLookups.addedItems = [...(state.additionalInfoLookups.addedItems), payload];
        },

        validateAndUpdateItem: (state, { payload }: PayloadAction<AddedItemModel>) => {

            let index = state.additionalInfoLookups.addedItems.findIndex(x => x.id === payload.id);
            if (index >= 0) {
                state.additionalInfoLookups.addedItems[index].selectedMakeId = payload.selectedMakeId;
                state.additionalInfoLookups.addedItems[index].selectedModelId = payload.selectedModelId;
                state.additionalInfoLookups.addedItems[index].selectedCategoryId = payload.selectedCategoryId;
                state.additionalInfoLookups.addedItems[index].selectedSubCategoryId = payload.selectedSubCategoryId;
                state.additionalInfoLookups.addedItems[index].eitemNameNv = payload.eitemNameNv;
                state.additionalInfoLookups.addedItems[index].eserialNumberNv = payload.eserialNumberNv;
                state.additionalInfoLookups.addedItems[index].eitemClassRollupIdFk = payload.eitemClassRollupIdFk;
                state.additionalInfoLookups.addedItems[index].eitemMakeModelRollupIdFk = payload.eitemMakeModelRollupIdFk;
                state.additionalInfoLookups.addedItems[index].eitemValueNm = payload.eitemValueNm;
                state.additionalInfoLookups.addedItems[index].eownerNamePersonNv = payload.eownerNamePersonNv;
                state.additionalInfoLookups.addedItems[index].itemNotesNt = payload.itemNotesNt;
            }
        },

        deleteItemFromAddedItems: (state, { payload }: PayloadAction<number>) => {
            state.additionalInfoLookups.addedItems = state.additionalInfoLookups.addedItems.filter(x => x.id != payload);
        },

        validatePhysicalSecurityincident: (state) => {
            let validationErrorCount = 0;
            let totalFileSize = 0;
            let validationError = "";
            var valid = true;


            if (!state.locationAndTime.selectedLocationTypeIdL1) {
                validationError = getLocalizedValue("RIN_CountryIsRequired")
                state.errorStates.country = validationError;
                if (valid) {
                    document.getElementById("physicalIncidentCountryId")?.focus();
                }
                valid = false;
                validationErrorCount++;
            }

            const currentDateTime = new Date();

            if (state.physicalIncidentInformation.occurredDateTimeDt == null) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_Expectedformatis");
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredFromHours > 23) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_HourCannotBeGreaterThan");
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredFromHours < 0) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_HourCannotBeLessThan");
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredFromMinutes > 59) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_MinuteCannotBeGreaterThan");
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredFromMinutes < 0) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_MinuteCannotBeLessThan");
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.physicalIncidentInformation.occurredDateTimeDt > currentDateTime) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredFromDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_OccuredFromCannotBeSetToAFutureTime")
                state.errorStates.OccurredFrom = validationError;
                valid = false;
                validationErrorCount++;
            }
            else {
                state.errorStates.OccurredFrom = "";
            }

            if (state.physicalIncidentInformation.occurredToDateTimeDt == null) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_ExpectedFormatIsDayMonthDateYear")
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredToHours > 23) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_HourCannotBeGreaterThan");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredToHours < 0) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_HourCannotBeLessThan");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredToMinutes > 59) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_MinuteCannotBeGreaterThan");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.occuredFromToHourAndMinute.occuredToMinutes < 0) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_MinuteCannotBeLessThan");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.physicalIncidentInformation.occurredToDateTimeDt && state.physicalIncidentInformation.occurredToDateTimeDt > currentDateTime) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_OccuredToCannotBeSetToAFutureTime");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else if (state.physicalIncidentInformation.occurredToDateTimeDt && state.physicalIncidentInformation.occurredDateTimeDt && state.physicalIncidentInformation.occurredToDateTimeDt < state.physicalIncidentInformation.occurredDateTimeDt) {
                if (valid) {
                    document.getElementById("physicalIncidentOccuredToDateId-label")?.focus();
                }
                validationError = getLocalizedValue("RIN_OccuredToMustBeGreaterThanOrEqualToOccuredFrom");
                state.errorStates.OccurredTo = validationError;
                valid = false;
                validationErrorCount++;
            }
            else {
                state.errorStates.OccurredTo = "";
            }

            if (state.physicalIncidentInformation.reportedToPoliceBt) {
                if (!(state.physicalIncidentInformation.policeFileNumberNv?.trim()?.length)) {
                    validationError = getLocalizedValue("RIN_PoliceFileNumberIsRequired")
                    state.errorStates.PoliceFileNumber = validationError;
                    if (valid) {
                        document.getElementById("physicalIncidentPoliceFileNumberId")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                }
                else if (state.physicalIncidentInformation.policeFileNumberNv.trim().length > 50) {
                    validationError = getLocalizedValue("RIN_PoliceFileNumberCannotBeLongerThan")
                    state.errorStates.PoliceFileNumber = validationError;
                    if (valid) {
                        document.getElementById("physicalIncidentPoliceFileNumberId")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                }
            }

            if (!(state.physicalIncidentInformation.reporterPhoneNumber?.trim()?.length)) {
                validationError = getLocalizedValue("RIN_PhoneNumberIsRequired")
                state.errorStates.PhoneNumber = validationError;
                if (valid) {
                    document.getElementById("physicalIncidentPhoneNumberId")?.focus();
                }
                valid = false;
                validationErrorCount++;
            }
            else if (state.physicalIncidentInformation.reporterPhoneNumber?.trim()?.length > 25) {
                validationError = getLocalizedValue("RIN_PhoneNumberCannotBeLongerThanCharacters")
                state.errorStates.PhoneNumber = validationError;
                if (valid) {
                    document.getElementById("physicalIncidentPhoneNumberId")?.focus();
                }
                valid = false;
                validationErrorCount++;
            }


            if (!(state.physicalIncidentInformation.summaryNt?.trim()?.length)) {
                validationError = getLocalizedValue("RIN_IncidentSummaryIsRequired")
                state.errorStates.IncidentSummary = validationError;
                if (valid) {
                    document.getElementById("physicalIncidentSummaryId")?.focus();
                }
                valid = false;
                validationErrorCount++;
            }
            else if (state.physicalIncidentInformation.summaryNt?.trim()?.length > 4000) {
                validationError = getLocalizedValue("RIN_IncidentSummaryCannotBeLongerThanCharacters")
                state.errorStates.IncidentSummary = validationError;
                if (valid) {
                    document.getElementById("physicalIncidentSummaryId")?.focus();
                }
                valid = false;
                validationErrorCount++;
            }


            if (state.physicalIncidentInformation.attachments) {
                state.physicalIncidentInformation.attachments.forEach(attachment => {
                    totalFileSize += attachment?.fileSizeIn ?? 0;
                    if ((attachment?.fileSizeIn ?? 0) > 5 * 1024 * 1024) {
                        if (valid) {
                            document.getElementById("fileUploadInputId")?.focus();
                        }
                        valid = false;
                        validationErrorCount++;
                        validationError = attachment.fileNameNv +getLocalizedValue("RIN_ExceededAllowedAttachmentSizeOf") +" 5MB";
                    }
                    if (attachment.fileNameNv.length > 100) {
                        if (valid) {
                            document.getElementById("fileUploadInputId")?.focus();
                        }
                        valid = false;
                        validationErrorCount++;
                        validationError = attachment.fileNameNv + " "+getLocalizedValue("RIN_ExceededTheAllowedAttachmentNameLength");
                    }
                });

                if(state.physicalIncidentInformation.attachments.length>MaxFileCountAllowed)
                {
                    if (valid) {
                        document.getElementById("fileUploadInputId")?.focus();
                    }
                    valid = false;
                    validationErrorCount++;
                    validationError = getLocalizedValue("RIN_FileUploadFileCountError");
                }
            }

            if (totalFileSize > 20 * 1024 * 1024) {
                if (valid) {
                    document.getElementById("fileUploadInputId")?.focus();
                }
                valid = false;
                validationErrorCount++;
                validationError = getLocalizedValue("RIN_TotalAttachmentSizeExceeded");
            }

            state.isFormValid = valid;
            state.validationErrorCount = validationErrorCount;
            state.validationError = validationError;
        },

        resetPhysicalIncidentInformation: (state, { payload }: PayloadAction<PsResetLevel>) => {

            if (payload <= PsResetLevel.IncidentTypeL3Changed) {
                state.incidentTypes.incidentTypesL4 = [];
                state.incidentTypes.selectedincidentTypeIdL4 = undefined;

                if (payload <= PsResetLevel.IncidentTypeL2Changed) {

                    state.physicalIncidentInformation = {
                        incidentTimezone: "",
                        incidentTimezoneOffset: new Date().getTimezoneOffset(),
                        reportedDateTimeDt: new Date(new Date().setSeconds(0, 0)),
                        occurredDateTimeDt: new Date(new Date().setSeconds(0, 0)),
                        occurredToDateTimeDt: new Date(new Date().setSeconds(0, 0)),
                        classRollupIdFk: "",
                        incidentType:"",
                        siteRollupIdFk: "",
                        summaryNt: "",
                        reportedToPoliceBt: false,
                        policeFileNumberNv: undefined,
                        reporterPhoneNumber: "",
                        attachments: [],
                        items: [],
                        persons: [],
                        vehicles: [],
                        // TODO: change the value of isTestIncident to undefined when this functionality is also added to the existing UI.
                        isTestIncident: state.physicalIncidentInformation.isTestIncident,
                    }

                    state.locationAndTime.locationsL2 = [];
                    state.locationAndTime.locationsL3 = [];
                    state.locationAndTime.selectedLocationTypeIdL1 = null;
                    state.locationAndTime.selectedLocationTypeIdL2 = null;
                    state.locationAndTime.selectedLocationTypeIdL3 = null;

                    state.additionalInfoLookups.addedItems = [];
                    state.additionalInfoLookups.addedVehicles = [];
                    state.additionalInfoLookups.addedPeople = [];
                    state.additionalInfoLookups.itemModelTypes = [];
                    state.additionalInfoLookups.itemSubCategoryTypes = [];
                    state.additionalInfoLookups.vehicleModelTypes = [];
                    state.additionalInfoLookups.selectedItemCategoryTypeId = undefined;
                    state.additionalInfoLookups.selectedVehicleMakeTypeId = undefined;
                    state.additionalInfoLookups.selectedItemMakeTypeId = undefined;
                    state.occuredFromToHourAndMinute.occuredFromHours = new Date().getHours();
                    state.occuredFromToHourAndMinute.occuredFromMinutes = new Date().getMinutes();
                    state.occuredFromToHourAndMinute.occuredToHours = new Date().getHours();
                    state.occuredFromToHourAndMinute.occuredToMinutes = new Date().getMinutes();
                    state.incidentTypes.incidentTypesL3 = [];
                    state.incidentTypes.selectedincidentTypeIdL3 = undefined;

                    state.errorStates = {
                        OccurredFrom: "",
                        OccurredTo: "",
                        PoliceFileNumber: "",
                        PhoneNumber: "",
                        IncidentSummary: "",
                        country: "",
                    };

                    state.validationErrorCount = 0;
                    state.validationError = "";
                    state.isFormValid = false;

                    if (payload <= PsResetLevel.IncidentTypeL1Changed) {
                        state.incidentTypes.incidentTypesL2 = [];
                        state.incidentTypes.selectedincidentTypeIdL2 = undefined;

                        if (payload <= PsResetLevel.CategoryChanged) {
                            state.incidentTypes.selectedincidentTypeIdL1 = undefined;
                        }
                    }
                }
            }

            if (payload == PsResetLevel.CategoryChanged)
                state.isAdditionalInfoShown = false;
        },

        resetValidationMessageOccurredFrom: (state, { payload }: PayloadAction<string>) => { state.errorStates.OccurredFrom = payload },
        resetValidationMessageOccurredTo: (state, { payload }: PayloadAction<string>) => { state.errorStates.OccurredTo = payload },
        resetValidationMessagePoliceFileNumber: (state) => { state.errorStates.PoliceFileNumber = "" },
        resetValidationMessagePhoneNumber: (state) => { state.errorStates.PhoneNumber = "" },
        resetValidationMessageIncidentSummary: (state) => { state.errorStates.IncidentSummary = "" },
        resetValidationMessageCountry: (state) => { state.errorStates.country = "" },

        submitPhysicalSecurityIncident: (state, {payload}: PayloadAction<NavigateFunction | undefined>) => {

            state.physicalIncidentInformation.policeFileNumberNv = state.physicalIncidentInformation.policeFileNumberNv?.trim();
            state.physicalIncidentInformation.reporterPhoneNumber = state.physicalIncidentInformation.reporterPhoneNumber?.trim();
            state.physicalIncidentInformation.summaryNt = state.physicalIncidentInformation.summaryNt?.trim();

            state.physicalIncidentInformation.classRollupIdFk = state.incidentTypes.selectedincidentTypeIdL4?.perspectiveDbId ?? state.incidentTypes.selectedincidentTypeIdL3?.perspectiveDbId ?? state.incidentTypes.selectedincidentTypeIdL2?.perspectiveDbId ?? state.incidentTypes.selectedincidentTypeIdL1?.perspectiveDbId ?? "";
            state.physicalIncidentInformation.incidentType = state.incidentTypes.incidentTypesL1.find((incidentTypeL1)=>incidentTypeL1.perspectiveDbId===state.incidentTypes.selectedincidentTypeIdL1?.perspectiveDbId)?.name ?? "";
            state.physicalIncidentInformation.reportedDateTimeDt = new Date();
            state.physicalIncidentInformation.siteRollupIdFk = state.locationAndTime.selectedLocationTypeIdL3 ?? state.locationAndTime.selectedLocationTypeIdL2 ?? state.locationAndTime.selectedLocationTypeIdL1 ?? "";

            state.physicalIncidentInformation.persons = state.additionalInfoLookups.addedPeople.map(x => {
                return {
                    etitleLookupIdFk: x.etitleLookupIdFk,
                    PersInvolvementTypeLookupIdFk: x.PersInvolvementTypeLookupIdFk,
                    PersInvolvementTypeName:state.additionalInfoLookups.personInvolvementTypes.find((pInv)=>pInv.idPk===x.PersInvolvementTypeLookupIdFk)?.valueNameNv,
                    eemployeeBt: x.eemployeeBt,
                    elastNameNv: x.elastNameNv,
                    efirstNameNv: x.efirstNameNv,
                    primaryPhoneNumberNv: x.primaryPhoneNumberNv,
                    primaryEmailNv: x.primaryEmailNv,
                    supplementalInformationNt: x.supplementalInformationNt,
                }
            });
            state.physicalIncidentInformation.vehicles = state.additionalInfoLookups.addedVehicles.map(x => {
                return {
                    vehInvolvementTypeLookupIdFk: x.vehInvolvementTypeLookupIdFk,
                    vehicleInvolvementTypeName:state.additionalInfoLookups.vehicleinvolvementTypes.find((vInvType)=>vInvType.idPk === x.vehInvolvementTypeLookupIdFk)?.valueNameNv??"",
                    elicensePlateNv: x.elicensePlateNv,
                    eyearNm: x.eyearNm,
                    evehMakeModelRollupIdFk: x.evehMakeModelRollupIdFk,
                    evehicleStyleLookupIdFk: x.evehicleStyleLookupIdFk,
                    evehicleColorLookupIdFk: x.evehicleColorLookupIdFk,
                    ecompanyVehicleBt: x.ecompanyVehicleBt,
                    evehicleDriverFullNameNv: x.evehicleDriverFullNameNv,
                    commentsNt: x.commentsNt,
                }
            });
            state.physicalIncidentInformation.items = state.additionalInfoLookups.addedItems.map(x => {
                return {
                    eitemNameNv: x.eitemNameNv,
                    eserialNumberNv: x.eserialNumberNv,
                    eitemClassRollupIdFk: x.eitemClassRollupIdFk,
                    eitemMakeModelRollupIdFk: x.eitemMakeModelRollupIdFk,
                    eitemValueNm: x.eitemValueNm,
                    eownerNamePersonNv: x.eownerNamePersonNv,
                    itemNotesNt: x.itemNotesNt,
                }
            });
        },

        validatePhysicalIncidentDataV2: (state, {payload}: PayloadAction<NavigateFunction>)=>{},
        setPsLocalizedSelectedIncidentTypeNameL1:(state,{payload}:PayloadAction<string>)=>{
            state.physicalIncidentLocalizedInformation.selectedincidentTypeNameL1 = payload;
        },
        setPsLocalizedSelectedIncidentTypeNameL2:(state,{payload}:PayloadAction<string>)=>{
            state.physicalIncidentLocalizedInformation.selectedincidentTypeNameL2 = payload;
        },
        setPsLocalizedSelectedIncidentTypeNameL3:(state,{payload}:PayloadAction<string>)=>{
            state.physicalIncidentLocalizedInformation.selectedincidentTypeNameL3 = payload;
        },
        setPsLocalizedSelectedIncidentTypeNameL4:(state,{payload}:PayloadAction<string>)=>{
            state.physicalIncidentLocalizedInformation.selectedincidentTypeNameL4 = payload;
        }
    }
});

export const {
    setPhysicalIncidentPanelVisibility,
    setPhysicalIncidentTimeZone,
    setAttachments,
    setIsL1PhysicalIncidentTypesApiCallSuccess,
    setIsL2PhysicalIncidentTypesApiCallSuccess,
    setIsL3PhysicalIncidentTypesApiCallSuccess,
    setIsL4PhysicalIncidentTypesApiCallSuccess,
    setOccurredDateTimeDate,
    setOccurredDateTimeHours,
    setOccurredDateTimeMinutes,
    setOccurredToDateTimeDate,
    setOccurredToDateTimeHours,
    setOccurredToDateTimeMinutes,
    setReportedToPoliceBt,
    setPoliceFileNumberNv,
    setReporterPhoneNumber,
    setSummaryNt,
    getPsIncidentTypesL1,
    getPsLocationsL1,
    setPsincidentTypesL1,
    setPsincidentTypesL2,
    setPsincidentTypesL3,
    setPsincidentTypesL4,
    setPsSelectedIncidentTypeIdL1,
    getPsIncidentTypesL2,
    setPsSelectedIncidentTypeIdL2,
    setPsSelectedIncidentTypeIdL3,
    setPsSelectedIncidentTypeIdL4,
    showAdditionalInfo,
    hideAdditionalInfo,
    setPsLocationsL1,
    setPsLocationsL2,
    setPsLocationsL3,
    setPsSelectedLocationIdL1,
    setPsSelectedLocationIdL2,
    setPsSelectedLocationIdL3,
    getPersonInvolvementTypes,
    setPersonInvolvementTypes,
    getVehicleInvolvementTypes,
    setVehicleInvolvementTypes,
    setAddedPeople,
    validateAndAddPerson,
    validateAndUpdatePerson,
    deletePersonFromAddedPeople,
    getVehicleMakeTypes,
    setVehicleMakeTypes,
    setSelectedVehicleMakeTypeId,
    getVehicleModelTypes,
    setVehicleModelTypes,
    getVehicleColorTypes,
    setVehicleColorTypes,
    getVehicleStyleTypes,
    setVehicleStyleTypes,
    validateAndAddVehicle,
    validateAndUpdateVehicle,
    getItemMakeTypes,
    setItemMakeTypes,
    setSelectedItemMakeTypeId,
    getItemModelTypes,
    setItemModelTypes,
    getItemCategoryTypes,
    setItemCategoryTypes,
    setSelectedItemCategoryTypeId,
    getItemSubCategoryTypes,
    setItemSubCategoryTypes,
    validateAndAddItem,
    validateAndUpdateItem,
    deleteVehicleFromAddedVehicles,
    deleteItemFromAddedItems,

    validatePhysicalSecurityincident,
    resetPhysicalIncidentInformation,
    resetValidationMessageOccurredFrom,
    resetValidationMessageOccurredTo,
    resetValidationMessagePoliceFileNumber,
    resetValidationMessagePhoneNumber,
    resetValidationMessageIncidentSummary,
    resetValidationMessageCountry,

    submitPhysicalSecurityIncident,
    validatePhysicalIncidentDataV2,
    setIsTestPhysicalIncidentIncident,
    setPsLocalizedSelectedIncidentTypeNameL1,
    setPsLocalizedSelectedIncidentTypeNameL2,
    setPsLocalizedSelectedIncidentTypeNameL3,
    setPsLocalizedSelectedIncidentTypeNameL4
} = physicalIncidentSlice.actions;


export const physicalIncidentInformationSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation;
export const physicalIncidentSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident;

export const occurredDateTimeDtSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.occurredDateTimeDt;

export const occurredToDateTimeDtSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.occurredToDateTimeDt;

export const PsIncidentTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes;

export const PsIncidentTypesL1Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.incidentTypesL1;

export const PsIncidentTypesL2Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.incidentTypesL2;

export const PsIncidentTypesL3Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.incidentTypesL3;

export const PsIncidentTypesL4Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.incidentTypesL4;

export const PsSelectedincidentTypeIdL1Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.selectedincidentTypeIdL1;

export const PsSelectedincidentTypeIdL2Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.selectedincidentTypeIdL2;

export const PsSelectedincidentTypeIdL3Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.selectedincidentTypeIdL3;

export const PsSelectedincidentTypeIdL4Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.selectedincidentTypeIdL4;

export const PsShowAdditionalInfoSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.isAdditionalInfoShown;

export const PsSelectedLocationIdL1Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.selectedLocationTypeIdL1;

export const PsSelectedLocationIdL2Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.selectedLocationTypeIdL2;

export const PsSelectedLocationIdL3Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.selectedLocationTypeIdL3;

export const PsLocationsL1Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.locationsL1;

export const PsLocationsL2Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.locationsL2;

export const PsLocationsL3Selector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.locationAndTime.locationsL3;

export const PsAdditionalInfoSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups;

export const PsAddedPeopleSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.addedPeople;

export const PsPersonInvolvementTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.personInvolvementTypes;

export const PsAddedVehiclesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.addedVehicles;

export const PsVehicleInvolvementTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.vehicleinvolvementTypes;

export const PsVehicleMakeTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.vehicleMakeTypes;

export const PsVehicleModelTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.vehicleModelTypes;

export const PsVehicleColorTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.vehicleColorTypes;

export const PsVehicleStyleTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.vehicleStyleTypes;

export const PsSelectedVehicleMakeTypeIdSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.selectedVehicleMakeTypeId;

export const PsAddedItemsSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.addedItems;

export const PsItemMakeTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.itemMakeTypes;

export const PsItemModelTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.itemModelTypes;

export const PsItemCategoryTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.itemCategoryTypes;

export const PsItemSubCategoryTypesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.itemSubCategoryTypes;

export const PsSelectedItemMakeTypeIdSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.selectedItemMakeTypeId;

export const PsSelectedItemCategoryTypeIdSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.additionalInfoLookups.selectedItemCategoryTypeId;
export const PsPoliceReportStatusSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.reportedToPoliceBt;
export const PsPoliceFileNumberSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.policeFileNumberNv;

export const PsErrorStatesSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.errorStates;

export const PsOccurredFromToHourAndMinuteSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.occuredFromToHourAndMinute;

export const PsValidationErrorMessageSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.validationError;

export const PsValidationErrorCountSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.validationErrorCount;

export const PsL1IncidentTypeApiCallSuccess = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.isL1ApiCallSuccess;

export const PsL2IncidentTypeApiCallSuccess = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.isL2ApiCallSuccess;

export const PsL3IncidentTypeApiCallSuccess = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.isL3ApiCallSuccess;

export const PsL4IncidentTypeApiCallSuccess = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.incidentTypes.isL4ApiCallSuccess;

export const IsPhysicalSecurityFormValidSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.isFormValid;

export const SelectedPhysicalIncidentTimeZoneSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.incidentTimezone;

export const PhysicalIncidentTypesPanelVisibilitySelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.isPhysicalIncidentPanelOpen;

export const IsTestPhysicalIncidentIncidentSelector = (state: { physicalIncident: PhysicalIncidentModel }) => state.physicalIncident.physicalIncidentInformation.isTestIncident;

export const PsLocalizedIncidentTypesSelector = (state:{physicalIncident:PhysicalIncidentModel})=>state.physicalIncident.physicalIncidentLocalizedInformation;

export default physicalIncidentSlice.reducer;