import { PayloadAction } from "@reduxjs/toolkit";
import { IDropdownOption, MessageBarType } from "@fluentui/react";
import path from "path";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  resetDigitalIncidentInformation,
  setDigitalIncidentDateTime,
  setIsOutlookButtonAvailable,
  setIsTestIncident,
  setLocalizedSelectedDigitalIncidentTypeNameL1,
  setSelectedDigitalIncidentTypeL1,
} from "../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import {
  clearFiles,
  fileSelector,
  FileState,
} from "../Components/FileUpload/FileUploadSlice";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../Components/IncidentCategory/IncidentCategorySlice";
import { setIsLoading } from "../Components/Loader/LoaderSlice";
import {
  addOrUpdateMessage,
  removeMessage,
} from "../Components/Notifications/NotificationsSlice";
import {
  IsPhysicalSecurityFormValidSelector,
  PsResetLevel,
  PsValidationErrorCountSelector,
  PsValidationErrorMessageSelector,
  resetPhysicalIncidentInformation,
  setIsTestPhysicalIncidentIncident,
  validatePhysicalSecurityincident,
} from "../Components/PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import {
  getSovereignDigitalL2IncidentTypes,
  getSovereigns,
  postSovereignDigitalFormInformation,
  selectedSovereignSelector,
  setIsSovereignIncidentSelected,
  setSelectedSovereign,
  setSovereigns,
  sovereignIncidentCategorySelector,
  sovereignIncidentTypesSelector,
  sovereignSelector,
} from "../Components/Sovereign/SovereignSlice";
import { showSuccessPage } from "../Components/SubmissionSuccess/SubmissionSuccessSlice";
import { appInsightsIncidentSubmissionFlowIdSelector, appInsightsIncidentSubmissionFlowStartTimeSelector, serviceCommunicationSettingsSelector } from "../Components/UserProfile/ServiceCommunicationSettingsSlice";
import {
  DIGITAL_SUBMISSION_MESSAGE,
  PHYSICAL_SUBMISSION_MESSAGE,
} from "../Constants/SubmissionSuccessConstants";
import { SovereignDigitalEventTypes } from "../Contracts/Enums/DigitalIncidentEnums";
import { ServiceCommunicationSettings } from "../Contracts/ServiceModels/ServiceCommunicationSettings";
import { WebResponseModel } from "../Contracts/TypesAndInterfaces/WebResponseModel";
import { handleError } from "../Services/errorHandlerService";
import { GET, POST } from "../Services/httpService";
import {
  IsFormValidSelector,
  validateDigitalIncidentFormInformation,
} from "./../Components/DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import {
  postSovereignPhysicalFormInformation,
  setSovereignIncidentTypes,
  SovereignIncidenttypes,
  SovereignModel,
  sovereignSelectedIncidentTypeL1Selector,
} from "./../Components/Sovereign/SovereignSlice";
import { IncidentCategories } from "./../Contracts/Enums/IncidentCategory";
import { GovNationsResponseViewModel } from "./../Contracts/TypesAndInterfaces/Sovereign/GovNationsResponseViewModel.d";
import {
  SovereignAttachmentModel,
  SovereignDigitalIncidentInputModel,
  SovereignDigitalIncidentModel,
  SovereignPhysicalIncidentInputModel,
  SovereignPhysicalIncidentModel,
} from "./../Contracts/TypesAndInterfaces/Sovereign/SovereignIncidentModels.d";
import { GetExceptionModel, trackEvent } from "../Services/telemetryService";
import {
  FetchGovIncident,
  FetchGovNations,
  SubmitGovIncident,
} from "../Constants/ErrorCodes";
import { SOURCE, STEP } from "../Constants/CommonConstants";
import {
  DigitalIncidentType,
  DigitalIncidentTypes,
} from "../Contracts/TypesAndInterfaces/DigitalIncidentType";
import { fetchAPIAccessToken } from "../Services/AuthService";
import { getLocalizedValue } from "../Services/localizationService";
import { PhysicalIncidentModel } from "../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import { NavigateFunction } from "react-router-dom";
import { DigitalIncidentModel } from "../Contracts/TypesAndInterfaces/DigitalIncidentModel";
import { INCIDENT_SUBMISSION_STEP } from "../Constants/InstrumentationConstants";
import { IncidentCategoryModel } from "../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";

export function* FetchSovereignsAsync() {
  try {
    var serviceCommunicationSettings: ServiceCommunicationSettings =
      yield select(serviceCommunicationSettingsSelector);
    let accessToken: string = yield fetchAPIAccessToken();
    yield put(setIsLoading(true));
    var apiResponse: WebResponseModel | undefined = yield GET<WebResponseModel>(
      serviceCommunicationSettings.apiParameters.apiBaseAddress,
      "/api/global/getgovnations",
      accessToken
    );
    yield put(
      setSovereigns(
        apiResponse?.responseObject == null ||
          apiResponse?.responseObject === undefined
          ? []
          : (apiResponse?.responseObject as GovNationsResponseViewModel[])
      )
    );
    if (!apiResponse?.responseSuccess) {
      yield handleError(true, false, undefined);
    }
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
    let exceptionModel = GetExceptionModel(
      FetchGovNations,
      SOURCE,
      "Sovereign" + STEP,
      "Fetch Sovereign Nations",
      "FetchSovereignsAsync",
      true,
      "Outer",
      null
    );
    yield handleError(true, false, exceptionModel);
  }
}

export function* watchGetSovereigns() {
  yield takeEvery(getSovereigns, FetchSovereignsAsync);
}

export function* FetchSovereignIncidentTypes({
  payload,
}: PayloadAction<GovNationsResponseViewModel | undefined>) {
  if (payload) {
    try {
      let serviceCommunicationSettings: ServiceCommunicationSettings =
        yield select(serviceCommunicationSettingsSelector);
      let sovereignIncidentCategory: IncidentCategories = yield select(
        sovereignIncidentCategorySelector
      );
      let selectedSovreign: GovNationsResponseViewModel = yield select(
        selectedSovereignSelector
      );
      let accessToken: string = yield fetchAPIAccessToken();
      yield put(setIsLoading(true));
      let apiEndpoint = "";
      if (sovereignIncidentCategory === IncidentCategories.Digital)
        apiEndpoint =
          "/api/digitalIncident/getincidenttypes?level=1&govNationId=" +
          selectedSovreign.nationId;
      else if (sovereignIncidentCategory === IncidentCategories.Physical)
        apiEndpoint =
          "/api/physicalIncident/getphysicalincidenttypesbylevelandid?rollupLevel=1&govNationId=" +
          selectedSovreign.nationId;
      var apiResponse: WebResponseModel | undefined =
        yield GET<WebResponseModel>(
          serviceCommunicationSettings.apiParameters.apiBaseAddress,
          apiEndpoint,
          accessToken
        );
      if (sovereignIncidentCategory === IncidentCategories.Digital) {
        // if digital incident, the payload is of type DigitalIncidentTypes and we set L1 incident types.
        yield put(
          setSovereignIncidentTypes(
            apiResponse?.responseObject == null ||
              apiResponse?.responseObject === undefined
              ? {
                  incidentTypesL1: [],
                  incidentTypesL2: [],
                }
              : {
                  incidentTypesL1:
                    apiResponse?.responseObject as DigitalIncidentType[],
                  incidentTypesL2: [],
                }
          )
        );
      } else if (sovereignIncidentCategory === IncidentCategories.Physical) {
        // if physical incident, the payload is of type PsPhysicalIncidentTypeResponseViewModel[] and we set the incident types.
        yield put(
          setSovereignIncidentTypes(
            apiResponse?.responseObject == null ||
              apiResponse?.responseObject === undefined
              ? []
              : (apiResponse?.responseObject as SovereignIncidenttypes)
          )
        );
      }
      if (!apiResponse?.responseSuccess) {
        yield handleError(true, false, undefined);
      }
      yield put(setIsLoading(false));
    } catch (e) {
      yield put(setIsLoading(false));
      let exceptionModel = GetExceptionModel(
        FetchGovIncident,
        SOURCE,
        "Sovereign" + STEP,
        "Fetch Sovereign incident types",
        "FetchSovereignIncidentTypes",
        true,
        "Outer",
        null
      );
      yield handleError(true, false, exceptionModel);
    }
  }
}

export function* FetchSovereignDigitalIncidentL2Types({
  payload,
}: PayloadAction<GovNationsResponseViewModel | undefined>) {
  if (payload) {
    try {
      let serviceCommunicationSettings: ServiceCommunicationSettings =
        yield select(serviceCommunicationSettingsSelector);
      let sovereign: SovereignModel = yield select(sovereignSelector);
      let selectedIncidentTypeDetails: DigitalIncidentTypes = yield select(
        sovereignIncidentTypesSelector
      );
      let selectedSovreign: GovNationsResponseViewModel = yield select(
        selectedSovereignSelector
      );
      yield put(setIsLoading(true));
      let accessToken: string = yield fetchAPIAccessToken();
      var apiResponse: WebResponseModel | undefined =
        yield GET<WebResponseModel>(
          serviceCommunicationSettings.apiParameters.apiBaseAddress,
          "/api/digitalIncident/getincidenttypes?level=2&parentIncidentTypeId=" +
            sovereign.IncidentDetails.selectedIncidentTypeL1?.key +
            "&govNationId=" +
            selectedSovreign.nationId,
          accessToken
        );
      yield put(
        setSovereignIncidentTypes(
          apiResponse?.responseObject == null ||
            apiResponse?.responseObject === undefined
            ? {
                incidentTypesL1: selectedIncidentTypeDetails.incidentTypesL1,
                incidentTypesL2: [],
              }
            : {
                incidentTypesL1: selectedIncidentTypeDetails.incidentTypesL1,
                incidentTypesL2:
                  apiResponse?.responseObject as DigitalIncidentType[],
              }
        )
      );
      if (!apiResponse?.responseSuccess) {
        yield handleError(true, false, undefined);
      }
      yield put(setIsLoading(false));
    } catch (e) {
      yield put(setIsLoading(false));
      let exceptionModel = GetExceptionModel(
        FetchGovIncident,
        SOURCE,
        "Sovereign" + STEP,
        "Fetch Sovereign incident types L2",
        "FetchSovereignIncidentTypes",
        true,
        "Outer",
        null
      );
      yield handleError(true, false, exceptionModel);
    }
  }
}

export function* watchGetSovereignIncidentTypes() {
  yield takeEvery(setSelectedSovereign, FetchSovereignIncidentTypes);
}

export function* watchGetSovereignIncidentTypesL2() {
  yield takeEvery(
    getSovereignDigitalL2IncidentTypes,
    FetchSovereignDigitalIncidentL2Types
  );
}

export function* PostSovereignDigitalIncident({ payload }: PayloadAction<{digitalIncident: DigitalIncidentModel, navigate?: NavigateFunction}>) {
  var serviceCommunicationSettings: ServiceCommunicationSettings = yield select(
    serviceCommunicationSettingsSelector
  );
  let sovereign: SovereignModel = yield select(sovereignSelector);
  let selectedIncident: IDropdownOption = yield select(
    sovereignSelectedIncidentTypeL1Selector
  );
  let accessToken: string = yield fetchAPIAccessToken();
  const fileUpload: FileState = yield select(fileSelector);
  yield put(removeMessage("form-submission-exception"));
  let isFormValid: boolean = false;
  yield put(
    validateDigitalIncidentFormInformation(
      selectedIncident.key as SovereignDigitalEventTypes
    )
  );
  isFormValid = yield select(IsFormValidSelector);
  let sovereignFormData: SovereignDigitalIncidentInputModel = {
    incidentDetails: {
      digitalIncident: sovereign.formData as SovereignDigitalIncidentModel,
    },
    attachments:
      fileUpload.files?.map((file) => {
        return {
          attachmentName: file.fileName,
          attachmentExtension: path.extname(file.fileName),
          attachmentSizeInKb: file.fileSize / 1024,
          attachmentData: file.fileContent,
          lastModifiedBy: "RIN_API",
        } as SovereignAttachmentModel;
      }) ?? undefined,
  };
  const appInsightsIncidentSubmissionFlowId: string | undefined = yield select(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime: number = yield select(appInsightsIncidentSubmissionFlowStartTimeSelector);
  const incidentCategoryDetails: IncidentCategoryModel = yield select(IncidentCategorySelector);
  if (isFormValid) {
    try {
      yield put(removeMessage("validation-message"));
      yield put(setIsLoading({ loadingState: true, showSubmissionText: true }));
      let apiEndpoint = "/api/digitalIncident/submitgovincident";
      var response: WebResponseModel | undefined =
        yield POST<SovereignDigitalIncidentInputModel>(
          serviceCommunicationSettings.apiParameters.apiBaseAddress,
          apiEndpoint,
          sovereignFormData,
          accessToken
        );

      if (!response?.responseSuccess) {
        yield put(
          setIsLoading({ loadingState: false, showSubmissionText: false })
        );
        if (response?.responseCode === 500) {
          yield handleError(false, true, undefined);
        } else {
          yield handleError(true, false, undefined);
        }
        if(payload.navigate)
          payload.navigate("/v2/exception")
      } else if (response?.responseSuccess) {
        yield put(
          showSuccessPage({
            isSuccessPageShown: true,
            message: getLocalizedValue(DIGITAL_SUBMISSION_MESSAGE),
          })
        );
        if(payload.navigate)
          payload.navigate("/v2/success")
          trackEvent({name: INCIDENT_SUBMISSION_STEP}, {
            incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
            step: INCIDENT_SUBMISSION_STEP,
            incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
            incidentType: sovereignFormData.incidentDetails.digitalIncident.incidentTypeDetails.level1,
            isSovereignIncident: true,
            timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
          });
        yield put(setSelectedIncidentCategory(undefined));
        yield put(setIsOutlookButtonAvailable(true));
        yield put(resetDigitalIncidentInformation(true));
        yield put(setSelectedDigitalIncidentTypeL1(undefined));
        yield put(setLocalizedSelectedDigitalIncidentTypeNameL1(undefined));
        yield put(removeMessage("form-submission-exception"));
        yield put(clearFiles());
        yield put(setIsTestIncident(undefined));
        yield put(setIsSovereignIncidentSelected(false));
        yield put(
          setIsLoading({ loadingState: false, showSubmissionText: false })
        );
      }
    } catch (e) {
      yield put(
        setIsLoading({ loadingState: false, showSubmissionText: false })
      );
      if(payload.navigate)
          payload.navigate("/v2/exception")
      let exceptionModel = GetExceptionModel(
        SubmitGovIncident,
        SOURCE,
        "Sovereign" + STEP,
        "Submit Sovereign Incident",
        "PostSovereignIncident",
        true,
        "Outer",
        null
      );
      yield handleError(false, true, exceptionModel);
    }
  }
}

export function* PostSovereignPhysicalIncident({ payload }: PayloadAction<{physicalIncident: PhysicalIncidentModel, navigate?: NavigateFunction}>) {
  var serviceCommunicationSettings: ServiceCommunicationSettings = yield select(
    serviceCommunicationSettingsSelector
  );
  let sovereign: SovereignModel = yield select(sovereignSelector);
  const fileUpload: FileState = yield select(fileSelector);
  yield put(removeMessage("form-submission-exception"));
  let isFormValid: boolean = false;

  yield put(validatePhysicalSecurityincident());
  isFormValid = yield select(IsPhysicalSecurityFormValidSelector);
  let sovereignFormData: SovereignPhysicalIncidentInputModel = {
    incidentDetails: {
      physicalIncident: sovereign.formData as SovereignPhysicalIncidentModel,
    },
    attachments:
      fileUpload.files?.map((file) => {
        return {
          attachmentName: file.fileName,
          attachmentExtension: path.extname(file.fileName),
          attachmentSizeInKb: file.fileSize / 1024,
          attachmentData: file.fileContent,
          lastModifiedBy: "RIN_API",
          attachmentId: 0,
          incidentId: 0,
          lastModifiedOn: new Date(),
        } as SovereignAttachmentModel;
      }) ?? undefined,
  };
  const appInsightsIncidentSubmissionFlowId: string | undefined = yield select(appInsightsIncidentSubmissionFlowIdSelector);
	const appInsightsIncidentSubmissionFlowStartTime: number = yield select(appInsightsIncidentSubmissionFlowStartTimeSelector);
  const incidentCategoryDetails: IncidentCategoryModel = yield select(IncidentCategorySelector);
  if (isFormValid) {
    try {
      yield put(removeMessage("validation-message"));
      yield put(setIsLoading({ loadingState: true, showSubmissionText: true }));
      let apiEndpoint = "/api/physicalIncident/submitgovincident";
      let accessToken: string = yield fetchAPIAccessToken();
      var response: WebResponseModel | undefined =
        yield POST<SovereignPhysicalIncidentInputModel>(
          serviceCommunicationSettings.apiParameters.apiBaseAddress,
          apiEndpoint,
          sovereignFormData,
          accessToken
        );

      if (!response?.responseSuccess) {
        yield put(
          setIsLoading({ loadingState: false, showSubmissionText: false })
        );
        if (response?.responseCode === 500) {
          yield handleError(false, true, undefined);
        } else {
          yield handleError(true, false, undefined);
        }
        if(payload.navigate)
          payload.navigate("/v2/exception")
      } else if (response?.responseSuccess) {
        yield put(
          showSuccessPage({
            isSuccessPageShown: true,
            message: getLocalizedValue(PHYSICAL_SUBMISSION_MESSAGE),
          })
        );
        if(payload.navigate){
          payload.navigate("/v2/success")
          trackEvent({name: INCIDENT_SUBMISSION_STEP}, {
            incidentSubmissionFlowId: appInsightsIncidentSubmissionFlowId,
            step: INCIDENT_SUBMISSION_STEP,
            incidentCategory: incidentCategoryDetails.selectedIncidentCategory?.name,
            incidentType: sovereignFormData.incidentDetails.physicalIncident.incidentTypeDetails.level1,
            isSovereignIncident: true,
            timeElapsedInSeconds: (new Date().getTime() - appInsightsIncidentSubmissionFlowStartTime)/1000,
          });
        }
        yield put(setSelectedIncidentCategory(undefined));
        yield put(
          resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged)
        );
        yield put(setSelectedDigitalIncidentTypeL1(undefined));
        yield put(setLocalizedSelectedDigitalIncidentTypeNameL1(undefined));
        yield put(removeMessage("form-submission-exception"));
        yield put(clearFiles());
        yield put(setIsTestPhysicalIncidentIncident(undefined));
        yield put(setIsSovereignIncidentSelected(false));
        yield put(
          setIsLoading({ loadingState: false, showSubmissionText: false })
        );
      }
    } catch (e) {
      yield put(
        setIsLoading({ loadingState: false, showSubmissionText: false })
      );
      if(payload.navigate)
          payload.navigate("/v2/exception")
      let exceptionModel = GetExceptionModel(
        SubmitGovIncident,
        SOURCE,
        "Sovereign" + STEP,
        "Submit Sovereign Incident",
        "PostSovereignIncident",
        true,
        "Outer",
        null
      );
      yield handleError(false, true, exceptionModel);
    }
  } else {
    const errorMessage: string = yield select(PsValidationErrorMessageSelector);
    const errorCount: number = yield select(PsValidationErrorCountSelector);
    yield put(
      addOrUpdateMessage({
        messageContent:
          errorCount > 1
          ? getLocalizedValue("RIN_YouHave")+" " + errorCount + " "+getLocalizedValue("RIN_ValidationErrors")
            : errorMessage,
        id: "validation-message",
        messageType: MessageBarType.error,
      })
    );
  }
}

export function* watchPostSovereignDigitalIncident() {
  yield takeEvery(
    postSovereignDigitalFormInformation,
    PostSovereignDigitalIncident
  );
}

export function* watchPostSovereignPhysicalIncident() {
  yield takeEvery(
    postSovereignPhysicalFormInformation,
    PostSovereignPhysicalIncident
  );
}
