import {
    Callout,
    DelayedRender,
    DirectionalHint,
    FocusTrapCallout,
    FocusTrapZone,
    ICheckboxProps,
    IconButton,
    IDropdownProps,
    IStackStyles,
    IStackTokens,
    ITextFieldProps,
    Label,
    Link,
    Stack,
} from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { labelCalloutStackStyles, stackTokens } from "../../../Constants/CommonConstants";
import { DigitalEventTypes, SovereignDigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { getLocalizedValue } from "../../../Services/localizationService";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { DigitalIncidentTypesSelector } from "../DigitalIncident/DigitalIncidentSlice";
import ExposureOfPersonalDataFields from "./ExposureOfPersonalDataFields";
import ExposureOfSensitiveInformationFields from "./ExposureOfSensitiveInformationFields";
import MalwareFields from "./MalwareFields";
import PhishingCallOrTextFields from "./PhishingCallOrTextFields";
import PhishingEmailFields from "./PhishingEmailFields";
import { SuspiciousOrMaliciousWebsiteFields } from "./SuspiciousOrMaliciousWebsiteFields";
import UnauthorizedAccessOrHackFields from "./UnauthorizedAccessOrHackFields";
import TechnicalSupportScam from "./TechnicalSupportScam";

interface IncidentSpecificFieldsProps {
    incidentTypeId?: number;
}

export default function IncidentSpecificFields({ incidentTypeId }: IncidentSpecificFieldsProps) {
    const digitalIncidentTypesDetails = useSelector(
        DigitalIncidentTypesSelector
    );

    const selectedIncidentTypeL1Id = incidentTypeId ?? digitalIncidentTypesDetails.selectedIncidentTypeL1?.typeId;
    let fields = <></>;
    switch (selectedIncidentTypeL1Id) {
        case DigitalEventTypes.PhishingEMail:
        case SovereignDigitalEventTypes.PhishingEMail:
            fields = <PhishingEmailFields />;
            break;
        case DigitalEventTypes.PhishingCallOrText:
        case SovereignDigitalEventTypes.PhishingCallOrText:
            fields = <PhishingCallOrTextFields />;
            break;
        case DigitalEventTypes.Malware:
        case SovereignDigitalEventTypes.Malware:
            fields = <MalwareFields />;
            break;
        case DigitalEventTypes.ExposureOfSensitiveInformation:
        case SovereignDigitalEventTypes.ExposureOfSensitiveInformation:
            fields = <ExposureOfSensitiveInformationFields />;
            break;
        case DigitalEventTypes.ExposureOfPersonalData:
        case SovereignDigitalEventTypes.ExposureOfPersonalData:
            fields = <ExposureOfPersonalDataFields />;
            break;
        case DigitalEventTypes.SuspiciousOrMaliciousWebsite:
        case SovereignDigitalEventTypes.SuspiciousOrMaliciousWebsite:
            fields = <SuspiciousOrMaliciousWebsiteFields />;
            break;
        case DigitalEventTypes.UnauthorizedAccess:
        case SovereignDigitalEventTypes.UnauthorizedAccess:
            fields = <UnauthorizedAccessOrHackFields />;
            break;
        case DigitalEventTypes.TechnicalSupportScam:
        case SovereignDigitalEventTypes.TechnicalSupportScam:
            fields = <TechnicalSupportScam/>      
            break;  
        default:
            break;
    }
    return fields;
}

const iconProps = { iconName: "Unknown" };

export type CustomLabelProps = (ITextFieldProps | ICheckboxProps | IDropdownProps | {
    id: string;
    label: string;
}) & {
    calloutDescription: string;
    moreInfoLink?: string;
    labelStyle?: React.CSSProperties;
};

export const CustomLabel = (props: CustomLabelProps): JSX.Element => {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const toggleIsCalloutVisible = () => setIsCalloutVisible(!isCalloutVisible);
    const descriptionId: string = props.id + "-description";
    const iconButtonId: string = props.id + "-iconButton";

    return (
        <>
            <Stack horizontal verticalAlign="center" tokens={stackTokens}>
                {
                    props.labelStyle == undefined ?
                        (<><Label id={props.id + 'label'}>{props.label}</Label>
                            <IconButton
                                id={iconButtonId}
                                iconProps={iconProps}
                                title={props.label +" " +getLocalizedValue("RIN_Help_LowerCase")}
                                ariaLabel={props.label + " "+getLocalizedValue("RIN_Help_LowerCase")}
                                onClick={toggleIsCalloutVisible}
                                className="iconButton"
                            /> </>) :
                        (<label id={props.id + 'label'} style={props.labelStyle}>{props.label}
                            <IconButton
                                id={iconButtonId}
                                iconProps={iconProps}
                                title={props.label + " "+getLocalizedValue("RIN_Help_LowerCase")}
                                ariaLabel={props.label + " "+getLocalizedValue("RIN_Help_LowerCase")}
                                onClick={toggleIsCalloutVisible}
                                className="iconButton"
                                styles={{ root: { height: "fit-content" } }}
                            />
                        </label>)
                }
            </Stack>
            {isCalloutVisible && (
                <FocusTrapCallout
                    directionalHint={DirectionalHint.topAutoEdge}
                    target={"#" + iconButtonId}
                    setInitialFocus
                    onDismiss={toggleIsCalloutVisible}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                    aria-live="assertive"
                >
                    <DelayedRender>
                        <FocusTrapZone isClickableOutsideFocusTrap>
                            <Stack
                                tokens={stackTokens}
                                horizontalAlign="start"
                                styles={labelCalloutStackStyles}
                            >
                                <span>
                                    <RichTextRenderer html={props.calloutDescription} />
                                </span>
                                {props.moreInfoLink?.trim()?.length &&
                                    <Link href={props.moreInfoLink}>{getLocalizedValue("RIN_MoreDetails")}</Link>}
                            </Stack>
                        </FocusTrapZone>
                    </DelayedRender>
                </FocusTrapCallout>
            )}
        </>
    );
};
