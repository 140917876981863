import React, { useState } from "react";
import { CoherenceHeader, initializeOBFeedback } from "@coherence-design-system/controls";
import { useSelector } from "react-redux";
import { UserProfileSelector } from "../../UserProfile/UserProfileSlice";
import { PanelBase, PrimaryButton } from "@fluentui/react";
import { SERVICE_COMMUNICATION_SETTINGS } from "../../../Constants/SessionConstants";
import { ClearObjectInSessionStorage } from "../../../Services/sessionService";
import { useBoolean } from "../../../Hooks/useBoolean";
import { OcvAppIdSelector, OcvEnvNameSelector } from "../../UserProfile/ServiceCommunicationSettingsSlice";
import { ProfilePanelv2 } from "../ProfilePanelv2/ProfilePanelv2";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
const buttonStyles = { root: { marginRight: 8 } };
export default function Headerv2() {
    const userProfileDetails = useSelector(UserProfileSelector);
    const [isProfileOpen, { setTrue: expandProfilePanel, setFalse: closeProfilePanel }] = useBoolean(false);

    const [profileClickCaptured, setProfileClickCaptured] = React.useState(false);

    const [isOCVButtonFocused, setIsOCVButtonFocused] = React.useState(false);

    const openProfilePanel = () => {
        expandProfilePanel();
        (document.querySelector("button[aria-label='Profile']") as HTMLButtonElement)?.classList.add("is-checked");
    }
    const dismissProfilePanel = () => {
        closeProfilePanel();
        (document.querySelector("button[aria-label='Profile']") as HTMLButtonElement)?.classList.remove("is-checked");
        (document.querySelector("button[aria-label='Profile']") as HTMLButtonElement)?.focus();
    }

    const handleProfileKeyDown = (event: React.KeyboardEvent<PanelBase>) => {
        // 27 is the keyCode for esc key
        if (event.keyCode === 27) {
            dismissProfilePanel();
        }
    };

    // Needed to ensure that the profile panel is not opened when the user clicks on the close button in profile panel as 
    const handleProfileClickCapture = (event: React.MouseEvent<PanelBase, MouseEvent>) => {
        setProfileClickCaptured(true);
    };
    const ocvAppId = useSelector(OcvAppIdSelector);
    const ocvEnvName = useSelector(OcvEnvNameSelector);
    React.useEffect(() => {
        if (ocvAppId > 0 && ocvEnvName.length > 0) {
            initializeOBFeedback(
                ocvAppId,
                ocvEnvName,
                "/officebrowserfeedback.min.js",
                "/officebrowserfeedback.min.css",
                "/intl/",
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                () => {
                    setIsOCVButtonFocused(false);
                },
                undefined,
                undefined,
                undefined,
                undefined,
                () => {
                    setIsOCVButtonFocused(false);
                    document.querySelector('#coher-header-ocv-button-id')?.focus();
                },
                undefined,
                undefined
            );
        }
    }, [ocvAppId, ocvEnvName])

    return (
        <>
            <CoherenceHeader
                headerLabel={getLocalizedValueV2("RIN_Title")}
                appNameSettings={{
                    ariaLabel: getLocalizedValueV2("RIN_Title"),
                    label: getLocalizedValueV2("RIN_Title"),
                }}
                farRightSettings={{
                    additionalItems: [
                        {
                            key: "HelpLink",
                            text: getLocalizedValueV2("RIN_Help"),
                            iconProps: {
                                iconName: "Help",
                            },
                            ariaLabel: getLocalizedValueV2("RIN_Help"),
                            role: "link",
                            onClick: () => {window.open('/v2/help', '_blank');}
                        },
                    ],
                    feedbackSettings: {
                        panelSettings: {
                            ocvButtonIsFocused: isOCVButtonFocused,
                            onClick: () => {
                                setIsOCVButtonFocused(true);
                                return true;
                            },
                            launchOptions: {
                                categories: {
                                    show: true,
                                    customCategories: ["Content", "Experience", "Vision", "Hearing", "Mobility"],
                                },
                            },
                        },
                    },
                    profileSettings: {
                        panelSettings: {
                            fullName: userProfileDetails.displayName,
                            imageUrl: userProfileDetails.pictureUrl,
                            emailAddress: userProfileDetails.onPremisesUserPrincipalName,
                            logOutLink: "home/signout",
                            onLogOut: () => ClearObjectInSessionStorage(SERVICE_COMMUNICATION_SETTINGS),
                            isLightDismiss: true,
                            onLightDismissClick: () => {
                                dismissProfilePanel();
                            },
                            body: (
                                <ProfilePanelv2
                                    dismissProfilePanel={dismissProfilePanel}
                                />
                            ),
                            onRenderFooter: () => (
                                <div>
                                    <PrimaryButton
                                        id="profileCloseButtonId"
                                        onClick={() => {
                                            dismissProfilePanel();
                                        }}
                                        styles={buttonStyles}
                                    >
                                        {getLocalizedValueV2("RIN_Close")}
                                    </PrimaryButton>
                                </div>
                            ),
                            isFooterAtBottom: true,
                            isOpen: isProfileOpen,
                            onKeyDown: handleProfileKeyDown,
                            onClick: () => {
                                if (!isProfileOpen && !profileClickCaptured) {
                                    openProfilePanel();
                                }
                                setProfileClickCaptured(false);
                            },
                            onClickCapture: handleProfileClickCapture,
                            styles: {
                                header: {
                                    paddingTop: "32px",
                                    marginBottom: "33px",
                                },
                                body: {
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                },
                            },
                            hideSignOut: true,
                            onOuterClick: dismissProfilePanel,
                            elementToFocusOnDismiss:
                                (document.querySelector("button[aria-label='Profile']") as HTMLButtonElement) ?? document.getElementById("coher-header-overflow-button-id") ?? undefined,
                        },
                    },
                }}
            />
        </>
    );
}
