import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { ExceptionModel } from '../Contracts/ExceptionModels/ExceptionModel';

let reactPlugin: ReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey: string, browserHistory: any) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;

export function trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties | undefined) {
    if (!customProperties)
        customProperties = {}
    customProperties["Origin"] = "RIN SPA";
    appInsights?.trackTrace(trace, customProperties);
}

export function trackEvent(event: IEventTelemetry, customProperties: ICustomProperties | undefined) {
    if (!customProperties)
        customProperties = {}
    customProperties["Origin"] = "RIN SPA";
    appInsights?.trackEvent(event, customProperties);
}

export function trackException(exceptionModel: ExceptionModel) {
    let telemetryException: IExceptionTelemetry = {
        properties: {
            "ErrorCode": exceptionModel.ErrorCode,
            "Source": exceptionModel.Source,
            "Step": exceptionModel.Step,
            "Scenario": exceptionModel.Scenario,
            "MethodName": exceptionModel.MethodName,
            "CatchLocation": exceptionModel.CatchLocation,
            "BlockingFlow": exceptionModel.BlockingFlow,
            "IncidentId": exceptionModel.IncidentId == null ? "N/A" : exceptionModel.IncidentId
        }
    }
    appInsights?.trackException(telemetryException);
}

export function GetExceptionModel(
    errorCode: string,
    source: string,
    step: string,
    scenario: string,
    methodName: string,
    blockingFlow: boolean,
    catchLocation: string,
    incidentId: number | null): ExceptionModel {
    let exceptionModel: ExceptionModel = {
        ErrorCode: errorCode,
        Source: source,
        Step: step,
        Scenario: scenario,
        MethodName: methodName,
        BlockingFlow: blockingFlow,
        CatchLocation: catchLocation,
        IncidentId: incidentId
    }
    return exceptionModel;
}