import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupStyles, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsUnauthorizedUninitiatedMFALocalizedViewModel, DsUnauthorizedUninitiatedMFAViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiUnauthorizedAccessOrHackInputViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { ErrorStatesSelector, UnauthorizedAccessOrHackSelector, setUnauthorizedAccessOrHackObject, setUnauthorizedUninitiatedMFAHostNameErrorMessage, setUnauthorizedUninitiatedMFAHostName, setUnauthorizedUninitiatedMFAPrompt, setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccount, LocalizedUnauthorizedAccessUnInitiatedMFA, setLocalizedUnauthorizedAccessOrHackObject } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { CustomLabel } from "./IncidentSpecificFieldsv2";


const MFAPromptOptions: IChoiceGroupOption[] = [
    { key: "1", text:getLocalizedValueV2("RIN_UnauthorizedAcessHackOption1"), value: 'Were you asked to "enter the correct number shown to sign in" out of 3 choices?', id: "Password-less" },
    { key: "2", text:getLocalizedValueV2("RIN_UnauthorizedAcessHackOption2"),value: "Were you asked to approve or deny?", id: "Password-based" },
];

const RelatedToWorkOrSchoolAccountOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_WorkAccount"), value: "Work account" },
        { key: "2", text: getLocalizedValueV2("RIN_PersonalAccount"), value: "Personal account" },
];

export default function UnauthorizedAccessUninitiatedMFA() {
    const dispatch = useDispatch();
    const uninitiatedMFA_CalloputProps = {
        id: "uninitiatedMFA-host-name-info",
        calloutDescription: "<div><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo1")+"</b></div><br><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo2")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo3")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo4")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo6")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo8")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo9")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo10")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo11")+"</li></ol><div><b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo12")+"</b></div><ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo13")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo14")+" <b>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo5")+"</b> "+getLocalizedValueV2("RIN_AffectedMachineNameInfo15")+"</li><li style='margin-bottom: 4px;'>"+getLocalizedValueV2("RIN_AffectedMachineNameInfo7")+"</li></ol></div>",
        moreInfoLink: undefined,
    };
    const [
        mfaOption,
        setMfaOption,
    ] = React.useState<IChoiceGroupOption>();

    const [
        workOrSchoolOption,
        setWorkOrSchoolOption,
    ] = React.useState<IChoiceGroupOption>();

    const choiceGroupChoicesWidth: number = 272;
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const unauthorizedAccessDetails = useSelector(UnauthorizedAccessOrHackSelector);
    const [uninitiatedMFAObject, setuninitiatedMFAObject] = React.useState<DsUnauthorizedUninitiatedMFAViewModel>({
        hostName: "",
        mfaprompt: 'Password-less',
        relatedToWorkOrSchoolAccount: undefined
    });

    const defaultLocalizedUninitiatedMFAObject: DsUnauthorizedUninitiatedMFALocalizedViewModel = {
        hostName: "",
        mfaprompt: getLocalizedValueV2("RIN_UnauthorizedAcessHackOption1"),
        relatedToWorkOrSchoolAccount: undefined
    }
    const localizedUninitiatedMFAObject: DsUnauthorizedUninitiatedMFALocalizedViewModel | undefined = useSelector(LocalizedUnauthorizedAccessUnInitiatedMFA);
    

    useEffect(() => {
        if(unauthorizedAccessDetails?.uninitiatedMFA) return;
        dispatch(setUnauthorizedAccessOrHackObject({ uninitiatedMFA: uninitiatedMFAObject }));
        dispatch(setLocalizedUnauthorizedAccessOrHackObject({ uninitiatedMFA: { ...localizedUninitiatedMFAObject, ...defaultLocalizedUninitiatedMFAObject } }));
    }, [])

    const handleHostNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (errorStatesDetails.unauthorizedUninitiatedMFAHostName !== "") {
                dispatch(setUnauthorizedUninitiatedMFAHostNameErrorMessage(""));
            }
            dispatch(setUnauthorizedUninitiatedMFAHostName(newValue));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ uninitiatedMFA: { ...localizedUninitiatedMFAObject, hostName: newValue } as DsUnauthorizedUninitiatedMFALocalizedViewModel }));
        }
    };

    const handleMfaOptionChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            let localizedOptionValue:string = option.key === "1" ? getLocalizedValueV2("RIN_PasswordLess") : getLocalizedValueV2("RIN_PasswordBased")
            setMfaOption(option);
            dispatch(setUnauthorizedUninitiatedMFAPrompt(option.key === "1" ? "Password-less" : "Password-based"));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ uninitiatedMFA: { ...localizedUninitiatedMFAObject, mfaprompt: localizedOptionValue } as DsUnauthorizedUninitiatedMFALocalizedViewModel }));
        }
    };

    const handleWorkOrSchoolOptionChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setWorkOrSchoolOption(option);
            dispatch(setUnauthorizedUninitiatedMFAIsRelatedToWorkOrSchoolAccount((typeof(option.value)==="string" ? option.value??"" :"" )));
            dispatch(setLocalizedUnauthorizedAccessOrHackObject({ uninitiatedMFA: { ...localizedUninitiatedMFAObject, relatedToWorkOrSchoolAccount: option.text } as DsUnauthorizedUninitiatedMFALocalizedViewModel }));
        }
    };

    const radioButtonStyle: IChoiceGroupStyles = {
        flexContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
        }
    }

    const hostNameDescription = <FieldValidationDescription errorMessage={errorStatesDetails.unauthorizedUninitiatedMFAHostName} descriptionColor={'red'} iconName={"ErrorBadge"} />

    return (
        <>
            <div className="ms-Grid-row" style={{ marginBottom: '20px' }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="uninitiatedMFAHostNameId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                        aria-labelledby={uninitiatedMFA_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion1")}
                        // eslint-disable-next-line react/jsx-no-bind
                        onRenderLabel={(props) => <CustomLabel {...uninitiatedMFA_CalloputProps} {...props} />}
                        value={unauthorizedAccessDetails?.uninitiatedMFA?.hostName}
                        onChange={handleHostNameChange}
                        onRenderDescription={() => hostNameDescription}
                        styles={
                            errorStatesDetails.unauthorizedUninitiatedMFAHostName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={MFAPromptOptions}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion4")}
                        selectedKey={MFAPromptOptions.find(x=>x.id === unauthorizedAccessDetails?.uninitiatedMFA?.mfaprompt)?.key}
                        onChange={handleMfaOptionChange}
                        defaultSelectedKey="1"
                        styles={radioButtonStyle}
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={RelatedToWorkOrSchoolAccountOptions}
                        label={getLocalizedValueV2("RIN_UnauthorizedAcessHackQuestion2")}
                        selectedKey={unauthorizedAccessDetails?.uninitiatedMFA?.relatedToWorkOrSchoolAccount ? (RelatedToWorkOrSchoolAccountOptions.find(x=>x.value === unauthorizedAccessDetails.uninitiatedMFA?.relatedToWorkOrSchoolAccount)?.key) : undefined}
                        onChange={handleWorkOrSchoolOptionChange}
                        styles={getRadioButtonStyle(choiceGroupChoicesWidth)}
                    />
                </div>
            </div>
        </>
    );
}