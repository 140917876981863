import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import { HelpSectionSelector } from "../../HelpPanel/HelpSectionSlice";
import styles from "../Headerv2/Headerv2.module.css";
import LinkOutVector from "../../../Assets/Vector.svg";
import { getLocalizedValueV2 } from "../../../Services/localizationServiceV2";
import { HelpSectionLinksLoadingSelector, HelpSectionsLoadingSelector } from "../../Loader/LoaderSlice";
import Loader from "../../Loader/Loader";
import { CustomAccordion } from "../Controls/CustomAccordion/CustomAccordion";


export function HelpV2() {
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const apiSuccess = helpSectionDetails.isHelpSectionApiCallSuccess && helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const isHelpSectionsLoading = useSelector(HelpSectionsLoadingSelector);
    const isHelpSectionLinksLoading = useSelector(HelpSectionLinksLoadingSelector);

    useEffect(() => {
        document.title = `Report It Now | Help page`;
    },[]);

    return (
        <div className="helpv2 site-margins">
            {isHelpSectionsLoading || isHelpSectionLinksLoading && <Loader />}
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
                    {apiSuccess && helpSectionDetails.helpSectionLinks.length > 0 && (
                        <div>
                            {helpSectionDetails.helpSections
                                .filter((x) => x.panelId === PanelEnums.Help)
                                .map((section) => (
                                    <>
                                        <h2 className={styles.heading2}>{getLocalizedValueV2(section.titleKey)}</h2>
                                        {helpSectionDetails.helpSectionLinks
                                            .filter((s) => s.sectionId === section.sectionId)
                                            .map((link) => (
                                                <div className={styles.heading3}>
                                                    {!link.openInNewTab ? (
                                                        <CustomAccordion link={link} />
                                                    ) : (
                                                        <div>
                                                            <a
                                                                className={styles.link}
                                                                href={link.moreInfoUrl}
                                                                aria-label={getLocalizedValueV2(link.titleKey) + getLocalizedValueV2("RIN_OpenInNewWindow")}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {link.title}
                                                            </a>
                                                            <span aria-hidden="true" className={styles.linkOutVector}>
                                                                <img src={LinkOutVector} alt={getLocalizedValueV2("RIN_LinkOutIcon")} />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <div className={styles.divider}></div>
                                    </>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

