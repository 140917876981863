import * as React from "react";
import {
    DefaultButton,
    IDropdownOption,
    PrimaryButton,
} from "@fluentui/react";
import styles from "./PhysicalIncidentTypesPanel.module.css";
import { useDispatch, useSelector } from "react-redux";
import LinkOutVector from "../../../Assets/Vector.svg";
import { PsIncidentTypesL1Selector, PsSelectedincidentTypeIdL1Selector } from "../PhysicalIncident/PhysicalIncidentSlice";
import { HelpSectionSelector, setHelpPanelVisibility } from "../../HelpPanel/HelpSectionSlice";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { PhysicalIncidentTypesSectionEnums } from "../../../Contracts/Enums/PhysicalIncidentTypesSectionEnums";
import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/controls";
import { getLocalizedValue } from "../../../Services/localizationService";
import { VSOCNumberBlue } from "../../../Constants/CommonConstants";

const buttonStyles = { root: { marginRight: 8 } };

export interface PhysicalIncidentTypesPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    handleIncidentTypeSelected: Function;
}

export const PhysicalIncidentTypesPanel: React.FC<PhysicalIncidentTypesPanelProps> = ({
    isOpen,
    dismissPanel,
    handleIncidentTypeSelected
}) => {
    const dispatch = useDispatch();
    const physicalIncidentL1Details = useSelector(PsIncidentTypesL1Selector);
    const selectedPhysicalIncident = useSelector(PsSelectedincidentTypeIdL1Selector);
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const physicalIncidentPanelSections = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.filter(s => s.panelId === PanelEnums.PhysicalIncidentTypes) : undefined;
    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
                    {getLocalizedValue("RIN_Close")}
                </PrimaryButton>
            </div>
        ),
        [dismissPanel]
    );

    const handleCreatePhysicalIncident = (incidentId: number) => {
        if (!helpSectionDetails.isPanelOpen) {
            dismissPanel();
        }
        else
            if (selectedPhysicalIncident === undefined || selectedPhysicalIncident == null) {
                dismissPanel();
            }
        handleIncidentTypeSelected(incidentId);
    };
    return (
        <CoherencePanel
            panelSize={CoherencePanelSize.medium}
            titleText={getLocalizedValue("RIN_PhysicalIncidentReportTypes")}
            isOpen={isOpen}
            onLightDismissClick={dismissPanel}
            onRenderFooter={onRenderFooterContent}
            isLightDismiss={true}
            onDismiss={dismissPanel}
            hasCloseButton={true}
            closeButtonAriaLabel={getLocalizedValue("RIN_ClosePanel")}
        >
            <div>
                {physicalIncidentPanelSections !== undefined && physicalIncidentPanelSections.length > 0 && physicalIncidentPanelSections.map((section) => (
                    <>
                        {helpSectionDetails.helpSectionLinks
                            .filter((s) => s.sectionId === section.sectionId)
                            .map((link) => (
                                <div>
                                    {link.linkId === PhysicalIncidentTypesSectionEnums.ImmediateAssistance ? (
                                        <>
                                            <p className={styles.heading2}><RichTextRenderer html={getLocalizedValue(link.descriptionKey)+VSOCNumberBlue} /></p>
                                        </>
                                    ) :
                                        link.linkId === PhysicalIncidentTypesSectionEnums.IncidentGuidance ?
                                            (
                                                <>
                                                    {link.description != null ? (
                                                        <p className={styles.desc} style={{ paddingTop: 0, marginTop: "28px" }}>
                                                            <RichTextRenderer html={getLocalizedValue(link.descriptionKey)} />
                                                        </p>
                                                    )
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <h2 className={styles.heading2} style={{ marginTop: "28px" }}>{link.title}</h2>
                                                    {link.description != null ? (
                                                        <p className={styles.desc} style={{ paddingTop: 0 }}>
                                                            <RichTextRenderer html={getLocalizedValue(link.descriptionKey)} />
                                                        </p>
                                                    )
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            ))}
                    </>
                ))
                }
                <h2 className={styles.heading2} style={{ marginTop: "20px" }}>
                    {getLocalizedValue("RIN_IncidentTypes")}
                </h2>
                {physicalIncidentL1Details.map((incidentType) => !incidentType.isVisibleInHelpPanel ? <></> : (
                    <>
                        <h3 className={styles.heading}>
                            {getLocalizedValue(incidentType.nameKey)}
                        </h3>
                        <div className={styles.incident}>
                            <div>
                                <div className={styles.text}>
                                    {getLocalizedValue(incidentType.descriptionKey)}
                                </div>
                            </div>
                            <div className={styles.create}>
                                <DefaultButton
                                    key={incidentType.perspectiveDbId}
                                    text={getLocalizedValue("RIN_Create")}
                                    aria-label={getLocalizedValue(incidentType.nameKey) + getLocalizedValue("RIN_Create")}
                                    onClick={() =>
                                        handleCreatePhysicalIncident(incidentType.typeId)
                                    }
                                    ariaLabel={getLocalizedValue("RIN_Create")+" " + getLocalizedValue(incidentType.nameKey) + " "+getLocalizedValue("RIN_IncidentType")}
                                />
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </CoherencePanel >
    );
};
