import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    MessageBar
} from '@fluentui/react';
import { addOrUpdateMessage, MessageDetailsSelector, removeMessage, setIsSiteNotificationsAPICallSuccess } from "./NotificationsSlice";
import { getMessageBarTypeFromNotificationType, SiteNotificationType, SiteNotificationType as SiteNotificationTypes } from "../../Contracts/Enums/NotificationEnums";
import RichTextRenderer from "../RichTextRenderer/RichTextRenderer";
import {  lstNotificationMessagesTemplate, notificationMessageTemplate, siteNotifications } from "./notificationMessages";
import { getLocalizedValue } from "../../Services/localizationService";
import { siteNotification } from "../../Contracts/TypesAndInterfaces/NotificationMessageModel";

export default function Notifications() {
   

    const siteNotificationMsgs: siteNotification = siteNotifications;

    const messageTemplate: string = notificationMessageTemplate;
    const lstMessagesTemplate: string = lstNotificationMessagesTemplate;
    const messages = useSelector(MessageDetailsSelector).messages;

    const dispatch = useDispatch();
    const deleteMessage = (id: string) => {
        dispatch(removeMessage(id));
        if (messages.length == 1 || messages[messages.length - 1].id == id) {
            document.getElementById("mainRegionHeader")?.focus()
        }
    }
    let notificationTimers: NodeJS.Timeout[] = [];



    const setNotificationMessages = () => {
        let messageBody = "";
        let messageContent = "";
        for (let notificationMessage of siteNotificationMsgs.lstNotificationMessages) {

            let msgTemplate: string = messageTemplate;
            messageBody += msgTemplate.replace("{{messagePlaceholder}}", getLocalizedValue(notificationMessage.descriptionKey));
        }

        for (let additionalNotificationMsg of siteNotificationMsgs.additionalNotificationMessages) {
            messageBody += additionalNotificationMsg.localizedDescription;
        }

        messageContent = lstMessagesTemplate.replace("{{contentPlaceHolder}}", messageBody);

        dispatch(addOrUpdateMessage({
            id: siteNotificationMsgs.name,
            messageContent: messageContent,
            messageType: getMessageBarTypeFromNotificationType(SiteNotificationType.info),
            bgColor: siteNotificationMsgs.textBackGroundColor,
            textColor: siteNotificationMsgs.textForeGroundColor,
            moreInfoUrl: siteNotificationMsgs.moreInforURL,
        }));

        dispatch(setIsSiteNotificationsAPICallSuccess(true));
    }



    React.useEffect(() => {
        setNotificationMessages();

        if (siteNotificationMsgs.visibilityInSeconds > 0) {
            notificationTimers.push(setTimeout(() => {
                dispatch(removeMessage(siteNotificationMsgs.name));
            }, siteNotificationMsgs.visibilityInSeconds * 1000));
        }
    
        return () => {
            notificationTimers.forEach(timer => {
                clearTimeout(timer);
            });
        }
    },[]);

    

return (
    <div>
        {messages.map(message => <MessageBar key={message.id}
            messageBarType={message.messageType}
            onDismiss={() => deleteMessage(message.id)}
            dismissButtonAriaLabel={getLocalizedValue("RIN_Close")}
            styles={{
                root: {
                    backgroundColor: message.bgColor ?? "#FFFFFF",
                    borderBottom: '.5px solid grey',
                    color: message.textColor ?? '#000000'
                },
                content: {
                    alignItems: 'center'
                }
            }}
        >
            {message.moreInfoUrl != null &&
                <a style={{ color: message.textColor }} href={message.moreInfoUrl} target="_blank" rel="noopener noreferrer" aria-label={getLocalizedValue("RIN_Notification")}>{message.messageContent}</a>
            }
            {message.moreInfoUrl == null &&
                 <RichTextRenderer html={message.messageContent} />
            }
        </MessageBar>)}
    </div>
);
}