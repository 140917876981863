import {
    DefaultButton,
    DialogFooter,
    DialogType,
    Dropdown,
    IButtonStyles,
    IconButton,
    IDropdown,
    IDropdownOption,
    IIconProps,
    ITextField,
    Label,
    Modal,
    PrimaryButton,
    TextField,
    Toggle,
} from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddedVehicleModel } from "../../../../Contracts/ServiceModels/PhysicalSecurity/PhysicalIncidentModel";
import { useBoolean } from "../../../../Hooks/useBoolean";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { physicalIncidentCardStyle } from "../../../../styles";
import { PsAddedVehiclesSelector, PsVehicleInvolvementTypesSelector, PsVehicleMakeTypesSelector, PsVehicleModelTypesSelector, PsVehicleColorTypesSelector, PsVehicleStyleTypesSelector, getVehicleInvolvementTypes, getVehicleMakeTypes, getVehicleColorTypes, getVehicleStyleTypes, validateAndAddVehicle, validateAndUpdateVehicle, deleteVehicleFromAddedVehicles, setSelectedVehicleMakeTypeId } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import './PhysicalIncidentAddVehicle.css'
import { DropdownAdapter } from "../../Controls";

const addIcon: IIconProps = { iconName: "Add" };


const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

export default function PhysicalIncidentAddVehiclev2() {
    const [hideDialog, { toggle: toggleHideDialog, setFalse: openHiddenDialog }] = useBoolean(true);
    const currentYear = new Date().getFullYear();
    const [vehicleId, setVehicleId] = React.useState<number>(-1);
    const [licensePlateNumber, setLicensePlateNumber] = React.useState<string>();
    const [DriverName, setDriverName] = React.useState<string>();
    const [involvement, setInvolvement] = React.useState<IDropdownOption>();
    const [isCompanyVehicle, setIsCompanyVehicle] = React.useState<boolean>();
    const [vehicleMakeId, setVehicleMake] = React.useState<string>();
    const [vehicleModelId, setVehicleModel] = React.useState<string>();
    const [vehicleStyleId, setVehicleStyle] = React.useState<string>();
    const [vehicleColorId, setVehicleColor] = React.useState<string>();
    const [vehicleYear, setVehicleYear] = React.useState<number | undefined>(currentYear);
    const [notes, setNotes] = React.useState<string>();

    const [licensePlateNumberErrorMessage, setLicensePlateNumberErrorMessage] = React.useState<string>();
    const [DriverNameErrorMessage, setDriverNameErrorMessage] = React.useState<string>();
    const [involvementErrorMessage, setInvolvementErrorMessage] = React.useState<string>();
    const [vehicleYearErrorMessage, setVehicleYearErrorMessage] = React.useState<string>();
    const [notesErrorMessage, setNotesErrorMessage] = React.useState<string>();

    const addedVehicles = useSelector(PsAddedVehiclesSelector);

    const addIconRef = React.useRef<HTMLElement>(null);

    const PsVehicleInvolvementTypes = useSelector(PsVehicleInvolvementTypesSelector);
    const [VehicleInvolvementTypes, setVehicleInvolvementtypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setVehicleInvolvementtypes(PsVehicleInvolvementTypes.map(x => {
            return { key: x.idPk, text: x.valueNameNv }
        }) ?? [])
    }, [PsVehicleInvolvementTypes])

    const PsVehicleMakeTypes = useSelector(PsVehicleMakeTypesSelector);
    const [VehicleMakeTypes, setVehicleMaketypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setVehicleMaketypes(PsVehicleMakeTypes.map(x => {
            return { key: x.idPk, text: x.makeModelNv }
        }) ?? [])
    }, [PsVehicleMakeTypes])

    const PsVehicleModelTypes = useSelector(PsVehicleModelTypesSelector);
    const [VehicleModelTypes, setVehicleModeltypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setVehicleModeltypes(PsVehicleModelTypes.map(x => {
            return { key: x.idPk, text: x.makeModelNv }
        }) ?? [])
    }, [PsVehicleModelTypes])

    const PsVehicleColorTypes = useSelector(PsVehicleColorTypesSelector);
    const [VehicleColorTypes, setVehicleColortypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setVehicleColortypes(PsVehicleColorTypes.map(x => {
            return { key: x.idPk, text: x.valueNameNv }
        }) ?? [])
    }, [PsVehicleColorTypes])

    const PsVehicleStyleTypes = useSelector(PsVehicleStyleTypesSelector);
    const [VehicleStyleTypes, setVehicleStyletypes] = React.useState<IDropdownOption[]>([]);
    React.useEffect(() => {
        setVehicleStyletypes(PsVehicleStyleTypes.map(x => {
            return { key: x.idPk, text: x.valueNameNv }
        }) ?? [])
    }, [PsVehicleStyleTypes])

    const dispatch = useDispatch();

    const handleAddVehicleButtonClicked = () => {
        if (VehicleInvolvementTypes.length == 0)
            dispatch(getVehicleInvolvementTypes());

        if (VehicleMakeTypes.length == 0)
            dispatch(getVehicleMakeTypes());

        if (VehicleColorTypes.length == 0)
            dispatch(getVehicleColorTypes());

        if (VehicleStyleTypes.length == 0)
            dispatch(getVehicleStyleTypes());

        toggleHideDialog();
    }

    const resetCurrentVehicle = () => {
        setVehicleId(-1);
        setLicensePlateNumber(undefined);
        setDriverName(undefined);
        setInvolvement(undefined);
        setIsCompanyVehicle(undefined);
        setVehicleMake(undefined);
        setVehicleModel(undefined);
        setVehicleStyle(undefined);
        setVehicleColor(undefined);
        setVehicleYear(undefined);
        setNotes(undefined);

        setLicensePlateNumberErrorMessage(undefined);
        setDriverNameErrorMessage(undefined);
        setInvolvementErrorMessage(undefined);
        setVehicleYearErrorMessage(undefined);
        setNotesErrorMessage(undefined);
    };

    const involvementTypeDropdownRef = React.useRef<IDropdown>(null);
    const vehicleYearRef = React.useRef<ITextField>(null);
    const driverNameRef = React.useRef<ITextField>(null);
    const notesRef = React.useRef<ITextField>(null);
    const licensePlateRef = React.useRef<ITextField>(null);

    const validateVehicle = () => {
        let isValid = true;

        if (!licensePlateNumber || licensePlateNumber?.trim()?.length == 0) {

            if (isValid) {
                licensePlateRef.current?.focus();

            }
            isValid = false;
            setLicensePlateNumberErrorMessage(getLocalizedValueV2("RIN_LicensePlateNumberIsRequired"));
        }
        else if (licensePlateNumber?.trim().length > 15) {
            if (isValid) {
                licensePlateRef.current?.focus();

            }
            isValid = false;
            setLicensePlateNumberErrorMessage(getLocalizedValueV2("RIN_LicensePlateNumberCannotBeLongerThan"));
        }

        if (!involvement) {
            if (isValid) {

                involvementTypeDropdownRef.current?.focus();
            }
            isValid = false;
            setInvolvementErrorMessage(getLocalizedValueV2("RIN_InvolvementTypeIsRequired"));
        }

        if (vehicleYear && (vehicleYear < 1800 || vehicleYear > 2500)) {
            if (isValid) {
                vehicleYearRef.current?.focus();
            }

            isValid = false;
            setVehicleYearErrorMessage(getLocalizedValueV2("RIN_YearMustBeBetween"));
        }

        if (DriverName && DriverName?.trim().length > 100) {
            if (isValid) {
                driverNameRef.current?.focus();
            }
            isValid = false;
            setDriverNameErrorMessage(getLocalizedValueV2("RIN_DriverNameCannotBeLongerThan"));
        }

        if (notes && notes?.trim().length > 4000) {
            if (isValid) {
                notesRef.current?.focus();
            }
            isValid = false;
            setNotesErrorMessage(getLocalizedValueV2("RIN_TotalCharactersMustBeLessThanOrEqual"));
        }

        return isValid;
    }

    const handelAddButtonClicked = () => {
        const isValid = validateVehicle();
        if (isValid) {
            const addedVehicle: AddedVehicleModel = {
                id: vehicleId,
                selectedMakeId: vehicleMakeId,
                selectedModelId: vehicleModelId,
                selectedInvolvement: involvement?.text,
                vehInvolvementTypeLookupIdFk: involvement?.key?.toString(),
                elicensePlateNv: licensePlateNumber?.trim() ?? "",
                eyearNm: vehicleYear,
                evehMakeModelRollupIdFk: vehicleModelId?.length ? vehicleModelId : vehicleMakeId,
                evehicleStyleLookupIdFk: vehicleStyleId,
                evehicleColorLookupIdFk: vehicleColorId,
                ecompanyVehicleBt: isCompanyVehicle,
                evehicleDriverFullNameNv: DriverName?.trim() ?? "",
                commentsNt: notes?.trim() ?? "",
            }
            if (vehicleId == -1) {
                dispatch(validateAndAddVehicle(addedVehicle));
            } else {
                dispatch(validateAndUpdateVehicle(addedVehicle))
            }

            resetCurrentVehicle();
            toggleHideDialog();

            if (document.querySelector("div[class='visually-hidden']") != null) {
                if (vehicleId === -1) {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyAddedVehicle") + licensePlateNumber;
                }
                else {
                    document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyUpdatedVehicle") + licensePlateNumber;
                }
            }
        }
    };

    const handleCloseButtonClicked = () => {
        resetCurrentVehicle();
        toggleHideDialog();
    };

    const setCurrentVehicle = (vehicle: AddedVehicleModel | undefined) => {
        setVehicleId(vehicle?.id ?? -1);
        setLicensePlateNumber(vehicle?.elicensePlateNv);
        setDriverName(vehicle?.evehicleDriverFullNameNv);
        setInvolvement(VehicleInvolvementTypes.find(c => c.key == vehicle?.vehInvolvementTypeLookupIdFk));
        setIsCompanyVehicle(vehicle?.ecompanyVehicleBt);
        setVehicleMake(vehicle?.selectedMakeId);
        setVehicleModel(vehicle?.selectedModelId);
        setVehicleStyle(vehicle?.evehicleStyleLookupIdFk);
        setVehicleColor(vehicle?.evehicleColorLookupIdFk);
        setVehicleYear(vehicle?.eyearNm);
        setNotes(vehicle?.commentsNt);
    };

    const deleteVehicle = (vehicleId: number) => {
        let deleteVehicle = addedVehicles.find(s => s.id === vehicleId);
        if (document.querySelector("div[class='visually-hidden']") != null) {
            document.querySelector("div[class='visually-hidden']")!.textContent = getLocalizedValueV2("RIN_SuccessfullyDeletedVehicle") + deleteVehicle?.elicensePlateNv;
        }
        dispatch(deleteVehicleFromAddedVehicles(vehicleId))
        resetCurrentVehicle();
        addIconRef?.current?.focus();
    };

    const updateVehicle = (vehicleId: number) => {
        const currentVehicle = addedVehicles.find((x) => x.id === vehicleId);
        if (currentVehicle)
            dispatch(setSelectedVehicleMakeTypeId(currentVehicle.selectedMakeId));
        setCurrentVehicle(currentVehicle);
        openHiddenDialog();
    };

    const handleVehicleYearChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, '');
            setVehicleYearErrorMessage(undefined);
            let yearValue = Number(newValue);
            if (yearValue > 2050 || yearValue < 1800) {
                setVehicleYear(new Date().getFullYear());
            }
            else {
                setVehicleYear(yearValue);
            }
        }
    }

    return (
        <div style={{ marginBottom: "28px" }}>
            <div className="visually-hidden" aria-live="assertive"></div>
            <div className="ms-Grid-row" style={{ marginBottom: "8px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <IconButton
                        elementRef={addIconRef}
                        iconProps={addIcon}
                        title={getLocalizedValueV2("RIN_AddVehicleOptional")}
                        onClick={handleAddVehicleButtonClicked}
                        styles={{
                            root: {
                                backgroundColor: "white",
                            },
                        }}
                    />
                    <Label
                        styles={{
                            root: {
                                display: "inline",
                                paddingLeft: 12,
                                verticalAlign: "text-top",
                            },
                        }}
                    >
                       {getLocalizedValueV2("RIN_AddVehicleOptional")}
                    </Label>
                </div>
            </div>

            {addedVehicles.map((vehicle) => (
                <>
                    <div
                        className="ms-Grid-row"
                        style={physicalIncidentCardStyle}
                    >
                        <div
                            className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-x10 ms-xx10 ms-xxxl10 block"
                            style={{ padding: 0 }}
                        >
                            <div>
                                <div>
                                    <label>
                                        <b>{getLocalizedValueV2("RIN_LicensePlate")} #: &nbsp;</b>
                                    </label>{" "}
                                    <span>{vehicle.elicensePlateNv}</span>
                                    <br />
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_Involvement")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ marginLeft: "0px" }}>{vehicle.selectedInvolvement ?? "N/A"}</span>
                                    <br />
                                    <label>
                                        <b>
                                        {getLocalizedValueV2("RIN_DriverName")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b>
                                    </label>{" "}
                                    <span style={{ marginLeft: "1px", wordBreak: "break-all" }}>{vehicle.evehicleDriverFullNameNv?.length ? vehicle.evehicleDriverFullNameNv : "N/A"}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-x2 ms-xx2 ms-xxxl2 block"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: 0,
                            }}
                        >
                            <IconButton
                                onClick={() => updateVehicle(vehicle.id)}
                                iconProps={{ iconName: "Edit" }}
                                title={getLocalizedValueV2("RIN_EditVehicleDetailsOf")+" " + vehicle.elicensePlateNv}
                            />
                            <IconButton
                                onClick={() => deleteVehicle(vehicle.id)}
                                iconProps={{ iconName: "Delete" }}
                                title={getLocalizedValueV2("RIN_DeleteVehicleDetailsOf")+" " + vehicle.elicensePlateNv}
                            />
                        </div>
                    </div>
                </>
            ))}
            <hr style={{ marginTop: 36, marginBottom: 36 }} />
            <Modal
                titleAriaId='add-vehicle-modal-header'
                isOpen={!hideDialog}
                onDismiss={handleCloseButtonClicked}
                isModeless={true}
                onLayerDidMount={() => involvementTypeDropdownRef.current?.focus()}
                styles={{
                    main: {
                        padding: 30,
                        paddingTop: 0,
                        width: "500px"
                    },
                    scrollableContent: {
                        overflowX: 'hidden'
                    }
                }}
            >
                <div style={{
                    flex: '1 1',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                }}>
                    <h2 id='add-vehicle-modal-header'>{getLocalizedValueV2("RIN_AddVehicle")}</h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel={getLocalizedValueV2("RIN_ClosePopUpModal")}
                        onClick={handleCloseButtonClicked}
                    />
                </div>
                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={licensePlateRef}
                            label={getLocalizedValueV2("RIN_LicensePlateNumber")}
                            value={licensePlateNumber}
                            required
                            aria-required={true}
                            placeholder={getLocalizedValueV2("RIN_LicensePlateNumberPlaceholder")}
                            onChange={(e, value) => { setLicensePlateNumber(value); setLicensePlateNumberErrorMessage(undefined); }}
                            errorMessage={licensePlateNumberErrorMessage}
                            onLoad={() => involvementTypeDropdownRef.current?.focus()}
                            styles={{
                                root: {
                                    display: "inline-block",
                                    width: "100%",
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <Toggle
                            checked={isCompanyVehicle}
                            label={getLocalizedValueV2("RIN_CompanyVehicle")}
                            onChange={(e, checked) =>
                                setIsCompanyVehicle(checked)
                            }
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block popup-form-control-container"
                    >
                        <TextField
                            componentRef={driverNameRef}
                            value={DriverName}
                            label={getLocalizedValueV2("RIN_DriverOwnerNameOptional")}
                            placeholder={getLocalizedValueV2("RIN_ProviderName")}
                            onChange={(e, value) => { setDriverName(value); setDriverNameErrorMessage(undefined) }}
                            errorMessage={DriverNameErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container"
                    >
                        <DropdownAdapter
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_VehicleMakeOptional")}
                            selectedKey={vehicleMakeId}
                            onChange={(e, value) => { setVehicleMake(value?.key?.toString()); setVehicleModeltypes([]); dispatch(setSelectedVehicleMakeTypeId(value?.key?.toString())) }}
                            options={VehicleMakeTypes}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>

                    {
                        VehicleModelTypes.length > 0 &&
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block popup-form-control-container">
                            <DropdownAdapter
                                placeholder={getLocalizedValueV2("RIN_Select")}
                                label={getLocalizedValueV2("RIN_VehicleModelOptional")}
                                selectedKey={vehicleModelId}
                                options={VehicleModelTypes}
                                onChange={(e, value) =>
                                    setVehicleModel(value?.key?.toString())
                                }
                                styles={{
                                    root: {
                                        width: "100%",
                                        display: "inline-block",
                                    },
                                }}
                            />
                        </div>
                    }


                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block vehicle-style-container"
                        style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                        <DropdownAdapter
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_VehicleStyleOptional")}
                            selectedKey={vehicleStyleId}
                            onChange={(e, value) => setVehicleStyle(value?.key?.toString())}
                            options={VehicleStyleTypes}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                                dropdownItemsWrapper: {
                                    selectors: {
                                        '@media (min-width: 720px)': {
                                            maxHeight: "30vh"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block"
                        style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                        <DropdownAdapter
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_ColorOptional")}
                            selectedKey={vehicleColorId}
                            options={VehicleColorTypes}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                                dropdownItemsWrapper: {
                                    selectors: {
                                        '@media (min-width: 720px)': {
                                            maxHeight: "30vh"
                                        }
                                    }
                                }
                            }}
                            onChange={(e, value) => setVehicleColor(value?.key?.toString())}
                        />
                    </div>
                    <div
                        className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block"
                        style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                        <TextField
                            componentRef={vehicleYearRef}
                            type="number"
                            placeholder={getLocalizedValueV2("RIN_Year")}
                            label={getLocalizedValueV2("RIN_YearOptional")}
                            min={1800}
                            max={2050}
                            value={vehicleYear?.toString()}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { if (event.key === '.') { event.preventDefault(); } }}
                            onChange={handleVehicleYearChange}
                            errorMessage={vehicleYearErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                        style={{ display: "inline-block", verticalAlign: "top" }}
                    >
                        <DropdownAdapter
                            componentRef={involvementTypeDropdownRef}
                            placeholder={getLocalizedValueV2("RIN_Select")}
                            label={getLocalizedValueV2("RIN_InvolvementType")}
                            required
                            aria-required={true}
                            selectedKey={involvement?.key}
                            onChange={(e, value) => { setInvolvement(value); setInvolvementErrorMessage(undefined); }}
                            options={VehicleInvolvementTypes}
                            errorMessage={involvementErrorMessage}
                            styles={{
                                root: {
                                    width: "100%",
                                    display: "inline-block",
                                },
                                dropdownItemsWrapper: {
                                    selectors: {
                                        '@media (min-width: 720px)': {
                                            maxHeight: "30vh"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                <TextField
                    componentRef={notesRef}
                    label={getLocalizedValueV2("RIN_NotesOptional")}
                    placeholder={getLocalizedValueV2("RIN_AddAdditionalDetail")}
                    value={notes}
                    onChange={(e, v) => { setNotes(v); setNotesErrorMessage(undefined); }}
                    errorMessage={notesErrorMessage}
                    multiline
                    rows={4}
                    styles={{ root: { marginBottom: 28 } }}
                />

                <DialogFooter>
                    <PrimaryButton
                        onClick={handelAddButtonClicked}
                        text={vehicleId == -1 ? getLocalizedValueV2("RIN_Add") : getLocalizedValueV2("RIN_Update")}
                    />
                    <DefaultButton
                        onClick={handleCloseButtonClicked}
                        text={getLocalizedValueV2("RIN_Cancel")}
                    />
                </DialogFooter>
            </Modal>
        </div>
    );
}
