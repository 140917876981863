import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationSettingsModel, ApplicationSettingsResponseViewModel, FlowConfigurations } from "../../Contracts/TypesAndInterfaces/ApplicationSettingsResponseViewModel";
import { IncidentStatisticsResponseViewModel } from "../../Contracts/TypesAndInterfaces/ApplicationStatisticsResponseViewModel";
import { SimulationStatisticsResponseViewModel } from "../../Contracts/TypesAndInterfaces/SimulationStatisticsResponseViewModel";

const initialState: ApplicationSettingsModel = {
    applicationSettings: [],
    flowConfigurations:{isDigitalIncidentSubmissionViaNewFlow:false,
                        isGovDigitalIncidentSubmissionViaNewFlow:false,
                        isGovPhysicalIncidentSubmissionViaNewFlow:false,
                        isShowPhishingCallOptionsToCorp:false,
                        isShowPhishingCallOptionsToFed:false},
    incidentStatistics: {
        digitalSubmissionsCount: 0,
        physicalSubmissionsCount: 0
    },
    isApplicationSettingsValid: true,
    incidentStatisticsErrorState: "",
    isIncidentStatisticsDataValid: true,
    simulationStatistics: [],
    applicationStatus: { statusCode: 200, statusReceived: false },
    incidentStatus: { statusCode: 200, statusReceived: false },
    simulationStatus: { statusCode: 200, statusReceived: false },
    clearSimulationStatus: { statusCode: 200, statusReceived: false }
}

const ApplicationSettingsSlice = createSlice({
    name: "ApplicationSettings",
    initialState,
    reducers: {
        getApplicationSettings: () => {
        },
        setApplicationSettings: (state, { payload }: PayloadAction<ApplicationSettingsResponseViewModel[]>) => {
            state.applicationSettings = payload;
        },
        updateApplicationSettings: (state, { payload }: PayloadAction<ApplicationSettingsResponseViewModel[]>) => {
            state.applicationSettings = payload;
        },
        submitApplicationSettings: (state, { payload }: PayloadAction<ApplicationSettingsResponseViewModel[]>) => {
        },
        getIncidentStatistics: (state, { payload }: PayloadAction<{ startDate: Date, endDate: Date }>) => {
        },
        setIncidentStatistics: (state, { payload }: PayloadAction<IncidentStatisticsResponseViewModel>) => {
            state.incidentStatistics = payload;
        },
        setErrorState: (state, { payload }: PayloadAction<{ settingIndex: number, errorMessage: string }>) => {
            state.applicationSettings[payload.settingIndex].errorState = payload.errorMessage;
        },
        validateApplicationSettings: (state, { payload }: PayloadAction<ApplicationSettingsResponseViewModel[]>) => {
            let valid = true;
            payload.forEach((setting, index) => {
                if (setting.value.length === 0 || setting.value === undefined || setting.value == null) {
                    state.applicationSettings[index].errorState = "Invalid setting value";
                    valid = false;
                }
                if (setting.value.length > 200) {
                    state.applicationSettings[index].errorState = "Setting value cannot be longer than 200 characters";
                    valid = false;
                }
            })
            state.isApplicationSettingsValid = valid;
        },
        validateIncidentStatistics: (state, { payload }: PayloadAction<{ startDate: Date, endDate: Date }>) => {
            let valid = true;
            if (payload.endDate < payload.startDate) {
                state.incidentStatisticsErrorState = "End date cannot be before the start date";
                valid = false;
            }
            if ((payload.endDate.getTime() - payload.startDate.getTime()) > (14 * 1000 * 60 * 60 * 24)) {
                state.incidentStatisticsErrorState = "Time frame cannot be over two weeks apart";
                valid = false;
            }
            state.isIncidentStatisticsDataValid = valid;
        },
        resetState: (state) => {
            state.applicationSettings.forEach(setting => setting.errorState = "");
            state.applicationStatus.statusReceived = false;
            state.incidentStatus.statusReceived = false;
            state.simulationStatus.statusReceived = false;
            state.clearSimulationStatus.statusReceived = false;
            state.incidentStatistics.digitalSubmissionsCount = 0;
            state.incidentStatistics.physicalSubmissionsCount = 0;
            state.incidentStatisticsErrorState = "";
        },
        setApplicationSettingsApiStatus: (state, { payload }: PayloadAction<{ statusCode: number | undefined, statusReceived: boolean }>) => {
            state.applicationStatus.statusCode = payload.statusCode;
            state.applicationStatus.statusReceived = payload.statusReceived;
        },
        setIncidentStatisticsApiStatus: (state, { payload }: PayloadAction<{ statusCode: number | undefined, statusReceived: boolean }>) => {
            state.incidentStatus.statusCode = payload.statusCode;
            state.incidentStatus.statusReceived = payload.statusReceived;
            if (state.simulationStatus.statusReceived) {
                state.simulationStatus.statusReceived = false;
            }
            if (state.clearSimulationStatus.statusReceived) {
                state.clearSimulationStatus.statusReceived = false;
            }
        },
        setSimulationStatisticsApiStatus: (state, { payload }: PayloadAction<{ statusCode: number | undefined, statusReceived: boolean }>) => {
            state.simulationStatus.statusCode = payload.statusCode;
            state.simulationStatus.statusReceived = payload.statusReceived;
            if (state.incidentStatus.statusReceived) {
                state.incidentStatus.statusReceived = false;
            }
            if (state.clearSimulationStatus.statusReceived) {
                state.clearSimulationStatus.statusReceived = false;
            }
        },
        setClearSimulationDetailsApiStatus: (state, { payload }: PayloadAction<{ statusCode: number | undefined, statusReceived: boolean }>) => {
            state.clearSimulationStatus.statusCode = payload.statusCode;
            state.clearSimulationStatus.statusReceived = payload.statusReceived;
            if (state.incidentStatus.statusReceived) {
                state.incidentStatus.statusReceived = false;
            }
            if (state.simulationStatus.statusReceived) {
                state.simulationStatus.statusReceived = false;
            }
        },
        setIncidentStatisticsErrorState: (state, { payload }: PayloadAction<string>) => {
            state.incidentStatisticsErrorState = payload;
        },
        getSimulationStatistics: (state, { payload }: PayloadAction<{ startDate: Date, endDate: Date }>) => {

        },
        setSimulationStatistics: (state, { payload }: PayloadAction<SimulationStatisticsResponseViewModel[]>) => {
            state.simulationStatistics = payload;
        },
        clearSimulationDetails: () => {

        },

        getFlowConfigurations:()=>{

        },

        setFlowConfigurations:(state,{payload}:PayloadAction<FlowConfigurations>)=>{
            state.flowConfigurations = payload;
        }

    },
})
export const {
    setApplicationSettingsApiStatus,
    setClearSimulationDetailsApiStatus,
    setIncidentStatisticsApiStatus,
    setSimulationStatisticsApiStatus,
    clearSimulationDetails,
    getSimulationStatistics,
    setSimulationStatistics,
    validateIncidentStatistics,
    setIncidentStatisticsErrorState,
    resetState,
    setErrorState,
    getApplicationSettings,
    setApplicationSettings,
    updateApplicationSettings,
    submitApplicationSettings,
    getIncidentStatistics,
    setIncidentStatistics,
    validateApplicationSettings,
    getFlowConfigurations,
    setFlowConfigurations
} = ApplicationSettingsSlice.actions;
export default ApplicationSettingsSlice.reducer;
export const ApplicationSettingsSelector = (state: { ApplicationSettings: ApplicationSettingsModel }) => state.ApplicationSettings