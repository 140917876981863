import { getLocalizedValueV2 } from "../Services/localizationServiceV2"

export const profileSections = [
    {
        id: 1,
        title: getLocalizedValueV2("RIN_AboutReportItNow"),
        links: [
            {
                id: 1,
                title: getLocalizedValueV2("RIN_About"),
                description: getLocalizedValueV2("RIN_AboutDesc")+" <a href='mailto:steengind@microsoft.com?subject=Need%20assistance%20with%20Report%20It%20Now' style='color:#0064BF;'>steengind@microsoft.com</a>",
                openInNewTab: false,
                openAsForm: true,
                url: undefined
            },
            {
                id: 2,
                title: getLocalizedValueV2("RIN_TermsOfService"),
                description: undefined,
                openInNewTab: true,
                openAsForm: false,
                url: "https://www.microsoft.com/en-us/legal/terms-of-use"
            },
            {
                id: 3,
                title: getLocalizedValueV2("RIN_PrivacyAndCookies"),
                description: undefined,
                openInNewTab: true,
                openAsForm: false,
                url: "https://privacy.microsoft.com/en-US/data-privacy-notice"
            }
        ]
    }
]
