import { SpinnerSize } from "@fluentui/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderModel } from "../../Contracts/TypesAndInterfaces/LoaderModel";
import { getLocalizedValue } from "../../Services/localizationService";

const initialState: LoaderModel = {
    isLoading: 0,
    loaderText: getLocalizedValue("RIN_Loading"),
    size: SpinnerSize.large,
    isInitializationComplete: false,
};

const LoaderSlice = createSlice({
    name: "Loader",
    initialState,
    reducers: {
        setIsLoading: (
            state,
            { payload }: PayloadAction<boolean | { loadingState: boolean; showSubmissionText: boolean }>
        ) => {
            if (typeof payload === "boolean") {
                if (payload) state.isLoading++;
                else state.isLoading--;
            } else if (typeof payload === "object") {
                if (payload.showSubmissionText) {
                    state.loaderText = getLocalizedValue("RIN_Submitting");
                } else {
                    state.loaderText = initialState.loaderText;
                }
                if (payload.loadingState) {
                    state.isLoading++;
                } else {
                    state.isLoading--;
                }
            }

            if (state.isLoading < 0) state.isLoading = 0;
        },
        setLoaderText: (state, { payload }: PayloadAction<string>) => {
            state.loaderText = payload;
        },
        setSpinnerSize: (state, { payload }: PayloadAction<SpinnerSize>) => {
            state.size = payload;
        },
        setInitializationCompletion: (state, { payload }: PayloadAction<boolean>) => {
            state.isInitializationComplete = payload;
        },
        setUserFlightStatusLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isUserFlightStatusLoading = payload;
        },
        setFeatureFlagsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isFeatureFlagsLoading = payload;
        },
        setUserTestInProdStatusLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isUserTestInProdStatusLoading = payload;
        },
        setFlowConfigurationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isFlowConfigurationsLoading = payload;
        },
        setIncidentCategoriesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isIncidentCategoriesLoading = payload;
        },
        setDigitalIncidentTypesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isDigitalIncidentTypesLoading = payload;
        },
        setPsIncidentTypesL1Loading: (state, { payload }: PayloadAction<boolean>) => {
            state.isPsIncidentTypesL1Loading = payload;
        },
        setSimulationCampaignsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isSimulationCampaignsLoading = payload;
        },
        setTimeZonesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isTimeZonesLoading = payload;
        },
        setPsLocationsL1Loading: (state, { payload }: PayloadAction<boolean>) => {
            state.isPsLocationsL1Loading = payload;
        },
        setHelpSectionsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isHelpSectionsLoading = payload;
        },
        setHelpSectionLinksLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isHelpSectionLinksLoading = payload;
        },
    },
});
export const {
    setIsLoading,
    setLoaderText,
    setSpinnerSize,
    setInitializationCompletion,
    setDigitalIncidentTypesLoading,
    setFeatureFlagsLoading,
    setFlowConfigurationsLoading,
    setHelpSectionLinksLoading,
    setHelpSectionsLoading,
    setIncidentCategoriesLoading,
    setPsIncidentTypesL1Loading,
    setPsLocationsL1Loading,
    setSimulationCampaignsLoading,
    setTimeZonesLoading,
    setUserFlightStatusLoading,
    setUserTestInProdStatusLoading,
} = LoaderSlice.actions;
export default LoaderSlice.reducer;
export const LoaderSelector = (state: { Loader: LoaderModel }) => state.Loader;
export const UserFlightStatusLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isUserFlightStatusLoading;
export const FeatureFlagsLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isFeatureFlagsLoading;
export const UserTestInProdStatusLoadingSelector = (state: { Loader: LoaderModel }) =>
    state.Loader.isUserTestInProdStatusLoading;
export const FlowConfigurationsLoadingSelector = (state: { Loader: LoaderModel }) =>
    state.Loader.isFlowConfigurationsLoading;
export const IncidentCategoriesLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isIncidentCategoriesLoading;
export const DigitalIncidentTypesLoadingSelector = (state: { Loader: LoaderModel }) =>
    state.Loader.isDigitalIncidentTypesLoading;
export const PsIncidentTypesL1LoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isPsIncidentTypesL1Loading;
export const SimulationCampaignsLoadingSelector = (state: { Loader: LoaderModel }) =>
    state.Loader.isSimulationCampaignsLoading;
export const TimeZonesLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isTimeZonesLoading;
export const PsLocationsL1LoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isPsLocationsL1Loading;
export const HelpSectionsLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isHelpSectionsLoading;
export const HelpSectionLinksLoadingSelector = (state: { Loader: LoaderModel }) => state.Loader.isHelpSectionLinksLoading;
