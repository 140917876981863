import * as React from "react";
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useDispatch, useSelector } from "react-redux";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../IncidentCategory/IncidentCategorySlice";
import { resetDigitalIncidentInformation, setDigitalIncidentTypesPanelVisibility, setLocalizedSelectedDigitalIncidentTypeNameL1, setSelectedDigitalIncidentTypeL1 } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { removeMessage } from "../Notifications/NotificationsSlice";
import { clearFiles } from "../FileUpload/FileUploadSlice";
import { PsResetLevel, resetPhysicalIncidentInformation, setPhysicalIncidentPanelVisibility } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { Dialog, DialogFooter, DialogType, IModalProps } from "@fluentui/react";
import { HelpPanelVisibilitySelector, setHelpPanelVisibility } from "../HelpPanel/HelpSectionSlice";
import { getLocalizedValue } from "../../Services/localizationService";

export interface LeaveIncidentProps {
    isHidden: boolean;
    dismissDialog: () => void;
    isTriggeredFromPanel?: boolean;
}
const dialogContentProps = {
    type: DialogType.close,
    title: getLocalizedValue("RIN_ChangingTheIncidentCategory"),
    titleProps: {
        style: {
            fontWeight: 700
        }
    },
    closeButtonAriaLabel: getLocalizedValue("RIN_CloseDialog"),
    subText: getLocalizedValue("RIN_ChangingTheIncidentCategoryInfo")
};

const dialogStyles = {
    main: {
        selectors: {
            '@media (min-width: 0px)': {
                height: 192,
                maxHeight: 192,
                maxWidth: 550,
                width: 550
            }
        }
    }
};

export const LeaveIncident: React.FC<LeaveIncidentProps> = ({ isHidden, dismissDialog, isTriggeredFromPanel }) => {
    const dispatch = useDispatch();
    const modalProps: IModalProps =
    {
        isBlocking: false,
        styles: dialogStyles
    };

    const incidentCategoryDetails = useSelector(IncidentCategorySelector);

    const handleContinue = () => {
        dismissDialog();
        if (incidentCategoryDetails.selectedIncidentCategory?.categoryId === IncidentCategories.Digital) {
            dispatch(resetDigitalIncidentInformation(true));
            var physicalIncidentCategory = incidentCategoryDetails.incidentCategories.find(s => s.categoryId === IncidentCategories.Physical);
            dispatch(setSelectedIncidentCategory(physicalIncidentCategory));
            dispatch(setSelectedDigitalIncidentTypeL1(undefined));
            dispatch(setLocalizedSelectedDigitalIncidentTypeNameL1(undefined));
            if (isTriggeredFromPanel !== undefined && isTriggeredFromPanel) {
                dispatch(setPhysicalIncidentPanelVisibility(true));
            }
        }
        else
            if (incidentCategoryDetails.selectedIncidentCategory?.categoryId == IncidentCategories.Physical) {
                var digitalIncidentCategory = incidentCategoryDetails.incidentCategories.find(s => s.categoryId === IncidentCategories.Digital);
                dispatch(setSelectedIncidentCategory(digitalIncidentCategory));
                dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
                if (isTriggeredFromPanel !== undefined && isTriggeredFromPanel) {
                    dispatch(setDigitalIncidentTypesPanelVisibility(true));
                }
            }
        dispatch(removeMessage("validation-message"));
        dispatch(removeMessage("form-submission-exception"));
        dispatch(clearFiles());
    }


    return (
        <Dialog
            hidden={isHidden}
            onDismiss={dismissDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter styles={{
                actions: {
                    marginTop: "30px"
                }
            }}>
                <PrimaryButton id="continueButtonId" onClick={handleContinue} text={getLocalizedValue("RIN_Continue")} />
                <DefaultButton id="cancelButtonId" onClick={dismissDialog} text={getLocalizedValue("RIN_Cancel")} />
            </DialogFooter>
        </Dialog>
    );
}