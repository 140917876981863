import * as React from "react";
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { useDispatch } from "react-redux";
import { removeMessage } from "../Notifications/NotificationsSlice";
import { clearFiles } from "../FileUpload/FileUploadSlice";
import { Dialog, DialogFooter, DialogType, IModalProps } from "@fluentui/react";

export interface SovereignChangeProps {
    isHidden: boolean;
    dismissDialog: () => void;
    executeOnContinue: (props: any) => void;
    executeOnContinueProps: any;
    executeOnCancle: Function
}
const dialogContentProps = {
    type: DialogType.close,
    title: 'Changing the sovereign',
    titleProps: {
        style: {
            fontWeight: 700
        }
    },
    closeButtonAriaLabel: 'Close dialog',
    subText: 'If you change the sovereign, all information will be lost. Do you still want to change the sovereign?'
};

const dialogStyles = {
    main: {
        selectors: {
            '@media (min-width: 0px)': {
                height: 192,
                maxHeight: 192,
                maxWidth: 550,
                width: 550
            }
        }
    }
};

export const SovereignChangeDialog: React.FC<SovereignChangeProps> = ({ isHidden, dismissDialog, executeOnContinue, executeOnContinueProps, executeOnCancle }) => {
    const dispatch = useDispatch();

    const modalProps: IModalProps =
    {
        isBlocking: false,
        styles: dialogStyles
    };

    const handleContinue = () => {
        dismissDialog();
        executeOnContinue(executeOnContinueProps);
        dispatch(removeMessage("validation-message"));
        dispatch(removeMessage("form-submission-exception"));
        dispatch(clearFiles());
    }

    const handleCancle = () => {
        dismissDialog();
        executeOnCancle();
    }


    return (
        <Dialog
            hidden={isHidden}
            onDismiss={dismissDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter styles={{
                actions: {
                    marginTop: "30px"
                }
            }}>
                <PrimaryButton onClick={handleContinue} text="Continue" />
                <DefaultButton onClick={handleCancle} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}