import { AccountInfo, PublicClientApplication } from "@azure/msal-browser";
import { handleError } from "../Services/errorHandlerService";
import { apiRequest, msalConfig } from "./AuthConfig";

export class AuthManager {
  private app: PublicClientApplication;
  private tokenRequest: { account: AccountInfo; scopes: string[] };

  public constructor() {
    this.app = new PublicClientApplication(msalConfig);
    this.tokenRequest = {
      account: this.app.getAllAccounts()[0],
      scopes: apiRequest.scopes,
    };
  }

  public async getAPIAccessToken(): Promise<string> {
    let accessToken = "";
    let app = this.app;
    let request = this.tokenRequest;
    if (!request.account) {
      request.account = this.app.getAllAccounts()[0];
    }
    try {
      let authResult = await app.acquireTokenSilent(request);
      accessToken = authResult.accessToken;
    } catch (error) {
      try {
        await app.acquireTokenRedirect(request);
        let redirectAuthResult = await app.acquireTokenSilent(request);
        accessToken = redirectAuthResult.accessToken;
      } catch (error) {
        handleError(true, false, undefined);
      }
    }
    return accessToken;
  }

}
