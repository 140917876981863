import React from "react";
import { HelpSectionLinkResponseViewModel } from "../../../../Contracts/TypesAndInterfaces/HelpSectionLinkResponseViewModel";
import { useBoolean } from "../../../../Hooks/useBoolean";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import './CustomAccordion.css'

export function CustomAccordion(props: { link: HelpSectionLinkResponseViewModel}) {
    let link = props.link;
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    return (
        <div >
            <div id={`accordionGroup-${link.linkId}`} className="accordion">
                <div>
               <h3>
                    <button type="button" aria-expanded={isCalloutVisible} className="accordionTrigger" aria-controls={ "section-" + link.linkId} id={ "accordion-" + link.linkId} onClick={toggleIsCalloutVisible}>
                            <span className="accordionTitle" >
                            <span className="accordionIcon"></span>
                            {getLocalizedValueV2(link.titleKey)}

                        </span>
                    </button>
                    </h3>
                </div>
                <div >
                    <div id={ "section-" + link.linkId} role="region" aria-labelledby={ "accordion-" + link.linkId} className="accordionPanel">
                        <div className="accordionBody">
                            {isCalloutVisible && (
                                <div>
                                    <p>{getLocalizedValueV2(link.descriptionKey)}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}