import * as React from "react";
import styles from "./DigitalIncidentTypesPanel.module.css";
import {
    DefaultButton,
    PrimaryButton,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";

import { DigitalIncidentTypesSelector, SelectedDigitalIncidentSelector, SelectedDigitalIncidentTypeL1Selector } from "../DigitalIncident/DigitalIncidentSlice";
import RichTextRenderer from "../../RichTextRenderer/RichTextRenderer";
import { HelpSectionSelector } from "../../HelpPanel/HelpSectionSlice";
import { PanelEnums } from "../../../Contracts/Enums/PanelEnums";
import { CoherencePanel } from "@coherence-design-system/panel";
import { CoherencePanelSize } from "@coherence-design-system/controls";
import { getLocalizedValue } from "../../../Services/localizationService";

export interface DigitalIncidentTypesPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    handleIncidentTypeSelected: Function;
}
const buttonStyles = { root: { marginRight: 8 } };
export const DigitalIncidentTypesPanel: React.FC<DigitalIncidentTypesPanelProps> = ({
    isOpen,
    dismissPanel,
    handleIncidentTypeSelected
}) => {
    const selectedIncidentType = useSelector(SelectedDigitalIncidentSelector);
    const selectedIncidentTypeId = useSelector(SelectedDigitalIncidentTypeL1Selector);
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const isHelpSectionApiCallSuccess = helpSectionDetails.isHelpSectionApiCallSuccess;
    const isHelpSectionLinksApiCallSuccess = helpSectionDetails.isHelpSectionLinksApiCallSuccess;
    const digitalIncidentPanelSections = (isHelpSectionApiCallSuccess && isHelpSectionLinksApiCallSuccess) ? helpSectionDetails.helpSections.filter(s => s.panelId === PanelEnums.DigitalIncidentTypes) : undefined;
    const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);
    const handleCreateDigitalIncident = (incidentId: number) => {
        if (!helpSectionDetails.isPanelOpen || (selectedIncidentTypeId === undefined || selectedIncidentTypeId == null)) {
            dismissPanel();
        }
        
        if (incidentId === selectedIncidentType) return;

        handleIncidentTypeSelected(incidentId);
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
                    {getLocalizedValue("RIN_Close")}
                </PrimaryButton>
            </div>
        ),
        [dismissPanel]
    );


    const onRenderHeader = ()=>{
        
        return (
                <div id="digitalIncidentPanelHeader" tabIndex={0} >
                    <h1 style={{marginLeft:"48px", marginRight:"48px", fontSize:"26px", fontWeight:"700",
                marginTop:"8px", marginBottom:"32px" }} >{getLocalizedValue("RIN_DigitalIncidentReportTypes")}</h1>
                </div>
        );

    }

    React.useEffect(()=>{
        
        setTimeout(()=>{
            document.getElementById("digitalIncidentPanelHeader")?.focus();
        });
    })
    return (
        <CoherencePanel
            panelSize={CoherencePanelSize.medium}
            titleText={getLocalizedValue("RIN_DigitalIncidentReportTypes")}
            isOpen={isOpen}
            onLightDismissClick={dismissPanel}
            onRenderFooter={onRenderFooterContent}
            isLightDismiss={true}
            onDismiss={dismissPanel}
            hasCloseButton={true}
            closeButtonAriaLabel={getLocalizedValue("RIN_ClosePanel")}
            onRenderHeader={onRenderHeader}
        >
            <div>
                {digitalIncidentPanelSections !== undefined && digitalIncidentPanelSections.length > 0 && digitalIncidentPanelSections.map((section) => (
                    <>
                        {helpSectionDetails.helpSectionLinks
                            .filter((s) => s.sectionId === section.sectionId)
                            .map((link) => (
                                <div>
                                    <h2 className={styles.heading2}>{getLocalizedValue(link.titleKey)}</h2>
                                    {link.description != null ? (
                                        <p className={styles.desc} style={{ paddingTop: 0 }}>
                                            <RichTextRenderer html={getLocalizedValue(link.descriptionKey)} />
                                        </p>
                                    )
                                        :
                                        <>
                                        </>
                                    }
                                </div>
                            ))
                        }
                    </>
                ))}
                <h2 className={styles.heading2}>{getLocalizedValue("RIN_IncidentTypes")}</h2>
                {digitalIncidentTypesDetails?.incidentTypesL1?.map((incidentType) => !(incidentType.isVisibleInHelpPanel) ? <></> : (
                    <>
                        <h3 className={styles.heading}>
                            {getLocalizedValue(incidentType.nameKey)}
                        </h3>
                        <div className={styles.incident}>
                            <div>
                                <div className={styles.text}>
                                    <RichTextRenderer html={getLocalizedValue(incidentType.descriptionKey)} />
                                </div>
                            </div>
                            <div className={styles.create}>
                                <DefaultButton
                                    key={incidentType.typeId}
                                    text={getLocalizedValue("RIN_Create")}
                                    onClick={() =>
                                        handleCreateDigitalIncident(incidentType.typeId)
                                    }
                                    ariaLabel={getLocalizedValue("RIN_Create") + getLocalizedValue(incidentType.nameKey) + getLocalizedValue("RIN_IncidentType")}
                                />
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </CoherencePanel>
    );
}
