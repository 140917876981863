import * as React from "react";
import {
    DatePicker,
    Dropdown,
    IDatePicker,
    IDropdownOption,
    Label,
    TextField,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { firstDayOfWeek, DayPickerStrings } from "../../../../Constants/DateControlConfigConstants";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { occurredDateTimeDtSelector, occurredToDateTimeDtSelector, PsOccurredFromToHourAndMinuteSelector, SelectedPhysicalIncidentTimeZoneSelector, PsSelectedincidentTypeIdL2Selector, resetValidationMessageOccurredFrom, setOccurredDateTimeDate, resetValidationMessageOccurredTo, setOccurredToDateTimeDate, PsLocationsL1Selector, PsSelectedLocationIdL1Selector, PsLocationsL2Selector, PsSelectedLocationIdL2Selector, PsLocationsL3Selector, PsSelectedLocationIdL3Selector, PsErrorStatesSelector, PsShowAdditionalInfoSelector, setOccurredDateTimeHours, setOccurredDateTimeMinutes, setOccurredToDateTimeHours, setOccurredToDateTimeMinutes, setPhysicalIncidentTimeZone, setPsSelectedLocationIdL1, resetValidationMessageCountry, setPsSelectedLocationIdL2, setPsSelectedLocationIdL3 } from "../../../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { TimeZonesSelector } from "../../../TimeZone/TimeZonesSlice";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { DropdownAdapter } from "../../Controls";
export default function PhysicalIncidentLocationAndTimev2() {
    const occuredFromDatePickerRef = React.useRef<IDatePicker>(null);
    const occuredToDatePickerRef = React.useRef<IDatePicker>(null);
    const dispatch = useDispatch();
    const timeZoneDetails = useSelector(TimeZonesSelector);
    const occurredFromDateValue = useSelector(occurredDateTimeDtSelector);
    const occurredToDateValue = useSelector(occurredToDateTimeDtSelector);
    const occurredFromToHourMinuteDetails = useSelector(PsOccurredFromToHourAndMinuteSelector);
    const selectedIncidentTimeZone = useSelector(SelectedPhysicalIncidentTimeZoneSelector);
    const [timeZones, setTimeZones] = React.useState<IDropdownOption[]>([]);
    const selectedIncidentTypeL2 = useSelector(PsSelectedincidentTypeIdL2Selector);
    const [selectedTimeZone, setSelectedTimeZone] = React.useState<IDropdownOption>();
    const [defaultSelectedTimeZone, setDefaultSelectedTimeZone] = React.useState<IDropdownOption>();

    const onSelectOccurredFromDate = (date: Date | null | undefined): void => {
        dispatch(resetValidationMessageOccurredFrom(""));
        if (date) {
            dispatch(setOccurredDateTimeDate(date));
        } else {
            dispatch(setOccurredDateTimeDate(null));
        }
    };

    const onSelectOccurredToDate = (date: Date | null | undefined): void => {
        dispatch(resetValidationMessageOccurredTo(""));
        if (date) {
            dispatch(setOccurredToDateTimeDate(date));
        } else {
            dispatch(setOccurredToDateTimeDate(null));
        }
    };

    const PsLocationsL1 = useSelector(PsLocationsL1Selector);
    const PsSelectedLocationIdL1 = useSelector(PsSelectedLocationIdL1Selector);
    const [locationsL1, setLocationsL1] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        setLocationsL1(
            PsLocationsL1?.map((x) => {
                return { key: x.idPk, text: x.siteNameNv };
            }) ?? []
        );
    }, [PsLocationsL1]);

    const PsLocationsL2 = useSelector(PsLocationsL2Selector);
    const PsSelectedLocationIdL2 = useSelector(PsSelectedLocationIdL2Selector);
    const [locationsL2, setLocationsL2] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        setLocationsL2(
            PsLocationsL2?.map((x) => {
                return { key: x.idPk, text: x.siteNameNv };
            }) ?? []
        );
    }, [PsLocationsL2]);

    const PsLocationsL3 = useSelector(PsLocationsL3Selector);
    const PsSelectedLocationIdL3 = useSelector(PsSelectedLocationIdL3Selector);
    const [locationsL3, setLocationsL3] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        setLocationsL3(
            PsLocationsL3?.map((x) => {
                return { key: x.idPk, text: x.siteNameNv };
            }) ?? []
        );
    }, [PsLocationsL3]);

    const errorStates = useSelector(PsErrorStatesSelector);

    const occurredFromStyles = () => {
        if (errorStates.OccurredFrom.length > 0)
            return { backgroundColor: "#FED9CC" };
        return { backgroundColor: "white" };
    };

    const occurredToStyles = () => {
        if (errorStates.OccurredTo.length > 0)
            return { backgroundColor: "#FED9CC" };
        return { backgroundColor: "white" };
    };

    const showLocationAndtime = useSelector(PsShowAdditionalInfoSelector);

    const handleOccuredFromHourChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(resetValidationMessageOccurredFrom(""));
            let hourValue = Number(newValue);
            if (hourValue > 23 || hourValue < 0) {
                dispatch(setOccurredDateTimeHours(0));
            } else {
                dispatch(setOccurredDateTimeHours(hourValue));
            }
        }
    };

    const handleOccuredFromMinuteChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(resetValidationMessageOccurredFrom(""));
            let minuteValue = Number(newValue);
            if (minuteValue > 59 || minuteValue < 0) {
                dispatch(setOccurredDateTimeMinutes(0));
            } else {
                dispatch(setOccurredDateTimeMinutes(minuteValue));
            }
        }
    };

    const handleOccuredToHourChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(resetValidationMessageOccurredTo(""));
            let hourValue = Number(newValue);
            if (hourValue > 23 || hourValue < 0) {
                dispatch(setOccurredToDateTimeHours(0));
            } else {
                dispatch(setOccurredToDateTimeHours(hourValue));
            }
        }
    };

    const handleOccuredToMinuteChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, "");
            dispatch(resetValidationMessageOccurredTo(""));
            let minuteValue = Number(newValue);
            if (minuteValue > 59 || minuteValue < 0) {
                dispatch(setOccurredToDateTimeMinutes(0));
            } else {
                dispatch(setOccurredToDateTimeMinutes(minuteValue));
            }
        }
    };

    const setBusinessRegion = () => {
        if (timeZoneDetails.length > 0) {
            var timeZoneList: IDropdownOption[] = [];
            const timeZoneOffset = -new Date().getTimezoneOffset();
            let isCurrentTimeZoneSet = false;

            for (var i = 0; i < timeZoneDetails.length; i++) {
                timeZoneList.push({
                    key: i.toString(),
                    text: timeZoneDetails[i].text,
                });

                if (
                    timeZoneOffset == timeZoneDetails[i].offset &&
                    !isCurrentTimeZoneSet
                ) {
                    isCurrentTimeZoneSet = true;
                    setSelectedTimeZone({
                        key: i.toString(),
                        text: timeZoneDetails[i].text,
                    });
                    setDefaultSelectedTimeZone({
                        key: i.toString(),
                        text: timeZoneDetails[i].text,
                    });
                    dispatch(setPhysicalIncidentTimeZone(timeZoneDetails[i].text));
                }
            }
            setTimeZones(timeZoneList);

            if (!isCurrentTimeZoneSet) {
                setSelectedTimeZone(timeZoneList[0]);
                setDefaultSelectedTimeZone(timeZoneList[0]);
                dispatch(setPhysicalIncidentTimeZone(timeZoneList[0].text));
            }
        }
    };

    const handleTimeZoneChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option !== undefined) {
            setSelectedTimeZone(option);
            dispatch(setPhysicalIncidentTimeZone(option.text));
        }
    };

    React.useEffect(() => {
        if (
            selectedIncidentTypeL2 !== undefined &&
            (selectedTimeZone === undefined ||
                (defaultSelectedTimeZone !== undefined &&
                    selectedIncidentTimeZone !== defaultSelectedTimeZone.text))
        ) {
            setBusinessRegion();
        }
    }, [selectedIncidentTypeL2]);

    if (showLocationAndtime) {
        return (
            <div className="location-time-container margin-bottom-32">
                <div className="ms-Grid-row section-header">
                    <h2 className="sectionHeading">{getLocalizedValueV2("RIN_LocationTime")}</h2>
                </div>
                <div className="ms-Grid-row">
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                        style={{ padding: "0 !important" }}
                    >
                        <DropdownAdapter
                            id="physicalIncidentCountryId"
                            required
                            aria-required={true}
                            label={getLocalizedValueV2("RIN_Country")}
                            aria-describedby="physicalErrorCountry"
                            selectedKey={PsSelectedLocationIdL1}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={(e, item) => {
                                dispatch(setPsSelectedLocationIdL1(item?.key?.toString()));
                                dispatch(resetValidationMessageCountry());
                            }}
                            placeholder={getLocalizedValueV2("RIN_CountryPlaceholder")}
                            options={locationsL1}
                            styles={{
                                root: {
                                    marginBottom: "16px",
                                    selectors: {
                                        "span.ms-Dropdown-title.ms-Dropdown-titleIsPlaceHolder":
                                            errorStates.country.length > 0
                                                ? { backgroundColor: "#FED9CC" }
                                                : { backgroundColor: "white" },
                                    },
                                },
                                //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                            }}
                        />
                    </div>
                    {locationsL2.length > 0 && (
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                            style={{ padding: "0 !important" }}
                        >
                            <DropdownAdapter
                                label={getLocalizedValueV2("RIN_CityOptional")}
                                selectedKey={PsSelectedLocationIdL2}
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={(e, item) =>
                                    dispatch(setPsSelectedLocationIdL2(item?.key?.toString()))
                                }
                                placeholder={getLocalizedValueV2("RIN_CityPlaceholder")}
                                options={locationsL2}
                                styles={{
                                    root: {
                                        marginBottom: "16px",
                                    },
                                    //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                                }}
                            />
                        </div>
                    )}
                </div>
                {locationsL3.length > 0 && (
                    <div className="ms-Grid-row">
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                            style={{ padding: "0 !important" }}
                        >
                            <DropdownAdapter
                                label={getLocalizedValueV2("RIN_BuildingOptional")}
                                selectedKey={PsSelectedLocationIdL3}
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={(e, item) =>
                                    dispatch(setPsSelectedLocationIdL3(item?.key?.toString()))
                                }
                                placeholder={getLocalizedValueV2("RIN_SelectBuilding")}
                                options={locationsL3}
                                styles={{
                                    root: {
                                        marginBottom: "16px",
                                    },
                                    //dropdownItemsWrapper: dropdownStyles.dropdownItemsWrapper
                                }}
                            />
                        </div>
                    </div>
                )}
                {errorStates.country.length > 0 && (
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                        style={{ marginBottom: 12, marginTop: -18, padding: 0 }}
                        id="physicalErrorCountry"
                    >
                        <span>
                            <FieldValidationDescription
                                errorMessage={errorStates.country}
                                iconName={"ErrorBadge"}
                                descriptionColor="#e50000"
                            />
                        </span>
                    </div>
                )}
                <div
                    className="ms-Grid-row"
                    style={{ marginTop: "15px", marginBottom: "11px" }}
                >
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                        style={{ padding: "0 !important" }}
                    >
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblOccurredFromDate"><span className="required-field-label">{getLocalizedValueV2("RIN_OccurredFrom")}</span></Label>
                            </div>
                        </div>
                        <DatePicker
                            id="physicalIncidentOccuredFromDateId"
                            isMonthPickerVisible={false}
                            componentRef={occuredFromDatePickerRef}
                            aria-labelledby="lblOccurredFromDate"
                            ariaLabel={getLocalizedValueV2("RIN_OccurredFrom")}
                            aria-required={true}
                            isRequired={true}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            value={occurredFromDateValue ?? undefined}
                            maxDate={new Date()}
                            onSelectDate={onSelectOccurredFromDate}
                            styles={{
                                root: {
                                    selectors: {
                                        input: occurredFromStyles(),
                                        "span > div": {
                                            color: "white",
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblHourOccurredFrom"><span className="required-field-label">{getLocalizedValueV2("RIN_Hour")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            aria-labelledby="lblHourOccurredFrom"
                            id="physicalIncidentOccuredFromHourId"
                            min={0}
                            max={23}
                            aria-required={true}
                            type="number"
                            value={occurredFromToHourMinuteDetails.occuredFromHours.toString()}
                            onKeyDown={(
                                event: React.KeyboardEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                if (event.key === ".") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleOccuredFromHourChange}
                            styles={{
                                field: occurredFromStyles(),
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblMinOccurredFrom"><span className="required-field-label">{getLocalizedValueV2("RIN_Min")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            aria-labelledby="lblMinOccurredFrom"
                            min={0}
                            max={59}
                            aria-required={true}
                            type="number"
                            value={occurredFromToHourMinuteDetails.occuredFromMinutes.toString()}
                            onKeyDown={(
                                event: React.KeyboardEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                if (event.key === ".") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleOccuredFromMinuteChange}
                            styles={{
                                field: occurredFromStyles(),
                            }}
                        />
                    </div>
                    {errorStates.OccurredFrom.length > 0 && (
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                            style={{ marginBottom: 12, marginTop: -8 }}
                        >
                            <span>
                                <FieldValidationDescription
                                    errorMessage={errorStates.OccurredFrom}
                                    descriptionColor="#e50000"
                                    iconName={"ErrorBadge"}
                                />
                            </span>
                        </div>
                    )}
                </div>
                <div className="ms-Grid-row">
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                        style={{ padding: "0 !important" }}
                    >
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblOccurredToDate"><span className="required-field-label">{getLocalizedValueV2("RIN_OccurredTo")}</span></Label>
                            </div>
                        </div>
                        <DatePicker
                            id="physicalIncidentOccuredToDateId"
                            isMonthPickerVisible={false}
                            aria-labelledby="lblOccurredToDate"
                            ariaLabel={getLocalizedValueV2("RIN_OccurredTo")}
                            componentRef={occuredToDatePickerRef}
                            aria-required={true}
                            isRequired={true}
                            //allowTextInput={true}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            value={occurredToDateValue ?? undefined}
                            maxDate={new Date()}
                            // eslint-disable-next-line react/jsx-no-bind
                            onSelectDate={onSelectOccurredToDate}
                            styles={{
                                root: {
                                    selectors: {
                                        input: occurredToStyles(),
                                        "span > div": {
                                            color: "white",
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblHourOccurredTo"><span className="required-field-label">{getLocalizedValueV2("RIN_Hour")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            id="physicalIncidentOccuredToHourId"
                            aria-labelledby="lblHourOccurredTo"
                            min={0}
                            max={23}
                            type="number"
                            aria-required={true}
                            value={occurredFromToHourMinuteDetails.occuredToHours.toString()}
                            onKeyDown={(
                                event: React.KeyboardEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                if (event.key === ".") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleOccuredToHourChange}
                            styles={{
                                field: occurredToStyles(),
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id="lblMinOccurredTo"><span className="required-field-label">{getLocalizedValueV2("RIN_Min")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            aria-labelledby="lblMinOccurredTo"
                            min={0}
                            max={59}
                            type="number"
                            aria-required={true}
                            value={occurredFromToHourMinuteDetails.occuredToMinutes.toString()}
                            onKeyDown={(
                                event: React.KeyboardEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                if (event.key === ".") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={handleOccuredToMinuteChange}
                            styles={{
                                field: occurredToStyles(),
                            }}
                        />
                    </div>
                    {errorStates.OccurredTo.length > 0 && (
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block"
                            style={{ marginBottom: 12, marginTop: -8 }}
                        >
                            <span>
                                <FieldValidationDescription
                                    errorMessage={errorStates.OccurredTo}
                                    descriptionColor="#e50000"
                                    iconName={"ErrorBadge"}
                                />
                            </span>
                        </div>
                    )}
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <DropdownAdapter
                            id="physicalSecurityTimezoneId"
                            required
                            label={getLocalizedValueV2("RIN_Timezone")}
                            role="combobox"
                            aria-haspopup={false}
                            ariaLabel={getLocalizedValueV2("RIN_SelectTimezone")}
                            selectedKey={selectedTimeZone?.key}
                            onChange={handleTimeZoneChange}
                            placeholder={getLocalizedValueV2("RIN_SelectTimezone")}
                            options={timeZones}
                            styles={{
                                root: {
                                    marginTop: "12px",
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
}
