import React from "react";
import "./Stepperv2.css"

export interface Step {
    label: string;
}

export interface StepperProps {
    steps: Step[];
    currentStepIndex: number;
}

export const Stepperv2: React.FC<StepperProps> = ({ steps, currentStepIndex }) => {
    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentStepIndex]);
    return (
        <div className="stepper-container" id="stepper-container" role="group" aria-label="progress" aria-description={`step ${currentStepIndex + 1} of ${steps.length}: incident ${steps[currentStepIndex].label}`} aria-live="polite">
            <ol className="c-stepper">
                {steps.map((step, index) => (
                    <>
                        {index === currentStepIndex && (
                            <li className="c-stepper__item__started" aria-current="step" key={index}>
                                <span className="visually-hidden">Current step: </span>
                                <span className="c-stepper__title">{step.label}</span>
                            </li>
                        )}
                        {index < currentStepIndex && (
                            <li className="c-stepper__item__completed" key={index}>
                                <span className="visually-hidden">Completed step: </span>
                                <span className="c-stepper__title">{step.label}</span>
                            </li>
                        )}
                        {index > currentStepIndex && (
                            <li className="c-stepper__item__notStarted" key={index}>
                                <span className="visually-hidden">Next step: </span>
                                <span className="c-stepper__title">{step.label}</span>
                            </li>
                        )}
                    </>
                ))}
            </ol>
            <div className="c-stepper__divider"></div>
        </div>
    );
}