import { siteNotification } from "../../Contracts/TypesAndInterfaces/NotificationMessageModel";
import { getLocalizedValue } from "../../Services/localizationService";

export const siteNotifications:siteNotification  = {

name:"List of all Notifications",
lstNotificationMessages: [
    {
        notificationMessageId:1,
        description:"For life threatening emergencies call your local emergency services (e.g. police, fire, medical).",
        descriptionKey:"RIN_NotificationMessage1"
    },
    {
        notificationMessageId:2,
        description:"For all Government/Sovereign related incidents please select the incident category (Physical or Digital), then select Government/Sovereign Related in the incident type drop down.",
        descriptionKey:"RIN_NotificationMessage2"
    },

],
additionalNotificationMessages:[
    {
        name:"List of all Notifications",
        description:"If you have tested positive for COVID-19 and want to report close contacts at work, please visit https://aka.ms/reportcovid19 or send an email to cv19@microsoft.com",
        localizedDescription:"<li>"+getLocalizedValue("RIN_NotificationMessage3Part1") + " <a href=\"https://aka.ms/reportcovid19\">https://aka.ms/reportcovid19</a> " + getLocalizedValue("RIN_NotificationMessage3Part2") + " <a href=\"mailto:cv19@microsoft.com\">cv19@microsoft.com</a></li>"
    }
],
textForeGroundColor:"#000000",
textBackGroundColor:"#FFF4CE",
moreInforURL:null!,
visibilityInSeconds:0,
}

export const notificationMessageTemplate:string = "<li>{{messagePlaceholder}}</li>";
export const lstNotificationMessagesTemplate:string = "<ul style=\"list-style-position: inside;padding: 0;margin: 0;list-style-type: '- '\">{{contentPlaceHolder}}</ul>";
