import { TextField } from '@fluentui/react';
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DsDiMalwareInputViewModel } from '../../../../Contracts/TypesAndInterfaces/DsDiMalwareInputViewModel';
import { getLocalizedValueV2 } from '../../../../Services/localizationServiceV2';
import { ErrorStatesSelector, MalwareSelector, setDigitalIncidentMalwareObject, setAffectedMachineNameErrorMessage, setMalwareAffectedMachineName, setFileNameErrorMessage, setMalwareFileName, setThreatNameErrorMessage, setMalwareThreatName } from '../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice';
import FieldValidationDescription from '../../../FieldValidationDescription/FieldValidationDescription';
import { CustomLabel } from './IncidentSpecificFieldsv2';


export default function MalwareFields() {
    const dispatch = useDispatch();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const malwareSelector = useSelector(MalwareSelector);
    const [malwareDetails, setMalwareDetails] = React.useState<DsDiMalwareInputViewModel>({ affectedMachineName: "", threatName: "", fileName: "" });


    React.useEffect(() => {
        if(malwareSelector) return;
        dispatch(setDigitalIncidentMalwareObject(malwareDetails));
    }, []);

    const affectedMachineName_CalloputProps = {
        id: "affected-machine-name-info",
        calloutDescription: 
        `<div>
            <div>
                <b role"heading" level="1">${getLocalizedValueV2("RIN_AffectedMachineNameInfo1")}</b>
            </div>
            <div>
                <b>${getLocalizedValueV2("RIN_AffectedMachineNameInfo2")}</b>
            </div>
            <ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo3")}</li>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo4")} <b>${getLocalizedValueV2("RIN_AffectedMachineNameInfo5")}</b> ${getLocalizedValueV2("RIN_AffectedMachineNameInfo6")}</li><li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo7")}</li>
            </ol>
            <div>
                <b>${getLocalizedValueV2("RIN_AffectedMachineNameInfo8")}</b>
            </div>
            <ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;padding-left: 12px;\'>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo9")}</li>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo10")}</li>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo11")}</li>
            </ol>
            <div>
                <b>${getLocalizedValueV2("RIN_AffectedMachineNameInfo12")}</b>
            </div>
            <ol class='orderedList' style='\list-style:decimal;text-align:left;margin-top:0px;margin-bottom:0px;padding-left: 12px;\'>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo13")}</li>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo14")} <b>${getLocalizedValueV2("RIN_AffectedMachineNameInfo5")}</b> ${getLocalizedValueV2("RIN_AffectedMachineNameInfo15")}</li>
                <li style='margin-bottom: 4px;'>${getLocalizedValueV2("RIN_AffectedMachineNameInfo7")}</li>
            </ol>
        </div>`,
        moreInfoLink: undefined,
    };
    const fileName_CalloutProps = {
        id: "file-name-info",
        calloutDescription: getLocalizedValueV2("RIN_FileNameInfo"),
        moreInfoLink: undefined
    };
    const threatName_CalloutProps = {
        id: "threat-name-info",
        calloutDescription: "<span style='word-break: break-all;'>"+getLocalizedValueV2("RIN_ThreatNameInfo")+"</span>",
        moreInfoLink: undefined
    };

    const machineNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareAffectedMachineName} descriptionColor={'red'} iconName={"ErrorBadge"}/>
    const fileNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareFileName} descriptionColor={'red'} iconName={"ErrorBadge"}/>
    const threatNamedescription = <FieldValidationDescription errorMessage={errorStatesDetails.malwareThreatName} descriptionColor={'red'} iconName={"ErrorBadge"}/>

    const handleAffectedMachineNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setAffectedMachineNameErrorMessage(""));
            dispatch(setMalwareAffectedMachineName(newValue));
        }
    }
    const handleFileNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setFileNameErrorMessage(""));
            dispatch(setMalwareFileName(newValue));

        }
    }
    const handleThreatNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue !== undefined) {
            dispatch(setThreatNameErrorMessage(""));
            dispatch(setMalwareThreatName(newValue));
        }
    }

    return (
        <div className="malware-fields">
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareAffectedMachineNameId"
                        required
                        aria-required={true}
                        ariaLabel={getLocalizedValueV2("RIN_AffectedMachineName")}
                        aria-labelledby={affectedMachineName_CalloputProps.id}
                        label={getLocalizedValueV2("RIN_AffectedMachineName")}
                        onRenderLabel={(props) => <CustomLabel {...affectedMachineName_CalloputProps} {...props} />}
                        value={malwareSelector?.affectedMachineName}
                        onChange={handleAffectedMachineNameChange}
                        onRenderDescription={() => machineNamedescription}
                        styles={
                            errorStatesDetails.malwareAffectedMachineName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareFileNameId"
                        required
                        aria-required={true}
                        aria-labelledby={fileName_CalloutProps.id}
                        label={getLocalizedValueV2("RIN_FileName")}
                        ariaLabel={getLocalizedValueV2("RIN_FileName")}
                        onRenderLabel={(props) => <CustomLabel {...fileName_CalloutProps} {...props} />}
                        value={malwareSelector?.fileName}
                        onChange={handleFileNameChange}
                        onRenderDescription={() => fileNamedescription}
                        styles={
                            errorStatesDetails.malwareFileName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
            <div className="ms-Grid-row" style={{marginBottom: '20px'}}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <TextField
                        id="malwareThreatNameId"
                        required
                        aria-required={true}
                        aria-labelledby={threatName_CalloutProps.id}
                        label={getLocalizedValueV2("RIN_ThreatName")}
                        ariaLabel={getLocalizedValueV2("RIN_ThreatName")}
                        onRenderLabel={(props) => <CustomLabel {...threatName_CalloutProps} {...props} />}
                        value={malwareSelector?.threatName}
                        onChange={handleThreatNameChange}
                        onRenderDescription={() => threatNamedescription}
                        styles={
                            errorStatesDetails.malwareThreatName.length > 0 ? {
                                field: {
                                    backgroundColor: "#FED9CC"
                                }
                            } :
                                {
                                    field: {
                                        backgroundColor: "white"
                                    }
                                }
                        }
                    />
                </div>
            </div>
        </div>
    );
}