import * as React from "react";
import { DefaultButton, Image } from "@fluentui/react";
import successImage from "../../Assets/success.svg";
import { useDispatch, useSelector } from "react-redux";
import { hideSuccessPage, setSubmittedIncidentId, submissionSuccessPageSelector } from "./SubmissionSuccessSlice";
import { buttonStyle } from "../DigitalSecurity/DigitalIncident/DigitalIncident";
import { setSubmissionStatus } from "../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { getLocalizedValue } from "../../Services/localizationService";

export default function SubmissionSuccess() {
    const dispatch = useDispatch();
    const message = useSelector(submissionSuccessPageSelector).message;
    const incidentId = useSelector(submissionSuccessPageSelector).incidentId;
    const handleReportAnotherIncidentClicked = () => {
        dispatch(hideSuccessPage());
        dispatch(setSubmittedIncidentId(undefined));
        dispatch(setSubmissionStatus(false));
    };

    const headingRef = React.useRef<HTMLHeadingElement>(null);
    React.useEffect(() => {
        if (headingRef.current != null) {
            headingRef.current.focus();
        }
    }, [])

    return (
        <div className="success-page">
            <h1 style={{
                marginTop: 0,
                fontSize: "34px",
                fontWeight: 500,
            }}
                tabIndex={0}
                ref={headingRef}
            >
                {getLocalizedValue("RIN_ThankYou")}
            </h1>
            {incidentId?.trim().length && (
                <p style={{ fontSize: 16 }}>
                    {getLocalizedValue("RIN_YourEIncidentNumberIs")} {incidentId}
                </p>
            )}
            <Image
                src={successImage}
                alt={getLocalizedValue("RIN_YouHaveSuccessfullySubmittedTheIncident")}
                styles={{
                    root: {
                        marginTop: 24,
                        marginBottom: 48,
                    },
                    image: {
                        margin: "0 auto",
                    },
                }}
            />
            <p style={{ fontSize: 14 }} tabIndex={0}>
                {message}
            </p>
            <DefaultButton
                styles={{
                    root: Object.assign({}, buttonStyle, {
                        marginTop: 24,
                        marginBottom: 136,
                    }),
                    rootHovered: Object.assign({}, buttonStyle, {
                        marginTop: 24,
                        marginBottom: 136,
                    }),
                }}
                onClick={handleReportAnotherIncidentClicked}
            >
                {getLocalizedValue("RIN_FileAnotherReport")}
            </DefaultButton>
        </div>
    );
}
