import * as React from "react";
import {
    DatePicker,
    DirectionalHint,
    Dropdown,
    IDatePicker,
    IDropdownOption,
    Label,
    TextField,
} from "@fluentui/react";
import {
    DayPickerStrings,
    firstDayOfWeek,
} from "../../../../Constants/DateControlConfigConstants";
import { useDispatch, useSelector } from "react-redux";
import { SelectedDigitalIncidentTypeL1Selector, SelectedDigitalIncidentTimeZoneSelector, DigitalIncidentTimeChangedSelector, DigitalIncidentInformationSelector, ErrorStatesSelector, setDigitalIncidentLocationAndTimeErrorMessage, setDigitalIncidentIncidentDate, setIsDigitalIncidentTimeChanged, setDigitalIncidentIncidentHour, setDigitalIncidentIncidentMinute, setDigitalIncidentIncidentTimeZone } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { TimeZonesSelector } from "../../../TimeZone/TimeZonesSlice";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { DropdownAdapter } from "../../Controls";


export default function DigitalLocationAndTimev2() {
    const [
        selectedTimeZone,
        setSelectedTimeZone,
    ] = React.useState<IDropdownOption>();

    const [
        defaultSelectedTimeZone,
        setDefaultSelectedTimeZone,
    ] = React.useState<IDropdownOption>();

    const selectedIncidentType = useSelector(SelectedDigitalIncidentTypeL1Selector);
    const selectedIncidentTimeZone = useSelector(SelectedDigitalIncidentTimeZoneSelector);
    const isTimeChanged = useSelector(DigitalIncidentTimeChangedSelector);
    const [timeZones, setTimeZones] = React.useState<IDropdownOption[]>([]);
    const dispatch = useDispatch();

    const digitalIncidentDetails = useSelector(DigitalIncidentInformationSelector);

    const timeZoneDetails = useSelector(TimeZonesSelector);

    const errorStatesDetails = useSelector(ErrorStatesSelector);

    const datePickerRef = React.useRef<IDatePicker>(null);

    const onSelectDate = (date: Date | null | undefined): void => {
        if (date != null && date !== undefined) {
            dispatch(setDigitalIncidentLocationAndTimeErrorMessage(""));
            dispatch(setDigitalIncidentIncidentDate(new Date(date)));
            if (!isTimeChanged) {
                dispatch(setIsDigitalIncidentTimeChanged(true));
            }
        }
        else {
            dispatch(setDigitalIncidentIncidentDate(null));
        }
    };

    const handleHourChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (!isTimeChanged) {
                dispatch(setIsDigitalIncidentTimeChanged(true));
            }
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, '');
            dispatch(setDigitalIncidentLocationAndTimeErrorMessage(""));
            let hourValue = Number(newValue);
            if (hourValue > 23 || hourValue < 0) {
                dispatch(setDigitalIncidentIncidentHour(0));
            }
            else {
                dispatch(setDigitalIncidentIncidentHour(hourValue));
            }
        }
    };

    const handleMinuteChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            if (!isTimeChanged) {
                dispatch(setIsDigitalIncidentTimeChanged(true));
            }
            newValue = event.currentTarget.value.replace(/[^0-9]*/g, '');
            dispatch(setDigitalIncidentLocationAndTimeErrorMessage(""));
            let minuteValue = Number(newValue);
            if (minuteValue > 59 || minuteValue < 0) {
                dispatch(setDigitalIncidentIncidentMinute(0));
            }
            else {
                dispatch(setDigitalIncidentIncidentMinute(minuteValue));
            }
        }
    };

    const handleTimeZoneChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option !== undefined) {
            setSelectedTimeZone(option);
            dispatch(setDigitalIncidentIncidentTimeZone(option.text));
            if (option.key !== defaultSelectedTimeZone?.key && !isTimeChanged) {
                dispatch(setIsDigitalIncidentTimeChanged(true));
            }
        }
    };

    const setBusinessRegion = () => {
        if (timeZoneDetails.length > 0) {
            var timeZoneList: IDropdownOption[] = [];
            const timeZoneOffset = -(new Date().getTimezoneOffset());
            let isCurrentTimeZoneSet = false;

            for (
                var i = 0;
                i < timeZoneDetails.length;
                i++
            ) {
                timeZoneList.push({
                    key: i.toString(),
                    text: timeZoneDetails[i].text,
                });

                if (timeZoneOffset == timeZoneDetails[i].offset && !isCurrentTimeZoneSet) {
                    isCurrentTimeZoneSet = true;
                    setSelectedTimeZone({
                        key: i.toString(),
                        text: timeZoneDetails[i].text,
                    });
                    setDefaultSelectedTimeZone({
                        key: i.toString(),
                        text: timeZoneDetails[i].text
                    });
                    dispatch(setDigitalIncidentIncidentTimeZone(timeZoneDetails[i].text));
                }
            }
            setTimeZones(timeZoneList);

            if (!isCurrentTimeZoneSet) {
                setSelectedTimeZone(timeZoneList[0]);
                setDefaultSelectedTimeZone(timeZoneList[0]);
                dispatch(setDigitalIncidentIncidentTimeZone(timeZoneList[0].text));
            }
        }
    }

    React.useEffect(() => {
        // to prevent narration on component mount
        document.querySelector("span[id='digitalSecurityBusinessRegionId-option']")?.setAttribute("aria-live", "off");
    }, [])

    React.useEffect(() => {
        if (selectedIncidentType !== undefined && (selectedTimeZone === undefined || (defaultSelectedTimeZone !== undefined && selectedIncidentTimeZone !== defaultSelectedTimeZone.text))) {
            setBusinessRegion();
        }
    }, [selectedIncidentType])

    return (
        <div className="location-time-container " style={{ marginBottom: 20 }}>
            <div className="ms-Grid-row">
                <h2 className="sectionHeading">{getLocalizedValueV2("RIN_LocationTime")}</h2>
            </div>
            <div>
                <div className="ms-Grid-row" >
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl6 ms-xxxl6 block"
                        style={{ padding: "0 !important", marginBottom: "16px" }}
                    >
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id='lblIncidentDate'><span className="required-field-label">{getLocalizedValueV2("RIN_IncidentDate")}</span></Label>
                            </div>
                        </div>
                        <DatePicker
                            id="digitalIncidentDateId"
                            aria-labelledby="lblIncidentDate"
                            ariaLabel={getLocalizedValueV2("RIN_IncidentDate")}
                            componentRef={datePickerRef}
                            aria-required={true}
                            isRequired={true}
                            firstDayOfWeek={firstDayOfWeek}
                            isMonthPickerVisible={false}
                            strings={DayPickerStrings}
                            value={digitalIncidentDetails.incidentDate ?? undefined}
                            onSelectDate={onSelectDate}
                            maxDate={new Date()}
                            styles={
                                errorStatesDetails.locationAndTime.length > 0
                                    ? {
                                        root: {
                                            selectors: {
                                                "input": {
                                                    backgroundColor: "#FED9CC",
                                                },
                                                "span > div": {
                                                    color: "white"
                                                }
                                            }
                                        },
                                    }
                                    : {
                                        root: {
                                            selectors: {
                                                "input": {
                                                    backgroundColor: "white",
                                                },
                                                "span > div": {
                                                    color: "white"
                                                }
                                            }
                                        },
                                    }
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block" style={{ marginBottom: "16px" }}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id='lblHour'><span className="required-field-label">{getLocalizedValueV2("RIN_Hour")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            aria-required={true}
                            aria-labelledby="lblHour"
                            min={0}
                            max={23}
                            type="number"
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { if (event.key === '.') { event.preventDefault(); }}}
                            onChange={handleHourChange}
                            value={digitalIncidentDetails.incidentHour.toString()}
                            styles={
                                errorStatesDetails.locationAndTime.length > 0
                                    ? {
                                        field: {
                                            backgroundColor: "#FED9CC",
                                        },
                                    }
                                    : {
                                        field: {
                                            backgroundColor: "white",
                                        },
                                    }
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 ms-xl3 ms-xxl3 ms-xxxl3 block" style={{ marginBottom: "16px" }}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                                <Label id='lblMin'><span className="required-field-label">{getLocalizedValueV2("RIN_Min")}</span></Label>
                            </div>
                        </div>
                        <TextField
                            aria-required={true}
                            aria-labelledby="lblMin"
                            min={0}
                            max={59}
                            type="number"
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { if (event.key === '.') { event.preventDefault(); } }}
                            onChange={handleMinuteChange}
                            value={digitalIncidentDetails.incidentMinute.toString()}
                            styles={
                                errorStatesDetails.locationAndTime.length > 0
                                    ? {
                                        field: {
                                            backgroundColor: "#FED9CC",
                                        },
                                    }
                                    : {
                                        field: {
                                            backgroundColor: "white",
                                        },
                                    }
                            }
                        />
                    </div>
                    {
                        errorStatesDetails.locationAndTime.length > 0 &&
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block" style={{ marginBottom: 12, marginTop: -24 }}>
                            <span>
                                <FieldValidationDescription
                                    errorMessage={
                                        errorStatesDetails.locationAndTime
                                    }
                                    descriptionColor={'red'}
                                    iconName={"ErrorBadge"}
                                />
                            </span>
                        </div>
                    }
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <DropdownAdapter
                            id="digitalSecurityBusinessRegionId"
                            required
                            label={getLocalizedValueV2("RIN_Timezone")}
                            role="combobox"
                            aria-haspopup={false}
                            ariaLabel={getLocalizedValueV2("RIN_SelectBusinessRegion")}
                            selectedKey={selectedTimeZone?.key}
                            onChange={handleTimeZoneChange}
                            placeholder={getLocalizedValueV2("RIN_SelectTimezone")}
                            options={timeZones}
                            styles={{
                                root: {
                                    marginBottom: "12px",
                                },
                                
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
