import React from "react";
import { useSelector } from "react-redux";
import { DigitalUnauthorizedAccessL2EventTypes, SovereignDigitalUnauthorizedAccessL2EventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { sovereignIncidentDetailsSelector } from "../../Sovereign/SovereignSlice";
import { DigitalIncidentTypesSelector} from "../DigitalIncident/DigitalIncidentSlice";
import UnauthorizedAccessSuspiciousLogin from "./UnauthorizedAccessSuspiciousLogin";
import TechnicalSupportScam from "./TechnicalSupportScam";
import UnauthorizedAccessUninitiatedMFA from "./UnauthorizedAccessUninitiatedMFA";
import UnauthorizedAccessUnknownDevice from "./UnauthorizedAccessUnknownDevice";

export default function UnauthorizedAccessOrHackFields() {
    const selectedIncidentTypeL2 = useSelector(DigitalIncidentTypesSelector).selectedIncidentTypeL2;
    const sovereignDetails = useSelector(sovereignIncidentDetailsSelector);

    return (
        <>
            {(selectedIncidentTypeL2?.typeId === DigitalUnauthorizedAccessL2EventTypes.SuspiciousLogin ||
                (sovereignDetails.selectedIncidentTypeL2 && sovereignDetails.selectedIncidentTypeL2.key === SovereignDigitalUnauthorizedAccessL2EventTypes.SuspiciousLogin)) &&
                <UnauthorizedAccessSuspiciousLogin />
            }
            {(selectedIncidentTypeL2?.typeId === DigitalUnauthorizedAccessL2EventTypes.UnknownDevice ||
                (sovereignDetails.selectedIncidentTypeL2 && sovereignDetails.selectedIncidentTypeL2.key === SovereignDigitalUnauthorizedAccessL2EventTypes.UnknownDevice)) &&
                <UnauthorizedAccessUnknownDevice />
            }
            {(selectedIncidentTypeL2?.typeId === DigitalUnauthorizedAccessL2EventTypes.UninitiatedMFA ||
                (sovereignDetails.selectedIncidentTypeL2 && sovereignDetails.selectedIncidentTypeL2.key === SovereignDigitalUnauthorizedAccessL2EventTypes.UninitiatedMFA)) &&
                <UnauthorizedAccessUninitiatedMFA />
            }
            {((sovereignDetails.selectedIncidentTypeL2 && sovereignDetails.selectedIncidentTypeL2.key === SovereignDigitalUnauthorizedAccessL2EventTypes.TechnicalSupportScam)) &&
                <TechnicalSupportScam />
            }
        </>
    );
}