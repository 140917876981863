import { all } from "redux-saga/effects";
import { watchClearSimulationDetails, watchGetApplicationSettings, watchGetApplicationStatistics, watchGetFlowConfigurations, watchGetSimulationStatistics, watchSubmitApplicationSettings } from "./applicationAdminSaga";
import { watchClearForm, watchGetDigitalIncidentTypes,  watchGetTimeZones, watchPostDigitalSimulationIncident, watchPostFormInformation, watchValidateDigitalV2 } from "./digitalIncidentSaga";
import { watchCloseDigitalIncidentTypesPanel, watchClosePhysicalIncidentTypesPanel, watchGetHelpSectionLinks, watchGetHelpSections } from "./helpSectionSaga";
import { watchGetIncidentCategories } from "./incidentCategorySaga";
import { watchGetSiteNotificationsSections } from "./notificationsSaga";
import { watchGetPsIncidentTypesL1, watchGetPsIncidentTypesL2, watchGetPsIncidentTypesL3, watchGetPsIncidentTypesL4, watchGetPsItemCategoryTypes, watchGetPsItemMakeTypes, watchGetPsItemModelTypes, watchGetPsItemSubCategoryTypes, watchGetPsLocationsL1, watchGetPsLocationsL2, watchGetPsLocationsL3, watchGetPsPersonInvolvementTypes, watchGetPsVehicleColorTypes, watchGetPsVehicleInvolvementTypes, watchGetPsVehicleMakeTypes, watchGetPsVehicleModelTypes, watchGetPsVehicleStyleTypes, watchPostPsIncidnet, watchPsIncidentFormReset, watchValidatePhysicalV2 } from "./PhysicalIncidentSaga";
import { watchGetSimulationCampaigns, watchSubmitUploadedSimulationCampaign } from "./simulationCampaignSaga";
import { watchGetSovereignIncidentTypes, watchGetSovereignIncidentTypesL2, watchGetSovereigns,watchPostSovereignDigitalIncident, watchPostSovereignPhysicalIncident } from "./sovereignIncidentSaga";
import { watchGetFeatureFlags, watchGetUserFlightStatus, watchGetUserProfile, watchGetUserTestInPRodStatus } from "./userProfileSaga";

export default function* rootSaga() {
    yield all([
        watchClosePhysicalIncidentTypesPanel(),
        watchCloseDigitalIncidentTypesPanel(),
        watchGetSovereignIncidentTypesL2(),
        watchGetDigitalIncidentTypes(),
        watchGetIncidentCategories(),
        watchPostFormInformation(),
        watchGetHelpSections(),
        watchGetHelpSectionLinks(),
        watchGetSimulationCampaigns(),
        watchGetTimeZones(),
        watchClearForm(),
        watchGetUserProfile(),
        watchPostDigitalSimulationIncident(),
        watchGetPsIncidentTypesL1(),
        watchGetPsIncidentTypesL2(),
        watchGetPsIncidentTypesL3(),
        watchGetPsIncidentTypesL4(),
        watchGetPsLocationsL1(),
        watchGetPsLocationsL2(),
        watchGetPsLocationsL3(),
        watchGetPsPersonInvolvementTypes(),
        watchGetPsVehicleInvolvementTypes(),
        watchGetPsVehicleMakeTypes(),
        watchGetPsVehicleModelTypes(),
        watchGetPsVehicleColorTypes(),
        watchGetPsVehicleStyleTypes(),
        watchGetPsItemMakeTypes(),
        watchGetPsItemModelTypes(),
        watchGetPsItemCategoryTypes(),
        watchGetPsItemSubCategoryTypes(),
        watchPostPsIncidnet(),
        watchPsIncidentFormReset(),
        watchSubmitUploadedSimulationCampaign(),
        watchGetApplicationSettings(),
        watchSubmitApplicationSettings(),
        watchGetApplicationStatistics(),
        watchGetSimulationStatistics(),
        watchClearSimulationDetails(),
        watchGetSovereigns(),
        watchGetSovereignIncidentTypes(),
        watchPostSovereignDigitalIncident(),
        watchPostSovereignPhysicalIncident(),
        watchGetSiteNotificationsSections(),
        watchValidatePhysicalV2(),
        watchGetUserFlightStatus(),
        watchValidateDigitalV2(),
        // watchValidateExecutiveAlias(),
        watchGetFlowConfigurations(),
        watchGetUserTestInPRodStatus(),
        watchGetFeatureFlags(),
    ]);
}