import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "../../Contracts/ServiceModels/ServiceCommunicationSettings";

export const initialState: UserProfile = {
    displayName: "",
    onPremisesUserPrincipalName: "",
    jobTitle: "",
    picture: "",
    pictureUrl: "",
    isUserMemberOfFlight: false,
    isFlightStatusAPICallCompleted: false,
    isUserMemberOfTestInProdGroup: false,
};

const UserProfileSlice = createSlice({
    name: "UserProfile",
    initialState,
    reducers: {
        getUserProfile: (state, { payload }: PayloadAction<string>) => {},
        setUserProfile: (state, { payload }: PayloadAction<UserProfile>) => {
            state.displayName = payload.displayName;
            state.jobTitle = payload.jobTitle;
            state.onPremisesUserPrincipalName = payload.onPremisesUserPrincipalName;
        },
        setUserProfilePicture: (state, { payload }: PayloadAction<string>) => {
            state.pictureUrl = payload;
        },
        getUserFlightStatus: () => {},
        setUserFlightStatus: (state, { payload }: PayloadAction<[boolean, boolean]>) => {
            state.isUserMemberOfFlight = payload[0];
            state.isFlightStatusAPICallCompleted = payload[1];
        },
        getUserTestInProdStatus: () => {},
        setUserTestInProdStatus: (state, { payload }: PayloadAction<boolean>) => {
            state.isUserMemberOfTestInProdGroup = payload;
        },
    },
});
export const { getUserProfile, setUserProfile, setUserProfilePicture, getUserFlightStatus, setUserFlightStatus , getUserTestInProdStatus, setUserTestInProdStatus} = UserProfileSlice.actions;
export default UserProfileSlice.reducer;
export const UserProfileSelector = (state: { UserProfile: UserProfile }) => state.UserProfile;
export const UserFlightStatusSelector = (state:{UserProfile: UserProfile}) => state.UserProfile.isUserMemberOfFlight;
export const UserFlightStatusAPICallSelector = (state: {UserProfile: UserProfile}) => state.UserProfile.isFlightStatusAPICallCompleted;
export const UserTestInProdStatusSelector = (state:{UserProfile: UserProfile}) => state.UserProfile.isUserMemberOfTestInProdGroup;