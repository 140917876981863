import { IDropdownOption } from "@fluentui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DigitalEventTypes } from "../../../../Contracts/Enums/DigitalIncidentEnums";
import { useBoolean } from "../../../../Hooks/useBoolean";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import {
	SelectedDigitalIncidentTypeL1Selector,
	DigitalIncidentTypesSelector,
	SelectedDigitalIncidentTypeL2Selector,
	clearDigitalIncidentErrorStates,
	setSelectedDigitalIncidentTypeL2,
	getDigitalIncidentTypes,
	setLocalizedSelectedDigitalIncidentTypeNameL2,
} from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { SimulationCampaignSelector } from "../../../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { isSovereignIncidentSelector } from "../../../Sovereign/SovereignSlice";
import { DefaultButtonAdapter, PrimaryButtonAdapter, DropdownAdapter } from "../../Controls";
import { CustomLabel } from "../IncidentSpecificFieldsv2/IncidentSpecificFieldsv2";
import { PhishingSimulationV2 } from "../PhishingSimulation/PhishingSimulationv2";

export function DigitalInicdentTypesL2v2() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const date = new Date(new Date().toDateString());
	const selectedDigitalIncidentTypeL1 = useSelector(SelectedDigitalIncidentTypeL1Selector);
	const simulationCampaign = useSelector(SimulationCampaignSelector).simulationCampaign;
	const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find((x) => x.typeId === DigitalEventTypes.PhishingEMail);
	const phishingSimulation = simulationCampaign;
	const [isPhishingSimulation, setIsPhishingSimulation] = React.useState(
		phishingIncidentType !== undefined && phishingSimulation !== undefined && phishingSimulation !== null
			? phishingIncidentType.isSimulationEnabled &&
					phishingSimulation.isActive &&
					date <= new Date(phishingSimulation.endDate.toDateString()) &&
					date >= new Date(phishingSimulation.startDate.toDateString())
			: false
	);
	const selectedIncidentTypeL2 = useSelector(SelectedDigitalIncidentTypeL2Selector);
	React.useEffect(() => {
		setIsPhishingSimulation(
			phishingIncidentType !== undefined && phishingSimulation !== undefined && phishingSimulation !== null
				? phishingIncidentType.isSimulationEnabled &&
						phishingSimulation.isActive &&
						date <= new Date(phishingSimulation.endDate.toDateString()) &&
						date >= new Date(phishingSimulation.startDate.toDateString())
				: false
		);
	}, [phishingSimulation]);
	const isGovIncident = useSelector(isSovereignIncidentSelector);
	const [
		isPhishingSimulationExperienceShown,
	] = useBoolean(isPhishingSimulation && selectedDigitalIncidentTypeL1?.typeId === DigitalEventTypes.PhishingEMail);

	React.useEffect(() => {
		if (!isPhishingSimulationExperienceShown && selectedDigitalIncidentTypeL1?.typeId !== DigitalEventTypes.UnauthorizedAccess)
			navigate("details", { replace: true });
	}, []);

	const [incidentSubcategoryCalloutProps, setIncidentSubcategoryCalloutProps] = React.useState({
		id: "incident-subcategory-info",
		calloutDescription: "",
		moreInfoLink: undefined,
	});
	const handleL2IncidentSelection = (item: IDropdownOption | undefined) => {
		if (item) {
			if (selectedIncidentTypeL2 !== undefined && selectedIncidentTypeL2.typeId !== item.key) {
				dispatch(clearDigitalIncidentErrorStates());
			}
			dispatch(setSelectedDigitalIncidentTypeL2(digitalIncidentTypeL2Details.find((s) => s.typeId === item.key)));
			dispatch(setLocalizedSelectedDigitalIncidentTypeNameL2(item.text));
		}
	};
	const [incidentTypesL2, setIncidentTypesL2] = React.useState<IDropdownOption[]>([]);
	const digitalIncidentTypeL2Details = useSelector(DigitalIncidentTypesSelector).incidentTypesL2;

	React.useEffect(() => {
		if (selectedDigitalIncidentTypeL1?.typeId === DigitalEventTypes.UnauthorizedAccess) {
			dispatch(
				getDigitalIncidentTypes({
					level: 2,
					parentIncidentTypeId: DigitalEventTypes.UnauthorizedAccess,
				})
			);
		}
	}, [selectedDigitalIncidentTypeL1]);

	React.useEffect(() => {
		if (digitalIncidentTypeL2Details.length > 0) {
			var incidentTypesL2: IDropdownOption[] = [];
			setIncidentSubcategoryCalloutProps((prevState) => ({
				...prevState,
				calloutDescription: "<div>",
			}));
			digitalIncidentTypeL2Details.forEach((incidentType, index) => {
				setIncidentSubcategoryCalloutProps((prevState) => ({
					...prevState,
					calloutDescription: prevState.calloutDescription + "<div><b>",
				}));
				incidentTypesL2.push({
					key: incidentType.typeId,
					text: getLocalizedValueV2(incidentType.nameKey),
				});
				if (index !== digitalIncidentTypeL2Details.length - 1) {
					setIncidentSubcategoryCalloutProps((prevState) => ({
						...prevState,
						calloutDescription: prevState.calloutDescription + incidentType.name + ":" + "</b></br>" + getLocalizedValueV2(incidentType.descriptionKey) + "</div><br>",
					}));
				} else {
					setIncidentSubcategoryCalloutProps((prevState) => ({
						...prevState,
						calloutDescription: prevState.calloutDescription + incidentType.name + ":" + "</b></br>" + getLocalizedValueV2(incidentType.descriptionKey) + "</div>",
					}));
				}
			});
			setIncidentSubcategoryCalloutProps((prevState) => ({
				...prevState,
				calloutDescription: prevState.calloutDescription + "</div>",
			}));
			setIncidentTypesL2(incidentTypesL2);
		}
	}, [digitalIncidentTypeL2Details]);

	if (isPhishingSimulationExperienceShown)
		return (
			<PhishingSimulationV2
				{...{
					isPhishingSimulationExperienceShown,
					image: phishingSimulation?.attachmentUrl,
					incidentTypeId: phishingSimulation?.incidentTypeId,
					simulationCampaignId: phishingSimulation?.campaignId,
				}}
			/>
		);
	else
		return (
			<div>
				<DropdownAdapter
					id="unauthorizedAccessDropdownId"
					required
					label={getLocalizedValueV2("RIN_SelectIncidentSubcategory")}
					ariaLabel={getLocalizedValueV2("RIN_SelectDigitalIncidentType")}
					selectedKey={selectedIncidentTypeL2?.typeId}
					role="combobox"
					aria-haspopup={false}
					onRenderLabel={(props) => <CustomLabel {...incidentSubcategoryCalloutProps} {...props} />}
					onChange={(e, item) => handleL2IncidentSelection(item)}
					placeholder={getLocalizedValueV2("RIN_SelectDigitalSecurityEventType")}
					options={incidentTypesL2}
					styles={{
						root: {
							marginTop: 16,
							marginBottom: 16,
						},
					}}
				/>
				<div className="ms-Grid-row" style={{ marginBottom: 16 }}>
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
						<DefaultButtonAdapter onClick={() => navigate("/v2/create/digital")}>{getLocalizedValueV2("RIN_Back")}</DefaultButtonAdapter>{" "}
						<PrimaryButtonAdapter
							disabled={!selectedIncidentTypeL2}
							onClick={() => {
								
								navigate("details");
							}}
						>
							{getLocalizedValueV2("RIN_Next")}
						</PrimaryButtonAdapter>
					</div>
				</div>
			</div>
		);
}
