import * as React from "react";
import { CoherencePanelSize, CoherencePanel } from "@coherence-design-system/controls";
import { PrimaryButton, DefaultButton } from "@fluentui/react";
import { Toggle } from '@fluentui/react/lib/Toggle';
import { mergeStyleSets } from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';
import styles from "./PhishingCampaignPanel.module.css";
import { DayPickerStrings, firstDayOfWeek } from "../../../Constants/DateControlConfigConstants";
import {
    MessageBar, MessageBarType
} from '@fluentui/react';
import { useDispatch, useSelector } from "react-redux";
import { getSimulationCampaigns, resetErrorStates, setApiStatus, setEndDateErrorState, setFileSizeErrorState, setFileTypeErrorState, setIsUploadedSimulationCampaignSubmitted, setPreviousSimulationCampaign, setStartDateErrorState, setUploadedSimulationCampaign, SimulationCampaignSelector, submitUploadedSimulationCampaign } from "../IncidentType/SimulationCampaignSlice";
import { DigitalEventTypes } from "../../../Contracts/Enums/DigitalIncidentEnums";
import { DsSimulationCampaignInputViewModel } from "../../../Contracts/TypesAndInterfaces/DsSimulationCampaignInputViewModel";
import path from "path";
import FieldValidationDescription from "../../FieldValidationDescription/FieldValidationDescription";
import { DigitalIncidentTypesSelector } from "../DigitalIncident/DigitalIncidentSlice";
import { DatePicker, TextField } from "@fluentui/react";
import { getLocalizedValue } from "../../../Services/localizationService";

export interface PhishingCampaignPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    allowedFileSize: number;
}
const buttonStyles = { root: { marginRight: 8 } };

const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 15px 0',
        maxWidth: '300px',
    },
});

export const PhishingCampaignPanel: React.FC<PhishingCampaignPanelProps> = ({ isOpen, dismissPanel, allowedFileSize }) => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const simulationCampaignDetails = useSelector(SimulationCampaignSelector);
    const apiStatus = simulationCampaignDetails.apiStatus;
    const isCampaignSubmitted = simulationCampaignDetails.isUploadedSimulationCampaignSubmitted;
    const errorStates = simulationCampaignDetails.errorStates;
    const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find(x => x.typeId === DigitalEventTypes.PhishingEMail);
    const phishingSimulation = phishingIncidentType?.isSimulationEnabled && simulationCampaignDetails.simulationCampaign!==null ? simulationCampaignDetails.simulationCampaign : undefined;
    const [simulationCampaign, setSimulationCampaign] = React.useState<DsSimulationCampaignInputViewModel>({
        campaignId: phishingSimulation=== undefined ? 0 : phishingSimulation.campaignId,
        isActive: phishingSimulation === undefined ? false : phishingSimulation.isActive,
        incidentTypeId: phishingSimulation === undefined ? (phishingIncidentType === undefined ? 0 : phishingIncidentType.simulationIncidentTypeId) : phishingSimulation.incidentTypeId,
        startDate: phishingSimulation?.startDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.startDate,
        endDate: phishingSimulation?.endDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.endDate,
        attachment: phishingSimulation === undefined ?
            {
                name: "",
                extension: "",
                fileContent: "",
                fileSizeInKB: 0
            } :
            {
                name: phishingSimulation.attachmentName,
                extension: phishingSimulation.attachmentExtension,
                fileContent: phishingSimulation.attachmentContent,
                fileSizeInKB: phishingSimulation.attachmentSizeInKB
            },
        incidentTimeZoneOffset: new Date().getTimezoneOffset(),
        phishingSimulationDetails : phishingSimulation === undefined ? {
            senderName:"",
            senderEmail:"",
            emailSubject:"",
            outlookWarningMessage:"",
            emailBody:""
        }:{
            senderName:phishingSimulation.senderName,
            senderEmail:phishingSimulation.senderEmail,
            emailSubject:phishingSimulation.emailSubject,
            outlookWarningMessage:phishingSimulation.outlookWarningMessage,
            emailBody:phishingSimulation.emailBody
        }
    });
    const [imgUrl, setImgUrl] = React.useState(phishingSimulation?.attachmentUrl);
    const isPhishingSimulationActive = phishingSimulation === undefined ? false : phishingSimulation.isActive;
    const [phishingSimulationStatus, setPhishingSimulationStatus] = React.useState(isPhishingSimulationActive);

    // const [senderName, setSenderName] = React.useState<string | undefined>("Figma");
    // const [senderEmail, setSenderEmail] = React.useState<string | undefined>("no-reply@figma.com");
    // const [emailSubject, setEmailSubject] = React.useState<string | undefined>('[EXTERNAL] Joel Hoke has invited you to join the project "Coherence Design System"');
    // const [warningMessage, setWarningMessage] = React.useState<string | undefined>("You don't often get email from no-reply@figma.com. Learn why this is important");
    // const [emailBody, setEmailBody] = React.useState<string | undefined>(`Joel Hoke
    //                             has invited you to join the project
    //                             "Coherence Design System"
    //                             Accept invite
    //                             Figma is a design platform for teams who build products together. Born on the Web, Figma helps the entire product team create, test, and ship better designs, faster.`);

    const startDatedescription = <FieldValidationDescription errorMessage={errorStates.startDate} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const endDatedescription = <FieldValidationDescription errorMessage={errorStates.endDate} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const fileSizeDescription = <FieldValidationDescription errorMessage={errorStates.fileSize} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const fileTypeDescription = <FieldValidationDescription errorMessage={errorStates.fileType} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const senderNameDescription = <FieldValidationDescription errorMessage={errorStates.senderName} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const emailSubjectDescription = <FieldValidationDescription errorMessage={errorStates.emailSubject} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const emailBodyDescription = <FieldValidationDescription errorMessage={errorStates.emailBody} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const outlookWarningMessageDescription = <FieldValidationDescription errorMessage={errorStates.outlookWarningMessage} descriptionColor={'red'} iconName={"ErrorBadge"} />
    const senderEmailDescription = <FieldValidationDescription errorMessage={errorStates.senderEmail} descriptionColor={'red'} iconName={"ErrorBadge"} />

    

    const saveCampaignToState = () => new Promise((resolve: any) => {
        dispatch(setUploadedSimulationCampaign(simulationCampaign));
        resolve();
    })
    const handleSaveCampaign = () => {
        saveCampaignToState().then(() => {
            dispatch(setIsUploadedSimulationCampaignSubmitted(false));
            dispatch(submitUploadedSimulationCampaign());
        })
    }

    React.useEffect(() => {
        if (phishingSimulation !== undefined && phishingSimulation !== null) {
            dispatch(setPreviousSimulationCampaign({
                campaignId: phishingSimulation.campaignId,
                startDate: phishingSimulation.startDate,
                endDate: phishingSimulation.endDate,
                attachment: {
                    name: phishingSimulation.attachmentName,
                    fileContent: phishingSimulation.attachmentContent,
                    fileSizeInKB: phishingSimulation.attachmentSizeInKB,
                    extension: phishingSimulation.attachmentExtension
                },
                isActive: phishingSimulation.isActive,
                incidentTypeId: phishingSimulation.incidentTypeId,
                incidentTimeZoneOffset: new Date().getTimezoneOffset(),
                phishingSimulationDetails : phishingSimulation === undefined ? {
                    senderName:"",
                    senderEmail:"",
                    emailSubject:"",
                    outlookWarningMessage:"",
                    emailBody:""
                } :{
                    senderName:phishingSimulation.senderName,
                    senderEmail:phishingSimulation.senderEmail,
                    emailSubject:phishingSimulation.emailSubject,
                    outlookWarningMessage:phishingSimulation.outlookWarningMessage,
                    emailBody:phishingSimulation.emailBody
                }
            }))

            setSimulationCampaign({
                campaignId: phishingSimulation === undefined ? 0 : phishingSimulation.campaignId,
                isActive: phishingSimulation === undefined ? false : phishingSimulation.isActive,
                incidentTypeId: phishingSimulation === undefined ? (phishingIncidentType === undefined ? 0 : phishingIncidentType.simulationIncidentTypeId) : phishingSimulation.incidentTypeId,
                startDate: phishingSimulation?.startDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.startDate,
                endDate: phishingSimulation?.endDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.endDate,
                attachment: phishingSimulation === undefined ?
                    {
                        name: "",
                        extension: "",
                        fileContent: "",
                        fileSizeInKB: 0
                    } :
                    {
                        name: phishingSimulation.attachmentName,
                        extension: phishingSimulation.attachmentExtension,
                        fileContent: phishingSimulation.attachmentContent,
                        fileSizeInKB: phishingSimulation.attachmentSizeInKB
                    },
                incidentTimeZoneOffset: new Date().getTimezoneOffset(),
                phishingSimulationDetails : phishingSimulation === undefined ? {
                    senderName:"",
                    senderEmail:"",
                    emailSubject:"",
                    outlookWarningMessage:"",
                    emailBody:""
                } :{
                    senderName:phishingSimulation.senderName,
                    senderEmail:phishingSimulation.senderEmail,
                    emailSubject:phishingSimulation.emailSubject,
                    outlookWarningMessage:phishingSimulation.outlookWarningMessage,
                    emailBody:phishingSimulation.emailBody
                }
            })
        }
        dispatch(resetErrorStates());

    }, [phishingSimulation])

    React.useEffect(() => {
        if (isCampaignSubmitted) {
            dispatch(getSimulationCampaigns());
        }
    }, [isCampaignSubmitted])

    React.useEffect(() => {
        return () => {
            dispatch(setIsUploadedSimulationCampaignSubmitted(false));
            dispatch(setApiStatus({ statusCode: 200, statusReceived: true }));
        }
    }, [])

    const onSelectStartDate = (date: Date | null | undefined): void => {
        if (date) {
            dispatch(setStartDateErrorState(""));
            setSimulationCampaign({
                ...simulationCampaign, startDate: date
            });
        }
    };

    const onSelectEndDate = (date: Date | null | undefined): void => {
        if (date) {
            dispatch(setEndDateErrorState(""));
            setSimulationCampaign({
                ...simulationCampaign, endDate: date
            });
        }
    };

    const isSaveEnabled = (): boolean => {
        if (phishingSimulation === undefined || phishingSimulation === null) {
            return phishingSimulationStatus && simulationCampaign.attachment.fileContent.length > 0;
        }
        else {
            return (errorStates.endDate === "" && errorStates.startDate === "" && errorStates.fileType === "" && errorStates.fileSize === "" &&
                ((phishingSimulation.startDate.toDateString() !== simulationCampaign.startDate.toDateString()) ||
                    (phishingSimulation.endDate.toDateString() !== simulationCampaign.endDate.toDateString()) ||
                    (simulationCampaign.attachment.extension !== phishingSimulation.attachmentExtension) ||
                    (simulationCampaign.attachment.name !== phishingSimulation.attachmentName) ||
                    (simulationCampaign.isActive !== phishingSimulation.isActive) || 
                    (simulationCampaign.phishingSimulationDetails.senderEmail !== phishingSimulation.senderEmail) ||
                    (simulationCampaign.phishingSimulationDetails.senderName !== phishingSimulation.senderName) ||
                    (simulationCampaign.phishingSimulationDetails.outlookWarningMessage !== phishingSimulation.outlookWarningMessage) ||
                    (simulationCampaign.phishingSimulationDetails.emailSubject !== phishingSimulation.emailSubject) || 
                    (simulationCampaign.phishingSimulationDetails.emailBody !== phishingSimulation.emailBody) ));
                    
        }
    }

    const onRenderFooterContent = React.useCallback(() => (
        <div>
            <PrimaryButton text={getLocalizedValue("RIN_Save")} onClick={handleSaveCampaign} styles={buttonStyles} disabled={!isSaveEnabled()}></PrimaryButton>
            <DefaultButton text={getLocalizedValue("RIN_Cancel")} onClick={dismissPanel} styles={buttonStyles}></DefaultButton>
        </div>
    ), [dismissPanel, phishingSimulationStatus, simulationCampaign, errorStates, phishingSimulation]);

    const handleStatusChange = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked !== undefined) {
            setPhishingSimulationStatus(checked);
            setSimulationCampaign({ ...simulationCampaign, isActive: checked });
        }
    }

    const toBase64 = (file: File) =>
        new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });

    const handleFileChange = async (e: any) => {
        dispatch(setFileSizeErrorState(""));
        dispatch(setFileTypeErrorState(""));
        const file: File = e.target.files[0];

        if (file !== undefined) {
            if (file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/jpg") {
                dispatch(setFileTypeErrorState(getLocalizedValue("RIN_AttachmentIsNotASupportedType")));
                setImgUrl(undefined);
                setSimulationCampaign({
                    ...simulationCampaign, attachment:
                    {
                        extension: "",
                        fileSizeInKB: 0,
                        fileContent: "",
                        name: ""
                    }
                });
                return;
            }
            else if (file.size > allowedFileSize * 1024 * 1024) {
                dispatch(setFileSizeErrorState(file.name + " "+getLocalizedValue("RIN_ExceedsTheAllowedSizeOf") +" " + allowedFileSize + "MB"));
                setImgUrl(undefined);
                setSimulationCampaign({
                    ...simulationCampaign, attachment:
                    {
                        extension: "",
                        fileSizeInKB: 0,
                        fileContent: "",
                        name: ""
                    }
                });
                return;
            }

            const reader = new FileReader();
            var fileContent = "";
            reader.readAsDataURL(file);
            reader.addEventListener("load", function () {
                setImgUrl(reader.result?.toString());
            }, false);

            await toBase64(file).then((result: any) => {
                fileContent = result.split(",")[1];
            });
            setSimulationCampaign({
                ...simulationCampaign, attachment:
                {
                    name: file.name,
                    extension: path.extname(file.name),
                    fileContent: fileContent,
                    fileSizeInKB: file.size / 1024
                }
            });
        }
    }
    const phishingSimulationMessage = (
        <div>
            <MessageBar
                messageBarType=
                {
                    (apiStatus.statusCode === 401 || apiStatus.statusCode === 400 || apiStatus.statusCode === 404 || apiStatus.statusCode === 500 || apiStatus.statusCode === undefined) ?
                        MessageBarType.error :
                        isCampaignSubmitted ? MessageBarType.success : MessageBarType.info
                }
                onDismiss={() => dispatch(setApiStatus({ statusCode: apiStatus.statusCode, statusReceived: false }))}
                dismissButtonAriaLabel={getLocalizedValue("RIN_Close")}
                styles={
                    (apiStatus.statusCode === 401 || apiStatus.statusCode === 400 || apiStatus.statusCode === 404 || apiStatus.statusCode === 500 || apiStatus.statusCode === undefined) ? {
                        root: {
                            backgroundColor: "#FED9CC",
                            marginLeft: '0px'
                        }
                    } :
                        (isPhishingSimulationActive && phishingSimulation?.isActive && new Date(currentDate.toDateString()) <= new Date(phishingSimulation.endDate.toDateString()) &&
                            new Date(currentDate.toDateString()) >= new Date(phishingSimulation.startDate.toDateString())) ?
                            {
                                root: {
                                    backgroundColor: "#DFF6DD",
                                    marginLeft: '0px'
                                },
                                icon: {
                                    color: "rgb(16, 124, 16)"
                                }

                            }
                            :
                            {
                                root: {
                                    backgroundColor: "#F2F2F2",
                                    marginLeft: '0px'
                                },
                                icon: {
                                    color: "black"
                                }
                            }

                }
            >
                {
                    apiStatus.statusCode === 401 ? <>Unauthorized.</> :
                        (apiStatus.statusCode === 500 || apiStatus.statusCode === 400 || apiStatus.statusCode === 404 || apiStatus.statusCode === undefined) ? <>{getLocalizedValue("RIN_PhishingCampaignErrorMessage")}</> :
                            isCampaignSubmitted ? <>{getLocalizedValue("RIN_PhishingCampaignInfoMessage1")}</> :
                                <>{getLocalizedValue("RIN_PhishingCampaignInfoMessage2")} {isPhishingSimulationActive && phishingSimulation?.isActive &&
                                    new Date(currentDate.toDateString()) <= new Date(phishingSimulation.endDate.toDateString()) &&
                                    new Date(currentDate.toDateString()) >= new Date(phishingSimulation.startDate.toDateString()) ? getLocalizedValue("RIN_Active") : getLocalizedValue("RIN_InActive")}  {getLocalizedValue("RIN_AcrossMicrosoft")}</>
                }
            </MessageBar>
        </div>
    );

    const phishingImageOrUpload = (): JSX.Element => {
        let imageSection = <></>;
        if (phishingSimulation === undefined) {
            if (imgUrl === undefined) {
                imageSection = (
                    <div className={styles.previewBlank}>
                        {getLocalizedValue("RIN_NoImageCurrentlyAdded")}
                    </div >
                );
            }
            else
                if (imgUrl !== undefined && imgUrl.length > 0) {
                    imageSection = (
                        <div>
                            <img className={styles.preview} src={imgUrl} alt={getLocalizedValue("RIN_SimulationCampaignImage")}></img>
                        </div>
                    );
                }
        }
        else {
            if (imgUrl === undefined) {
                imageSection = (
                    <div className={styles.previewBlank}>
                        No Image currently added
                    </div >
                );
            }
            else
                if (phishingSimulationStatus) {
                    imageSection = (
                        <div>
                            <img className={styles.preview} src={imgUrl} alt={getLocalizedValue("RIN_SimulationCampaignImage")}></img>
                        </div>
                    );
                }
                else {
                    imageSection = (
                        <div>
                            <img className={styles.previewGray} src={imgUrl} alt={getLocalizedValue("RIN_SimulationCampaignImage")}></img>
                        </div>
                    );
                }
        }
        return imageSection;
    }

    return (
        <CoherencePanel
            className={styles.phishingCampaignPanel}
            titleText={getLocalizedValue("RIN_AdminSetPhishingCampaign")}
            panelSize={CoherencePanelSize.medium}
            isOpen={isOpen}
            onLightDismissClick={dismissPanel}
            isLightDismiss={true}
            hasCloseButton={true}
            isFooterAtBottom={true}
            onDismiss={dismissPanel}
            onRenderFooter={onRenderFooterContent}
            closeButtonAriaLabel={getLocalizedValue("RIN_ClosePanel")}
            backButton={{
                ariaLabel: getLocalizedValue("RIN_Back"),
                onClick: dismissPanel
            }}
            styles={{
                header: {
                    marginBottom: '24px'
                }
            }}
            //panelBodyResponsiveMargin={true}
        >
            <div>
                <div className="stickey-Header-Container">
                    {apiStatus.statusReceived && phishingSimulationMessage}
                </div>
                <div className={styles.toggle}>
                    <Toggle label={getLocalizedValue("RIN_SetStatus")} onText={getLocalizedValue("RIN_Enabled")} offText={getLocalizedValue("RIN_Disabled")} onChange={handleStatusChange} checked={phishingSimulationStatus} />
                </div>
                <div className={styles.date}>
                    <div className={styles.starting}>
                        <DatePicker
                            label={getLocalizedValue("RIN_StartingOn")}
                            isMonthPickerVisible={false}
                            className={controlClass.control}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            placeholder={getLocalizedValue("RIN_SelectaDatePlaceholder")}
                            ariaLabel={getLocalizedValue("RIN_SelectADate")}
                            //allowTextInput={true}
                            styles={{
                                root: {
                                    width: '240px',
                                    marginBottom: '4px'
                                }
                            }}
                            value={simulationCampaign.startDate}
                            disabled={!phishingSimulationStatus}
                            onSelectDate={onSelectStartDate}
                        />
                        <div style={{ marginTop: "-4px" }}>
                            {errorStates.startDate.length > 0 && startDatedescription}
                        </div>
                    </div>
                    <div className={styles.ending}>
                        <DatePicker
                            label={getLocalizedValue("RIN_EndingOn")}
                            className={controlClass.control}
                            isMonthPickerVisible={false}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            placeholder={getLocalizedValue("RIN_SelectaDatePlaceholder")}
                            ariaLabel={getLocalizedValue("RIN_SelectADate")}
                            //allowTextInput={true}
                            styles={{
                                root: {
                                    width: '240px',
                                    marginBottom: '4px'
                                }
                            }}
                            value={simulationCampaign.endDate}
                            disabled={!phishingSimulationStatus}
                            onSelectDate={onSelectEndDate}
                        />
                        <div style={{ marginTop: "-4px" }}>
                            {errorStates.endDate.length > 0 && endDatedescription}
                        </div>
                    </div>
                </div>
                <div className={styles.image}>
                    <Label>{getLocalizedValue("RIN_AttachImage")} </Label>
                    <p className={styles.text}>{getLocalizedValue("RIN_ReccomendedSize")}</p>
                    {phishingImageOrUpload()}
                    <div className={styles.size}>
                        <div>
                            {getLocalizedValue("RIN_TotalAllowedSize")}: {allowedFileSize} MB
                        </div>
                        <div>
                            {getLocalizedValue("RIN_ConsumedSize")}: {" "}
                            {
                                simulationCampaign.attachment.fileSizeInKB < 1024 ?
                                    <>{simulationCampaign.attachment.fileSizeInKB.toFixed(2)} KB</> :
                                    <>{(simulationCampaign.attachment.fileSizeInKB / 1024).toFixed(2)} MB</>
                            }
                        </div>
                    </div>
                    <div className={styles.fileerros}>
                        <div style={{ marginTop: '12px' }}>
                            {errorStates.fileSize.length > 0 && fileSizeDescription}
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            {errorStates.fileType.length > 0 && fileTypeDescription}
                        </div>
                    </div>
                </div>
                <input id="fileInput" type="file" style={{ display: 'none' }} accept=".jpg, .png, .jpeg" onChange={handleFileChange} />
                {
                    phishingSimulationStatus &&
                    <DefaultButton text={getLocalizedValue("RIN_AddChange")} onClick={() => document.getElementById("fileInput")?.click()} disabled={!phishingSimulationStatus} />
                }

                <TextField
                    id="emailSenderName"
                    label={getLocalizedValue("RIN_SenderName")}
                    placeholder={getLocalizedValue("RIN_ProvideSenderName")}
                    value={simulationCampaign.phishingSimulationDetails.senderName}
                    onChange={(e,v)=>setSimulationCampaign({...simulationCampaign, phishingSimulationDetails: {...simulationCampaign.phishingSimulationDetails, senderName:v??"" }})}
                    onRenderDescription = {()=>senderNameDescription}
                    styles={{
                        root: {
                            marginTop: 12,
                        }
                    }}
                />
                <TextField
                    id="emailSenderaddress"
                    label={getLocalizedValue("RIN_SenderEmailOptional")}
                    placeholder={getLocalizedValue("RIN_ProvideSenderEmail")}
                    value={simulationCampaign.phishingSimulationDetails.senderEmail}
                    onChange={(e,v)=>setSimulationCampaign({...simulationCampaign, phishingSimulationDetails: {...simulationCampaign.phishingSimulationDetails, senderEmail:v??"" }})}
                    onRenderDescription = {()=>senderEmailDescription}
                    styles={{
                        root: {
                            marginTop: 12,
                        }
                    }}
                    
                />
                <TextField
                    id="emailSubject"
                    label={getLocalizedValue("RIN_EmailSubject")}
                    placeholder={getLocalizedValue("RIN_ProvideEmailSubject")}
                    value={simulationCampaign.phishingSimulationDetails.emailSubject}
                    onChange={(e,v)=>setSimulationCampaign({...simulationCampaign, phishingSimulationDetails: {...simulationCampaign.phishingSimulationDetails, emailSubject:v??"" }})}
                    onRenderDescription = {()=>emailSubjectDescription}
                    styles={{
                        root: {
                            marginTop: 12,
                        }
                    }}
                />
                <TextField
                    id="outlookWarning"
                    label={getLocalizedValue("RIN_OutlookWarningMessageOptional")}
                    placeholder={getLocalizedValue("RIN_ProvideOutlookWarningMessage")}
                    value={simulationCampaign.phishingSimulationDetails.outlookWarningMessage}
                    onChange={(e,v)=>setSimulationCampaign({...simulationCampaign, phishingSimulationDetails: {...simulationCampaign.phishingSimulationDetails, outlookWarningMessage:v??"" }})}
                    onRenderDescription = {()=>outlookWarningMessageDescription}
                    styles={{
                        root: {
                            marginTop: 12,
                        }
                    }}
                />
                <TextField
                    id="emailBody"
                    multiline
                    rows={3}
                    label={getLocalizedValue("RIN_EmailBody")}
                    placeholder={getLocalizedValue("RIN_ProvideEmailBody")}
                    value={simulationCampaign.phishingSimulationDetails.emailBody}
                    onChange={(e,v)=>setSimulationCampaign({...simulationCampaign, phishingSimulationDetails: {...simulationCampaign.phishingSimulationDetails, emailBody:v??"" }})}
                    onRenderDescription = {()=>emailBodyDescription}
                    styles={{
                        root: {
                            marginTop: 12,
                        }
                    }}
                />
            </div>
        </CoherencePanel>
    );
}