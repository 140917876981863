import * as React from "react";
import { DefaultButton, MessageBar, MessageBarType } from "@fluentui/react";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { mergeStyleSets } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import styles from "./PhishingCampaignPanel.module.css";
import { DatePicker, TextField } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { firstDayOfWeek, DayPickerStrings } from "../../../../Constants/DateControlConfigConstants";
import { DigitalEventTypes } from "../../../../Contracts/Enums/DigitalIncidentEnums";
import { DsSimulationCampaignInputViewModel } from "../../../../Contracts/TypesAndInterfaces/DsSimulationCampaignInputViewModel";
import { DigitalIncidentTypesSelector } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";

import { DefaultButtonAdapter, PrimaryButtonAdapter } from "../../Controls";
import { useNavigate } from "react-router-dom";
import { getSimulationCampaigns, resetErrorStates, setApiStatus, setEndDateErrorState, setFileSizeErrorState, setFileTypeErrorState, setIsUploadedSimulationCampaignSubmitted, setPreviousSimulationCampaign, setStartDateErrorState, setUploadedSimulationCampaign, SimulationCampaignSelector, submitUploadedSimulationCampaign } from "../../../DigitalSecurity/IncidentType/SimulationCampaignSlice";
import { HelpSectionSelector } from "../../../HelpPanel/HelpSectionSlice";
import { ProfileSections } from "../../../../Contracts/Enums/ProfileSectionEnums";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { SimulationCampaignsLoadingSelector } from "../../../Loader/LoaderSlice";
import Loader from "../../../Loader/Loader";

export interface PhishingCampaignPanelProps {
	allowedFileSize: number;
}

const controlClass = mergeStyleSets({
	control: {
		margin: "0 0 15px 0",
		maxWidth: "300px",
	},
});

export const PhishingCampaignv2: React.FC<PhishingCampaignPanelProps> = () => {
	const allowedFileSize = 1;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currentDate = new Date();
	const simulationCampaignDetails = useSelector(SimulationCampaignSelector);
	const apiStatus = simulationCampaignDetails.apiStatus;
	const isCampaignSubmitted = simulationCampaignDetails.isUploadedSimulationCampaignSubmitted;
	const errorStates = simulationCampaignDetails.errorStates;
	const phishingIncidentType = useSelector(DigitalIncidentTypesSelector).incidentTypesL1.find((x) => x.typeId === DigitalEventTypes.PhishingEMail);
	const phishingSimulation = phishingIncidentType?.isSimulationEnabled && simulationCampaignDetails.simulationCampaign !== null
		? simulationCampaignDetails.simulationCampaign
		: undefined;
	const [simulationCampaign, setSimulationCampaign] = React.useState<DsSimulationCampaignInputViewModel>({
		campaignId: phishingSimulation === undefined ? 0 : phishingSimulation.campaignId,
		isActive: phishingSimulation === undefined ? false : phishingSimulation.isActive,
		incidentTypeId:
			phishingSimulation === undefined
				? phishingIncidentType === undefined
					? 0
					: phishingIncidentType.simulationIncidentTypeId
				: phishingSimulation.incidentTypeId,
		startDate: phishingSimulation?.startDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.startDate,
		endDate: phishingSimulation?.endDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.endDate,
		attachment:
			phishingSimulation === undefined
				? {
					name: "",
					extension: "",
					fileContent: "",
					fileSizeInKB: 0,
				}
				: {
					name: phishingSimulation.attachmentName,
					extension: phishingSimulation.attachmentExtension,
					fileContent: phishingSimulation.attachmentContent,
					fileSizeInKB: phishingSimulation.attachmentSizeInKB,
				},
		incidentTimeZoneOffset: new Date().getTimezoneOffset(),
		phishingSimulationDetails:
			phishingSimulation === undefined
				? {
					senderName: "",
					senderEmail: "",
					emailSubject: "",
					outlookWarningMessage: "",
					emailBody: "",
				}
				: {
					senderName: phishingSimulation.senderName,
					senderEmail: phishingSimulation.senderEmail,
					emailSubject: phishingSimulation.emailSubject,
					outlookWarningMessage: phishingSimulation.outlookWarningMessage,
					emailBody: phishingSimulation.emailBody,
				},
	});
	const helpSectionDetails = useSelector(HelpSectionSelector);
	const [imgUrl, setImgUrl] = React.useState(phishingSimulation?.attachmentUrl);
	const isPhishingSimulationActive = phishingSimulation === undefined ? false : phishingSimulation.isActive;
	const [phishingSimulationStatus, setPhishingSimulationStatus] = React.useState(isPhishingSimulationActive);

	const startDatedescription = <FieldValidationDescription errorMessage={errorStates.startDate} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const endDatedescription = <FieldValidationDescription errorMessage={errorStates.endDate} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const fileSizeDescription = <FieldValidationDescription errorMessage={errorStates.fileSize} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const fileTypeDescription = <FieldValidationDescription errorMessage={errorStates.fileType} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const senderNameDescription = <FieldValidationDescription errorMessage={errorStates.senderName} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const emailSubjectDescription = <FieldValidationDescription errorMessage={errorStates.emailSubject} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const emailBodyDescription = <FieldValidationDescription errorMessage={errorStates.emailBody} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;
	const outlookWarningMessageDescription = (
		<FieldValidationDescription errorMessage={errorStates.outlookWarningMessage} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />
	);
	const senderEmailDescription = <FieldValidationDescription errorMessage={errorStates.senderEmail} descriptionColor={"#e50000"} iconName={"ErrorBadge"} />;

	const saveCampaignToState = () =>
		new Promise((resolve: any) => {
			dispatch(setUploadedSimulationCampaign(simulationCampaign));
			resolve();
		});
	const handleSaveCampaign = () => {
		saveCampaignToState().then(() => {
			dispatch(setIsUploadedSimulationCampaignSubmitted(false));
			dispatch(submitUploadedSimulationCampaign());
		});
	};

	React.useEffect(() => {
		if (!helpSectionDetails.helpSectionLinks || helpSectionDetails.helpSectionLinks.length <= 0) return;
		const phishingSimulationAdminLink = helpSectionDetails.helpSectionLinks.find(x => x.linkId === ProfileSections.SetPhishingCampaign);
		if (!phishingSimulationAdminLink) navigate("/v2");
	}, [helpSectionDetails.helpSectionLinks]);

	React.useEffect(() => {
		if (phishingSimulation !== undefined && phishingSimulation !== null) {
			dispatch(
				setPreviousSimulationCampaign({
					campaignId: phishingSimulation.campaignId,
					startDate: phishingSimulation.startDate,
					endDate: phishingSimulation.endDate,
					attachment: {
						name: phishingSimulation.attachmentName,
						fileContent: phishingSimulation.attachmentContent,
						fileSizeInKB: phishingSimulation.attachmentSizeInKB,
						extension: phishingSimulation.attachmentExtension,
					},
					isActive: phishingSimulation.isActive,
					incidentTypeId: phishingSimulation.incidentTypeId,
					incidentTimeZoneOffset: new Date().getTimezoneOffset(),
					phishingSimulationDetails:
						phishingSimulation === undefined
							? {
								senderName: "",
								senderEmail: "",
								emailSubject: "",
								outlookWarningMessage: "",
								emailBody: "",
							}
							: {
								senderName: phishingSimulation.senderName,
								senderEmail: phishingSimulation.senderEmail,
								emailSubject: phishingSimulation.emailSubject,
								outlookWarningMessage: phishingSimulation.outlookWarningMessage,
								emailBody: phishingSimulation.emailBody,
							},
				})
			);

			setSimulationCampaign({
				campaignId: phishingSimulation === undefined ? 0 : phishingSimulation.campaignId,
				isActive: phishingSimulation === undefined ? false : phishingSimulation.isActive,
				incidentTypeId:
					phishingSimulation === undefined
						? phishingIncidentType === undefined
							? 0
							: phishingIncidentType.simulationIncidentTypeId
						: phishingSimulation.incidentTypeId,
				startDate: phishingSimulation?.startDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.startDate,
				endDate: phishingSimulation?.endDate === undefined ? new Date(new Date().toDateString()) : phishingSimulation.endDate,
				attachment:
					phishingSimulation === undefined
						? {
							name: "",
							extension: "",
							fileContent: "",
							fileSizeInKB: 0,
						}
						: {
							name: phishingSimulation.attachmentName,
							extension: phishingSimulation.attachmentExtension,
							fileContent: phishingSimulation.attachmentContent,
							fileSizeInKB: phishingSimulation.attachmentSizeInKB,
						},
				incidentTimeZoneOffset: new Date().getTimezoneOffset(),
				phishingSimulationDetails:
					phishingSimulation === undefined
						? {
							senderName: "",
							senderEmail: "",
							emailSubject: "",
							outlookWarningMessage: "",
							emailBody: "",
						}
						: {
							senderName: phishingSimulation.senderName,
							senderEmail: phishingSimulation.senderEmail,
							emailSubject: phishingSimulation.emailSubject,
							outlookWarningMessage: phishingSimulation.outlookWarningMessage,
							emailBody: phishingSimulation.emailBody,
						},
			});
		}
		dispatch(resetErrorStates());
	}, [phishingSimulation]);

	React.useEffect(() => {
		if (isCampaignSubmitted) {
			dispatch(getSimulationCampaigns());
		}
	}, [isCampaignSubmitted]);

	React.useEffect(() => {
		return () => {
			dispatch(setIsUploadedSimulationCampaignSubmitted(false));
			dispatch(setApiStatus({ statusCode: 200, statusReceived: true }));
		};
	}, []);

	const onSelectStartDate = (date: Date | null | undefined): void => {
		if (date) {
			dispatch(setStartDateErrorState(""));
			setSimulationCampaign({
				...simulationCampaign,
				startDate: date,
			});
		}
	};

	const onSelectEndDate = (date: Date | null | undefined): void => {
		if (date) {
			dispatch(setEndDateErrorState(""));
			setSimulationCampaign({
				...simulationCampaign,
				endDate: date,
			});
		}
	};

	const isSaveEnabled = (): boolean => {
		if (phishingSimulation === undefined || phishingSimulation === null) {
			return phishingSimulationStatus && simulationCampaign.attachment.fileContent.length > 0;
		} else {
			return (
				errorStates.endDate === "" &&
				errorStates.startDate === "" &&
				errorStates.fileType === "" &&
				errorStates.fileSize === "" &&
				(phishingSimulation.startDate.toDateString() !== simulationCampaign.startDate.toDateString() ||
					phishingSimulation.endDate.toDateString() !== simulationCampaign.endDate.toDateString() ||
					simulationCampaign.attachment.extension !== phishingSimulation.attachmentExtension ||
					simulationCampaign.attachment.name !== phishingSimulation.attachmentName ||
					simulationCampaign.isActive !== phishingSimulation.isActive ||
					simulationCampaign.phishingSimulationDetails.senderEmail !== phishingSimulation.senderEmail ||
					simulationCampaign.phishingSimulationDetails.senderName !== phishingSimulation.senderName ||
					simulationCampaign.phishingSimulationDetails.outlookWarningMessage !== phishingSimulation.outlookWarningMessage ||
					simulationCampaign.phishingSimulationDetails.emailSubject !== phishingSimulation.emailSubject ||
					simulationCampaign.phishingSimulationDetails.emailBody !== phishingSimulation.emailBody)
			);
		}
	};

	const handleStatusChange = (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
		if (checked !== undefined) {
			setPhishingSimulationStatus(checked);
			setSimulationCampaign({ ...simulationCampaign, isActive: checked });
		}
	};

	const toBase64 = (file: File) =>
		new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
		});

	const handleFileChange = async (e: any) => {
		dispatch(setFileSizeErrorState(""));
		dispatch(setFileTypeErrorState(""));
		const file: File = e.target.files[0];

		if (file !== undefined) {
			if (file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/jpg") {
				dispatch(setFileTypeErrorState(getLocalizedValueV2(getLocalizedValueV2("RIN_AttachmentIsNotASupportedType"))));
				setImgUrl(undefined);
				setSimulationCampaign({
					...simulationCampaign,
					attachment: {
						extension: "",
						fileSizeInKB: 0,
						fileContent: "",
						name: "",
					},
				});
				return;
			} else if (file.size > allowedFileSize * 1024 * 1024) {
				dispatch(setFileSizeErrorState(file.name + getLocalizedValueV2("RIN_ExceedsTheAllowedSizeOf") + allowedFileSize + "MB"));
				setImgUrl(undefined);
				setSimulationCampaign({
					...simulationCampaign,
					attachment: {
						extension: "",
						fileSizeInKB: 0,
						fileContent: "",
						name: "",
					},
				});
				return;
			}

			const reader = new FileReader();
			var fileContent = "";
			reader.readAsDataURL(file);
			reader.addEventListener(
				"load",
				function () {
					setImgUrl(reader.result?.toString());
				},
				false
			);

			await toBase64(file).then((result: any) => {
				fileContent = result.split(",")[1];
			});
			setSimulationCampaign({
				...simulationCampaign,
				attachment: {
					name: file.name,
					extension: getExtension(file.name),
					fileContent: fileContent,
					fileSizeInKB: file.size / 1024,
				},
			});
		}
	};
	const phishingSimulationMessage = (
		<div>
			<MessageBar
				messageBarType={
					apiStatus.statusCode === 401 ||
						apiStatus.statusCode === 400 ||
						apiStatus.statusCode === 404 ||
						apiStatus.statusCode === 500 ||
						apiStatus.statusCode === undefined
						? MessageBarType.error
						: isCampaignSubmitted
							? MessageBarType.success
							: MessageBarType.info
				}
				onDismiss={() => dispatch(setApiStatus({ statusCode: apiStatus.statusCode, statusReceived: false }))}
				dismissButtonAriaLabel="Close"
				styles={
					apiStatus.statusCode === 401 ||
						apiStatus.statusCode === 400 ||
						apiStatus.statusCode === 404 ||
						apiStatus.statusCode === 500 ||
						apiStatus.statusCode === undefined
						? {
							root: {
								backgroundColor: "#FED9CC",
								marginLeft: "0px",
							},
						}
						: isPhishingSimulationActive &&
							phishingSimulation?.isActive &&
							new Date(currentDate.toDateString()) <= new Date(phishingSimulation.endDate.toDateString()) &&
							new Date(currentDate.toDateString()) >= new Date(phishingSimulation.startDate.toDateString())
							? {
								root: {
									backgroundColor: "#DFF6DD",
									marginLeft: "0px",
								},
								icon: {
									color: "rgb(16, 124, 16)",
								},
							}
							: {
								root: {
									backgroundColor: "#F2F2F2",
									marginLeft: "0px",
								},
								icon: {
									color: "black",
								},
							}
				}
			>
				{apiStatus.statusCode === 401 ? (
					<>{getLocalizedValueV2("RIN_Unauthorized")}</>
				) : apiStatus.statusCode === 500 || apiStatus.statusCode === 400 || apiStatus.statusCode === 404 || apiStatus.statusCode === undefined ? (
					<> {getLocalizedValueV2("RIN_PhishingCampaignErrorMessage")}</>
				) : isCampaignSubmitted ? (
					<>{getLocalizedValueV2("RIN_PhishingCampaignInfoMessage1")}</>
				) : (
					<>
						{getLocalizedValueV2("RIN_PhishingCampaignInfoMessage2")}{" "}
						{isPhishingSimulationActive &&
							phishingSimulation?.isActive &&
							new Date(currentDate.toDateString()) <= new Date(phishingSimulation.endDate.toDateString()) &&
							new Date(currentDate.toDateString()) >= new Date(phishingSimulation.startDate.toDateString())
							? "active"
							: "inactive"}{" "}
						{getLocalizedValueV2("RIN_AcrossMicrosoft")}
					</>
				)}
			</MessageBar>
		</div>
	);

	const phishingImageOrUpload = (): JSX.Element => {
		let imageSection = <></>;
		if (phishingSimulation === undefined) {
			if (imgUrl === undefined) {
				imageSection = <div className={styles.previewBlank}>{getLocalizedValueV2("RIN_NoImageCurrentlyAdded")}</div>;
			} else if (imgUrl !== undefined && imgUrl.length > 0) {
				imageSection = (
					<div>
						<img className={styles.preview} src={imgUrl} alt={getLocalizedValueV2("RIN_SimulationCampaignImage")}></img>
					</div>
				);
			}
		} else {
			if (imgUrl === undefined) {
				imageSection = <div className={styles.previewBlank}>No Image currently added</div>;
			} else if (phishingSimulationStatus) {
				imageSection = (
					<div>
						<img className={styles.preview} src={imgUrl} alt={getLocalizedValueV2("RIN_SimulationCampaignImage")}></img>
					</div>
				);
			} else
				imageSection = (
					<div>
						<img className={styles.previewGray} src={imgUrl} alt={getLocalizedValueV2("RIN_SimulationCampaignImage")}></img>
					</div>
				);
		}
		return imageSection;
	};

	return (
		<div style={{ marginTop: 64 }}>
			<div className="ms-Grid-row">
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					<div>
						<div className="stickey-Header-Container">{apiStatus.statusReceived && phishingSimulationMessage}</div>
						<div className={styles.toggle}>
							<Toggle label={getLocalizedValueV2("RIN_SetStatus")} onText="Enabled" offText="Disabled" onChange={handleStatusChange} checked={phishingSimulationStatus} />
						</div>
						<div className={styles.date}>
							<div className={styles.starting}>
								<DatePicker
									label={getLocalizedValueV2("RIN_StartingOn")}
									isMonthPickerVisible={false}
									className={controlClass.control}
									firstDayOfWeek={firstDayOfWeek}
									strings={DayPickerStrings}
									placeholder={getLocalizedValueV2("RIN_SelectaDatePlaceholder")}
									ariaLabel={getLocalizedValueV2("RIN_SelectADate")}
									//allowTextInput={true}
									styles={{
										root: {
											width: "240px",
											marginBottom: "4px",
										},
									}}
									value={simulationCampaign.startDate}
									disabled={!phishingSimulationStatus}
									onSelectDate={onSelectStartDate}
								/>
								<div style={{ marginTop: "-4px" }}>{errorStates.startDate.length > 0 && startDatedescription}</div>
							</div>
							<div className={styles.ending}>
								<DatePicker
									label={getLocalizedValueV2("RIN_EndingOn")}
									className={controlClass.control}
									isMonthPickerVisible={false}
									firstDayOfWeek={firstDayOfWeek}
									strings={DayPickerStrings}
									placeholder={getLocalizedValueV2("RIN_SelectaDatePlaceholder")}
									ariaLabel={getLocalizedValueV2("RIN_SelectADate")}
									//allowTextInput={true}
									styles={{
										root: {
											width: "240px",
											marginBottom: "4px",
										},
									}}
									value={simulationCampaign.endDate}
									disabled={!phishingSimulationStatus}
									onSelectDate={onSelectEndDate}
								/>
								<div style={{ marginTop: "-4px" }}>{errorStates.endDate.length > 0 && endDatedescription}</div>
							</div>
						</div>
						<div className={styles.image}>
							<Label>{getLocalizedValueV2("RIN_AttachImage")} </Label>
							<p className={styles.text}>
								{getLocalizedValueV2("RIN_ReccomendedSize")}
							</p>
							{phishingImageOrUpload()}
							<div className={styles.size}>
								<div>{getLocalizedValueV2("RIN_TotalAllowedSize")}: {allowedFileSize} MB</div>
								<div>
									{getLocalizedValueV2("RIN_ConsumedSize")}:{" "}
									{simulationCampaign.attachment.fileSizeInKB < 1024 ? (
										<>{simulationCampaign.attachment.fileSizeInKB.toFixed(2)} KB</>
									) : (
										<>{(simulationCampaign.attachment.fileSizeInKB / 1024).toFixed(2)} MB</>
									)}
								</div>
							</div>
							<div className={styles.fileerros}>
								<div style={{ marginTop: "12px" }}>{errorStates.fileSize.length > 0 && fileSizeDescription}</div>
								<div style={{ marginTop: "12px" }}>{errorStates.fileType.length > 0 && fileTypeDescription}</div>
							</div>
						</div>
						<input id="fileInput" type="file" style={{ display: "none" }} accept=".jpg, .png, .jpeg" onChange={handleFileChange} />
						{phishingSimulationStatus && (
							<DefaultButton
								text={getLocalizedValueV2("RIN_AddChange")}
								onClick={() => document.getElementById("fileInput")?.click()}
								disabled={!phishingSimulationStatus}
							/>
						)}

						<TextField
							id="emailSenderName"
							label={getLocalizedValueV2("RIN_SenderName")}
							placeholder={getLocalizedValueV2("RIN_ProvideSenderName")}
							value={simulationCampaign.phishingSimulationDetails.senderName}
							onChange={(e, v) =>
								setSimulationCampaign({
									...simulationCampaign,
									phishingSimulationDetails: { ...simulationCampaign.phishingSimulationDetails, senderName: v ?? "" },
								})
							}
							onRenderDescription={() => senderNameDescription}
							styles={{
								root: {
									marginTop: 12,
								},
							}}
						/>
						<TextField
							id="emailSenderaddress"
							label={getLocalizedValueV2("RIN_SenderEmailOptional")}
							placeholder={getLocalizedValueV2("RIN_ProvideSenderEmail")}
							value={simulationCampaign.phishingSimulationDetails.senderEmail}
							onChange={(e, v) =>
								setSimulationCampaign({
									...simulationCampaign,
									phishingSimulationDetails: { ...simulationCampaign.phishingSimulationDetails, senderEmail: v ?? "" },
								})
							}
							onRenderDescription={() => senderEmailDescription}
							styles={{
								root: {
									marginTop: 12,
								},
							}}
						/>
						<TextField
							id="emailSubject"
							label={getLocalizedValueV2("RIN_EmailSubject")}
							placeholder={getLocalizedValueV2("RIN_ProvideEmailSubject")}
							value={simulationCampaign.phishingSimulationDetails.emailSubject}
							onChange={(e, v) =>
								setSimulationCampaign({
									...simulationCampaign,
									phishingSimulationDetails: { ...simulationCampaign.phishingSimulationDetails, emailSubject: v ?? "" },
								})
							}
							onRenderDescription={() => emailSubjectDescription}
							styles={{
								root: {
									marginTop: 12,
								},
							}}
						/>
						<TextField
							id="outlookWarning"
							label={getLocalizedValueV2("RIN_OutlookWarningMessageOptional")}
							placeholder={getLocalizedValueV2("RIN_ProvideOutlookWarningMessage")}
							value={simulationCampaign.phishingSimulationDetails.outlookWarningMessage}
							onChange={(e, v) =>
								setSimulationCampaign({
									...simulationCampaign,
									phishingSimulationDetails: { ...simulationCampaign.phishingSimulationDetails, outlookWarningMessage: v ?? "" },
								})
							}
							onRenderDescription={() => outlookWarningMessageDescription}
							styles={{
								root: {
									marginTop: 12,
								},
							}}
						/>
						<TextField
							id="emailBody"
							multiline
							rows={4}
							label={getLocalizedValueV2("RIN_EmailBody")}
							placeholder={getLocalizedValueV2("RIN_ProvideSenderEmail")}
							value={simulationCampaign.phishingSimulationDetails.emailBody}
							onChange={(e, v) =>
								setSimulationCampaign({
									...simulationCampaign,
									phishingSimulationDetails: { ...simulationCampaign.phishingSimulationDetails, emailBody: v ?? "" },
								})
							}
							onRenderDescription={() => emailBodyDescription}
							styles={{
								root: {
									marginTop: 12,
								},
							}}
						/>
					</div>
				</div>
			</div>
			<div className="ms-Grid-row" style={{ marginBottom: 16, marginTop: 16 }}>
				<div className="ms-Grid-col ms-sm12 ms-md8 ms-mdPush2 ms-lg8 ms-lgPush2 ms-xl6 ms-xlPush3 ms-xxl6 ms-xxlPush3 ms-xxxl6 ms-xxxlPush3">
					<DefaultButtonAdapter onClick={() => navigate("/v2")}>{getLocalizedValueV2("RIN_Cancel")}</DefaultButtonAdapter>{" "}
					<PrimaryButtonAdapter disabled={!isSaveEnabled()} onClick={handleSaveCampaign}>
						{getLocalizedValueV2("RIN_Save")}
					</PrimaryButtonAdapter>
				</div>
			</div>
		</div>
	);
};


function getExtension(path: string) {
	var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
		// (supports `\\` and `/` separators)
		pos = basename?.lastIndexOf(".") ?? 0;       // get last position of `.`

	if (basename === "" || pos < 1)            // if file name is empty or ...
		return "";                             //  `.` not found (-1) or comes first (0)

	return basename?.slice(pos) ?? "";            // extract extension ignoring `.`
}