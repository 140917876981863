import {
    ChoiceGroup,
    IChoiceGroupOption,
    Label,
    TextField,
} from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DsDiPhishingEmailInputViewModel, DsDiPhishingEmailLocalizedInputViewModel } from "../../../../Contracts/TypesAndInterfaces/DsDiPhishingEmailInputViewModel";
import { getLocalizedValueV2 } from "../../../../Services/localizationServiceV2";
import { getRadioButtonStyle } from "../../../../styles";
import { ErrorStatesSelector, PhishingEmailSelector, setDigitalIncidentPhishingEmailObject, setPhishingEmailDeliveredLocation, setPhishingEmailAttachmentUrlOpenedByUser, setPhishingActionsErrorMessage, setPhishingEmailInfoSharedByUser, LocalizedPhishingEmailInputSelector, setDigitalIncidentLocalizedPhishingEmailObject } from "../../../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import FieldValidationDescription from "../../../FieldValidationDescription/FieldValidationDescription";
import { renderDescriptionLabel } from "../../../Home/Home";

const emailDeliveryLocationOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Inbox"), value: "Inbox" },
    { key: "2", text: getLocalizedValueV2("RIN_JunkMailFolder"), value: "Junk mail folder" },
];

const userActionOptions: IChoiceGroupOption[] = [
    { key: "1", text: getLocalizedValueV2("RIN_Yes"), value: "Yes" },
    { key: "2", text: getLocalizedValueV2("RIN_No"), value: "No" },
];

export default function PhishingEmailFields() {
    const labelId: string = "action-taken-details";
    const dispatch = useDispatch();
    const [
        phishingDetails,
        setPhishingDetails,
    ] = React.useState<DsDiPhishingEmailInputViewModel>({
        emailDeliveredLocation: "Inbox",
        infoSharedByUser: "",
        isAttachmentorURLOpenedByUser: false,
    });
    const defaultLocalizedPhishingDetails: DsDiPhishingEmailLocalizedInputViewModel = {
        emailDeliveredLocation: getLocalizedValueV2("RIN_Inbox"),
        infoSharedByUser: "",
        isAttachmentorURLOpenedByUser: getLocalizedValueV2("RIN_No")
    }
    const localizedPhishingDetails: DsDiPhishingEmailLocalizedInputViewModel = useSelector(LocalizedPhishingEmailInputSelector);

    const [
        emailLocation,
        setEmailLocation,
    ] = React.useState<IChoiceGroupOption>();
    const [
        attachmentOpened,
        setAttachmentOpened,
    ] = React.useState<IChoiceGroupOption>();
    const errorStatesDetails = useSelector(ErrorStatesSelector);
    const phishingEmailSelector = useSelector(PhishingEmailSelector);
    const phishingActionsMessage = (
        <FieldValidationDescription
            errorMessage={errorStatesDetails.phishingActions}
            descriptionColor={'red'}
            iconName={"ErrorBadge"}
        />
    );

    React.useEffect(() => {
        // TODO: when cleaning up the existing experience.Cleanup this logic to be more simple.
        if(phishingEmailSelector){
            setPhishingDetails(phishingEmailSelector);
            setEmailLocation(emailDeliveryLocationOptions.find(x=>x.value == phishingEmailSelector.emailDeliveredLocation));
            setAttachmentOpened(userActionOptions.find(x=>x.value === (phishingEmailSelector.isAttachmentorURLOpenedByUser ? "Yes": "No")))
        }
        else{
            dispatch(setDigitalIncidentPhishingEmailObject(phishingDetails));
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({ ...localizedPhishingDetails, ...defaultLocalizedPhishingDetails }));
        }
    }, []);

    const handleDeliveryLocationChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setEmailLocation(option);
            dispatch(setPhishingEmailDeliveredLocation((typeof (option.value) === "string" ? option.value ?? "" : "")));
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({ ...localizedPhishingDetails, emailDeliveredLocation: option.text }));
        }
    };

    const handleAttachmentOpenedChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) => {
        if (option) {
            setAttachmentOpened(option);
            if (option.value == "Yes") {
                dispatch(setPhishingEmailAttachmentUrlOpenedByUser(true));
            }
            else {
                dispatch(setPhishingEmailAttachmentUrlOpenedByUser(false));
                dispatch(setPhishingActionsErrorMessage(""));
                dispatch(setPhishingEmailInfoSharedByUser(""));
            }
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({ ...localizedPhishingDetails, isAttachmentorURLOpenedByUser: option.text }));
        }
    };

    const handleInfoChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            dispatch(setPhishingActionsErrorMessage(""));
            dispatch(setPhishingEmailInfoSharedByUser(newValue));
            dispatch(setDigitalIncidentLocalizedPhishingEmailObject({ ...localizedPhishingDetails, infoSharedByUser: newValue }));
        }
    };



    return (
        <div className="phishing-email-fields">
            <div className="ms-Grid-row" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                    <ChoiceGroup
                        options={emailDeliveryLocationOptions}
                        label={getLocalizedValueV2("RIN_EmailDeliveryLocation")}
                        selectedKey={emailDeliveryLocationOptions.find(x=>x.value == phishingEmailSelector?.emailDeliveredLocation)?.key}
                        onChange={handleDeliveryLocationChange}
                        defaultSelectedKey="1"
                        styles={getRadioButtonStyle(222)}
                    />
                </div>
            </div>

            <div id="your-actions-section" style={{ marginBottom: "28px" }}>
                <div className="ms-Grid-row" style={{ marginBottom: "2px" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <Label>{getLocalizedValueV2("RIN_YourActions")}</Label>
                    </div>
                </div>

                <div className="ms-Grid-row" style={{ marginBottom: "20px" }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12 block">
                        <Label
                            id="url-clicked"
                            style={{ fontWeight: "normal" }}
                        >
                            {getLocalizedValueV2("RIN_PhishingEmailQuestion1")}
                        </Label>
                        <ChoiceGroup
                            options={userActionOptions}
                            ariaLabelledBy="url-clicked"
                            selectedKey={userActionOptions.find(x=>x.value === (phishingEmailSelector?.isAttachmentorURLOpenedByUser ? "Yes": "No"))?.key}
                            onChange={handleAttachmentOpenedChange}
                            defaultSelectedKey="2"
                            styles={getRadioButtonStyle(128)}
                        />
                    </div>
                </div>

                {
                    attachmentOpened?.value == "Yes" &&
                    <TextField
                        id="phishingEmailDetailsID"
                        required
                        aria-required={true}
                        aria-labelledby={labelId}
                        label={getLocalizedValueV2("RIN_PhishingEmailQuestion2")}
                        placeholder={getLocalizedValueV2("RIN_PhishingEmailQuestion2PlaceHolder")}
                        multiline
                        onRenderLabel={(props) =>
                            renderDescriptionLabel({...props, className: "required-field-label"}, labelId)
                        }
                        value={phishingEmailSelector?.infoSharedByUser}
                        onChange={handleInfoChange}
                        onRenderDescription={() => phishingActionsMessage}
                        styles={
                            errorStatesDetails.phishingActions
                                .length > 0
                                ? {
                                    field: {
                                        backgroundColor: "#FED9CC",
                                    },
                                }
                                : {
                                    field: {
                                        backgroundColor: "white",
                                    },
                                }
                        }
                    />}
            </div>
        </div>
    );
}
