import * as React from "react";
import { useBoolean } from "../../Hooks/useBoolean";
import { PrimaryButton } from "@fluentui/react";
import styles from "./HelpPanel.module.css";
import LinkOutVector from "../../Assets/Vector.svg";
import { useDispatch, useSelector } from "react-redux";
import { HelpSectionSelector } from "./HelpSectionSlice";
import { PanelEnums } from "../../Contracts/Enums/PanelEnums";
import SomethingWentWrongImage from "../../Assets/Error.svg";
import { IncidentCategorySelector, setSelectedIncidentCategory } from "../IncidentCategory/IncidentCategorySlice";
import { IncidentCategories } from "../../Contracts/Enums/IncidentCategory";
import { DigitalIncidentTypesSelector, setDigitalIncidentTypesPanelVisibility } from "../DigitalSecurity/DigitalIncident/DigitalIncidentSlice";
import { physicalIncidentSelector, PsResetLevel, resetPhysicalIncidentInformation, setPhysicalIncidentPanelVisibility } from "../PhysicalSecurity/PhysicalIncident/PhysicalIncidentSlice";
import { IncidentCategory } from "../../Contracts/TypesAndInterfaces/IncidentCategoryResponseViewModel";
import { LeaveIncident } from "../LeaveIncident/LeaveIncident";
import { getLocalizedValue } from "../../Services/localizationService";

export interface HelpPanelProps {
    openL2Panel: () => void;
    dismissPanel: () => void;
    setLinkId: Function;
    setTitle: Function;
    setDescription: Function;
    setMoreInfoUrl: Function;
    linkIdToFocus?: string;
    setL2OpenedSource: Function;
}
const buttonStyles = { root: { marginRight: 8 } };

export const helpFooter = (dismissPanel: () => void, setLinkId: Function): JSX.Element => {
    return (
        <div>
            <PrimaryButton
                text={getLocalizedValue("RIN_Close")}
                id="helpCloseButtonId"
                onClick={() => {
                    dismissPanel();
                    setLinkId(undefined);
                    document.getElementById("helpButtonId")?.click();
                }}
                styles={buttonStyles}
            ></PrimaryButton>
        </div>
    );
};

export const HelpPanel: React.FC<HelpPanelProps> = ({
    openL2Panel,
    setTitle,
    setLinkId,
    setDescription,
    setMoreInfoUrl,
    dismissPanel,
    linkIdToFocus,
    setL2OpenedSource
}) => {
    const helpSectionDetails = useSelector(HelpSectionSelector);
    const dispatch = useDispatch();
    const apiSuccess = (helpSectionDetails.isHelpSectionApiCallSuccess && helpSectionDetails.isHelpSectionLinksApiCallSuccess);
    const digitalLink = helpSectionDetails.helpSectionLinks.find(s => s.sectionId === 2);
    const physicalLink = helpSectionDetails.helpSectionLinks.find(s => s.sectionId === 3);
    const incidentCategoryDetails = useSelector(IncidentCategorySelector);
    const digitalIncidentCategory = incidentCategoryDetails.incidentCategories.find(s => s.categoryId === IncidentCategories.Digital);
    const physicalIncidentCategory = incidentCategoryDetails.incidentCategories.find(s => s.categoryId === IncidentCategories.Physical);
    const [isLeaveIncidentDialogOpen, { setTrue: openLeaveIncidentDialog, setFalse: dismissLeaveIncidentDialog }] = useBoolean(false);
    const physicalIncidentDetails = useSelector(physicalIncidentSelector);
    const digitalIncidentTypesDetails = useSelector(DigitalIncidentTypesSelector);

    const handleL2PanelClick = (
        title: string,
        description: string,
        moreInfoUrl: string,
        linkId: number,
        sectionId: number
    ) => {
        setTitle(getLocalizedValue(title));
        setDescription(getLocalizedValue(description));
        setMoreInfoUrl(moreInfoUrl);
        setLinkId(sectionId.toString() + "-" + linkId.toString());
        setL2OpenedSource(PanelEnums.Help);
    };

    const linkRef = React.useRef<HTMLAnchorElement>(null);
    React.useEffect(() => {
        if (linkIdToFocus !== undefined && linkRef.current != null) {
            linkRef.current.focus();
        }
    }, [])

    const handleIncidentCategoryChange = (currentIncidentCategory: IncidentCategory | undefined) => {
        if (currentIncidentCategory) {
            if (incidentCategoryDetails.selectedIncidentCategory === undefined || incidentCategoryDetails.selectedIncidentCategory == null) {
                if (currentIncidentCategory.categoryId === IncidentCategories.Digital) {
                    dispatch(setSelectedIncidentCategory(digitalIncidentCategory))
                    dispatch(setDigitalIncidentTypesPanelVisibility(true));
                }
                else {
                    dispatch(setSelectedIncidentCategory(physicalIncidentCategory))
                    dispatch(setPhysicalIncidentPanelVisibility(true));
                }
            }
            else {
                if (incidentCategoryDetails.selectedIncidentCategory.categoryId !== currentIncidentCategory.categoryId) {
                    if (incidentCategoryDetails.selectedIncidentCategory.categoryId === IncidentCategories.Physical) {
                        if (physicalIncidentDetails.incidentTypes.selectedincidentTypeIdL1) {
                            openLeaveIncidentDialog();
                        }
                        else {
                            dispatch(setSelectedIncidentCategory(currentIncidentCategory));
                            dispatch(resetPhysicalIncidentInformation(PsResetLevel.CategoryChanged));
                            dispatch(setDigitalIncidentTypesPanelVisibility(true));
                        }
                    } else if (incidentCategoryDetails.selectedIncidentCategory.categoryId === IncidentCategories.Digital) {
                        if (digitalIncidentTypesDetails.selectedIncidentTypeL1) {
                            openLeaveIncidentDialog();
                        } else {
                            dispatch(setSelectedIncidentCategory(currentIncidentCategory));
                            dispatch(setPhysicalIncidentPanelVisibility(true));
                        }
                    }
                }
                else {
                    if (currentIncidentCategory.categoryId === IncidentCategories.Digital) {
                        dispatch(setDigitalIncidentTypesPanelVisibility(true));
                    }
                    else {
                        dispatch(setPhysicalIncidentPanelVisibility(true));
                    }
                }
            }
        }
    }

    return (
        <>
            {apiSuccess && helpSectionDetails.helpSectionLinks.length > 0 && (
                <div>
                    {helpSectionDetails.helpSections.filter(x => x.panelId === PanelEnums.Help).map((section) => (
                        <>
                            {(section.sectionId === 2 || section.sectionId === 3) &&
                                <div style={{ display: "flex" }}>
                                    <h2 className={styles.heading2}>{getLocalizedValue(section.titleKey)}</h2>
                                    <a href={section.sectionId === 2 ? digitalLink?.moreInfoUrl : physicalLink?.moreInfoUrl}
                                        title={section.sectionId === 2 ? (getLocalizedValue(digitalLink?.titleKey) + ' ' + getLocalizedValue("RIN_OpenInNewWindow")) : (getLocalizedValue(physicalLink?.titleKey) + ' '+ getLocalizedValue("RIN_OpenInNewWindow"))}
                                        aria-label={section.sectionId === 2 ? (getLocalizedValue(digitalLink?.titleKey) + ' ' + getLocalizedValue("RIN_OpenInNewWindow")) : (getLocalizedValue(physicalLink?.titleKey) + ' ' + getLocalizedValue("RIN_OpenInNewWindow"))}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ padding: "11px", marginTop: "6px" }}
                                    >
                                        <img
                                            src={LinkOutVector}
                                            alt={section.sectionId === 2 ? getLocalizedValue("RIN_DigitalSecurityInfo") : getLocalizedValue("RIN_PhysicalSecurityInfo")}
                                        />
                                    </a>
                                </div>
                            }
                            {(section.sectionId !== 2 && section.sectionId !== 3) &&
                                <h2 className={styles.heading2}>{getLocalizedValue(section.titleKey)}</h2>}
                            {(section.sectionId === 2 || section.sectionId === 3) &&
                                <div>
                                    {section.sectionId === 2 &&
                                        <div>
                                            {getLocalizedValue(digitalIncidentCategory?.descriptionKey)}
                                            <br />
                                            <br />
                                            <a
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                role="button"
                                                onClick={() => handleIncidentCategoryChange(digitalIncidentCategory)}
                                                aria-label={getLocalizedValue("RIN_DigitalIncidentTypes")}
                                                href='#'
                                            >
                                                {getLocalizedValue("RIN_DigitalIncidentTypes")}
                                            </a>
                                        </div>
                                    }
                                    {section.sectionId === 3 &&
                                        <div>
                                            {getLocalizedValue(physicalIncidentCategory?.descriptionKey)}
                                            <br />
                                            <br />
                                            <a
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                role="button"
                                                onClick={() => handleIncidentCategoryChange(physicalIncidentCategory)}
                                                aria-label={getLocalizedValue("RIN_PhysicalIncidentTypes")}
                                                href='#'
                                            >
                                                {getLocalizedValue("RIN_PhysicalIncidentTypes")}
                                            </a>
                                        </div>
                                    }
                                </div>
                            }
                            {(section.sectionId !== 2 && section.sectionId !== 3) && helpSectionDetails.helpSectionLinks
                                .filter((s) => s.sectionId === section.sectionId)
                                .map((link) => (
                                    <div className={styles.heading3}>
                                        {!link.openInNewTab ? (
                                            <a
                                                ref={(linkIdToFocus !== undefined && Number(linkIdToFocus.split("-")[0]) == section.sectionId && Number(linkIdToFocus.split("-")[1]) === link.linkId) ? linkRef : undefined}
                                                className={styles.link}
                                                style={{ cursor: "pointer" }}
                                                role="button"
                                                onClick={() => {
                                                    handleL2PanelClick(
                                                        link.titleKey,
                                                        link.descriptionKey,
                                                        link.moreInfoUrl,
                                                        link.linkId,
                                                        section.sectionId
                                                    );
                                                    dismissPanel();
                                                    openL2Panel();
                                                }
                                                }
                                                aria-label={getLocalizedValue(link.titleKey)}
                                                href='#'
                                            >
                                                {getLocalizedValue(link.titleKey)}
                                            </a>
                                        ) : (
                                            <div>
                                                <a
                                                    className={styles.link}
                                                    href={link.moreInfoUrl}
                                                    aria-label={getLocalizedValue(link.titleKey) + ' ' + getLocalizedValue("RIN_OpenInNewWindow") }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {getLocalizedValue(link.titleKey)}
                                                </a>
                                                <span aria-hidden={true} className={styles.linkOutVector}>
                                                    <img
                                                        src={LinkOutVector}
                                                        alt="Link out icon"
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            <div className={styles.divider}></div>
                        </>
                    ))}
                </div>
            )}
            {(!apiSuccess || helpSectionDetails.helpSectionLinks.length === 0) && (
                <div className={styles.helpErrorContainer}>
                    <img
                        src={SomethingWentWrongImage}
                        alt={getLocalizedValue("RIN_SomethingWentWrongImage")}
                        className={styles.helpErrorImage}
                    />
                    <h2 className={styles.helpErrorHeading} >{getLocalizedValue("RIN_ErrorPageHeader")}</h2>
                    <p className={styles.helpErrorMessage}>{getLocalizedValue("RIN_HelpErrorMessage1")} <a href="https://aka.ms/msprotect" aria-label={getLocalizedValue("RIN_HelpErrorMessage4")} style={{ color: "#0064BF" }} target="_blank" rel="noopener noreferrer">{getLocalizedValue("RIN_HelpErrorMessage2")}</a> {getLocalizedValue("RIN_HelpErrorMessage3")}</p>
                </div>
            )}
            {isLeaveIncidentDialogOpen && (
                <LeaveIncident
                    isHidden={!isLeaveIncidentDialogOpen}
                    dismissDialog={dismissLeaveIncidentDialog}
                    isTriggeredFromPanel={true}
                />
            )}
        </>
    );
};
